/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  CardTitle,
  DataTable,
  DataTableColumnOptions,
  DataTableRowOptions,
  DataTableSortOrder,
} from '@fcg-tech/regtech-components';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';

import {
  ListDataTableLeadParagraph,
  ListDataTableTableWrapper,
} from './ListDataTable.styles';

interface ListDataTableProps<P = any, CK = any> {
  title?: string;
  description?: string;
  emptyTableText?: string;
  data: Array<DataTableRowOptions<any>>;
  columns: Array<DataTableColumnOptions<P, CK>>;
  sortOrder: DataTableSortOrder<any>;
  handleSortOrderChange: (sortOrder: DataTableSortOrder<any>) => void;
  handleClickRow: (id: string) => void;
}

export const ListDataTable = ({
  title,
  description,
  emptyTableText,
  columns,
  data,
  sortOrder,
  handleSortOrderChange,
  handleClickRow,
}: ListDataTableProps) => {
  const { t } = useTranslation();

  return <>
    {title ? <CardTitle>{title}</CardTitle> : null}
    {description ? (
      <ListDataTableLeadParagraph>{description}</ListDataTableLeadParagraph>
    ) : null}
    <ListDataTableTableWrapper>
      <DataTable
        rows={data}
        columns={columns}
        sortOrder={sortOrder}
        emptyMessage={
          emptyTableText ?? t(MessageKeys.ListDataTableNOItemsLabel)
        }
        onSortOrderChange={handleSortOrderChange}
        noScroll={true}
        onRowClick={handleClickRow}
      />
    </ListDataTableTableWrapper>
  </>;
};
