import { createGlobalStyle, css } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  ${({ theme }) => css`
    * {
      box-sizing: border-box;
    }

    body {
      margin: 0;
      font-family: ${theme?.typography?.fontFamily ?? 'Roboto'};
      background-color: ${theme?.colors?.backdrop ??
      theme?.palette?.MainWhite ??
      '#fff'};
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 1.4rem;
    }

    body.no-select {
      user-select: none;
    }

    html {
      font-size: 62.5%;
    }

    button {
      font-family: ${theme?.typography?.fontFamily};
    }

    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;

      &.envelope {
        display: inline-block;
        padding: 0.2rem 0.4rem;
        border: 1px solid #ccc;
        background-color: #ddd;
        border-radius: 2px;
      }
    }

    button {
      cursor: pointer;
    }

    /* .important looks weird? It is needed due to the fact that global styles in styled-components dev mode are not
        inserted in the same order when doing a production build, so we need to increase specificity without
        resorting to !important
    */

    .emph {
      font-weight: 600;
    }

    .rotate-90,
    .rotate-90.important {
      transform: rotate(90deg);
    }

    .rotate-180,
    .rotate-180.important {
      transform: rotate(180deg);
    }

    .rotate-270,
    .rotate-270.important {
      transform: rotate(270deg);
    }

    .flex {
      display: flex;
    }

    .flex-end-horizontal,
    .flex-end-horizontal.important {
      justify-content: flex-end;
    }

    .align-end {
      align-items: flex-end;
    }

    .justify-end {
      justify-content: flex-end;
    }

    .space-between,
    .space-between.important {
      justify-content: space-between;
    }

    .leading-icon {
      margin-right: 1rem;
    }

    .overflow-hidden {
      overflow: hidden;
    }

    a {
      color: ${theme?.palette?.MainColor?.main ?? 'initial'};
      font-weight: 400;

      &:visited {
        color: ${theme?.palette?.MainColor?.light ?? 'initial'};
      }

      &:hover,
      &:focus,
      &:active {
        color: ${theme?.palette?.MainColor?.dark ?? 'initial'};
      }
    }

    @media print {
      a,
      a:visited {
        color: #0000cc;
        text-decoration: underline;
      }
    }
  `};
`;
