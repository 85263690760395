/* tslint:disable */
/* eslint-disable */
/**
 * Notification API
 * This API handles Notification API
 *
 * The version of the OpenAPI document: 0.0.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ListNotificationResponse,
    ListNotificationResponseFromJSON,
    ListNotificationResponseToJSON,
    MarkAsReadData,
    MarkAsReadDataFromJSON,
    MarkAsReadDataToJSON,
} from '../models';

export interface ListNotificationsRequest {
    application: string;
    offset?: string;
    unreadOnly?: boolean;
}

export interface MarkLotsOfNotificationsAsReadRequest {
    application: string;
    markAsReadData: MarkAsReadData;
}

export interface MarkNotificationsAsReadRequest {
    application: string;
    ids: Array<string>;
}

export interface MarkNotificationsAsUnreadRequest {
    application: string;
    ids: Array<string>;
}

/**
 * NotificationsApi - interface
 * 
 * @export
 * @interface NotificationsApiInterface
 */
export interface NotificationsApiInterface {
    /**
     * 
     * @summary List all notifications. Could be paginated
     * @param {string} application filter out notification from only this application.
     * @param {string} [offset] The id of the last event in the previous pagination
     * @param {boolean} [unreadOnly] If true, the query will return unread notifications only.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApiInterface
     */
    listNotificationsRaw(requestParameters: ListNotificationsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ListNotificationResponse>>;

    /**
     * List all notifications. Could be paginated
     */
    listNotifications(requestParameters: ListNotificationsRequest, initOverrides?: RequestInit): Promise<ListNotificationResponse>;

    /**
     * 
     * @param {string} application filter out notification from only this application.
     * @param {MarkAsReadData} markAsReadData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApiInterface
     */
    markLotsOfNotificationsAsReadRaw(requestParameters: MarkLotsOfNotificationsAsReadRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    markLotsOfNotificationsAsRead(requestParameters: MarkLotsOfNotificationsAsReadRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary Mark Notifications as read
     * @param {string} application filter out notification from only this application.
     * @param {Array<string>} ids 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApiInterface
     */
    markNotificationsAsReadRaw(requestParameters: MarkNotificationsAsReadRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * Mark Notifications as read
     */
    markNotificationsAsRead(requestParameters: MarkNotificationsAsReadRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary Mark Notifications as unread
     * @param {string} application filter out notification from only this application.
     * @param {Array<string>} ids 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApiInterface
     */
    markNotificationsAsUnreadRaw(requestParameters: MarkNotificationsAsUnreadRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * Mark Notifications as unread
     */
    markNotificationsAsUnread(requestParameters: MarkNotificationsAsUnreadRequest, initOverrides?: RequestInit): Promise<void>;

}

/**
 * 
 */
export class NotificationsApi extends runtime.BaseAPI implements NotificationsApiInterface {

    /**
     * List all notifications. Could be paginated
     */
    async listNotificationsRaw(requestParameters: ListNotificationsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ListNotificationResponse>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling listNotifications.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.unreadOnly !== undefined) {
            queryParameters['unreadOnly'] = requestParameters.unreadOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/notifications/{application}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListNotificationResponseFromJSON(jsonValue));
    }

    /**
     * List all notifications. Could be paginated
     */
    async listNotifications(requestParameters: ListNotificationsRequest, initOverrides?: RequestInit): Promise<ListNotificationResponse> {
        const response = await this.listNotificationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async markLotsOfNotificationsAsReadRaw(requestParameters: MarkLotsOfNotificationsAsReadRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling markLotsOfNotificationsAsRead.');
        }

        if (requestParameters.markAsReadData === null || requestParameters.markAsReadData === undefined) {
            throw new runtime.RequiredError('markAsReadData','Required parameter requestParameters.markAsReadData was null or undefined when calling markLotsOfNotificationsAsRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/notifications/{application}/read`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MarkAsReadDataToJSON(requestParameters.markAsReadData),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markLotsOfNotificationsAsRead(requestParameters: MarkLotsOfNotificationsAsReadRequest, initOverrides?: RequestInit): Promise<void> {
        await this.markLotsOfNotificationsAsReadRaw(requestParameters, initOverrides);
    }

    /**
     * Mark Notifications as read
     */
    async markNotificationsAsReadRaw(requestParameters: MarkNotificationsAsReadRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling markNotificationsAsRead.');
        }

        if (requestParameters.ids === null || requestParameters.ids === undefined) {
            throw new runtime.RequiredError('ids','Required parameter requestParameters.ids was null or undefined when calling markNotificationsAsRead.');
        }

        const queryParameters: any = {};

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/notifications/{application}/read`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mark Notifications as read
     */
    async markNotificationsAsRead(requestParameters: MarkNotificationsAsReadRequest, initOverrides?: RequestInit): Promise<void> {
        await this.markNotificationsAsReadRaw(requestParameters, initOverrides);
    }

    /**
     * Mark Notifications as unread
     */
    async markNotificationsAsUnreadRaw(requestParameters: MarkNotificationsAsUnreadRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling markNotificationsAsUnread.');
        }

        if (requestParameters.ids === null || requestParameters.ids === undefined) {
            throw new runtime.RequiredError('ids','Required parameter requestParameters.ids was null or undefined when calling markNotificationsAsUnread.');
        }

        const queryParameters: any = {};

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/notifications/{application}/read`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mark Notifications as unread
     */
    async markNotificationsAsUnread(requestParameters: MarkNotificationsAsUnreadRequest, initOverrides?: RequestInit): Promise<void> {
        await this.markNotificationsAsUnreadRaw(requestParameters, initOverrides);
    }

}
