/* eslint-disable @typescript-eslint/no-explicit-any */
import { SortingRule } from '@fcg-tech/regtech-datatable';
import {
  FilterValues,
  getDateFilterForInterval,
  RelativeDateInterval,
  StoredFilter,
} from '@fcg-tech/regtech-filter';
import { cleanUndefined, single } from '@fcg-tech/regtech-utils';
import update from 'immutability-helper';
import hash from 'object-hash';
import {
  AgreementListItem,
  AgreementFilterValues,
  AgreementOrderOptions,
  AgreementTableColumns,
  BooleanFilterEnum,
  DateFilter,
  SupplierOrderOptions,
  SupplierListItem,
  SupplierTableColumns,
  SupplierFilterValues,
  Country,
} from '../types';
import {
  arrayCompareFn,
  currencyAmountCompareFn,
  dateCompareFn,
  numberCompareFn,
  stringCompareFn,
} from './compareFns';
import { formatBoolean } from './formatters';
import { getSearchRows } from './searchUtils';

export const dateIntervals: Array<RelativeDateInterval> = [
  RelativeDateInterval.Overdue,
  RelativeDateInterval.LastMonth,
  RelativeDateInterval.ThisMonth,
  RelativeDateInterval.LastQuarter,
  RelativeDateInterval.ThisQuarter,
  RelativeDateInterval.LastYear,
  RelativeDateInterval.ThisYear,
];

export const isStoredFilterChanged = (
  storedFilter: StoredFilter<FilterValues>,
  filterValues: FilterValues,
) => {
  const storedFilterValues = cleanUndefined(
    update(storedFilter.filter, {
      $unset: ['isPinned'],
    }),
  );
  const cleanedFilterValues = cleanUndefined(
    update(filterValues, {
      $unset: ['isPinned'],
    }),
  );
  return hash(storedFilterValues) !== hash(cleanedFilterValues);
};

const isValidDate = (date: Date | undefined) => {
  return date !== undefined && date instanceof Date;
};

export const compareDateFilter = (
  date: Date | undefined,
  filterValue: DateFilter,
) => {
  if (!date) {
    return false;
  }

  const interval = filterValue.interval as RelativeDateInterval;
  const intervalRange = getDateFilterForInterval(interval);
  const from = intervalRange?.from ?? filterValue.from;
  const to = intervalRange?.to ?? filterValue.to;

  if (!isValidDate(from) && !isValidDate(to)) {
    return null;
  }
  if (!isValidDate(from)) {
    return date?.getTime() <= to?.getTime();
  }
  if (!isValidDate(to)) {
    return from?.getTime() <= date?.getTime();
  }
  return from?.getTime() <= date?.getTime() && date?.getTime() <= to?.getTime();
};

export const compareBooleanFilter = (
  value: boolean | undefined,
  filterValue: Array<BooleanFilterEnum>,
) => {
  switch (value) {
    case true:
      return filterValue?.includes(BooleanFilterEnum.Yes);
    case false:
      return filterValue?.includes(BooleanFilterEnum.No);
    case undefined:
      return filterValue?.includes(BooleanFilterEnum.NotSet);
    default:
      return false;
  }
};

export const compareSearchFilter = (
  value: string,
  filterValue: Array<string> | string,
) => {
  const searchRows = getSearchRows(single(filterValue) ?? '');
  if (searchRows.length) {
    return searchRows?.some((searchRow) => {
      const a = value?.trim()?.toLowerCase();
      const b = searchRow.text?.trim()?.replace(/"/g, '')?.toLowerCase();
      return a?.match(b);
    });
  }
  return true;
};

export const getAgreementOrderBy = (
  sortBy: SortingRule<AgreementListItem>,
): AgreementOrderOptions => {
  if (!sortBy || sortBy.desc === undefined) {
    return undefined;
  }

  switch (sortBy.id) {
    case AgreementTableColumns.Name:
      return !sortBy.desc
        ? AgreementOrderOptions.NameAsc
        : AgreementOrderOptions.NameDesc;
    case AgreementTableColumns.AgreementType:
      return !sortBy.desc
        ? AgreementOrderOptions.AgreementTypeAsc
        : AgreementOrderOptions.AgreementTypeDesc;
    case AgreementTableColumns.FunctionCategory:
      return !sortBy.desc
        ? AgreementOrderOptions.FunctionCategoryAsc
        : AgreementOrderOptions.FunctionCategoryDesc;
    case AgreementTableColumns.BriefDescription:
      return !sortBy.desc
        ? AgreementOrderOptions.BriefDescriptionAsc
        : AgreementOrderOptions.BriefDescriptionDesc;
    case AgreementTableColumns.ContractOwner:
      return !sortBy.desc
        ? AgreementOrderOptions.ContractOwnerAsc
        : AgreementOrderOptions.ContractOwnerDesc;
    case AgreementTableColumns.SupplierName:
      return !sortBy.desc
        ? AgreementOrderOptions.SupplierNameAsc
        : AgreementOrderOptions.SupplierNameDesc;
    case AgreementTableColumns.PartyToAgreement:
      return !sortBy.desc
        ? AgreementOrderOptions.PartyToAgreementAsc
        : AgreementOrderOptions.PartyToAgreementDesc;
    case AgreementTableColumns.InternalReference:
      return !sortBy.desc
        ? AgreementOrderOptions.InternalReferenceAsc
        : AgreementOrderOptions.InternalReferenceDesc;
    case AgreementTableColumns.Cabinet:
      return !sortBy.desc
        ? AgreementOrderOptions.CabinetAsc
        : AgreementOrderOptions.CabinetDesc;
    case AgreementTableColumns.Status:
      return !sortBy.desc
        ? AgreementOrderOptions.StatusAsc
        : AgreementOrderOptions.StatusDesc;
    case AgreementTableColumns.StartDate:
      return !sortBy.desc
        ? AgreementOrderOptions.StartDateAsc
        : AgreementOrderOptions.StartDateDesc;
    case AgreementTableColumns.EndDate:
      return !sortBy.desc
        ? AgreementOrderOptions.EndDateAsc
        : AgreementOrderOptions.EndDateDesc;
    case AgreementTableColumns.LatestReview:
      return !sortBy.desc
        ? AgreementOrderOptions.LatestReviewAsc
        : AgreementOrderOptions.LatestReviewDesc;
    case AgreementTableColumns.NextReview:
      return !sortBy.desc
        ? AgreementOrderOptions.NextReviewAsc
        : AgreementOrderOptions.NextReviewDesc;
    case AgreementTableColumns.ArchivingReference:
      return !sortBy.desc
        ? AgreementOrderOptions.ArchivingReferenceAsc
        : AgreementOrderOptions.ArchivingReferenceDesc;
    case AgreementTableColumns.CompetentAuthorityNotified:
      return !sortBy.desc
        ? AgreementOrderOptions.CompetentAuthorityNotifiedAsc
        : AgreementOrderOptions.CompetentAuthorityNotifiedDesc;
    case AgreementTableColumns.IsOutsourcingArrangement:
      return !sortBy.desc
        ? AgreementOrderOptions.IsOutsourcingArrangementAsc
        : AgreementOrderOptions.IsOutsourcingArrangementDesc;
    case AgreementTableColumns.IsCritical:
      return !sortBy.desc
        ? AgreementOrderOptions.IsCriticalAsc
        : AgreementOrderOptions.IsCriticalDesc;
    case AgreementTableColumns.NrOfComments:
      return !sortBy.desc
        ? AgreementOrderOptions.NrOfCommentsAsc
        : AgreementOrderOptions.NrOfCommentsDesc;
    case AgreementTableColumns.IsPersonalDataTransferred:
      return !sortBy.desc
        ? AgreementOrderOptions.IsPersonalDataTransferredAsc
        : AgreementOrderOptions.IsPersonalDataTransferredDesc;
    case AgreementTableColumns.IsPersonalDataProcessed:
      return !sortBy.desc
        ? AgreementOrderOptions.IsPersonalDataProcessedAsc
        : AgreementOrderOptions.IsPersonalDataProcessedDesc;
    case AgreementTableColumns.IsProvidedAsCloudService:
      return !sortBy.desc
        ? AgreementOrderOptions.IsProvidedAsCloudServiceAsc
        : AgreementOrderOptions.IsProvidedAsCloudServiceDesc;
    case AgreementTableColumns.TotalValueOfAgreement:
      return !sortBy.desc
        ? AgreementOrderOptions.TotalValueOfAgreementAsc
        : AgreementOrderOptions.TotalValueOfAgreementDesc;
    case AgreementTableColumns.Tags:
      return !sortBy.desc
        ? AgreementOrderOptions.TagsAsc
        : AgreementOrderOptions.TagsDesc;
  }
};

export const getAgreementSortBy = (
  orderBy: AgreementOrderOptions | Array<AgreementOrderOptions>,
): SortingRule<AgreementListItem> | undefined => {
  switch (single(orderBy)) {
    case AgreementOrderOptions.NameAsc:
      return { desc: false, id: AgreementTableColumns.Name };
    case AgreementOrderOptions.NameDesc:
      return { desc: true, id: AgreementTableColumns.Name };
    case AgreementOrderOptions.AgreementTypeAsc:
      return { desc: false, id: AgreementTableColumns.AgreementType };
    case AgreementOrderOptions.AgreementTypeDesc:
      return { desc: true, id: AgreementTableColumns.AgreementType };
    case AgreementOrderOptions.FunctionCategoryAsc:
      return { desc: false, id: AgreementTableColumns.FunctionCategory };
    case AgreementOrderOptions.FunctionCategoryDesc:
      return { desc: true, id: AgreementTableColumns.FunctionCategory };
    case AgreementOrderOptions.BriefDescriptionAsc:
      return { desc: false, id: AgreementTableColumns.BriefDescription };
    case AgreementOrderOptions.BriefDescriptionDesc:
      return { desc: true, id: AgreementTableColumns.BriefDescription };
    case AgreementOrderOptions.ContractOwnerAsc:
      return { desc: false, id: AgreementTableColumns.ContractOwner };
    case AgreementOrderOptions.ContractOwnerDesc:
      return { desc: true, id: AgreementTableColumns.ContractOwner };
    case AgreementOrderOptions.SupplierNameAsc:
      return { desc: false, id: AgreementTableColumns.SupplierName };
    case AgreementOrderOptions.SupplierNameDesc:
      return { desc: true, id: AgreementTableColumns.SupplierName };
    case AgreementOrderOptions.PartyToAgreementAsc:
      return { desc: false, id: AgreementTableColumns.PartyToAgreement };
    case AgreementOrderOptions.PartyToAgreementDesc:
      return { desc: true, id: AgreementTableColumns.PartyToAgreement };
    case AgreementOrderOptions.InternalReferenceAsc:
      return { desc: false, id: AgreementTableColumns.InternalReference };
    case AgreementOrderOptions.InternalReferenceDesc:
      return { desc: true, id: AgreementTableColumns.InternalReference };
    case AgreementOrderOptions.CabinetAsc:
      return { desc: false, id: AgreementTableColumns.Cabinet };
    case AgreementOrderOptions.CabinetDesc:
      return { desc: true, id: AgreementTableColumns.Cabinet };
    case AgreementOrderOptions.StatusAsc:
      return { desc: false, id: AgreementTableColumns.Status };
    case AgreementOrderOptions.StatusDesc:
      return { desc: true, id: AgreementTableColumns.Status };
    case AgreementOrderOptions.StartDateAsc:
      return { desc: false, id: AgreementTableColumns.StartDate };
    case AgreementOrderOptions.StartDateDesc:
      return { desc: true, id: AgreementTableColumns.StartDate };
    case AgreementOrderOptions.EndDateAsc:
      return { desc: false, id: AgreementTableColumns.EndDate };
    case AgreementOrderOptions.EndDateDesc:
      return { desc: true, id: AgreementTableColumns.EndDate };
    case AgreementOrderOptions.LatestReviewAsc:
      return { desc: false, id: AgreementTableColumns.LatestReview };
    case AgreementOrderOptions.LatestReviewDesc:
      return { desc: true, id: AgreementTableColumns.LatestReview };
    case AgreementOrderOptions.NextReviewAsc:
      return { desc: false, id: AgreementTableColumns.NextReview };
    case AgreementOrderOptions.NextReviewDesc:
      return { desc: true, id: AgreementTableColumns.NextReview };
    case AgreementOrderOptions.ArchivingReferenceAsc:
      return { desc: false, id: AgreementTableColumns.ArchivingReference };
    case AgreementOrderOptions.ArchivingReferenceDesc:
      return { desc: true, id: AgreementTableColumns.ArchivingReference };
    case AgreementOrderOptions.CompetentAuthorityNotifiedAsc:
      return {
        desc: false,
        id: AgreementTableColumns.CompetentAuthorityNotified,
      };
    case AgreementOrderOptions.CompetentAuthorityNotifiedDesc:
      return {
        desc: true,
        id: AgreementTableColumns.CompetentAuthorityNotified,
      };
    case AgreementOrderOptions.IsOutsourcingArrangementAsc:
      return {
        desc: false,
        id: AgreementTableColumns.IsOutsourcingArrangement,
      };
    case AgreementOrderOptions.IsOutsourcingArrangementDesc:
      return { desc: true, id: AgreementTableColumns.IsOutsourcingArrangement };
    case AgreementOrderOptions.IsCriticalAsc:
      return { desc: false, id: AgreementTableColumns.IsCritical };
    case AgreementOrderOptions.IsCriticalDesc:
      return { desc: true, id: AgreementTableColumns.IsCritical };
    case AgreementOrderOptions.NrOfCommentsAsc:
      return { desc: false, id: AgreementTableColumns.NrOfComments };
    case AgreementOrderOptions.NrOfCommentsDesc:
      return { desc: true, id: AgreementTableColumns.NrOfComments };
    case AgreementOrderOptions.IsPersonalDataTransferredAsc:
      return {
        desc: false,
        id: AgreementTableColumns.IsPersonalDataTransferred,
      };
    case AgreementOrderOptions.IsPersonalDataTransferredDesc:
      return {
        desc: true,
        id: AgreementTableColumns.IsPersonalDataTransferred,
      };
    case AgreementOrderOptions.IsPersonalDataProcessedAsc:
      return { desc: false, id: AgreementTableColumns.IsPersonalDataProcessed };
    case AgreementOrderOptions.IsPersonalDataProcessedDesc:
      return { desc: true, id: AgreementTableColumns.IsPersonalDataProcessed };
    case AgreementOrderOptions.IsProvidedAsCloudServiceAsc:
      return {
        desc: false,
        id: AgreementTableColumns.IsProvidedAsCloudService,
      };
    case AgreementOrderOptions.IsProvidedAsCloudServiceDesc:
      return { desc: true, id: AgreementTableColumns.IsProvidedAsCloudService };
    case AgreementOrderOptions.TotalValueOfAgreementAsc:
      return { desc: false, id: AgreementTableColumns.TotalValueOfAgreement };
    case AgreementOrderOptions.TotalValueOfAgreementDesc:
      return { desc: true, id: AgreementTableColumns.TotalValueOfAgreement };
    case AgreementOrderOptions.TagsAsc:
      return { desc: false, id: AgreementTableColumns.Tags };
    case AgreementOrderOptions.TagsDesc:
      return { desc: true, id: AgreementTableColumns.Tags };
  }
};

export const getSupplierOrderBy = (
  sortBy: SortingRule<SupplierListItem>,
): SupplierOrderOptions => {
  if (!sortBy || sortBy.desc === undefined) {
    return undefined;
  }

  switch (sortBy.id) {
    case SupplierTableColumns.Name:
      return !sortBy.desc
        ? SupplierOrderOptions.NameAsc
        : SupplierOrderOptions.NameDesc;
    case SupplierTableColumns.CountryOfRegistration:
      return !sortBy.desc
        ? SupplierOrderOptions.CountryOfRegistrationAsc
        : SupplierOrderOptions.CountryOfRegistrationDesc;
    case SupplierTableColumns.CorporateRegistrationNumber:
      return !sortBy.desc
        ? SupplierOrderOptions.CorporateRegistrationNumberAsc
        : SupplierOrderOptions.CorporateRegistrationNumberDesc;
  }
};

export const getSupplierSortBy = (
  orderBy: SupplierOrderOptions | Array<SupplierOrderOptions>,
): SortingRule<SupplierListItem> | undefined => {
  switch (single(orderBy)) {
    case SupplierOrderOptions.NameAsc:
      return { desc: false, id: SupplierTableColumns.Name };
    case SupplierOrderOptions.NameDesc:
      return { desc: true, id: SupplierTableColumns.Name };
    case SupplierOrderOptions.CountryOfRegistrationAsc:
      return { desc: false, id: SupplierTableColumns.CountryOfRegistration };
    case SupplierOrderOptions.CountryOfRegistrationDesc:
      return { desc: true, id: SupplierTableColumns.CountryOfRegistration };
    case SupplierOrderOptions.CorporateRegistrationNumberAsc:
      return {
        desc: false,
        id: SupplierTableColumns.CorporateRegistrationNumber,
      };
    case SupplierOrderOptions.CorporateRegistrationNumberDesc:
      return {
        desc: true,
        id: SupplierTableColumns.CorporateRegistrationNumber,
      };
  }
};

export const filterAgreements = (
  items: Array<AgreementListItem>,
  filter: AgreementFilterValues,
) => {
  let filtered = items;
  if (filter.name) {
    filtered = filtered.filter((item) =>
      compareSearchFilter(item.name, filter.name),
    );
  }
  if (filter.agreementType) {
    filtered = filtered.filter((item) =>
      filter.agreementType.includes(item.agreementType),
    );
  }
  if (filter.functionCategory) {
    filtered = filtered.filter((item) =>
      filter.functionCategory.includes(item.functionCategory),
    );
  }
  if (filter.contractOwner) {
    filtered = filtered.filter((item) =>
      filter.contractOwner.includes(item.contractOwner?.toLowerCase()),
    );
  }
  if (filter.supplierName) {
    filtered = filtered.filter((item) =>
      filter.supplierName.includes(item.supplierName),
    );
  }
  if (filter.partyToAgreement) {
    filtered = filtered.filter((item) =>
      filter.partyToAgreement.includes(item.partyToAgreement),
    );
  }
  if (filter.cabinet) {
    filtered = filtered.filter((item) => filter.cabinet.includes(item.cabinet));
  }
  if (filter.status) {
    filtered = filtered.filter((item) => filter.status.includes(item.status));
  }
  if (filter.startDate) {
    filtered = filtered?.filter((item) =>
      compareDateFilter(item.startDate, filter.startDate),
    );
  }
  if (filter.endDate) {
    filtered = filtered?.filter((item) =>
      compareDateFilter(item.endDate, filter.endDate),
    );
  }
  if (filter.latestReview) {
    filtered = filtered?.filter((item) =>
      compareDateFilter(item.latestReview, filter.latestReview),
    );
  }
  if (filter.nextReview) {
    filtered = filtered?.filter((item) =>
      compareDateFilter(item.nextReview, filter.nextReview),
    );
  }
  if (filter.competentAuthorityNotified) {
    filtered = filtered?.filter((item) =>
      compareBooleanFilter(
        item.competentAuthorityNotified,
        filter.competentAuthorityNotified,
      ),
    );
  }
  if (filter.isOutsourcingArrangement) {
    filtered = filtered?.filter((item) =>
      compareBooleanFilter(
        item.isOutsourcingArrangement,
        filter.isOutsourcingArrangement,
      ),
    );
  }
  if (filter.isCritical) {
    filtered = filtered?.filter((item) =>
      compareBooleanFilter(item.isCritical, filter.isCritical),
    );
  }
  if (filter.nrOfComments) {
    filtered = filtered?.filter((item) =>
      item.nrOfComments > 0 ? true : false,
    );
  }
  if (filter.isPersonalDataTransferred) {
    filtered = filtered?.filter((item) =>
      compareBooleanFilter(
        item.isPersonalDataTransferred,
        filter.isPersonalDataTransferred,
      ),
    );
  }
  if (filter.isPersonalDataProcessed) {
    filtered = filtered?.filter((item) =>
      compareBooleanFilter(
        item.isPersonalDataProcessed,
        filter.isPersonalDataProcessed,
      ),
    );
  }
  if (filter.isProvidedAsCloudService) {
    filtered = filtered?.filter((item) =>
      compareBooleanFilter(
        item.isProvidedAsCloudService,
        filter.isProvidedAsCloudService,
      ),
    );
  }
  if (filter.tags) {
    filtered = filtered?.filter((item) =>
      item?.tags?.some((item) => filter.tags.includes(item)),
    );
  }
  return filtered;
};

export const filterSuppliers = (
  suppliers: Array<SupplierListItem>,
  filter: SupplierFilterValues,
) => {
  let filtered = suppliers;
  if (filter.supplierName) {
    filtered = filtered.filter((item) =>
      compareSearchFilter(item.supplierName, filter.supplierName),
    );
  }
  if (filter.countryOfRegistration) {
    filtered = filtered.filter((item) =>
      filter.countryOfRegistration.includes(item.countryOfRegistration),
    );
  }
  if (filter.corporateRegistrationNumber) {
    filtered = filtered.filter((item) =>
      compareSearchFilter(
        item.corporateRegistrationNumber,
        filter.corporateRegistrationNumber,
      ),
    );
  }
  return filtered;
};

export const agreementSorter = (
  a: AgreementListItem,
  b: AgreementListItem,
  sortBy: SortingRule<AgreementListItem>,
) => {
  switch (sortBy?.id) {
    case AgreementTableColumns.Name:
      return stringCompareFn(a.name, b.name, !sortBy.desc);

    case AgreementTableColumns.AgreementType:
      return stringCompareFn(a.agreementType, b.agreementType, !sortBy.desc);
    case AgreementTableColumns.FunctionCategory:
      return stringCompareFn(
        a.functionCategory,
        b.functionCategory,
        !sortBy.desc,
      );
    case AgreementTableColumns.BriefDescription:
      return stringCompareFn(
        a.briefDescription,
        b.briefDescription,
        !sortBy.desc,
      );
    case AgreementTableColumns.ContractOwner:
      return stringCompareFn(a.contractOwner, b.contractOwner, !sortBy.desc);
    case AgreementTableColumns.SupplierName:
      return stringCompareFn(a.supplierName, b.supplierName, !sortBy.desc);
    case AgreementTableColumns.PartyToAgreement:
      return stringCompareFn(
        a.partyToAgreement,
        b.partyToAgreement,
        !sortBy.desc,
      );
    case AgreementTableColumns.InternalReference:
      return stringCompareFn(
        a.internalReference,
        b.internalReference,
        !sortBy.desc,
      );
    case AgreementTableColumns.Cabinet:
      return stringCompareFn(a.cabinet, b.cabinet, !sortBy.desc);
    case AgreementTableColumns.Status:
      return stringCompareFn(a.status, b.status, !sortBy.desc);
    case AgreementTableColumns.StartDate:
      return dateCompareFn(a.startDate, b.startDate, !sortBy.desc);
    case AgreementTableColumns.EndDate:
      return dateCompareFn(a.endDate, b.endDate, !sortBy.desc);
    case AgreementTableColumns.LatestReview:
      return dateCompareFn(a.latestReview, b.latestReview, !sortBy.desc);
    case AgreementTableColumns.NextReview:
      return dateCompareFn(a.nextReview, b.nextReview, !sortBy.desc);
    case AgreementTableColumns.ArchivingReference:
      return stringCompareFn(
        a.archivingReference,
        b.archivingReference,
        !sortBy.desc,
      );
    case AgreementTableColumns.CompetentAuthorityNotified:
      return stringCompareFn(
        formatBoolean(a.competentAuthorityNotified),
        formatBoolean(b.competentAuthorityNotified),
        !sortBy.desc,
      );
    case AgreementTableColumns.IsOutsourcingArrangement:
      return stringCompareFn(
        formatBoolean(a.isOutsourcingArrangement),
        formatBoolean(b.isOutsourcingArrangement),
        !sortBy.desc,
      );
    case AgreementTableColumns.IsCritical:
      return stringCompareFn(
        formatBoolean(a.isCritical),
        formatBoolean(b.isCritical),
        !sortBy.desc,
      );
    case AgreementTableColumns.NrOfComments:
      return numberCompareFn(a.nrOfComments, b.nrOfComments, !sortBy.desc);
    case AgreementTableColumns.IsPersonalDataTransferred:
      return stringCompareFn(
        formatBoolean(a.isPersonalDataTransferred),
        formatBoolean(b.isPersonalDataTransferred),
        !sortBy.desc,
      );
    case AgreementTableColumns.IsPersonalDataProcessed:
      return stringCompareFn(
        formatBoolean(a.isPersonalDataProcessed),
        formatBoolean(b.isPersonalDataProcessed),
        !sortBy.desc,
      );
    case AgreementTableColumns.IsProvidedAsCloudService:
      return stringCompareFn(
        formatBoolean(a.isProvidedAsCloudService),
        formatBoolean(b.isProvidedAsCloudService),
        !sortBy.desc,
      );
    case AgreementTableColumns.TotalValueOfAgreement:
      return currencyAmountCompareFn(
        a.totalValueOfAgreement,
        b.totalValueOfAgreement,
        !sortBy.desc,
      );
    case AgreementTableColumns.Tags:
      return arrayCompareFn(a.tags, b.tags, !sortBy.desc);
  }
};

export const supplierSorter = (
  a: SupplierListItem,
  b: SupplierListItem,
  sortBy: SortingRule<SupplierListItem>,
  // ugly hack to make the sorting work for countries. Should instead change
  // the type of field countryOfRegistration from string to Country.
  // This goes for all other country and region fields we have.
  countries?: Array<Country>,
) => {
  switch (sortBy?.id) {
    case SupplierTableColumns.Name:
      return stringCompareFn(a.supplierName, b.supplierName, !sortBy.desc);
    case SupplierTableColumns.CountryOfRegistration: {
      const aa = countries?.find(
        (c) => c.isoAlpha2 === a.countryOfRegistration,
      );
      const bb = countries?.find(
        (c) => c.isoAlpha2 === b.countryOfRegistration,
      );
      return stringCompareFn(aa?.name, bb?.name, !sortBy.desc);
    }
    case SupplierTableColumns.CorporateRegistrationNumber:
      return stringCompareFn(
        a.corporateRegistrationNumber,
        b.corporateRegistrationNumber,
        !sortBy.desc,
      );
  }
};
