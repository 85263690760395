import styled from 'styled-components';
import { FormButtonRow } from '../FormLayout';
import { Modal } from '../Modal';

export const ConfirmDialogWrapper = styled(Modal)`
  max-width: 50rem;
`;

export const ConfirmDialogButtonRow = styled(FormButtonRow)`
  margin-top: 2rem;
  align-items: center;
`;

export const ConfirmDialogDoNotShowAgainWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
