import styled, { css } from 'styled-components';

export const LabelText = styled.span`
  display: inline-block;
  margin-left: 1rem;
  ${({ theme }) => theme?.elements?.label?.inner}
`;

export const LabelWrapper = styled.label`
  ${({ theme }) => css`
    display: flex;
    flex: 1;
    width: 100%;
    align-items: center;
    font-family: ${theme.typography?.fontFamily ?? 'inherit'};
    font-size: 1.4rem;
    font-weight: 500;
    margin: 0;
    color: ${theme?.palette?.MainBlack ?? '#000'};

    &.column {
      flex-direction: column;
      align-items: stretch;

      & > ${LabelText} {
        margin-left: 0;
        margin-bottom: 0.5rem;
      }
    }

    ${theme?.elements?.label?.wrapper}
  `}
`;
