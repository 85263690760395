export const getPages = (
  currentPage: number,
  totalPages: number,
  delta = 2,
) => {
  const current = currentPage;
  const last = totalPages;
  const left = current - delta;
  const right = current + delta + 1;
  const range = [];
  const rangeWithDots: Array<number | null> = [];
  let l: number;

  for (let i = 1; i <= last; i++) {
    if (i === 1 || i === last || (i >= left && i < right)) {
      range.push(i);
    }
  }

  for (const i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (i - l !== 1) {
        rangeWithDots.push(null);
      }
    }
    rangeWithDots.push(i);
    l = i;
  }

  return rangeWithDots;
};
