/* eslint-disable no-nested-ternary */
import { BooleanDecision as DefaultBooleanDecision } from '@fcg-tech/regtech-components';
import { FieldInputProps, FormikHelpers } from 'formik';
import React, { FunctionComponent } from 'react';

interface SubComponents {
  FieldLabel: typeof DefaultBooleanDecision.FieldLabel;
  FieldContainer: typeof DefaultBooleanDecision.FieldContainer;
}

export interface BooleanDecisionProps
  extends React.ComponentPropsWithoutRef<typeof DefaultBooleanDecision> {
  field: FieldInputProps<boolean>;
  form: FormikHelpers<boolean>;
}

export const BooleanDecision: FunctionComponent<BooleanDecisionProps> &
  SubComponents = ({ field, form, ...props }) => {
  const handleChange = React.useCallback(
    (event) => {
      if (event.target.checked) {
        let value = event.target.value === 'true';
        if (event.target.value === 'notRequired') {
          // eslint-disable-next-line prefer-destructuring
          value = event.target.value;
        }
        form.setFieldValue(field.name, value);
      }
    },
    [field.name, form],
  );

  const handleClear = React.useCallback(() => {
    form.setFieldValue(field.name, null);
  }, [field.name, form]);

  return (
    <DefaultBooleanDecision
      {...props}
      value={field.value}
      onChange={handleChange}
      onClear={handleClear}
    />
  );
};

BooleanDecision.FieldLabel = DefaultBooleanDecision.FieldLabel;
BooleanDecision.FieldContainer = DefaultBooleanDecision.FieldContainer;
