import React from 'react';
import { useAsync } from 'react-async';
import { useMatch } from 'react-router-dom';
import { Loader } from '@fcg-tech/regtech-components';
import { routes } from '../../routes';
import { loadGroups } from '../../api';
import { ErrorMessage } from '../../components/ErrorBoundary';
import { GroupsPage } from './components/GroupsPage';

export const GroupsContainer = () => {
  const match = useMatch(routes.groups);
  const { tenantId } = match.params;

  const groupsReq = useAsync({
    promiseFn: loadGroups,
    tenantId,
  });

  if (groupsReq.isLoading) {
    return <Loader message="Loading groups" />;
  }

  if (groupsReq.error) {
    return <ErrorMessage error={groupsReq.error} />;
  }

  return <GroupsPage groups={groupsReq.data.result} />;
};
