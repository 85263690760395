import {
  MutableRefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ArrowDirection, LegacyTheme } from '@fcg-tech/regtech-types';
import { useTheme } from 'styled-components';
import { array, debounce } from './misc';
import { useSWRConfig } from 'swr';
import { matchPath, useLocation } from 'react-router-dom';

export type ArrowDirectionOld = 'up' | 'down' | 'left' | 'right';

export const useFocus = <T extends { focus: () => void }>() => {
  const ref = useRef<T>();
  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  return ref;
};

export const useFocusEvents = <T extends HTMLElement>(
  ref: MutableRefObject<T>,
  defaultOnFocus?: (event: React.FocusEvent<T>) => void,
  defaultOnBlur?: (event: React.FocusEvent<T>) => void,
): [
  hasFocus: boolean,
  onFocus: (event: React.FocusEvent<T>) => void,
  onBlur: (event: React.FocusEvent<T>) => void,
] => {
  const [hasFocus, setHasFocus] = useState(false);
  const onFocus = useCallback(
    (event: React.FocusEvent<T>) => {
      setHasFocus(
        (document.activeElement as unknown) === (ref?.current as unknown),
      );
      defaultOnFocus?.(event);
    },
    [defaultOnFocus, ref],
  );

  const onBlur = useCallback(
    (event: React.FocusEvent<T>) => {
      setHasFocus(
        (document.activeElement as unknown) === (ref?.current as unknown),
      );
      defaultOnBlur?.(event);
    },
    [defaultOnBlur, ref],
  );

  return [hasFocus, onFocus, onBlur];
};

export const isInputActive = (event: KeyboardEvent) =>
  document.activeElement?.tagName?.toLowerCase() === 'input' ||
  document.activeElement?.tagName?.toLowerCase() === 'textarea' ||
  document.activeElement?.getAttribute('contenteditable') ||
  (event.target as HTMLElement)?.tagName?.toLowerCase() === 'input' ||
  (event.target as HTMLElement)?.tagName?.toLowerCase() === 'textarea';

type HotkeyModifier = 'shiftKey' | 'ctrlKey' | 'metaKey' | 'altKey';
type HotkeyCallback = (
  hotkey: string,
  modifiers: Array<HotkeyModifier>,
) => void | boolean;

export type HotkeyOptions = {
  /**
   * Callback for this hotkey combination. If the callback returns false, and only false, hotkey listeners with lower priority will not be invoked
   */
  callback: HotkeyCallback;
  hotkeys?: string | Array<string>;
  modifier?: HotkeyModifier | Array<HotkeyModifier>;
  allowInputEvents?: boolean;
  preventDefault?: boolean;
  stopPropagation?: boolean;
  priority?: number;
};

const hotkeyListeners: Array<HotkeyOptions> = [];

let hotkeyMainListener: (event: KeyboardEvent) => void;

export const useHotkeys = (inOptions: HotkeyOptions) => {
  // Don't trust inOptions to be memoized, so create a memoized version
  const {
    callback,
    hotkeys,
    allowInputEvents,
    modifier,
    preventDefault,
    priority,
    stopPropagation,
  } = inOptions;
  const options = useMemo<HotkeyOptions>(
    () => ({
      callback,
      hotkeys,
      allowInputEvents,
      modifier,
      preventDefault,
      priority,
      stopPropagation,
    }),
    [
      allowInputEvents,
      callback,
      hotkeys,
      modifier,
      preventDefault,
      priority,
      stopPropagation,
    ],
  );
  useEffect(() => {
    if (!hotkeyMainListener) {
      hotkeyMainListener = (event: KeyboardEvent) => {
        const hotkey = event.key?.toLowerCase();
        if (!hotkey) {
          return;
        }
        const modifiers: Array<HotkeyModifier> = [
          event.ctrlKey && 'ctrlKey',
          event.metaKey && 'metaKey',
          event.shiftKey && 'shiftKey',
          event.altKey && 'altKey',
        ].filter(Boolean) as Array<HotkeyModifier>;

        const listeners = hotkeyListeners
          .filter((opt) =>
            opt.hotkeys
              ? array(opt.hotkeys)?.includes(hotkey)
              : array(opt.modifier).every((mod) => modifiers.includes(mod)),
          )
          .filter((opt) => {
            return (
              (opt.modifier &&
                (Array.isArray(opt.modifier)
                  ? opt.modifier.every((m) => event[m])
                  : event[opt.modifier])) ||
              (!opt.modifier && modifiers.length === 0)
            );
          })
          .filter((opt) => opt.allowInputEvents || !isInputActive(event));

        for (let i = 0; i < listeners.length; i++) {
          const opt = listeners[i];
          if (opt.preventDefault) {
            event.preventDefault();
          }
          if (opt.stopPropagation) {
            event.stopPropagation();
          }
          const result = opt?.callback?.(event.key, modifiers);
          if (result === false) {
            break;
          }
        }
      };
      document.addEventListener('keydown', hotkeyMainListener);
    }
    if (options) {
      hotkeyListeners.push({
        priority: 1,
        ...options,
        hotkeys: array(options.hotkeys)?.map((c) => c.toLowerCase()) ?? [],
      });
      hotkeyListeners.sort((a, b) => (b.priority ?? 1) - (a.priority ?? 1));

      return () => {
        hotkeyListeners.splice(
          hotkeyListeners.findIndex((i) => i.callback === options.callback),
          1,
        );
      };
    }

    return undefined;
  }, [options]);
};

type EscapeCallback = () => void | boolean;

/**
 * @deprecated
 */
export const useEscapeKeyOld = (
  callback?: EscapeCallback,
  priority?: number,
) => {
  useHotkeys({
    hotkeys: 'Escape',
    callback: callback as HotkeyCallback,
    priority,
  });
};

export const useEscapeKey = (
  options: Omit<HotkeyOptions, 'hotkeys'> & {
    callback?: (direction: ArrowDirectionOld) => void | boolean;
  },
) => {
  useHotkeys({
    hotkeys: 'Escape',
    ...options,
  });
};

/**
 * @deprecated
 */
export const useReturnKeyOld = (callback?: () => void) => {
  useHotkeys({
    hotkeys: 'Enter',
    callback: callback as HotkeyCallback,
    stopPropagation: true,
  });
};

/**
 * @deprecated
 */
export const useArrowKeysOld = (
  callback?: (direction: ArrowDirectionOld) => void,
  allowInputEvents = false,
  preventDefault = false,
) => {
  useHotkeys({
    callback: callback as HotkeyCallback,
    hotkeys: ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'],
    allowInputEvents,
    preventDefault,
  });
};

export const useArrowKeys = (
  options: Omit<HotkeyOptions, 'callback' | 'hotkeys'> & {
    callback?: (direction: ArrowDirection) => void | boolean;
  },
) => {
  useHotkeys({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: options.callback as any,
    hotkeys: ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'],
    ...options,
  });
};

export type HotkeyOptionsOld = {
  modifier?: HotkeyModifier | Array<HotkeyModifier>;
  allowInputEvents?: boolean;
  preventDefault?: boolean;
};

/**
 * @deprecated
 */
export const useHotkeysOld = (
  hotkeys: string | Array<string>,
  callback: (key: string) => void,
  options: HotkeyOptionsOld = {},
) => {
  useHotkeys({
    hotkeys,
    callback,
    ...options,
  });
};

export const useIsMountedRef = () => {
  const isMountedRef = useRef<boolean>(false);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  return isMountedRef;
};

export const useWindowResize = (callback?: () => void, debounceMS?: number) => {
  useEffect(() => {
    let listener = () => {
      callback?.();
    };
    if (debounceMS) {
      listener = debounce(listener, debounceMS);
    }
    window.addEventListener('resize', listener);

    return () => window.removeEventListener('resize', listener);
  }, [callback, debounceMS]);
};

export const useDevice = () => {
  const theme = useTheme();
  const mediaQuery = `(max-width: ${
    (theme as LegacyTheme)?.breakpoints?.mobile ?? 730
  }px)`;

  const [isMobile, setIsMobile] = useState(
    window.matchMedia(mediaQuery)?.matches ?? false,
  );
  useWindowResize(() => {
    setIsMobile(window.matchMedia(mediaQuery)?.matches ?? false);
  });

  return { isMobile };
};

export const useMatchMutate = () => {
  const { mutate, cache } = useSWRConfig();
  return (matcher: RegExp, ...args: Array<unknown>) => {
    if (!(cache instanceof Map)) {
      throw new Error(
        'matchMutate requires the cache provider to be a Map instance',
      );
    }

    const keys = [];

    for (const key of cache.keys()) {
      if (matcher.test(key)) {
        keys.push(key);
      }
    }

    const mutations = keys.map((key) => mutate(key, ...args));
    return Promise.all(mutations);
  };
};

export const useRouteMatch = (
  patterns: string | Array<string>,
): Record<string, string> | null => {
  const { pathname } = useLocation();
  return useMemo(() => {
    let result: Record<string, string | undefined> | null = null;
    array(patterns)?.forEach((pattern) => {
      const match = matchPath(pattern, pathname);
      if (match) {
        result = result ?? {};
        result = {
          ...result,
          ...match.params,
        };
      }
    });

    return result;
  }, [pathname, patterns]);
};

export const useMouseHover = (
  options: { leaveTimeout?: number; disable?: boolean } = {},
) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const disabledRef = useRef(options.disable ?? false);
  disabledRef.current = options.disable ?? false;
  const onMouseEnter = useCallback(() => {
    if (!disabledRef.current) {
      setIsMouseOver(true);
    }
  }, []);
  const onMouseLeave = useCallback(
    () =>
      setTimeout(() => {
        if (!disabledRef.current) {
          setIsMouseOver(false);
        }
      }, options.leaveTimeout ?? 0),
    [options.leaveTimeout],
  );
  const onBlur = useCallback(
    () => setTimeout(() => setIsMouseOver(false), options.leaveTimeout ?? 0),
    [options.leaveTimeout],
  );
  const onMouseMove = useCallback(() => {
    if (!disabledRef.current) {
      setIsMouseOver(true);
    }
  }, []);

  return useMemo(
    () => ({ isMouseOver, onMouseEnter, onMouseLeave, onBlur, onMouseMove }),
    [isMouseOver, onBlur, onMouseEnter, onMouseLeave, onMouseMove],
  );
};

export const useChangeDetector = (values: Array<unknown>, key?: string) => {
  const ref = useRef(values);

  useEffect(() => {
    const changedIndexes: Array<number> = [];
    ref.current.forEach((value, index) => {
      if (value !== values[index]) {
        changedIndexes.push(index);
      }
    });
    console.log(
      `Changed indexes: ${key ? `(${key})` : ''} ${changedIndexes.join(', ')}`,
    );
    ref.current = values;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);
};

export const useMountDetector = (key: string) => {
  useEffect(() => {
    console.log(`!!!!   Mounted: ${key}   !!!!!`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
