import { EmptyPropsWithChildren } from '@fcg-tech/regtech-types';
import { single } from '@fcg-tech/regtech-utils';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { parse } from 'querystring';
import { useAuthContext } from '../../authContext';
import { endLoginFlow, getAccessToken } from '../../authFlow';

enum AuthState {
  Pending,
  Initialized,
  Authenticated,
  NotAuthenticated,
}

export const SingleTenantAuthCallback: FunctionComponent<EmptyPropsWithChildren> = ({ children }) => {
  const { reAuthenticate, setIsAuthenticated } = useAuthContext();
  const navigate = useNavigate();

  const hash = window.location.hash;
  const parameters = useMemo(() => parse(hash.replace(/^#/, '')), [hash]);
  const stateKey = single(parameters?.state);
  const idToken = single(parameters?.id_token);

  const state = window.localStorage.getItem(stateKey as string);

  const [authState, setAuthState] = useState(
    state ? AuthState.Initialized : AuthState.Pending,
  );

  useEffect(() => {
    switch (authState) {
      case AuthState.Pending: {
        const accessToken = getAccessToken();
        if (accessToken) {
          // Optimistically set user as authenticated if an accessToken is detected. This might cuase the app to invoke and endpoint ending with a failure in which case it should start the login flow again
          setIsAuthenticated?.(true);
          setAuthState(AuthState.Authenticated);
        } else {
          reAuthenticate();
        }
        break;
      }

      case AuthState.Initialized: {
        if (stateKey && state) {
          window.localStorage.removeItem(stateKey);
          if (idToken) {
            endLoginFlow(idToken);
            setIsAuthenticated?.(true);
            setAuthState(AuthState.Authenticated);
            const { returnUrl } = JSON.parse(state);
            setTimeout(
              () =>
                navigate(
                  returnUrl && returnUrl !== '/auth/signout' ? returnUrl : '/',
                ),
              1,
            );
          } else {
            setAuthState(AuthState.NotAuthenticated);
            setIsAuthenticated?.(false);
          }
        } else {
          // eslint-disable-next-line no-console
          console.error(
            'Could not find the referenced state, this is an invalid request',
          );
          setAuthState(AuthState.NotAuthenticated);
          setIsAuthenticated?.(false);
          navigate('/', { replace: true });
        }
        break;
      }

      default:
        // noop
        break;
    }
  }, [
    idToken,
    stateKey,
    state,
    authState,
    navigate,
    reAuthenticate,
    setIsAuthenticated,
  ]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return authState === AuthState.Authenticated ? <>{children}</> : null;
};
