import { SpinningLoadingIcon } from '@fcg-tech/regtech-components';
import { DataTableColumn, Row, SortingRule } from '@fcg-tech/regtech-datatable';
import { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import { SupplierListItem } from '../../types';
import { TableLoadingWrapper, TableNoContent } from '../tables';
import {
  SupplierTableDataTable,
  SupplierTableWrapper,
} from './SupplierTable.styles';

interface SupplierTableProps {
  suppliers?: Array<SupplierListItem>;
  columns?: Array<DataTableColumn<SupplierListItem>>;
  loading?: boolean;
  sortBy?: SortingRule<SupplierListItem>;
  onSortByChange?: (sortBy: SortingRule<SupplierListItem>) => void;
  onRowClick?: (supplierId: string) => void;
}

const getRowId = (originalRow: SupplierListItem) => originalRow.metadata.id;

export const SupplierTable: FunctionComponent<SupplierTableProps> = ({
  suppliers,
  columns,
  loading,
  sortBy,
  onSortByChange,
  onRowClick,
}) => {
  const { t } = useTranslation();

  const handleRowClick = useCallback(
    (row: Row<SupplierListItem>) => {
      onRowClick?.(row.original.metadata.id);
    },
    [onRowClick],
  );

  return (
    <SupplierTableWrapper>
      <SupplierTableDataTable
        columns={columns}
        data={suppliers}
        fullHeight
        getRowId={getRowId}
        sortBy={sortBy}
        onRowClick={handleRowClick}
        onSortByChange={onSortByChange}
      />
      {suppliers?.length === 0 ? (
        <TableNoContent>{t(MessageKeys.SuppliersNoItems)}</TableNoContent>
      ) : null}
      {loading ? (
        <TableLoadingWrapper>
          <SpinningLoadingIcon size="80" />
        </TableLoadingWrapper>
      ) : null}
    </SupplierTableWrapper>
  );
};
