import React from 'react';
import { useAsync } from 'react-async';
import { useNavigate, useMatch } from 'react-router-dom';
import { EditProvider } from '@fcg-tech/regtech-components';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { routes } from '../../routes';
import { createUser } from '../../api';
import { NewUserPage } from './components';

export const NewUserContainer = () => {
  const navigate = useNavigate();
  const match = useMatch(routes.newUser);
  const { tenantId } = match.params;

  const [isSaving, setIsSaving] = React.useState(false);

  const createUserProxy = async (args) => {
    setIsSaving(true);
    try {
      await createUser(...args);
      navigate(constructUrl(routes.users, { tenantId }));
    } catch (err) {
      setIsSaving(false);
      throw err;
    }
  };

  const createUserReq = useAsync({ deferFn: createUserProxy });

  const handleSave = React.useCallback(
    (user) => createUserReq.run({ tenantId, user }),
    [createUserReq, tenantId],
  );

  const handleCancel = React.useCallback(() => {
    navigate(constructUrl(routes.users, { tenantId }));
  }, [navigate, tenantId]);

  if (createUserReq.error) {
    console.log('error creating user');
  }

  return (
    <EditProvider value>
      <NewUserPage
        isSaving={isSaving}
        onSave={handleSave}
        onCancel={handleCancel}
      />
    </EditProvider>
  );
};
