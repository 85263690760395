import React, { FunctionComponent, useCallback } from 'react';
import { IconButton } from '../Button';
import { TrashIcon } from '../Icons';
import { DynamicListItemRemoveIconButton } from './DynamicList.styles';

interface DynamicListItemRemoveButtonProps {
  index: number;
  onClick: (index: number) => void;
}

export const DynamicListItemRemoveButton: FunctionComponent<DynamicListItemRemoveButtonProps> =
  ({ index, onClick }) => {
    const handleClick = useCallback(() => onClick?.(index), [onClick, index]);
    return (
      <DynamicListItemRemoveIconButton onClick={handleClick}>
        <TrashIcon size="16" />
      </DynamicListItemRemoveIconButton>
    );
  };
