import { Select as DefaultSelect } from '@fcg-tech/regtech-components';
import { FieldInputProps, FormikHelpers } from 'formik';
import React from 'react';

interface SelectProps
  extends React.ComponentPropsWithRef<typeof DefaultSelect> {
  form?: FormikHelpers<string | number>;
  field?: FieldInputProps<string | number>;
}

export const Select = ({ field, form, onChange, ...props }: SelectProps) => {
  const handleChange = React.useCallback(
    (value: string | number) => {
      if (field && form) {
        // eslint-disable-next-line no-unused-expressions
        form.setFieldValue(field.name, value);
      } else {
        onChange(value);
      }
    },
    [field, form, onChange],
  );

  return (
    <DefaultSelect {...props} {...field} onChange={onChange || handleChange} />
  );
};
