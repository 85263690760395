import styled from 'styled-components';

export const AttachmentTableRowDownloadLink = styled.a`
  width: 0;
  height: 0;
  overflow: hidden;
`;

export const AttachmentTableNoContent = styled.div`
  font-size: 1.6rem;
  font-weight: 400;
`;
