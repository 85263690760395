import { format } from 'date-fns';
import { Stringifiable, stringify, StringifyOptions } from 'query-string';
import { generatePath } from 'react-router-dom';
import { getAccessToken } from './auth';

type HaveRaw = { raw: Response };

export type ConstructUrlParams<Key extends string = string> = {
  readonly [key in Key]: string | undefined;
};

export const POST = {
  method: 'POST',
  headers: {
    'content-type': 'application/json',
  },
};

export const PUT = {
  method: 'PUT',
  headers: {
    'content-type': 'application/json',
  },
};

export const DELETE = {
  method: 'DELETE',
};

export const PATCH = {
  method: 'PATCH',
  headers: {
    'content-type': 'application/json',
  },
};

// The OpenAPI generator is STUPID because it assumes any dates sent in are in UTC, runs toISOString() on them,
// and then substr(0,10), which will almost always result in the wrong date (yyyy-MM-dd) being sent. So we need to trick it
export const formatApiDate = <T = Date>(date: Date): T | undefined => {
  if (date) {
    const str = format(date, 'yyyy-MM-dd');
    return {
      toString: () => str,
      toISOString: () => str,
      isApiDate: true,
    } as unknown as T;
  }

  return undefined;
};

export const formatQueryDate = (date: Date): string | undefined =>
  date ? format(date, 'yyyy-MM-dd') : undefined;

export const parseQueryDate = (dateStr: string) =>
  dateStr ? new Date(`${dateStr}T00:00:00Z`) : undefined;

export const isQueryDate = (dateStr: string) =>
  Boolean(dateStr?.match(/^\d{4}-\d{2}-\d{2}$/));

export const fetchAuthenticated = async (
  url: RequestInfo,
  opts: RequestInit = {},
  accessToken?: string,
) => {
  const options: RequestInit & { headers: Headers } = {
    mode: 'cors',
    method: 'GET',
    ...opts,
    headers: new Headers(opts.headers ?? {}),
  };
  const finalAccessToken = accessToken ?? getAccessToken();
  if (!finalAccessToken) {
    throw new Error('No access token set');
  }

  options.headers.set('Authorization', `Bearer ${finalAccessToken}`);

  return fetch(url, options);
};

export const baseFetch = async (url: RequestInfo, opts: RequestInit = {}) => {
  const options: RequestInit & { headers: Headers } = {
    mode: 'cors',
    method: 'GET',
    ...opts,
    headers: new Headers(opts.headers ?? {}),
  };
  return fetch(url, options);
};

export const checkResponse = (response: Response) => {
  if (!response.ok) {
    return false;
  }
  return true;
};

export const constructUrl = <
  Q = Record<string, Stringifiable | Stringifiable[]>,
>(
  url: string,
  args?: ConstructUrlParams,
  query?: Q,
  queryOptions: StringifyOptions = {},
): string => {
  const argsCopy = { ...(args ?? {}) };

  let finalUrl = generatePath(url, argsCopy);

  if (query && Object.keys(query).length) {
    finalUrl = `${finalUrl}?${stringify(
      query as unknown as Record<string, Stringifiable>,
      { arrayFormat: 'comma', ...queryOptions },
    )}`;
  }

  return finalUrl;
};

export const constructFullUrl = <
  Q = Record<string, Stringifiable | Stringifiable[]>,
>(
  url: string,
  args?: ConstructUrlParams,
  query?: Q,
  queryOptions: StringifyOptions = {},
): string => {
  const urlPart = constructUrl(url, args, query, queryOptions);
  return `${window.location.origin}${urlPart}`;
};

export const constructCatchAllUrl = (url: string) => {
  const path = url.split('?')[0];
  if (path.endsWith('/')) {
    return `${path}*`;
  } else if (path.endsWith('*')) {
    return path;
  }
  return `${path}/*`;
};

type OperationRequest = {
  requestBody: {
    'application/json': unknown;
  };
};

export type RequestBody<O extends OperationRequest> =
  O['requestBody']['application/json'];

type Operation200 = {
  responses: {
    '200': {
      'application/json': unknown;
    };
  };
};

type Operation201 = {
  responses: {
    '201': {
      'application/json': unknown;
    };
  };
};

export type R20X<T> = T extends Operation200
  ? T['responses']['200']['application/json']
  : T extends Operation201
  ? T['responses']['201']['application/json']
  : never;

export type Items<R extends { items: Array<unknown> }> = R['items'];

export const getJSONResponse = async <T>(response: Response) => {
  return (await response.json()) as R20X<T>;
};

export const postBody = <B = unknown>(
  data: B extends OperationRequest ? RequestBody<B> : B,
) => ({
  ...POST,
  body: JSON.stringify(data),
});

export const putBody = <B = unknown>(
  data: B extends OperationRequest ? RequestBody<B> : B,
) => ({
  ...PUT,
  body: JSON.stringify(data),
});

export const patchBody = <B = unknown>(
  data: B extends OperationRequest ? RequestBody<B> : B,
) => ({
  ...PATCH,
  body: JSON.stringify(data),
});

export const getResponse = (err: unknown): Response | null => {
  if (err instanceof Response) {
    return err;
  }
  if (Reflect.has(err as HaveRaw, 'raw')) {
    return (err as HaveRaw).raw;
  }
  return null;
};

export const checkAuthError = (err: unknown) => {
  const response = getResponse(err);
  if (response?.status === 401) {
    window.document.location.href = '/login';
    return true;
  }
  return false;
};
