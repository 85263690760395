import React from 'react';
import { FormikConsumer, getIn } from 'formik';
import { LogLevel } from '../../types';
import { FieldErrorMessage } from './FieldError.styles';

type FieldErrorType = {
  level: LogLevel.Warning | LogLevel.Error;
  message: string;
};

interface FieldErrorProps {
  name: string;
}

export const FieldError = ({ name }: FieldErrorProps) => (
  <FormikConsumer>
    {(formik) => {
      const error = getIn(formik.errors, name);
      if (error) {
        if (typeof error === 'string') {
          return <FieldErrorMessage>{error}</FieldErrorMessage>;
        }
        if (
          typeof error === 'object' &&
          'level' in error &&
          'message' in error
        ) {
          const { level, message } = error as unknown as FieldErrorType;
          return <FieldErrorMessage level={level}>{message}</FieldErrorMessage>;
        }
      }
      return null;
    }}
  </FormikConsumer>
);
