import { useTranslation } from 'react-i18next';
import {
  DataTable,
  DataTableColumnOptions,
  DataTableRowOptions,
  SpinningLoadingIcon,
} from '@fcg-tech/regtech-components';
import { FunctionComponent, useCallback, useMemo } from 'react';
import { MessageKeys } from '../../translations/messageKeys';
import { Metadata } from '../../types';
import {
  VersionHistoryTableLoadingWrapper,
  VersionHistoryTableNoContent,
} from './VersionHistoryTable.styles';
import {
  ColumnKeys,
  VersionHistoryTableRow,
  RowData,
} from './VersionHistoryTableRow';

export interface VersionHistoryTableProps {
  versions: Array<Metadata>;
  loading?: boolean;
  onRowClick?: (id: string, version: string) => void;
}

export const VersionHistoryTable: FunctionComponent<
  VersionHistoryTableProps
> = ({ versions, loading, onRowClick }) => {
  const { t } = useTranslation();

  const columns = useMemo<
    Array<DataTableColumnOptions<RowData, ColumnKeys>>
  >(() => {
    return [
      {
        columnKey: 'version',
        label: t(MessageKeys.VersionHistoryTableVersionLabel),
        resizable: true,
        sortable: true,
        minWidth: 140,
        initialSortAscending: true,
      },
      {
        columnKey: 'modified',
        label: t(MessageKeys.VersionHistoryTableModifiedLabel),
        resizable: true,
        sortable: true,
        minWidth: 140,
        initialSortAscending: false,
      },
      {
        columnKey: 'modifiedBy',
        label: t(MessageKeys.VersionHistoryTableModifiedByLabel),
        resizable: true,
        sortable: true,
        minWidth: 140,
        initialSortAscending: false,
      },
    ];
  }, [t]);

  const dataRows = useMemo<Array<DataTableRowOptions<RowData>>>(() => {
    return versions
      ?.map((value) => ({
        id: value.id,
        data: {
          version: value.version,
          modified: value.modified,
          modifiedBy: value.modifiedBy,
        },
      }))
      ?.sort((a, b) => Number(b.data.version) - Number(a.data.version));
  }, [versions]);

  const handleRowClick = useCallback(
    (id, version) => onRowClick(id, version),
    [onRowClick],
  );

  return <>
    <DataTable rows={dataRows} columns={columns} stickyHeader>
      {(row) => (
        <VersionHistoryTableRow
          key={`${row.id}-${row.data.version}`}
          row={row}
          onClick={handleRowClick}
        />
      )}
    </DataTable>
    {dataRows?.length === 0 ? (
      <VersionHistoryTableNoContent>
        {t(MessageKeys.VersionHistoryTableNoItems)}
      </VersionHistoryTableNoContent>
    ) : null}
    {loading ? (
      <VersionHistoryTableLoadingWrapper>
        <SpinningLoadingIcon size="80" />
      </VersionHistoryTableLoadingWrapper>
    ) : null}
  </>;
};
