import {
  addDays,
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  isSameDay,
  isSameMonth,
  Locale,
  startOfMonth,
} from 'date-fns';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
  PropsWithChildren,
} from 'react';
import { useToggle } from '../../miscHooks';
import { CalendarEventData, CalendarEventType } from '../../types/calendar';
import {
  CalendarInnerWrapper,
  CalendarWeek,
  CalendarWrapper,
} from './Calendar.styles';
import { CalendarAgenda } from './CalendarAgenda';
import { CalendarDay } from './CalendarDay';
import { CalendarNavigation } from './CalendarNavigation';

const getDateKey = (date: Date) => format(date, 'yyyy-MM-dd');

export interface CalendarProps {
  monthStartDate?: Date;
  dateFnsSettings?: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  };
  todayLabel: string;
  agendaLabel?: string;
  calendarLabel?: string;
  events?: Array<CalendarEventData>;
  eventTypes?: Array<CalendarEventType>;
  showAgenda?: boolean;
  onMonthChange?: (month: Date) => void;
  onEventClick?: (eventId: string | number, eventKey: string | number) => void;
  onToggleShowAgenda?: () => void;
}

export const Calendar: FunctionComponent<PropsWithChildren<CalendarProps>> = ({
  monthStartDate,
  dateFnsSettings,
  todayLabel,
  agendaLabel,
  calendarLabel,
  events,
  eventTypes,
  showAgenda: controlledShowAgenda,
  onEventClick,
  onMonthChange,
  onToggleShowAgenda,
  children,
}) => {
  const [currentMonth, setCurrentMonth] = useState(
    monthStartDate ?? startOfMonth(new Date()),
  );

  const monthStartDateStr = format(monthStartDate, 'yyyy-MM-dd');
  useEffect(() => {
    setCurrentMonth(new Date(monthStartDateStr));
  }, [monthStartDateStr]);

  const [showAgenda, , toggleShowAgenda] = useToggle(false);

  const weeks = useMemo(() => {
    const weekStartDates = eachWeekOfInterval(
      {
        start: currentMonth,
        end: endOfMonth(currentMonth),
      },
      dateFnsSettings,
    );

    return weekStartDates.map((weekStartDate) =>
      eachDayOfInterval({
        start: weekStartDate,
        end: addDays(endOfWeek(weekStartDate), 1),
      }),
    );
  }, [currentMonth, dateFnsSettings]);

  const handleMonthChange = useCallback(
    (month: Date) => {
      setCurrentMonth(month);
      onMonthChange?.(month);
    },
    [onMonthChange],
  );

  const handleReset = useCallback(() => {
    const date = startOfMonth(new Date());
    setCurrentMonth(date);
    onMonthChange?.(date);
  }, [onMonthChange]);

  const today = useMemo(() => new Date(), []);

  const eventMap = useMemo<Record<number, Array<CalendarEventData>>>(() => {
    const map: Record<number, Array<CalendarEventData>> = {};
    events?.forEach((event) => {
      if (
        (showAgenda || controlledShowAgenda) &&
        !isSameMonth(event.dateTime, currentMonth)
      ) {
        return;
      }
      map[getDateKey(event.dateTime)] = map[getDateKey(event.dateTime)] ?? [];
      map[getDateKey(event.dateTime)].push(event);
    });
    return map;
  }, [events, showAgenda, controlledShowAgenda, currentMonth]);

  return (
    <CalendarWrapper>
      <CalendarNavigation
        month={currentMonth}
        todayLabel={todayLabel}
        toggleAgendaLabel={
          showAgenda || controlledShowAgenda ? calendarLabel : agendaLabel
        }
        onChange={handleMonthChange}
        onToggleAgendaClick={onToggleShowAgenda ?? toggleShowAgenda}
        onReset={handleReset}
      >
        {children}
      </CalendarNavigation>
      {showAgenda || controlledShowAgenda ? (
        <CalendarAgenda
          events={eventMap}
          eventTypes={eventTypes}
          onEventClick={onEventClick}
        />
      ) : (
        <CalendarInnerWrapper>
          {weeks.map((week, i) => (
            <CalendarWeek key={week[0].toISOString()}>
              {week.map((day) => (
                <CalendarDay
                  key={day.toISOString()}
                  date={day}
                  displayWeekday={i === 0}
                  today={isSameDay(today, day)}
                  disabled={!isSameMonth(currentMonth, day)}
                  events={eventMap[getDateKey(day)]}
                  eventTypes={eventTypes}
                  onEventClick={onEventClick}
                />
              ))}
            </CalendarWeek>
          ))}
        </CalendarInnerWrapper>
      )}
    </CalendarWrapper>
  );
};
