import React, { FunctionComponent, PropsWithChildren } from 'react';
import { NotificationType } from '../../types';
import {
  CloseButton,
  CloseButtonContainer,
  CloseIcon,
  Label,
  NotificationContent,
  NotificationWrapper,
} from './Notification.styles';

export interface NotificationProps {
  label?: string;
  type?: NotificationType;
  onClose?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Notification: FunctionComponent<PropsWithChildren<NotificationProps>> = ({
  label,
  children,
  type = NotificationType.Info,
  onClose,
  ...props
}) => {
  const handleClose = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onClose?.(event);
    },
    [onClose],
  );

  return (
    <NotificationWrapper type={type} {...props}>
      {onClose && (
        <CloseButtonContainer>
          <CloseButton onClick={handleClose}>
            <CloseIcon type={type} />
          </CloseButton>
        </CloseButtonContainer>
      )}
      {label && <Label type={type}>{label}</Label>}
      <NotificationContent type={type}>{children}</NotificationContent>
    </NotificationWrapper>
  );
};
