import { Notification } from '@fcg-tech/notifications';
import update from 'immutability-helper';

export const markAsRead = (
  notifications: Array<Notification>,
  selected: Array<string>,
): Array<Notification> => {
  return notifications.map((item) => {
    if (selected.includes(item.id)) {
      return {
        ...item,
        read: true,
      };
    }
    return item;
  });
};

export const fixPath = (notification?: Notification): Notification | null => {
  if (!notification) {
    return null;
  }
  return update(notification, {
    link: {
      path: {
        $set: notification.link?.path?.startsWith('/')
          ? notification.link?.path
          : `/${notification.link?.path}`,
      },
    },
  });
};
