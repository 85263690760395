import {
  DataTableSortOrder,
  DataTableColumnOptions,
  DataTable,
  DataTableRowOptions,
} from '@fcg-tech/regtech-components';
import { FileAttachment } from '@fcg-tech/regtech-types/oasys';
import { FunctionComponent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import { AttachmentTableRow, ColumnKeys, RowData } from './AttachmentTableRow';

// TODO: use types from generated schema

export interface AttachmentTableProps {
  attachments: Array<FileAttachment>;
  emptyMessage?: string;
  isLoading?: boolean;
  onDownloadAttachment?: (attachmentId: string) => Promise<string>;
  onRemoveAttachment?: (attachmentId: string) => Promise<boolean>;
  onRenameAttachment?: (
    attachmentId: string,
    newFilename: string,
  ) => Promise<boolean>;
}

export const AttachmentTable: FunctionComponent<AttachmentTableProps> = ({
  attachments,
  isLoading,
  emptyMessage,
  onDownloadAttachment,
  onRemoveAttachment,
  onRenameAttachment,
}) => {
  const { t } = useTranslation();

  const [sortOrder, setSortOrder] = useState<DataTableSortOrder<ColumnKeys>>({
    ascending: true,
    columnKey: 'filename',
  });

  const columns = useMemo<Array<DataTableColumnOptions<RowData, ColumnKeys>>>(
    () => [
      {
        columnKey: 'filename',
        label: t(MessageKeys.AttachmentTableFilenameLabel),
        resizable: true,
        sortable: true,
      },
      {
        columnKey: 'uploaded',
        label: t(MessageKeys.AttachmentTableUploadedLabel),
        resizable: true,
        sortable: true,
      },
      {
        columnKey: 'actions',
        label: '',
        resizable: false,
        sortable: false,
        minWidth: 140,
        width: 140,
        fixed: true,
      },
    ],
    [t],
  );

  const dataRows = useMemo<Array<DataTableRowOptions<RowData>>>(() => {
    return attachments
      ?.map((attachment) => ({
        id: attachment.id,
        data: {
          filename: attachment.filename,
          uploaded: attachment.uploaded,
        },
      }))
      .sort((a, b) => {
        if (sortOrder?.ascending === undefined) {
          return 0;
        }
        switch (sortOrder?.columnKey) {
          case 'filename':
            return (
              a.data.filename.localeCompare(b.data.filename) *
              (sortOrder.ascending !== false ? 1 : -1)
            );
          case 'uploaded':
            return 0;
        }
      });
  }, [attachments, sortOrder.ascending, sortOrder?.columnKey]);

  return (
    <DataTable
      columns={columns}
      rows={dataRows}
      loading={isLoading}
      emptyMessage={emptyMessage || t(MessageKeys.AttachmentTableNoItems)}
      sortOrder={sortOrder.ascending !== undefined ? sortOrder : undefined}
      noScroll
      onSortOrderChange={setSortOrder}
    >
      {(row, { columns }) => (
        <AttachmentTableRow
          key={row.id}
          row={row}
          onDownloadClick={onDownloadAttachment}
          onRemoveClick={onRemoveAttachment}
          onRenameClick={onRenameAttachment}
        />
      )}
    </DataTable>
  );
};
