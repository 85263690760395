import React, { useCallback, useState } from 'react';
import {
  SideNavigationExpandButton,
  SideNavigationExpandButtonChevronIcon,
  SideNavigationItemLinkLabel,
  SideNavigationItemIcon,
  SideNavigationMenu,
  UsersIcon,
  SideNavigationSectionHeading,
  SideNavigationItem,
  SettingsIcon,
  SideNavigationItemButton,
  UserCheckIcon,
  UserIcon,
} from '@fcg-tech/regtech-components';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { useTenant } from '../../states/tenantState';
import { routes } from '../../routes';
import { AccessControl } from '../AccessControl';
import { PermissionManagementSideNavigationWrapper } from './PermissionManagementSideNavigation.styles';
import { IamActions } from '../../types';

export const PermissionManagementSideNavigation = () => {
  const tenant = useTenant();
  const tenantId = tenant.id;
  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = useCallback(() => setExpanded((old) => !old), []);

  return (
    <PermissionManagementSideNavigationWrapper>
      <SideNavigationSectionHeading>
        <SideNavigationExpandButton
          onClick={handleExpandClick}
          title="show/hide"
        >
          <SideNavigationExpandButtonChevronIcon down={expanded} />
        </SideNavigationExpandButton>
        <SideNavigationItemButton onClick={handleExpandClick}>
          <SideNavigationItemIcon as={SettingsIcon} />
          <SideNavigationItemLinkLabel>Permissions</SideNavigationItemLinkLabel>
        </SideNavigationItemButton>
      </SideNavigationSectionHeading>
      {expanded ? (
        <SideNavigationMenu collapsible controlled>
          <AccessControl requiredPermissions={IamActions.ViewMyUsers}>
            <SideNavigationItem
              href={constructUrl(routes.users, {
                tenantId,
              })}
              end={false}
            >
              <SideNavigationItemIcon as={UserIcon} />
              <SideNavigationItemLinkLabel>Users</SideNavigationItemLinkLabel>
            </SideNavigationItem>
          </AccessControl>
          <AccessControl requiredPermissions={IamActions.ViewMyGroups}>
            <SideNavigationItem
              href={constructUrl(routes.groups, {
                tenantId,
              })}
              end={false}
            >
              <SideNavigationItemIcon as={UsersIcon} />
              <SideNavigationItemLinkLabel>Groups</SideNavigationItemLinkLabel>
            </SideNavigationItem>
          </AccessControl>
          <AccessControl requiredPermissions={IamActions.ViewMyRoles}>
            <SideNavigationItem
              href={constructUrl(routes.roles, {
                tenantId,
              })}
              end={false}
            >
              <SideNavigationItemIcon as={UserCheckIcon} />
              <SideNavigationItemLinkLabel>Roles</SideNavigationItemLinkLabel>
            </SideNavigationItem>
          </AccessControl>
        </SideNavigationMenu>
      ) : null}
    </PermissionManagementSideNavigationWrapper>
  );
};
