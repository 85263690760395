import { classNames } from '@fcg-tech/regtech-utils';
import React, {
  FunctionComponent,
  Ref,
  useCallback,
  useState,
  PropsWithChildren,
} from 'react';
import styled from 'styled-components';
import { useToggle } from '../../miscHooks';
import { SpinningLoadingIcon } from '../Icons';
import {
  DropdownButtonArrow,
  DropdownButtonArrowWrapper,
  DropdownButtonButton,
  DropdownButtonContent,
  DropdownButtonWrapper,
  IconButtonDefaultIconWrapper,
  IconButtonLinkWrapper,
  IconButtonWrapper,
  LoaderWrapper,
  MultiButtonArrow,
  MultiButtonTitleWrapper,
  MultiStaticButtonWrapper,
  PrimaryButtonLinkWrapper,
  PrimaryButtonWrapper,
  SecondaryButtonLinkWrapper,
  SecondaryButtonWrapper,
} from './Button.styles';

const Loader = () => (
  <LoaderWrapper>
    <SpinningLoadingIcon size="30" />
  </LoaderWrapper>
);

export interface ButtonProps {
  id?: string;
  to?: string;
  disabled?: boolean;
  /**
   * NOTE: `loading` has no effect on link buttons
   */
  loading?: boolean;
  type?: 'button' | 'submit' | 'reset';
  onClick?: (event: React.MouseEvent<unknown>) => void;
  className?: string;
  style?: React.CSSProperties;
}

export const PrimaryButton: FunctionComponent<
  PropsWithChildren<ButtonProps>
> = ({ to, children, type = 'button', className, ...props }) => {
  return to ? (
    <PrimaryButtonLinkWrapper
      {...props}
      className={classNames('button', className)}
      to={to}
    >
      {children}
    </PrimaryButtonLinkWrapper>
  ) : (
    <PrimaryButtonWrapper
      {...props}
      type={type}
      className={classNames('button', className)}
      disabled={props.disabled || props.loading}
    >
      {children}
      {props.loading ? <Loader /> : null}
    </PrimaryButtonWrapper>
  );
};

export const SecondaryButton: FunctionComponent<
  PropsWithChildren<ButtonProps>
> = ({ to, children, type = 'button', className, ...props }) => {
  return to ? (
    <SecondaryButtonLinkWrapper
      {...props}
      className={classNames('button', className)}
      to={to}
    >
      {children}
    </SecondaryButtonLinkWrapper>
  ) : (
    <SecondaryButtonWrapper
      {...props}
      type={type}
      className={classNames('button', className)}
      disabled={props.disabled || props.loading}
    >
      {children}
      {props.loading ? <Loader /> : null}
    </SecondaryButtonWrapper>
  );
};

export interface MultiButtonProps extends ButtonProps {
  initiallyOpen?: boolean;
  title?: string;
  secondary?: boolean;
}

export const MultiButton: FunctionComponent<
  PropsWithChildren<MultiButtonProps>
> = ({
  children,
  className,
  initiallyOpen,
  secondary,
  title,
  onClick,
  ...props
}) => {
  const [isOpen, toggleIsOpen] = useToggle(initiallyOpen);

  const handleClick = useCallback(
    (event: React.MouseEvent<unknown>) => {
      toggleIsOpen();
      onClick?.(event);
    },
    [onClick, toggleIsOpen],
  );

  const Button = secondary ? SecondaryButton : PrimaryButton;
  return (
    <Button
      {...props}
      className={classNames('button', className)}
      onClick={handleClick}
    >
      <MultiButtonTitleWrapper>{title}</MultiButtonTitleWrapper>
      <MultiButtonArrow down={isOpen} />
      {isOpen ? children : null}
    </Button>
  );
};

export interface IconButtonProps extends React.PropsWithChildren<ButtonProps> {
  toggled?: boolean;
}

export const IconButton = React.forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  IconButtonProps
>(({ children, className, toggled, to, loading, ...props }, ref) => {
  if (to) {
    return (
      <IconButtonLinkWrapper
        {...props}
        to={to}
        ref={ref as Ref<HTMLAnchorElement>}
        $loading={loading}
        className={classNames(
          'button',
          className,
          toggled && 'icon-button--toggled',
        )}
      >
        <IconButtonDefaultIconWrapper>{children}</IconButtonDefaultIconWrapper>
        {loading ? <Loader /> : null}
      </IconButtonLinkWrapper>
    );
  }
  return (
    <IconButtonWrapper
      {...props}
      ref={ref as Ref<HTMLButtonElement>}
      $loading={loading}
      className={classNames(
        'button',
        className,
        toggled && 'icon-button--toggled',
      )}
      disabled={props.disabled || loading}
    >
      <IconButtonDefaultIconWrapper>{children}</IconButtonDefaultIconWrapper>
      {loading ? <Loader /> : null}
    </IconButtonWrapper>
  );
});

export interface MultiStaticButtonProps {
  children: Array<React.ReactElement<ButtonProps>>;
  selected?: ButtonProps['id'];
}

export const MultiStaticButton = ({
  children,
  selected,
}: MultiStaticButtonProps) => {
  return (
    <MultiStaticButtonWrapper>
      {React.Children.map(children, (child) => {
        if (!child.props.id) {
          return null;
        }

        return React.cloneElement(child, {
          ...child.props,
          className: classNames(
            child.props.className,
            child.props.id === selected && 'active',
          ),
        });
      })}
    </MultiStaticButtonWrapper>
  );
};

export interface DropdownButtonProps extends ButtonProps {
  initiallyOpen?: boolean;
  title?: string;
  children: (
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  ) => JSX.Element;
}

export const DropdownButton: FunctionComponent<DropdownButtonProps> = ({
  children,
  className,
  initiallyOpen,
  title,
  disabled,
  onClick,
  ...props
}) => {
  const [isOpen, setOpen] = useState(initiallyOpen);

  const handleArrowClick = useCallback(() => {
    setOpen((old) => !old);
  }, []);

  return (
    <DropdownButtonWrapper
      {...props}
      className={classNames('button', className)}
    >
      <DropdownButtonButton disabled={disabled} onClick={onClick}>
        {title}
      </DropdownButtonButton>
      <DropdownButtonArrowWrapper
        disabled={disabled}
        onClick={handleArrowClick}
      >
        <DropdownButtonArrow />
      </DropdownButtonArrowWrapper>
      {isOpen ? (
        <DropdownButtonContent>{children(setOpen)}</DropdownButtonContent>
      ) : null}
    </DropdownButtonWrapper>
  );
};

export const TertiaryButton = styled(SecondaryButton)`
  border-color: transparent;
`;
