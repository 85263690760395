/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import { FieldCompareMessage, FieldCompareIcon } from './FieldCompare.styles';
import { useFieldCompare } from './FieldCompareManager';

interface FieldCompareProps {
  name?: string;
  nameOptions?: Array<{ key: string; label: string }>;
}

export const FieldCompare = ({ name, nameOptions }: FieldCompareProps) => {
  const { t } = useTranslation();
  const compare = useFieldCompare(name);

  if (compare) {
    const flattenObject = (obj: any) => {
      const flattened: any = [];
      if (obj.type) {
        return [obj];
      }
      Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'object') {
          return flattened.push(obj[key]);
        }
      });
      return flattened;
    };

    compare.label =
      nameOptions?.find(({ key }) => key === compare.oldData)?.label ??
      compare.oldData;

    return flattenObject(compare).map((item: any) => {
      switch (item.type) {
        case 'updated':
          return item.label &&
            item.label != null &&
            typeof item.label != 'boolean' ? (
            <FieldCompareMessage key={item}>
              <FieldCompareIcon size="12" />
              <span>
                {t(MessageKeys.FieldCompareValueUpdatedFromLabel, {
                  label: item?.label,
                })}
              </span>
            </FieldCompareMessage>
          ) : (
            <FieldCompareMessage key={item}>
              <FieldCompareIcon size="12" />{' '}
              <span>
                {t(MessageKeys.FieldCompareValueUpdatedLabel)}
              </span>
            </FieldCompareMessage>
          );
        case 'created':
          return (
            <FieldCompareMessage key={item}>
              <FieldCompareIcon size="12" />{' '}
              <span>
                {t(MessageKeys.FieldCompareItemCreatedLabel)}
              </span>
            </FieldCompareMessage>
          );
        case 'deleted':
          return (
            <FieldCompareMessage key={item}>
              <FieldCompareIcon size="12" />{' '}
              <span>
                {t(MessageKeys.FieldCompareItemDeletedLabel)}
              </span>
            </FieldCompareMessage>
          );
      }
      return null;
    });
  }
  return null;
};
