import { useTranslation } from 'react-i18next';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import {
  CabinetIcon,
  CalendarIcon,
  ChainIcon,
  ColumnsIcon,
  DashboardIcon,
  DocumentIcon,
  StackIcon,
  TagIcon,
  TrendingUpIcon,
  TruckIcon,
  SideNavigationItemLinkLabel,
  SideNavigationMenu,
  SideNavigationItemIcon,
  SideNavigationItem,
} from '@fcg-tech/regtech-components';
import { environment } from '../../environments/environment';
import { MessageKeys } from '../../translations/messageKeys';
import { routes } from '../../routes';
import { GlobalActions } from '../../types';
import { useTenant } from '../../states/tenantState';
import { canNavigateToPermissionManagementAdministration } from '../../utils/iamHelpers';
import { AccessControl } from '../AccessControl';
import { useIamContext } from '../IamContext';
import { PermissionManagementSideNavigation } from './PermissionManagementSideNavigation';
import {
  StyledSideBar,
  MainSideNavigationContent,
  MainSideNavigationMenuWrapper,
  StyledOasysText,
} from './MainSideNavigation.styles';

const LogoText = () => (
  <StyledOasysText
    color={environment.featureSet === 'hoist_finance' ? '#3b3b3b' : '#fff'}
  />
);

export const MainSideNavigation = () => {
  const { t } = useTranslation();
  const iam = useIamContext();
  const tenant = useTenant();
  const tenantId = tenant.id;

  return (
    <StyledSideBar collapsible logo={LogoText}>
      <MainSideNavigationContent>
        <MainSideNavigationMenuWrapper>
          <SideNavigationMenu>
            <AccessControl requiredPermissions={GlobalActions.ViewMyDashboard}>
              <SideNavigationItem
                href={constructUrl(routes.dashboard, { tenantId })}
                end={false}
              >
                <SideNavigationItemIcon as={DashboardIcon} />
                <SideNavigationItemLinkLabel>
                  {t(MessageKeys.SideMenuDashboardLabel)}
                </SideNavigationItemLinkLabel>
              </SideNavigationItem>
            </AccessControl>
            <AccessControl requiredPermissions={GlobalActions.ViewMyCalendar}>
              <SideNavigationItem
                href={constructUrl(routes.calendar, { tenantId })}
                end={false}
              >
                <SideNavigationItemIcon as={CalendarIcon} />
                <SideNavigationItemLinkLabel>
                  {t(MessageKeys.SideMenuCalendarLabel)}
                </SideNavigationItemLinkLabel>
              </SideNavigationItem>
            </AccessControl>
            <AccessControl requiredPermissions={GlobalActions.ViewMyAgreements}>
              <SideNavigationItem
                href={constructUrl(routes.agreements, { tenantId })}
                end={false}
              >
                <SideNavigationItemIcon as={DocumentIcon} />
                <SideNavigationItemLinkLabel>
                  {t(MessageKeys.SideMenuAgreementsLabel)}
                </SideNavigationItemLinkLabel>
              </SideNavigationItem>
            </AccessControl>
            <AccessControl requiredPermissions={GlobalActions.ViewMyCabinets}>
              <SideNavigationItem
                href={constructUrl(routes.cabinets, { tenantId })}
                end={false}
              >
                <SideNavigationItemIcon as={CabinetIcon} />
                <SideNavigationItemLinkLabel>
                  {t(MessageKeys.SideMenuCabinetsLabel)}
                </SideNavigationItemLinkLabel>
              </SideNavigationItem>
            </AccessControl>
            <AccessControl requiredPermissions={GlobalActions.ViewMyTags}>
              <SideNavigationItem
                href={constructUrl(routes.tags, { tenantId })}
                end={false}
              >
                <SideNavigationItemIcon as={TagIcon} />
                <SideNavigationItemLinkLabel>
                  {t(MessageKeys.SideMenuTagsLabel)}
                </SideNavigationItemLinkLabel>
              </SideNavigationItem>
            </AccessControl>
            <AccessControl
              requiredPermissions={GlobalActions.ViewMyAgreementTypes}
            >
              <SideNavigationItem
                href={constructUrl(routes.agreementTypes, { tenantId })}
                end={false}
              >
                <SideNavigationItemIcon as={ColumnsIcon} />
                <SideNavigationItemLinkLabel>
                  {t(MessageKeys.SideMenuAgreementTypesLabel)}
                </SideNavigationItemLinkLabel>
              </SideNavigationItem>
            </AccessControl>
            <AccessControl requiredPermissions={GlobalActions.ViewMyCategories}>
              <SideNavigationItem
                href={constructUrl(routes.categories, { tenantId })}
                end={false}
              >
                <SideNavigationItemIcon as={StackIcon} />
                <SideNavigationItemLinkLabel>
                  {t(MessageKeys.SideMenuCategoriesLabel)}
                </SideNavigationItemLinkLabel>
              </SideNavigationItem>
            </AccessControl>
            <AccessControl requiredPermissions={GlobalActions.ViewMySuppliers}>
              <SideNavigationItem
                href={constructUrl(routes.suppliers, { tenantId })}
                end={false}
              >
                <SideNavigationItemIcon as={TruckIcon} />
                <SideNavigationItemLinkLabel>
                  {t(MessageKeys.SideMenuSuppliersLabel)}
                </SideNavigationItemLinkLabel>
              </SideNavigationItem>
            </AccessControl>
            <AccessControl
              requiredPermissions={GlobalActions.ViewMyInternalEntities}
            >
              <SideNavigationItem
                href={constructUrl(routes.internalEntities, { tenantId })}
                end={false}
              >
                <SideNavigationItemIcon as={ChainIcon} />
                <SideNavigationItemLinkLabel>
                  {t(MessageKeys.SideMenuInternalEntitiesLabel)}
                </SideNavigationItemLinkLabel>
              </SideNavigationItem>
            </AccessControl>
            <AccessControl requiredPermissions={GlobalActions.ViewMyReports}>
              <SideNavigationItem
                href={constructUrl(routes.reports, { tenantId })}
                end={false}
              >
                <SideNavigationItemIcon as={TrendingUpIcon} />
                <SideNavigationItemLinkLabel>
                  {t(MessageKeys.SideMenuReportsLabel)}
                </SideNavigationItemLinkLabel>
              </SideNavigationItem>
            </AccessControl>
          </SideNavigationMenu>
          {canNavigateToPermissionManagementAdministration(iam) ? (
            <PermissionManagementSideNavigation />
          ) : null}
        </MainSideNavigationMenuWrapper>
      </MainSideNavigationContent>
    </StyledSideBar>
  );
};
