import useSWR, { SWRConfiguration } from 'swr';
import { useApi } from '../apiUtils';
import { GlobalSearchApi, GlobalSearchResult } from '../schema';
import { globalSearchKey } from './cacheKeys';

export const globalSearch = async (
  searchString: string,
  api: GlobalSearchApi,
): Promise<Array<GlobalSearchResult>> => {
  const result = await api.globalSearch({ query: searchString });
  return result.result ?? [];
};

export const useGlobalSearch = (
  tenantId: string,
  searchString: string,
  config?: SWRConfiguration,
) => {
  const api = useApi<GlobalSearchApi>('GlobalSearchApi', tenantId);
  return useSWR<Array<GlobalSearchResult>>(
    globalSearchKey(tenantId, searchString),
    () => globalSearch(searchString, api),
    { suspense: true, ...config },
  );
};
