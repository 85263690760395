/* eslint-disable no-nested-ternary */
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { isKeyLabelItem } from '../../guards';
import { SelectOption } from '../../types/form';
import { Item } from './Select.styles';

export interface OptionProps {
  value: SelectOption;
  isSelected?: boolean;
  isFocused?: boolean;
  className?: string;
  tabIndex?: number;

  onClick?: (key: string | number) => void;
}

export const Option: FunctionComponent<PropsWithChildren<OptionProps>> = ({
  value,
  isSelected,
  isFocused,
  className,
  tabIndex = -1,
  children,
  onClick,
}) => {
  const ref = React.useRef<HTMLLIElement>();

  React.useEffect(() => {
    if (isSelected) {
      ref.current.scrollIntoView(false);
    }
  });

  const { key, label } = isKeyLabelItem(value)
    ? value
    : { key: value, label: value };

  const handleClick = React.useCallback(() => onClick?.(key), [key, onClick]);

  return (
    <Item
      role="button"
      ref={ref}
      tabIndex={tabIndex}
      isSelected={isSelected}
      isFocused={isFocused}
      className={className}
      onClick={handleClick}
    >
      {children ?? label}
    </Item>
  );
};
