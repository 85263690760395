import React from 'react';
import styled, { css } from 'styled-components';
import { IconButton } from '../Button';
import { H2 } from '../Headings';
import { CrossIcon } from '../Icons';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const Backdrop = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  background: rgba(0, 0, 0, 0.5);

  &.blur {
    backdrop-filter: blur(4px);
  }
`;

export const ModalContent = styled.div`
  ${({ theme }) => css`
    position: relative;
    top: -5vh;
    max-height: 85vh;
    background: white;
    border-radius: 0.3rem;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 2px 3px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      max-width: 95vw;
      width: 95vw;
    }
  `}
`;

export const CloseButton = styled(IconButton).attrs({ className: 'narrow' })`
  padding: 0.3rem 0.9rem;
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1;

  &.floating {
    top: 0;
    right: 0;
    transform: translateX(50%) translateY(-50%);
  }

  &.floating ${CloseButton} {
    background-color: white;
  }
`;

export const CloseIcon = styled(CrossIcon)`
  width: 1.2rem;
  height: 1.2rem;
`;

export const ModalBody = styled.div`
  overflow-y: scroll;
  padding: 2.4rem;
  font-size: 1.4rem;
  flex: 1;

  &.no-scroll {
    overflow-y: initial;
  }

  &.center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

export const ModalHeader = styled.div`
  padding: 1.2rem 1.4rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
`;

export const ModalTitle = styled(({ component: Component = H2, ...props }) => (
  <Component {...props} />
))`
  margin-bottom: 0;
  font-weight: normal;
  font-size: 2rem;
`;

export const ModalButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2.6rem;

  & > button + button {
    margin-left: 0.8rem;
  }

  &.space-between {
    justify-content: space-between;
  }
`;

export const ModalFooter = styled.div`
  padding: 1.4rem;
  border-top: 1px solid rgba(0, 0, 0, 0.15);

  & > ${ModalButtonRow} {
    margin-top: 0;
  }
`;

export const ModalPage = styled.div`
  transition: transform ease-in-out 0.2s;
  transform: translateX(0%);

  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;

  &.before,
  &.after {
    transform: translateX(110%);
    height: 0;
    min-height: 0;
    padding: 0;
    margin: 0;
    flex: 0;
    overflow: hidden;
  }

  &.after {
    transform: translateX(-110%);
  }
`;
