import { EmptyPropsWithChildren } from '@fcg-tech/regtech-types';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import update from 'immutability-helper';

type TooltipDesc = {
  id: string | number;
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  priority?: number;
};

export type TooltipContext = {
  tooltips: Array<TooltipDesc>;
  visibleTooltip?: TooltipDesc;
  setVisibleTooltip?: (id: string | number) => void;
  setTooltips?: (desc: TooltipDesc, remove?: boolean) => void;
};

export const tooltipContext = React.createContext<TooltipContext>({
  tooltips: [],
});

export const useTooltipContext = () => React.useContext(tooltipContext);

export const TooltipContextProvider: FunctionComponent<EmptyPropsWithChildren> = ({ children }) => {
  const [tooltips, setTooltipsArray] = useState<Array<TooltipDesc>>([]);

  const [visibleTooltip, setVisibleTooltip] = useState<TooltipDesc | null>();

  const setTooltips = useCallback<TooltipContext['setTooltips']>(
    (desc, remove = false) => {
      setTooltipsArray((old) => {
        if (remove) {
          const index = old.findIndex((t) => t.id === desc.id);
          if (index >= 0) {
            return update(old, { $splice: [[index, 1]] });
          }
        } else {
          return update(old, { $push: [desc] });
        }
        return old;
      });
    },
    [],
  );

  const handleSetVisibleTooltip = useCallback<
    TooltipContext['setVisibleTooltip']
  >(
    (id) => {
      const t = tooltips.find((t) => t.id === id);
      setVisibleTooltip(t);
    },
    [tooltips],
  );

  const value = useMemo<TooltipContext>(
    () => ({
      tooltips,
      visibleTooltip,
      setVisibleTooltip: handleSetVisibleTooltip,
      setTooltips,
    }),
    [handleSetVisibleTooltip, setTooltips, tooltips, visibleTooltip],
  );

  return (
    <tooltipContext.Provider value={value}>{children}</tooltipContext.Provider>
  );
};
