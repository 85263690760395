export const notificationsKey = (
  application: string,
  offset?: string | undefined,
  unreadOnly?: boolean | undefined,
) => `/notifications/${application}?${offset}&${unreadOnly}`;

export const markNotificationsAsReadKey = (application: string) =>
  `/notifications/${application}/read`;

export const notificationsSettingsKey = (application: string) =>
  `/notifications/${application}/subscription/email`;
