import React, { FunctionComponent } from 'react';

import { TextField } from '../TextField';

const numberRe = /^\d*$/;
const spaceRe = /\s/g;

interface NumberFieldProps
  extends Omit<
    React.ComponentPropsWithoutRef<typeof TextField>,
    'onChange' | 'value'
  > {
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: number,
  ) => void;
  value?: number;
}

export const NumberField: FunctionComponent<NumberFieldProps> = ({
  onChange,
  value,
  ...props
}) => {
  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value.replace(spaceRe, '');
      if (!value.match(numberRe)) {
        event.preventDefault();
        return;
      }
      if (onChange && typeof onChange === 'function') {
        onChange(event, Number(value));
      }
    },
    [onChange],
  );

  return (
    <TextField {...props} value={value?.toString()} onChange={handleChange} />
  );
};
