import { EmptyPropsWithChildren } from '@fcg-tech/regtech-types';
import { useAuthContext } from '@fcg-tech/regtech-auth';
import {
  Modal,
  ModalBody,
  ModalButtonRow,
  ModalHeader,
  ModalTitle,
  PrimaryButton,
} from '@fcg-tech/regtech-components';
import { FunctionComponent, useCallback, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { AccessControl } from '../../components/AccessControl';
import { MessageKeys } from '../../translations/messageKeys';
import { GlobalActions } from '../../types';

export const AccessControlWrapper: FunctionComponent<PropsWithChildren<EmptyPropsWithChildren>> = ({ children }) => {
  const { t } = useTranslation();
  const { logout } = useAuthContext();

  const handleLogout = useCallback(() => logout(), [logout]);

  const renderNoAccess = useCallback(
    () => (
      <Modal>
        <ModalHeader>
          <ModalTitle>{t(MessageKeys.ErrorAccessDenied)}</ModalTitle>
        </ModalHeader>
        <ModalBody style={{ maxWidth: '52rem' }}>
          {t(MessageKeys.ErrorAccessDeniedMessage)}
          <ModalButtonRow>
            <PrimaryButton onClick={handleLogout}>
              {t(MessageKeys.LabelLogout)}
            </PrimaryButton>
          </ModalButtonRow>
        </ModalBody>
      </Modal>
    ),
    [handleLogout, t],
  );

  return (
    <AccessControl
      requiredPermissions={GlobalActions.ViewMyDashboard}
      renderNoAccess={renderNoAccess}
    >
      {children}
    </AccessControl>
  );
};
