import React, { FunctionComponent } from 'react';
import { format } from 'date-fns';
import {
  Modal,
  ModalBody,
  ModalButtonRow,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  PrimaryButton,
} from '@fcg-tech/regtech-components';
import {
  ErrorDialogVersionMessage,
  ErrorDialogMessage,
  ErrorDialogTitleInner,
  ErrorDialogErrorIcon,
} from './ErrorDialog.styles';

const GIT_SHA = process.env.REACT_APP_GIT_SHA;

export interface ErrorDialogProps {
  title: string;
  message: string;
  closeText: string;
  onClose: (event: React.MouseEvent<unknown>) => void;
}

export const ErrorDialog: FunctionComponent<ErrorDialogProps> = ({
  title,
  message,
  closeText,
  onClose,
}) => (
  <Modal>
    <ModalHeader>
      <ModalTitle>
        <ErrorDialogTitleInner>
          <ErrorDialogErrorIcon size="30" />
          <span>{title}</span>
        </ErrorDialogTitleInner>
      </ModalTitle>
    </ModalHeader>
    <ModalBody>
      <ErrorDialogMessage>{message}</ErrorDialogMessage>
      <ModalButtonRow>
        <PrimaryButton onClick={onClose}>{closeText}</PrimaryButton>
      </ModalButtonRow>
    </ModalBody>
    <ModalFooter>
      {GIT_SHA ? (
        <ErrorDialogVersionMessage>
          {`v ${GIT_SHA} ${format(new Date(), 'yyyy-MM-dd HH:mm:SS')}`}
        </ErrorDialogVersionMessage>
      ) : null}
    </ModalFooter>
  </Modal>
);
