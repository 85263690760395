/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { css } from 'styled-components';
import { excludeProps } from '@fcg-tech/regtech-components';
import { LogLevel } from '../../types';

export const FieldErrorMessage = styled.p.withConfig<{ level?: string }>(
  excludeProps('level'),
)`
  ${({ theme, level = LogLevel.Error }) => css`
    color: ${(theme as any)?.colors[level as string]?.main ?? 'red'};
    font-size: 1.4rem;
    font-weight: 400;
    font-family: ${theme?.typography?.fontFamily};
    margin: 0;
    margin-top: 0.75rem;
  `}
`;
