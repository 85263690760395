import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { useAuthContext } from '@fcg-tech/regtech-auth';
import {
  IconButton,
  SearchTextField,
  PrimaryButton,
  useFlash,
  TooltipNext as Tooltip,
  BookIcon,
} from '@fcg-tech/regtech-components';
import { BellIconButton } from '@fcg-tech/notifications';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getDocumentationUrl } from '../../api/hooks/docsApi';
import { useNotifications } from '../../api/hooks/notificationsApiHooks';
import { routes } from '../../routes';
import { useTenant } from '../../states/tenantState';
import { MessageKeys } from '../../translations/messageKeys';
import { GlobalActions } from '../../types';
import { AccessControl } from '../AccessControl';
import Features from '../../Features';
import {
  HeaderWrapper,
  SearchTextFieldWrapper,
  HeaderLeftContent,
  HeaderRightContent,
  HeaderActionButtonWrapper,
} from './Header.styles';

export const Header = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tenant = useTenant();
  const { isAuthenticated, logout } = useAuthContext();
  const addFlash = useFlash();
  const [loading, setLoading] = useState(false);

  const { data } = useNotifications(
    {
      unreadOnly: true,
    },
    {
      suspense: false,
    },
  );

  const countStr = useMemo(() => {
    if (data) {
      if (
        data.items.length > 99 ||
        (data.items.length === 99 && data.truncated)
      ) {
        return '99+';
      }
      return data.items.length;
    }

    return 0;
  }, [data]);

  const [searchQuery, setSearchQuery] = useState<string>('');

  const handleLogout = useCallback(() => logout(), [logout]);

  const handleSearchChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setSearchQuery(event.target?.value ?? ''),
    [],
  );

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
        const query = searchQuery.toString().trim();
        if (query) {
          event.currentTarget.blur();
          setSearchQuery('');
          navigate(
            constructUrl(
              routes.search,
              { tenantId: tenant.id },
              { searchString: query },
            ),
          );
        }
      }
    },
    [searchQuery, navigate, tenant.id],
  );

  const handleDocsClick = useCallback(async () => {
    try {
      setLoading(true);
      const url = await getDocumentationUrl();
      window.open(url, '_blank');
    } catch (e) {
      addFlash({
        content: t(MessageKeys.ErrorGeneric),
        level: 'error',
      });
    }
    setLoading(false);
  }, [addFlash, t]);

  const handleClear = useCallback(() => setSearchQuery(''), []);

  return (
    <HeaderWrapper>
      <HeaderLeftContent>
        <SearchTextFieldWrapper>
          <SearchTextField
            value={searchQuery}
            placeholder={t(MessageKeys.MainLayoutHeaderSearchLabel)}
            onChange={handleSearchChange}
            onClear={handleClear}
            onKeyDown={handleKeyDown}
          />
        </SearchTextFieldWrapper>
      </HeaderLeftContent>
      <HeaderRightContent>
        <AccessControl requiredPermissions={GlobalActions.ViewMySettings}>
          <HeaderActionButtonWrapper>
            <BellIconButton
              count={countStr}
              href={constructUrl(routes.notifications, { tenantId: tenant.id })}
              tooltip={t(MessageKeys.NotificationsUnreadCountTooltip, {
                count: data?.items?.length,
              })}
            />
          </HeaderActionButtonWrapper>
        </AccessControl>
        {Features.UserGuide ? (
          <HeaderActionButtonWrapper>
            <Tooltip
              content={t(MessageKeys.DocumentationLinkTooltip)}
              showDelay={1000}
              placement="bottom-end"
            >
              <IconButton
                id="documentation-button-wrapper"
                loading={loading}
                onClick={handleDocsClick}
              >
                <BookIcon />
              </IconButton>
            </Tooltip>
          </HeaderActionButtonWrapper>
        ) : null}
        {isAuthenticated === true ? (
          <PrimaryButton data-kind="logout-button" onClick={handleLogout}>
            Logout
          </PrimaryButton>
        ) : null}
      </HeaderRightContent>
    </HeaderWrapper>
  );
};
