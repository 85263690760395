/* eslint-disable no-nested-ternary */
import React, { FunctionComponent } from 'react';
import { useRadioState } from 'reakit/Radio';
import {
  ClearButton,
  BooleanDecisionWrapper,
  ControlContainer,
  Label,
  LabelContainer,
  Radio,
  RadioGroup,
  ReadOnlyLabel,
  StyledInfoCircle,
  BooleanDecisionFieldWrapper,
  BooleanDecisionFieldLabel,
} from './BooleanDecision.styles';

export interface BooleanDecisionProps {
  /**
   * @deprecated Use className instead
   */
  contractInfo?: boolean;
  info?: string;
  isEditEnabled?: boolean;
  /**
   * @default true
   */
  isClearable?: boolean;
  allowNotRequired?: boolean;
  value?: string | boolean;
  className?: string;
  yesLabel?: string;
  noLabel?: string;
  clearLabel?: string;
  notSetLabel?: string;
  notRequiredLabel?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClear?: (event: React.MouseEvent) => void;
}

export interface BooleanDecisionFn
  extends FunctionComponent<BooleanDecisionProps> {
  /**
   * @deprecated Use exported version instead
   */
  FieldContainer: typeof BooleanDecisionFieldWrapper;
  /**
   * @deprecated Use exported version instead
   */
  FieldLabel: typeof BooleanDecisionFieldLabel;
}

export const BooleanDecision: BooleanDecisionFn = ({
  info,
  contractInfo,
  isEditEnabled = true,
  isClearable = true,
  allowNotRequired,
  value,
  yesLabel = 'Yes',
  noLabel = 'No',
  clearLabel = 'Clear',
  notSetLabel = 'Not set',
  notRequiredLabel = 'Not required',
  className,
  onChange,
  onClear,
}) => {
  const radio = useRadioState({
    state: (typeof value === 'boolean' ? value : value || '<clear>').toString(),
  });

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event);
    },
    [onChange],
  );

  const handleClear = React.useCallback(
    (event: React.MouseEvent) => {
      onClear?.(event);
    },
    [onClear],
  );

  return (
    <BooleanDecisionWrapper contractInfo={contractInfo} className={className}>
      {isEditEnabled ? (
        <ControlContainer>
          <RadioGroup {...radio} aria-label="Yes or No">
            <LabelContainer>
              <Label>
                <Radio
                  {...radio}
                  value="true"
                  checked={value === true}
                  onChange={handleChange}
                />
                {yesLabel}
              </Label>
              <Label>
                <Radio
                  {...radio}
                  value="false"
                  checked={value === false}
                  onChange={handleChange}
                />
                {noLabel}
              </Label>
              {allowNotRequired ? (
                <Label>
                  <Radio
                    {...radio}
                    value="notRequired"
                    checked={value === 'notRequired'}
                    onChange={handleChange}
                  />
                  {notRequiredLabel}
                </Label>
              ) : null}
            </LabelContainer>
          </RadioGroup>
          {isClearable ? (
            <ClearButton onClick={handleClear}>{clearLabel}</ClearButton>
          ) : null}
        </ControlContainer>
      ) : value ? (
        <ReadOnlyLabel>
          {value === true ? yesLabel : notRequiredLabel}
        </ReadOnlyLabel>
      ) : (
        <ReadOnlyLabel>{value === false ? noLabel : notSetLabel}</ReadOnlyLabel>
      )}

      {info && <StyledInfoCircle info={info} />}
    </BooleanDecisionWrapper>
  );
};

BooleanDecision.FieldContainer = BooleanDecisionFieldWrapper;
BooleanDecision.FieldLabel = BooleanDecisionFieldLabel;
