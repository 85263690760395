import {
  DataTableCell,
  DataTableRow,
  DataTableRowOptions,
} from '@fcg-tech/regtech-components';
import { FunctionComponent, useCallback } from 'react';

export type RowData = {
  name: string;
};

export type ColumnKeys = 'name';

interface CabinetsTableRowProps {
  row: DataTableRowOptions<RowData>;
  onClick?: (rowId: string) => void;
}

export const CabinetsTableRow: FunctionComponent<CabinetsTableRowProps> = ({
  row,
  onClick,
}) => {
  const data = row.data;

  const handleClick = useCallback(() => {
    onClick?.(row.id);
  }, [onClick, row]);

  return (
    <DataTableRow row={row} onClick={handleClick}>
      <DataTableCell>{data.name}</DataTableCell>
    </DataTableRow>
  );
};
