import styled, { css } from 'styled-components';

import { FormGroup } from './FormGroup';

export const FormRow = styled.div`
  ${({ theme }) => css`
    margin: 2.4rem 0;
    display: flex;
    width: 100%;

    & + &,
    & + ${FormGroup} {
      margin-top: 2.4rem;
    }

    @media screen and (max-width: ${theme.breakpoints.mobile}px) {
      flex-direction: column;
    }
  `}
`;
