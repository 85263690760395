import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  horizontalListSortingStrategy,
  SortableContext,
} from '@dnd-kit/sortable';
import update from 'immutability-helper';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  FilterBarLeft,
  FilterBarPinnedItemsList,
  FilterBarPinnedItemsListWrapper,
  FilterBarRight,
  FilterBarWrapper,
} from './FilterBar.styles';
import { FilterBarItem, FilterBarItemProps } from './FilterBarItem';
import { FilterValues, StoredFilter, StoredFilterType } from './types';

interface FilterBarProps<T extends FilterValues>
  extends Pick<
    FilterBarItemProps<T>,
    'multiUserFilterToolTipMessageKey' | 'singleUserFilterToolTipMessageKey'
  > {
  filters?: Array<StoredFilter<T>>;
  currentFilterId?: string;
  components?: Partial<
    Record<StoredFilterType, FunctionComponent<FilterBarItemProps<T>>>
  >;
  className?: string;

  onPinnedFilterClick?: (filterId?: string) => void;
  onPinnedFilterSortOrderChange?: (
    targetFilterId: string,
    insertBeforeId: string | null,
  ) => void;
}

export const FilterBar = <T extends FilterValues>({
  filters,
  currentFilterId,
  singleUserFilterToolTipMessageKey,
  multiUserFilterToolTipMessageKey,
  components,
  className,
  onPinnedFilterClick,
  onPinnedFilterSortOrderChange,
  children,
}: React.PropsWithChildren<FilterBarProps<T>>) => {
  const handleFilterClick = useCallback(
    (filterId?: string) => {
      if (filterId === currentFilterId) {
        onPinnedFilterClick?.();
      } else {
        onPinnedFilterClick?.(filterId);
      }
    },
    [onPinnedFilterClick, currentFilterId],
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { delay: 350, tolerance: 5 },
    }),
  );

  const [sortedItems, setSortedItems] = useState<Array<string>>(
    () => filters?.map((filter) => filter.id) ?? [],
  );

  const [tempFilters, setTempFilters] = useState<
    Array<StoredFilter<T>> | undefined
  >(filters);

  useEffect(() => {
    setSortedItems(filters?.map((filter) => filter.id) ?? []);
    setTempFilters(filters);
  }, [filters]);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (
        onPinnedFilterSortOrderChange &&
        active &&
        over &&
        active?.id !== over?.id
      ) {
        const newIndex = sortedItems.indexOf(over.id);
        const oldIndex = sortedItems.indexOf(active.id);

        setSortedItems((old) =>
          update(old, {
            $splice: [
              [oldIndex, 1],
              [newIndex, 0, active.id],
            ],
          }),
        );

        setTempFilters((old) => {
          const targetFilter = old?.[oldIndex];
          return old && targetFilter
            ? update(old, {
                $splice: [
                  [oldIndex, 1],
                  [newIndex, 0, targetFilter],
                ],
              })
            : old;
        });

        setTimeout(
          () =>
            onPinnedFilterSortOrderChange?.(
              active.id,
              newIndex === sortedItems.length - 1 ? null : over.id,
            ),
          1,
        );
      }
    },
    [sortedItems, onPinnedFilterSortOrderChange],
  );

  const filterBarItems = tempFilters?.map((filter) => {
    const Component =
      (filter.type ? components?.[filter.type] : null) ?? FilterBarItem;
    return (
      <Component
        key={filter.id}
        filter={filter}
        active={filter.id === currentFilterId}
        reorderable={Boolean(onPinnedFilterSortOrderChange)}
        singleUserFilterToolTipMessageKey={singleUserFilterToolTipMessageKey}
        multiUserFilterToolTipMessageKey={multiUserFilterToolTipMessageKey}
        onFilterClick={handleFilterClick}
      />
    );
  });

  return tempFilters?.length || React.Children.count(children) ? (
    <FilterBarWrapper className={className}>
      <FilterBarLeft>
        {onPinnedFilterSortOrderChange ? (
          <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
            <FilterBarPinnedItemsListWrapper>
              <FilterBarPinnedItemsList>
                <SortableContext
                  items={sortedItems}
                  strategy={horizontalListSortingStrategy}
                >
                  {filterBarItems}
                </SortableContext>
              </FilterBarPinnedItemsList>
            </FilterBarPinnedItemsListWrapper>
          </DndContext>
        ) : (
          filterBarItems
        )}
      </FilterBarLeft>
      <FilterBarRight>{children}</FilterBarRight>
    </FilterBarWrapper>
  ) : null;
};
