import { atom, useRecoilValue } from 'recoil';

type Tenant = {
  id: string;
};

export const tenantState = atom<Tenant>({
  key: 'TenantState',
  default: {
    id: 'xjk',
  },
});

export const useTenant = () => useRecoilValue(tenantState);
