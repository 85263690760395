import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  CardTitle,
  Field,
  Label,
} from '@fcg-tech/regtech-components';
import {
  BooleanDecision,
  PillMultiSelect,
  Select,
  TextArea,
  TextField,
} from '@fcg-tech/regtech-formik-components';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { MessageKeys } from '../../../translations/messageKeys';
import { getTooltip } from '../../../utils/tooltipHelpers';
import {
  agreementPropType,
  categoryPropType,
  internalEntityPropType,
  supplierPropType,
} from '../../../propTypes';
import { NONE_SELECT_ITEM, DEPLOYMENT_MODEL_OPTIONS } from '../../../constants';
import { countryState } from '../../../states/countryState';
import { regionState } from '../../../states/regionState';
import { regionSelectOption } from '../../../utils/optionConverters';
import { NoticePeriodField } from '../../NoticePeriodField';
import { FormRow, FormColumn, FormGroup } from '../../FormLayout';
import { FieldCompare } from '../../FieldCompare/FieldCompare';
import {
  PARTY_TERMINATE_ITEM,
  DEGREE_CRITICALITY_ITEM,
  PAYMENT_CONDITION_ITEM,
  RESOLUTION_RESILIENT_ITEM,
} from '../../../constants';
import { FieldErrorNonBlocking } from '../../FieldError/FieldErrorNonBlocking';
import Features from '../../../Features';

export const ResolvabilityAssessmentSection = ({ agreement }) => {
  const { t } = useTranslation();
  const countries = useRecoilValue(countryState);
  const regions = useRecoilValue(regionState);
  const ValidationToggles =
    Features.FieldErrors.NonBlocking.Agreement.ResolvabilityAssessment;

  const regionSelectOptions = useMemo(
    () => regions?.map(regionSelectOption),
    [regions],
  );

  const countrySelectOptions = useMemo(
    () =>
      countries?.map((country) => ({
        key: country.isoAlpha2,
        label: country.name,
      })),
    [countries],
  );

  return (
    <Card>
      <CardBody>
        <CardTitle>
          {t(MessageKeys.ResolvabilityAssessmentSectionLabel)}
        </CardTitle>
        <FormRow>
          <FormColumn>
            <FormGroup>
              <BooleanDecision.FieldContainer>
                <BooleanDecision.FieldLabel>
                  {t(
                    MessageKeys.ResolvabilityAssessmentSectionPartOfResolutionGroupLabel,
                  )}
                </BooleanDecision.FieldLabel>
                <Field
                  component={BooleanDecision}
                  name="resolvabilityAssessment.partOfResolutionGroup"
                  info={getTooltip(
                    'resolvabilityAssessment.partOfResolutionGroup',
                  )}
                />
              </BooleanDecision.FieldContainer>
              {ValidationToggles.partOfResolutionGroup && (
                <FieldErrorNonBlocking name="resolvabilityAssessment.partOfResolutionGroup" />
              )}
              <FieldCompare name="resolvabilityAssessment.partOfResolutionGroup" />
            </FormGroup>
          </FormColumn>
          <FormColumn />
        </FormRow>

        {agreement.resolvabilityAssessment?.partOfResolutionGroup && (
          <FormRow>
            <FormColumn>
              <FormGroup>
                <Label
                  column
                  htmlFor="resolvability-assessment-resolution-group-name"
                >
                  {t(MessageKeys.ResolvabilityAssessmentSectionGroupNameLabel)}
                </Label>
                <Field
                  component={TextField}
                  name="resolvabilityAssessment.resolutionGroupName"
                  type="text"
                  id="resolvability-assessment-resolution-group-name"
                  info={getTooltip(
                    'resolvabilityAssessment.resolutionGroupName',
                  )}
                />
                {ValidationToggles.resolutionGroupName && (
                  <FieldErrorNonBlocking name="resolvabilityAssessment.resolutionGroupName" />
                )}
                <FieldCompare name="resolvabilityAssessment.resolutionGroupName" />
              </FormGroup>

              <FormGroup>
                <Label
                  column
                  htmlFor="resolvability-assessment-resolution-resilient-agreement"
                >
                  {t(MessageKeys.ResolvabilityAssessmentSectionResilientLabel)}
                </Label>
                <Field
                  component={Select}
                  items={[NONE_SELECT_ITEM, ...RESOLUTION_RESILIENT_ITEM]}
                  typeahead
                  name="resolvabilityAssessment.resolutionResilientAgreement"
                  id="resolvability-assessment-resolution-resilient-agreement"
                  info={getTooltip(
                    'resolvabilityAssessment.resolutionResilientAgreement',
                  )}
                />
                {ValidationToggles.resolutionResilientAgreement && (
                  <FieldErrorNonBlocking name="resolvabilityAssessment.resolutionResilientAgreement" />
                )}
                <FieldCompare name="resolvabilityAssessment.resolutionResilientAgreement" />
              </FormGroup>

              <FormGroup>
                <BooleanDecision.FieldContainer>
                  <BooleanDecision.FieldLabel>
                    {t(
                      MessageKeys.ResolvabilityAssessmentSectionCriticalBusinessFunctionLabel,
                    )}
                  </BooleanDecision.FieldLabel>
                  <Field
                    component={BooleanDecision}
                    name="resolvabilityAssessment.criticalBusinessFunction"
                    info={getTooltip(
                      'resolvabilityAssessment.criticalBusinessFunction',
                    )}
                  />
                </BooleanDecision.FieldContainer>
                {ValidationToggles.criticalBusinessFunction && (
                  <FieldErrorNonBlocking name="resolvabilityAssessment.criticalBusinessFunction" />
                )}
                <FieldCompare name="resolvabilityAssessment.criticalBusinessFunction" />
              </FormGroup>

              {agreement.resolvabilityAssessment?.criticalBusinessFunction && (
                <FormGroup>
                  <Field
                    component={TextField}
                    name="resolvabilityAssessment.criticalBusinessFunctionDetails"
                    type="text"
                    id="critical-business-function-details"
                    info={getTooltip(
                      'resolvabilityAssessment.criticalBusinessFunctionDetails',
                    )}
                  />
                  {ValidationToggles.criticalBusinessFunctionDetails && (
                    <FieldErrorNonBlocking name="resolvabilityAssessment.criticalBusinessFunctionDetails" />
                  )}
                  <FieldCompare name="resolvabilityAssessment.criticalBusinessFunctionDetails" />
                </FormGroup>
              )}

              <FormGroup>
                <Label
                  column
                  htmlFor="resolvability-assessment-core-business-lines"
                >
                  {t(
                    MessageKeys.ResolvabilityAssessmentSectionCoreBusinessLinesLabel,
                  )}
                </Label>
                <Field
                  component={TextField}
                  name="resolvabilityAssessment.coreBusinessLine"
                  type="text"
                  id="resolvability-assessment-core-business-lines"
                  info={getTooltip('resolvabilityAssessment.coreBusinessLine')}
                />
                {ValidationToggles.coreBusinessLine && (
                  <FieldErrorNonBlocking name="resolvabilityAssessment.coreBusinessLine" />
                )}
                <FieldCompare name="resolvabilityAssessment.coreBusinessLine" />
              </FormGroup>

              <FormGroup>
                <Label
                  column
                  htmlFor="resolvability-assessment-degree-criticality"
                >
                  {t(
                    MessageKeys.ResolvabilityAssessmentSectionDegreeCriticalityLabel,
                  )}
                </Label>
                <Field
                  component={Select}
                  items={[NONE_SELECT_ITEM, ...DEGREE_CRITICALITY_ITEM]}
                  typeahead
                  name="resolvabilityAssessment.degreeOfCriticality"
                  id="resolvability-assessment-degree-criticality"
                  info={getTooltip(
                    'resolvabilityAssessment.degreeOfCriticality',
                  )}
                />
                {ValidationToggles.degreeOfCriticality && (
                  <FieldErrorNonBlocking name="resolvabilityAssessment.degreeOfCriticality" />
                )}
                <FieldCompare name="resolvabilityAssessment.degreeOfCriticality" />
              </FormGroup>

              <FormGroup>
                <Label column htmlFor="resolvability-assessment-jurisdictions">
                  {t(
                    MessageKeys.ResolvabilityAssessmentSectionJurisdictionsLabel,
                  )}
                </Label>
                <Field
                  typeahead
                  component={PillMultiSelect}
                  name="resolvabilityAssessment.jurisdiction"
                  items={[...regionSelectOptions, ...countrySelectOptions]}
                  id="resolvability-assessment-jurisdictions"
                  info={getTooltip('resolvabilityAssessment.jurisdictions')}
                />
                {ValidationToggles.jurisdiction && (
                  <FieldErrorNonBlocking name="resolvabilityAssessment.jurisdiction" />
                )}
                <FieldCompare name="resolvabilityAssessment.jurisdiction" />
              </FormGroup>

              <FormGroup>
                <BooleanDecision.FieldContainer>
                  <BooleanDecision.FieldLabel>
                    {t(
                      MessageKeys.ResolvabilityAssessmentSectionAutomaticRenewalClausesLabel,
                    )}
                  </BooleanDecision.FieldLabel>
                  <Field
                    component={BooleanDecision}
                    name="resolvabilityAssessment.existenceOfAutomaticRenewalClauses"
                    info={getTooltip(
                      'resolvabilityAssessment.existenceOfAutomaticRenewalClauses',
                    )}
                  />
                </BooleanDecision.FieldContainer>
                {ValidationToggles.existenceOfAutomaticRenewalClauses && (
                  <FieldErrorNonBlocking name="resolvabilityAssessment.existenceOfAutomaticRenewalClauses" />
                )}
                <FieldCompare name="resolvabilityAssessment.existenceOfAutomaticRenewalClauses" />
              </FormGroup>

              <FormGroup>
                <BooleanDecision.FieldContainer>
                  <BooleanDecision.FieldLabel>
                    {t(
                      MessageKeys.ResolvabilityAssessmentSectionPredictablePricingStructureLabel,
                    )}
                  </BooleanDecision.FieldLabel>
                  <Field
                    component={BooleanDecision}
                    name="resolvabilityAssessment.pricingStructurePredictable"
                    info={getTooltip(
                      'resolvabilityAssessment.pricingStructurePredictable',
                    )}
                  />
                </BooleanDecision.FieldContainer>
                {ValidationToggles.pricingStructurePredictable && (
                  <FieldErrorNonBlocking name="resolvabilityAssessment.pricingStructurePredictable" />
                )}
                <FieldCompare name="resolvabilityAssessment.pricingStructurePredictable" />
              </FormGroup>

              <FormGroup>
                <Label
                  column
                  htmlFor="resolvability-assessment-payment-conditions"
                >
                  {t(
                    MessageKeys.ResolvabilityAssessmentSectionPaymentConditionLabel,
                  )}
                </Label>
                <Field
                  component={Select}
                  items={[NONE_SELECT_ITEM, ...PAYMENT_CONDITION_ITEM]}
                  typeahead
                  name="resolvabilityAssessment.conditionsOfPayment"
                  id="resolvability-assessment-payment-conditions"
                  info={getTooltip(
                    'resolvabilityAssessment.conditionsOfPayment',
                  )}
                />
                {ValidationToggles.conditionsOfPayment && (
                  <FieldErrorNonBlocking name="resolvabilityAssessment.conditionsOfPayment" />
                )}
                <FieldCompare name="resolvabilityAssessment.conditionsOfPayment" />
              </FormGroup>

              <FormGroup>
                <Label
                  column
                  htmlFor="resolvability-assessment-performance-targets"
                >
                  {t(
                    MessageKeys.ResolvabilityAssessmentSectionPerformanceTargetsLabel,
                  )}
                </Label>
                <Field
                  component={TextArea}
                  name="resolvabilityAssessment.qualitativeAndQuantitativePerformanceTarget"
                  id="resolvability-assessment-performance-targets"
                  info={getTooltip(
                    'resolvabilityAssessment.qualitativeAndQuantitativePerformanceTarget',
                  )}
                />
                {ValidationToggles.qualitativeAndQuantitativePerformanceTarget && (
                  <FieldErrorNonBlocking name="resolvabilityAssessment.qualitativeAndQuantitativePerformanceTarget" />
                )}
                <FieldCompare name="resolvabilityAssessment.qualitativeAndQuantitativePerformanceTarget" />
              </FormGroup>
            </FormColumn>

            <FormColumn>
              <FormGroup>
                <Label
                  column
                  htmlFor="resolvability-assessment-effects-of-breaches"
                >
                  {t(
                    MessageKeys.ResolvabilityAssessmentSectionEffectsBreachesLabel,
                  )}
                </Label>
                <Field
                  component={TextArea}
                  name="resolvabilityAssessment.effectsOfBreaches"
                  id="resolvability-assessment-effects-of-breaches"
                  info={getTooltip('resolvabilityAssessment.effectsOfBreaches')}
                />
                {ValidationToggles.effectsOfBreaches && (
                  <FieldErrorNonBlocking name="resolvabilityAssessment.effectsOfBreaches" />
                )}
                <FieldCompare name="resolvabilityAssessment.effectsOfBreaches" />
              </FormGroup>

              <FormGroup>
                <Label
                  column
                  htmlFor="resolvability-assessment-early-termination"
                >
                  {t(
                    MessageKeys.ResolvabilityAssessmentSectionEarlyTerminationLabel,
                  )}
                </Label>
                <Field
                  component={TextArea}
                  name="resolvabilityAssessment.triggersForEarlyTermination"
                  id="resolvability-assessment-early-termination"
                  info={getTooltip(
                    'resolvabilityAssessment.triggersForEarlyTermination',
                  )}
                />
                {ValidationToggles.triggersForEarlyTermination && (
                  <FieldErrorNonBlocking name="resolvabilityAssessment.triggersForEarlyTermination" />
                )}
                <FieldCompare name="resolvabilityAssessment.triggersForEarlyTermination" />
              </FormGroup>

              <FormGroup>
                <Label
                  column
                  htmlFor="resolvability-assessment-party-terminate"
                >
                  {t(
                    MessageKeys.ResolvabilityAssessmentSectionPartyTerminateLabel,
                  )}
                </Label>
                <Field
                  component={Select}
                  items={[NONE_SELECT_ITEM, ...PARTY_TERMINATE_ITEM]}
                  typeahead
                  name="resolvabilityAssessment.partiesAllowedToTerminate"
                  id="resolvability-assessment-party-terminate"
                  info={getTooltip(
                    'resolvabilityAssessment.partiesAllowedToTerminate',
                  )}
                />
                {ValidationToggles.partiesAllowedToTerminate && (
                  <FieldErrorNonBlocking name="resolvabilityAssessment.partiesAllowedToTerminate" />
                )}
                <FieldCompare name="resolvabilityAssessment.partiesAllowedToTerminate" />
              </FormGroup>

              <FormGroup>
                <Label column htmlFor="resolvability-assessment-estimated-time">
                  {t(
                    MessageKeys.ResolvabilityAssessmentSectionEstimatedTimeLabel,
                  )}
                </Label>
                <Field
                  component={NoticePeriodField}
                  name="resolvabilityAssessment.estimatedTime"
                  id="resolvability-assessment-estimated-time"
                  info={getTooltip('resolvabilityAssessment.estimatedTime')}
                />
                {ValidationToggles.estimatedTime && (
                  <FieldErrorNonBlocking name="resolvabilityAssessment.estimatedTime.value" />
                )}
                {ValidationToggles.estimatedTime && (
                  <FieldErrorNonBlocking name="resolvabilityAssessment.estimatedTime.unit" />
                )}
                <FieldCompare name="resolvabilityAssessment.estimatedTime.value" />
                <FieldCompare name="resolvabilityAssessment.estimatedTime.unit" />
              </FormGroup>

              <FormGroup>
                <Label
                  column
                  htmlFor="resolvability-assessment-duration-of-post-termination-assist"
                >
                  {t(
                    MessageKeys.ResolvabilityAssessmentSectionPostTerminationAssistLabel,
                  )}
                </Label>
                <Field
                  component={NoticePeriodField}
                  name="resolvabilityAssessment.durationOfPostTerminationAssist"
                  id="resolvability-assessment-duration-of-post-termination-assist"
                  info={getTooltip(
                    'resolvabilityAssessment.durationOfPostTerminationAssist',
                  )}
                />
                {ValidationToggles.durationOfPostTerminationAssist && (
                  <FieldErrorNonBlocking name="resolvabilityAssessment.durationOfPostTerminationAssist.value" />
                )}
                {ValidationToggles.durationOfPostTerminationAssist && (
                  <FieldErrorNonBlocking name="resolvabilityAssessment.durationOfPostTerminationAssist.unit" />
                )}
                <FieldCompare name="resolvabilityAssessment.durationOfPostTerminationAssist.value" />
                <FieldCompare name="resolvabilityAssessment.durationOfPostTerminationAssist.unit" />
              </FormGroup>

              <FormGroup>
                <Label
                  column
                  htmlFor="resolvability-assessment-resolvability-alternative-service-providers"
                >
                  {t(
                    MessageKeys.ResolvabilityAssessmentSectionResolvabilityAlternativeServiceProvidersLabel,
                  )}
                </Label>
                <Field
                  component={TextArea}
                  name="resolvabilityAssessment.resolvabilityAlternativeServiceProviders"
                  id="resolvability-assessment-resolvability-alternative-service-providers"
                  info={getTooltip(
                    'resolvabilityAssessment.resolvabilityAlternativeServiceProviders',
                  )}
                />
                {ValidationToggles.resolvabilityAlternativeServiceProviders && (
                  <FieldErrorNonBlocking name="resolvabilityAssessment.resolvabilityAlternativeServiceProviders" />
                )}
                <FieldCompare name="resolvabilityAssessment.resolvabilityAlternativeServiceProviders" />
              </FormGroup>
            </FormColumn>
          </FormRow>
        )}
      </CardBody>
    </Card>
  );
};
