import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import jmespath from 'jmespath';
import {
  Card,
  CardBody,
  CardTitle,
  Field,
  Label,
  PrimaryButton,
  useEditContext,
} from '@fcg-tech/regtech-components';
import {
  Select,
  TextField,
  TextArea,
} from '@fcg-tech/regtech-formik-components';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../../translations/messageKeys';
import { metadataPropType } from '../../../propTypes';
import { getTooltip } from '../../../utils/tooltipHelpers';
import { JSONPretty } from '../../JSONPretty';
import { JSONTable } from '../../JSONTable';
import { FormRow, FormColumn, FormGroup } from '../../FormLayout';

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  & > * + * {
    margin-left: 1rem;
  }
`;

const search = (json, query) => {
  try {
    const res = jmespath.search(json, query);
    return res;
  } catch (err) {
    return err;
  }
};

const ViewTypes = {
  Json: 'json',
  Table: 'table',
};

export const ReportDetailsCard = ({
  report,
  entityJson,
  onDownloadCSVFile,
}) => {
  const { t } = useTranslation();
  const isEditEnabled = useEditContext();
  const [view, setView] = React.useState(ViewTypes.Table);

  const ENTITY_ITEMS = [
    {
      key: 'agreements',
      label: t(MessageKeys.ReportDetailsCardEntityAgreementsLabel),
    },
    {
      key: 'suppliers',
      label: t(MessageKeys.ReportDetailsCardEntitySuppliersLabel),
    },
  ];

  const changeView = React.useCallback(
    (value) => {
      setView(value);
    },
    [setView],
  );

  const searchResult = React.useMemo(() => {
    return search(entityJson, report.data.query);
  }, [report.data.query, entityJson]);

  return (
    <Card>
      <CardBody>
        <CardTitle>{t(MessageKeys.ReportDetailsCardLabel)}</CardTitle>
        <FormRow>
          <FormColumn>
            <FormGroup>
              <Label column htmlFor="report-name">
                {t(MessageKeys.ReportDetailsCardNameLabel)}
              </Label>
              <Field
                component={TextField}
                name="data.name"
                type="text"
                id="report-name"
              />
            </FormGroup>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormGroup>
              <Label column htmlFor="report-entity">
                {t(MessageKeys.ReportDetailsCardEntityLabel)}
              </Label>
              <Field
                component={Select}
                name="data.entity"
                items={ENTITY_ITEMS}
                id="report-entity"
              />
            </FormGroup>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormGroup>
              {isEditEnabled ? (
                <>
                  <Label column htmlFor="report-query">
                    {t(MessageKeys.ReportDetailsCardQueryLabel)}
                  </Label>
                  <Field
                    component={TextArea}
                    name="data.query"
                    type="text"
                    id="report-query"
                    info={getTooltip('report.query')}
                  />
                </>
              ) : null}
            </FormGroup>
            <ButtonGroup>
              <PrimaryButton
                onClick={() =>
                  onDownloadCSVFile(
                    report.data.query,
                    ['agreements', 'suppliers'].includes(report.data.entity)
                      ? report.data.entity.toLowerCase()
                      : 'agreements',
                  )
                }
              >
                {t(MessageKeys.ReportDetailsCardDownloadCsvLabel)}
              </PrimaryButton>
              {view === ViewTypes.Json ? (
                <PrimaryButton onClick={() => changeView(ViewTypes.Table)}>
                  {t(MessageKeys.ReportDetailsCardViewTableLabel)}
                </PrimaryButton>
              ) : (
                <PrimaryButton onClick={() => changeView(ViewTypes.Json)}>
                  {t(MessageKeys.ReportDetailsCardViewJsonLabel)}
                </PrimaryButton>
              )}
            </ButtonGroup>
          </FormColumn>
        </FormRow>
        {view === ViewTypes.Json && (
          <FormRow>
            <JSONPretty data={searchResult} />
            <JSONPretty data={entityJson} />
          </FormRow>
        )}
        {view === ViewTypes.Table && <JSONTable data={searchResult} />}
      </CardBody>
    </Card>
  );
};

ReportDetailsCard.propTypes = {
  report: PropTypes.shape({
    metadata: metadataPropType,
    data: PropTypes.shape({
      query: PropTypes.string,
    }),
  }),
  // eslint-disable-next-line react/forbid-prop-types
  entityJson: PropTypes.any,
  onDownloadCSVFile: PropTypes.func,
};

ReportDetailsCard.defaultProps = {
  report: null,
  entityJson: null,
  onDownloadCSVFile: null,
};
