import {
  TranslationLocale,
  FilterMessageKeys as MessageKeys,
} from './filterMessageKeys';

const en: TranslationLocale = {
  language: 'en',
  messages: {
    [MessageKeys.LabelFrom]: 'From',
    [MessageKeys.LabelTo]: 'To',
    [MessageKeys.LabelSaveFilter]: 'Save as new filter',
    [MessageKeys.LabelUpdateFilter]: 'Save updated filter',
    [MessageKeys.LabelDeleteFilter]: 'Delete filter',
    [MessageKeys.LabelEditFilterName]: 'Edit filter name',
    [MessageKeys.LabelClearFilter]: 'Clear filter',

    [MessageKeys.LoadFilter]: 'Load filter',
    [MessageKeys.PinFilterButtonLabel]: 'Pin this filter',
    [MessageKeys.UnpinFilterButtonLabel]: 'Unpin filter',
    [MessageKeys.FilterMultiUserFilterTooltipLabel]:
      'Multi user filter {{name}}',
    [MessageKeys.FilterSingleUserFilterTooltipLabel]:
      'Single user filter {{name}}',
    [MessageKeys.FilterSelctMenuLoadingAdditional]:
      'Loading additional results...',

    [MessageKeys.RelativeDateIntervalToday]: 'Today',
    [MessageKeys.RelativeDateIntervalLastThreeDays]: 'Last 3 days',
    [MessageKeys.RelativeDateIntervalLastWeek]: 'Last 7 days',
    [MessageKeys.RelativeDateIntervalLastTwoWeeks]: 'Last 14 days',
    [MessageKeys.RelativeDateIntervalThisWeek]: 'This week',
    [MessageKeys.RelativeDateIntervalThisPartialWeek]: 'This week',
    [MessageKeys.RelativeDateIntervalLastMonth]: 'Last 30 days',
    [MessageKeys.RelativeDateIntervalThisMonth]: 'This month',
    [MessageKeys.RelativeDateIntervalThisPartialMonth]: 'This month',
    [MessageKeys.RelativeDateIntervalLastQuarter]: 'Last 90 days',
    [MessageKeys.RelativeDateIntervalThisQuarter]: 'This quarter',
    [MessageKeys.RelativeDateIntervalThisPartialQuarter]: 'This quarter',
    [MessageKeys.RelativeDateIntervalLastYear]: 'Last 1 year',
    [MessageKeys.RelativeDateIntervalThisYear]: 'This year',
    [MessageKeys.RelativeDateIntervalThisPartialYear]: 'This year',
    [MessageKeys.RelativeDateIntervalNextWeek]: 'Coming 7 days',
    [MessageKeys.RelativeDateIntervalNextMonth]: 'Coming 30 days',
    [MessageKeys.RelativeDateIntervalOverdue]: 'Overdue',
    [MessageKeys.RelativeDateIntervalCustom]: 'Custom',
  },
};

export default en;
