/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react';
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@fcg-tech/regtech-components';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import {
  Category,
  Direction,
  InternalEntity,
  Subcontractor,
  WithChildren,
  WithMetadata,
} from '../../types';
import { SubcontractorsFieldTableRow } from './SubcontractorsFieldTableRow';
import {
  SubcontractorsFieldTableEmptyMessage,
  SubcontractorsFieldTableWrapper,
} from './SubcontractorsFieldTable.styles';
import { FieldCompare } from '../FieldCompare/FieldCompare';

interface SubcontractorsFieldTableProps {
  subcontractors: Array<WithChildren<Subcontractor>>;
  isEditEnabled: boolean;
  suppliers?: Array<WithMetadata<any>>; // TODO: describe supplier type
  categories?: Array<WithMetadata<Category>>;
  internalEntities?: Array<WithMetadata<InternalEntity>>;
  onEditRow: (subcontractorId: string) => void;
  onDeleteRow: (subcontractorId: string) => void;
  onMoveRow: (subcontractorId: string, direction: Direction) => void;
}

export const SubcontractorsFieldTable = ({
  subcontractors,
  isEditEnabled,
  suppliers = [],
  categories = [],
  internalEntities = [],
  onEditRow,
  onDeleteRow,
  onMoveRow,
}: SubcontractorsFieldTableProps) => {
  const { t } = useTranslation();
  const formatSupplierName = useCallback(
    (isExternal: boolean, supplierId: string | null) => {
      if (supplierId) {
        if (isExternal) {
          const externalSupplier = suppliers.find(
            (supplier) => supplier.metadata.id === supplierId,
          );
          if (externalSupplier) {
            return externalSupplier.data.supplierName;
          }
        } else {
          const internalSupplier = internalEntities.find(
            (internalEntity) => internalEntity.metadata.id === supplierId,
          );
          if (internalSupplier) {
            return internalSupplier?.name;
          }
        }
      }
      return '';
    },
    [suppliers, internalEntities],
  );

  const formatCategoryName = useCallback(
    (categoryOfActivity: string | null) => {
      if (!categoryOfActivity) {
        return '';
      }
      return (
        categories.find(
          (category) => category.metadata.id === categoryOfActivity,
        )?.name ?? ''
      );
    },
    [categories],
  );

  return (
    <SubcontractorsFieldTableWrapper>
      <TableHead>
        <TableRow>
          <TableCell>
            {t(MessageKeys.SubcontractorsFieldTableSupplierNameLabel)}
          </TableCell>
          <TableCell>
            {t(MessageKeys.SubcontractorsFieldTableCategoryLabel)}
          </TableCell>
          <TableCell>
            {t(
              MessageKeys.SubcontractorsFieldTableCountriesServicePerformedLabel,
            )}
          </TableCell>
          <TableCell>{t(MessageKeys.LabelActions)}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {subcontractors.length ? (
          subcontractors.map((subcontractor, index) => (
            <SubcontractorsFieldTableRow
              key={subcontractor.id}
              subcontractor={subcontractor}
              isEditEnabled={isEditEnabled}
              index={index}
              depth={0}
              numSiblings={subcontractors.length - 1}
              onEditRow={onEditRow}
              onDeleteRow={onDeleteRow}
              onMoveRow={onMoveRow}
              formatSupplierName={formatSupplierName}
              formatCategoryName={formatCategoryName}
            />
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={4}>
              <SubcontractorsFieldTableEmptyMessage>
                {t(
                  MessageKeys.SubcontractorsFieldTableNoSubcontractorsLabel,
                )}
              </SubcontractorsFieldTableEmptyMessage>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
      <FieldCompare name="serviceDetails.subcontractors" />
    </SubcontractorsFieldTableWrapper>
  );
};
