import React from 'react';
import { EnumDecision as DefaultEnumDecision } from '@fcg-tech/regtech-components';
import { FormikHelpers, FieldInputProps } from 'formik';

interface EnumDecisionProps extends React.ComponentPropsWithRef<typeof DefaultEnumDecision> {
  form: FormikHelpers<string>;
  field: FieldInputProps<string>;
}

export const EnumDecision = ({ field, form, ...props }: EnumDecisionProps) => {
  const handleChange = React.useCallback(
    (event) => {
      if (event.target.checked) {
        let { value } = event.target;
        try {
          value = JSON.parse(value);
          // eslint-disable-next-line no-empty
        } catch {}
        form.setFieldValue(field.name, value);
      }
    },
    [field.name, form],
  );

  return <DefaultEnumDecision {...props} value={field.value} onChange={handleChange} />;
};

EnumDecision.FieldLabel = DefaultEnumDecision.FieldLabel;
EnumDecision.FieldContainer = DefaultEnumDecision.FieldContainer;
