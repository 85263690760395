import { useTranslation } from 'react-i18next';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import {
  DataTable,
  DataTableColumnOptions,
  DataTableRowOptions,
  DataTableSortOrder,
  PrimaryButton,
  QuestionIcon,
  SpinningLoadingIcon,
  TooltipNext as Tooltip,
  useToggle,
} from '@fcg-tech/regtech-components';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useCategories } from '../../api/hooks/categoriesApiHooks';
import { GlobalActions } from '../../api/schema';
import { AccessControl } from '../../components/AccessControl';
import { ErrorMessage } from '../../components/ErrorBoundary';
import { MainLayoutPaddedContentWrapper } from '../../components/MainLayout';
import { PaginatedTableHeader } from '../../components/PaginatedTableHeader';
import {
  TableLoadingWrapper,
  TableNoContent,
  TableWrapper,
} from '../../components/tables';
import { routes } from '../../routes';
import { useTenant } from '../../states/tenantState';
import { MessageKeys } from '../../translations/messageKeys';
import { stringCompareFn } from '../../utils/compareFns';
import { CategoriesCreateModal } from './CategoriesCreateModal';
import { CategoriesTableRow, ColumnKeys, RowData } from './CategoriesTableRow';
import {
  CategoriesTableHeaderQuestionButton,
  CategoriesTableHeadingWrapper,
} from './CategoriesPage.styles';

export const CategoriesTable: FunctionComponent = () => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const navigate = useNavigate();
  const [showCreateModal, setShowCreateModal, toggleCreateModal] = useToggle();

  const { data, isValidating, error } = useCategories(tenant.id, {
    suspense: false,
  });
  const isLoading = !data && isValidating;

  const [sortOrder, setSortOrder] = useState<DataTableSortOrder<ColumnKeys>>({
    ascending: true,
    columnKey: 'name',
  });

  const columns = useMemo<DataTableColumnOptions<RowData, ColumnKeys>[]>(
    () => [
      {
        columnKey: 'name',
        label: t(MessageKeys.LabelName),
        resizable: false,
        sortable: true,
        initialSortAscending: true,
      },
    ],
    [t],
  );

  const dataRows = useMemo<DataTableRowOptions<RowData>[]>(() => {
    return data
      ?.map((category) => ({
        id: category.metadata.id,
        data: {
          name: category.name,
        },
      }))
      .sort((a, b) => {
        if (sortOrder?.ascending === undefined) {
          return 0;
        }
        switch (sortOrder?.columnKey) {
          case 'name':
            return stringCompareFn(
              a.data.name,
              b.data.name,
              sortOrder.ascending,
            );
        }
      });
  }, [sortOrder, data]);

  const handleRowClick = useCallback(
    (categoryId: string) => {
      navigate(
        constructUrl(routes.category, {
          tenantId: tenant.id,
          categoryId,
        }),
      );
    },
    [navigate, tenant.id],
  );

  const handleCreateCategoryModalRequestClose = useCallback(
    async (categoryId?: string) => {
      if (categoryId) {
        navigate(
          constructUrl(routes.category, {
            tenantId: tenant.id,
            categoryId,
          }),
        );
      }
      setShowCreateModal(false);
    },
    [navigate, setShowCreateModal, tenant.id],
  );

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <>
      {showCreateModal ? (
        <CategoriesCreateModal
          onRequestClose={handleCreateCategoryModalRequestClose}
        />
      ) : null}
      <MainLayoutPaddedContentWrapper>
        <PaginatedTableHeader
          totalResults={dataRows?.length ?? 0}
          heading={
            <CategoriesTableHeadingWrapper>
              {t(MessageKeys.CategoriesHeading)}
              <Tooltip
                placement="right"
                content={t(MessageKeys.CategoriesDescription)}
              >
                <CategoriesTableHeaderQuestionButton>
                  <QuestionIcon size="18" />
                </CategoriesTableHeaderQuestionButton>
              </Tooltip>
            </CategoriesTableHeadingWrapper>
          }
        >
          <AccessControl requiredPermissions={GlobalActions.CategoryAdd}>
            <PrimaryButton onClick={toggleCreateModal}>
              {t(MessageKeys.CategoriesCreateNewCategoryLabel)}
            </PrimaryButton>
          </AccessControl>
        </PaginatedTableHeader>
      </MainLayoutPaddedContentWrapper>
      <TableWrapper>
        <DataTable
          rows={dataRows}
          columns={columns}
          sortOrder={sortOrder.ascending !== undefined ? sortOrder : undefined}
          onSortOrderChange={setSortOrder}
          stickyHeader
        >
          {(row) => (
            <CategoriesTableRow
              key={row.id}
              row={row}
              onClick={handleRowClick}
            />
          )}
        </DataTable>
        {dataRows?.length === 0 ? (
          <TableNoContent>{t(MessageKeys.CategoriesNoItems)}</TableNoContent>
        ) : null}
        {isLoading ? (
          <TableLoadingWrapper>
            <SpinningLoadingIcon size="80" />
          </TableLoadingWrapper>
        ) : null}
      </TableWrapper>
    </>
  );
};
