import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import {
  PrimaryButton,
  SecondaryButton,
  Card,
  CardBody,
  CardTitle,
  PageHeader,
  PageLayout,
  PageLayoutPaddedContent,
  useEditContext,
  PageHeaderActionButton,
  AccessControl,
} from '@fcg-tech/regtech-components';
import { Trash2 } from 'lucide-react';
import { PolicyField } from '../../../components/PolicyField';
import {
  RoleDetailsSubForm,
  RolePolicySubForm,
} from '../../../components/SubForms';

export const RolePage = ({
  role,
  isSaving,
  onEdit,
  onSave,
  onCancel,
  onDelete,
}) => {
  const isEditEnabled = useEditContext();

  const pageTitle = React.useMemo(() => {
    return role.name || '(Unnamed)';
  }, [role]);

  const initialValues = React.useMemo(
    () => ({
      ...role,
      // eslint-disable-next-line react/prop-types
      policy: JSON.stringify(role.policy, null, 2),
    }),
    [role],
  );

  return (
    <Formik initialValues={initialValues} onSubmit={onSave}>
      {({ handleSubmit, dirty }) => (
        <PageLayout>
          <PageHeader title={pageTitle} breadcrumbs={[]}>
            {isEditEnabled ? (
              <>
                <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
                <PrimaryButton
                  onClick={handleSubmit}
                  loading={isSaving}
                  disabled={!dirty || isSaving}
                >
                  Submit
                </PrimaryButton>
              </>
            ) : (
              <>
                <AccessControl
                  permissions={role.permissions}
                  requiredPermissions={['iam:RoleDelete']}
                >
                  <PageHeaderActionButton onClick={onDelete}>
                    <Trash2 size="24" />
                  </PageHeaderActionButton>
                </AccessControl>
                <AccessControl
                  permissions={role.permissions}
                  requiredPermissions={['iam:RoleUpdate']}
                >
                  <PrimaryButton onClick={onEdit}>Edit</PrimaryButton>
                </AccessControl>
              </>
            )}
          </PageHeader>
          <PageLayoutPaddedContent>
            <Card>
              <CardBody>
                <CardTitle>Details</CardTitle>
                <Form>
                  <RoleDetailsSubForm />
                  {isEditEnabled ? (
                    <RolePolicySubForm />
                  ) : (
                    <PolicyField title="Policy" policy={role.policy} />
                  )}
                </Form>
              </CardBody>
            </Card>
          </PageLayoutPaddedContent>
        </PageLayout>
      )}
    </Formik>
  );
};

RolePage.propTypes = {
  role: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        action: PropTypes.string,
        resource: PropTypes.string,
      }),
    ),
  }).isRequired,
  isSaving: PropTypes.bool,
  onEdit: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
};

RolePage.defaultProps = {
  isSaving: false,
  onEdit: null,
  onSave: null,
  onCancel: null,
  onDelete: null,
};
