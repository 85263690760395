import { DatepickerTheme } from '@fcg-tech/regtech-types';
import styled, { css } from 'styled-components';
import { UnstyledButton } from '../Button';
import { theme as defaultDatepickerTheme } from '../DatePicker/theme';
import { InfoCircle } from '../InfoCircle';

export const DateFieldWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex: 1;
    width: 100%;
  `}
`;

export const DateFieldTextFieldInputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const DatePickerWrapper = styled.div`
  position: fixed;
  z-index: 100;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  border-radius: 3px;
`;

export const DateFieldRemoveDateButton = styled(UnstyledButton)`
  position: absolute;
  right: 0.8rem;
  top: 50%;
  transform: translateY(-50%);
`;

export const ReadOnlyContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
`;

export const ReadOnlyLabel = styled.div`
  display: flex;
  flex: 1;
  font-size: 1.4rem;
  padding: 1rem 1rem 1rem 0;
  border-radius: 3px;
  min-width: 7rem;
`;

export const StyledInfoCircle = styled(InfoCircle)`
  margin-left: 1rem;
  margin-top: 1rem;
`;
