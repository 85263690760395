/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetReportResponse,
  ListAllReportsResponse,
  ListReportAgreementsResponse,
  ListReportSuppliersResponse,
  Report,
} from '../models';
import {
    GetReportResponseFromJSON,
    GetReportResponseToJSON,
    ListAllReportsResponseFromJSON,
    ListAllReportsResponseToJSON,
    ListReportAgreementsResponseFromJSON,
    ListReportAgreementsResponseToJSON,
    ListReportSuppliersResponseFromJSON,
    ListReportSuppliersResponseToJSON,
    ReportFromJSON,
    ReportToJSON,
} from '../models';

export interface CreateReportRequest {
    report?: Report;
}

export interface DeleteReportRequest {
    reportId: string;
}

export interface GetReportRequest {
    reportId: string;
}

export interface ListReportAgreementsRequest {
    query?: string;
}

export interface ListReportAgreementsCsvRequest {
    query: string;
}

export interface ListReportSuppliersRequest {
    query?: string;
}

export interface ListReportSuppliersCsvRequest {
    query: string;
}

export interface UpdateReportRequest {
    reportId: string;
    report?: Report;
}

/**
 * ReportsApi - interface
 * 
 * @export
 * @interface ReportsApiInterface
 */
export interface ReportsApiInterface {
    /**
     * 
     * @summary Create a Report
     * @param {Report} [report] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    createReportRaw(requestParameters: CreateReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Create a Report
     */
    createReport(requestParameters: CreateReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Delete report
     * @param {string} reportId The id of the report to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    deleteReportRaw(requestParameters: DeleteReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete report
     */
    deleteReport(requestParameters: DeleteReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Get report
     * @param {string} reportId The id of the report to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    getReportRaw(requestParameters: GetReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetReportResponse>>;

    /**
     * Get report
     */
    getReport(requestParameters: GetReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetReportResponse>;

    /**
     * 
     * @summary List all reports
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    listAllReportsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListAllReportsResponse>>;

    /**
     * List all reports
     */
    listAllReports(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListAllReportsResponse>;

    /**
     * 
     * @summary List all agreements data to create a report
     * @param {string} [query] an JMESPath query that should be run on the backend
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    listReportAgreementsRaw(requestParameters: ListReportAgreementsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListReportAgreementsResponse>>;

    /**
     * List all agreements data to create a report
     */
    listReportAgreements(requestParameters: ListReportAgreementsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListReportAgreementsResponse>;

    /**
     * 
     * @summary List all agreement data in csv format to create a report
     * @param {string} query an JMESPath query that should be run on the backend
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    listReportAgreementsCsvRaw(requestParameters: ListReportAgreementsCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>>;

    /**
     * List all agreement data in csv format to create a report
     */
    listReportAgreementsCsv(requestParameters: ListReportAgreementsCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string>;

    /**
     * 
     * @summary List all supplier data to create a report
     * @param {string} [query] an JMESPath query that should be run on the backend
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    listReportSuppliersRaw(requestParameters: ListReportSuppliersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListReportSuppliersResponse>>;

    /**
     * List all supplier data to create a report
     */
    listReportSuppliers(requestParameters: ListReportSuppliersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListReportSuppliersResponse>;

    /**
     * 
     * @summary List all supplier data in csv format to create a report
     * @param {string} query an JMESPath query that should be run on the backend
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    listReportSuppliersCsvRaw(requestParameters: ListReportSuppliersCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>>;

    /**
     * List all supplier data in csv format to create a report
     */
    listReportSuppliersCsv(requestParameters: ListReportSuppliersCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string>;

    /**
     * 
     * @summary Update report
     * @param {string} reportId The id of the report to retrieve
     * @param {Report} [report] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    updateReportRaw(requestParameters: UpdateReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Update report
     */
    updateReport(requestParameters: UpdateReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class ReportsApi extends runtime.BaseAPI implements ReportsApiInterface {

    /**
     * Create a Report
     */
    async createReportRaw(requestParameters: CreateReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/oasys/reports`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReportToJSON(requestParameters.report),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a Report
     */
    async createReport(requestParameters: CreateReportRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createReportRaw(requestParameters, initOverrides);
    }

    /**
     * Delete report
     */
    async deleteReportRaw(requestParameters: DeleteReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling deleteReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/reports/{reportId}`.replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete report
     */
    async deleteReport(requestParameters: DeleteReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteReportRaw(requestParameters, initOverrides);
    }

    /**
     * Get report
     */
    async getReportRaw(requestParameters: GetReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetReportResponse>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling getReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/reports/{reportId}`.replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetReportResponseFromJSON(jsonValue));
    }

    /**
     * Get report
     */
    async getReport(requestParameters: GetReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetReportResponse> {
        const response = await this.getReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all reports
     */
    async listAllReportsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListAllReportsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/reports`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListAllReportsResponseFromJSON(jsonValue));
    }

    /**
     * List all reports
     */
    async listAllReports(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListAllReportsResponse> {
        const response = await this.listAllReportsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List all agreements data to create a report
     */
    async listReportAgreementsRaw(requestParameters: ListReportAgreementsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListReportAgreementsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/reports/agreements`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListReportAgreementsResponseFromJSON(jsonValue));
    }

    /**
     * List all agreements data to create a report
     */
    async listReportAgreements(requestParameters: ListReportAgreementsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListReportAgreementsResponse> {
        const response = await this.listReportAgreementsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all agreement data in csv format to create a report
     */
    async listReportAgreementsCsvRaw(requestParameters: ListReportAgreementsCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError('query','Required parameter requestParameters.query was null or undefined when calling listReportAgreementsCsv.');
        }

        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/reports/agreementsCSV`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * List all agreement data in csv format to create a report
     */
    async listReportAgreementsCsv(requestParameters: ListReportAgreementsCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.listReportAgreementsCsvRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all supplier data to create a report
     */
    async listReportSuppliersRaw(requestParameters: ListReportSuppliersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListReportSuppliersResponse>> {
        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/reports/suppliers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListReportSuppliersResponseFromJSON(jsonValue));
    }

    /**
     * List all supplier data to create a report
     */
    async listReportSuppliers(requestParameters: ListReportSuppliersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListReportSuppliersResponse> {
        const response = await this.listReportSuppliersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all supplier data in csv format to create a report
     */
    async listReportSuppliersCsvRaw(requestParameters: ListReportSuppliersCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError('query','Required parameter requestParameters.query was null or undefined when calling listReportSuppliersCsv.');
        }

        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/reports/suppliersCSV`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * List all supplier data in csv format to create a report
     */
    async listReportSuppliersCsv(requestParameters: ListReportSuppliersCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.listReportSuppliersCsvRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update report
     */
    async updateReportRaw(requestParameters: UpdateReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling updateReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/oasys/reports/{reportId}`.replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ReportToJSON(requestParameters.report),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update report
     */
    async updateReport(requestParameters: UpdateReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateReportRaw(requestParameters, initOverrides);
    }

}
