import styled from 'styled-components';
import color from 'color';

export const ToggleSwitchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ToggleSwitchInner = styled.div`
  position: relative;
  display: flex;
`;

export const ToggleSwitchLabel = styled.label`
  font-size: 1.4rem;
  font-weight: 500;
  &:first-child {
    margin-right: 1rem;
  }

  &:last-child {
    margin-left: 1rem;
  }
`;

export const ToggleSwitchDot = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 15px;
  border: 1px solid ${({ theme }) => theme.palette?.MainColor?.main ?? '#000'};
  background-color: ${({ theme }) =>
    color(theme.palette?.MainColor?.main ?? '#000')
      .alpha(0.05)
      .hsl()
      .string()};
  box-shadow: inset 0 0 3px 0px
    ${({ theme }) =>
      color(theme.palette?.MainColor?.main ?? '#000')
        .alpha(0.6)
        .hsl()
        .string()};

  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: ${({ theme }) => theme.palette?.MainColor?.main ?? '#000'};
    box-shadow: 0 0 4px 1px rgba(255, 255, 255, 0.5);
    transition: 0.2s;
  }
`;

export const ToggleSwitchInput = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  margin: 0;
  cursor: pointer;
  &:checked + ${ToggleSwitchDot} {
    background: ${({ theme }) => theme.palette?.MainColor?.main ?? '#000'};
    &::after {
      content: '';
      background: ${({ theme }) =>
        theme.palette?.MainColor?.mainContrast ?? '#fff'};
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 19px;
      transition: 0.1s;
    }
  }
`;
