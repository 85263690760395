import styled from 'styled-components';

export const NotificationsSettingsDateDescription = styled.div`
  font-size: 1.3rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 1rem;
`;

export const NotificationsSettingsDateDescriptionWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
`;

export const NotificationsSettingsDateSectionHeading = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
`;

export const NotificationsSettingsDateToggleSwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  & > label {
    margin: 0;
    padding: 0;
    margin-right: 1rem;
    font-size: 1.4rem;
    font-weight: 500;
  }
`;

export const NotificationsSettingsDateDaysAheadWrapper = styled.div`
  margin-top: 1.4rem;
`;

export const NotificationsSettingsDateDaysAheadInner = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin-bottom: 0.4rem;
  & > b {
    font-size: 1.4rem;
  }
  & > * + * {
    margin-left: 1rem;
  }
`;

export const NotificationsSettingsDateDaysAheadFieldWrapper = styled.div`
  width: 14rem;
`;

export const NotificationsSettingsDateDisabledLabel = styled.span`
  font-weight: 400;
  color: gray;
  margin-left: 1rem;
`;
