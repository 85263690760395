/* eslint-disable @typescript-eslint/ban-types */
// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../@types/react-table/index.d.ts" />

import { classNames } from '@fcg-tech/regtech-utils';
import React from 'react';
import { TableBodyPropGetter, TableBodyProps } from 'react-table';
import { DataTableRow } from './DataTableRow';
import type { CommonRowProps, RowRenderer, Row } from './types';

interface DataTableBodyProps<D extends object = {}> extends CommonRowProps<D> {
  getTableBodyProps: (
    propGetter?: TableBodyPropGetter<D> | undefined,
  ) => TableBodyProps;
  prepareRow: (row: Row<D>) => void;
  rows: Array<Row<D>>;
  scrollRef?: React.MutableRefObject<HTMLDivElement>;
  isAnyColumnResizing?: boolean;
  Row?: RowRenderer<D>;
  onScroll: (event: React.UIEvent<HTMLDivElement>) => void;
}

const DataTableBody = <D extends object = {}>({
  getTableBodyProps,
  prepareRow,
  rows,
  renderExpanded,
  scrollRef,
  expanded,
  isAnyColumnResizing,
  Row,
  onRowClick,
  onScroll,
}: DataTableBodyProps<D>) => {
  return (
    <div
      {...getTableBodyProps()}
      className={classNames(
        'rowgroup',
        isAnyColumnResizing && 'resizing-columns',
      )}
      onScroll={onScroll}
      ref={scrollRef}
    >
      {rows.map((row, i) => {
        prepareRow(row);
        return Row ? (
          <Row
            key={row.getRowProps().key}
            row={row}
            expanded={expanded}
            renderExpanded={renderExpanded}
            isAnyColumnResizing={isAnyColumnResizing}
            onRowClick={onRowClick}
          />
        ) : (
          <DataTableRow
            key={row.getRowProps().key}
            row={row}
            expanded={expanded}
            renderExpanded={renderExpanded}
            isAnyColumnResizing={isAnyColumnResizing}
            onRowClick={onRowClick}
          />
        );
      })}
    </div>
  );
};

const MemoizedDataTableBody = React.memo(
  DataTableBody,
  (prevProps, nextProps) =>
    (nextProps.isAnyColumnResizing && prevProps.isAnyColumnResizing === true) ||
    prevProps.isAnyColumnResizing === undefined,
) as typeof DataTableBody;

export { MemoizedDataTableBody as DataTableBody };
