import styled from 'styled-components';
import { BellIcon } from '@fcg-tech/regtech-components';

export const FieldCompareMessage = styled.p`
  color: ${({ theme }) => theme.colors?.warning?.main ?? '#f1720a'};
  font-size: 1.4rem;
  font-weight: 400;
  margin: 0;
  margin-top: 0.75rem;
  display: flex;
  align-items: center;
`;

export const FieldCompareIcon = styled(BellIcon)`
  color: ${({ theme }) => theme.colors?.warning?.main ?? '#f1720a'};
  margin-right: 0.75rem;
`;
