/* eslint-disable no-nested-ternary */
import { classNames } from '@fcg-tech/regtech-utils';
import React, { FunctionComponent } from 'react';
import { MenuWrapper, MenuItemList, MenuItem } from './Menu.styles';

export interface MenuProps extends React.HTMLAttributes<HTMLDivElement> {
  narrow?: boolean;
  alignRight?: boolean;
}

export const Menu = React.forwardRef<HTMLDivElement, MenuProps>(
  ({ children, narrow, alignRight, className, ...props }, ref) => (
    <MenuWrapper
      ref={ref}
      className={classNames(
        className,
        narrow && 'narrow',
        alignRight && 'align-right',
      )}
      {...props}
    >
      <MenuItemList>{children}</MenuItemList>
    </MenuWrapper>
  ),
);
