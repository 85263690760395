import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  PillWrapper,
  PillIconButton,
  PillIconButtonContainer,
  StyledCrossIcon,
} from './Pill.styles';

export interface PillProps {
  removable?: boolean;
  value?: string | number;
  onRemove: (value: string | number) => void;
}

export const Pill: FunctionComponent<PropsWithChildren<PillProps>> = ({
  children,
  removable = true,
  value,
  onRemove,
  ...props
}) => {
  const handleRemove = React.useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (onRemove) {
        onRemove(value);
      }
    },
    [onRemove, value]
  );

  return (
    <PillWrapper {...props}>
      {children}
      {removable && (
        <PillIconButton onClick={handleRemove}>
          <PillIconButtonContainer>
            <StyledCrossIcon />
          </PillIconButtonContainer>
        </PillIconButton>
      )}
    </PillWrapper>
  );
};
