import styled from 'styled-components';
import { Menu } from './Menu';

export const AbsoluteMenu = styled(Menu)`
  position: absolute;
  top: 100%;
  margin-top: 2px;
  left: 0;
  z-index: 10;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.4);

  &.top {
    top: initial;
    bottom: 100%;
    margin-top: initial;
    margin-bottom: 2px;
  }

  &.right {
    left: initial;
    right: 0;
  }
`;
