import { useTranslation } from 'react-i18next';
import React, { FunctionComponent, useCallback } from 'react';
import {
  FilterDateLabel,
  FilterDateLabelText,
  FilterDateWrapper,
  FilterDateInput,
} from './Filter.styles';
import { FilterMessageKeys } from './lang/filterMessageKeys';
import { DateRange } from './types';

interface FilterDateRangeProps {
  from?: Date;
  to?: Date;
  minFrom?: Date | null;
  maxTo?: Date | null;
  disabled?: boolean;
  onChange: (dates: DateRange) => void;
}

export const FilterDateRange: FunctionComponent<FilterDateRangeProps> = ({
  from,
  to,
  minFrom,
  maxTo,
  disabled = false,
  onChange,
}) => {
  const { t } = useTranslation();
  const handleFromChange = useCallback(
    (date?: Date | null) => {
      onChange({
        from: date ?? undefined,
        to,
      });
    },
    [onChange, to],
  );

  const handleToChange = useCallback(
    (date?: Date | null) => {
      onChange({
        to: date ?? undefined,
        from,
      });
    },
    [from, onChange],
  );

  return (
    <FilterDateWrapper>
      <FilterDateLabel>
        <FilterDateLabelText>
          {t(FilterMessageKeys.LabelFrom)}:
        </FilterDateLabelText>
        <FilterDateInput
          isEditEnabled={!disabled}
          value={from}
          onChange={handleFromChange}
          onReset={handleFromChange}
          textFieldInputClassName="date-field-text-input"
          max={to ?? maxTo}
          min={minFrom}
        />
      </FilterDateLabel>
      <FilterDateLabel>
        <FilterDateLabelText>
          {t(FilterMessageKeys.LabelTo)}:
        </FilterDateLabelText>
        <FilterDateInput
          isEditEnabled={!disabled}
          value={to}
          onChange={handleToChange}
          onReset={handleToChange}
          textFieldInputClassName="date-field-text-input"
          min={from ?? minFrom}
          max={maxTo}
        />
      </FilterDateLabel>
    </FilterDateWrapper>
  );
};
