import { useTranslation } from 'react-i18next';
import {
  AbsoluteMenu,
  DropdownButton,
  Menu,
  MenuButton,
  MenuItem,
  MoreDotsIcon,
  PinIcon,
  PinPinnedIcon,
  PrimaryButton,
  useClickOutside,
  useToggle,
} from '@fcg-tech/regtech-components';
import { classNames } from '@fcg-tech/regtech-utils';
import React, { ReactNode, useCallback, useRef, useState } from 'react';
import {
  FilterActionRowIconWrapper,
  FilterActionsRow,
  FilterActionsRowButton,
  FilterActionsRowWrapper,
  FilterClearAllButton,
  FilterRow,
  FilterRowList,
  FilterStickyHeader,
  FilterWrapper,
} from './Filter.styles';
import { FilterCheckbox } from './FilterCheckbox';
import { FilterDateRangeInterval } from './FilterDateRangeInterval';
import { FilterSelect } from './FilterSelect';
import { FilterStoredFilterSelect } from './FilterStoredFilterSelect';
import { FilterTextField } from './FilterTextField';
import { FilterMessageKeys } from './lang/filterMessageKeys';
import {
  FilterCommonProps,
  FilterValues,
  FilterWidget,
  HandleClear,
  HandleExclude,
  HandleFilterChange,
  HandleFilterClear,
  HandleFilterValueChange,
  StoredFilter,
} from './types';

const filterWidgets = [
  FilterSelect,
  FilterCheckbox,
  FilterDateRangeInterval,
  FilterTextField,
];

const isReactComp = (o: unknown): o is JSX.Element =>
  !!o &&
  typeof o === 'object' &&
  o !== null &&
  Reflect.has(o, 'props') &&
  Reflect.has(o, 'type');

export interface FilterProps<T extends FilterValues>
  extends Omit<
    FilterCommonProps<T>,
    'isExcluding' | 'onChange' | 'onClear' | 'onExclude'
  > {
  filterId?: string;
  storedFilters?: Array<StoredFilter<T>>;
  className?: string;
  isSelectedStoredFilterPinned?: boolean;
  getNotification?: () => JSX.Element | ReactNode;
  saveFilterDisabled?: boolean;
  onFilterChange: HandleFilterChange;
  onFilterClear?: HandleFilterClear;
  onFilterDeleteClick?: () => void;
  onFilterEditNameClick?: () => void;
  onFilterLoad?: (filterId: string | null) => void;
  onFilterPinClick?: () => void;
  onFilterSaveClick?: () => void;
  onFilterUpdateClick?: () => void;
  onFilterValueChange: HandleFilterValueChange<T>;
  onFilterValueClear: HandleClear<T>;
  onFilterValueExclude?: HandleExclude<T>;
}

export const Filter = <T extends FilterValues>({
  filter,
  filterId,
  clearLabel,
  excludeLabel,
  getNoResultsMessage,
  loadingMessage,
  noItemsLabel,
  excludePropertyKey,
  storedFilters,
  isSelectedStoredFilterPinned,
  getNotification,
  saveFilterDisabled,
  className,
  onFilterClear,
  onFilterDeleteClick,
  onFilterEditNameClick,
  onFilterLoad,
  onFilterPinClick,
  onFilterSaveClick,
  onFilterUpdateClick,
  onFilterValueChange,
  onFilterValueClear,
  onFilterValueExclude,
  children,
}: React.PropsWithChildren<FilterProps<T>>) => {
  const { t } = useTranslation();

  const storedFilterActionsWrapper = useRef<
    HTMLDivElement | null | undefined
  >() as React.MutableRefObject<HTMLDivElement>;

  const [showStoredFilterActionsMenu, , toggleShowStoredFilterActionsMenu] =
    useToggle();

  const handleClickOutside = useCallback(() => {
    if (showStoredFilterActionsMenu) {
      toggleShowStoredFilterActionsMenu();
    }
  }, [showStoredFilterActionsMenu, toggleShowStoredFilterActionsMenu]);

  useClickOutside(storedFilterActionsWrapper, handleClickOutside);

  const handleSaveFilterClick = useCallback(
    (setOpen: React.Dispatch<React.SetStateAction<boolean>>) => {
      setOpen(false);
      onFilterSaveClick?.();
    },
    [onFilterSaveClick],
  );

  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = useCallback((event: React.UIEvent<HTMLDivElement>) => {
    setIsScrolled(event.currentTarget.scrollTop > 2);
  }, []);

  return (
    <FilterWrapper className={className}>
      <FilterRowList onScroll={handleScroll}>
        {onFilterSaveClick || onFilterUpdateClick || onFilterClear ? (
          <FilterStickyHeader className={classNames(isScrolled && 'scrolled')}>
            {onFilterSaveClick && onFilterUpdateClick ? (
              <DropdownButton
                onClick={onFilterUpdateClick}
                title={t(FilterMessageKeys.LabelUpdateFilter)}
              >
                {(setOpen) => (
                  <Menu narrow alignRight>
                    <MenuItem onClick={() => handleSaveFilterClick(setOpen)}>
                      {t(FilterMessageKeys.LabelSaveFilter)}
                    </MenuItem>
                  </Menu>
                )}
              </DropdownButton>
            ) : null}
            {onFilterSaveClick && !onFilterUpdateClick ? (
              <PrimaryButton
                onClick={onFilterSaveClick}
                disabled={saveFilterDisabled}
              >
                {t(FilterMessageKeys.LabelSaveFilter)}
              </PrimaryButton>
            ) : null}
            {!onFilterSaveClick && onFilterUpdateClick ? (
              <PrimaryButton onClick={onFilterUpdateClick}>
                {t(FilterMessageKeys.LabelUpdateFilter)}
              </PrimaryButton>
            ) : null}
            {onFilterClear ? (
              <FilterClearAllButton role="button" onClick={onFilterClear}>
                {t(FilterMessageKeys.LabelClearFilter)}
              </FilterClearAllButton>
            ) : null}
          </FilterStickyHeader>
        ) : null}
        {getNotification?.()}

        {onFilterLoad && storedFilters ? (
          <FilterRow>
            <FilterStoredFilterSelect
              clearLabel={clearLabel}
              storedFilters={storedFilters}
              storedFilterId={filterId}
              onChange={onFilterLoad}
            />
            {filterId &&
            (onFilterEditNameClick ||
              onFilterDeleteClick ||
              onFilterPinClick) ? (
              <FilterActionsRow>
                <FilterActionsRowWrapper ref={storedFilterActionsWrapper}>
                  <FilterActionsRowButton
                    onClick={toggleShowStoredFilterActionsMenu}
                  >
                    <MoreDotsIcon size="18" className="rotate-90" />
                  </FilterActionsRowButton>
                  {showStoredFilterActionsMenu ? (
                    <AbsoluteMenu>
                      {onFilterEditNameClick ? (
                        <MenuButton onClick={onFilterEditNameClick}>
                          {t(FilterMessageKeys.LabelEditFilterName)}
                        </MenuButton>
                      ) : null}
                      {onFilterDeleteClick ? (
                        <MenuButton onClick={onFilterDeleteClick}>
                          {t(FilterMessageKeys.LabelDeleteFilter)}
                        </MenuButton>
                      ) : null}
                    </AbsoluteMenu>
                  ) : null}
                </FilterActionsRowWrapper>
                {onFilterPinClick ? (
                  <FilterActionsRowButton onClick={onFilterPinClick}>
                    {t(
                      isSelectedStoredFilterPinned
                        ? FilterMessageKeys.UnpinFilterButtonLabel
                        : FilterMessageKeys.PinFilterButtonLabel,
                    )}
                    <FilterActionRowIconWrapper>
                      {isSelectedStoredFilterPinned ? (
                        <PinIcon size="16" />
                      ) : (
                        <PinPinnedIcon size="16" />
                      )}
                    </FilterActionRowIconWrapper>
                  </FilterActionsRowButton>
                ) : null}
              </FilterActionsRow>
            ) : null}
          </FilterRow>
        ) : null}
        {React.Children.map(children, (child) => {
          if (
            child &&
            isReactComp(child) &&
            (filterWidgets.includes(child.type as never) ||
              (child.type as FilterWidget<unknown, FilterValues>)
                .isFilterWidget)
          ) {
            const props: FilterCommonProps<T> = {
              filter,
              filterId,
              clearLabel,
              excludeLabel,
              getNoResultsMessage,
              loadingMessage,
              noItemsLabel,
              excludePropertyKey,
              onChange: onFilterValueChange,
              onClear: onFilterValueClear,
              onExclude: onFilterValueExclude,
              ...child.props,
            };

            return (
              <FilterRow
                className={classNames(child.type === FilterCheckbox && 'slim')}
              >
                {React.cloneElement(child, props)}
              </FilterRow>
            );
          }
          return child;
        })}
      </FilterRowList>
    </FilterWrapper>
  );
};
