import React from 'react';
import { IconProps } from '../../types/icons';

export const ChainIcon = ({
  size = '24',
  color = 'currentColor',
  ...props
}: IconProps) => (
  <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7753 2.75071C13.9069 1.65776 15.4225 1.05299 16.9957 1.06666C18.5689 1.08033 20.0738 1.71135 21.1862 2.8238C22.2986 3.93624 22.9297 5.44112 22.9433 7.0143C22.957 8.58748 22.3522 10.1031 21.2593 11.2347L21.2471 11.2471L18.2472 14.247C17.639 14.8555 16.907 15.326 16.1009 15.6267C15.2947 15.9274 14.4334 16.0512 13.5752 15.9898C12.717 15.9283 11.8821 15.683 11.1271 15.2705C10.3721 14.858 9.71458 14.2879 9.19924 13.599C8.86843 13.1567 8.95877 12.53 9.40102 12.1992C9.84327 11.8684 10.47 11.9588 10.8008 12.401C11.1443 12.8603 11.5827 13.2404 12.086 13.5154C12.5894 13.7904 13.146 13.9539 13.7181 13.9949C14.2902 14.0359 14.8644 13.9533 15.4018 13.7528C15.9393 13.5524 16.4273 13.2387 16.8328 12.833L19.8264 9.83937C20.5514 9.0856 20.9525 8.07773 20.9434 7.03168C20.9343 5.98289 20.5136 4.97964 19.772 4.23801C19.0304 3.49638 18.0271 3.0757 16.9783 3.06659C15.9318 3.05749 14.9235 3.4589 14.1696 4.18453L12.455 5.88916C12.0634 6.27855 11.4302 6.2767 11.0408 5.88504C10.6515 5.49338 10.6533 4.86021 11.045 4.47083L12.765 2.76083L12.7753 2.75071Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.89915 8.37328C8.70526 8.07258 9.56661 7.94876 10.4248 8.01022C11.283 8.07168 12.1179 8.31698 12.8729 8.72949C13.6279 9.142 14.2854 9.71206 14.8008 10.401C15.1316 10.8433 15.0412 11.47 14.599 11.8008C14.1567 12.1316 13.53 12.0412 13.1992 11.599C12.8557 11.1397 12.4173 10.7596 11.914 10.4846C11.4106 10.2096 10.854 10.0461 10.2819 10.0051C9.7098 9.96413 9.13557 10.0467 8.59816 10.2471C8.06075 10.4476 7.57274 10.7613 7.16724 11.167L4.17359 14.1606C3.44856 14.9144 3.0475 15.9223 3.05659 16.9683C3.06571 18.0171 3.48638 19.0203 4.22802 19.762C4.96965 20.5036 5.9729 20.9243 7.02168 20.9334C8.06773 20.9425 9.0756 20.5414 9.82937 19.8164L11.5329 18.1129C11.9234 17.7224 12.5566 17.7224 12.9471 18.1129C13.3376 18.5034 13.3376 19.1366 12.9471 19.5271L11.2371 21.2371L11.2247 21.2493C10.0931 22.3422 8.57749 22.947 7.00431 22.9333C5.43113 22.9197 3.92625 22.2886 2.8138 21.1762C1.70135 20.0637 1.07034 18.5589 1.05667 16.9857C1.043 15.4125 1.64776 13.8969 2.74071 12.7653L2.75289 12.7529L5.75277 9.75302C5.75272 9.75306 5.75281 9.75297 5.75277 9.75302C6.361 9.1446 7.0931 8.67396 7.89915 8.37328Z"
      fill={color}
    />
  </svg>
);
