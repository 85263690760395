import { SideBarContextProvider } from '@fcg-tech/regtech-components';
import { FunctionComponent } from 'react';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { IEBlocker } from '../../components/IEBlocker';
import { MainLayout } from '../../components/MainLayout';
import { SessionTimeout } from '../../components/SessionTimeout/SessionTimeout';
import { SuspenseLoader } from '../../components/SuspenseLoader';
import { AccessControlWrapper } from './AccessControlWrapper';
import { AppRoutes } from './AppRoutes';

export const AuthenticatedApp: FunctionComponent = () => {
  return (
    <AccessControlWrapper>
      <IEBlocker>
        <SessionTimeout />
        <SuspenseLoader className="maximized">
          <SideBarContextProvider>
            <MainLayout>
              <ErrorBoundary>
                <AppRoutes />
              </ErrorBoundary>
            </MainLayout>
          </SideBarContextProvider>
        </SuspenseLoader>
      </IEBlocker>
    </AccessControlWrapper>
  );
};
