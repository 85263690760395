import React, { useCallback, useMemo } from 'react';
import { FormikHelpers, FieldInputProps } from 'formik';
import {
  TextField,
  Select,
  itemLabelGetter,
  itemKeyGetter,
} from '@fcg-tech/regtech-components';
import { Amount } from '@fcg-tech/regtech-types/oasys';
import {
  CurrencyAmountFieldWrapper,
  CurrencyAmountFieldInner,
  CurrencyAmountFieldReadOnlyWrapper,
  CurrencyAmountFieldReadOnlyValue,
  StyledInfoCircle,
} from './CurrencyAmountField.styles';

interface CurrencyAmountFieldProps {
  form: FormikHelpers<Amount>;
  field: FieldInputProps<Amount>;
  items: React.ComponentPropsWithoutRef<typeof Select>['items'];
  isEditEnabled?: boolean;
  info: string;
  id: string;
}

export const CurrencyAmountField = ({
  form,
  field,
  items,
  isEditEnabled = true,
  info,
  id,
}: CurrencyAmountFieldProps) => {
  const selectedCurrencyLabel = useMemo(() => {
    const selected = items.find(
      (item) => itemKeyGetter(item) === field.value?.currency,
    );
    return selected ? itemLabelGetter(selected) : '';
  }, [items, field.value]);

  const handleAmountChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value;
      form.setFieldValue(`${field.name}.amount`, value);
    },
    [form, field.name],
  );

  const handleCurrencyChange = useCallback(
    (value) => form.setFieldValue(`${field.name}.currency`, value),
    [form, field.name],
  );

  return (
    <CurrencyAmountFieldWrapper>
      {isEditEnabled ? (
        <>
          <CurrencyAmountFieldInner>
            <TextField
              id={id}
              value={field.value?.amount}
              onChange={handleAmountChange}
            />
          </CurrencyAmountFieldInner>
          <Select
            items={items}
            value={field.value?.currency ?? undefined}
            onChange={handleCurrencyChange}
            info={info}
            typeahead
          />
        </>
      ) : (
        <CurrencyAmountFieldReadOnlyWrapper>
          <CurrencyAmountFieldReadOnlyValue>
            {field.value?.amount}
          </CurrencyAmountFieldReadOnlyValue>
          <CurrencyAmountFieldReadOnlyValue>
            {selectedCurrencyLabel}
          </CurrencyAmountFieldReadOnlyValue>
          <StyledInfoCircle info={info} />
        </CurrencyAmountFieldReadOnlyWrapper>
      )}
    </CurrencyAmountFieldWrapper>
  );
};
