import { DataTableColumn } from '@fcg-tech/regtech-datatable';
import { encode } from 'js-base64';
import { parse } from 'json2csv';
import {
  AgreementListItem,
  AgreementTableColumns,
  SupplierListItem,
  SupplierTableColumns,
} from '../types';
import { stringCompareFn } from './compareFns';
import { formatBoolean, formatDate, formatStatus } from './formatters';

export const createCsvExport = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>,
  fields: Array<{ value: string; label: string }>,
) => {
  return {
    data: encode(parse(data, { fields, withBOM: true, delimiter: ';' })),
    contentType: 'text/csv;charset=utf-8',
  };
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const convertDataTableColumnToCsvField = <D extends object = {}>(
  column: DataTableColumn<D>,
): { value: string; label: string } => {
  return {
    value: column.id,
    label: typeof column.Header === 'string' ? column.Header : '',
  };
};

export const createAgreementTableExport = (
  columns: Array<DataTableColumn<AgreementListItem>>,
  agreements: Array<AgreementListItem>,
) => {
  const fields = columns.map(convertDataTableColumnToCsvField);

  const data = agreements
    .sort((a, b) => stringCompareFn(a.name, b.name, true))
    .map((agreement) => ({
      [AgreementTableColumns.Name]: agreement.name,
      [AgreementTableColumns.AgreementType]: agreement.agreementType,
      [AgreementTableColumns.FunctionCategory]: agreement.functionCategory,
      [AgreementTableColumns.BriefDescription]: agreement.briefDescription,
      [AgreementTableColumns.ContractOwner]: agreement.contractOwner,
      [AgreementTableColumns.SupplierName]: agreement.supplierName,
      [AgreementTableColumns.PartyToAgreement]: agreement.partyToAgreement,
      [AgreementTableColumns.InternalReference]: agreement.internalReference,
      [AgreementTableColumns.Cabinet]: agreement.cabinet,
      [AgreementTableColumns.Status]: formatStatus(agreement.status),
      [AgreementTableColumns.StartDate]: formatDate(agreement.startDate),
      [AgreementTableColumns.EndDate]: formatDate(agreement.endDate),
      [AgreementTableColumns.LatestReview]: formatDate(agreement.latestReview),
      [AgreementTableColumns.NextReview]: formatDate(agreement.nextReview),
      [AgreementTableColumns.ArchivingReference]: agreement.archivingReference,
      [AgreementTableColumns.CompetentAuthorityNotified]: formatBoolean(
        agreement.competentAuthorityNotified,
      ),
      [AgreementTableColumns.IsOutsourcingArrangement]: formatBoolean(
        agreement.isOutsourcingArrangement,
      ),
      [AgreementTableColumns.IsCritical]: formatBoolean(agreement.isCritical),
      [AgreementTableColumns.NrOfComments]:
        agreement.nrOfComments > 0 ? agreement.nrOfComments : '',

      [AgreementTableColumns.IsPersonalDataProcessed]: formatBoolean(
        agreement.isPersonalDataProcessed,
      ),
      [AgreementTableColumns.IsPersonalDataTransferred]: formatBoolean(
        agreement.isPersonalDataTransferred,
      ),
      [AgreementTableColumns.IsProvidedAsCloudService]: formatBoolean(
        agreement.isProvidedAsCloudService,
      ),
      [AgreementTableColumns.Tags]:
        agreement.tags?.sort((a, b) => a?.localeCompare(b))?.join?.(', ') ?? '',
    }));

  return createCsvExport(data, fields);
};

export const createSupplierTableExport = (
  columns: Array<DataTableColumn<SupplierListItem>>,
  suppliers: Array<SupplierListItem>,
) => {
  const fields = columns.map(convertDataTableColumnToCsvField);

  const data = suppliers
    .sort((a, b) => stringCompareFn(a.supplierName, b.supplierName, true))
    .map((supplier) => ({
      [SupplierTableColumns.Name]: supplier.supplierName,
      [SupplierTableColumns.CountryOfRegistration]:
        supplier.countryOfRegistration,
      [SupplierTableColumns.CorporateRegistrationNumber]:
        supplier.corporateRegistrationNumber,
    }));

  return createCsvExport(data, fields);
};
