import {
  AllowedFilter,
  RelativeDateInterval,
  StoredFilter as GenericStoredFilter,
} from '@fcg-tech/regtech-filter';
import { Options as BaseJson2CsvOptions } from 'json2csv';

import {
  AgreementFilter as ApiAgreementFilter,
  ArchivedOptions,
  DateFilter as ApiDateFilter,
  GlobalActions,
  InitData as ApiInitData,
  InternalEntity,
  ListAllAgreementsRequest as ApiGetAllAgreementsRequest,
  Metadata as ApiMetadata,
  StoredAgreementFilter as ApiStoredAgreementFilter,
  StoredSupplierFilter as ApiStoredSupplierFilter,
  Supplier,
  SupplierFilter as ApiSupplierFilter,
  SupplierListItem as ApiSupplierListItem,
} from './api/schema';

export type {
  AgreementFilter as ApiAgreementFilter,
  AgreementListItem,
  Country,
  Currency,
  DateFilter as ApiDateFilter,
  InternalEntity,
  Metadata as ApiMetadata,
  Region,
  StoredAgreementFilter as ApiStoredAgreementFilter,
  StoredSupplierFilter as ApiStoredSupplierFilter,
  Supplier,
  SupplierFilter as ApiSupplierFilter,
  SupplierListItem as ApiSupplierListItem,
} from './api/schema';

export {
  AgreementActions,
  AgreementTypeActions,
  ArchivedOptions,
  BooleanFilterEnum,
  CabinetActions,
  CategoryActions,
  GlobalActions,
  InternalEntityActions,
  ReportActions,
  SupplierActions,
} from './api/schema';

export type {
  GetAgreementResponse,
  GetSupplierResponse,
  ListAllAgreementsRequest as ApiGetAllAgreementsRequest,
} from './api/schema';

export type Metadata = ApiMetadata;

export type Json2CsvOptions<T> = Pick<
  BaseJson2CsvOptions<T>,
  'fields' | 'withBOM' | 'delimiter'
>;

export type Settings = {
  version?: number;
  dateFormat: string;
  dateTimeformat: string;
  startOfWeek: 0 | 1 | 2 | 3 | 4 | 5 | 6; // 0 = sunday
  dateFnsLocale: 'sv';
};

export type SettingsV1 = {
  dateFormat: string;
  dateTimeformat: string;
  startOfWeek: 0 | 1 | 2 | 3 | 4 | 5 | 6; // 0 = sunday
  dateFnsLocale: 'sv';
  columns: Record<
    AgreementTableType,
    Partial<Record<AgreementTableColumns, boolean>>
  >;
};

export type Pagination = {
  totalResults: number;
  limit: number;
  skip: number;
};

export type PaginatedItems<T> = {
  items: Array<T>;
  pagination: Pagination;
};

export enum IamActions {
  ViewMyGroups = 'iam:ViewMyGroups',
  ViewMyRoles = 'iam:ViewMyRoles',
  ViewMyUsers = 'iam:ViewMyUsers',
  GroupAdd = 'iam:GroupAdd',
  RoleAdd = 'iam:RoleAdd',
  UserAdd = 'iam:UserAdd',
}

export type Permissions = GlobalActions | IamActions;

export type InitData = Omit<ApiInitData, 'permissions'> & {
  permissions: Array<Permissions>;
};

export type SearchRow = {
  id: string;
  inverted?: boolean;
  operator?: 'AND' | 'OR';
  text: string;
};

export type ChartDataEntry = {
  name: string;
  value: number;
};

export type ChartType = 'bar' | 'pie';

export type SupplierListItem = Supplier & {
  metadata: ApiSupplierListItem['metadata'];
};

export type CreateSupplierFormikValues = Omit<
  Supplier,
  'supplierName' | 'supplierAssessment'
> & {
  supplierName: string;
};

export type CreateAgreementFormikValues = {
  name: string;
  cabinet: string;
  tags?: Array<string>;
};

export type InternalEntityFormikValues = InternalEntity;

export enum AgreementTableType {
  Overview = 'overview',
}

export enum AgreementTableColumns {
  Name = 'name',
  AgreementType = 'agreementType',
  FunctionCategory = 'functionCategory',
  BriefDescription = 'briefDescription',
  ContractOwner = 'contractOwner',
  SupplierName = 'supplierName',
  PartyToAgreement = 'partyToAgreement',
  InternalReference = 'internalReference',
  Cabinet = 'cabinet',
  Status = 'status',
  StartDate = 'startDate',
  EndDate = 'endDate',
  LatestReview = 'latestReview',
  NextReview = 'nextReview',
  ArchivingReference = 'archivingReference',
  CompetentAuthorityNotified = 'competentAuthorityNotified',
  IsOutsourcingArrangement = 'isOutsourcingArrangement',
  IsCritical = 'isCritical',
  NrOfComments = 'nrOfComments',
  IsPersonalDataTransferred = 'isPersonalDataTransferred',
  IsPersonalDataProcessed = 'isPersonalDataProcessed',
  IsProvidedAsCloudService = 'isProvidedAsCloudService',
  TotalValueOfAgreement = 'totalValueOfAgreement',
  Tags = 'tags',
}

export enum SupplierTableColumns {
  Name = 'name',
  CountryOfRegistration = 'countryOfRegistration',
  CorporateRegistrationNumber = 'corporateRegistrationNumber',
}

export type StoredAgreementTableColumnOptions = {
  width: number | string;
  visible: boolean;
};

export enum AgreementOrderOptions {
  NameAsc = 'nameAsc',
  NameDesc = 'nameDesc',
  AgreementTypeAsc = 'agreementTypeAsc',
  AgreementTypeDesc = 'agreementTypeDesc',
  FunctionCategoryAsc = 'functionCategoryAsc',
  FunctionCategoryDesc = 'functionCategoryDesc',
  BriefDescriptionAsc = 'briefDescriptionAsc',
  BriefDescriptionDesc = 'briefDescriptionDesc',
  ContractOwnerAsc = 'contractOwnerAsc',
  ContractOwnerDesc = 'contractOwnerDesc',
  SupplierNameAsc = 'supplierNameAsc',
  SupplierNameDesc = 'supplierNameDesc',
  PartyToAgreementAsc = 'partyToAgreementAsc',
  PartyToAgreementDesc = 'partyToAgreementDesc',
  InternalReferenceAsc = 'internalReferenceAsc',
  InternalReferenceDesc = 'internalReferenceDesc',
  CabinetAsc = 'cabinetAsc',
  CabinetDesc = 'cabinetDesc',
  StatusAsc = 'statusAsc',
  StatusDesc = 'statusDesc',
  StartDateAsc = 'startDateAsc',
  StartDateDesc = 'startDateDesc',
  EndDateAsc = 'endDateAsc',
  EndDateDesc = 'endDateDesc',
  LatestReviewAsc = 'latestReviewAsc',
  LatestReviewDesc = 'latestReviewDesc',
  NextReviewAsc = 'nextReviewAsc',
  NextReviewDesc = 'nextReviewDesc',
  ArchivingReferenceAsc = 'archivingReferenceAsc',
  ArchivingReferenceDesc = 'archivingReferenceDesc',
  CompetentAuthorityNotifiedAsc = 'competentAuthorityNotifiedAsc',
  CompetentAuthorityNotifiedDesc = 'competentAuthorityNotifiedDesc',
  IsOutsourcingArrangementAsc = 'isOutsourcingArrangementAsc',
  IsOutsourcingArrangementDesc = 'isOutsourcingArrangementDesc',
  IsCriticalAsc = 'isCriticalAsc',
  IsCriticalDesc = 'isCriticalDesc',
  NrOfCommentsAsc = 'nrOfCommentsAsc',
  NrOfCommentsDesc = 'nrOfCommentsDesc',
  IsPersonalDataTransferredAsc = 'isPersonalDataTransferredAsc',
  IsPersonalDataTransferredDesc = 'isPersonalDataTransferredDesc',
  IsPersonalDataProcessedAsc = 'isPersonalDataProcessedAsc',
  IsPersonalDataProcessedDesc = 'isPersonalDataProcessedDesc',
  IsProvidedAsCloudServiceAsc = 'isProvidedAsCloudServiceAsc',
  IsProvidedAsCloudServiceDesc = 'isProvidedAsCloudServiceDesc',
  TotalValueOfAgreementAsc = 'totalValueOfAgreementAsc',
  TotalValueOfAgreementDesc = 'totalValueOfAgreementDesc',
  TagsAsc = 'tagsAsc',
  TagsDesc = 'tagsDesc',
}

export enum SupplierOrderOptions {
  NameAsc = 'nameAsc',
  NameDesc = 'nameDesc',
  CountryOfRegistrationAsc = 'countryOfRegistrationAsc',
  CountryOfRegistrationDesc = 'countryOfRegistrationDesc',
  CorporateRegistrationNumberAsc = 'corporateRegistrationNumberAsc',
  CorporateRegistrationNumberDesc = 'corporateRegistrationNumberDesc',
}

export enum FilterType {
  AgreementFilter = 'agreement-filter',
  SupplierFilter = 'supplier-filter',
}

export type DateFilter = Omit<ApiDateFilter, 'interval'> & {
  interval?: RelativeDateInterval | string;
};

export type AgreementsQueryFilter = Omit<
  ApiGetAllAgreementsRequest,
  'skip' | 'limit' | 'isArchived'
> & {
  archived?: ArchivedOptions;
};

export type AgreementFilter = Omit<
  AllowedFilter<ApiAgreementFilter>,
  'name'
> & {
  name?: string;
};

export type AvailableAgreementFilters = {
  agreementTypes?: Array<string>;
  functionCategories?: Array<string>;
  contractOwners?: Array<string>;
  supplierNames?: Array<string>;
  partyToAgreement?: Array<string>;
  cabinets?: Array<string>;
  status?: Array<string>;
};

export type AgreementFilterValues = AgreementFilter & {
  orderBy?: AgreementOrderOptions;
};

export type StoredAgreementFilter = GenericStoredFilter<AgreementFilter> &
  Omit<ApiStoredAgreementFilter, 'filter'> & {
    filter: AgreementFilter;
  };

export type AgreementFetchContext = {
  filter?: AgreementFilterValues;
  query?: AgreementsQueryFilter;
};

export type SupplierFilter = Omit<
  AllowedFilter<ApiSupplierFilter>,
  'supplierName' | 'corporateRegistrationNumber'
> & {
  supplierName?: string;
  corporateRegistrationNumber?: string;
};

export type AvailableSupplierFilters = {
  countriesOfRegistration?: Array<string>;
};

export type SupplierFilterValues = SupplierFilter & {
  orderBy?: SupplierOrderOptions;
};

export type StoredSupplierFilter = GenericStoredFilter<SupplierFilter> &
  Omit<ApiStoredSupplierFilter, 'filter'> & {
    filter: SupplierFilter;
  };

/**
 *
 */

export enum LogLevel {
  Warning = 'warning',
  Error = 'error',
}

export enum Direction {
  Left = 'left',
  Down = 'down',
  Up = 'up',
  Right = 'right',
}

export enum GeneralAgreementsListTableColumnKeys {
  Name = 'name',
  AgreementType = 'agreementType',
  FunctionCategory = 'functionCategory',
  ContractOwner = 'contractOwner',
}

export type GeneralAgreementsListTableRowData = {
  metadata: Metadata;
  name: string;
  agreementType: string;
  functionCategory: string;
  contractOwner: string;
};

export type GeneralAgreementsListTableOrderBy =
  | 'nameAsc'
  | 'nameDesc'
  | 'agreementTypeAsc'
  | 'agreementTypeDesc'
  | 'functionCategoryAsc'
  | 'functionCategoryDesc'
  | 'contractOwnerAsc'
  | 'contractOwnerDesc';

export type WithMetadata<T> = T & {
  metadata: Metadata;
};

export type WithChildren<T> = T & {
  children: Array<WithChildren<T>>;
};

export type ResultArray<T> = { result: Array<T> };

export type SingleEntity<T> = {
  data: T;
  metadata: Metadata;
  permissions: Array<string>;
};

export type AgreementType = {
  name: string;
};

export type AgreementTypeAgreement = {
  name: string;
  agreementType: string;
  functionCategory: string;
  internalReference: string;
  contractOwner: string;
  metadata: Metadata;
};

export type Cabinet = {
  name: string;
};

export type Tag = {
  name: string;
};

export type Category = {
  name: string;
};

export type AgreementDetails = {
  term: {
    endDate?: string | null;
    startDate?: string | null;
  };
};

export type AgreementNPAP = unknown;
export type AgreementPersonalData = unknown;
export type AgreementServiceDetails = unknown;
export type AgreementRelatedAgreements = unknown;
export type AgreementAssessment = unknown;

export type Agreement = {
  details: AgreementDetails;
  personalData: AgreementPersonalData;
  relatedAgreements: AgreementRelatedAgreements;
  npap: AgreementNPAP;
  serviceDetails: AgreementServiceDetails;
  agreementAssessments: AgreementAssessment;
};

export type Subcontractor = {
  id: string;
  parentId: string | null;
  supplier: {
    isExternal: boolean;
    internalSupplier: string | null;
    externalSupplier: string | null;
  };
  categoryOfActivity: string | null;
  briefDescription: string;
  countriesWhereServiceIsPerformed: Array<string>;
  countriesOrRegionsWhereDataIsStored: Array<string>;
};

export type CalendarEvents = {
  metadata: { id: string; eventType: string };
  title: string;
  importance: string;
  date: Date;
  tags: Array<string>;
};

export type NonBlockingError = {
  message: string;
  field: string;
  validationGroup?: string;
  level: LogLevel.Warning | LogLevel.Error;
};
