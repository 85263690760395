import React from 'react';
import { IconProps } from '../../types/icons';

export const CabinetIcon = ({
  size = '24',
  color = 'currentColor',
  ...props
}: IconProps) => (
  <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 4a3 3 0 0 1 3-3h16a3 3 0 0 1 3 3v16a3 3 0 0 1-3 3H4a3 3 0 0 1-3-3V4Zm3-1a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4Zm1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V7H7v3a1 1 0 1 1-2 0V6Zm1 7a1 1 0 0 0-1 1v4a1 1 0 1 0 2 0v-3h10v3a1 1 0 1 0 2 0v-4a1 1 0 0 0-1-1H6Zm5-3a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2H12a1 1 0 0 1-1-1Zm1 7a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H12Z"
      fill={color}
    />
  </svg>
);
