import { Translator } from '@fcg-tech/regtech-types';
import * as yup from 'yup';
import { MessageKeys } from '../translations/messageKeys';
import { LogLevel } from '../types';
import Features from '../Features';

export const validateAgreementSchema = (t: Translator) => {
  const contractOwner = yup.string().email(() => ({
    level: LogLevel.Error,
    message: t(MessageKeys.ValidateAgreementContractOwnerEmailLabel),
    field: 'details.contractOwner',
  }));

  return yup.object().shape({
    details: yup.object().shape({
      name: yup.string().required(() => ({
        level: LogLevel.Error,
        message: t(MessageKeys.ValidateAgreementNameLabel),
        field: 'details.name',
      })),
      contractOwner: Features.FieldErrors.Blocking.Agreement
        .ContractOwnerMandatory
        ? contractOwner.required(() => ({
            level: LogLevel.Error,
            message: t(MessageKeys.ValidateAgreementContractOwnerLabel),
            field: 'details.contractOwner',
          }))
        : contractOwner,
      tags: yup.array().of(yup.string()),
    }),
  });
};
