import React, {
  PropsWithChildren,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { reposition } from 'nanopop';
import { Portal } from '../Portal';
import { SelectMenuPositioner, SelectMenuWrapper } from './Select.styles';

interface SelectMenuProps {
  containerRef: React.MutableRefObject<HTMLElement>;
  visible?: boolean;
  placement: 'bottom' | 'top';
  disableRepositionInterval?: boolean;
}

export const SelectMenu = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<SelectMenuProps>
>(
  (
    { children, containerRef, visible, placement, disableRepositionInterval },
    ref,
  ) => {
    const container = containerRef.current;
    const positionerRef = useRef<HTMLDivElement>();

    const width = useMemo(() => container?.getBoundingClientRect().width, [
      container,
    ]);

    const [positionMatch, setPositionMatch] = useState<string>();

    /*useLayoutEffect(() => {
    if (container && visible && positionerRef.current) {
      setPositionMatch(
        reposition(container, positionerRef.current, {
          margin: 0,
          container: document.documentElement.getBoundingClientRect(),
          position: 'bottom-start',
        }),
      );
    }
  }, [visible, container, containerRef]);*/

    return visible ? (
      <SelectMenuWrapper
        ref={ref}
        bottom={placement === 'bottom'}
        style={{ width }}
      >
        {children}
      </SelectMenuWrapper>
    ) : null;
  },
);
