/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ListAllAgreementTagsResponse,
} from '../models';
import {
    ListAllAgreementTagsResponseFromJSON,
    ListAllAgreementTagsResponseToJSON,
} from '../models';

export interface AddTagToAgreementRequest {
    agreementId: string;
    tagName: string;
}

export interface ListAllAgreementTagsRequest {
    agreementId: string;
}

export interface RemoveTagFromAgreementRequest {
    agreementId: string;
    tagName: string;
}

/**
 * AgreementTagsApi - interface
 * 
 * @export
 * @interface AgreementTagsApiInterface
 */
export interface AgreementTagsApiInterface {
    /**
     * 
     * @summary Tag an agreement with a tag
     * @param {string} agreementId The id of the agreement to retrieve tags from
     * @param {string} tagName The name of the tag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementTagsApiInterface
     */
    addTagToAgreementRaw(requestParameters: AddTagToAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Tag an agreement with a tag
     */
    addTagToAgreement(requestParameters: AddTagToAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary List all tags
     * @param {string} agreementId The id of the agreement to retrieve tags from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementTagsApiInterface
     */
    listAllAgreementTagsRaw(requestParameters: ListAllAgreementTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListAllAgreementTagsResponse>>;

    /**
     * List all tags
     */
    listAllAgreementTags(requestParameters: ListAllAgreementTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListAllAgreementTagsResponse>;

    /**
     * 
     * @summary Remove a tag from an agreement
     * @param {string} agreementId The id of the agreement to retrieve tags from
     * @param {string} tagName The name of the tag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementTagsApiInterface
     */
    removeTagFromAgreementRaw(requestParameters: RemoveTagFromAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Remove a tag from an agreement
     */
    removeTagFromAgreement(requestParameters: RemoveTagFromAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class AgreementTagsApi extends runtime.BaseAPI implements AgreementTagsApiInterface {

    /**
     * Tag an agreement with a tag
     */
    async addTagToAgreementRaw(requestParameters: AddTagToAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling addTagToAgreement.');
        }

        if (requestParameters.tagName === null || requestParameters.tagName === undefined) {
            throw new runtime.RequiredError('tagName','Required parameter requestParameters.tagName was null or undefined when calling addTagToAgreement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/agreements/{agreementId}/tags/{tagName}`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))).replace(`{${"tagName"}}`, encodeURIComponent(String(requestParameters.tagName))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Tag an agreement with a tag
     */
    async addTagToAgreement(requestParameters: AddTagToAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addTagToAgreementRaw(requestParameters, initOverrides);
    }

    /**
     * List all tags
     */
    async listAllAgreementTagsRaw(requestParameters: ListAllAgreementTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListAllAgreementTagsResponse>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling listAllAgreementTags.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/agreements/{agreementId}/tags`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListAllAgreementTagsResponseFromJSON(jsonValue));
    }

    /**
     * List all tags
     */
    async listAllAgreementTags(requestParameters: ListAllAgreementTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListAllAgreementTagsResponse> {
        const response = await this.listAllAgreementTagsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove a tag from an agreement
     */
    async removeTagFromAgreementRaw(requestParameters: RemoveTagFromAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling removeTagFromAgreement.');
        }

        if (requestParameters.tagName === null || requestParameters.tagName === undefined) {
            throw new runtime.RequiredError('tagName','Required parameter requestParameters.tagName was null or undefined when calling removeTagFromAgreement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/agreements/{agreementId}/tags/{tagName}`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))).replace(`{${"tagName"}}`, encodeURIComponent(String(requestParameters.tagName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove a tag from an agreement
     */
    async removeTagFromAgreement(requestParameters: RemoveTagFromAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeTagFromAgreementRaw(requestParameters, initOverrides);
    }

}
