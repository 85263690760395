import { foundation } from '@fcg-tech/regtech-design-system';
import styled, { css } from 'styled-components';
import { UnstyledButton } from '../Button';
import { InputCheckboxWrapper } from '../Checkbox/Checkbox.styles';
import { StyledTextArea } from '../TextArea/TextArea.styles';
import {
  TextFieldBorderContainer,
  TextFieldInput,
  TextFieldWrapper,
} from '../TextField/TextField.styles';

const PADDING = '0.7rem';

export const FormGridLabel = styled.div`
  grid-area: start / start / input-start / end;
  font-size: 1.2rem;
  color: ${foundation.palette.onyx300.hex};

  transition: color 0.2s ease-in-out;
`;

export const FormGridControlIcon = styled.div`
  grid-area: input-start / start / end / input-start;
  display: flex;
  align-items: flex-end;
  padding-right: ${PADDING};
`;

export const FormGridControl = styled.div`
  grid-area: input-start / start / end / end;
  position: relative;

  transition: all 0.1s ease-in-out;

  ${FormGridControlIcon} ~ & {
    grid-area: input-start / input-start / end / end;
  }

  & ${TextFieldBorderContainer}, & ${StyledTextArea} {
    border-color: transparent;
  }

  & ${TextFieldBorderContainer}.edit-disabled {
    padding-bottom: 0;
  }

  & ${TextFieldWrapper} {
    height: auto;
  }

  & ${TextFieldInput}, & ${StyledTextArea} {
    padding-left: 0;
    padding-bottom: 0;
    height: auto;
  }
`;

const validState = css`
  & ${FormGridLabel} {
    color: ${foundation.palette.topaz200.hex};
  }
  &:hover,
  &:focus,
  &:focus-within {
    box-shadow: inset 0 0 0 1px ${foundation.palette.topaz200.hex};
    border-color: ${foundation.palette.topaz200.hex};
  }
`;

const invalidState = css`
  ${FormGridLabel} {
    color: ${foundation.palette.gold700.hex};
  }
  border-color: ${foundation.palette.gold700.hex};
  &:hover,
  &:focus,
  &:focus-within {
    box-shadow: inset 0 0 0 1px ${foundation.palette.gold700.hex};
    border-color: ${foundation.palette.gold700.hex};
  }

  & ${StyledTextArea}, & ${TextFieldBorderContainer} {
    border-color: transparent;
    box-shadow: none;
  }
`;

const disabledState = css`
  background-color: ${foundation.palette.onyx200.hex};
  border-color: ${foundation.palette.onyx300.hex};
  cursor: not-allowed;

  & > ${FormGridControl} {
    opacity: 0.6;
  }
`;

export const FormGridRow = styled.label`
  position: relative;
  display: grid;
  grid-template-columns: [start] auto [input-start] 1fr [end];

  grid-template-rows: [start] auto [input-start] 1fr [end];
  row-gap: 0;

  padding: ${PADDING};
  border: 1px solid ${foundation.palette.onyx200.hex};
  border-radius: 4px;
  background-color: white;

  &:hover,
  &:focus,
  &:focus-within {
    box-shadow: inset 0 0 0 1px ${foundation.palette.topaz200.hex};
    border-color: ${foundation.palette.topaz200.hex};

    ${FormGridLabel} {
      color: ${foundation.palette.topaz200.hex};
    }
  }

  &.with-subinput {
    grid-template-rows: [start] auto [input-start] 1fr [subinput] auto [end];

    & > ${FormGridControl} {
      grid-area: input-start / start / subinput / end;
    }
  }

  &.with-select {
    padding: ${PADDING} 0 0 ${PADDING};

    &.with-subinput {
      padding: ${PADDING} 0 ${PADDING} ${PADDING};
    }

    & > ${FormGridControlIcon} {
      align-items: center;
    }
  }

  &.with-toggle {
    grid-template-columns: [start] 1fr [input-start] auto [end];
    grid-template-rows: [start] auto [end];

    ${FormGridLabel} {
      grid-area: start / start / end / input-start;
      align-self: center;
      padding-right: 2rem;
    }

    ${FormGridControl} {
      grid-area: start / input-start / end / end;
    }
  }

  &.valid {
    ${validState}
  }

  &.invalid:not(:focus-within) {
    ${invalidState}
  }

  &.disabled {
    ${disabledState}
  }

  & + & {
    margin-top: 1.6rem;
  }

  // Future. Not yet supported in current browsers.
  &:has(input.valid),
  &:has(textarea.valid),
  &:has(input:checked) {
    ${validState}
  }

  &:has(input.invalid),
  &:has(textarea.invalid) {
    ${invalidState}
  }

  &:has(input[disabled]),
  &:has(textarea[disabled]) {
    ${disabledState}
  }
`;

export const FormGridContainer = styled(FormGridRow).attrs({ as: 'div' })`
  &.with-multi-controls > ${FormGridControl} {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto;
    row-gap: 1rem;
    column-gap: 1.6rem;
  }

  & ${FormGridControl}:has(${InputCheckboxWrapper}) {
    margin-left: -1px;
  }

  &.with-radio-group > ${FormGridControl} > div[role='radiogroup'] {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto;
    row-gap: 1rem;
    column-gap: 1.6rem;
  }
`;

export const FormGridSubInput = styled.div`
  grid-area: subinput / input-start / end / end;
  justify-self: end;
  padding-right: ${PADDING};
`;

export const FormGridButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2.6rem;

  & > button + button {
    margin-left: ${PADDING};
  }

  &.space-between {
    justify-content: space-between;
  }
`;

export const FormGridRemoveButton = styled(UnstyledButton)`
  position: absolute;
  top: 1px;
  right: 1px;
  padding: 7px;
  opacity: 0.7;
  transition: opacity 0.2s ease-in-out;
  outline: 0;

  &:hover {
    opacity: 1;
  }
`;
