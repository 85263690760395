import {
  FormButtonRow,
  InputCheckboxField,
  mobile,
} from '@fcg-tech/regtech-components';
import styled, { css } from 'styled-components';

export const UserEmailPromptWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #fafafa;
`;

export const UserEmailPromptContent = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  background: white;
`;

export const UserEmailPromptChildrenWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 2rem 0;
  background-color: #eee;
  border-bottom: 1px solid #ccc;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 1;
`;

export const UserEmailForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 2.6rem;
  margin-top: 1.6rem;

  width: 40rem;

  ${mobile(
    () => css`
      width: 95vw;
    `,
  )}
`;

export const UserEmailLabel = styled.label`
  margin-bottom: 0.6rem;
  font-weight: 600;
  font-size: 1.2rem;
`;

export const UserEmailPromptButtonRow = styled(FormButtonRow)`
  margin-top: 1.6rem;
`;

export const UserEmailPromptCheckboxField = styled(InputCheckboxField)`
  margin-top: 1rem;
`;
