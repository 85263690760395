import { EmptyPropsWithChildren } from '@fcg-tech/regtech-types';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { ErrorDialogManager } from '../ErrorDialog';
import { MainSideNavigation } from '../MainSideNavigation';
import { Header } from './Header';
import {
  MainLayoutWrapper,
  MainLayoutContent,
  MainLayoutPageWrapper,
  MainLayoutFlashNotificationWrapper,
} from './MainLayout.styles';

export const MainLayout: FunctionComponent<PropsWithChildren<EmptyPropsWithChildren>> = ({ children }) => {
  return (
    <MainLayoutWrapper>
      <MainSideNavigation />
      <MainLayoutContent>
        <MainLayoutFlashNotificationWrapper>
          <ErrorDialogManager>
            <Header />
            <MainLayoutPageWrapper>{children}</MainLayoutPageWrapper>
          </ErrorDialogManager>
        </MainLayoutFlashNotificationWrapper>
      </MainLayoutContent>
    </MainLayoutWrapper>
  );
};
