import React from 'react';
import { useAsync } from 'react-async';
import { useNavigate, useMatch } from 'react-router-dom';
import {
  ConfirmDialog,
  EditProvider,
  Loader,
  useFlash,
} from '@fcg-tech/regtech-components';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import {
  loadCategory,
  updateCategory,
  deleteCategory,
  loadCategoryAgreements,
} from '../../api';
import { routes } from '../../routes';
import { useTenant } from '../../states/tenantState';
import { useErrorDialog } from '../../components/ErrorDialog';
import { EditCategoryPage } from './components';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { ErrorMessage } from '../../components/ErrorBoundary';

export const EditCategoryContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tenant = useTenant();
  const tenantId = tenant.id;
  const match = useMatch(routes.category);
  const { categoryId } = match?.params ?? {};

  const showErrorDialog = useErrorDialog();
  const addFlash = useFlash();

  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);
  const [isEditEnabled, setEditEnabled] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);

  const loadCategoryReq = useAsync({
    promiseFn: loadCategory,
    tenantId,
    categoryId,
  });

  const loadCategoryAgreementsReq = useAsync({
    promiseFn: loadCategoryAgreements,
    tenantId,
    category: categoryId,
    isArchived: false,
  });

  const updateCategoryProxy = async (args) => {
    setIsSaving(true);
    try {
      await updateCategory(...args);
      addFlash({
        level: 'success',
        content: t(MessageKeys.EditCategorySuccessfullyUpdatedLabel),
      });
      setEditEnabled(false);
      loadCategoryReq.reload();
    } catch (err) {
      showErrorDialog({
        title: t(MessageKeys.LabelSomethingWentWrong),
        message: t(MessageKeys.LabelErrorOccured),
      });
    } finally {
      setIsSaving(false);
    }
  };

  const deleteCategoryProxy = async (args) => {
    try {
      await deleteCategory(...args);
      addFlash({
        level: 'success',
        content: t(MessageKeys.EditCategorySuccessfullyDeletedLabel),
      });
      navigate(constructUrl(routes.categories, { tenantId }));
    } catch (err) {
      showErrorDialog({
        title: t(MessageKeys.LabelSomethingWentWrong),
        message: t(MessageKeys.EditCategoryFailedDeleteLabel),
      });
    }
  };

  const updateCategoryReq = useAsync({ deferFn: updateCategoryProxy });
  const deleteCategoryReq = useAsync({ deferFn: deleteCategoryProxy });

  const handleEdit = React.useCallback(() => {
    setEditEnabled(true);
  }, [setEditEnabled]);

  const handleCancel = React.useCallback(() => {
    setEditEnabled(false);
  }, [setEditEnabled]);

  const handleSave = React.useCallback(
    (updated) => {
      setIsSaving(true);
      updateCategoryReq.run({ tenantId, categoryId, category: updated.data });
    },
    [updateCategoryReq, setIsSaving, tenantId, categoryId],
  );

  const handleDelete = React.useCallback(() => {
    setShowDeleteConfirm(true);
  }, []);

  const handleDeleteChoice = React.useCallback(
    (choice) => {
      if (choice) {
        deleteCategoryReq.run({ tenantId, categoryId });
      }
      setShowDeleteConfirm(false);
    },
    [categoryId, tenantId, deleteCategoryReq],
  );

  if (loadCategoryReq.isLoading) {
    return <Loader message={t(MessageKeys.LabelLoadingCategory)} />;
  }

  if (loadCategoryReq.error || updateCategoryReq.error) {
    return (
      <ErrorMessage error={loadCategoryReq.error || updateCategoryReq.error} />
    );
  }

  return (
    <>
      {showDeleteConfirm && (
        <ConfirmDialog
          title={t(MessageKeys.LabelConfirm)}
          body={t(MessageKeys.EditCategoryConfirmDeleteLabel)}
          confirmText={t(MessageKeys.LabelConfirmDelete)}
          cancelText={t(MessageKeys.LabelNo)}
          onChoice={handleDeleteChoice}
        />
      )}
      <EditProvider value={isEditEnabled}>
        <EditCategoryPage
          category={loadCategoryReq.data}
          agreements={
            loadCategoryAgreementsReq.isFulfilled
              ? loadCategoryAgreementsReq.data.result
              : []
          }
          isSaving={isSaving}
          onEdit={handleEdit}
          onCancel={handleCancel}
          onDelete={handleDelete}
          onSave={handleSave}
        />
      </EditProvider>
    </>
  );
};
