import React, { MutableRefObject, FunctionComponent } from 'react';

export const useClickOutside = (
  containerRef: MutableRefObject<HTMLElement>,
  onClick?: null | undefined | ((event: React.MouseEvent) => void),
) => {
  React.useEffect(() => {
    const listener = (event) => {
      if (
        containerRef.current &&
        containerRef.current.contains &&
        !containerRef.current.contains(event.target)
      ) {
        onClick?.(event);
      }
    };

    window.addEventListener('pointerdown', listener);

    return () => window.removeEventListener('pointerdown', listener);
  }, [containerRef, onClick]);

  return null;
};
