import { format } from 'date-fns';
import React, { FunctionComponent } from 'react';
import { CalendarEventData, CalendarEventType } from '../../types/calendar';
import {
  CalendarDayContent,
  CalendarDayDate,
  CalendarDayInnerWrapper,
  CalendarDayWeekday,
  CalendarDayWrapper,
} from './Calendar.styles';
import { CalendarEvent } from './CalendarEvent';

interface CalendarDayProps {
  date: Date;
  today: boolean;
  disabled?: boolean;
  displayWeekday?: boolean;
  events?: Array<CalendarEventData>;
  eventTypes?: Array<CalendarEventType>;
  onEventClick?: (eventId: string | number, eventKey: string | number) => void;
}

export const CalendarDay: FunctionComponent<CalendarDayProps> = ({
  date,
  today,
  disabled,
  displayWeekday = true,
  events,
  eventTypes,
  onEventClick,
}) => {
  return (
    <CalendarDayWrapper>
      {displayWeekday ? (
        <>
          <CalendarDayWeekday>{format(date, 'EEEE')}</CalendarDayWeekday>
          <CalendarDayWeekday className="short-weekday">
            {format(date, 'E')}
          </CalendarDayWeekday>
        </>
      ) : null}
      <CalendarDayInnerWrapper disabled={disabled}>
        <CalendarDayDate today={today}>{format(date, 'd')}</CalendarDayDate>
        <CalendarDayContent>
          {events?.map((event) => {
            const eventType = eventTypes.find(
              ({ id }) => id === event.eventType,
            );
            if (eventType) {
              if (eventType.component) {
                return (
                  <eventType.component
                    key={event.key ?? event.id}
                    event={event}
                    eventType={eventType}
                    context="calendar"
                    onClick={onEventClick}
                  />
                );
              }
              return (
                <CalendarEvent
                  key={event.key ?? event.id}
                  event={event}
                  eventType={eventType}
                  onClick={onEventClick}
                />
              );
            }
            return null;
          })}
        </CalendarDayContent>
      </CalendarDayInnerWrapper>
    </CalendarDayWrapper>
  );
};
