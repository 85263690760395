import { AGREEMENT_SKELETON } from '../constants';
import { Agreement, SingleEntity } from '../types';

export const convertAgreement = (agreement: SingleEntity<Agreement>) => ({
  ...agreement,
  data: {
    ...AGREEMENT_SKELETON.data,
    ...agreement.data,
    details: {
      ...agreement.data.details,
      term: {
        ...agreement.data.details.term,
        endDateRequired:
          agreement.data.details.term?.endDate !== undefined
            ? Boolean(agreement.data.details.term.endDate)
            : null,
      },
    },
  },
});
