import { LegacyPalette, LegacyTheme } from '@fcg-tech/regtech-types';
import { css } from 'styled-components';

const buttonStyle = css`
  border-radius: 0.3rem;
`;

export const palette2: LegacyPalette = {
  MainColor: {
    light: 'rgba(69, 123, 157, 1)',
    lightContrast: 'rgba(241, 250, 238, 1)',
    main: 'rgba(29, 53, 87, 1)',
    mainContrast: 'rgba(241, 250, 238, 1)',
    dark: 'rgba(15, 28, 46, 1)',
    darkContrast: 'rgba(241, 250, 238, 1)',
  },
  MainColorAccent: {
    light: 'rgba(251, 235, 173, 1)',
    lightContrast: 'rgba(10,10,10,1)',
    main: 'rgba(250, 214, 73, 1)',
    mainContrast: 'rgba(10,10,10,1)',
    dark: 'rgba(193, 160, 30, 1)',
    darkContrast: 'rgba(10,10,10,1)',
  },
  MainBlack: '#000',
  MainWhite: '#FFF',
};

// https://coolors.co/86bbd8-33658a-2f4858-eb9c0a-f26419
export const palette1: LegacyPalette = {
  MainColor: {
    light: '#86BBD8',
    lightContrast: '#000',
    main: '#33658A',
    mainContrast: '#fff',
    dark: '#2F4858',
    darkContrast: '#fff',
  },
  MainColorAccent: {
    light: '#F9CB76',
    lightContrast: 'rgba(10,10,10,1)',
    main: '#F6AE2D',
    mainContrast: 'rgba(10,10,10,1)',
    dark: '#EB9C0A',
    darkContrast: 'rgba(10,10,10,1)',
  },
  MainBlack: '#000',
  MainWhite: '#FFF',
};

export const palette: LegacyPalette = {
  MainColor: {
    light: '#3DBDDD',
    lightContrast: '#000',
    main: '#1A7A92',
    mainContrast: '#fff',
    dark: '#1A7A92',
    darkContrast: '#fff',
  },
  MainColorAccent: {
    light: '#FFC229',
    lightContrast: 'rgba(10,10,10,1)',
    main: '#FFB703',
    mainContrast: 'rgba(10,10,10,1)',
    dark: '#DFA000',
    darkContrast: 'rgba(10,10,10,1)',
  },
  MainBlack: '#000',
  MainWhite: '#FFF',
};

export const theme: LegacyTheme = {
  version: '1',
  breakpoints: {
    mobile: 730,
    narrowDesktop: 1024,
    mediumDesktop: 1240,
  },

  colors: {
    backdrop: '#FFFFFF',
    attention: {
      main: '#DC3545',
      contrast: palette.MainWhite,
    },
    info: {
      main: '#B3D3F9',
      contrast: palette.MainBlack,
      dark: '#039ae3',
      darkContrast: '#FFF',
    },
    error: {
      main: '#DC3545',
      contrast: palette.MainWhite,
    },
    warning: {
      main: '#e0ab0d',
      contrast: palette.MainBlack,
    },
    success: {
      main: '#28A745',
      contrast: palette.MainWhite,
    },
    primary: {
      main: palette.MainColor.main,
      contrast: palette.MainColor.mainContrast,
    },
    secondary: {
      main: palette.MainColorAccent.light,
      contrast: palette.MainColorAccent.lightContrast,
    },
  },

  typography: {
    fontFamily: 'Roboto',
  },

  palette: palette,

  elements: {
    header: {
      wrapper: css`
        height: 7rem;
        border-bottom-width: 1px;
      `,
    },
    sideNavigation: {
      menu: {
        wrapper: css`
          color: ${palette.MainColor.mainContrast ?? palette.MainWhite};
        `,
      },
      expandButton: {
        icon: css`
          color: ${palette.MainColor.mainContrast ?? palette.MainWhite};
        `,
      },
    },
    sideBar: {
      logoWrapper: css`
        background: ${palette.MainColor.main};
        height: 7rem;
      `,
      wrapper: css`
        background: ${palette.MainColor.main};
        border-right: 1px solid rgba(0, 0, 0, 0.3);
      `,
    },
    button: {
      primary: buttonStyle,
      secondary: buttonStyle,
    },
    card: {
      wrapper: css`
        background-color: ${palette.MainWhite};
      `,
    },
  },
};
