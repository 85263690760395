import React from 'react';
import { IconProps } from '../../types/icons';

export const SpinningLoadingIcon = ({
  size = '34',
  color = 'currentColor',
  ...props
}: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="30 30 40 40"
    preserveAspectRatio="xMidYMid"
  >
    <g transform="rotate(0 50 50)">
      <rect
        x="46.5"
        y="39.5"
        rx="3.5"
        ry="1.5"
        width="7"
        height="3"
        fill={color}
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.8620689655172413s"
          begin="-0.7389162561576353s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(51.42857142857143 50 50)">
      <rect
        x="46.5"
        y="39.5"
        rx="3.5"
        ry="1.5"
        width="7"
        height="3"
        fill={color}
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.8620689655172413s"
          begin="-0.6157635467980295s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(102.85714285714286 50 50)">
      <rect
        x="46.5"
        y="39.5"
        rx="3.5"
        ry="1.5"
        width="7"
        height="3"
        fill={color}
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.8620689655172413s"
          begin="-0.4926108374384236s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(154.28571428571428 50 50)">
      <rect
        x="46.5"
        y="39.5"
        rx="3.5"
        ry="1.5"
        width="7"
        height="3"
        fill={color}
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.8620689655172413s"
          begin="-0.36945812807881767s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(205.71428571428572 50 50)">
      <rect
        x="46.5"
        y="39.5"
        rx="3.5"
        ry="1.5"
        width="7"
        height="3"
        fill={color}
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.8620689655172413s"
          begin="-0.2463054187192118s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(257.14285714285717 50 50)">
      <rect
        x="46.5"
        y="39.5"
        rx="3.5"
        ry="1.5"
        width="7"
        height="3"
        fill={color}
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.8620689655172413s"
          begin="-0.1231527093596059s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(308.57142857142856 50 50)">
      <rect
        x="46.5"
        y="39.5"
        rx="3.5"
        ry="1.5"
        width="7"
        height="3"
        fill={color}
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.8620689655172413s"
          begin="0s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
  </svg>
);
