import { H1, H2 } from '@fcg-tech/regtech-components';
import styled from 'styled-components';
import { OasysText } from '../icons/OasysText';

export const ErrorPageWrapper = styled.div`
  padding-top: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ErrorStatusHeading = styled(H1)`
  text-align: center;
  font-size: 4rem;
  margin-bottom: 3rem;
`;

export const ErrorHeading = styled(H2)`
  color: ${({ theme }) => theme?.colors?.error?.main ?? 'red'};
`;

export const ErrorContent = styled.code`
  display: block;
  padding: 0 1rem;
  border: 1px solid #333;
  border-radius: 4px;
  background-color: white;
  font-size: 1.3rem;
  max-width: max(40rem, 50vw);
`;

export const ErrorRequestId = styled.div`
  font-family: 'Courier New', Courier, monospace;
  font-size: 1.6rem;
  background-color: #eee;
  border: 1px solid #888;
  border-radius: 3px;
  padding: 0.8rem;
  font-weight: 600;
`;

export const ErrorImage = styled.img`
  width: 30rem;
  margin-bottom: 4rem;
`;

export const ErrorInfo = styled.p`
  font-size: 1.4rem;

  &.center {
    display: flex;
    justify-content: center;
  }
`;

export const StyledOasysText = styled(OasysText)`
  width: 30rem;
  max-width: 75vw;
  margin-bottom: 7rem;
`;

export const Error403LogoutContaienr = styled.div`
  position: fixed;
  margin-top: 1.6rem;
  padding-right: 1.6rem;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
