import { media, ModalBody } from '@fcg-tech/regtech-components';
import styled, { css } from 'styled-components';

export const SearchHelpModalContent = styled(ModalBody)`
  width: 40vw;
  min-width: 40rem;
  max-width: 100vw;

  ${({ theme }) =>
    media(theme)(css`
      width: 100%;
      max-width: 100%;
      min-width: 100%;
    `)}
`;

export const SearchHelpModalSection = styled.p`
  &:first-child {
    margin-top: 0;
  }
  line-height: 2.3rem;
`;

export const SearchHelpModalSectionLabel = styled.span`
  font-weight: 600;
  margin-right: 0.6rem;
`;

export const SearchHelpModalExampleString = styled.code`
  background-color: #eee;
  padding: 0.2rem 0.5rem;
  margin-right: 0.6rem;
`;
