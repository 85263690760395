import styled, { css } from 'styled-components';
import Color from 'color';
import { IconButton, Menu } from '@fcg-tech/regtech-components';
import { foundation } from '@fcg-tech/regtech-design-system';

export const FilterBarWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.6rem 0;
  background-color: ${foundation.palette.onyx100.hex};
  border-top: 2px solid white;
`;

export const FilterBarPinnedItemsList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0 0 0 0;
  position: relative;
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding-right: 4rem;
`;

export const FilterBarPinnedItemsListWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    max-width: 100%;
    &:after {
      content: ' ';
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      bottom: 0;
      width: 3rem;
    }
    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      padding-bottom: 0.5rem;
    }
  `}
`;

export const FilterBarPinnedItemWrapper = styled.li`
  ${({ theme }) => css`
    cursor: pointer;
    margin: 0;
    padding: 0.5rem 1rem;
    border: 2px solid
      ${Color(theme?.palette?.MainColor?.main ?? '#000')
        .alpha(0.3)
        .toString()};
    border-radius: 900px/1000px;
    background-color: ${theme?.palette?.MainColorAccent?.main ?? '#ccc'};
    color: ${theme?.palette?.MainColorAccent?.mainContrast ?? '#333'};
    font-size: 1.2rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 0.5rem;
    white-space: nowrap;
    outline: none !important;
    will-change: opacity;

    opacity: 0.6;

    &.active {
      opacity: 1;
    }

    &:hover:not(.active) {
      opacity: 0.8;
    }

    &.isdragging {
      box-shadow: 0 -1px 4px 2px rgba(0, 0, 0, 0.3);
      position: relative;
      z-index: 10;
      opacity: 0.95;
      cursor: ew-resize;
    }

    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      font-size: 1.1rem;
      padding: 0.5rem 0.8rem;

      &:hover {
        border-color: none;
      }
    }
  `}
`;

export const FilterBarIconWrapper = styled.span`
  margin-right: 0.5rem;
  position: relative;
  top: 0.1rem;
`;

export const FilterBarLeft = styled.div`
  flex: 1 0 0;
  display: flex;
  align-items: center;
  min-width: 0;
`;

export const FilterBarRight = styled.div`
  display: flex;
  align-items: center;
`;

export const FilterBarColumnSelectWrapper = styled.div`
  position: relative;
`;

export const FilterBarButton = styled(IconButton)`
  width: 3rem;
  height: 3rem;
`;

export const FilterBarColumnSelectMenu = styled(Menu)`
  padding: 1.6rem;
  position: absolute;
  top: 100%;
  right: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  min-width: 20rem;
  max-height: initial;
  z-index: 100;
`;
