import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Card,
  CardBody,
  CardTitle,
  PageHeader,
  PageHeaderActions,
  PageHeaderActionButton,
  PageLayout,
  PageLayoutPaddedContent,
  SecondaryButton,
  TooltipNext as Tooltip,
  useAccessControl,
} from '@fcg-tech/regtech-components';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { useTranslation } from 'react-i18next';
import { History } from 'lucide-react';
import { MessageKeys } from '../../../translations/messageKeys';
import { SUPPLIER_SKELETON } from '../../../constants';
import { routes } from '../../../routes';
import { attachmentPropType, supplierPropType } from '../../../propTypes';
import { useTenant } from '../../../states/tenantState';
import { Attachments } from '../../../components/Attachments';
import {
  SupplierDetailsSubForm,
  SupplierAssessmentSubForm,
} from '../../../components/SubForms';
import { formatZonedDateTime } from '../../../utils/formatters';

export const SupplierVersionPage = ({ supplier, attachments }) => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const tenantId = tenant.id;
  const supplierId = supplier.metadata?.id;

  const canViewHistory = useAccessControl(supplier.permissions, [
    'oasys:SupplierHistory',
  ]);

  const pageTitle = React.useMemo(
    () => supplier.data.supplierName || t(MessageKeys.LabelUnnamed),
    [supplier.data.supplierName, t],
  );

  const initialValues = React.useMemo(
    () => ({
      data: {
        ...SUPPLIER_SKELETON.data,
        ...supplier.data,
        supplierAssessment: {
          ...SUPPLIER_SKELETON.data.supplierAssessment,
          ...supplier.data?.supplierAssessment,
        },
      },
    }),
    [supplier],
  );

  return (
    <Formik initialValues={initialValues} onSubmit={null}>
      {({ handleSubmit }) => (
        <PageLayout>
          <PageHeader title={pageTitle} breadcrumbs={[]}>
            <PageHeaderActions>
              {canViewHistory ? (
                <Tooltip
                  html
                  content={t(
                    MessageKeys.SupplierVersionVersionSavedLabel,
                    {
                      version: supplier.metadata.version,
                      date: formatZonedDateTime(supplier.metadata.modified),
                      modifiedBy: supplier.metadata.modifiedBy,
                    },
                  )}
                  placement="bottom-end"
                >
                  <PageHeaderActionButton
                    to={constructUrl(routes.supplierVersions, {
                      tenantId,
                      supplierId,
                    })}
                  >
                    <History size="24" />
                  </PageHeaderActionButton>
                </Tooltip>
              ) : null}
            </PageHeaderActions>
            <SecondaryButton
              to={constructUrl(routes.supplier, {
                tenantId,
                supplierId,
              })}
            >
              {t(MessageKeys.LabelCurrent)}
            </SecondaryButton>
          </PageHeader>
          <PageLayoutPaddedContent>
            <form onSubmit={handleSubmit}>
              <Card>
                <CardBody>
                  <CardTitle>{t(MessageKeys.LabelDetails)}</CardTitle>
                  <SupplierDetailsSubForm />
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle>
                    {t(
                      MessageKeys.SupplierVersionSupplierAssessmentLabel,
                    )}
                  </CardTitle>
                  <SupplierAssessmentSubForm attachments={attachments} />
                </CardBody>
              </Card>
              {supplier.metadata && (
                <Card>
                  <CardBody>
                    <CardTitle>
                      {t(MessageKeys.LabelOtherDocumentation)}
                    </CardTitle>
                    <Attachments attachments={attachments} section="other" />
                  </CardBody>
                </Card>
              )}
            </form>
          </PageLayoutPaddedContent>
        </PageLayout>
      )}
    </Formik>
  );
};

SupplierVersionPage.propTypes = {
  supplier: supplierPropType.isRequired,
  attachments: PropTypes.arrayOf(attachmentPropType),
};

SupplierVersionPage.defaultProps = {
  attachments: [],
};
