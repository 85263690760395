import React, { FunctionComponent, useCallback, useMemo } from 'react';
import {
  Label,
  PrimaryButton,
  useEditContext,
  useToggle,
} from '@fcg-tech/regtech-components';
import { FileAttachment } from '@fcg-tech/regtech-types/oasys';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import { AttachmentTable } from '../AttachmentTable';
import { FileUploadModal } from '../FileUploadModal';
import { useAttachmentControl } from './AttachmentControlContext';
import {
  AttachmentTableHeaderCell,
  AttachmentTableHeaderRightCell,
  AttachmentTableHeaderWrapper,
  AttachmentTableWrapper,
} from './Attachments.styles';

export interface AttachmentsProps {
  title?: string;
  section?: FileAttachment['section']; // TODO: FileAttachmentSection
  attachments: FileAttachment[]; // TODO: Use type from schema (need to check for both agreements and suppliers)
  noAttachmentsFoundMessage?: string;
  showFileUploadOnMount?: boolean;
}

export const Attachments: FunctionComponent<AttachmentsProps> = ({
  title,
  attachments: initialAttachments,
  section,
  noAttachmentsFoundMessage: emptyMessage,
}) => {
  const { t } = useTranslation();
  const isEditEnabled = useEditContext();
  const [showUploadModal, , toggleShowUploadModal] = useToggle(false);

  const {
    onDeleteAttachment,
    onDownloadAttachment,
    onRenameAttachment,
    onUploadAttachment,
  } = useAttachmentControl();

  const attachments = useMemo(() => {
    if (section) {
      return initialAttachments.filter(
        (attachment) => attachment?.section === section,
      );
    }
    return initialAttachments;
  }, [initialAttachments, section]);

  const handleUploadAttachment = useCallback(
    async (acceptedFiles: Array<File>) => {
      await onUploadAttachment?.(acceptedFiles, section);
    },
    [onUploadAttachment, section],
  );

  return (
    <>
      {showUploadModal ? (
        <FileUploadModal
          allowMultiple
          onSubmit={handleUploadAttachment}
          onRequestClose={toggleShowUploadModal}
        />
      ) : null}
      <AttachmentTableWrapper>
        <AttachmentTableHeaderWrapper>
          <AttachmentTableHeaderCell>
            {title && <Label column>{title}</Label>}
          </AttachmentTableHeaderCell>
          <AttachmentTableHeaderRightCell>
            {isEditEnabled ? (
              <PrimaryButton type="button" onClick={toggleShowUploadModal}>
                {t(MessageKeys.LabelUploadFiles)}
              </PrimaryButton>
            ) : null}
          </AttachmentTableHeaderRightCell>
        </AttachmentTableHeaderWrapper>
        <AttachmentTable
          attachments={attachments}
          emptyMessage={emptyMessage}
          onDownloadAttachment={onDownloadAttachment}
          onRemoveAttachment={isEditEnabled ? onDeleteAttachment : undefined}
          onRenameAttachment={isEditEnabled ? onRenameAttachment : undefined}
        />
      </AttachmentTableWrapper>
    </>
  );
};
