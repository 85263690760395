import React, { FunctionComponent, PropsWithChildren } from 'react';
import { isKeyLabelItem } from '../../guards';
import { SquareCheckIcon, SquareIcon } from '../Icons';
import {
  CheckboxOptionCheckbox,
  CheckboxOptionInnerOption,
  CheckboxOptionWrapper,
} from './CheckboxOption.styles';
import { OptionProps } from './Option';

export const CheckboxOption: FunctionComponent<PropsWithChildren<OptionProps>> = ({
  isSelected,
  children,
  value,
  onClick,
  ...props
}) => {
  const { key, label } = isKeyLabelItem(value)
    ? value
    : { key: value, label: value };

  const handleClick = React.useCallback(() => onClick?.(key), [key, onClick]);

  return (
    <CheckboxOptionWrapper role="option">
      <CheckboxOptionCheckbox
        tabIndex={-1}
        role="checkbox"
        onClick={handleClick}
      >
        {isSelected ? <SquareCheckIcon size="18" /> : <SquareIcon size="18" />}
      </CheckboxOptionCheckbox>
      <CheckboxOptionInnerOption
        {...props}
        tabIndex={-1}
        value={value}
        isSelected={false}
        onClick={onClick}
      >
        {children ?? label}
      </CheckboxOptionInnerOption>
    </CheckboxOptionWrapper>
  );
};
