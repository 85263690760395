export const tenantBase = '/:tenantId';
export const tenantIamBase = `${tenantBase}/iam`;

export const routes = {
  dashboard: `${tenantBase}/dashboard`,
  calendar: `${tenantBase}/calendar`,
  search: `${tenantBase}/search`,
  notifications: `${tenantBase}/notifications`,
  notificationsSettings: `${tenantBase}/notifications/settings`,
  agreements: `${tenantBase}/agreements`,
  agreement: `${tenantBase}/agreements/:agreementId`,
  editAgreement: `${tenantBase}/agreements/:agreementId/edit`,
  agreementVersions: `${tenantBase}/agreements/:agreementId/versions`,
  agreementVersion: `${tenantBase}/agreements/:agreementId/versions/:version`,
  agreementTypes: `${tenantBase}/agreement-types`,
  agreementType: `${tenantBase}/agreement-types/:agreementTypeId`,
  cabinets: `${tenantBase}/cabinets`,
  cabinet: `${tenantBase}/cabinets/:cabinetId`,
  categories: `${tenantBase}/categories`,
  category: `${tenantBase}/categories/:categoryId`,
  internalEntities: `${tenantBase}/internal-entities`,
  internalEntity: `${tenantBase}/internal-entities/:internalEntityId`,
  reports: `${tenantBase}/reports`,
  report: `${tenantBase}/reports/:reportId`,
  newReport: `${tenantBase}/reports/new`,
  suppliers: `${tenantBase}/suppliers`,
  supplier: `${tenantBase}/suppliers/:supplierId`,
  supplierVersions: `${tenantBase}/suppliers/:supplierId/versions`,
  supplierVersion: `${tenantBase}/suppliers/:supplierId/versions/:version`,
  tags: `${tenantBase}/tags`,
  tag: `${tenantBase}/tags/:tagId`,

  groups: `${tenantIamBase}/groups`,
  group: `${tenantIamBase}/groups/:groupId`,
  newGroup: `${tenantIamBase}/groups/new`,
  roles: `${tenantIamBase}/roles`,
  role: `${tenantIamBase}/roles/:roleId`,
  newRole: `${tenantIamBase}/roles/new`,
  users: `${tenantIamBase}/users`,
  user: `${tenantIamBase}/users/:username`,
  newUser: `${tenantIamBase}/users/new`,

  // Old routes that have been replaced by state based modals
  // Should be removed in the future, but for now we just redirect to the listings instead.
  newAgreement: `${tenantBase}/agreements/new`,
  newSupplier: `${tenantBase}/suppliers/new`,
  newCategory: `${tenantBase}/categories/new`,
  newInternalEntity: `${tenantBase}/internal-entities/new`,
  newTag: `${tenantBase}/tags/new`,
  newCabinet: `${tenantBase}/cabinets/new`,
  newAgreementType: `${tenantBase}/agreement-types/new`,
};
