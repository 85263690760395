/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAccessToken } from '@fcg-tech/regtech-api-utils';
import loglevel, { RootLogger } from 'loglevel';
import remote, { RemoteOptions } from 'loglevel-plugin-remote';
import { ILogger, LoggerConfiguration, LogOptions } from './types';
import { formatLog } from './utils';

export class Logger implements ILogger {
  private readonly console: RootLogger;

  constructor(config: LoggerConfiguration) {
    const options: RemoteOptions = {
      ...config,
      url: config.url,
      token: config.accessToken ?? getAccessToken(),
      method: config?.method ?? 'POST',
      headers: config?.headers ?? undefined,
      format: config?.format ?? formatLog,
    };
    remote.apply(loglevel, options);
    this.console = loglevel;
    this.console.enableAll();
    this.console.noConflict();
  }

  info(message: any, options?: LogOptions) {
    if (options) {
      this.console.info(`${message} %o`, options);
    } else {
      this.console.info(message);
    }
  }

  warn(message: any, options?: LogOptions) {
    if (options) {
      this.console.warn(`${message} %o`, options);
    } else {
      this.console.warn(message);
    }
  }

  debug(message: any, options?: LogOptions) {
    if (options) {
      this.console.debug(`${message} %o`, options);
    } else {
      this.console.debug(message);
    }
  }

  error(message: any, options?: LogOptions) {
    if (options) {
      this.console.error(`${message} %o`, options);
    } else {
      this.console.error(message);
    }
  }

  trace(message: any, options?: LogOptions) {
    if (options) {
      this.console.trace(`${message} %o`, options);
    } else {
      this.console.trace(message);
    }
  }
}
