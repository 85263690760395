import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { Field, Label } from '@fcg-tech/regtech-components';
import {
  BooleanDecision,
  DynamicList,
  Select,
  TextArea,
  TextField,
} from '@fcg-tech/regtech-formik-components';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { countryState } from '../../states/countryState';
import { MessageKeys } from '../../translations/messageKeys';
import { countrySelectOptions } from '../../utils/optionConverters';
import { getTooltip } from '../../utils/tooltipHelpers';
import { FormRow, FormColumn, FormGroup } from '../FormLayout';

export const InternalEntityDetailsSubForm = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  const countries = useRecoilValue(countryState);

  const countryOptions = useMemo(
    () => countries?.map(countrySelectOptions),
    [countries],
  );

  return (
    <FormRow>
      <FormColumn>
        <FormGroup>
          <Label column htmlFor="name">
            {t(MessageKeys.LabelName)}
          </Label>
          <Field
            component={TextField}
            name="data.name"
            type="text"
            id="name"
            info={getTooltip('internalEntity.name')}
          />
        </FormGroup>
        <FormGroup>
          <Label column htmlFor="country-of-registration">
            {t(
              MessageKeys.InternalEntityDetailsSubFormCountryRegistrationLabel,
            )}
          </Label>
          <Field
            component={Select}
            name="data.countryOfRegistration"
            items={countryOptions}
            id="country-of-registration"
            info={getTooltip('internalEntity.countryOfRegistration')}
            typeahead
          />
        </FormGroup>
        <FormGroup>
          <Label column htmlFor="registered-address">
            {t(MessageKeys.InternalEntityDetailsSubFormRegistratedAddressLabel)}
          </Label>
          <Field
            component={TextArea}
            name="data.registeredAddress"
            id="registered-address"
            info={getTooltip('internalEntity.registeredAddress')}
          />
        </FormGroup>
        <FormGroup>
          <BooleanDecision.FieldContainer>
            <BooleanDecision.FieldLabel>
              {t(MessageKeys.InternalEntityDetailsSubFormUltimateParentLabel)}
            </BooleanDecision.FieldLabel>
            <Field
              component={BooleanDecision}
              name="data.isUltimateParent"
              info={getTooltip('internalEntity.isUltimateParent')}
            />
          </BooleanDecision.FieldContainer>
        </FormGroup>
        {!values.data.isUltimateParent && (
          <FormGroup>
            <Label column htmlFor="parent-company">
              {t(MessageKeys.InternalEntityDetailsSubFormParentCompanyLabel)}
            </Label>
            <Field
              component={TextField}
              type="text"
              name="data.parentCompany"
              id="entity-parent-company"
              info={getTooltip('internalEntity.parentCompany')}
            />
          </FormGroup>
        )}
      </FormColumn>
      <FormColumn>
        <FormGroup>
          <Label column htmlFor="corporate-registration-number">
            {t(
              MessageKeys.InternalEntityDetailsSubFormCorporateRegistrationNumberLabel,
            )}
          </Label>
          <Field
            component={TextField}
            name="data.corporateRegistrationNumber"
            type="text"
            id="corporate-registration-number"
            info={getTooltip('internalEntity.corporateRegistrationNumber')}
          />
        </FormGroup>
        <FormGroup>
          <Label column htmlFor="identifier">
            {t(
              MessageKeys.InternalEntityDetailsSubFormLegalEntityIdentifierLabel,
            )}
          </Label>
          <Field
            component={TextField}
            name="data.legalEntityIdentifier"
            type="text"
            id="identifier"
            info={getTooltip('internalEntity.legalEntityIdentifier')}
          />
        </FormGroup>
        <FormGroup>
          <Label column htmlFor="identifier">
            {t(MessageKeys.InternalEntityDetailsSubFormContactDetailsLabel)}
          </Label>
          <Field
            component={DynamicList}
            name="data.contactDetails"
            id="contact-details"
            info={getTooltip('internalEntity.contactDetails')}
          />
        </FormGroup>
        <FormGroup>
          <Label column htmlFor="description">
            {t(MessageKeys.LabelDescription)}
          </Label>
          <Field
            id="description"
            name="data.description"
            component={TextArea}
            info={getTooltip('internalEntity.description')}
          />
        </FormGroup>
      </FormColumn>
    </FormRow>
  );
};
