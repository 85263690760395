import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  CardTitle,
  Field,
  Label,
} from '@fcg-tech/regtech-components';
import {
  BooleanDecision,
  PillMultiSelect,
  Select,
  TextArea,
} from '@fcg-tech/regtech-formik-components';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { MessageKeys } from '../../../translations/messageKeys';
import { getTooltip } from '../../../utils/tooltipHelpers';
import {
  agreementPropType,
  categoryPropType,
  internalEntityPropType,
  supplierPropType,
} from '../../../propTypes';
import { NONE_SELECT_ITEM, DEPLOYMENT_MODEL_OPTIONS } from '../../../constants';
import { countryState } from '../../../states/countryState';
import { regionState } from '../../../states/regionState';
import { regionSelectOption } from '../../../utils/optionConverters';
import { ErrorBoundary } from '../../ErrorBoundary';
import { FormRow, FormColumn, FormGroup } from '../../FormLayout';
import {
  SubcontractorsField,
  SubcontractorsFieldLabel,
} from '../../SubcontractorsField';
import { FieldCompare } from '../../FieldCompare/FieldCompare';

export const ServiceDetailsSection = ({
  agreement,
  categories,
  internalEntities,
  suppliers,
}) => {
  const { t } = useTranslation();
  const countries = useRecoilValue(countryState);
  const regions = useRecoilValue(regionState);

  const regionSelectOptions = useMemo(
    () => regions?.map(regionSelectOption),
    [regions],
  );

  const countrySelectOptions = useMemo(
    () =>
      countries?.map((country) => ({
        key: country.isoAlpha2,
        label: country.name,
      })),
    [countries],
  );

  const deploymentModelSelectOptions = useMemo(() => {
    return DEPLOYMENT_MODEL_OPTIONS.map(
      (option) => ({ label: option, key: option }),
      [],
    );
  }, []);

  return (
    <Card>
      <CardBody>
        <CardTitle>{t(MessageKeys.ServiceDetailsSectionLabel)}</CardTitle>
        <FormRow>
          <FormColumn>
            <FormGroup>
              <BooleanDecision.FieldContainer>
                <BooleanDecision.FieldLabel>
                  {t(MessageKeys.ServiceDetailsSectionCloudServiceLabel)}
                </BooleanDecision.FieldLabel>
                <Field
                  component={BooleanDecision}
                  name="serviceDetails.isProvidedAsCloudService"
                  info={getTooltip('serviceDetails.isProvidedAsCloudService')}
                />
              </BooleanDecision.FieldContainer>
              <FieldCompare name="serviceDetails.isProvidedAsCloudService" />
            </FormGroup>
          </FormColumn>
          <FormColumn />
        </FormRow>
        {agreement.serviceDetails.isProvidedAsCloudService && (
          <>
            <FormRow>
              <FormColumn>
                <FormGroup>
                  <Label column htmlFor="service-details-deployment-model">
                    {t(MessageKeys.ServiceDetailsSectionDeploymentModelLabel)}
                  </Label>
                  <Field
                    component={Select}
                    items={[NONE_SELECT_ITEM, ...deploymentModelSelectOptions]}
                    typeahead
                    name="serviceDetails.data.deploymentModel"
                    id="service-details-deployment-model"
                    info={getTooltip('serviceDetails.data.deploymentModel')}
                  />
                  <FieldCompare name="serviceDetails.data.deploymentModel" />
                </FormGroup>
              </FormColumn>
              <FormColumn />
            </FormRow>
            <FormRow>
              <FormColumn>
                <FormGroup>
                  <Label column htmlFor="service-details-nature-of-data">
                    {t(MessageKeys.ServiceDetailsSectionNatureDataLabel)}
                  </Label>
                  <Field
                    component={TextArea}
                    name="serviceDetails.data.natureOfData"
                    id="service-details-nature-of-data"
                    info={getTooltip('serviceDetails.data.natureOfData')}
                  />
                  <FieldCompare name="serviceDetails.data.natureOfData" />
                </FormGroup>
              </FormColumn>
            </FormRow>
          </>
        )}
        <FormRow>
          <FormColumn>
            <FormGroup>
              <Label
                column
                htmlFor="service-details-countries-where-data-is-performed"
              >
                {t(
                  MessageKeys.ServiceDetailsSectionCountriesServicePerformedLabel,
                )}
              </Label>
              <Field
                typeahead
                component={PillMultiSelect}
                name="serviceDetails.countriesWhereServiceIsPerformed"
                items={countrySelectOptions}
                id="service-details-countries-where-data-is-performed"
                info={getTooltip(
                  'serviceDetails.countriesWhereServiceIsPerformed',
                )}
              />
              <FieldCompare name="serviceDetails.countriesWhereServiceIsPerformed" />
            </FormGroup>
          </FormColumn>
          <FormColumn>
            <FormGroup>
              <Label
                column
                htmlFor="service-details-countries-or-regions-where-data-is-stored"
              >
                {t(MessageKeys.ServiceDetailsSectionCountriesDataStoredLabel)}
              </Label>
              <Field
                typeahead
                component={PillMultiSelect}
                name="serviceDetails.countriesOrRegionsWhereDataIsStored"
                items={[...regionSelectOptions, ...countrySelectOptions]}
                id="service-details-countries-or-regions-where-data-is-stored"
                info={getTooltip(
                  'serviceDetails.countriesOrRegionsWhereDataIsStored',
                )}
              />
              <FieldCompare name="serviceDetails.countriesOrRegionsWhereDataIsStored" />
            </FormGroup>
          </FormColumn>
        </FormRow>
        <ErrorBoundary>
          <FormRow>
            <FormColumn>
              <FormGroup>
                <SubcontractorsFieldLabel
                  column
                  info={getTooltip('serviceDetails.subcontractors')}
                >
                  {t(MessageKeys.ServiceDetailsSectionSubContractorsLabel)}
                </SubcontractorsFieldLabel>
                <Field
                  name="serviceDetails.subcontractors"
                  component={SubcontractorsField}
                  suppliers={suppliers}
                  internalEntities={internalEntities}
                  categories={categories}
                />
              </FormGroup>
            </FormColumn>
          </FormRow>
        </ErrorBoundary>
      </CardBody>
    </Card>
  );
};

ServiceDetailsSection.propTypes = {
  agreement: agreementPropType.isRequired,
  categories: PropTypes.arrayOf(categoryPropType),
  internalEntities: PropTypes.arrayOf(internalEntityPropType),
  suppliers: PropTypes.arrayOf(supplierPropType),
};

ServiceDetailsSection.defaultProps = {
  categories: [],
  suppliers: [],
  internalEntities: [],
};
