import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Field, Label } from '@fcg-tech/regtech-components';
import { FormRow, FormColumn, FormGroup } from '../FormLayout';
import { JsonEditorField } from '../JsonEditorField';

export const RolePolicySubForm = ({ readOnly }) => (
  <Suspense fallback={<div>Loading policy...</div>}>
    <FormRow>
      <FormColumn>
        <FormGroup>
          <Label column htmlFor="role-policy">
            Policy
          </Label>
          <Field
            id="role-policy"
            name="policy"
            component={JsonEditorField}
            readOnly={readOnly}
          />
        </FormGroup>
      </FormColumn>
    </FormRow>
  </Suspense>
);

RolePolicySubForm.propTypes = {
  readOnly: PropTypes.bool,
};

RolePolicySubForm.defaultProps = {
  readOnly: false,
};
