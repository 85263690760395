import React, { useCallback } from 'react';
import { useAsync } from 'react-async';
import { useNavigate, useMatch } from 'react-router-dom';
import {
  ConfirmDialog,
  EditProvider,
  Loader,
} from '@fcg-tech/regtech-components';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import {
  loadGroup,
  updateGroup,
  loadGroupRoles,
  loadGroupUsers,
  deleteGroup,
  deleteGroupUser,
  deleteGroupRole,
  loadRoles,
  loadRole,
  addGroupRole,
  addGroupUser,
  loadUsers,
} from '../../api';
import { routes } from '../../routes';
import { useTenant } from '../../states/tenantState';
import { GroupPage } from './components/GroupPage';
import { AddGroupRoleModal } from './components/AddGroupRoleModal';
import { AddGroupUserModal } from './components/AddGroupUserModal';
import { EditGroupRoleModal } from './components/EditGroupRoleModal';
import { ErrorMessage } from '../../components/ErrorBoundary';

export const GroupContainer = () => {
  const navigate = useNavigate();
  const tenant = useTenant();
  const tenantId = tenant.id;
  const match = useMatch(routes.group);
  const { groupId } = match?.params ?? {};

  const [isSaving, setIsSaving] = React.useState(false);
  const [isEditEnabled, setIsEditEnabled] = React.useState(false);
  const [showAddGroupRoleModal, setShowAddGroupRoleModal] =
    React.useState(false);
  const [showAddGroupUserModal, setShowAddGroupUserModal] =
    React.useState(false);
  const [editGroupRole, setEditGroupRole] = React.useState(undefined);
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);

  // promise fns
  const groupReq = useAsync({
    promiseFn: loadGroup,
    tenantId,
    groupId,
  });

  const groupRolesReq = useAsync({
    promiseFn: loadGroupRoles,
    tenantId,
    groupId,
  });

  const groupUsersReq = useAsync({
    promiseFn: loadGroupUsers,
    tenantId,
    groupId,
  });

  const allUsersReq = useAsync({
    promiseFn: loadUsers,
    tenantId,
  });

  const allRolesReq = useAsync({
    promiseFn: loadRoles,
    tenantId,
  });

  // proxy fns
  const updateGroupProxy = async (args) => {
    setIsSaving(true);
    try {
      await updateGroup(...args);
      setIsSaving(false);
      groupReq.reload();
      setIsEditEnabled(false);
    } catch (err) {
      setIsSaving(false);
      throw err;
    }
  };

  const deleteGroupProxy = async (args) => {
    await deleteGroup(...args);
    navigate(constructUrl(routes.groups, { tenantId }));
  };

  const deleteUserProxy = async (args) => {
    await deleteGroupUser(...args);
    groupUsersReq.reload();
  };

  const deleteRoleProxy = async (args) => {
    await deleteGroupRole(...args);
    groupRolesReq.reload();
  };

  const addGroupRoleProxy = async (args) => {
    await addGroupRole(...args);
    groupRolesReq.reload();
    setShowAddGroupRoleModal(false);
  };

  const addGroupUserProxy = async (args) => {
    await addGroupUser(...args);
    groupUsersReq?.reload();
    setShowAddGroupUserModal(false);
  };

  const loadRoleProxy = async (args) => {
    const role = await loadRole(...args);
    return role;
  };

  const saveEditGroupRoleProxy = async (args) => {
    await addGroupRole(...args);
    groupRolesReq.reload();
    setEditGroupRole(undefined);
  };

  // defer fns
  const updateGroupReq = useAsync({ deferFn: updateGroupProxy });
  const deleteGroupReq = useAsync({ deferFn: deleteGroupProxy });
  const deleteUserReq = useAsync({ deferFn: deleteUserProxy });
  const deleteRoleReq = useAsync({ deferFn: deleteRoleProxy });
  const addGroupRoleReq = useAsync({ deferFn: addGroupRoleProxy });
  const addGroupUserReq = useAsync({ deferFn: addGroupUserProxy });
  const roleReq = useAsync({ deferFn: loadRoleProxy });
  const saveEditGroupRoleReq = useAsync({ deferFn: saveEditGroupRoleProxy });

  // group callbacks
  const handleSave = React.useCallback(
    (group) => {
      updateGroupReq.run({ tenantId, groupId, group });
    },
    [updateGroupReq, tenantId, groupId],
  );

  const handleDelete = React.useCallback(() => setShowDeleteConfirm(true), []);

  const handleDeleteChoice = React.useCallback(
    (choice) => {
      if (choice) {
        deleteGroupReq.run({ tenantId, groupId });
      }
      setShowDeleteConfirm(false);
    },
    [deleteGroupReq, tenantId, groupId],
  );

  const handleCancel = React.useCallback(() => setIsEditEnabled(false), []);

  const handleEdit = React.useCallback(() => setIsEditEnabled(true), []);

  // group role callbacks
  const handleAddRole = React.useCallback(() => {
    setShowAddGroupRoleModal(true);
  }, []);

  const handleViewRole = React.useCallback(
    (roleId) => {
      navigate(constructUrl(routes.role, { tenantId, roleId }));
    },
    [navigate, tenantId],
  );

  const handleRoleChange = React.useCallback(
    (roleId) => {
      roleReq.run({ tenantId, roleId });
    },
    [roleReq, tenantId],
  );

  const handleSaveGroupRole = React.useCallback(
    (updated) => {
      const { id, parameters } = updated;
      addGroupRoleReq.run({
        tenantId,
        groupId,
        roleId: id,
        parameters,
      });
    },
    [addGroupRoleReq, tenantId, groupId],
  );

  const handleEditRole = React.useCallback(
    (roleId) => {
      setEditGroupRole(
        groupRolesReq?.data?.roles?.find((role) => role.roleId === roleId),
      );
    },
    [groupRolesReq?.data?.roles],
  );

  const handleDeleteRole = React.useCallback(
    (roleId) => {
      deleteRoleReq.run({ tenantId, groupId, roleId });
    },
    [deleteRoleReq, tenantId, groupId],
  );

  const handleCloseAddGroupRoleModal = useCallback(
    () => setShowAddGroupRoleModal(false),
    [],
  );

  const handleSaveEditGroupRole = React.useCallback(
    (updated) => {
      const { roleId, parameters } = updated;
      saveEditGroupRoleReq.run({
        tenantId,
        groupId,
        roleId,
        parameters,
      });
    },
    [saveEditGroupRoleReq, tenantId, groupId],
  );

  const handleCloseEditGroupRoleModal = React.useCallback(() => {
    setEditGroupRole(undefined);
  }, []);

  // group user callbacks
  const handleDeleteUser = React.useCallback(
    (username) => {
      deleteUserReq.run({ tenantId, groupId, username });
    },
    [deleteUserReq, tenantId, groupId],
  );

  const handleAddUser = React.useCallback(
    () => setShowAddGroupUserModal(true),
    [],
  );

  const handleCloseAddGroupUserModal = useCallback(
    () => setShowAddGroupUserModal(false),
    [],
  );

  const handleSaveGroupUser = React.useCallback(
    ({ username }) => {
      addGroupUserReq.run({ tenantId, groupId, username });
    },
    [addGroupUserReq, tenantId, groupId],
  );

  if (groupReq.isLoading) {
    return <Loader message="Loading group" />;
  }

  const error =
    groupReq.error |
    groupRolesReq.error |
    allRolesReq.error |
    groupUsersReq.error;

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <>
      <EditProvider value={isEditEnabled}>
        {showDeleteConfirm ? (
          <ConfirmDialog
            title="Confirm"
            body="Are you sure you want to delete this group?"
            onChoice={handleDeleteChoice}
            confirmText="Yes, delete it"
            cancelText="No"
          />
        ) : null}
        <GroupPage
          group={groupReq.data}
          users={groupUsersReq?.data?.users || []}
          roles={groupRolesReq?.data?.roles || []}
          isLoadingUsers={groupUsersReq.isLoading}
          isLoadingRoles={groupRolesReq.isLoading}
          onEdit={handleEdit}
          onSave={handleSave}
          onCancel={handleCancel}
          onDelete={handleDelete}
          onAddRole={handleAddRole}
          onViewRole={handleViewRole}
          onEditRole={handleEditRole}
          onDeleteRole={handleDeleteRole}
          onAddUser={handleAddUser}
          onDeleteUser={handleDeleteUser}
          isSaving={isSaving}
        />
      </EditProvider>
      <EditProvider value>
        {showAddGroupRoleModal ? (
          <AddGroupRoleModal
            role={roleReq.isFulfilled ? roleReq.data : null}
            roles={allRolesReq?.data?.roles || []}
            onSave={handleSaveGroupRole}
            onCancel={handleCloseAddGroupRoleModal}
            onRoleChange={handleRoleChange}
          />
        ) : null}
        {showAddGroupUserModal ? (
          <AddGroupUserModal
            users={allUsersReq?.data?.users || []}
            groupUsers={groupUsersReq?.data?.users || []}
            onSave={handleSaveGroupUser}
            onCancel={handleCloseAddGroupUserModal}
          />
        ) : null}
        {editGroupRole ? (
          <EditGroupRoleModal
            role={editGroupRole}
            onSave={handleSaveEditGroupRole}
            onCancel={handleCloseEditGroupRoleModal}
          />
        ) : null}
      </EditProvider>
    </>
  );
};
