import styled from 'styled-components';

export const CheckboxWrapper = styled.div`
  position: relative;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  will-change: opacity;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const CheckboxPartiallyCheckedDot = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.7rem;
  height: 0.7rem;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: black;
`;

export const InputCheckboxWrapper = styled.label`
  position: relative;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  flex: 1;

  &.with-label {
    width: auto;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const InputCheckboxLabelWrapper = styled.span`
  display: inline-block;

  margin-right: 0.6rem;

  .lucide + & {
    margin-right: 0;
    margin-left: 0.6rem;
  }
`;

export const InputCheckboxField = styled.div`
  display: flex;
  align-items: center;
  & > ${InputCheckboxWrapper}:last-child {
    margin-left: 0.6rem;
  }

  & > ${InputCheckboxWrapper}:first-child {
    margin-right: 0.6rem;
  }
`;

export const CheckboxHiddenInput = styled.input`
  height: 0;
  overflow: hidden;
  border: 0;
  width: 0;
  opacity: 0;
  position: fixed;
`;
