import { TextField as DefaultTextField } from '@fcg-tech/regtech-components';
import React from 'react';
import { FieldInputProps } from 'formik';

interface TextAreaProps
  extends React.ComponentPropsWithRef<typeof DefaultTextField> {
  field: FieldInputProps<string>;
}

export const TextField = React.forwardRef<HTMLInputElement, TextAreaProps>(
  ({ field, ...props }, ref) => (
    <DefaultTextField ref={ref} {...props} {...(field ?? {})} />
  )
);
