import { differenceInMilliseconds } from 'date-fns';
import { Notification } from '../api/schema';

/**
 * Merges notifications in b into a and sorts the result in descending order
 * @param a Base list of notifications
 * @param b New notifications
 */
export const mergeNotifications = (
  a: Array<Notification>,
  b: Array<Notification>,
): Array<Notification> => {
  const newArr = [...a];

  const map = newArr.reduce<Record<string, Notification>>((c, n) => {
    c[n.id] = n;
    return c;
  }, {});

  b.forEach((n) => {
    if (map[n.id]) {
      map[n.id].read = n.read;
    } else {
      newArr.push(n);
    }
  });

  return newArr.sort(
    (a, b) => differenceInMilliseconds(a.timestamp, b.timestamp) * -1,
  );
};
