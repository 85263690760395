import { useState, useEffect, useCallback } from 'react';

type ColumnKey = string;
type Row = Record<ColumnKey, unknown>;

const defaultSort = <T>(a: T, b: T, columnKey: keyof T, ascending = true) => {
  const aVal = a[columnKey];
  const bVal = b[columnKey];
  if (typeof aVal === 'string' && typeof bVal === 'string') {
    return ascending
      ? aVal.localeCompare(bVal)
      : bVal.localeCompare(aVal);
  } else if (typeof aVal === 'number' && typeof bVal === 'number') {
    return ascending ? bVal - aVal : bVal - aVal;
  }

  return 0;
};

const sortItems = <T=Row>(
  items: Array<T>,
  columnKey: keyof T,
  ascending: boolean,
  sorter = defaultSort
) => items.sort((a, b) => sorter(a, b, columnKey, ascending));

interface UseSortableTableProps<T=Row> {
  column: keyof T;
  ascending?: boolean;
  items: Array<T>;
  sorter?: typeof defaultSort;
}

export const useSortableTable = <T=Row>({
  column,
  ascending = true,
  items,
  sorter = defaultSort,
}: UseSortableTableProps<T>) => {
  const [sortedColumnKey, setSortedColumnKey] = useState(column);
  const [isAscendingSortOrder, setAscSortOrder] = useState(ascending);
  const [sortedItems, setSortedItems] = useState(() =>
    sortItems(items, sortedColumnKey, isAscendingSortOrder, sorter)
  );

  useEffect(() => {
    const updatedItems = sortItems(
      items,
      sortedColumnKey,
      isAscendingSortOrder,
      sorter
    );
    setSortedItems(updatedItems);
  }, [isAscendingSortOrder, items, setSortedItems, sortedColumnKey, sorter]);

  const onClick = useCallback(
    (event) => {
      const key = event.currentTarget.getAttribute('data-key');
      if (key) {
        setSortedColumnKey((previousKey) => {
          if (key === previousKey) setAscSortOrder((prev) => !prev);
          setSortedItems((latestItems) =>
            latestItems.sort((a, b) =>
              sorter(
                a,
                b,
                key,
                key === previousKey
                  ? !isAscendingSortOrder
                  : isAscendingSortOrder
              )
            )
          );
          return key;
        });
      }
    },
    [
      setSortedColumnKey,
      setSortedItems,
      setAscSortOrder,
      isAscendingSortOrder,
      sorter,
    ]
  );

  return {
    onClick,
    role: 'button',
    sortedItems,
    sortedColumnKey,
    isAscendingSortOrder,
  };
};
