import React from 'react';
import { useAsync } from 'react-async';
import { useMatch } from 'react-router-dom';
import { EditProvider, Loader } from '@fcg-tech/regtech-components';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import { loadTag, loadTagAgreements } from '../../api';
import { routes } from '../../routes';
import { useTenant } from '../../states/tenantState';
import { EditTagPage } from './components';
import { ErrorMessage } from '../../components/ErrorBoundary';

export const EditTagContainer = () => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const tenantId = tenant.id;
  const match = useMatch(routes.tag);
  const { tagId } = match?.params ?? {};

  const loadTagReq = useAsync({
    promiseFn: loadTag,
    tenantId,
    tag: tagId,
  });

  const loadTagAgreementsReq = useAsync({
    promiseFn: loadTagAgreements,
    tenantId,
    tag: tagId,
    isArchived: false,
  });

  if (loadTagReq.isLoading) {
    return <Loader message={t(MessageKeys.LabelLoadingTag)} />;
  }

  if (loadTagReq.error) {
    return <ErrorMessage error={loadTagReq.error} />;
  }

  return (
    <EditProvider value={false}>
      <EditTagPage
        tag={loadTagReq.data}
        agreements={
          loadTagAgreementsReq.isFulfilled
            ? loadTagAgreementsReq.data.result
            : []
        }
      />
    </EditProvider>
  );
};
