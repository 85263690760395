import { createContext, useContext } from 'react';

export interface AttachmentControlContextProps {
  onDownloadAttachment?: (attachmentId: string) => Promise<string>;
  onDeleteAttachment?: (attachmentId: string) => Promise<boolean>;
  onUploadAttachment?: (
    acceptedFiles: File[],
    section?: string,
  ) => Promise<void>;
  onRenameAttachment?: (
    attachmentId: string,
    filename?: string,
  ) => Promise<boolean>;
}

export const AttachmentControlContext =
  createContext<AttachmentControlContextProps>({});

export const AttachmentControlProvider = AttachmentControlContext.Provider;

export const useAttachmentControl = () => useContext(AttachmentControlContext);
