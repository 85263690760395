import styled, { css } from 'styled-components';

import { IconButton } from '../Button';
import { CrossIcon } from '../Icons';
import { excludeProps } from '../../utils';
import { NotificationType } from '../../types';

export const NotificationWrapper = styled.section.withConfig(
  excludeProps('type'),
)<{
  type?: NotificationType;
}>`
  ${({ theme, type = NotificationType.Info }) => css`
    border-radius: 0.2rem;
    background-color: ${theme.colors[type as string]?.main ?? '#fff'};
    box-shadow: 0 0 0 1px ${theme.colors[type as string]?.main ?? '#000'},
      0 2px 3px rgba(0, 0, 0, 0.2);
    flex: 1;

    @media print {
      border: 1px solid ${theme?.palette?.MainBlack ?? '#000'};
      background-color: ${theme?.palette?.MainWhite ?? '#fff'};
      box-shadow: none;
    }
  `}
`;

export const Label = styled.div.withConfig(excludeProps('type'))<{
  type?: NotificationType;
}>`
  ${({ theme, type = NotificationType.Info }) => css`
    color: ${theme.colors[type as string]?.contrast ?? '#000'};
    font-family: ${theme.typography?.fontFamily};
    font-size: 2rem;
    font-weight: 500;
    padding-top: 1.6rem;
    padding-left: 1rem;
    padding-right: 1rem;
  `}
`;

export const NotificationContent = styled.div.withConfig(excludeProps('type'))<{
  type?: NotificationType;
}>`
  ${({ theme, type = NotificationType.Info }) => css`
    color: ${theme.colors[type as string]?.contrast ?? '#000'};
    font-family: ${theme.typography?.fontFamily};
    font-size: 1.4rem;
    font-weight: 500;
    padding: 1.4rem 1.4rem 2rem 1.4rem;
  `}
`;

export const CloseButtonContainer = styled.div`
  position: relative;
  float: right;
`;

export const CloseButton = styled(IconButton)`
  padding: 0.3rem 0.9rem;
  &:hover {
    background-color: transparent;
    border-radius: 0;
  }
`;

export const CloseIcon = styled(CrossIcon).withConfig(excludeProps('type'))<{
  type?: NotificationType;
}>`
  ${({ theme, type = NotificationType.Info }) => css`
    width: 1.4rem;
    height: 1.4rem;
    color: ${theme.colors[type as string]?.contrast ?? '#000'};
  `}
`;
