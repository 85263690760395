import React, { useContext } from 'react';
import { AuthContext } from './types';

const authContext = React.createContext<AuthContext>({
  isAuthenticated: false,
});

export { authContext };

export const useAuthContext = () => useContext(authContext);
