import {
  DashboardCard,
  DashboardCardHeader,
  medium,
  narrow,
  UnstyledButton,
  wide,
  xWide,
} from '@fcg-tech/regtech-components';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { MainLayoutPaddedContentWrapper } from '../../components/MainLayout/MainLayout.styles';

export const DashboardPageWrapper = styled.div``;

export const DashboardPageCardsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  margin-top: 1rem;

  ${wide(
    () => css`
      grid-template-columns: repeat(3, 1fr);
    `,
  )}
  ${xWide(
    () => css`
      grid-template-columns: repeat(4, 1fr);
    `,
  )}
  ${medium(
    () => css`
      grid-template-columns: repeat(2, 1fr);
    `,
  )}
  ${narrow(
    () => css`
      grid-template-columns: repeat(1, 1fr);
    `,
  )}
`;

export const StyledMainLayoutPaddedContentWrapper = styled(
  MainLayoutPaddedContentWrapper,
)`
  min-height: 100vh;
`;

export const OasysDashboardCard = styled(DashboardCard)`
  background-color: white;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-color: #bbb;
  grid-column: span 1;
  max-width: 100%;
  overflow: hidden;
  & > ${DashboardCardHeader} {
    border-color: #bbb;
  }
`;

export const DashboardCardTitleWrapper = styled.div`
  flex: 1 0 0;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 0.4rem;
`;

export const DashboardCardRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  flex: 1;
`;

export const DashboardCardBodyInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: 0;
  flex: 1;
  & + & {
    margin-top: 1.6rem;
    border-top: 1px solid #bbb;
    padding-top: 1.6rem;
  }
`;

export const DashboardCardChartWrapper = styled.div`
  margin: 0 auto;
  padding: 0;
  flex: 1;
  min-width: 0;
`;

export const DashboardCardListWrapper = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  flex: 1;
  min-width: 0;
`;

export const DashboardCardListItem = styled.li`
  display: flex;
  align-items: center;
  & + & {
    margin-top: 0.8rem;
  }
`;

export const DashboardCardListCheckbutton = styled(UnstyledButton)`
  margin-left: 1.6rem;
  color: #777;
  &:hover {
    color: black;
  }
`;

export const DashboardCardListItemText = styled(Link)`
  flex: 1;
  font-size: 1.4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  color: black;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  &:visited,
  &:active {
    color: black;
  }
  &:hover {
    color: black;
    text-decoration: underline;
  }
  &.read {
    text-decoration: line-through;
  }
`;

export const DashboardCardListItemTextTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  margin-bottom: 0.4rem;
`;

export const DashboardCardListItemTextBody = styled.div`
  display: inline-block;
  & + & {
    margin-left: 1rem;
  }
`;

export const DashboardCardListItemIconWrapper = styled.div`
  margin-right: 1rem;
  color: #777;
`;

export const DashboardCardCountWrapper = styled.div`
  font-size: 2rem;
  font-weight: 600;
  padding: 1rem 1rem 1rem 0;
  text-align: center;
  width: 14rem;
  color: #444;
  & .count {
    font-size: 4rem;
    display: block;
    color: black;
  }
  ${medium(
    () => css`
      font-size: 1.6rem;
      width: 10rem;
      & .count {
        font-size: 2.4rem;
      }
    `,
  )}
  ${narrow(
    () => css`
      width: initial;
      padding: 1rem 4rem 1rem 3rem;
    `,
  )}
`;

export const DashboardCardNoContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  font-weight: 600;
  font-size: 2rem;
  color: black;
  flex: 1;
  ${medium(
    () => css`
      font-size: 2.4rem;
    `,
  )}
`;

export const DashboardCardNoContentIconWrapper = styled.div`
  ${({ theme }) => css`
    width: 4ch;
    height: 4ch;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme?.colors?.success?.main ?? 'green'};
    color: white;
    margin: 1ch;
    border-radius: 50%;
  `}
`;

export const DashboardVideoThumbnailWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DashboardVideoThumbnailText = styled.p`
  font-size: 1.6rem;
  font-weight: 500;
`;

export const DashboardVideoThumbnail = styled.img`
  width: 100%;
  height: 100%;
  transform: width 0.2s linear;
  &:hover {
    cursor: pointer;
  }
`;
