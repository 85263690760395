import { stringify } from 'query-string';

/** @deprecated - use @fcg-tech/regtech-auth version instead */
const ACCESS_TOKEN = 'ACCESS_TOKEN';

/** @deprecated - use @fcg-tech/regtech-auth version instead */
export const setAccessToken = (value: string) => {
  window.sessionStorage.setItem(ACCESS_TOKEN, value);
};

/** @deprecated - use @fcg-tech/regtech-auth version instead */
export const getAccessToken = (): string | null => {
  return window.sessionStorage.getItem(ACCESS_TOKEN);
};

const makeStateKey = () => Math.random().toString(36).substring(7);

type StartLoginFlowProps = {
  returnUrl?: string;
  responseType?: string;
  clientId: string;
  redirectUri: string;
  domain: string;
  scope: string;
};

export const startLoginFlow = ({
  returnUrl = '/',
  responseType = 'token',
  clientId,
  redirectUri,
  domain,
  scope,
}: StartLoginFlowProps) => {
  const stateKey = makeStateKey();
  window.localStorage.setItem(stateKey, JSON.stringify({ returnUrl }));
  const queryParameters = {
    response_type: responseType,
    client_id: clientId,
    redirect_uri: redirectUri,
    state: stateKey,
  };
  window.location.href = `${domain}/login?${stringify(
    queryParameters,
  )}&scope=${scope}`;
};

export const endLoginFlow = (accessToken: string) => {
  setAccessToken(accessToken);
};

type LogoutProps = {
  clientId: string;
  redirectUri: string;
  logoutUri: string;
  responseType: string;
  domain: string;
};

export const logout = ({
  clientId,
  redirectUri,
  logoutUri,
  responseType,
  domain,
}: LogoutProps) => {
  window.sessionStorage.clear();
  const queryParameters = {
    client_id: clientId,
    redirect_uri: redirectUri,
    response_type: responseType,
    logout_uri: logoutUri,
  };
  window.location.href = `${domain}/logout?${stringify(queryParameters)}`;
};
