import styled, { css } from 'styled-components';
import { InfoCircle } from '../InfoCircle';

export const TextFieldWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    flex: 1;
    font-family: ${theme.typography?.fontFamily};
    font-size: inherit;
    font-weight: inherit;
    height: 3.8rem;
    position: relative;

    ${theme?.elements?.textField?.wrapper ?? ''}
  `}
`;

export const TextFieldBorderContainer = styled.div`
  ${({ theme }) => css`
    background-color: white;
    border: 1px solid ${theme.colors?.border ?? 'rgba(0,0,0,0.3)'};
    border-radius: 5px;
    flex: 1;
    overflow: hidden;

    &.disabled {
      background-color: #eee;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }

    &.error {
      border: 1px solid ${theme.colors?.error?.main ?? 'red'};
      box-shadow: 0 0 0 1px ${theme.colors?.error?.main ?? 'rgba(0,0,0,0.1)'};
    }

    &.edit-disabled {
      border-color: transparent;
      background-color: transparent;
      padding: 1rem 1rem 1rem 0;
      font-family: ${theme?.typography?.fontFamily ?? 'inherit'};
      font-size: inherit;
      font-weight: 400;
    }

    ${theme?.elements?.textField?.borderContainer ?? ''}
  `}
`;

export const StyledInfoCircle = styled(InfoCircle)`
  margin-left: 1rem;
`;

export const TextFieldInput = styled.input`
  border: 0;
  margin: 0;
  padding: 1rem;
  font-family: ${({ theme }) => theme?.typography?.fontFamily ?? 'inherit'};
  font-size: inherit;
  font-weight: 400;
  background: none;
  outline: 0;
  height: 3.8rem;
  width: 100%;
  ${({ theme }) => theme?.elements?.textField?.input ?? ''}
`;

export const InputErrorMessage = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 0.4rem;
  font-size: 1.2rem;
  color: ${({ theme }) => theme?.colors?.error?.main ?? 'red'};
`;
