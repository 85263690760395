import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  CardTitle,
  Field,
  Label,
} from '@fcg-tech/regtech-components';
import { BooleanDecision, TextArea } from '@fcg-tech/regtech-formik-components';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../../translations/messageKeys';
import { getTooltip } from '../../../utils/tooltipHelpers';
import {
  attachmentPropType,
  metadataPropType,
  agreementPropType,
} from '../../../propTypes';
import { FormRow, FormColumn, FormGroup } from '../../FormLayout';
import { Attachments } from '../../Attachments';
import { FieldCompare } from '../../FieldCompare/FieldCompare';

export const NPAPSection = ({ agreement, metadata, attachments }) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardBody>
        <CardTitle>{t(MessageKeys.NPAPSectionLabel)}</CardTitle>
        <FormRow>
          <FormColumn>
            <FormGroup>
              <BooleanDecision.FieldContainer>
                <BooleanDecision.FieldLabel>
                  {t(MessageKeys.LabelRequired)}
                </BooleanDecision.FieldLabel>
                <Field
                  component={BooleanDecision}
                  name="npap.npapRequired"
                  info={getTooltip('npap.npapRequired')}
                  isClearable
                />
              </BooleanDecision.FieldContainer>
              <FieldCompare name="npap.npapRequired" />
            </FormGroup>
          </FormColumn>
          <FormColumn />
        </FormRow>
        {agreement.npap.npapRequired && (
          <>
            <FormRow>
              <FormColumn>
                <FormGroup>
                  <BooleanDecision.FieldContainer>
                    <BooleanDecision.FieldLabel>
                      {t(MessageKeys.LabelCompleted)}
                    </BooleanDecision.FieldLabel>
                    <Field
                      component={BooleanDecision}
                      name="npap.data.npapCompleted"
                      info={getTooltip('npap.data.npapCompleted')}
                      isClearable
                    />
                  </BooleanDecision.FieldContainer>
                  <FieldCompare name="npap.data.npapCompleted" />
                </FormGroup>
              </FormColumn>
              <FormColumn />
            </FormRow>
            <FormRow>
              <FormColumn>
                <FormGroup>
                  <Label column htmlFor="agreement-npap-summary">
                    {t(MessageKeys.LabelSummary)}
                  </Label>
                  <Field
                    component={TextArea}
                    name="npap.data.npapDescription"
                    id="agreement-npap-summary"
                    info={getTooltip('npap.data.npapDescription')}
                  />
                  <FieldCompare name="npap.data.npapDescription" />
                </FormGroup>
              </FormColumn>
            </FormRow>
            {metadata && (
              <Attachments attachments={attachments} section="npap" />
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
};

NPAPSection.propTypes = {
  agreement: agreementPropType.isRequired,
  metadata: metadataPropType,
  attachments: PropTypes.arrayOf(attachmentPropType),
};

NPAPSection.defaultProps = {
  metadata: null,
  attachments: [],
};
