/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { FormikConsumer, FormikErrors, getIn } from 'formik';
import { Notification, NotificationType } from '@fcg-tech/regtech-components';

import {
  ValidationErrorsSummaryWrapper,
  ValidationErrorsSummaryTitle,
  ValidationErrorsSummaryList,
  ValidationErrorsSummaryListItem,
} from './ValidationErrorsSummary.styles';

interface ValidationErrorsSummaryProps {
  title?: string;
}

export const errorMessageFlat = (error: FormikErrors<any>) => {
  const errorMessage: Array<string> = [];

  if (getIn(error, 'details.name.message')) {
    errorMessage.push(getIn(error, 'details.name.message'));
  }

  if (getIn(error, 'details.contractOwner.message')) {
    errorMessage.push(getIn(error, 'details.contractOwner.message'));
  }
  return errorMessage;
};

export const ValidationErrorsSummary = ({
  title,
}: ValidationErrorsSummaryProps) => (
  <FormikConsumer>
    {(formik) => {
      const error: FormikErrors<any> = formik.errors;
      const errorFlatten: Array<string> = errorMessageFlat(error);
      return error &&
        typeof error === 'object' &&
        Object.keys(error).length !== 0 ? (
        <ValidationErrorsSummaryWrapper>
          <Notification type={NotificationType.Error}>
            <ValidationErrorsSummaryTitle>{title}</ValidationErrorsSummaryTitle>
            <ValidationErrorsSummaryList>
              {errorFlatten.map((message) => (
                <ValidationErrorsSummaryListItem key={message}>
                  {message}
                </ValidationErrorsSummaryListItem>
              ))}
            </ValidationErrorsSummaryList>
          </Notification>
        </ValidationErrorsSummaryWrapper>
      ) : null;
    }}
  </FormikConsumer>
);
