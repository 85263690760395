import React from 'react';
import { randId } from '../../utils';
import { ItemList, ItemListItem } from '../ItemList';
import {
  DynamicListInner,
  DynamicListWrapper,
  StyledInfoCircle,
} from './DynamicList.styles';
import { DynamicListItem } from './DynamicListItem';
import { DynamicListItemRemoveButton } from './DynamicListItemRemoveButton';

// TODO: maybe go back using shorthash for this?
const generateKey = (value: string) => `${randId()}-${value}`;

export interface DynamicListProps {
  info?: string;
  isEditEnabled?: boolean;
  value?: Array<string>;
  placeholder?: string;
  onSetValue?: (value: string, index: number) => void;
  onRemoveValue?: (index: number) => void;
}

export const DynamicList = ({
  info,
  isEditEnabled = true,
  value: values = [],
  placeholder = 'Enter a new value and press enter',
  onSetValue,
  onRemoveValue,
}: DynamicListProps) => {
  const keys = React.useMemo(() => values.map(generateKey), [values]);

  const newItemRef = React.useRef<HTMLInputElement>();
  const [newItemValue, setNewItemValue] = React.useState('');

  const handleNewItemChange = React.useCallback(
    (event) => setNewItemValue(event.target.value),
    [setNewItemValue],
  );

  const handleNewItemBlur = React.useCallback(() => {
    if (newItemValue.length) {
      setNewItemValue('');
      setTimeout(() => newItemRef.current?.focus(), 10);
    }
  }, [newItemValue, setNewItemValue]);

  const handleNewItemKeyDown = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleNewItemBlur();
      }
    },
    [handleNewItemBlur],
  );

  const handleSetValue = React.useCallback(
    (value: string, index: number) => {
      if (value) {
        if (onSetValue && typeof onSetValue === 'function') {
          onSetValue(value, index);
        }
      }
    },
    [onSetValue],
  );

  const handleRemoveValue = React.useCallback(
    (index: number) => {
      if (onRemoveValue && typeof onRemoveValue === 'function') {
        onRemoveValue(index);
      }
    },
    [onRemoveValue],
  );

  return (
    <DynamicListWrapper>
      <DynamicListInner>
        {isEditEnabled &&
          values.map((value, index) => (
            <DynamicListItem
              key={keys[index]}
              index={index}
              value={value}
              onChanged={handleSetValue}
            >
              <DynamicListItemRemoveButton
                index={index}
                onClick={handleRemoveValue}
                key={keys[index]}
              />
            </DynamicListItem>
          ))}
        {isEditEnabled && (
          <DynamicListItem
            key="new-item"
            ref={newItemRef}
            placeholder={placeholder}
            onBlur={handleNewItemBlur}
            onChange={handleNewItemChange}
            onKeyDown={handleNewItemKeyDown}
            onChanged={handleSetValue}
          />
        )}
        {newItemValue.length && isEditEnabled ? (
          <DynamicListItem readOnly placeholder={placeholder} />
        ) : null}
        {!isEditEnabled && values.length ? (
          <ItemList>
            {values.map((value, index) => (
              <ItemListItem key={keys[index]}>{value}</ItemListItem>
            ))}
          </ItemList>
        ) : null}
      </DynamicListInner>
      {info && <StyledInfoCircle info={info} isEditEnabled={isEditEnabled} />}
    </DynamicListWrapper>
  );
};
