import { useMemo } from 'react';
import useSWR, { SWRConfiguration, useSWRConfig } from 'swr';
import { useNotificationsApi } from '../notificationsApiUtils';
import {
  ListNotificationResponse,
  ListNotificationsRequest,
  MarkLotsOfNotificationsAsReadRequest,
  MarkNotificationsAsUnreadRequest,
  NotificationsApi,
} from '../schema';
import { markNotificationsAsReadKey, notificationsKey } from './cacheKeys';

export const listNotificationsFetcher = async (
  api: NotificationsApi,
  application: string,
  {
    offset,
    unreadOnly,
  }: Pick<ListNotificationsRequest, 'offset' | 'unreadOnly'> = {},
) => {
  try {
    const result = await api.listNotifications({
      application,
      offset,
      unreadOnly,
    });
    return result;
  } catch (err) {
    throw new Error(`Failed to list all notifications from ${application}`);
  }
};

export const useNotifications = (
  baseEndpoint: string,
  application: string,
  params: Pick<ListNotificationsRequest, 'offset' | 'unreadOnly'> = {},
  config: SWRConfiguration = {},
) => {
  const api = useNotificationsApi(baseEndpoint);
  return useSWR<ListNotificationResponse>(
    notificationsKey(application, params.offset, params.unreadOnly),
    () => listNotificationsFetcher(api, application, params),
    { suspense: true, ...config },
  );
};

export const useNotificationsReadActions = (
  baseEndpoint: string,
  currentOffset?: string,
) => {
  const api = useNotificationsApi(baseEndpoint);
  const { mutate } = useSWRConfig();

  return useMemo(
    () => ({
      markNotificationsAsRead: async (
        application: string,
        ids: Array<string>,
      ) => {
        try {
          const requestParams: MarkLotsOfNotificationsAsReadRequest = {
            application,
            markAsReadData: {
              ids,
            },
          };
          const result = await api.markLotsOfNotificationsAsRead(requestParams);
          mutate(markNotificationsAsReadKey(application), result, false);
          mutate(notificationsKey(application, currentOffset, true));
          return result;
        } catch (err) {
          throw new Error(`Failed to mark notifications as read`);
        }
      },
      markNotificationsAsUnread: async (
        application: string,
        ids: Array<string>,
      ) => {
        try {
          const requestParams: MarkNotificationsAsUnreadRequest = {
            application,
            ids,
          };
          const result = await api.markNotificationsAsUnread(requestParams);
          mutate(markNotificationsAsReadKey(application), result, false);
          mutate(notificationsKey(application, currentOffset, true));
          return result;
        } catch (err) {
          throw new Error(`Failed to mark notifications as unread`);
        }
      },
    }),
    [api, currentOffset, mutate],
  );
};
