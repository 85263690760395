import { Pagination } from '@fcg-tech/regtech-components';
import { useDevice } from '@fcg-tech/regtech-utils';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Trans } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import {
  PaginatedTableHeaderCell,
  PaginatedTableHeaderHeading,
  PaginatedTableHeaderSummary,
  PaginatedTableHeaderWrapper,
  RightArticleTableHeaderCell,
} from './PaginatedTableHeader.styles';

interface PaginatedTableHeaderProps {
  heading?: string | JSX.Element;
  currentPage?: number;
  totalPages?: number;
  totalResults?: number;
  disabled?: boolean;
  onPageClick?: React.ComponentPropsWithoutRef<
    typeof Pagination
  >['onPageClick'];
}

export const PaginatedTableHeader: FunctionComponent<PropsWithChildren<PropsWithChildren<PaginatedTableHeaderProps>>> = ({
  heading,
  currentPage = 1,
  totalPages,
  totalResults,
  children,
  disabled,
  onPageClick,
}) => {
  const { isMobile } = useDevice();

  return (
    <PaginatedTableHeaderWrapper>
      <PaginatedTableHeaderCell>
        {heading ? (
          <PaginatedTableHeaderHeading>{heading}</PaginatedTableHeaderHeading>
        ) : null}
      </PaginatedTableHeaderCell>
      <RightArticleTableHeaderCell>
        {totalResults ? (
          <PaginatedTableHeaderSummary>
            <span>
              <Trans
                i18nKey={MessageKeys.LabelSearchResultSummary as string}
                values={{
                  totalResults: new Intl.NumberFormat().format(
                    Math.max(0, totalResults),
                  ),
                }}
                components={{
                  bold: <b />,
                }}
              />
            </span>
          </PaginatedTableHeaderSummary>
        ) : null}
        {onPageClick && totalPages > 1 ? (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            disabled={disabled}
            narrow={isMobile}
            onPageClick={onPageClick}
          />
        ) : null}
        {children}
      </RightArticleTableHeaderCell>
    </PaginatedTableHeaderWrapper>
  );
};
