import { FunctionComponent } from 'react';
import { AgreementListItem } from '../../api/schema';
import { DataTableRow, DataTableRowProps } from '@fcg-tech/regtech-datatable';
import { classNames } from '@fcg-tech/regtech-utils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
type AgreementTableRowProps = DataTableRowProps<AgreementListItem>;

export const AgreementTableRow: FunctionComponent<AgreementTableRowProps> = (
  props,
) => {
  const { row } = props;
  const agreement = row.original;

  return (
    <DataTableRow
      {...props}
      className={classNames(agreement.metadata.isArchived && 'archived')}
    />
  );
};
