/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AgreementCreateInput,
  AgreementVersionCreateInput,
  GetAgreementResponse,
  GetAgreementVersionResponse,
  ListAllAgreementVersionsResponse,
  ListAllAgreementsResponse,
} from '../models';
import {
    AgreementCreateInputFromJSON,
    AgreementCreateInputToJSON,
    AgreementVersionCreateInputFromJSON,
    AgreementVersionCreateInputToJSON,
    GetAgreementResponseFromJSON,
    GetAgreementResponseToJSON,
    GetAgreementVersionResponseFromJSON,
    GetAgreementVersionResponseToJSON,
    ListAllAgreementVersionsResponseFromJSON,
    ListAllAgreementVersionsResponseToJSON,
    ListAllAgreementsResponseFromJSON,
    ListAllAgreementsResponseToJSON,
} from '../models';

export interface ArchiveAgreementRequest {
    agreementId: string;
}

export interface CreateAgreementRequest {
    cabinet: string;
    force?: boolean;
    agreementCreateInput?: AgreementCreateInput;
}

export interface CreateAgreementVersionRequest {
    agreementId: string;
    version: string;
    force?: boolean;
    agreementVersionCreateInput?: AgreementVersionCreateInput;
}

export interface DeleteAgreementRequest {
    agreementId: string;
}

export interface GetAgreementRequest {
    agreementId: string;
}

export interface GetAgreementVersionRequest {
    agreementId: string;
    version: string;
}

export interface ListAllAgreementVersionsRequest {
    agreementId: string;
}

export interface ListAllAgreementsRequest {
    internalEntity?: string;
    supplier?: string;
    skip?: number;
    limit?: number;
    cabinet?: string;
    tag?: string;
    archivingReference?: string;
    category?: string;
    agreementType?: string;
    isArchived?: boolean;
    isProvidedAsCloudService?: boolean;
}

export interface UnarchiveAgreementRequest {
    agreementId: string;
}

export interface UpdateAgreementCabinetRequest {
    agreementId: string;
    cabinet: string;
}

/**
 * AgreementsApi - interface
 * 
 * @export
 * @interface AgreementsApiInterface
 */
export interface AgreementsApiInterface {
    /**
     * 
     * @summary Archive an agreement
     * @param {string} agreementId The id of the agreement to archive
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementsApiInterface
     */
    archiveAgreementRaw(requestParameters: ArchiveAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Archive an agreement
     */
    archiveAgreement(requestParameters: ArchiveAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Create an Agreement
     * @param {string} cabinet The name of the cabinet where to create the agreement.
     * @param {boolean} [force] Force to create the agreement even if the user is now allowed to see the agreement after the creation.
     * @param {AgreementCreateInput} [agreementCreateInput] Agreement item to add
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementsApiInterface
     */
    createAgreementRaw(requestParameters: CreateAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Create an Agreement
     */
    createAgreement(requestParameters: CreateAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Create a new version of an Agreement
     * @param {string} agreementId The id of the agreement to retrieve
     * @param {string} version The number of the version
     * @param {boolean} [force] Force to save the agreement even if the user is now allowed to see the agreement after the operation.
     * @param {AgreementVersionCreateInput} [agreementVersionCreateInput] Inventory item to add
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementsApiInterface
     */
    createAgreementVersionRaw(requestParameters: CreateAgreementVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Create a new version of an Agreement
     */
    createAgreementVersion(requestParameters: CreateAgreementVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Delete an Agreement
     * @param {string} agreementId The id of the agreement to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementsApiInterface
     */
    deleteAgreementRaw(requestParameters: DeleteAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete an Agreement
     */
    deleteAgreement(requestParameters: DeleteAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Get agreement
     * @param {string} agreementId The id of the agreement to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementsApiInterface
     */
    getAgreementRaw(requestParameters: GetAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAgreementResponse>>;

    /**
     * Get agreement
     */
    getAgreement(requestParameters: GetAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAgreementResponse>;

    /**
     * 
     * @summary Get an old version of an Agreement
     * @param {string} agreementId The id of the agreement to retrieve
     * @param {string} version The number of the version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementsApiInterface
     */
    getAgreementVersionRaw(requestParameters: GetAgreementVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAgreementVersionResponse>>;

    /**
     * Get an old version of an Agreement
     */
    getAgreementVersion(requestParameters: GetAgreementVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAgreementVersionResponse>;

    /**
     * 
     * @summary List all old version of an agreement
     * @param {string} agreementId The id of the agreement to retrieve old versions for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementsApiInterface
     */
    listAllAgreementVersionsRaw(requestParameters: ListAllAgreementVersionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListAllAgreementVersionsResponse>>;

    /**
     * List all old version of an agreement
     */
    listAllAgreementVersions(requestParameters: ListAllAgreementVersionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListAllAgreementVersionsResponse>;

    /**
     * 
     * @summary List all Agreements
     * @param {string} [internalEntity] pass an optional id to filter on internal entity
     * @param {string} [supplier] pass an optional id to filter on supplier
     * @param {number} [skip] number of records to skip for pagination
     * @param {number} [limit] maximum number of records to return
     * @param {string} [cabinet] Pass an optional cabinet name to filter on cabinet
     * @param {string} [tag] Pass an optional tag name to filter on tag
     * @param {string} [archivingReference] Pass an optional archivingReference name to filter
     * @param {string} [category] pass an optional id to filter on category
     * @param {string} [agreementType] pass an optional id to filter on agreementType
     * @param {boolean} [isArchived] pass an optional boolean to filter archived/unarchived agreements
     * @param {boolean} [isProvidedAsCloudService] pass an optional boolean to filter wether a cloud service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementsApiInterface
     */
    listAllAgreementsRaw(requestParameters: ListAllAgreementsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListAllAgreementsResponse>>;

    /**
     * List all Agreements
     */
    listAllAgreements(requestParameters: ListAllAgreementsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListAllAgreementsResponse>;

    /**
     * 
     * @summary Unarchive an agreement
     * @param {string} agreementId The id of the agreement to unarchive
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementsApiInterface
     */
    unarchiveAgreementRaw(requestParameters: UnarchiveAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Unarchive an agreement
     */
    unarchiveAgreement(requestParameters: UnarchiveAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Update cabinet for an agreement
     * @param {string} agreementId The id of the agreement to update the cabinet for
     * @param {string} cabinet New cabinet name for the agreement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementsApiInterface
     */
    updateAgreementCabinetRaw(requestParameters: UpdateAgreementCabinetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Update cabinet for an agreement
     */
    updateAgreementCabinet(requestParameters: UpdateAgreementCabinetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class AgreementsApi extends runtime.BaseAPI implements AgreementsApiInterface {

    /**
     * Archive an agreement
     */
    async archiveAgreementRaw(requestParameters: ArchiveAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling archiveAgreement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/agreements/{agreementId}/archive`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archive an agreement
     */
    async archiveAgreement(requestParameters: ArchiveAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.archiveAgreementRaw(requestParameters, initOverrides);
    }

    /**
     * Create an Agreement
     */
    async createAgreementRaw(requestParameters: CreateAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.cabinet === null || requestParameters.cabinet === undefined) {
            throw new runtime.RequiredError('cabinet','Required parameter requestParameters.cabinet was null or undefined when calling createAgreement.');
        }

        const queryParameters: any = {};

        if (requestParameters.cabinet !== undefined) {
            queryParameters['cabinet'] = requestParameters.cabinet;
        }

        if (requestParameters.force !== undefined) {
            queryParameters['force'] = requestParameters.force;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/oasys/agreements`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AgreementCreateInputToJSON(requestParameters.agreementCreateInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create an Agreement
     */
    async createAgreement(requestParameters: CreateAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createAgreementRaw(requestParameters, initOverrides);
    }

    /**
     * Create a new version of an Agreement
     */
    async createAgreementVersionRaw(requestParameters: CreateAgreementVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling createAgreementVersion.');
        }

        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version','Required parameter requestParameters.version was null or undefined when calling createAgreementVersion.');
        }

        const queryParameters: any = {};

        if (requestParameters.force !== undefined) {
            queryParameters['force'] = requestParameters.force;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/oasys/agreements/{agreementId}/versions/{version}`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))).replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AgreementVersionCreateInputToJSON(requestParameters.agreementVersionCreateInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new version of an Agreement
     */
    async createAgreementVersion(requestParameters: CreateAgreementVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createAgreementVersionRaw(requestParameters, initOverrides);
    }

    /**
     * Delete an Agreement
     */
    async deleteAgreementRaw(requestParameters: DeleteAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling deleteAgreement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/agreements/{agreementId}`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an Agreement
     */
    async deleteAgreement(requestParameters: DeleteAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAgreementRaw(requestParameters, initOverrides);
    }

    /**
     * Get agreement
     */
    async getAgreementRaw(requestParameters: GetAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAgreementResponse>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling getAgreement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/agreements/{agreementId}`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAgreementResponseFromJSON(jsonValue));
    }

    /**
     * Get agreement
     */
    async getAgreement(requestParameters: GetAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAgreementResponse> {
        const response = await this.getAgreementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get an old version of an Agreement
     */
    async getAgreementVersionRaw(requestParameters: GetAgreementVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAgreementVersionResponse>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling getAgreementVersion.');
        }

        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version','Required parameter requestParameters.version was null or undefined when calling getAgreementVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/agreements/{agreementId}/versions/{version}`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))).replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAgreementVersionResponseFromJSON(jsonValue));
    }

    /**
     * Get an old version of an Agreement
     */
    async getAgreementVersion(requestParameters: GetAgreementVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAgreementVersionResponse> {
        const response = await this.getAgreementVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all old version of an agreement
     */
    async listAllAgreementVersionsRaw(requestParameters: ListAllAgreementVersionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListAllAgreementVersionsResponse>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling listAllAgreementVersions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/agreements/{agreementId}/versions`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListAllAgreementVersionsResponseFromJSON(jsonValue));
    }

    /**
     * List all old version of an agreement
     */
    async listAllAgreementVersions(requestParameters: ListAllAgreementVersionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListAllAgreementVersionsResponse> {
        const response = await this.listAllAgreementVersionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all Agreements
     */
    async listAllAgreementsRaw(requestParameters: ListAllAgreementsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListAllAgreementsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.internalEntity !== undefined) {
            queryParameters['internalEntity'] = requestParameters.internalEntity;
        }

        if (requestParameters.supplier !== undefined) {
            queryParameters['supplier'] = requestParameters.supplier;
        }

        if (requestParameters.skip !== undefined) {
            queryParameters['skip'] = requestParameters.skip;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.cabinet !== undefined) {
            queryParameters['cabinet'] = requestParameters.cabinet;
        }

        if (requestParameters.tag !== undefined) {
            queryParameters['tag'] = requestParameters.tag;
        }

        if (requestParameters.archivingReference !== undefined) {
            queryParameters['archivingReference'] = requestParameters.archivingReference;
        }

        if (requestParameters.category !== undefined) {
            queryParameters['category'] = requestParameters.category;
        }

        if (requestParameters.agreementType !== undefined) {
            queryParameters['agreementType'] = requestParameters.agreementType;
        }

        if (requestParameters.isArchived !== undefined) {
            queryParameters['isArchived'] = requestParameters.isArchived;
        }

        if (requestParameters.isProvidedAsCloudService !== undefined) {
            queryParameters['isProvidedAsCloudService'] = requestParameters.isProvidedAsCloudService;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/agreements`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListAllAgreementsResponseFromJSON(jsonValue));
    }

    /**
     * List all Agreements
     */
    async listAllAgreements(requestParameters: ListAllAgreementsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListAllAgreementsResponse> {
        const response = await this.listAllAgreementsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Unarchive an agreement
     */
    async unarchiveAgreementRaw(requestParameters: UnarchiveAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling unarchiveAgreement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/agreements/{agreementId}/unarchive`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unarchive an agreement
     */
    async unarchiveAgreement(requestParameters: UnarchiveAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unarchiveAgreementRaw(requestParameters, initOverrides);
    }

    /**
     * Update cabinet for an agreement
     */
    async updateAgreementCabinetRaw(requestParameters: UpdateAgreementCabinetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling updateAgreementCabinet.');
        }

        if (requestParameters.cabinet === null || requestParameters.cabinet === undefined) {
            throw new runtime.RequiredError('cabinet','Required parameter requestParameters.cabinet was null or undefined when calling updateAgreementCabinet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/agreements/{agreementId}/cabinet/{cabinet}`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))).replace(`{${"cabinet"}}`, encodeURIComponent(String(requestParameters.cabinet))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update cabinet for an agreement
     */
    async updateAgreementCabinet(requestParameters: UpdateAgreementCabinetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateAgreementCabinetRaw(requestParameters, initOverrides);
    }

}
