import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useMatch } from 'react-router-dom';
import {
  Card,
  CardBody,
  PageHeader,
  PageLayout,
  PageLayoutPaddedContent,
  PrimaryButton,
} from '@fcg-tech/regtech-components';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { useAccessControl } from '../../../components/AccessControl';
import { routes } from '../../../routes';
import { DataTable } from '../../../components/DataTable';

const tableColumns = [
  { id: 'name', label: 'Name' },
  { id: 'numOfUsers', label: '# Users' },
];

const toTableData = ({ id, name, numOfUsers }) => ({
  id,
  name,
  numOfUsers: numOfUsers.toString(),
});

export const GroupsPage = ({ groups }) => {
  const navigate = useNavigate();
  const match = useMatch(routes.groups);
  const { tenantId } = match.params;

  const canAdd = useAccessControl('iam:GroupAdd');

  const handleRowClick = React.useCallback(
    (id) => {
      navigate(
        constructUrl(routes.group, {
          tenantId,
          groupId: id,
        }),
      );
    },
    [navigate, tenantId],
  );

  return (
    <PageLayout>
      <PageHeader title="Groups">
        {canAdd ? (
          <PrimaryButton to={constructUrl(routes.newGroup, { tenantId })}>
            Add new
          </PrimaryButton>
        ) : null}
      </PageHeader>
      <PageLayoutPaddedContent>
        <Card>
          <CardBody>
            <DataTable
              columns={tableColumns}
              data={groups?.map(toTableData)}
              onRowClick={handleRowClick}
              emptyMessage="No groups found."
              sortable={{ orderBy: 'name' }}
            />
          </CardBody>
        </Card>
      </PageLayoutPaddedContent>
    </PageLayout>
  );
};

const groupShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

GroupsPage.propTypes = {
  groups: PropTypes.arrayOf(groupShape),
};

GroupsPage.defaultProps = {
  groups: [],
};
