/**
 * LEGACY
 */

import { stringify } from 'query-string';
import {
  checkResponse,
  getAccessToken,
  DELETE,
  POST,
  PUT,
  PATCH,
  uploadToS3,
} from '@fcg-tech/regtech-api-utils';
import { environment } from '../environments/environment';
import { getLogger, getResponsePayload } from './logger';
import { checkAuthError } from './apiUtils';

// Authenticated fetch wrapper
export const doFetchWithSession = async (token, ...args) => {
  const [url, options] = args;
  const headers = (options ? options.headers : {}) || {};
  headers.Authorization = `Bearer ${token}`;

  const actualOptions = {
    mode: 'cors',
    method: 'GET',
    ...(options || {}),
    headers,
    cors: true,
  };

  const path = `${environment.apiBaseUrl}${url}`;
  const response = await fetch(path, actualOptions);
  if (checkResponse(response)) {
    return Promise.resolve(response);
  }
  if (!checkAuthError(response)) {
    getLogger().error('An error occured', getResponsePayload(response));
    throw response;
  }
};

export const apiFetch = async (...args) => {
  const accessToken = getAccessToken();
  if (!accessToken) {
    return Promise.reject(new Error('Not logged in'));
  }
  return await doFetchWithSession(accessToken, ...args);
};

export const loadCategories = async ({ tenantId }, { signal } = {}) => {
  const res = await apiFetch(`/${tenantId}/oasys/categories`, { signal });
  return res.json();
};

export const loadCategory = async ({ tenantId, categoryId }, { signal }) => {
  const res = await apiFetch(`/${tenantId}/oasys/categories/${categoryId}`, {
    signal,
  });
  return res.json();
};

export const loadCategoryAgreements = async (
  { tenantId, category, isArchived },
  { signal } = {},
) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/agreements?${stringify({ category, isArchived })}`,
    {
      signal,
    },
  );
  return res.json();
};

export const updateCategory = async (
  { tenantId, categoryId, category },
  { signal } = {},
) => {
  const res = await apiFetch(`/${tenantId}/oasys/categories/${categoryId}`, {
    ...PUT,
    body: JSON.stringify(category),
    signal,
  });
  return res;
};

export const createCategory = async (
  { tenantId, category },
  { signal } = {},
) => {
  const res = await apiFetch(`/${tenantId}/oasys/categories`, {
    ...POST,
    body: JSON.stringify(category),
    signal,
  });
  return res.json();
};

export const deleteCategory = async (
  { tenantId, categoryId },
  { signal } = {},
) => {
  const res = await apiFetch(`/${tenantId}/oasys/categories/${categoryId}`, {
    ...DELETE,
    signal,
  });
  return res;
};

export const loadInternalEntities = async ({ tenantId }, { signal } = {}) => {
  const res = await apiFetch(`/${tenantId}/oasys/internalEntities`, {
    signal,
  });
  return res.json();
};

export const loadInternalEntity = async (
  { tenantId, internalEntityId },
  { signal },
) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/internalEntities/${internalEntityId}`,
    { signal },
  );
  return res.json();
};

export const updateInternalEntity = async (
  { tenantId, internalEntityId, internalEntity },
  { signal } = {},
) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/internalEntities/${internalEntityId}`,
    {
      ...PUT,
      body: JSON.stringify(internalEntity),
      signal,
    },
  );
  return res;
};

export const createInternalEntity = async (
  { tenantId, internalEntity },
  { signal } = {},
) => {
  const res = await apiFetch(`/${tenantId}/oasys/internalEntities`, {
    ...POST,
    body: JSON.stringify(internalEntity),
    signal,
  });
  return res.json();
};

export const deleteInternalEntity = async (
  { tenantId, internalEntityId },
  { signal } = {},
) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/internalEntities/${internalEntityId}`,
    {
      ...DELETE,
      signal,
    },
  );
  return res;
};

export const loadInternalEntityAgreements = async (
  { tenantId, internalEntityId, isArchived },
  { signal } = {},
) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/agreements?${stringify({
      internalEntity: internalEntityId,
      isArchived,
    })}`,
    {
      signal,
    },
  );
  return res.json();
};

export const loadAgreements = async ({ tenantId }, { signal } = {}) => {
  const res = await apiFetch(`/${tenantId}/oasys/agreements`, {
    signal,
  });
  return res.json();
};

export const loadAgreementVersions = async (
  { tenantId, agreementId },
  { signal } = {},
) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/agreements/${agreementId}/versions`,
    {
      signal,
    },
  );
  return res.json();
};

export const loadAgreement = async (
  { tenantId, agreementId, version },
  { signal } = {},
) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/agreements/${agreementId}${
      version ? `/versions/${version}` : ''
    }`,
    { signal },
  );
  return res.json();
};

export const createAgreement = async (
  { tenantId, agreement, tags, cabinet, force = false },
  { signal } = {},
) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/agreements?${stringify({ cabinet })}${
      force ? '&force=true' : ''
    }`,
    {
      ...POST,
      body: JSON.stringify({ agreement, tags }),
      signal,
    },
  );
  return res.json();
};

export const updateAgreement = async (
  { tenantId, agreementId, agreement, tags, version, force = false },
  { signal } = {},
) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/agreements/${agreementId}/versions/${version}${
      force ? '?force=true' : ''
    }`,
    {
      ...PUT,
      body: JSON.stringify({ agreement, tags }),
      signal,
    },
  );
  return res;
};

export const deleteAgreement = async (
  { tenantId, agreementId },
  { signal } = {},
) => {
  const res = await apiFetch(`/${tenantId}/oasys/agreements/${agreementId}`, {
    ...DELETE,
    signal,
  });
  return res;
};

export const loadAgreementAttachments = async (
  { tenantId, agreementId, agreementVersion },
  { signal } = {},
) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/agreements/${agreementId}/attachments${
      agreementVersion ? `?${stringify({ agreementVersion })}` : ''
    }`,
    {
      signal,
    },
  );
  return res.json();
};

export const deleteAgreementAttachment = async (
  { tenantId, agreementId, attachmentId },
  { signal } = {},
) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/agreements/${agreementId}/attachments/${attachmentId}`,
    { ...DELETE, signal },
  );
  return res;
};

export const loadAgreementAttachmentViewUrl = async (
  { tenantId, agreementId, attachmentId },
  { signal } = {},
) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/agreements/${agreementId}/attachments/${attachmentId}`,
    { signal },
  );
  return res.json();
};

export const renameAgreementAttachment = async (
  { tenantId, agreementId, attachmentId, filename },
  { signal } = {},
) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/agreements/${agreementId}/attachments/${attachmentId}`,
    {
      ...PATCH,
      body: JSON.stringify({ filename }),
      signal,
    },
  );
  return res;
};

export const fetchAgreementAttachmentUploadUrl = async (
  { tenantId, agreementId, section, filename },
  { signal } = {},
) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/agreements/${agreementId}/attachments`,
    {
      ...POST,
      body: JSON.stringify({ section, filename }),
      signal,
    },
  );
  return res.json();
};

export const uploadAgreementAttachment = async ({
  tenantId,
  agreementId,
  section,
  files,
}) => {
  await Promise.all(
    files?.map(async (file) => {
      const { url } = await fetchAgreementAttachmentUploadUrl({
        tenantId,
        agreementId,
        section,
        filename: file.name,
      });
      await uploadToS3(url, file);
    }),
  );
};

export const loadSupplierAgreements = async (
  { tenantId, supplierId, isArchived },
  { signal } = {},
) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/agreements?${stringify({
      supplier: supplierId,
      isArchived,
    })}`,
    {
      signal,
    },
  );
  return res.json();
};

export const loadCalendarEvents = async ({ tenantId }, { signal }) => {
  const res = await apiFetch(`/${tenantId}/oasys/calendar/events`, {
    signal,
  });
  return res.json();
};

export const loadReports = async ({ tenantId }, { signal } = {}) => {
  const res = await apiFetch(`/${tenantId}/oasys/reports`, { signal });
  return res.json();
};

export const loadReport = async ({ tenantId, reportId }, { signal } = {}) => {
  const res = await apiFetch(`/${tenantId}/oasys/reports/${reportId}`, {
    signal,
  });
  return res.json();
};

export const createReport = async ({ tenantId, report }, { signal } = {}) => {
  const res = await apiFetch(`/${tenantId}/oasys/reports`, {
    ...POST,
    body: JSON.stringify(report),
    signal,
  });
  return res.json();
};

export const updateReport = async (
  { tenantId, reportId, report },
  { signal } = {},
) => {
  const res = await apiFetch(`/${tenantId}/oasys/reports/${reportId}`, {
    ...PUT,
    body: JSON.stringify(report),
    signal,
  });
  return res;
};

export const deleteReport = async ({ tenantId, reportId }, { signal } = {}) => {
  const res = await apiFetch(`/${tenantId}/oasys/reports/${reportId}`, {
    ...DELETE,
    signal,
  });
  return res;
};

export const loadReportAgreements = async ({ tenantId }, { signal } = {}) => {
  const res = await apiFetch(`/${tenantId}/oasys/reports/agreements`, {
    signal,
  });
  return res.json();
};

export const loadReportSuppliers = async ({ tenantId }, { signal } = {}) => {
  const res = await apiFetch(`/${tenantId}/oasys/reports/suppliers`, {
    signal,
  });
  return res.json();
};

export const downloadReportCSVFile = async (
  { tenantId, query, entity },
  { signal } = {},
) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/reports/${entity}CSV?${stringify({
      query,
    })}`,
    {
      signal,
    },
  );
  return res.blob();
};

export const loadSuppliers = async ({ tenantId }, { signal } = {}) => {
  const res = await apiFetch(`/${tenantId}/oasys/suppliers`, {
    signal,
  });
  return res.json();
};

export const loadSupplier = async (
  { tenantId, supplierId, version },
  { signal } = {},
) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/suppliers/${supplierId}${
      version ? `/versions/${version}` : ''
    }`,
    {
      signal,
    },
  );
  return res.json();
};

export const createSupplier = async (
  { tenantId, supplier },
  { signal } = {},
) => {
  const res = await apiFetch(`/${tenantId}/oasys/suppliers`, {
    ...POST,
    body: JSON.stringify(supplier),
    signal,
  });
  return res.json();
};

export const updateSupplier = async (
  { tenantId, supplierId, supplier, version },
  { signal } = {},
) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/suppliers/${supplierId}/versions/${version}`,
    {
      ...PUT,
      body: JSON.stringify(supplier),
      signal,
    },
  );
  return res;
};

export const deleteSupplier = async (
  { tenantId, supplierId },
  { signal } = {},
) => {
  const res = await apiFetch(`/${tenantId}/oasys/suppliers/${supplierId}`, {
    ...DELETE,
    signal,
  });
  return res;
};

export const loadSupplierVersions = async (
  { tenantId, supplierId },
  { signal } = {},
) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/suppliers/${supplierId}/versions`,
    {
      signal,
    },
  );
  return res.json();
};

export const loadSupplierAttachments = async (
  { tenantId, supplierId, supplierVersion },
  { signal } = {},
) => {
  let url = `/${tenantId}/oasys/suppliers/${supplierId}/attachments`;
  if (supplierVersion) {
    url += `?${stringify({ supplierVersion })}`;
  }
  const res = await apiFetch(url, { signal });
  return res.json();
};

export const fetchSupplierAttachmentUploadUrl = async (
  { tenantId, supplierId, filename, section },
  { signal } = {},
) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/suppliers/${supplierId}/attachments`,
    {
      ...POST,
      body: JSON.stringify({ filename, section }),
      signal,
    },
  );
  return res.json();
};

export const uploadSupplierAttachment = async ({
  tenantId,
  supplierId,
  section,
  files,
}) => {
  await Promise.all(
    files?.map(async (file) => {
      const { url } = await fetchSupplierAttachmentUploadUrl({
        tenantId,
        supplierId,
        section,
        filename: file.name,
      });
      await uploadToS3(url, file);
    }),
  );
};

export const downloadSupplierAttachment = async (
  { tenantId, supplierId, attachmentId },
  { signal } = {},
) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/suppliers/${supplierId}/attachments/${attachmentId}`,
    { signal },
  );
  return res.json();
};

export const deleteSupplierAttachment = async (
  { tenantId, supplierId, attachmentId },
  { signal } = {},
) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/suppliers/${supplierId}/attachments/${attachmentId}`,
    { ...DELETE, signal },
  );
  return res;
};

export const renameSupplierAttachment = async (
  { tenantId, supplierId, attachmentId, filename },
  { signal } = {},
) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/suppliers/${supplierId}/attachments/${attachmentId}`,
    {
      ...PATCH,
      body: JSON.stringify({ filename }),
      signal,
    },
  );
  return res;
};

export const loadPersonalSettings = async ({ tenantId }, { signal } = {}) => {
  const res = await apiFetch(`/${tenantId}/oasys/personalSettings`, {
    signal,
  });
  return res;
};

export const loadPersonalSettingsToken = async (
  { tenantId },
  { signal } = {},
) => {
  const res = await apiFetch(`/${tenantId}/oasys/personalSettings/token`, {
    signal,
  });
  return res.json();
};

export const createPersonalSettingsToken = async (
  { tenantId },
  { signal } = {},
) => {
  const res = await apiFetch(`/${tenantId}/oasys/personalSettings/token`, {
    ...POST,
    signal,
  });
  return res.json();
};

export const deletePersonalSettingsToken = async (
  { tenantId },
  { signal } = {},
) => {
  const res = await apiFetch(`/${tenantId}/oasys/personalSettings/token`, {
    ...DELETE,
    signal,
  });
  return res;
};

export const loadCabinets = async ({ tenantId }, { signal } = {}) => {
  const res = await apiFetch(`/${tenantId}/oasys/agreements/cabinets`, {
    signal,
  });
  return res.json();
};

export const createCabinet = async ({ tenantId, cabinet }, { signal } = {}) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/agreements/cabinets/${cabinet.name}`,
    {
      ...PUT,
      body: JSON.stringify(cabinet),
      signal,
    },
  );
  return res;
};

export const loadCabinet = async ({ tenantId, cabinet }, { signal } = {}) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/agreements/cabinets/${cabinet}`,
    {
      signal,
    },
  );
  return res.json();
};

export const deleteCabinet = async ({ tenantId, cabinet }, { signal } = {}) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/agreements/cabinets/${cabinet}`,
    {
      ...DELETE,
      signal,
    },
  );
  return res;
};

export const loadCabinetAgreements = async (
  { tenantId, cabinet, isArchived },
  { signal } = {},
) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/agreements?${stringify({
      cabinet,
      isArchived,
    })}`,
    {
      signal,
    },
  );
  return res.json();
};

export const loadTags = async ({ tenantId }, { signal } = {}) => {
  const res = await apiFetch(`/${tenantId}/oasys/agreements/tags`, { signal });
  return res.json();
};

export const createTag = async ({ tenantId, tag }, { signal } = {}) => {
  const res = await apiFetch(`/${tenantId}/oasys/agreements/tags/${tag.name}`, {
    ...PUT,
    body: JSON.stringify(tag),
    signal,
  });
  return res;
};

export const deleteTag = async ({ tenantId, tag }, { signal } = {}) => {
  const res = await apiFetch(`/${tenantId}/oasys/agreements/tags/${tag}`, {
    ...DELETE,
    signal,
  });
  return res;
};

export const loadTag = async ({ tenantId, tag }, { signal } = {}) => {
  const res = await apiFetch(`/${tenantId}/oasys/agreements/tags/${tag}`, {
    signal,
  });
  return res.json();
};

export const loadTagAgreements = async (
  { tenantId, tag, isArchived },
  { signal } = {},
) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/agreements?${stringify({ tag, isArchived })}`,
    {
      signal,
    },
  );
  return res.json();
};

// #region Groups
export const loadGroups = async ({ tenantId }, { signal } = {}) => {
  const res = await apiFetch(`/${tenantId}/oasys/iam/groups`, { signal });
  return res.json();
};

export const createGroup = async ({ tenantId, group }, { signal } = {}) => {
  const res = await apiFetch(`/${tenantId}/oasys/iam/groups`, {
    ...POST,
    body: JSON.stringify(group),
    signal,
  });
  return res.json();
};

export const loadGroup = async ({ tenantId, groupId }, { signal } = {}) => {
  const res = await apiFetch(`/${tenantId}/oasys/iam/groups/${groupId}`, {
    signal,
  });
  return res.json();
};

export const updateGroup = async (
  { tenantId, groupId, group },
  { signal } = {},
) => {
  const res = await apiFetch(`/${tenantId}/oasys/iam/groups/${groupId}`, {
    ...PUT,
    body: JSON.stringify(group),
    signal,
  });
  return res;
};

export const deleteGroup = async ({ tenantId, groupId }, { signal } = {}) => {
  const res = await apiFetch(`/${tenantId}/oasys/iam/groups/${groupId}`, {
    ...DELETE,
    signal,
  });
  return res;
};

export const loadGroupUsers = async (
  { tenantId, groupId },
  { signal } = {},
) => {
  const res = await apiFetch(`/${tenantId}/oasys/iam/groups/${groupId}/users`, {
    signal,
  });
  return res.json();
};

export const addGroupUser = async (
  { tenantId, groupId, username },
  { signal } = {},
) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/iam/groups/${groupId}/users/${encodeURIComponent(
      username,
    )}`,
    {
      ...PUT,
      signal,
    },
  );
  return res;
};

export const deleteGroupUser = async (
  { tenantId, groupId, username },
  { signal } = {},
) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/iam/groups/${groupId}/users/${encodeURIComponent(
      username,
    )}`,
    {
      ...DELETE,
      signal,
    },
  );
  return res;
};

export const loadGroupRoles = async (
  { tenantId, groupId },
  { signal } = {},
) => {
  const res = await apiFetch(`/${tenantId}/oasys/iam/groups/${groupId}/roles`, {
    signal,
  });
  return res.json();
};

export const addGroupRole = async (
  { tenantId, groupId, roleId, parameters },
  { signal } = {},
) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/iam/groups/${groupId}/roles/${roleId}`,
    {
      ...PUT,
      body: JSON.stringify({ parameters }),
      signal,
    },
  );
  return res;
};

export const deleteGroupRole = async (
  { tenantId, groupId, roleId },
  { signal } = {},
) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/iam/groups/${groupId}/roles/${roleId}`,
    { ...DELETE, signal },
  );
  return res;
};
// #endregion

export const loadRoles = async ({ tenantId }, { signal } = {}) => {
  const res = await apiFetch(`/${tenantId}/oasys/iam/roles`, {
    signal,
  });
  return res.json();
};

export const createRole = async ({ tenantId, role }, { signal } = {}) => {
  const res = await apiFetch(`/${tenantId}/oasys/iam/roles`, {
    ...POST,
    body: JSON.stringify(role),
    signal,
  });
  return res.json();
};

export const loadRole = async ({ tenantId, roleId }, { signal } = {}) => {
  const res = await apiFetch(`/${tenantId}/oasys/iam/roles/${roleId}`, {
    signal,
  });
  return res.json();
};

export const updateRole = async (
  { tenantId, roleId, role },
  { signal } = {},
) => {
  const res = await apiFetch(`/${tenantId}/oasys/iam/roles/${roleId}`, {
    ...PUT,
    body: JSON.stringify(role),
    signal,
  });
  return res;
};

export const deleteRole = async ({ tenantId, roleId }, { signal } = {}) => {
  const res = await apiFetch(`/${tenantId}/oasys/iam/roles/${roleId}`, {
    ...DELETE,
    signal,
  });
  return res;
};

export const loadUsers = async ({ tenantId }, { signal } = {}) => {
  const res = await apiFetch(`/${tenantId}/oasys/iam/users`, { signal });
  return res.json();
};

export const loadUser = async ({ tenantId, username }, { signal } = {}) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/iam/users/${encodeURIComponent(username)}`,
    {
      signal,
    },
  );
  return res.json();
};

export const deleteUser = async ({ tenantId, username }, { signal } = {}) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/iam/users/${encodeURIComponent(username)}`,
    {
      ...DELETE,
      signal,
    },
  );
  return res;
};

export const createUser = async ({ tenantId, user }, { signal } = {}) => {
  const res = await apiFetch(
    `/${tenantId}/oasys/iam/users/${encodeURIComponent(user.username)}`,
    {
      ...PUT,
      body: JSON.stringify(user),
      signal,
    },
  );
  return res;
};
