import { Theme } from '@fcg-tech/regtech-types';
import { css } from 'styled-components';
import { foundation } from './foundation';

const sidebarMainColor = foundation.palette.opal400;

const buttonStyle = css`
  border-radius: 0.3rem;
`;

export type RegeyeTheme = Theme;

export const HEADER_HEIGHT_REM = 7;

export const theme: RegeyeTheme = {
  version: '1',
  breakpoints: {
    mobile: 730,
    narrowDesktop: 1024,
    mediumDesktop: 1240,
  },

  elements: {
    header: {
      wrapper: css`
        height: ${HEADER_HEIGHT_REM}rem;
        border-bottom-width: 1px;
      `,
    },
    sideNavigation: {
      menu: {
        wrapper: css`
          color: ${sidebarMainColor.contrastHex};
        `,
      },
      item: {
        link: {
          nominal: css`
            color: ${sidebarMainColor.contrastHex};
          `,
          active: css`
            color: ${sidebarMainColor.contrastHex};
          `,
          focus: css`
            color: ${sidebarMainColor.contrastHex};
          `,
          hover: css`
            color: ${sidebarMainColor.contrastHex};
          `,
          visited: css`
            color: ${sidebarMainColor.contrastHex};
          `,
        },
        button: {
          nominal: css`
            color: ${sidebarMainColor.contrastHex};
          `,
          hover: css`
            color: ${sidebarMainColor.contrastHex};
          `,
          active: css`
            color: ${sidebarMainColor.contrastHex};
          `,
        },
      },
      sectionHeading: css`
        color: ${sidebarMainColor.contrastHex};
      `,
      expandButton: {
        icon: css`
          color: ${sidebarMainColor.contrastHex};
        `,
      },
    },
    sideBar: {
      hamburgerButton: {
        wrapper: css`
          color: ${sidebarMainColor.contrastHex};
        `,
      },
      logoWrapper: css`
        background: ${sidebarMainColor.hex};
        height: 7rem;
      `,
      wrapper: css`
        background: ${sidebarMainColor.hex};
        border-right: 1px solid rgba(0, 0, 0, 0.3);
      `,
    },
    button: {
      primary: css`
        ${buttonStyle}
        background-color: ${foundation.palette.emerald400.hex};
        color: ${foundation.palette.emerald400.contrastHex};
      `,
      secondary: buttonStyle,
    },
    card: {
      wrapper: css`
        background-color: ${foundation.palette.onyx100.hex};
      `,
    },
  },
};
