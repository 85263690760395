export enum FilterMessageKeys {
  LabelFrom = 'regtech-filter-label-from',
  LabelTo = 'regtech-filter-label-to',
  LabelSaveFilter = 'regtech-filter-save-filter',
  LabelUpdateFilter = 'regtech-filter-update-filter',
  LabelEditFilterName = 'regtech-filter-edit-filter-name',
  LabelDeleteFilter = 'regtech-filter-delete-filter',
  LabelClearFilter = 'regtech-filter-clear-filter',

  LoadFilter = 'regtech-filter-load-filter',
  PinFilterButtonLabel = 'regtech-filter-pin-button-label',
  UnpinFilterButtonLabel = 'regtech-filter-unpin-button-label',

  FilterSingleUserFilterTooltipLabel = 'regtec-filter-single-user-filter-tooltip-label',
  FilterMultiUserFilterTooltipLabel = 'regtec-filter-multi-user-filter-tooltip-label',

  FilterSelctMenuLoadingAdditional = 'filter-select-menu-loading-additional',

  RelativeDateIntervalToday = 'regtech-filter-relative-date-interval-today',
  RelativeDateIntervalLastWeek = 'regtech-filter-relative-date-interval-lastweek',
  RelativeDateIntervalLastThreeDays = 'regtech-filter-relative-date-interval-last-three-days',
  RelativeDateIntervalLastTwoWeeks = 'regtech-filter-relative-date-interval-last-two-weeks',
  RelativeDateIntervalThisWeek = 'regtech-filter-relative-date-intervalthisweek',
  RelativeDateIntervalThisPartialWeek = 'regtech-filter-relative-date-intervalthispartialweek',
  RelativeDateIntervalLastMonth = 'regtech-filter-relative-date-interval-lastmonth',
  RelativeDateIntervalThisMonth = 'regtech-filter-relative-date-intervalthismonth',
  RelativeDateIntervalThisPartialMonth = 'regtech-filter-relative-date-intervalthispartialmonth',
  RelativeDateIntervalLastQuarter = 'regtech-filter-relative-date-interval-lastquarter',
  RelativeDateIntervalThisQuarter = 'regtech-filter-relative-date-interval-thisquarter',
  RelativeDateIntervalThisPartialQuarter = 'regtech-filter-relative-date-interval-thispartialquarter',
  RelativeDateIntervalLastYear = 'regtech-filter-relative-date-interval-lastyear',
  RelativeDateIntervalThisYear = 'regtech-filter-relative-date-interval-thisyear',
  RelativeDateIntervalThisPartialYear = 'regtech-filter-relative-date-interval-thispartialyear',
  RelativeDateIntervalNextWeek = 'regtech-filter-relative-date-interval-nextweek',
  RelativeDateIntervalNextMonth = 'regtech-filter-relative-date-interval-nextmonth',
  RelativeDateIntervalOverdue = 'regtech-filter-relative-date-interval-overdue',
  RelativeDateIntervalCustom = 'regtech-filter-relative-date-interval-custom',
}

export type TranslationLocale = {
  language: string;
  messages: {
    [P in FilterMessageKeys]: string;
  };
};

export const RelativeDateIntervalPrefix = 'RelativeDateInterval';
