import { FunctionComponent } from 'react';
import { BooleanTreeWrapper } from './BooleanTree.styles';
import { BooleanTreeRow, BooleanTreeRowProps } from './BooleanTreeRow';
import { BooleanTreeItem } from './booleanTreeTypes';

export interface BooleanTreeProps {
  items?: Array<BooleanTreeItem>;
  onChange?: BooleanTreeRowProps['onChange'];
}

export const BooleanTree: FunctionComponent<BooleanTreeProps> = ({
  items = [],
  onChange,
}) => {
  return (
    <BooleanTreeWrapper>
      {items.map((item) => (
        <BooleanTreeRow key={item.id} item={item} onChange={onChange} />
      ))}
    </BooleanTreeWrapper>
  );
};
