import { useMemo } from 'react';
import useSWR, { mutate, SWRConfiguration } from 'swr';
import { getResourceIdFromLocationHeader, useApi } from '../apiUtils';
import {
  CategoriesApi,
  Category,
  CategoryListItem,
  GetCategoryResponse,
} from '../schema';
import { allCategoriesKey, categoryKey } from './cacheKeys';

export const getAllCategories = async (
  api: CategoriesApi,
): Promise<Array<CategoryListItem>> => {
  const result = await api.listAllCategories();
  return result.result ?? [];
};

export const getCategory = async (
  categoryId: string,
  api: CategoriesApi,
): Promise<GetCategoryResponse> => {
  const result = await api.getCategory({ categoryId });
  return result;
};

export const useCategories = (tenantId: string, config?: SWRConfiguration) => {
  const api = useApi<CategoriesApi>('CategoriesApi', tenantId);
  return useSWR<Array<CategoryListItem>>(
    allCategoriesKey(tenantId),
    () => getAllCategories(api),
    { suspense: true, ...config },
  );
};

export const useCategory = (categoryId: string, config?: SWRConfiguration) => {
  const tenant = 'xjk';
  const api = useApi<CategoriesApi>('CategoriesApi', tenant);
  return useSWR<GetCategoryResponse>(
    categoryKey(tenant, categoryId),
    () => getCategory(categoryId, api),
    { suspense: true, ...config },
  );
};

export const useCategoryActions = (tenantId: string) => {
  const api = useApi<CategoriesApi>('CategoriesApi', tenantId);
  return useMemo(
    () => ({
      createCategory: async (name: string): Promise<string> => {
        const category: Category = { name };
        const createCategoryResponse = await api.createCategoryRaw({
          category,
        });
        mutate(allCategoriesKey(tenantId));
        return getResourceIdFromLocationHeader(createCategoryResponse);
      },
      updateCategory: async (categoryId: string, name: string) => {
        const category: Category = { name };

        await api.updateCategory({ categoryId, category });
        mutate(allCategoriesKey(tenantId));
      },
      deleteCategory: async (categoryId: string) => {
        await api.deleteCategory({ categoryId });
        // TODO: mutate
      },
    }),
    [api, tenantId],
  );
};
