import { useTranslation } from 'react-i18next';
import {
  KeyLabelItem,
  ReactSelectOption,
  UserIcon,
  UsersIcon,
} from '@fcg-tech/regtech-components';
import Select, { components, OptionProps, StylesConfig } from 'react-select';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import {
  FilterClearButton,
  FilterRowLabel,
  FilterStoredFilterSelectIconWrapper,
  FilterStoredFilterSelectOptionWrapper,
} from './Filter.styles';
import { FilterValues, StoredFilter, StoredFilterType } from './types';
import { FilterMessageKeys } from './lang/filterMessageKeys';

export const multiSelectStyles: StylesConfig<
  ReactSelectOption<StoredFilter<never>>,
  boolean
> = {
  container: (p) => ({
    ...p,
    fontSize: '1.4rem',
    minWidth: '28rem',
  }),
  menu: (p) => ({
    ...p,
    zIndex: 10,
  }),
};

interface FilterStoredFilterSelectProps<T extends FilterValues> {
  clearLabel?: string;
  storedFilters: Array<StoredFilter<T>>;
  storedFilterId?: string;
  onChange: (filterId: string | null) => void;
}

export const FilterStoredFilterSelect = <T extends FilterValues>({
  clearLabel,
  storedFilters,
  storedFilterId,
  onChange,
}: FilterStoredFilterSelectProps<T>) => {
  const { t } = useTranslation();
  const options = useMemo<Array<ReactSelectOption<StoredFilter<T>>>>(
    () =>
      storedFilters.map<ReactSelectOption<StoredFilter<T>>>((filter) => ({
        value: filter,
        label: filter.name ?? 'N/A',
      })),

    [storedFilters],
  );

  const selectedFilter = useMemo(
    () => options.find(({ value }) => value.id === storedFilterId),
    [options, storedFilterId],
  );

  const handleChange = useCallback(
    (option: ReactSelectOption<StoredFilter<T>> | null) => {
      if (option?.value?.id) {
        onChange?.(option.value.id);
      }
    },
    [onChange],
  );

  const handleClear = useCallback(() => onChange?.(null), [onChange]);

  return <>
    <FilterRowLabel>
      {t(FilterMessageKeys.LoadFilter)}
      <FilterClearButton onClick={handleClear}>
        {clearLabel}
      </FilterClearButton>
    </FilterRowLabel>
    <Select
      value={selectedFilter ?? null}
      options={options}
      components={{
        Option: FilterStoredFilterSelectOption as FunctionComponent<
          FilterStoredFilterSelectOptionProps<T>
        >,
      }}
      isMulti={false}
      onChange={handleChange}
    />
  </>;
};

type FilterStoredFilterSelectOptionProps<T extends FilterValues> = OptionProps<
  ReactSelectOption<StoredFilter<T>>,
  false
>;

const FilterStoredFilterSelectOption = <T extends FilterValues>(
  props: FilterStoredFilterSelectOptionProps<T>,
) => {
  const filter = props.data.value as StoredFilter<T>;
  return (
    <components.Option {...props}>
      <FilterStoredFilterSelectOptionWrapper>
        {filter.type ? (
          <FilterStoredFilterSelectIconWrapper>
            {filter.type === StoredFilterType.SingleUserFilter ? (
              <UserIcon size="16" />
            ) : null}
            {filter.type === StoredFilterType.MultiUserFilter ? (
              <UsersIcon size="16" />
            ) : null}
          </FilterStoredFilterSelectIconWrapper>
        ) : null}
        {props.label}
      </FilterStoredFilterSelectOptionWrapper>
    </components.Option>
  );
};
