import styled from 'styled-components';
import { TriangleExclamationIcon } from '@fcg-tech/regtech-components';

export const IEBlockerTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const IEBlockerTriangleExclamationIcon = styled(TriangleExclamationIcon)`
  margin-right: 1.4rem;
`;

export const IEBlockerMessageWrapper = styled.div`
  min-width: 45rem;
  & > p {
    margin: 0.5rem 0;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 400;
  }
`;
