import React, { useCallback, useMemo } from 'react';
import {
  TableRow,
  TableCell,
  EditIcon,
  TrashIcon,
} from '@fcg-tech/regtech-components';
import { useRecoilValue } from 'recoil';
import { Direction, Subcontractor, WithChildren } from '../../types';
import { countryState } from '../../states/countryState';
import {
  TableCellEllipsis,
  TableCellIndent,
  StyledIconButton,
  ChevronIcon,
} from './SubcontractorsFieldTableRow.styled';

interface SubcontractorsFieldTableRowProps {
  subcontractor: WithChildren<Subcontractor>;
  index: number;
  depth: number;
  numSiblings: number;
  isEditEnabled: boolean;
  onEditRow: (subcontractorId: string) => void;
  onDeleteRow: (subcontractorId: string) => void;
  onMoveRow: (subcontractorId: string, direction: Direction) => void;
  formatSupplierName: (
    isExternal: boolean,
    supplierId: string | null,
  ) => string;
  formatCategoryName: (categoryOfActivity: string | null) => string;
}

export const SubcontractorsFieldTableRow = ({
  subcontractor,
  index,
  depth,
  numSiblings,
  isEditEnabled,
  onEditRow,
  onDeleteRow,
  onMoveRow,
  formatSupplierName,
  formatCategoryName,
}: SubcontractorsFieldTableRowProps) => {
  const countries = useRecoilValue(countryState);

  const renderChildren = useCallback(
    () =>
      subcontractor.children.map(
        (child: WithChildren<Subcontractor>, childIndex: number) => (
          <SubcontractorsFieldTableRow
            key={child.id}
            subcontractor={child}
            index={childIndex}
            depth={depth + 1}
            numSiblings={subcontractor.children.length - 1}
            isEditEnabled={isEditEnabled}
            onEditRow={onEditRow}
            onDeleteRow={onDeleteRow}
            onMoveRow={onMoveRow}
            formatSupplierName={formatSupplierName}
            formatCategoryName={formatCategoryName}
          />
        ),
      ) ?? null,
    [
      subcontractor,
      depth,
      isEditEnabled,
      onEditRow,
      onDeleteRow,
      onMoveRow,
      formatSupplierName,
      formatCategoryName,
    ],
  );

  const supplier = useMemo(() => {
    const { isExternal, externalSupplier, internalSupplier } =
      subcontractor?.supplier ?? {};
    return {
      isExternal,
      supplierId: isExternal ? externalSupplier : internalSupplier,
    };
  }, [subcontractor]);

  const countriesWhereServiceIsPerformed = useMemo(
    () =>
      subcontractor.countriesWhereServiceIsPerformed
        .map(
          (countryCode: string) =>
            countries.find((country) => country.isoAlpha2 === countryCode)
              ?.name ?? undefined,
        )
        .filter(Boolean)
        .join(', '),
    [countries, subcontractor.countriesWhereServiceIsPerformed],
  );

  const handleEdit = useCallback(
    () => onEditRow(subcontractor.id),
    [onEditRow, subcontractor.id],
  );

  const handleDelete = useCallback(
    () => onDeleteRow(subcontractor.id),
    [onDeleteRow, subcontractor.id],
  );

  const handleMoveLeft = useCallback(
    () => onMoveRow(subcontractor.id, Direction.Left),
    [onMoveRow, subcontractor.id],
  );

  const handleMoveDown = useCallback(
    () => onMoveRow(subcontractor.id, Direction.Down),
    [onMoveRow, subcontractor.id],
  );

  const handleMoveUp = useCallback(
    () => onMoveRow(subcontractor.id, Direction.Up),
    [onMoveRow, subcontractor.id],
  );

  const handleMoveRight = useCallback(
    () => onMoveRow(subcontractor.id, Direction.Right),
    [onMoveRow, subcontractor.id],
  );

  return (
    <>
      <TableRow>
        <TableCell>
          <TableCellIndent depth={depth}>
            <TableCellEllipsis>
              {formatSupplierName(supplier.isExternal, supplier.supplierId)}
            </TableCellEllipsis>
          </TableCellIndent>
        </TableCell>
        <TableCell>
          {formatCategoryName(subcontractor.categoryOfActivity)}
        </TableCell>
        <TableCell>{countriesWhereServiceIsPerformed}</TableCell>
        <TableCell>
          {isEditEnabled ? (
            <>
              <StyledIconButton onClick={handleEdit}>
                <EditIcon size="16" />
              </StyledIconButton>
              <StyledIconButton onClick={handleDelete}>
                <TrashIcon size="16" />
              </StyledIconButton>
              <StyledIconButton onClick={handleMoveLeft} disabled={depth === 0}>
                <ChevronIcon left />
              </StyledIconButton>
              <StyledIconButton
                onClick={handleMoveDown}
                disabled={index >= numSiblings || numSiblings === 0}
              >
                <ChevronIcon down />
              </StyledIconButton>
              <StyledIconButton onClick={handleMoveUp} disabled={index === 0}>
                <ChevronIcon up />
              </StyledIconButton>
              <StyledIconButton
                onClick={handleMoveRight}
                disabled={index === 0}
              >
                <ChevronIcon />
              </StyledIconButton>
            </>
          ) : null}
        </TableCell>
      </TableRow>
      {renderChildren()}
    </>
  );
};
