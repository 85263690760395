import { DataTableSortOrder } from '@fcg-tech/regtech-components';
import {
  GeneralAgreementsListTableOrderBy,
  GeneralAgreementsListTableColumnKeys,
} from '../types';

export const getGeneralAgreementsListSortOrder = (
  orderBy: GeneralAgreementsListTableOrderBy,
): DataTableSortOrder => {
  switch (orderBy) {
    case 'nameAsc':
      return {
        columnKey: GeneralAgreementsListTableColumnKeys.Name,
        ascending: true,
      };
    case 'nameDesc':
      return {
        columnKey: GeneralAgreementsListTableColumnKeys.Name,
        ascending: false,
      };
    case 'agreementTypeAsc':
      return {
        columnKey: GeneralAgreementsListTableColumnKeys.AgreementType,
        ascending: true,
      };
    case 'agreementTypeDesc':
      return {
        columnKey: GeneralAgreementsListTableColumnKeys.AgreementType,
        ascending: false,
      };
    case 'functionCategoryAsc':
      return {
        columnKey: GeneralAgreementsListTableColumnKeys.FunctionCategory,
        ascending: true,
      };
    case 'functionCategoryDesc':
      return {
        columnKey: GeneralAgreementsListTableColumnKeys.FunctionCategory,
        ascending: false,
      };
    case 'contractOwnerAsc':
      return {
        columnKey: GeneralAgreementsListTableColumnKeys.ContractOwner,
        ascending: true,
      };
    case 'contractOwnerDesc':
      return {
        columnKey: GeneralAgreementsListTableColumnKeys.ContractOwner,
        ascending: false,
      };
  }
};
