import { LegacyPalette, LegacyTheme } from '@fcg-tech/regtech-types';
import { css } from 'styled-components';
import { foundation } from './foundation';

const buttonStyle = css`
  border-radius: 0.3rem;
`;

export const palette: LegacyPalette = {
  MainColor: {
    light: '#1EC59B',
    lightContrast: 'rgba(10,10,10,1)',
    main: '#004C42',
    mainContrast: 'rgba(247, 247, 247, 1)',
    dark: '#00241f',
    darkContrast: 'rgba(247, 247, 247, 1)',
  },
  MainColorAccent: {
    light: '#ECDEC5',
    lightContrast: 'rgba(10,10,10,1)',
    main: '#E5D3B2',
    mainContrast: 'rgba(10,10,10,1)',
    dark: '#ccba99',
    darkContrast: 'rgba(10,10,10,1)',
  },
  MainBlack: '#000',
  MainWhite: '#FFF',
};

export const theme: LegacyTheme = {
  version: '1',
  breakpoints: {
    mobile: 730,
    narrowDesktop: 1024,
    mediumDesktop: 1240,
  },

  colors: {
    backdrop: '#F9F9F9',
    info: {
      main: palette.MainColor.main,
      contrast: palette.MainColor.mainContrast,
    },
    error: {
      main: '#DC3545',
      contrast: palette.MainWhite,
    },
    warning: {
      main: '#FFC109',
      contrast: palette.MainBlack,
    },
    success: {
      main: '#28A745',
      contrast: palette.MainWhite,
    },
    primary: {
      main: palette.MainColor.main,
      contrast: palette.MainColor.mainContrast,
    },
    secondary: {
      main: palette.MainColorAccent.light,
      contrast: palette.MainColorAccent.lightContrast,
    },
  },

  typography: {
    fontFamily: 'Roboto',
  },

  palette: palette,

  elements: {
    header: {
      wrapper: css`
        height: 7rem;
        border-bottom-width: 1px;
      `,
    },
    sideNavigation: {
      menu: {
        wrapper: css`
          color: ${palette.MainWhite};
        `,
      },
      item: {
        link: {
          nominal: css`
            color: ${palette.MainWhite};
          `,
          active: css`
            color: ${palette.MainWhite};
          `,
          focus: css`
            color: ${palette.MainWhite};
          `,
          hover: css`
            color: ${palette.MainWhite};
          `,
          visited: css`
            color: ${palette.MainWhite};
          `,
        },
      },
      sectionHeading: css`
        color: ${palette.MainWhite};
      `,
      expandButton: {
        icon: css`
          color: ${palette.MainWhite};
        `,
      },
    },
    sideBar: {
      logoWrapper: css`
        background: ${palette.MainColor.main};
        height: 7rem;
      `,
      wrapper: css`
        background: ${palette.MainColor.main};
        border-right: 1px solid rgba(0, 0, 0, 0.3);
      `,
    },
    button: {
      primary: buttonStyle,
      secondary: buttonStyle,
    },
    card: {
      wrapper: css`
        background-color: ${palette.MainWhite};
      `,
    },
    chart: {
      palette: {
        alpha: [
          foundation.palette.topaz125,
          foundation.palette.topaz175,
          foundation.palette.topaz250,
          foundation.palette.topaz400,
          foundation.palette.topaz500,
        ],
        beta: [
          foundation.palette.topaz400,
          foundation.palette.gold500,
          foundation.palette.emerald400,
          foundation.palette.gold600,
          foundation.palette.onyx500,
        ],
      },
    },
  },
};
