import { useMemo } from 'react';
import useSWR, { mutate, SWRConfiguration } from 'swr';
import { getResourceIdFromLocationHeader, useApi } from '../apiUtils';
import {
  AgreementType,
  AgreementTypeListItem,
  AgreementTypesApi,
  GetAgreementTypeResponse,
} from '../schema';
import { agreementTypeKey, allAgreementTypesKey } from './cacheKeys';

export const getAllAgreementTypes = async (
  api: AgreementTypesApi,
): Promise<Array<AgreementTypeListItem>> => {
  const result = await api.listAllAgreementTypes();
  return result.result ?? [];
};

export const getAgreementType = async (
  agreementTypeId: string,
  api: AgreementTypesApi,
): Promise<GetAgreementTypeResponse> => {
  const result = await api.getAgreementType({ agreementTypeId });
  return result;
};

export const useAgreementTypes = (
  tenantId: string,
  config?: SWRConfiguration,
) => {
  const api = useApi<AgreementTypesApi>('AgreementTypesApi', tenantId);
  return useSWR<Array<AgreementTypeListItem>>(
    allAgreementTypesKey(tenantId),
    () => getAllAgreementTypes(api),
    {
      suspense: true,
      ...config,
    },
  );
};

export const useAgreementType = (
  agreementTypeId: string,
  config?: SWRConfiguration,
) => {
  const tenant = 'xjk';
  const api = useApi<AgreementTypesApi>('AgreementTypesApi', tenant);
  return useSWR<GetAgreementTypeResponse>(
    agreementTypeKey(tenant, agreementTypeId),
    () => getAgreementType(agreementTypeId, api),
    { suspense: true, ...config },
  );
};

export const useAgreementTypeActions = (tenantId: string) => {
  const api = useApi<AgreementTypesApi>('AgreementTypesApi', tenantId);
  return useMemo(
    () => ({
      createAgreementType: async (name: string): Promise<string> => {
        const agreementType: AgreementType = { name };
        const createAgreementTypeResponse = await api.createAgreementTypeRaw({
          agreementType,
        });
        mutate(allAgreementTypesKey(tenantId));
        return getResourceIdFromLocationHeader(createAgreementTypeResponse);
      },
      updateAgreementType: async (agreementTypeId: string, name: string) => {
        const agreementType: AgreementType = { name };
        await api.updateAgreementType({ agreementTypeId, agreementType });
        mutate(allAgreementTypesKey(tenantId));
      },
      deleteAgreementType: async (agreementTypeId: string) => {
        await api.deleteAgreementType({ agreementTypeId });
        mutate(allAgreementTypesKey(tenantId));
      },
    }),
    [api, tenantId],
  );
};
