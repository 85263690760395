/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Card,
  CardBody,
  CardTitle,
  Field,
  InfoCircle,
  Label,
} from '@fcg-tech/regtech-components';
import {
  BooleanDecision,
  DateField,
  TextArea,
  TextField,
  Select,
} from '@fcg-tech/regtech-formik-components';

import {
  agreementPropType,
  internalEntityPropType,
  attachmentPropType,
  metadataPropType,
  supplierPropType,
} from '../../../propTypes';
import { NONE_SELECT_ITEM } from '../../../constants';
import { getTooltip } from '../../../utils/tooltipHelpers';
import { FormRow, FormColumn, FormGroup } from '../../FormLayout';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../../translations/messageKeys';
import { Attachments } from '../../Attachments';
import { FieldErrorNonBlocking } from '../../FieldError/FieldErrorNonBlocking';
import { FieldCompare } from '../../FieldCompare/FieldCompare';
import { RISK_ASSESSMENT_ITEM } from '../../../constants';

const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ReadOnlyValue = styled.div`
  display: flex;
  flex: 1;
  font-size: 1.4rem;
  padding: 1rem 1rem 1rem 0;
  margin-left: 1.2rem;
`;

const ReadOnlyInfoCircle = styled(InfoCircle)`
  margin-left: 4rem;
`;

export const RiskAssessmentSection = ({
  agreement,
  metadata,
  categories = [],
  attachments = [],
  internalEntities = [],
  suppliers = [],
}) => {
  const { t } = useTranslation();
  const internalOrExternalSupplierWithName = React.useMemo(() => {
    const { mainSupplier } = agreement.details || {};
    if (mainSupplier?.isExternal) {
      const { externalSupplier } = mainSupplier;
      const supplier = suppliers.find(
        (item) => item.metadata.id === externalSupplier,
      );
      return supplier ? `No: ${supplier?.data?.supplierName}` : 'No';
    }
    const { internalSupplier } = mainSupplier || {};
    const institution = internalEntities.find(
      (item) => item.metadata.id === internalSupplier,
    );
    return institution ? `Yes: ${institution?.name}` : 'Yes';
  }, [agreement, suppliers, internalEntities]);

  const categoryItems = React.useMemo(
    () =>
      [NONE_SELECT_ITEM].concat(
        categories.map((category) => ({
          key: category.metadata.id,
          label: category.name,
        })),
      ),
    [categories],
  );

  return (
    <Card>
      <CardBody>
        <CardTitle>{t(MessageKeys.RiskAssessmentSectionLabel)}</CardTitle>
        <FormRow>
          <FormColumn>
            <FormGroup>
              <Label
                column
                htmlFor="agreementAssessment.data.criticalOrImportant.riskAssessment"
              >
                {t(MessageKeys.AgreementAssessmentSectionRiskAssessmentLabel)}
              </Label>
              <Field
                component={Select}
                items={[NONE_SELECT_ITEM, ...RISK_ASSESSMENT_ITEM]}
                typeahead
                name="agreementAssessment.data.criticalOrImportant.riskAssessment"
                id="risk-assessment-level"
                info={getTooltip(
                  'agreementAssessment.data.criticalOrImportant.riskAssessment',
                )}
              />
              <FieldErrorNonBlocking name="agreementAssessment.data.criticalOrImportant.riskAssessment" />
              <FieldCompare name="agreementAssessment.data.criticalOrImportant.riskAssessment" />
            </FormGroup>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormGroup>
              <Label column htmlFor="latest-risk-assessment">
                {t(
                  MessageKeys.AgreementAssessmentSectionLatestRiskAssessmentLabel,
                )}
              </Label>
              <Field
                component={DateField}
                name="agreementAssessment.data.criticalOrImportant.latestRiskAssessment"
                id="latest-risk-assessment"
                info={getTooltip(
                  'agreementAssessment.data.criticalOrImportant.latestRiskAssessment',
                )}
              />
              <FieldErrorNonBlocking name="agreementAssessment.data.criticalOrImportant.latestRiskAssessment" />
              <FieldCompare name="agreementAssessment.data.criticalOrImportant.latestRiskAssessment" />
            </FormGroup>
          </FormColumn>
          <FormColumn>
            <FormGroup>
              <Label column htmlFor="next-risk-assessment">
                {t(
                  MessageKeys.AgreementAssessmentSectionNextRiskAssessmentLabel,
                )}
              </Label>
              <Field
                component={DateField}
                name="agreementAssessment.data.criticalOrImportant.nextRiskAssessment"
                id="next-risk-assessment"
                info={getTooltip(
                  'agreementAssessment.data.criticalOrImportant.nextRiskAssessment',
                )}
              />
              <FieldErrorNonBlocking name="agreementAssessment.data.criticalOrImportant.nextRiskAssessment" />
              <FieldCompare name="agreementAssessment.data.criticalOrImportant.nextRiskAssessment" />
            </FormGroup>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormGroup>
              <Label column htmlFor="risk-assessment-summary">
                {t(
                  MessageKeys.AgreementAssessmentSectionRiskAssessmentSummaryLabel,
                )}
              </Label>
              <Field
                component={TextArea}
                name="agreementAssessment.data.criticalOrImportant.latestRiskAssessmentSummary"
                id="risk-assessment-summary"
                info={getTooltip(
                  'agreementAssessment.data.criticalOrImportant.latestRiskAssessmentSummary',
                )}
              />
              <FieldCompare name="agreementAssessment.data.criticalOrImportant.latestRiskAssessmentSummary" />
            </FormGroup>
          </FormColumn>
        </FormRow>
        {metadata && (
          <Attachments
            attachments={attachments}
            section="criticalOrImportantRiskAssesment"
          />
        )}
      </CardBody>
    </Card>
  );
};

RiskAssessmentSection.propTypes = {
  agreement: agreementPropType,
  internalEntities: PropTypes.arrayOf(internalEntityPropType),
  suppliers: PropTypes.arrayOf(supplierPropType),
  metadata: metadataPropType,
  attachments: PropTypes.arrayOf(attachmentPropType),
};

RiskAssessmentSection.defaultProps = {
  agreement: {},
  metadata: {},
  attachments: [],
  internalEntities: [],
  suppliers: [],
};
