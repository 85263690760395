/* eslint-disable @typescript-eslint/no-explicit-any */
export enum DiffType {
  Created = 'created',
  Updated = 'updated',
  Deleted = 'deleted',
  Unchanged = 'unchanged',
}

const isFunction = (x: any) =>
  Object.prototype.toString.call(x) === '[object Function]';
const isArray = (x: any) =>
  Object.prototype.toString.call(x) === '[object Array]';
const isDate = (x: any) =>
  Object.prototype.toString.call(x) === '[object Date]';
const isObject = (x: any) =>
  Object.prototype.toString.call(x) === '[object Object]';
const isValue = (x: any) => !isObject(x) && !isArray(x);

const compareValues = (value1: any, value2: any) => {
  if (value1 === value2) {
    return DiffType.Unchanged;
  }
  if (
    isDate(value1) &&
    isDate(value2) &&
    value1.getTime() === value2.getTime()
  ) {
    return DiffType.Unchanged;
  }
  if (value1 === undefined) {
    return DiffType.Created;
  }
  if (value2 === undefined) {
    return DiffType.Deleted;
  }
  return DiffType.Updated;
};

export const jsonDiff = (oldObj: any, newObj: any) => {
  if (isFunction(oldObj) || isFunction(newObj)) {
    throw console.log('Invalid argument. Function given, object expected.');
  }
  if (isValue(oldObj) || isValue(newObj)) {
    return {
      type: compareValues(oldObj, newObj),
      oldData: oldObj,
      newData: newObj,
    };
  }

  const diff: { [index: string]: any } = {};

  for (const key in oldObj) {
    if (isFunction(oldObj[key])) {
      continue;
    }
    let value2 = undefined;
    if (newObj[key] !== undefined) {
      value2 = newObj[key];
    }
    diff[key] = jsonDiff(oldObj[key], value2);
  }

  for (const key in newObj) {
    if (isFunction(newObj[key]) || diff[key] !== undefined) {
      continue;
    }
    diff[key] = jsonDiff(undefined, newObj[key]);
  }

  return diff;
};
