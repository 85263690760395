import styled, { css } from 'styled-components';
import { excludeProps } from '../../utils';
import { UnstyledButton } from '../Button/Button.styles';

export const MenuWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme?.palette?.MainWhite ?? '#fff'};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 0 6px 1px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    max-height: 20rem;
    min-width: 15rem;
    overflow-y: auto;

    &.narrow {
      min-width: initial;
    }
  `}
`;

export const MenuItemList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: block;
  font-size: 1.4rem;

  .narrow > & {
    font-size: 1.2rem;
  }
`;

export const MenuItem = styled.li
  .attrs({ role: 'button' })
  .withConfig(excludeProps('isCurrent', 'isHighlighted'))<{
  isCurrent?: boolean;
  isHighlighted?: boolean;
}>`
  ${({ theme, isCurrent, isHighlighted }) => css`
    position: relative;
    padding: 1.5rem;
    display: block;
    outline: 0;
    cursor: pointer;
    min-width: 10rem;
    color: ${theme?.palette?.MainBlack ?? '#000'};
    background-color: ${isHighlighted
      ? '#ffee96'
      : isCurrent
      ? '#eee'
      : 'transparent'};
    word-wrap: nowrap;

    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
    }

    &.highlight {
      background-color: #ffee96;
    }

    & + & {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .narrow > ${MenuItemList} & {
      padding: 0.6rem 1.2rem;
      min-width: initial;
    }

    .align-right > ${MenuItemList} & {
      text-align: right;
    }
  `}
`;

export const MenuButton = styled(UnstyledButton).attrs({ role: 'listbutton' })`
  padding: 1.5rem;
  width: 100%;
  min-width: 10rem;
  text-align: left;
  justify-content: flex-start;
  background-color: #f8f8f8;
  transition: all ease-out 300ms;
  color: #222;
  display: block;

  &:hover {
    position: relative;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
    background-color: #fff;
    z-index: 1;
  }

  & + & {
    border-top: 1px solid #eee;
  }
`;
