import React from 'react';
import styled, { css } from 'styled-components';
import { ChevronRightIcon } from '../Icons';
import { IconProps } from '../../types/icons';
import { excludeProps } from '../../utils';

interface StyledChevronRightIconProps extends IconProps {
  ascending?: boolean;
  isActive?: boolean;
}

export const StyledChevronRightIcon = styled(
  ({ ascending, isActive, ...props }: StyledChevronRightIconProps) => (
    <ChevronRightIcon {...props} />
  ),
)`
  transform: rotate(
    ${({ ascending, isActive }) => (ascending && isActive ? '270deg' : '90deg')}
  );
  transition: transform 0.1s linear;
  width: 2rem;
  height: 2rem;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.2)};
`;

export const Content = styled.div.withConfig(excludeProps('inHead'))<{
  inHead?: boolean;
}>`
  ${({ inHead }) => css`
    display: flex;
    align-items: center;
    justify-content: ${inHead ? 'space-between' : 'flex-start'};
  `}
`;

export const Cell = styled.td.withConfig(excludeProps('visible'))<{
  visible?: boolean;
}>`
  ${({ visible }) => css`
    display: ${visible ? 'table-cell' : 'none'};
    padding: 0.3rem 0.6rem;
    white-space: nowrap;
  `}
`;
