import { useTranslation } from 'react-i18next';
import {
  FormRow,
  Modal,
  ModalBody,
  ModalButtonRow,
  ModalHeader,
  ModalTitle,
  PrimaryButton,
  ReactSelectOption,
  SecondaryButton,
  useFlash,
} from '@fcg-tech/regtech-components';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useCabinets } from '../../api/hooks/cabinetsApiHooks';
import { Cabinet } from '../../api/schema';
import { ModalFormLabel } from '../../components/modals';
import { useTenant } from '../../states/tenantState';
import { FormSelect } from '../../components/FormSelect';
import { MessageKeys } from '../../translations/messageKeys';
import { useUpdateAgreementCabinet } from '../../api/hooks/agreementsApiHooks';
import {
  CabinetMoveModalFormWrapper,
  CautionZone,
} from './CabinetMoveModal.styles';

interface CabinetMoveModalProps {
  fromCabinet: string;
  agreementId: string;
  onRequestClose: () => void;
}

export const CabinetMoveModal: FunctionComponent<CabinetMoveModalProps> = ({
  fromCabinet,
  agreementId,
  onRequestClose,
}) => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const addFlash = useFlash();

  const [cabinet, setCabinet] = useState<ReactSelectOption<Cabinet>>();

  const { data: cabinets, isValidating: isValidatingCabinets } = useCabinets(
    tenant.id,
    { suspense: false },
  );

  const cabinetOptions = useMemo<Array<ReactSelectOption<Cabinet>>>(
    () =>
      cabinets?.map<ReactSelectOption<Cabinet>>((cabinet) => ({
        label: cabinet.name,
        value: cabinet,
      })),
    [cabinets],
  );

  const { updateAgreementCabinet } = useUpdateAgreementCabinet();

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      try {
        await updateAgreementCabinet(agreementId, cabinet.value.name);
        addFlash({
          level: 'success',
          content: t(MessageKeys.EditAgreementCabinetMoveSuccessLabel),
        });
        onRequestClose();
      } catch (err) {
        addFlash({
          level: 'error',
          content: t(MessageKeys.EditAgreementCabinetMoveFailLabel),
        });
      }
    },
    [addFlash, t, updateAgreementCabinet, onRequestClose, cabinet, agreementId],
  );

  const handleCabinetChange = useCallback(
    (option: ReactSelectOption<Cabinet>) => {
      setCabinet(option);
    },
    [],
  );

  const handleHide = useCallback(() => onRequestClose(), [onRequestClose]);

  return (
    <Modal onHide={handleHide}>
      <ModalHeader>
        <ModalTitle>
          {t(MessageKeys.CabinetMoveModalMoveAgreementLabel)}
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <CabinetMoveModalFormWrapper onSubmit={handleSubmit}>
          <FormRow>
            <ModalFormLabel htmlFor="cabinet">
              {t(MessageKeys.LabelCabinet)}
            </ModalFormLabel>
            <FormSelect
              id="cabinet"
              name="cabinet"
              options={cabinetOptions?.filter((e) => e.label != fromCabinet)}
              value={cabinet}
              isLoading={!cabinets && isValidatingCabinets}
              onChange={handleCabinetChange}
            />
          </FormRow>
          <CautionZone>
            <FormRow>{t(MessageKeys.CabinetMoveCaution)}</FormRow>
            <FormRow>{t(MessageKeys.CabinetMoveCautionText)}</FormRow>
          </CautionZone>
          <ModalButtonRow>
            <SecondaryButton type="button" onClick={handleHide}>
              {t(MessageKeys.LabelCancel)}
            </SecondaryButton>
            <PrimaryButton type="submit" disabled={!cabinet}>
              {t(MessageKeys.LabelMove)}
            </PrimaryButton>
          </ModalButtonRow>
        </CabinetMoveModalFormWrapper>
      </ModalBody>
    </Modal>
  );
};
