import React from 'react';
import PropTypes from 'prop-types';
import AceEditor from 'react-ace';
import styled from 'styled-components';
import 'ace-builds/src-noconflict/ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';

import { formikFormShape, formikFieldShape } from '../../propTypes';

const AceEditorWrapper = styled.div`
  & .ace_gutter {
    z-index: 0;
  }
`;

export const JsonEditorField = ({ form, field, isEditEnabled, ...props }) => {
  const handleChange = React.useCallback(
    (value) => {
      try {
        JSON.parse(value);
        form.setFieldValue(field.name, value);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    },
    [form, field.name],
  );

  return (
    <AceEditorWrapper>
      <AceEditor
        mode="json"
        theme="github"
        editorProps={{ $blockScrolling: true }}
        width="100%"
        setOptions={{
          tabSize: 2,
          useWorker: false,
        }}
        onChange={handleChange}
        value={field.value}
        // eslint-disable-next-line react/prop-types
        name={props.id}
        readOnly={!isEditEnabled}
        {...props}
      />
    </AceEditorWrapper>
  );
};

JsonEditorField.propTypes = {
  form: formikFormShape.isRequired,
  field: formikFieldShape.isRequired,
  isEditEnabled: PropTypes.bool,
};

JsonEditorField.defaultProps = {
  isEditEnabled: false,
};
