import styled from 'styled-components';

import { CheckIcon } from '@fcg-tech/regtech-components';

export const TagPickerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Tag = styled.div`
  display: flex;
  min-width: 0;
  flex: 0 0 25%;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 0;
`;

export const TagLabel = styled.span`
  font-size: 1.2rem;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TagCheckbox = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 0.4rem;
`;

export const TagCheckmark = styled(CheckIcon)`
  color: ${({ checked }) => (checked ? 'inherit' : 'transparent')};
  width: 1.6rem;
  height: 1.6rem;
  padding: 0.2rem;
`;

export const ReadOnlyTag = styled.div`
  background-color: rgba(59, 65, 81, 0.2);
  padding: 1rem 1.2rem;
  display: flex;
  align-items: center;
  border-radius: 2px;
  font-size: 1.4rem;
  font-weight: 500;
  margin-right: 1rem;
  margin-top: 1rem;
`;
