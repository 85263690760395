import { QuestionIcon } from '@fcg-tech/regtech-components';
import { classNames } from '@fcg-tech/regtech-utils';
import { useCallback, useMemo } from 'react';
import {
  FilterClearButton,
  FilterRowItem,
  FilterRowLabel,
  FilterRowLabelButton,
  FilterRowLabelLeft,
} from './Filter.styles';
import { FilterDateRange } from './FilterDateRange';
import { FilterRelativeDateIntervalSelect } from './FilterRelativeDateIntervalSelect';
import {
  DateRange,
  DateRangeInterval,
  FilterCommonProps,
  FilterValues,
  FilterWidgetProps,
  RelativeDateInterval,
} from './types';
import { getDateFilterForInterval } from './utils';

interface FilterDateRangeIntervalProps<T extends FilterValues>
  extends Omit<FilterCommonProps<T>, 'getNoResultsMessage' | 'loadingMessage'>,
    FilterWidgetProps<T> {
  intervals: Array<RelativeDateInterval>;
  allowedRange?: DateRange;
  onHelpClick?: (filterPropType: keyof T) => void;
}

export const FilterDateRangeInterval = <T extends FilterValues>({
  disabled,
  filter,
  filterId,
  filterPropKey,
  excludePropertyKey,
  className,
  allowExclude,
  label,
  clearLabel,
  allowedRange,
  excludeLabel,
  noItemsLabel,
  isExcluding,
  intervals,
  children,
  onClear,
  onChange,
  onExclude,
  onHelpClick,
}: React.PropsWithChildren<FilterDateRangeIntervalProps<T>>) => {
  const value = filter?.[filterPropKey] as DateRangeInterval;
  const handleHelpClick = useCallback(
    () => onHelpClick?.(filterPropKey),
    [filterPropKey, onHelpClick],
  );

  const handleClear = useCallback(
    () => onClear?.(filterPropKey),
    [filterPropKey, onClear],
  );

  const range = filter?.[filterPropKey] as DateRangeInterval;

  const handleIntervalChange = useCallback(
    (interval: RelativeDateInterval | null) => {
      const calculated = getDateFilterForInterval(interval);
      onChange?.(filterPropKey, calculated, filterId);
    },
    [filterPropKey, onChange, filterId],
  );

  const handleRangeChange = useCallback(
    (range: DateRange | null) => {
      onChange?.(filterPropKey, range, filterId);
    },
    [filterPropKey, onChange, filterId],
  );

  const calculatedRange = useMemo(
    () => getDateFilterForInterval(value?.interval as RelativeDateInterval),
    [value?.interval],
  );

  return (
    <>
      <FilterRowLabel as="div">
        <FilterRowLabelLeft>
          {label}
          {onHelpClick ? (
            <FilterRowLabelButton onClick={handleHelpClick}>
              <QuestionIcon size="14" />
            </FilterRowLabelButton>
          ) : null}
        </FilterRowLabelLeft>
        {onClear ? (
          <FilterClearButton
            className={classNames(disabled && 'disabled')}
            onClick={disabled ? undefined : handleClear}
          >
            {clearLabel}
          </FilterClearButton>
        ) : null}
      </FilterRowLabel>
      <FilterRowItem>
        <FilterRelativeDateIntervalSelect
          disabled={disabled}
          interval={range?.interval as RelativeDateInterval}
          intervals={intervals}
          onChange={handleIntervalChange}
        />
      </FilterRowItem>
      <FilterRowItem>
        <FilterDateRange
          from={range?.from ?? calculatedRange?.from}
          to={range?.to ?? calculatedRange?.to}
          minFrom={allowedRange?.from}
          maxTo={allowedRange?.to}
          disabled={Boolean(range?.interval) || disabled}
          onChange={handleRangeChange}
        />
      </FilterRowItem>
    </>
  );
};
