import { FilterBar, FilterBarWrapper } from '@fcg-tech/regtech-filter';
import styled, { css } from 'styled-components';

export const FilterBorderWrapper = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    overflow-y: auto;

    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 5;
      box-shadow: -3px 0 4px 0 rgba(0, 0, 0, 0.3);
      background-color: white;
    }
  `}
`;

export const StyledFilterBar = styled(FilterBar)`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`;

export const StyledFilterBarWrapper = styled(FilterBarWrapper)`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`;
