import styled, { css } from 'styled-components';
import { excludeProps } from '../../utils';
import { CrossIcon } from '../Icons';

export const PillWrapper = styled.div.withConfig(excludeProps('removable'))<{
  removable?: boolean;
}>`
  ${({ removable }) => css`
    background-color: rgba(59, 65, 81, 0.2);
    padding: 0.5rem ${removable ? '0.5rem' : '1rem'} 0.5rem 1rem;
    display: flex;
    align-items: center;
    border-radius: 2px;
    font-size: 1.4rem;
  `}
`;

export const PillIconButton = styled.button`
  border: 0;
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  margin: 0 0 0 1rem;
  padding: 0;
`;

export const PillIconButtonContainer = styled.div`
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledCrossIcon = styled(CrossIcon)`
  width: 1.4rem;
  height: 1.4rem;
`;
