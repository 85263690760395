import styled from 'styled-components';

export const SuspenseLoaderWrapper = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 8rem;

  &.maximized {
    height: 100vh;
    width: 100vw;
  }
`;
