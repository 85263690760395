import { OpenApiDate } from '@fcg-tech/regtech-types';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const flattenJSON = <T = any>(
  data: T,
  flattenArrays = true,
): Record<string | number | symbol, any> => {
  const result: Record<string | number | symbol, any> = {};
  function recurse(cur: any, prop: string) {
    if (
      Object(cur) !== cur ||
      cur instanceof Date ||
      (!flattenArrays && Array.isArray(cur))
    ) {
      result[prop] = cur;
    } else if (Array.isArray(cur)) {
      let l;
      for (let i = 0, l = cur.length; i < l; i++)
        recurse(cur[i], prop + '[' + i + ']');
      if (l == 0) result[prop] = [];
    } else if (
      typeof cur === 'object' &&
      (cur as unknown as OpenApiDate).isApiDate
    ) {
      result[prop] = (cur as unknown as OpenApiDate).toISOString();
    } else {
      let isEmpty = true;
      for (const p in cur) {
        isEmpty = false;
        recurse(cur[p] as any, prop ? prop + '.' + p : p);
      }
      if (isEmpty && prop) result[prop] = {};
    }
  }
  recurse(data, '');
  return result;
};

export const unflattenJSON = (
  data: Record<string | number | symbol, unknown>,
) => {
  if (Object(data) !== data || Array.isArray(data)) return data;
  const regex = /\.?([^.[\]]+)|\[(\d+)\]/g;
  const resultholder: Record<string, unknown> = {};
  for (const p in data) {
    let cur: any = resultholder;
    let prop = '';
    let m: RegExpExecArray | null;
    while ((m = regex.exec(p))) {
      cur = cur[prop] || (cur[prop] = m[2] ? [] : {});
      prop = m[2] || m[1];
    }
    cur[prop] = data[p];
  }
  return resultholder[''] || resultholder;
};
