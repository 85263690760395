import { useTranslation } from 'react-i18next';
import { createContext, useContext } from 'react';
import { components, MenuProps } from 'react-select';
import { FilterSelectMenuLoading } from './Filter.styles';
import { FilterMessageKeys } from './lang/filterMessageKeys';
import { FilterSelectOption } from './types';

export const FilterSelectMenuContext = createContext({ isLoadingMore: true });

export const FilterSelectMenu = <M extends boolean>(
  props: MenuProps<FilterSelectOption<{ id: string | number }>, M>,
) => {
  const { t } = useTranslation();
  const { isLoadingMore } = useContext(FilterSelectMenuContext);
  return (
    <components.Menu {...props}>
      {isLoadingMore ? (
        <FilterSelectMenuLoading>
          {t(FilterMessageKeys.FilterSelctMenuLoadingAdditional)}
        </FilterSelectMenuLoading>
      ) : null}
      {props.children}
    </components.Menu>
  );
};
