import React, { FunctionComponent, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { TableRow } from './TableRow';

const StyledTableBody = styled.tbody`
  ${({ theme }) => css`
    width: 100%;
    font-size: 1.4rem;
    border-bottom: 1px solid ${theme.colors?.border ?? 'rgba(0,0,0,0.3)'};
    & > ${TableRow} {
      &:last-child {
        border: 0;
      }
    }
  `}
`;

export const TableBody: FunctionComponent<PropsWithChildren<React.HTMLAttributes<
  HTMLTableSectionElement
>>> = ({ children, ...props }) => (
  <StyledTableBody {...props}>{children}</StyledTableBody>
);
