import React, { FunctionComponent, useCallback, PropsWithChildren } from 'react';
import { ExpansionPanelSummary } from './ExpansionPanelSummary';
import {
  ExpansionPanelWrapper,
  ExpansionPanelDetailsWrapper,
  ExpansionPanelDetails,
} from './ExpansionPanel.styles';

export interface ExpansionPanelProps {
  id?: string;
  title?: string;
  subtitle?: string;
  expanded?: boolean;
  onToggle?: (event: React.MouseEvent<unknown>, id?: string) => void;
  disabled?: boolean;
}

export const ExpansionPanel: FunctionComponent<PropsWithChildren<ExpansionPanelProps>> = ({
  id,
  title,
  subtitle,
  expanded = false,
  onToggle,
  disabled = false,
  children,
}) => {
  const handleToggle = useCallback(
    (event: React.MouseEvent<unknown>) => {
      if (onToggle && !disabled) {
        onToggle(event, id);
      }
    },
    [onToggle, disabled, id],
  );

  return (
    <ExpansionPanelWrapper expanded={expanded}>
      <ExpansionPanelSummary
        title={title}
        subtitle={subtitle}
        disabled={disabled}
        expanded={expanded}
        onToggle={handleToggle}
      />
      <ExpansionPanelDetailsWrapper expanded={expanded}>
        <ExpansionPanelDetails>{children}</ExpansionPanelDetails>
      </ExpansionPanelDetailsWrapper>
    </ExpansionPanelWrapper>
  );
};
