import { Label } from '@fcg-tech/regtech-components';
import styled from 'styled-components';

export const ModalFormWrapper = styled.form`
  padding: 0.3rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 30vw;
  min-width: 50rem;
  flex: 1;
  overflow-y: auto;
`;

export const ModalFormLabel = styled(Label)`
  padding-bottom: 0.6rem;
  position: relative;
  flex: 0;
`;

export const ModalFormGroup = styled.div`
  & + & {
    padding-top: 3.2rem;
  }
`;
