import { FunctionComponent, useCallback } from 'react';
import { useTheme } from 'styled-components';
import { ButtonProps, UnstyledButton } from '../Button';
import {
  SideBarHamburgerButtonIcon,
  SideBarHamburgerButtonWrapper,
} from './SideBar.styles';

export interface SideBarHamburgerButtonProps extends ButtonProps {
  toggle?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SideBarHamburgerButton: FunctionComponent<SideBarHamburgerButtonProps> =
  ({ toggle, onClick, ...props }) => {
    const theme = useTheme();
    const handleClick = useCallback(
      (event: React.MouseEvent) => {
        onClick?.(event);
        toggle?.((old) => !old);
      },
      [onClick, toggle],
    );
    return (
      <SideBarHamburgerButtonWrapper>
        <UnstyledButton {...props} onClick={handleClick}>
          <SideBarHamburgerButtonIcon
            color={theme?.palette?.MainColorAccent?.dark}
          />
        </UnstyledButton>
      </SideBarHamburgerButtonWrapper>
    );
  };
