import { FunctionComponent } from 'react';
import { PageScrollingWrapper } from '../../components/Page';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { SuspenseLoader } from '../../components/SuspenseLoader';
import { SuppliersOverviewTable } from './SuppliersOverviewTable';

export const SuppliersOverviewPage: FunctionComponent = () => {
  return (
    <PageScrollingWrapper>
      <ErrorBoundary>
        <SuspenseLoader>
          <SuppliersOverviewTable />
        </SuspenseLoader>
      </ErrorBoundary>
    </PageScrollingWrapper>
  );
};
