import { getAccessToken } from '@fcg-tech/regtech-auth';
import { ILogger, setupLogger } from '@fcg-tech/regtech-logger';
import { getResponse } from '../api/apiUtils';
import { environment } from '../environments/environment';

export const getLogger = (): ILogger | Console => {
  if (getAccessToken() && environment.production) {
    return setupLogger({
      url: `${environment.apiBaseUrl}/report`,
      accessToken: getAccessToken(),
      interval: 5000,
      stacktrace: {
        depth: 100,
      },
    });
  }
  return console;
};

export const getResponsePayload = (err: unknown) => {
  const response = getResponse(err);
  return {
    requestUrl: response?.url,
    requestId: response?.headers?.get('apigw-requestid'),
    status: response?.status,
    path: window.location.pathname,
  };
};
