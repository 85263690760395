import React, { FunctionComponent } from 'react';
import { IconProps } from '../../types/icons';

export const ColumnsIcon: FunctionComponent<IconProps> = ({
  size = '24',
  color = 'currentColor',
  ...props
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    width={size}
    height={size}
    stroke={color}
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="chevron-right-icon_svg__feather chevron-right-icon_svg__feather-chevron-right"
    {...props}
  >
    <path d="M12 3h7a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-7m0-18H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h7m0-18v18"></path>
  </svg>
);
