import React, { useCallback, useState } from 'react';
import { PrimaryButton, useFlash } from '@fcg-tech/regtech-components';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import { NotificationSettings } from '../../api/schema';
import { usePersonalNotificationSettingsActions } from '../../api/hooks/personalSettingsApiHooks';
import { NotificationsSettingsDate } from './NotificationsSettingsDate';
import { useTenant } from '../../states/tenantState';
import {
  NotificationsSettingsTabWrapper,
  NotificationsSettingsTabHeader,
  NotificationsSettingsTabButtonGroup,
} from './NotificationsSettingsTab.styles';
export interface NotificationsSettingsTabProps {
  dateSettings: NotificationSettings;
}

export const NotificationsSettingsTab = ({
  dateSettings,
}: NotificationsSettingsTabProps) => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const tenantId = tenant.id;
  const [isSaving, setIsSaving] = useState(false);
  const [expandedIds, setExpandedIds] = useState<string[]>([]);

  const { updatePersonalNotificationSettings } =
    usePersonalNotificationSettingsActions(tenantId);

  const addFlash = useFlash();

  const [editState, setEditState] =
    useState<NotificationSettings>(dateSettings);

  const handleToggleExpanded = useCallback((_, panelId?: string) => {
    if (panelId) {
      setExpandedIds((state) =>
        state.includes(panelId)
          ? state.filter((id) => id !== panelId)
          : [...state, panelId],
      );
    }
  }, []);

  const handleSubmit = useCallback(async () => {
    setIsSaving(true);
    try {
      await updatePersonalNotificationSettings(
        editState as NotificationSettings,
      );
      addFlash({
        level: 'success',
        content: t(MessageKeys.NotificationsSettingsSavedSuccess),
      });
    } catch (e) {
      addFlash({
        level: 'error',
        content: t(MessageKeys.NotificationsSettingsSavedFailure),
      });
    } finally {
      setIsSaving(false);
    }
  }, [addFlash, t, editState, updatePersonalNotificationSettings]);

  const handleChangeEndDate = useCallback(
    (daysAhead) =>
      setEditState((old) => ({
        ...old,
        endDate: {
          daysAhead: daysAhead,
        },
      })),
    [],
  );

  const handleChangeRenewalDate = useCallback(
    (daysAhead) =>
      setEditState((old) => ({
        ...old,
        renewalDate: {
          daysAhead: daysAhead,
        },
      })),
    [],
  );

  const handleChangeNextReview = useCallback(
    (daysAhead) =>
      setEditState((old) => ({
        ...old,
        nextReview: {
          daysAhead: daysAhead,
        },
      })),
    [],
  );

  const handleChangeNextAudit = useCallback(
    (daysAhead) =>
      setEditState((old) => ({
        ...old,
        nextAudit: {
          daysAhead: daysAhead,
        },
      })),
    [],
  );

  const handleChangeNextRiskAssessment = useCallback(
    (daysAhead) =>
      setEditState((old) => ({
        ...old,
        nextRiskAssessment: {
          daysAhead: daysAhead,
        },
      })),
    [],
  );

  return <>
    <NotificationsSettingsTabWrapper>
      <NotificationsSettingsTabHeader>
        <NotificationsSettingsTabButtonGroup>
          <PrimaryButton
            type="submit"
            loading={isSaving}
            onClick={handleSubmit}
          >
            {t(MessageKeys.LabelSubmit)}
          </PrimaryButton>
        </NotificationsSettingsTabButtonGroup>
      </NotificationsSettingsTabHeader>
      <NotificationsSettingsDate
        key={'endDate'}
        name={'endDate'}
        title={t(
          MessageKeys.NotificationsSettingsEndDateTitleLabel,
        )}
        description={t(
          MessageKeys.NotificationsSettingsEndDateDescriptionLabel,
        )}
        daysAhead={editState?.endDate?.daysAhead ?? []}
        expanded={expandedIds.includes('endDate')}
        onToggle={handleToggleExpanded}
        onChange={handleChangeEndDate}
      />
      <NotificationsSettingsDate
        key={'renewalDate'}
        name={'renewalDate'}
        title={t(
          MessageKeys.NotificationsSettingsRenewalDateTitleLabel,
        )}
        description={t(
          MessageKeys.NotificationsSettingsRenewalDateDescriptionLabel,
        )}
        daysAhead={editState?.renewalDate?.daysAhead ?? []}
        expanded={expandedIds.includes('renewalDate')}
        onToggle={handleToggleExpanded}
        onChange={handleChangeRenewalDate}
      />
      <NotificationsSettingsDate
        key={'nextReview'}
        name={'nextReview'}
        title={t(
          MessageKeys.NotificationsSettingsNextReviewTitleLabel,
        )}
        description={t(
          MessageKeys.NotificationsSettingsNextReviewDescriptionLabel,
        )}
        daysAhead={editState?.nextReview?.daysAhead ?? []}
        expanded={expandedIds.includes('nextReview')}
        onToggle={handleToggleExpanded}
        onChange={handleChangeNextReview}
      />
      <NotificationsSettingsDate
        key={'nextAudit'}
        name={'nextAudit'}
        title={t(
          MessageKeys.NotificationsSettingsNextAuditTitleLabel,
        )}
        description={t(
          MessageKeys.NotificationsSettingsNextAuditDescriptionLabel,
        )}
        daysAhead={editState?.nextAudit?.daysAhead ?? []}
        expanded={expandedIds.includes('nextAudit')}
        onToggle={handleToggleExpanded}
        onChange={handleChangeNextAudit}
      />
      <NotificationsSettingsDate
        key={'nextRiskAssessment'}
        name={'nextRiskAssessment'}
        title={t(
          MessageKeys.NotificationsSettingsNextRiskAssessmentTitleLabel,
        )}
        description={t(
          MessageKeys.NotificationsSettingsNextRiskAssessmentDescriptionLabel,
        )}
        daysAhead={editState?.nextRiskAssessment?.daysAhead ?? []}
        expanded={expandedIds.includes('nextRiskAssessment')}
        onToggle={handleToggleExpanded}
        onChange={handleChangeNextRiskAssessment}
      />
    </NotificationsSettingsTabWrapper>
  </>;
};
