import { FunctionComponent } from 'react';

export enum RelativeDateInterval {
  Today = 'Today',
  LastThreeDays = 'LastThreeDays',
  LastWeek = 'LastWeek',
  LastTwoWeeks = 'LastTwoWeeks',
  ThisWeek = 'ThisWeek',
  ThisPartialWeek = 'ThisPartialWeek',
  LastMonth = 'LastMonth',
  ThisMonth = 'ThisMonth',
  ThisPartialMonth = 'ThisPartialMonth',
  LastQuarter = 'LastQuarter',
  ThisQuarter = 'ThisQuarter',
  ThisPartialQuarter = 'ThisPartialQuarter',
  LastYear = 'LastYear',
  ThisYear = 'ThisYear',
  ThisPartialYear = 'ThisPartialYear',
  NextWeek = 'NextWeek',
  NextMonth = 'NextMonth',
  Overdue = 'Overdue',
}

export interface DateRange {
  from?: Date;
  to?: Date;
}

export interface DateRangeInterval extends DateRange {
  interval?: RelativeDateInterval | string;
}

export type FilterValue =
  | string
  | number
  | boolean
  | Date
  | DateRangeInterval
  | RelativeDateInterval
  | Array<string>
  | Array<number>
  | Array<boolean>
  | Array<Date>
  | Array<DateRangeInterval>
  | Array<RelativeDateInterval>
  | null;

export type AllowedFilter<T> = {
  [K in keyof T]: T[K] extends FilterValue ? T[K] : never;
};

export type FilterValues = { [key: string]: FilterValue };

export enum StoredFilterType {
  SingleUserFilter = 1,
  MultiUserFilter = 2,
}

export type StoredFilter<T extends FilterValues> = {
  id: string;
  name?: string;
  filter: T;
  type?: StoredFilterType;
};

export type HandleClear<T extends FilterValues, K = keyof T> = (
  filterPropKey: K,
) => FilterValues;

export type HandleFilterChange = (
  value: FilterValues,
  filterId?: string,
) => FilterValues;

export type HandleFilterClear = () => FilterValues;

export type HandleFilterValueChange<T extends FilterValues, K = keyof T> = (
  filterPropKey: K,
  value: FilterValue,
  filterId?: string,
) => FilterValues;

export type HandleExclude<T extends FilterValues, K = keyof T> = (
  filterPropKey: K,
  exclude: boolean,
) => FilterValues;

export type FilterSelectOption<
  T extends { id: string | number } = { id: string | number },
> = {
  value: T;
  label: string;
};

export interface FilterCommonProps<T extends FilterValues> {
  filter?: T;
  filterId?: string;
  allowExclude?: boolean;
  clearLabel?: string;
  excludeLabel?: string;
  loadingMessage?: string;
  noItemsLabel?: string;
  isExcluding?: boolean;
  excludePropertyKey?: string;
  disabled?: boolean;
  getNoResultsMessage?: (
    searchString: string,
    limitExceeded: boolean,
    outOfOptions: boolean,
  ) => string;
  onClear?: HandleClear<T>;
  onChange?: HandleFilterValueChange<T>;
  onExclude?: HandleExclude<T>;
}

export interface FilterWidgetProps<T extends FilterValues> {
  filterPropKey: keyof T;
  label?: string;
  className?: string;
}

export type FilterWidget<P, T extends FilterValues> = FunctionComponent<
  P & FilterCommonProps<T>
> & {
  isFilterWidget: true;
};
