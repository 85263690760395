import {
  ConfirmDialog,
  DataTableCell,
  DataTableRow,
  DataTableRowOptions,
  FormRow,
  Modal,
  ModalBody,
  ModalButtonRow,
  ModalHeader,
  ModalTitle,
  PrimaryButton,
  SecondaryButton,
  TextField,
  useConfirmDialog,
  useInput,
  useToggle,
} from '@fcg-tech/regtech-components';
import { useIsMountedRef } from '@fcg-tech/regtech-utils';
import {
  Download as DownloadIcon,
  Edit2 as EditIcon,
  Trash2 as TrashIcon,
} from 'lucide-react';
import { useTranslation } from 'react-i18next';
import React, { FunctionComponent, useCallback, useRef, useState } from 'react';
import { formatZonedDateTime } from '../../utils/formatters';
import { MessageKeys } from '../../translations/messageKeys';
import { DataTableIconButton } from '../buttons';
import { ModalFormLabel, ModalFormWrapper } from '../modals';
import { AttachmentTableRowDownloadLink } from './AttachmentTable.styles';

export type RowData = { filename: string; uploaded: string };
export type ColumnKeys = keyof RowData | 'actions';

export interface AttachmentTableRowProps {
  row: DataTableRowOptions<RowData>;
  onDownloadClick?: (attachmentId: string) => Promise<string>;
  onRemoveClick?: (attachmentId: string) => Promise<boolean>;
  onRenameClick?: (
    attachmentId: string,
    newFilename: string,
  ) => Promise<boolean>;
}

export const AttachmentTableRow: FunctionComponent<AttachmentTableRowProps> = ({
  row,
  onDownloadClick,
  onRemoveClick,
  onRenameClick,
}) => {
  const { t } = useTranslation();
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [loadingRename, setLoadingRename] = useState(false);
  const [showRenameModal, , toggleShowRenameModal] = useToggle(false);

  const data = row.data;
  const downloadRef = useRef<HTMLAnchorElement>();
  const isMounted = useIsMountedRef();

  const [filename, onFilenameChange] = useInput<HTMLInputElement>(
    data.filename,
  );
  const [showRemoveConfirm, handleRemoveConfirm, confirmRemove] =
    useConfirmDialog();

  const handleDownloadClick = useCallback(async () => {
    setLoadingDownload(true);
    const presignedUrl = await onDownloadClick(row.id);
    downloadRef.current.download = data.filename;
    downloadRef.current.href = presignedUrl;
    downloadRef.current.click();
    setLoadingDownload(false);
  }, [data, onDownloadClick, row.id]);

  const handleRemoveClick = useCallback(async () => {
    if (await confirmRemove()) {
      setLoadingRemove(true);
      await onRemoveClick?.(row.id);
      if (isMounted.current) {
        setLoadingRemove(false);
      }
    }
  }, [confirmRemove, isMounted, onRemoveClick, row.id]);

  const handleRenameAttachment = useCallback(async () => {
    setLoadingRename(true);
    await onRenameClick(row.id, filename);
    setLoadingRename(false);
    toggleShowRenameModal();
  }, [filename, onRenameClick, row.id, toggleShowRenameModal]);

  return (
    <>
      {showRemoveConfirm ? (
        <ConfirmDialog
          onChoice={handleRemoveConfirm}
          title={t(MessageKeys.LabelConfirmAction)}
          body={t(MessageKeys.AttachmentTableConfirmDeleteQuestion)}
          confirmText={t(MessageKeys.LabelConfirmLabel)}
          cancelText={t(MessageKeys.LabelCancel)}
        />
      ) : null}
      {showRenameModal ? (
        <Modal>
          <ModalHeader>
            <ModalTitle>Rename</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <ModalFormWrapper onSubmit={null}>
              <FormRow>
                <ModalFormLabel htmlFor="filename">Filename</ModalFormLabel>
                <TextField
                  id="filename"
                  value={filename}
                  onChange={onFilenameChange}
                />
              </FormRow>
            </ModalFormWrapper>
            <ModalButtonRow>
              <SecondaryButton onClick={toggleShowRenameModal}>
                {t(MessageKeys.LabelCancel)}
              </SecondaryButton>
              <PrimaryButton
                onClick={handleRenameAttachment}
                loading={loadingRename}
                disabled={filename === data.filename}
              >
                {t(MessageKeys.LabelSubmit)}
              </PrimaryButton>
            </ModalButtonRow>
          </ModalBody>
        </Modal>
      ) : null}
      <DataTableRow row={row}>
        <DataTableCell>{data.filename}</DataTableCell>
        <DataTableCell>{formatZonedDateTime(data.uploaded)}</DataTableCell>
        <DataTableCell>
          <AttachmentTableRowDownloadLink ref={downloadRef} />
          <DataTableIconButton
            onClick={handleDownloadClick}
            loading={loadingDownload}
          >
            <DownloadIcon size="18" />
          </DataTableIconButton>
          {onRenameClick ? (
            <DataTableIconButton
              onClick={toggleShowRenameModal}
              loading={loadingRename}
            >
              <EditIcon size="18" />
            </DataTableIconButton>
          ) : null}
          {onRemoveClick ? (
            <DataTableIconButton
              onClick={handleRemoveClick}
              loading={loadingRemove}
            >
              <TrashIcon size="18" />
            </DataTableIconButton>
          ) : null}
        </DataTableCell>
      </DataTableRow>
    </>
  );
};
