import { useTranslation } from 'react-i18next';
import { FunctionComponent, useCallback } from 'react';
import imgIntroduction from '../../../assets/hoist_introduction.png';
import Features from '../../../Features';
import { MessageKeys } from '../../../translations/messageKeys';
import {
  DashboardCardBodyInner,
  DashboardCardTitleWrapper,
  DashboardVideoThumbnail,
  DashboardVideoThumbnailText,
  DashboardVideoThumbnailWrapper,
  OasysDashboardCard as DashboardCard,
} from '../Dashboard.styles';

export const DashboardHoistGettingStartedCard: FunctionComponent = () => {
  const { t } = useTranslation();
  const handleVideoClick = useCallback(
    () =>
      window.open(
        'https://web.microsoftstream.com/video/da946964-35c8-4c09-a9b0-57bc340de2c1',
        '_blank',
      ),
    [],
  );

  if (Features.HoistIntstructionVideo) {
    return (
      <DashboardCard
        title={
          <DashboardCardTitleWrapper>
            {t(MessageKeys.DashboardHoistGettingStartedHeading)}
          </DashboardCardTitleWrapper>
        }
      >
        <DashboardCardBodyInner>
          <DashboardVideoThumbnailWrapper>
            <DashboardVideoThumbnailText>
              {t(MessageKeys.DashboardHoistGettingStartedBody)}
            </DashboardVideoThumbnailText>
            <DashboardVideoThumbnail
              src={imgIntroduction}
              title={t(
                MessageKeys.DashboardHoistGettingStartedHeading,
              )}
              onClick={handleVideoClick}
            />
          </DashboardVideoThumbnailWrapper>
        </DashboardCardBodyInner>
      </DashboardCard>
    );
  }
  return null;
};
