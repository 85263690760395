import React, { FunctionComponent, useCallback, PropsWithChildren } from 'react';
import { CalendarEventData, CalendarEventType } from '../../types/calendar';
import { CalendarEventLabel, CalendarEventWrapper } from './Calendar.styles';

interface CalendarEventProps {
  event: CalendarEventData;
  eventType?: CalendarEventType;
  className?: string;
  onClick?: (eventId: string | number, eventKey: string | number) => void;
}

export const CalendarEvent: FunctionComponent<PropsWithChildren<CalendarEventProps>> = ({
  event,
  eventType,
  className,
  children,
  onClick,
}) => {
  const eventId = event.id;
  const eventKey = event?.key ?? '';
  const handleClick = useCallback(
    () => onClick?.(eventId, eventKey),
    [eventId, eventKey, onClick],
  );

  return (
    <CalendarEventWrapper
      color={eventType?.color}
      backgroundColor={eventType?.backgroundColor}
      className={className}
      role="button"
      onClick={handleClick}
    >
      {children ?? (
        <CalendarEventLabel title={event.label}>
          {event.label}
        </CalendarEventLabel>
      )}
    </CalendarEventWrapper>
  );
};
