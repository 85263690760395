import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const ScrollContainer = styled.div`
  overflow-x: auto;
`;

const StyledTable = styled.table`
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
`;

export const Table: FunctionComponent<React.HTMLAttributes<
  HTMLTableElement
>> = (props) => (
  <ScrollContainer>
    <StyledTable {...props} />
  </ScrollContainer>
);
