import { Calendar, SecondaryButton } from '@fcg-tech/regtech-components';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { startOfMonth } from 'date-fns';
import { useTheme } from 'styled-components';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../../translations/messageKeys';
import { CalendarEvents } from '../../../types';
import { routes } from '../../../routes';
import { getDateFnsLocale } from '../../../utils/dateUtils';
import { useSettings } from '../../../states/settingState';
import { useTenant } from '../../../states/tenantState';
import { MainLayoutPaddedContentWrapper } from '../../../components/MainLayout';

const EventType = {
  Agreement: 'agreement',
};

export interface CalendarePageProps {
  events: Array<CalendarEvents>;
  onExportCalendar: () => void;
}

export const CalendarPage: FunctionComponent<CalendarePageProps> = ({
  events = [],
  onExportCalendar,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { settings } = useSettings();
  const tenant = useTenant();
  const tenantId = tenant.id;

  const calendarEvents = useMemo(
    () =>
      events
        ?.filter((event) => event.date)
        ?.filter((event) => event.metadata.eventType === EventType.Agreement)
        ?.map((event) => ({
          key: `${event.metadata.id}-${event.importance}`,
          id: event.metadata.id,
          dateTime: new Date(event.date),
          eventType: event.importance,
          label: event.title,
        })) ?? [],
    [events],
  );

  const eventTypes = [
    {
      id: 'low',
      color: 'white',
      backgroundColor: theme?.colors?.info?.main ?? '#004C42',
    },
    {
      id: 'medium',
      color: 'black',
      backgroundColor: theme?.colors?.warning?.main ?? '#FFC109',
    },
    {
      id: 'high',
      color: 'white',
      backgroundColor: theme?.colors?.error?.main ?? '#DC3545',
    },
  ];

  const handleEventClick = useCallback(
    (eventId: string) => {
      navigate(
        constructUrl(routes.agreement, { tenantId, agreementId: eventId }),
      );
    },
    [navigate, tenantId],
  );

  return (
    <MainLayoutPaddedContentWrapper>
      <Calendar
        monthStartDate={startOfMonth(new Date())}
        dateFnsSettings={{
          locale: getDateFnsLocale(settings.dateFnsLocale ?? 'sv'),
          weekStartsOn: settings.startOfWeek,
        }}
        todayLabel={t(MessageKeys.CalendarExportTodayLabel)}
        agendaLabel={t(MessageKeys.CalendarExportAgendaLabel)}
        calendarLabel={t(MessageKeys.CalendarExportCalendarLabel)}
        eventTypes={eventTypes}
        events={calendarEvents}
        onEventClick={handleEventClick}
      >
        <SecondaryButton onClick={onExportCalendar}>
          {t(MessageKeys.CalendarExportExportLabel)}
        </SecondaryButton>
      </Calendar>
    </MainLayoutPaddedContentWrapper>
  );
};
