import styled from 'styled-components';
import { excludeProps } from '@fcg-tech/regtech-components';

export const FormGroup = styled.div.withConfig<{ contracted?: boolean }>(
  excludeProps('contracted'),
)`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  width: 100%;
  align-items: ${({ contracted }) => (contracted ? 'flex-start' : 'stretch')};
  flex: 1;
  &:last-child {
    margin-bottom: 0;
  }
`;
