import { useCallback, useMemo } from 'react';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { Settings, SettingsV1 } from '../types';

const defaultSettings: Settings = {
  version: 2,
  dateFormat: 'yyyy-MM-dd',
  dateTimeformat: 'yyyy-MM-dd HH:mm',
  startOfWeek: 1,
  dateFnsLocale: 'sv',
};

const SETTINGS_KEY = 'appdata';

const loadSettings = (): Settings => {
  const settings = (JSON.parse(localStorage.getItem(SETTINGS_KEY)) ??
    {}) as Settings;
  settings.version = settings.version ?? 1;

  if (settings.version === 1) {
    delete (settings as SettingsV1)?.columns;
    settings.version = 2;
    saveSettings({ ...defaultSettings, ...(settings ?? {}) });
  }

  return { ...defaultSettings, ...(settings ?? {}) };
};

const saveSettings = (settings: Settings) => {
  localStorage.setItem(SETTINGS_KEY, JSON.stringify(settings));
};

export const settingsState = atom<Settings>({
  key: 'SettingsState',
  default: loadSettings(),
});

export const useSettings = () => {
  const settings = useRecoilValue(settingsState);
  return { settings };
};

export const useSettingsActions = () => {
  const [settings, setSettings] = useRecoilState(settingsState);

  const updateSettings = useCallback(
    (newSettings: Settings) => {
      saveSettings(newSettings);
      setSettings(newSettings);
    },
    [setSettings],
  );

  return useMemo(
    () => ({ settings, updateSettings }),
    [settings, updateSettings],
  );
};
