import styled, { css } from 'styled-components';
import { InfoCircle } from '../InfoCircle';

export const TextAreaWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1;
  background: white;
  position: relative;

  ${({ theme }) => theme?.elements?.textArea?.wrapper}
`;

export const StyledInfoCircle = styled(InfoCircle)`
  margin-left: 1rem;
  margin-top: 0.7rem;
`;

export const StyledTextArea = styled.textarea`
  ${({ theme }) => css`
    flex: 1;
    font-size: 1.4rem;
    padding: 1rem;
    border: 1px solid ${theme?.colors?.border ?? 'rgba(0,0,0,0.3)'};
    border-radius: 4px;
    min-height: 12rem;
    width: 100%;
    height: 100%;
    font-family: ${theme?.typography?.fontFamily ?? 'initial'};
    background: inherit;

    &:focus {
      outline: none;
      border-color: ${theme?.palette?.MainColor?.main ?? 'rgba(0,0,0,0.7)'};
    }

    &.error,
    &.error:focus {
      border-color: ${theme?.colors?.error?.main ?? 'crimson'};
    }

    ${theme?.elements?.textArea?.input}
  `}
`;

export const ReadOnly = styled.div`
  flex: 1;
  font-size: 1.4rem;
  padding: 1rem 1rem 1rem 0;
`;

export const TextAreaErrorMessage = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 0.4rem;
  font-size: 1.2rem;
  color: ${({ theme }) => theme?.colors?.error?.main ?? 'red'};
`;
