import { Label } from '@fcg-tech/regtech-components';
import styled from 'styled-components';

export const CsvExportModalFilenameRow = styled.div`
  display: flex;
  align-items: center;
  padding-right: 2rem;
  min-width: 30rem;
`;

export const CsvExportModalFilenameExtension = styled.span`
  margin-left: 0.5rem;
  font-weight: 600;
`;

export const CsvExportModalFormLabel = styled(Label)`
  position: relative;
`;
