import { isValid, parse } from 'date-fns';

const longRe = /^(\d{4})[-/]?(\d{1,2})[-/]?(\d{1,2})\s*$/;
const monthRe = /^(\d{4})[-/]?(\d{1,2})\s*$/

const pad = (v: string) => v.length === 1 ? `0${v}` : v;

export const parseDate = (value: string): Date | undefined => {
  const month = (value ?? '').match(monthRe);
  if (month) {
    const date = parse(`${month[1]}-${pad(month[2])}-01`, 'yyyy-MM-dd', new Date());
    return isValid(date) ? date : undefined;
  }
  const long = (value ?? '').match(longRe);
  if (long) {
    const date = parse(`${long[1]}-${pad(long[2])}-${pad(long[3])}`, 'yyyy-MM-dd', new Date());
    return isValid(date) ? date : undefined;
  }

  return undefined;
};
