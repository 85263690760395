import styled, { css } from 'styled-components';
import { excludeProps } from '../../utils';

const borderRadius = css`
  border-radius: 0.3rem;
`;

export const ExpansionPanelWrapper = styled.div.withConfig<{
  expanded?: boolean;
}>(excludeProps('expanded'))`
  ${({ expanded }) => css`
    ${expanded && borderRadius}

    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    margin-top: ${expanded ? '1rem' : '0'};
    margin-bottom: ${expanded ? '1rem' : '0'};
    padding: 0.8rem;

    &:first-of-type {
      border-top: 1px solid rgba(0, 0, 0, 0.15);
      border-top-left-radius: 0.3rem;
      border-top-right-radius: 0.3rem;
    }

    &:last-of-type {
      border-bottom-left-radius: 0.3rem;
      border-bottom-right-radius: 0.3rem;
    }
  `}
`;

export const ExpansionPanelDetailsWrapper = styled.div.withConfig<{
  expanded?: boolean;
}>(excludeProps('expanded'))`
  ${({ expanded }) => css`
    display: ${expanded ? 'block' : 'none'};
  `}
`;

export const ExpansionPanelDetails = styled.div`
  padding: 0.8rem 1.2rem;
  font-size: 1.2rem;
`;
