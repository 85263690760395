import { constructCatchAllUrl } from '@fcg-tech/regtech-api-utils';
import { classNames } from '@fcg-tech/regtech-utils';
import {
  Tab,
  TabList,
  TabPanel,
  TabProps,
  TabStateProps,
  useTabState,
} from 'ariakit/tab';
import {
  matchPath,
  useHref,
  useLinkClickHandler,
  useLocation,
  useNavigate,
} from 'react-router-dom';

export const TabLinkList = TabList;

export const TabBarPanel = TabPanel;

export type TabLinkProps = TabProps<'a'> & {
  to: string;
  selectedForActiveSubPaths?: boolean;
};

export function TabLink({
  to,
  selectedForActiveSubPaths,
  className,
  ...props
}: TabLinkProps) {
  const location = useLocation();
  const href = useHref(to);
  const onClick = useLinkClickHandler(to);
  const forceSelected = selectedForActiveSubPaths
    ? matchPath(constructCatchAllUrl(to), location.pathname)
    : undefined;
  return (
    <Tab
      {...props}
      as="a"
      href={href}
      className={classNames(className, forceSelected && 'force-selected')}
      onClick={onClick}
    />
  );
}

export function useTabLinkState(props: TabStateProps = {}) {
  const { pathname: selectedId } = useLocation();
  const navigate = useNavigate();

  const tab = useTabState({
    ...props,
    selectedId,
    setSelectedId: (id) => {
      // setSelectedId may be called more than once for the same id, so we make
      // sure we only navigate once.
      if (id !== selectedId) {
        navigate(id || '/');
      }
    },
  });

  return tab;
}
