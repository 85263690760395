import { useTranslation } from 'react-i18next';
import {
  ColumnsIcon,
  TooltipNext as Tooltip,
  useClickOutside,
  useToggle,
} from '@fcg-tech/regtech-components';
import {
  FilterBarButton,
  FilterBarColumnSelectMenu,
  FilterBarColumnSelectWrapper,
} from '@fcg-tech/regtech-filter';
import update from 'immutability-helper';
import { FunctionComponent, useCallback, useMemo, useRef } from 'react';
import { AgreementTableColumns, AgreementTableType } from '../../types';
import { MessageKeys } from '../../translations/messageKeys';
import { getAgreementTableColumns } from '../AgreementTable/agreementTableHelpers';
import { ColumnSelector } from '../ColumnSelector';
import { useEscapeKey } from '@fcg-tech/regtech-utils';
import { useAgreementTableContext } from '../AgreementTable/AgreementTableContext';

interface AgreementFilterBarColumnSelectorProps {
  tableType?: AgreementTableType;
  disabled?: boolean;
}

export const AgreementFilterBarColumnSelector: FunctionComponent<
  AgreementFilterBarColumnSelectorProps
> = ({ tableType, disabled }) => {
  const { t } = useTranslation();

  const allowedColumns = getAgreementTableColumns(tableType, t);
  const { columnOptions, updateColumnOptions } = useAgreementTableContext();

  const columnSelectContainer = useRef<HTMLDivElement>();
  const [showColumnSelector, setShowColumnSelector, toggleShowColumnSelector] =
    useToggle();

  const closeColumnSelector = useCallback(
    () => setShowColumnSelector(false),
    [setShowColumnSelector],
  );

  const selectedColumns = useMemo(() => {
    if (!columnOptions) {
      return allowedColumns.map((c) => columnOptions?.[c.id]?.visible ?? true);
    }

    return allowedColumns
      .map((c) => c.id)
      .filter((c) => columnOptions[c]?.visible ?? true);
  }, [allowedColumns, columnOptions]);

  useClickOutside(columnSelectContainer, closeColumnSelector);
  useEscapeKey({ callback: closeColumnSelector });

  const handleColumnsChanged = useCallback(
    (selected: Array<AgreementTableColumns>) => {
      updateColumnOptions((prevColumnOptions) => {
        const selectedSet = new Set(selected);
        let updatedOptions = prevColumnOptions;
        allowedColumns.forEach((c) => {
          if (!(c.id in prevColumnOptions)) {
            updatedOptions = update(updatedOptions, {
              [c.id]: {
                $set: {
                  width: c.width,
                  visible: selectedSet.has(c.id as AgreementTableColumns),
                },
              },
            });
          } else {
            updatedOptions = update(updatedOptions, {
              [c.id]: {
                visible: {
                  $set: selectedSet.has(c.id as AgreementTableColumns),
                },
              },
            });
          }
        });
        return updatedOptions;
      });
    },
    [allowedColumns, updateColumnOptions],
  );

  return (
    <FilterBarColumnSelectWrapper
      ref={columnSelectContainer}
      id="column-picker-button-wrapper"
    >
      <Tooltip
        content={t(MessageKeys.AgreementFilterColumnPickerTooltip)}
        showDelay={500}
        placement="bottom-end"
      >
        <FilterBarButton
          toggled={showColumnSelector}
          disabled={disabled}
          onClick={toggleShowColumnSelector}
        >
          <ColumnsIcon size="18" />
        </FilterBarButton>
      </Tooltip>
      {showColumnSelector ? (
        <FilterBarColumnSelectMenu>
          <ColumnSelector
            allowedColumns={allowedColumns}
            selectedColumns={selectedColumns as Array<AgreementTableColumns>}
            onChange={handleColumnsChanged}
          />
        </FilterBarColumnSelectMenu>
      ) : null}
    </FilterBarColumnSelectWrapper>
  );
};
