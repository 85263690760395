import {
  AccessControl as BaseAccessControl,
  AccessControlProps as BaseAccessControlProps,
  useAccessControl as useBaseAccessControl,
} from '@fcg-tech/regtech-components';
import React, { FunctionComponent, PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Permissions } from '../../types';
import { NoContentMessage } from '../generic';
import { useIamContext } from '../IamContext';

interface AccessControlProps
  extends Omit<BaseAccessControlProps<Permissions>, 'permissions'> {
  noAccessMessageKey?: string;
}

export const AccessControl: FunctionComponent<
  PropsWithChildren<AccessControlProps>
> = ({ requiredPermissions, noAccessMessageKey, ...props }) => {
  const { t } = useTranslation();
  const permissions = usePermissions();

  const renderNoAccess = useMemo(
    () =>
      props.renderNoAccess ??
      (noAccessMessageKey
        ? () => <NoContentMessage>{t(noAccessMessageKey)}</NoContentMessage>
        : null),

    [noAccessMessageKey, props.renderNoAccess, t],
  );

  return (
    <BaseAccessControl
      {...props}
      renderNoAccess={renderNoAccess}
      requiredPermissions={requiredPermissions}
      permissions={permissions}
    />
  );
};

export const useAccessControl = (
  requiredPermissions: Permissions | Array<Permissions>,
  opts: { inverted?: boolean } = {},
) => {
  const permissions = usePermissions();
  const allowed = useBaseAccessControl(permissions, requiredPermissions);
  return opts.inverted ? !allowed : allowed;
};

const usePermissions = () => {
  const iamContext = useIamContext();

  const permissions = useMemo(
    () => [...iamContext.globalPermissions, ...iamContext.iamPermissions],
    [iamContext],
  );

  return permissions;
};
