import React from 'react';
import { IconProps } from '../../types/icons';

export const PinIcon = ({
  size = '24',
  color = 'currentColor',
  ...props
}: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    width={size}
    height={size}
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    fill="none"
    {...props}
  >
    <path d="M15 5a2 2 0 0 0 2-2V2H7v1c0 1.1.9 2 2 2m6 0l1 7m-1-7H9m7 7H8m8 0s3 .5 3 4H5c0-3.5 3-4 3-4m0 0l1-7M12 16v6" />
  </svg>
);
