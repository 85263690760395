import { MessageLevel } from '@fcg-tech/regtech-types';
import update from 'immutability-helper';
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useMemo,
  useState,
  PropsWithChildren,
} from 'react';
import { FlashNotification } from './FlashNotification';
import { FlashNotificationListWrapper } from './FlashNotification.styles';

type FlashNotificationData = {
  id?: number | string;
  timeoutMS?: number;
  content: string;
  level: MessageLevel | 'info' | 'success' | 'warning' | 'error';
  error?: Error;
};

type FlashNotifcationContextProps = {
  addFlash: (data: FlashNotificationData) => void;
  clearFlash: (id?: number | string) => void;
};

export const FlashNotificationContext =
  React.createContext<FlashNotifcationContextProps>({
    addFlash: () => null,
    clearFlash: () => null,
  });

export type FlashNotificationManagerProps = {
  className?: string;
};

export const FlashNotificationManager: FunctionComponent<PropsWithChildren<FlashNotificationManagerProps>> = ({ className, children }) => {
  const [notifications, setNotifications] = useState<
    Array<FlashNotificationData>
  >([]);

  const addFlash = useCallback((data: FlashNotificationData) => {
    const d = {
      ...data,
      id: data.id ?? Math.random().toString(32).substring(2),
    };
    setNotifications((old) => [...old, d]);

    if (
      process.env['NODE_ENV'] === 'development' &&
      data.level === MessageLevel.Error
    ) {
      console.error('DEV:', data.content);
      if (data.error) {
        console.error(data.error);
      }
    }

    setTimeout(() => {
      setNotifications((old) => {
        const index = old.findIndex(({ id }) => id === d.id);
        if (index >= 0) {
          return update(old, { $splice: [[index, 1]] });
        }
        return old;
      });
    }, data.timeoutMS ?? 4500);
  }, []);

  const clearFlash = useCallback((id?: number | string) => {
    setNotifications((old) => {
      const index = old.findIndex((f) => id === f.id);
      if (index >= 0) {
        return update(old, { $splice: [[index, 1]] });
      } else if (!id) {
        return [];
      }
      return old;
    });
  }, []);

  const value = useMemo<FlashNotifcationContextProps>(
    () => ({ addFlash, clearFlash }),
    [addFlash, clearFlash],
  );

  return (
    <FlashNotificationContext.Provider value={value}>
      {children}
      <FlashNotificationListWrapper className={className}>
        {notifications?.map((notification) => (
          <FlashNotification
            key={notification.id}
            level={notification.level as MessageLevel}
            content={notification.content}
          />
        ))}
      </FlashNotificationListWrapper>
    </FlashNotificationContext.Provider>
  );
};

export const useFlash = () => {
  const { addFlash } = useContext(FlashNotificationContext);
  return addFlash;
};

export const useFlashContext = () => useContext(FlashNotificationContext);
