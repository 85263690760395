import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardBody,
  PageHeader,
  PageLayout,
  PageLayoutPaddedContent,
  PrimaryButton,
} from '@fcg-tech/regtech-components';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { useAccessControl } from '../../../components/AccessControl';
import { DataTable } from '../../../components/DataTable';
import { routes } from '../../../routes';
import { useTenant } from '../../../states/tenantState';

const tableColumns = [
  { id: 'name', label: 'Name' },
  { id: 'type', label: 'Type' },
];

const toTableData = ({ id, name, type }) => ({
  id,
  name,
  type,
});

export const RolesPage = ({ roles }) => {
  const navigate = useNavigate();
  const tenant = useTenant();
  const tenantId = tenant.id;

  const canAdd = useAccessControl('iam:RoleAdd');

  const handleRowClick = React.useCallback(
    (id) => {
      navigate(constructUrl(routes.role, { tenantId, roleId: id }));
    },
    [navigate, tenantId],
  );

  return (
    <PageLayout>
      <PageHeader title="Roles" breadcrumbs={[]}>
        {canAdd ? (
          <PrimaryButton to={constructUrl(routes.newRole, { tenantId })}>
            Add new
          </PrimaryButton>
        ) : null}
      </PageHeader>
      <PageLayoutPaddedContent>
        <Card>
          <CardBody>
            <DataTable
              columns={tableColumns}
              data={roles?.map(toTableData)}
              onRowClick={handleRowClick}
              emptyMessage="No roles found."
              sortable={{ orderBy: 'name' }}
            />
          </CardBody>
        </Card>
      </PageLayoutPaddedContent>
    </PageLayout>
  );
};

const roleShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
});

RolesPage.propTypes = {
  roles: PropTypes.arrayOf(roleShape),
};

RolesPage.defaultProps = {
  roles: [],
};
