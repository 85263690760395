import React, { FunctionComponent, useMemo } from 'react';
import { CalendarEventData, CalendarEventType } from '../../types/calendar';
import { CalendarAgendaWrapper } from './Calendar.styles';
import { CalendarAgendaEventRow } from './CalendarAgendaEventRow';

interface CalendarAgendaProps {
  events?: Record<number, Array<CalendarEventData>>;
  eventTypes?: Array<CalendarEventType>;
  onEventClick: (eventId: string | number, eventKey: string | number) => void;
}

export const CalendarAgenda: FunctionComponent<CalendarAgendaProps> = ({
  events,
  eventTypes,
  onEventClick,
}) => {
  const sortedEvents = useMemo<Array<Array<CalendarEventData>>>(() => {
    const sortedDates = Object.keys(events).sort();
    return sortedDates.map((date) => events[date]);
  }, [events]);

  return (
    <CalendarAgendaWrapper>
      {sortedEvents?.map((events, index) => (
        <CalendarAgendaEventRow
          key={events[0].key ?? events[0].id ?? index}
          events={events}
          eventTypes={eventTypes}
          onEventClick={onEventClick}
        />
      ))}
    </CalendarAgendaWrapper>
  );
};
