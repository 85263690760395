import styled from 'styled-components';
import { InfoCircle } from '../InfoCircle';
import { Pill } from './Pill';

export const PillMultiSelectWrapper = styled.div`
  width: 100%;
`;

export const ReadOnlyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledInfoCircle = styled(InfoCircle)`
  margin-left: 1rem;
`;

export const PillCollection = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-left: -0.5rem;
  margin-bottom: 0.5rem;
`;

export const StyledPill = styled(Pill)`
  margin: 0.5rem;
`;
