import {exists, mapValues} from '../runtime';



/* Agreement.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



















/**
 * 
 * @export
 * @interface Agreement
 */
export interface Agreement {
    /**
     * 
     * @type {Array<string>}
     * @memberof Agreement
     */
    nonBlockingValidationComments?: Array<string>;
    /**
     * 
     * @type {Detail}
     * @memberof Agreement
     */
    details?: Detail;
    /**
     * 
     * @type {RelatedAgreements}
     * @memberof Agreement
     */
    relatedAgreements?: RelatedAgreements;
    /**
     * 
     * @type {PersonalData}
     * @memberof Agreement
     */
    personalData?: PersonalData;
    /**
     * 
     * @type {ServiceDetails}
     * @memberof Agreement
     */
    serviceDetails?: ServiceDetails;
    /**
     * 
     * @type {Npap}
     * @memberof Agreement
     */
    npap?: Npap;
    /**
     * 
     * @type {AgreementAssessment}
     * @memberof Agreement
     */
    agreementAssessment?: AgreementAssessment;
    /**
     * 
     * @type {ChangeManagementProcess}
     * @memberof Agreement
     */
    changeManagementProcess?: ChangeManagementProcess;
    /**
     * 
     * @type {Array<AgreementValidationsInner>}
     * @memberof Agreement
     */
    validations?: Array<AgreementValidationsInner>;
}

/**
 * Check if a given object implements the Agreement interface.
 */
export function instanceOfAgreement(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AgreementFromJSON(json: any): Agreement {
    return AgreementFromJSONTyped(json, false);
}

export function AgreementFromJSONTyped(json: any, ignoreDiscriminator: boolean): Agreement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nonBlockingValidationComments': !exists(json, 'nonBlockingValidationComments') ? undefined : json['nonBlockingValidationComments'],
        'details': !exists(json, 'details') ? undefined : DetailFromJSON(json['details']),
        'relatedAgreements': !exists(json, 'relatedAgreements') ? undefined : RelatedAgreementsFromJSON(json['relatedAgreements']),
        'personalData': !exists(json, 'personalData') ? undefined : PersonalDataFromJSON(json['personalData']),
        'serviceDetails': !exists(json, 'serviceDetails') ? undefined : ServiceDetailsFromJSON(json['serviceDetails']),
        'npap': !exists(json, 'npap') ? undefined : NpapFromJSON(json['npap']),
        'agreementAssessment': !exists(json, 'agreementAssessment') ? undefined : AgreementAssessmentFromJSON(json['agreementAssessment']),
        'changeManagementProcess': !exists(json, 'changeManagementProcess') ? undefined : ChangeManagementProcessFromJSON(json['changeManagementProcess']),
        'validations': !exists(json, 'validations') ? undefined : ((json['validations'] as Array<any>).map(AgreementValidationsInnerFromJSON)),
    };
}

export function AgreementToJSON(value?: Agreement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nonBlockingValidationComments': value.nonBlockingValidationComments,
        'details': DetailToJSON(value.details),
        'relatedAgreements': RelatedAgreementsToJSON(value.relatedAgreements),
        'personalData': PersonalDataToJSON(value.personalData),
        'serviceDetails': ServiceDetailsToJSON(value.serviceDetails),
        'npap': NpapToJSON(value.npap),
        'agreementAssessment': AgreementAssessmentToJSON(value.agreementAssessment),
        'changeManagementProcess': ChangeManagementProcessToJSON(value.changeManagementProcess),
        'validations': value.validations === undefined ? undefined : ((value.validations as Array<any>).map(AgreementValidationsInnerToJSON)),
    };
}



/* AgreementActions.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AgreementActions = {
    AgreementAttachmentsDelete: 'oasys:AgreementAttachmentsDelete',
    AgreementAttachmentsGet: 'oasys:AgreementAttachmentsGet',
    AgreementAttachmentsList: 'oasys:AgreementAttachmentsList',
    AgreementAttachmentsRename: 'oasys:AgreementAttachmentsRename',
    AgreementAttachmentsUpload: 'oasys:AgreementAttachmentsUpload',
    AgreementDelete: 'oasys:AgreementDelete',
    AgreementEdit: 'oasys:AgreementEdit',
    AgreementEditTags: 'oasys:AgreementEditTags',
    AgreementGet: 'oasys:AgreementGet',
    AgreementHistory: 'oasys:AgreementHistory'
} as const;
export type AgreementActions = typeof AgreementActions[keyof typeof AgreementActions];


export function AgreementActionsFromJSON(json: any): AgreementActions {
    return AgreementActionsFromJSONTyped(json, false);
}

export function AgreementActionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgreementActions {
    return json as AgreementActions;
}

export function AgreementActionsToJSON(value?: AgreementActions | null): any {
    return value as any;
}



/* AgreementAssessment.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface AgreementAssessment
 */
export interface AgreementAssessment {
    /**
     * 
     * @type {boolean}
     * @memberof AgreementAssessment
     */
    isOutsourcingArrangement?: boolean | null;
    /**
     * 
     * @type {AgreementAssessmentData}
     * @memberof AgreementAssessment
     */
    data?: AgreementAssessmentData;
}

/**
 * Check if a given object implements the AgreementAssessment interface.
 */
export function instanceOfAgreementAssessment(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AgreementAssessmentFromJSON(json: any): AgreementAssessment {
    return AgreementAssessmentFromJSONTyped(json, false);
}

export function AgreementAssessmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgreementAssessment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isOutsourcingArrangement': !exists(json, 'isOutsourcingArrangement') ? undefined : json['isOutsourcingArrangement'],
        'data': !exists(json, 'data') ? undefined : AgreementAssessmentDataFromJSON(json['data']),
    };
}

export function AgreementAssessmentToJSON(value?: AgreementAssessment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isOutsourcingArrangement': value.isOutsourcingArrangement,
        'data': AgreementAssessmentDataToJSON(value.data),
    };
}



/* AgreementAssessmentData.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface AgreementAssessmentData
 */
export interface AgreementAssessmentData {
    /**
     * 
     * @type {boolean}
     * @memberof AgreementAssessmentData
     */
    assessmentCompleted?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof AgreementAssessmentData
     */
    latestAssessment?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof AgreementAssessmentData
     */
    summaryOfAssessment?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgreementAssessmentData
     */
    competentAuthorityNotified?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof AgreementAssessmentData
     */
    competentAuthorityNotifiedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof AgreementAssessmentData
     */
    competentAuthorityNotifiedDescription?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgreementAssessmentData
     */
    isCriticalOrImportant?: boolean | null;
    /**
     * 
     * @type {AgreementAssessmentDataCriticalOrImportant}
     * @memberof AgreementAssessmentData
     */
    criticalOrImportant?: AgreementAssessmentDataCriticalOrImportant;
    /**
     * 
     * @type {string}
     * @memberof AgreementAssessmentData
     */
    functionCategory?: string | null;
}

/**
 * Check if a given object implements the AgreementAssessmentData interface.
 */
export function instanceOfAgreementAssessmentData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AgreementAssessmentDataFromJSON(json: any): AgreementAssessmentData {
    return AgreementAssessmentDataFromJSONTyped(json, false);
}

export function AgreementAssessmentDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgreementAssessmentData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assessmentCompleted': !exists(json, 'assessmentCompleted') ? undefined : json['assessmentCompleted'],
        'latestAssessment': !exists(json, 'latestAssessment') ? undefined : (json['latestAssessment'] === null ? null : new Date(json['latestAssessment'])),
        'summaryOfAssessment': !exists(json, 'summaryOfAssessment') ? undefined : json['summaryOfAssessment'],
        'competentAuthorityNotified': !exists(json, 'competentAuthorityNotified') ? undefined : json['competentAuthorityNotified'],
        'competentAuthorityNotifiedDate': !exists(json, 'competentAuthorityNotifiedDate') ? undefined : (json['competentAuthorityNotifiedDate'] === null ? null : new Date(json['competentAuthorityNotifiedDate'])),
        'competentAuthorityNotifiedDescription': !exists(json, 'competentAuthorityNotifiedDescription') ? undefined : json['competentAuthorityNotifiedDescription'],
        'isCriticalOrImportant': !exists(json, 'isCriticalOrImportant') ? undefined : json['isCriticalOrImportant'],
        'criticalOrImportant': !exists(json, 'criticalOrImportant') ? undefined : AgreementAssessmentDataCriticalOrImportantFromJSON(json['criticalOrImportant']),
        'functionCategory': !exists(json, 'functionCategory') ? undefined : json['functionCategory'],
    };
}

export function AgreementAssessmentDataToJSON(value?: AgreementAssessmentData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assessmentCompleted': value.assessmentCompleted,
        'latestAssessment': value.latestAssessment === undefined ? undefined : (value.latestAssessment === null ? null : value.latestAssessment.toISOString().substr(0,10)),
        'summaryOfAssessment': value.summaryOfAssessment,
        'competentAuthorityNotified': value.competentAuthorityNotified,
        'competentAuthorityNotifiedDate': value.competentAuthorityNotifiedDate === undefined ? undefined : (value.competentAuthorityNotifiedDate === null ? null : value.competentAuthorityNotifiedDate.toISOString().substr(0,10)),
        'competentAuthorityNotifiedDescription': value.competentAuthorityNotifiedDescription,
        'isCriticalOrImportant': value.isCriticalOrImportant,
        'criticalOrImportant': AgreementAssessmentDataCriticalOrImportantToJSON(value.criticalOrImportant),
        'functionCategory': value.functionCategory,
    };
}



/* AgreementAssessmentDataCriticalOrImportant.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface AgreementAssessmentDataCriticalOrImportant
 */
export interface AgreementAssessmentDataCriticalOrImportant {
    /**
     * 
     * @type {boolean}
     * @memberof AgreementAssessmentDataCriticalOrImportant
     */
    riskAssessmentCompleted?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof AgreementAssessmentDataCriticalOrImportant
     */
    latestRiskAssessment?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof AgreementAssessmentDataCriticalOrImportant
     */
    nextRiskAssessment?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof AgreementAssessmentDataCriticalOrImportant
     */
    latestRiskAssessmentSummary?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementAssessmentDataCriticalOrImportant
     */
    finalApproval?: string;
    /**
     * 
     * @type {Date}
     * @memberof AgreementAssessmentDataCriticalOrImportant
     */
    latestAudit?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof AgreementAssessmentDataCriticalOrImportant
     */
    latestAuditNotes?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof AgreementAssessmentDataCriticalOrImportant
     */
    nextAudit?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof AgreementAssessmentDataCriticalOrImportant
     */
    exitStrategy?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementAssessmentDataCriticalOrImportant
     */
    alternativeServiceProviders?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgreementAssessmentDataCriticalOrImportant
     */
    timeCriticalOperation?: boolean | null;
}

/**
 * Check if a given object implements the AgreementAssessmentDataCriticalOrImportant interface.
 */
export function instanceOfAgreementAssessmentDataCriticalOrImportant(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AgreementAssessmentDataCriticalOrImportantFromJSON(json: any): AgreementAssessmentDataCriticalOrImportant {
    return AgreementAssessmentDataCriticalOrImportantFromJSONTyped(json, false);
}

export function AgreementAssessmentDataCriticalOrImportantFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgreementAssessmentDataCriticalOrImportant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'riskAssessmentCompleted': !exists(json, 'riskAssessmentCompleted') ? undefined : json['riskAssessmentCompleted'],
        'latestRiskAssessment': !exists(json, 'latestRiskAssessment') ? undefined : (json['latestRiskAssessment'] === null ? null : new Date(json['latestRiskAssessment'])),
        'nextRiskAssessment': !exists(json, 'nextRiskAssessment') ? undefined : (json['nextRiskAssessment'] === null ? null : new Date(json['nextRiskAssessment'])),
        'latestRiskAssessmentSummary': !exists(json, 'latestRiskAssessmentSummary') ? undefined : json['latestRiskAssessmentSummary'],
        'finalApproval': !exists(json, 'finalApproval') ? undefined : json['finalApproval'],
        'latestAudit': !exists(json, 'latestAudit') ? undefined : (json['latestAudit'] === null ? null : new Date(json['latestAudit'])),
        'latestAuditNotes': !exists(json, 'latestAuditNotes') ? undefined : json['latestAuditNotes'],
        'nextAudit': !exists(json, 'nextAudit') ? undefined : (json['nextAudit'] === null ? null : new Date(json['nextAudit'])),
        'exitStrategy': !exists(json, 'exitStrategy') ? undefined : json['exitStrategy'],
        'alternativeServiceProviders': !exists(json, 'alternativeServiceProviders') ? undefined : json['alternativeServiceProviders'],
        'timeCriticalOperation': !exists(json, 'timeCriticalOperation') ? undefined : json['timeCriticalOperation'],
    };
}

export function AgreementAssessmentDataCriticalOrImportantToJSON(value?: AgreementAssessmentDataCriticalOrImportant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'riskAssessmentCompleted': value.riskAssessmentCompleted,
        'latestRiskAssessment': value.latestRiskAssessment === undefined ? undefined : (value.latestRiskAssessment === null ? null : value.latestRiskAssessment.toISOString().substr(0,10)),
        'nextRiskAssessment': value.nextRiskAssessment === undefined ? undefined : (value.nextRiskAssessment === null ? null : value.nextRiskAssessment.toISOString().substr(0,10)),
        'latestRiskAssessmentSummary': value.latestRiskAssessmentSummary,
        'finalApproval': value.finalApproval,
        'latestAudit': value.latestAudit === undefined ? undefined : (value.latestAudit === null ? null : value.latestAudit.toISOString().substr(0,10)),
        'latestAuditNotes': value.latestAuditNotes,
        'nextAudit': value.nextAudit === undefined ? undefined : (value.nextAudit === null ? null : value.nextAudit.toISOString().substr(0,10)),
        'exitStrategy': value.exitStrategy,
        'alternativeServiceProviders': value.alternativeServiceProviders,
        'timeCriticalOperation': value.timeCriticalOperation,
    };
}



/* AgreementAttachmentSearchResult.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface AgreementAttachmentSearchResult
 */
export interface AgreementAttachmentSearchResult {
    /**
     * 
     * @type {string}
     * @memberof AgreementAttachmentSearchResult
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementAttachmentSearchResult
     */
    content?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgreementAttachmentSearchResult
     */
    tags?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AgreementAttachmentSearchResult
     */
    rank?: number;
    /**
     * 
     * @type {GlobalSearchResultType}
     * @memberof AgreementAttachmentSearchResult
     */
    type?: GlobalSearchResultType;
    /**
     * 
     * @type {string}
     * @memberof AgreementAttachmentSearchResult
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementAttachmentSearchResult
     */
    cabinet?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementAttachmentSearchResult
     */
    attachedTo?: string;
}

/**
 * Check if a given object implements the AgreementAttachmentSearchResult interface.
 */
export function instanceOfAgreementAttachmentSearchResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AgreementAttachmentSearchResultFromJSON(json: any): AgreementAttachmentSearchResult {
    return AgreementAttachmentSearchResultFromJSONTyped(json, false);
}

export function AgreementAttachmentSearchResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgreementAttachmentSearchResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'rank': !exists(json, 'rank') ? undefined : json['rank'],
        'type': !exists(json, 'type') ? undefined : GlobalSearchResultTypeFromJSON(json['type']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'cabinet': !exists(json, 'cabinet') ? undefined : json['cabinet'],
        'attachedTo': !exists(json, 'attachedTo') ? undefined : json['attachedTo'],
    };
}

export function AgreementAttachmentSearchResultToJSON(value?: AgreementAttachmentSearchResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'content': value.content,
        'tags': value.tags,
        'rank': value.rank,
        'type': GlobalSearchResultTypeToJSON(value.type),
        'name': value.name,
        'cabinet': value.cabinet,
        'attachedTo': value.attachedTo,
    };
}



/* AgreementCreateInput.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface AgreementCreateInput
 */
export interface AgreementCreateInput {
    /**
     * 
     * @type {Agreement}
     * @memberof AgreementCreateInput
     */
    agreement?: Agreement;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgreementCreateInput
     */
    tags?: Array<string>;
}

/**
 * Check if a given object implements the AgreementCreateInput interface.
 */
export function instanceOfAgreementCreateInput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AgreementCreateInputFromJSON(json: any): AgreementCreateInput {
    return AgreementCreateInputFromJSONTyped(json, false);
}

export function AgreementCreateInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgreementCreateInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agreement': !exists(json, 'agreement') ? undefined : AgreementFromJSON(json['agreement']),
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
    };
}

export function AgreementCreateInputToJSON(value?: AgreementCreateInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agreement': AgreementToJSON(value.agreement),
        'tags': value.tags,
    };
}



/* AgreementFilter.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */









/**
 * 
 * @export
 * @interface AgreementFilter
 */
export interface AgreementFilter {
    /**
     * 
     * @type {boolean}
     * @memberof AgreementFilter
     */
    isPinned?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgreementFilter
     */
    name?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgreementFilter
     */
    agreementType?: Array<string>;
    /**
     * 
     * @type {DateFilter}
     * @memberof AgreementFilter
     */
    startDate?: DateFilter;
    /**
     * 
     * @type {DateFilter}
     * @memberof AgreementFilter
     */
    endDate?: DateFilter;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgreementFilter
     */
    supplierName?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgreementFilter
     */
    partyToAgreement?: Array<string>;
    /**
     * 
     * @type {Array<BooleanFilterEnum>}
     * @memberof AgreementFilter
     */
    isCritical?: Array<BooleanFilterEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof AgreementFilter
     */
    nrOfComments?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgreementFilter
     */
    functionCategory?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgreementFilter
     */
    internalReference?: Array<string>;
    /**
     * 
     * @type {DateFilter}
     * @memberof AgreementFilter
     */
    latestReview?: DateFilter;
    /**
     * 
     * @type {DateFilter}
     * @memberof AgreementFilter
     */
    nextReview?: DateFilter;
    /**
     * 
     * @type {Array<BooleanFilterEnum>}
     * @memberof AgreementFilter
     */
    isPersonalDataTransferred?: Array<BooleanFilterEnum>;
    /**
     * 
     * @type {Array<BooleanFilterEnum>}
     * @memberof AgreementFilter
     */
    isPersonalDataProcessed?: Array<BooleanFilterEnum>;
    /**
     * 
     * @type {Array<BooleanFilterEnum>}
     * @memberof AgreementFilter
     */
    competentAuthorityNotified?: Array<BooleanFilterEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgreementFilter
     */
    archivingReference?: Array<string>;
    /**
     * 
     * @type {Array<BooleanFilterEnum>}
     * @memberof AgreementFilter
     */
    isOutsourcingArrangement?: Array<BooleanFilterEnum>;
    /**
     * 
     * @type {Array<BooleanFilterEnum>}
     * @memberof AgreementFilter
     */
    isProvidedAsCloudService?: Array<BooleanFilterEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgreementFilter
     */
    contractOwner?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgreementFilter
     */
    cabinet?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgreementFilter
     */
    tags?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgreementFilter
     */
    status?: Array<string>;
    /**
     * 
     * @type {ArchivedOptions}
     * @memberof AgreementFilter
     */
    archived?: ArchivedOptions;
}

/**
 * Check if a given object implements the AgreementFilter interface.
 */
export function instanceOfAgreementFilter(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AgreementFilterFromJSON(json: any): AgreementFilter {
    return AgreementFilterFromJSONTyped(json, false);
}

export function AgreementFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgreementFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isPinned': !exists(json, 'isPinned') ? undefined : json['isPinned'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'agreementType': !exists(json, 'agreementType') ? undefined : json['agreementType'],
        'startDate': !exists(json, 'startDate') ? undefined : DateFilterFromJSON(json['startDate']),
        'endDate': !exists(json, 'endDate') ? undefined : DateFilterFromJSON(json['endDate']),
        'supplierName': !exists(json, 'supplierName') ? undefined : json['supplierName'],
        'partyToAgreement': !exists(json, 'partyToAgreement') ? undefined : json['partyToAgreement'],
        'isCritical': !exists(json, 'isCritical') ? undefined : ((json['isCritical'] as Array<any>).map(BooleanFilterEnumFromJSON)),
        'nrOfComments': !exists(json, 'nrOfComments') ? undefined : json['nrOfComments'],
        'functionCategory': !exists(json, 'functionCategory') ? undefined : json['functionCategory'],
        'internalReference': !exists(json, 'internalReference') ? undefined : json['internalReference'],
        'latestReview': !exists(json, 'latestReview') ? undefined : DateFilterFromJSON(json['latestReview']),
        'nextReview': !exists(json, 'nextReview') ? undefined : DateFilterFromJSON(json['nextReview']),
        'isPersonalDataTransferred': !exists(json, 'isPersonalDataTransferred') ? undefined : ((json['isPersonalDataTransferred'] as Array<any>).map(BooleanFilterEnumFromJSON)),
        'isPersonalDataProcessed': !exists(json, 'isPersonalDataProcessed') ? undefined : ((json['isPersonalDataProcessed'] as Array<any>).map(BooleanFilterEnumFromJSON)),
        'competentAuthorityNotified': !exists(json, 'competentAuthorityNotified') ? undefined : ((json['competentAuthorityNotified'] as Array<any>).map(BooleanFilterEnumFromJSON)),
        'archivingReference': !exists(json, 'archivingReference') ? undefined : json['archivingReference'],
        'isOutsourcingArrangement': !exists(json, 'isOutsourcingArrangement') ? undefined : ((json['isOutsourcingArrangement'] as Array<any>).map(BooleanFilterEnumFromJSON)),
        'isProvidedAsCloudService': !exists(json, 'isProvidedAsCloudService') ? undefined : ((json['isProvidedAsCloudService'] as Array<any>).map(BooleanFilterEnumFromJSON)),
        'contractOwner': !exists(json, 'contractOwner') ? undefined : json['contractOwner'],
        'cabinet': !exists(json, 'cabinet') ? undefined : json['cabinet'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'archived': !exists(json, 'archived') ? undefined : ArchivedOptionsFromJSON(json['archived']),
    };
}

export function AgreementFilterToJSON(value?: AgreementFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isPinned': value.isPinned,
        'name': value.name,
        'agreementType': value.agreementType,
        'startDate': DateFilterToJSON(value.startDate),
        'endDate': DateFilterToJSON(value.endDate),
        'supplierName': value.supplierName,
        'partyToAgreement': value.partyToAgreement,
        'isCritical': value.isCritical === undefined ? undefined : ((value.isCritical as Array<any>).map(BooleanFilterEnumToJSON)),
        'nrOfComments': value.nrOfComments,
        'functionCategory': value.functionCategory,
        'internalReference': value.internalReference,
        'latestReview': DateFilterToJSON(value.latestReview),
        'nextReview': DateFilterToJSON(value.nextReview),
        'isPersonalDataTransferred': value.isPersonalDataTransferred === undefined ? undefined : ((value.isPersonalDataTransferred as Array<any>).map(BooleanFilterEnumToJSON)),
        'isPersonalDataProcessed': value.isPersonalDataProcessed === undefined ? undefined : ((value.isPersonalDataProcessed as Array<any>).map(BooleanFilterEnumToJSON)),
        'competentAuthorityNotified': value.competentAuthorityNotified === undefined ? undefined : ((value.competentAuthorityNotified as Array<any>).map(BooleanFilterEnumToJSON)),
        'archivingReference': value.archivingReference,
        'isOutsourcingArrangement': value.isOutsourcingArrangement === undefined ? undefined : ((value.isOutsourcingArrangement as Array<any>).map(BooleanFilterEnumToJSON)),
        'isProvidedAsCloudService': value.isProvidedAsCloudService === undefined ? undefined : ((value.isProvidedAsCloudService as Array<any>).map(BooleanFilterEnumToJSON)),
        'contractOwner': value.contractOwner,
        'cabinet': value.cabinet,
        'tags': value.tags,
        'status': value.status,
        'archived': ArchivedOptionsToJSON(value.archived),
    };
}



/* AgreementFilterInput.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface AgreementFilterInput
 */
export interface AgreementFilterInput {
    /**
     * 
     * @type {string}
     * @memberof AgreementFilterInput
     */
    name: string;
    /**
     * 
     * @type {AgreementFilter}
     * @memberof AgreementFilterInput
     */
    filter?: AgreementFilter;
}

/**
 * Check if a given object implements the AgreementFilterInput interface.
 */
export function instanceOfAgreementFilterInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function AgreementFilterInputFromJSON(json: any): AgreementFilterInput {
    return AgreementFilterInputFromJSONTyped(json, false);
}

export function AgreementFilterInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgreementFilterInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'filter': !exists(json, 'filter') ? undefined : AgreementFilterFromJSON(json['filter']),
    };
}

export function AgreementFilterInputToJSON(value?: AgreementFilterInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'filter': AgreementFilterToJSON(value.filter),
    };
}



/* AgreementListItem.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */









/**
 * 
 * @export
 * @interface AgreementListItem
 */
export interface AgreementListItem {
    /**
     * 
     * @type {Metadata}
     * @memberof AgreementListItem
     */
    metadata: Metadata;
    /**
     * 
     * @type {string}
     * @memberof AgreementListItem
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementListItem
     */
    agreementType?: string;
    /**
     * 
     * @type {Date}
     * @memberof AgreementListItem
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AgreementListItem
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof AgreementListItem
     */
    supplierName?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementListItem
     */
    partyToAgreement?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgreementListItem
     */
    isCritical?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AgreementListItem
     */
    nrOfComments?: number;
    /**
     * 
     * @type {string}
     * @memberof AgreementListItem
     */
    groupName?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementListItem
     */
    functionCategory?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementListItem
     */
    internalReference?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementListItem
     */
    briefDescription?: string;
    /**
     * 
     * @type {Date}
     * @memberof AgreementListItem
     */
    latestReview?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AgreementListItem
     */
    nextReview?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof AgreementListItem
     */
    isPersonalDataTransferred?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgreementListItem
     */
    isPersonalDataProcessed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgreementListItem
     */
    isProvidedAsCloudService?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgreementListItem
     */
    competentAuthorityNotified?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgreementListItem
     */
    archivingReference?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgreementListItem
     */
    isOutsourcingArrangement?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgreementListItem
     */
    contractOwner?: string;
    /**
     * 
     * @type {Amount}
     * @memberof AgreementListItem
     */
    totalValueOfAgreement?: Amount;
    /**
     * 
     * @type {string}
     * @memberof AgreementListItem
     */
    cabinet?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgreementListItem
     */
    tags?: Array<string>;
    /**
     * 
     * @type {Status}
     * @memberof AgreementListItem
     */
    status?: Status | null;
}

/**
 * Check if a given object implements the AgreementListItem interface.
 */
export function instanceOfAgreementListItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;

    return isInstance;
}

export function AgreementListItemFromJSON(json: any): AgreementListItem {
    return AgreementListItemFromJSONTyped(json, false);
}

export function AgreementListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgreementListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataFromJSON(json['metadata']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'agreementType': !exists(json, 'agreementType') ? undefined : json['agreementType'],
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'supplierName': !exists(json, 'supplierName') ? undefined : json['supplierName'],
        'partyToAgreement': !exists(json, 'partyToAgreement') ? undefined : json['partyToAgreement'],
        'isCritical': !exists(json, 'isCritical') ? undefined : json['isCritical'],
        'nrOfComments': !exists(json, 'nrOfComments') ? undefined : json['nrOfComments'],
        'groupName': !exists(json, 'groupName') ? undefined : json['groupName'],
        'functionCategory': !exists(json, 'functionCategory') ? undefined : json['functionCategory'],
        'internalReference': !exists(json, 'internalReference') ? undefined : json['internalReference'],
        'briefDescription': !exists(json, 'briefDescription') ? undefined : json['briefDescription'],
        'latestReview': !exists(json, 'latestReview') ? undefined : (new Date(json['latestReview'])),
        'nextReview': !exists(json, 'nextReview') ? undefined : (new Date(json['nextReview'])),
        'isPersonalDataTransferred': !exists(json, 'isPersonalDataTransferred') ? undefined : json['isPersonalDataTransferred'],
        'isPersonalDataProcessed': !exists(json, 'isPersonalDataProcessed') ? undefined : json['isPersonalDataProcessed'],
        'isProvidedAsCloudService': !exists(json, 'isProvidedAsCloudService') ? undefined : json['isProvidedAsCloudService'],
        'competentAuthorityNotified': !exists(json, 'competentAuthorityNotified') ? undefined : json['competentAuthorityNotified'],
        'archivingReference': !exists(json, 'archivingReference') ? undefined : json['archivingReference'],
        'isOutsourcingArrangement': !exists(json, 'isOutsourcingArrangement') ? undefined : json['isOutsourcingArrangement'],
        'contractOwner': !exists(json, 'contractOwner') ? undefined : json['contractOwner'],
        'totalValueOfAgreement': !exists(json, 'totalValueOfAgreement') ? undefined : AmountFromJSON(json['totalValueOfAgreement']),
        'cabinet': !exists(json, 'cabinet') ? undefined : json['cabinet'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'status': !exists(json, 'status') ? undefined : StatusFromJSON(json['status']),
    };
}

export function AgreementListItemToJSON(value?: AgreementListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataToJSON(value.metadata),
        'name': value.name,
        'agreementType': value.agreementType,
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString().substr(0,10)),
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString().substr(0,10)),
        'supplierName': value.supplierName,
        'partyToAgreement': value.partyToAgreement,
        'isCritical': value.isCritical,
        'nrOfComments': value.nrOfComments,
        'groupName': value.groupName,
        'functionCategory': value.functionCategory,
        'internalReference': value.internalReference,
        'briefDescription': value.briefDescription,
        'latestReview': value.latestReview === undefined ? undefined : (value.latestReview.toISOString().substr(0,10)),
        'nextReview': value.nextReview === undefined ? undefined : (value.nextReview.toISOString().substr(0,10)),
        'isPersonalDataTransferred': value.isPersonalDataTransferred,
        'isPersonalDataProcessed': value.isPersonalDataProcessed,
        'isProvidedAsCloudService': value.isProvidedAsCloudService,
        'competentAuthorityNotified': value.competentAuthorityNotified,
        'archivingReference': value.archivingReference,
        'isOutsourcingArrangement': value.isOutsourcingArrangement,
        'contractOwner': value.contractOwner,
        'totalValueOfAgreement': AmountToJSON(value.totalValueOfAgreement),
        'cabinet': value.cabinet,
        'tags': value.tags,
        'status': StatusToJSON(value.status),
    };
}



/* AgreementSearchResult.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface AgreementSearchResult
 */
export interface AgreementSearchResult {
    /**
     * 
     * @type {string}
     * @memberof AgreementSearchResult
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementSearchResult
     */
    content?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgreementSearchResult
     */
    tags?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AgreementSearchResult
     */
    rank?: number;
    /**
     * 
     * @type {GlobalSearchResultType}
     * @memberof AgreementSearchResult
     */
    type?: GlobalSearchResultType;
    /**
     * 
     * @type {string}
     * @memberof AgreementSearchResult
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementSearchResult
     */
    cabinet?: string;
}

/**
 * Check if a given object implements the AgreementSearchResult interface.
 */
export function instanceOfAgreementSearchResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AgreementSearchResultFromJSON(json: any): AgreementSearchResult {
    return AgreementSearchResultFromJSONTyped(json, false);
}

export function AgreementSearchResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgreementSearchResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'rank': !exists(json, 'rank') ? undefined : json['rank'],
        'type': !exists(json, 'type') ? undefined : GlobalSearchResultTypeFromJSON(json['type']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'cabinet': !exists(json, 'cabinet') ? undefined : json['cabinet'],
    };
}

export function AgreementSearchResultToJSON(value?: AgreementSearchResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'content': value.content,
        'tags': value.tags,
        'rank': value.rank,
        'type': GlobalSearchResultTypeToJSON(value.type),
        'name': value.name,
        'cabinet': value.cabinet,
    };
}



/* AgreementType.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface AgreementType
 */
export interface AgreementType {
    /**
     * 
     * @type {string}
     * @memberof AgreementType
     */
    name: string;
}

/**
 * Check if a given object implements the AgreementType interface.
 */
export function instanceOfAgreementType(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function AgreementTypeFromJSON(json: any): AgreementType {
    return AgreementTypeFromJSONTyped(json, false);
}

export function AgreementTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgreementType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
    };
}

export function AgreementTypeToJSON(value?: AgreementType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
    };
}



/* AgreementTypeActions.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AgreementTypeActions = {
    AgreementTypeEdit: 'oasys:AgreementTypeEdit',
    AgreementTypeDelete: 'oasys:AgreementTypeDelete',
    AgreementTypeGet: 'oasys:AgreementTypeGet'
} as const;
export type AgreementTypeActions = typeof AgreementTypeActions[keyof typeof AgreementTypeActions];


export function AgreementTypeActionsFromJSON(json: any): AgreementTypeActions {
    return AgreementTypeActionsFromJSONTyped(json, false);
}

export function AgreementTypeActionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgreementTypeActions {
    return json as AgreementTypeActions;
}

export function AgreementTypeActionsToJSON(value?: AgreementTypeActions | null): any {
    return value as any;
}



/* AgreementTypeListItem.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface AgreementTypeListItem
 */
export interface AgreementTypeListItem {
    /**
     * 
     * @type {Metadata}
     * @memberof AgreementTypeListItem
     */
    metadata: Metadata;
    /**
     * 
     * @type {string}
     * @memberof AgreementTypeListItem
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof AgreementTypeListItem
     */
    nrOfAgreements?: number;
}

/**
 * Check if a given object implements the AgreementTypeListItem interface.
 */
export function instanceOfAgreementTypeListItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;

    return isInstance;
}

export function AgreementTypeListItemFromJSON(json: any): AgreementTypeListItem {
    return AgreementTypeListItemFromJSONTyped(json, false);
}

export function AgreementTypeListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgreementTypeListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataFromJSON(json['metadata']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'nrOfAgreements': !exists(json, 'nrOfAgreements') ? undefined : json['nrOfAgreements'],
    };
}

export function AgreementTypeListItemToJSON(value?: AgreementTypeListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataToJSON(value.metadata),
        'name': value.name,
        'nrOfAgreements': value.nrOfAgreements,
    };
}



/* AgreementValidationsInner.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface AgreementValidationsInner
 */
export interface AgreementValidationsInner {
    /**
     * 
     * @type {string}
     * @memberof AgreementValidationsInner
     */
    field?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementValidationsInner
     */
    level?: AgreementValidationsInnerLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof AgreementValidationsInner
     */
    validationGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementValidationsInner
     */
    message?: string;
}


/**
 * @export
 */
export const AgreementValidationsInnerLevelEnum = {
    Warning: 'warning',
    Error: 'error'
} as const;
export type AgreementValidationsInnerLevelEnum = typeof AgreementValidationsInnerLevelEnum[keyof typeof AgreementValidationsInnerLevelEnum];


/**
 * Check if a given object implements the AgreementValidationsInner interface.
 */
export function instanceOfAgreementValidationsInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AgreementValidationsInnerFromJSON(json: any): AgreementValidationsInner {
    return AgreementValidationsInnerFromJSONTyped(json, false);
}

export function AgreementValidationsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgreementValidationsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'field': !exists(json, 'field') ? undefined : json['field'],
        'level': !exists(json, 'level') ? undefined : json['level'],
        'validationGroup': !exists(json, 'validationGroup') ? undefined : json['validationGroup'],
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function AgreementValidationsInnerToJSON(value?: AgreementValidationsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'field': value.field,
        'level': value.level,
        'validationGroup': value.validationGroup,
        'message': value.message,
    };
}



/* AgreementVersionCreateInput.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface AgreementVersionCreateInput
 */
export interface AgreementVersionCreateInput {
    /**
     * 
     * @type {Agreement}
     * @memberof AgreementVersionCreateInput
     */
    agreement?: Agreement;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgreementVersionCreateInput
     */
    tags?: Array<string>;
}

/**
 * Check if a given object implements the AgreementVersionCreateInput interface.
 */
export function instanceOfAgreementVersionCreateInput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AgreementVersionCreateInputFromJSON(json: any): AgreementVersionCreateInput {
    return AgreementVersionCreateInputFromJSONTyped(json, false);
}

export function AgreementVersionCreateInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgreementVersionCreateInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agreement': !exists(json, 'agreement') ? undefined : AgreementFromJSON(json['agreement']),
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
    };
}

export function AgreementVersionCreateInputToJSON(value?: AgreementVersionCreateInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agreement': AgreementToJSON(value.agreement),
        'tags': value.tags,
    };
}



/* AgreementVersionListItem.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface AgreementVersionListItem
 */
export interface AgreementVersionListItem {
    /**
     * 
     * @type {Metadata}
     * @memberof AgreementVersionListItem
     */
    metadata: Metadata;
}

/**
 * Check if a given object implements the AgreementVersionListItem interface.
 */
export function instanceOfAgreementVersionListItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;

    return isInstance;
}

export function AgreementVersionListItemFromJSON(json: any): AgreementVersionListItem {
    return AgreementVersionListItemFromJSONTyped(json, false);
}

export function AgreementVersionListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgreementVersionListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataFromJSON(json['metadata']),
    };
}

export function AgreementVersionListItemToJSON(value?: AgreementVersionListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataToJSON(value.metadata),
    };
}



/* Amount.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface Amount
 */
export interface Amount {
    /**
     * 
     * @type {string}
     * @memberof Amount
     */
    amount?: string;
    /**
     * 
     * @type {string}
     * @memberof Amount
     */
    currency?: string | null;
}

/**
 * Check if a given object implements the Amount interface.
 */
export function instanceOfAmount(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AmountFromJSON(json: any): Amount {
    return AmountFromJSONTyped(json, false);
}

export function AmountFromJSONTyped(json: any, ignoreDiscriminator: boolean): Amount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
    };
}

export function AmountToJSON(value?: Amount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'currency': value.currency,
    };
}



/* ArchivedOptions.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ArchivedOptions = {
    Archived: 'archived',
    NotArchived: 'not-archived',
    All: 'all'
} as const;
export type ArchivedOptions = typeof ArchivedOptions[keyof typeof ArchivedOptions];


export function ArchivedOptionsFromJSON(json: any): ArchivedOptions {
    return ArchivedOptionsFromJSONTyped(json, false);
}

export function ArchivedOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArchivedOptions {
    return json as ArchivedOptions;
}

export function ArchivedOptionsToJSON(value?: ArchivedOptions | null): any {
    return value as any;
}



/* BooleanFilterEnum.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const BooleanFilterEnum = {
    Yes: 'yes',
    No: 'no',
    NotSet: 'notSet'
} as const;
export type BooleanFilterEnum = typeof BooleanFilterEnum[keyof typeof BooleanFilterEnum];


export function BooleanFilterEnumFromJSON(json: any): BooleanFilterEnum {
    return BooleanFilterEnumFromJSONTyped(json, false);
}

export function BooleanFilterEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): BooleanFilterEnum {
    return json as BooleanFilterEnum;
}

export function BooleanFilterEnumToJSON(value?: BooleanFilterEnum | null): any {
    return value as any;
}



/* Cabinet.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface Cabinet
 */
export interface Cabinet {
    /**
     * 
     * @type {string}
     * @memberof Cabinet
     */
    name: string;
}

/**
 * Check if a given object implements the Cabinet interface.
 */
export function instanceOfCabinet(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function CabinetFromJSON(json: any): Cabinet {
    return CabinetFromJSONTyped(json, false);
}

export function CabinetFromJSONTyped(json: any, ignoreDiscriminator: boolean): Cabinet {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
    };
}

export function CabinetToJSON(value?: Cabinet | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
    };
}



/* CabinetActions.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CabinetActions = {
    AgreementAddInCabinet: 'oasys:AgreementAddInCabinet',
    CabinetDelete: 'oasys:CabinetDelete',
    CabinetGet: 'oasys:CabinetGet'
} as const;
export type CabinetActions = typeof CabinetActions[keyof typeof CabinetActions];


export function CabinetActionsFromJSON(json: any): CabinetActions {
    return CabinetActionsFromJSONTyped(json, false);
}

export function CabinetActionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CabinetActions {
    return json as CabinetActions;
}

export function CabinetActionsToJSON(value?: CabinetActions | null): any {
    return value as any;
}



/* CalendarEvent.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface CalendarEvent
 */
export interface CalendarEvent {
    /**
     * 
     * @type {string}
     * @memberof CalendarEvent
     */
    title?: string;
    /**
     * 
     * @type {Date}
     * @memberof CalendarEvent
     */
    date?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof CalendarEvent
     */
    tags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CalendarEvent
     */
    importance?: CalendarEventImportanceEnum;
    /**
     * 
     * @type {CalendarEventMetadata}
     * @memberof CalendarEvent
     */
    metadata?: CalendarEventMetadata;
}


/**
 * @export
 */
export const CalendarEventImportanceEnum = {
    Low: 'low',
    Medium: 'medium',
    High: 'high'
} as const;
export type CalendarEventImportanceEnum = typeof CalendarEventImportanceEnum[keyof typeof CalendarEventImportanceEnum];


/**
 * Check if a given object implements the CalendarEvent interface.
 */
export function instanceOfCalendarEvent(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CalendarEventFromJSON(json: any): CalendarEvent {
    return CalendarEventFromJSONTyped(json, false);
}

export function CalendarEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalendarEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'importance': !exists(json, 'importance') ? undefined : json['importance'],
        'metadata': !exists(json, 'metadata') ? undefined : CalendarEventMetadataFromJSON(json['metadata']),
    };
}

export function CalendarEventToJSON(value?: CalendarEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'date': value.date === undefined ? undefined : (value.date.toISOString().substr(0,10)),
        'tags': value.tags,
        'importance': value.importance,
        'metadata': CalendarEventMetadataToJSON(value.metadata),
    };
}



/* CalendarEventMetadata.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface CalendarEventMetadata
 */
export interface CalendarEventMetadata {
    /**
     * 
     * @type {string}
     * @memberof CalendarEventMetadata
     */
    eventType?: CalendarEventMetadataEventTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventMetadata
     */
    id?: string;
}


/**
 * @export
 */
export const CalendarEventMetadataEventTypeEnum = {
    Agreement: 'agreement',
    Other: 'other'
} as const;
export type CalendarEventMetadataEventTypeEnum = typeof CalendarEventMetadataEventTypeEnum[keyof typeof CalendarEventMetadataEventTypeEnum];


/**
 * Check if a given object implements the CalendarEventMetadata interface.
 */
export function instanceOfCalendarEventMetadata(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CalendarEventMetadataFromJSON(json: any): CalendarEventMetadata {
    return CalendarEventMetadataFromJSONTyped(json, false);
}

export function CalendarEventMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalendarEventMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventType': !exists(json, 'eventType') ? undefined : json['eventType'],
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function CalendarEventMetadataToJSON(value?: CalendarEventMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eventType': value.eventType,
        'id': value.id,
    };
}



/* Category.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    name: string;
}

/**
 * Check if a given object implements the Category interface.
 */
export function instanceOfCategory(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function CategoryFromJSON(json: any): Category {
    return CategoryFromJSONTyped(json, false);
}

export function CategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Category {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
    };
}

export function CategoryToJSON(value?: Category | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
    };
}



/* CategoryActions.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CategoryActions = {
    CategoryDelete: 'oasys:CategoryDelete',
    CategoryEdit: 'oasys:CategoryEdit',
    CategoryGet: 'oasys:CategoryGet'
} as const;
export type CategoryActions = typeof CategoryActions[keyof typeof CategoryActions];


export function CategoryActionsFromJSON(json: any): CategoryActions {
    return CategoryActionsFromJSONTyped(json, false);
}

export function CategoryActionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryActions {
    return json as CategoryActions;
}

export function CategoryActionsToJSON(value?: CategoryActions | null): any {
    return value as any;
}



/* CategoryListItem.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface CategoryListItem
 */
export interface CategoryListItem {
    /**
     * 
     * @type {Metadata}
     * @memberof CategoryListItem
     */
    metadata: Metadata;
    /**
     * 
     * @type {string}
     * @memberof CategoryListItem
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof CategoryListItem
     */
    nrOfAgreements?: number;
}

/**
 * Check if a given object implements the CategoryListItem interface.
 */
export function instanceOfCategoryListItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;

    return isInstance;
}

export function CategoryListItemFromJSON(json: any): CategoryListItem {
    return CategoryListItemFromJSONTyped(json, false);
}

export function CategoryListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataFromJSON(json['metadata']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'nrOfAgreements': !exists(json, 'nrOfAgreements') ? undefined : json['nrOfAgreements'],
    };
}

export function CategoryListItemToJSON(value?: CategoryListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataToJSON(value.metadata),
        'name': value.name,
        'nrOfAgreements': value.nrOfAgreements,
    };
}



/* ChangeManagementProcess.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface ChangeManagementProcess
 */
export interface ChangeManagementProcess {
    /**
     * 
     * @type {boolean}
     * @memberof ChangeManagementProcess
     */
    required?: boolean | null;
    /**
     * 
     * @type {ChangeManagementProcessData}
     * @memberof ChangeManagementProcess
     */
    data?: ChangeManagementProcessData;
}

/**
 * Check if a given object implements the ChangeManagementProcess interface.
 */
export function instanceOfChangeManagementProcess(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChangeManagementProcessFromJSON(json: any): ChangeManagementProcess {
    return ChangeManagementProcessFromJSONTyped(json, false);
}

export function ChangeManagementProcessFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeManagementProcess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'required': !exists(json, 'required') ? undefined : json['required'],
        'data': !exists(json, 'data') ? undefined : ChangeManagementProcessDataFromJSON(json['data']),
    };
}

export function ChangeManagementProcessToJSON(value?: ChangeManagementProcess | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'required': value.required,
        'data': ChangeManagementProcessDataToJSON(value.data),
    };
}



/* ChangeManagementProcessData.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface ChangeManagementProcessData
 */
export interface ChangeManagementProcessData {
    /**
     * 
     * @type {string}
     * @memberof ChangeManagementProcessData
     */
    summary?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ChangeManagementProcessData
     */
    completed?: boolean | null;
}

/**
 * Check if a given object implements the ChangeManagementProcessData interface.
 */
export function instanceOfChangeManagementProcessData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChangeManagementProcessDataFromJSON(json: any): ChangeManagementProcessData {
    return ChangeManagementProcessDataFromJSONTyped(json, false);
}

export function ChangeManagementProcessDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeManagementProcessData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'completed': !exists(json, 'completed') ? undefined : json['completed'],
    };
}

export function ChangeManagementProcessDataToJSON(value?: ChangeManagementProcessData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'summary': value.summary,
        'completed': value.completed,
    };
}



/* Country.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface Country
 */
export interface Country {
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    isoAlpha2: string;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    name: string;
}

/**
 * Check if a given object implements the Country interface.
 */
export function instanceOfCountry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "isoAlpha2" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function CountryFromJSON(json: any): Country {
    return CountryFromJSONTyped(json, false);
}

export function CountryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Country {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isoAlpha2': json['isoAlpha2'],
        'name': json['name'],
    };
}

export function CountryToJSON(value?: Country | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isoAlpha2': value.isoAlpha2,
        'name': value.name,
    };
}



/* CreateAgreementAttachmentUploadUrlRequest.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface CreateAgreementAttachmentUploadUrlRequest
 */
export interface CreateAgreementAttachmentUploadUrlRequest {
    /**
     * 
     * @type {FileAttachmentSection}
     * @memberof CreateAgreementAttachmentUploadUrlRequest
     */
    section: FileAttachmentSection;
    /**
     * 
     * @type {string}
     * @memberof CreateAgreementAttachmentUploadUrlRequest
     */
    filename: string;
}

/**
 * Check if a given object implements the CreateAgreementAttachmentUploadUrlRequest interface.
 */
export function instanceOfCreateAgreementAttachmentUploadUrlRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "section" in value;
    isInstance = isInstance && "filename" in value;

    return isInstance;
}

export function CreateAgreementAttachmentUploadUrlRequestFromJSON(json: any): CreateAgreementAttachmentUploadUrlRequest {
    return CreateAgreementAttachmentUploadUrlRequestFromJSONTyped(json, false);
}

export function CreateAgreementAttachmentUploadUrlRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAgreementAttachmentUploadUrlRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'section': FileAttachmentSectionFromJSON(json['section']),
        'filename': json['filename'],
    };
}

export function CreateAgreementAttachmentUploadUrlRequestToJSON(value?: CreateAgreementAttachmentUploadUrlRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'section': FileAttachmentSectionToJSON(value.section),
        'filename': value.filename,
    };
}



/* CreateSupplierAttachmentUploadUrlRequest.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface CreateSupplierAttachmentUploadUrlRequest
 */
export interface CreateSupplierAttachmentUploadUrlRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierAttachmentUploadUrlRequest
     */
    filename: string;
}

/**
 * Check if a given object implements the CreateSupplierAttachmentUploadUrlRequest interface.
 */
export function instanceOfCreateSupplierAttachmentUploadUrlRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "filename" in value;

    return isInstance;
}

export function CreateSupplierAttachmentUploadUrlRequestFromJSON(json: any): CreateSupplierAttachmentUploadUrlRequest {
    return CreateSupplierAttachmentUploadUrlRequestFromJSONTyped(json, false);
}

export function CreateSupplierAttachmentUploadUrlRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSupplierAttachmentUploadUrlRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filename': json['filename'],
    };
}

export function CreateSupplierAttachmentUploadUrlRequestToJSON(value?: CreateSupplierAttachmentUploadUrlRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filename': value.filename,
    };
}



/* CreatedResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface CreatedResponse
 */
export interface CreatedResponse {
    /**
     * 
     * @type {string}
     * @memberof CreatedResponse
     */
    id: string;
}

/**
 * Check if a given object implements the CreatedResponse interface.
 */
export function instanceOfCreatedResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function CreatedResponseFromJSON(json: any): CreatedResponse {
    return CreatedResponseFromJSONTyped(json, false);
}

export function CreatedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatedResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
    };
}

export function CreatedResponseToJSON(value?: CreatedResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
    };
}



/* Currency.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface Currency
 */
export interface Currency {
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    name: string;
}

/**
 * Check if a given object implements the Currency interface.
 */
export function instanceOfCurrency(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function CurrencyFromJSON(json: any): Currency {
    return CurrencyFromJSONTyped(json, false);
}

export function CurrencyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Currency {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'name': json['name'],
    };
}

export function CurrencyToJSON(value?: Currency | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'name': value.name,
    };
}



/* DateFilter.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface DateFilter
 */
export interface DateFilter {
    /**
     * 
     * @type {Date}
     * @memberof DateFilter
     */
    from?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DateFilter
     */
    to?: Date;
    /**
     * 
     * @type {string}
     * @memberof DateFilter
     */
    interval?: string;
}

/**
 * Check if a given object implements the DateFilter interface.
 */
export function instanceOfDateFilter(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DateFilterFromJSON(json: any): DateFilter {
    return DateFilterFromJSONTyped(json, false);
}

export function DateFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): DateFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'from': !exists(json, 'from') ? undefined : (new Date(json['from'])),
        'to': !exists(json, 'to') ? undefined : (new Date(json['to'])),
        'interval': !exists(json, 'interval') ? undefined : json['interval'],
    };
}

export function DateFilterToJSON(value?: DateFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from': value.from === undefined ? undefined : (value.from.toISOString().substr(0,10)),
        'to': value.to === undefined ? undefined : (value.to.toISOString().substr(0,10)),
        'interval': value.interval,
    };
}



/* DeploymentModel.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DeploymentModel = {
    Public: 'Public',
    Private: 'Private',
    Hybrid: 'Hybrid',
    Community: 'Community',
    Null: 'null'
} as const;
export type DeploymentModel = typeof DeploymentModel[keyof typeof DeploymentModel];


export function DeploymentModelFromJSON(json: any): DeploymentModel {
    return DeploymentModelFromJSONTyped(json, false);
}

export function DeploymentModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeploymentModel {
    return json as DeploymentModel;
}

export function DeploymentModelToJSON(value?: DeploymentModel | null): any {
    return value as any;
}



/* Detail.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */











/**
 * 
 * @export
 * @interface Detail
 */
export interface Detail {
    /**
     * 
     * @type {string}
     * @memberof Detail
     */
    name: string;
    /**
     * 
     * @type {DetailMainSupplier}
     * @memberof Detail
     */
    mainSupplier?: DetailMainSupplier;
    /**
     * Reference an internalEntity that handle the agreement
     * @type {string}
     * @memberof Detail
     */
    partyToAgreement?: string | null;
    /**
     * Reference to internalEntities. Required when critical or important
     * @type {Array<string>}
     * @memberof Detail
     */
    receiversOfProvidedService?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Detail
     */
    contractOwner?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Detail
     */
    internalReference?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Detail
     */
    governingLawOftheAgreement?: string | null;
    /**
     * 
     * @type {Status}
     * @memberof Detail
     */
    status?: Status | null;
    /**
     * 
     * @type {Term}
     * @memberof Detail
     */
    term?: Term;
    /**
     * 
     * @type {string}
     * @memberof Detail
     */
    archivingReference?: string;
    /**
     * 
     * @type {Date}
     * @memberof Detail
     */
    latestReview?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Detail
     */
    nextReview?: Date | null;
    /**
     * 
     * @type {Amount}
     * @memberof Detail
     */
    annualBudgetCostEstimation?: Amount;
    /**
     * 
     * @type {Amount}
     * @memberof Detail
     */
    totalValueOfAgreement?: Amount;
    /**
     * A brief description of function an data
     * @type {string}
     * @memberof Detail
     */
    briefDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof Detail
     */
    agreementType?: string | null;
}

/**
 * Check if a given object implements the Detail interface.
 */
export function instanceOfDetail(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function DetailFromJSON(json: any): Detail {
    return DetailFromJSONTyped(json, false);
}

export function DetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): Detail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'mainSupplier': !exists(json, 'mainSupplier') ? undefined : DetailMainSupplierFromJSON(json['mainSupplier']),
        'partyToAgreement': !exists(json, 'partyToAgreement') ? undefined : json['partyToAgreement'],
        'receiversOfProvidedService': !exists(json, 'receiversOfProvidedService') ? undefined : json['receiversOfProvidedService'],
        'contractOwner': !exists(json, 'contractOwner') ? undefined : json['contractOwner'],
        'internalReference': !exists(json, 'internalReference') ? undefined : json['internalReference'],
        'governingLawOftheAgreement': !exists(json, 'governingLawOftheAgreement') ? undefined : json['governingLawOftheAgreement'],
        'status': !exists(json, 'status') ? undefined : StatusFromJSON(json['status']),
        'term': !exists(json, 'term') ? undefined : TermFromJSON(json['term']),
        'archivingReference': !exists(json, 'archivingReference') ? undefined : json['archivingReference'],
        'latestReview': !exists(json, 'latestReview') ? undefined : (json['latestReview'] === null ? null : new Date(json['latestReview'])),
        'nextReview': !exists(json, 'nextReview') ? undefined : (json['nextReview'] === null ? null : new Date(json['nextReview'])),
        'annualBudgetCostEstimation': !exists(json, 'annualBudgetCostEstimation') ? undefined : AmountFromJSON(json['annualBudgetCostEstimation']),
        'totalValueOfAgreement': !exists(json, 'totalValueOfAgreement') ? undefined : AmountFromJSON(json['totalValueOfAgreement']),
        'briefDescription': !exists(json, 'briefDescription') ? undefined : json['briefDescription'],
        'agreementType': !exists(json, 'agreementType') ? undefined : json['agreementType'],
    };
}

export function DetailToJSON(value?: Detail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'mainSupplier': DetailMainSupplierToJSON(value.mainSupplier),
        'partyToAgreement': value.partyToAgreement,
        'receiversOfProvidedService': value.receiversOfProvidedService,
        'contractOwner': value.contractOwner,
        'internalReference': value.internalReference,
        'governingLawOftheAgreement': value.governingLawOftheAgreement,
        'status': StatusToJSON(value.status),
        'term': TermToJSON(value.term),
        'archivingReference': value.archivingReference,
        'latestReview': value.latestReview === undefined ? undefined : (value.latestReview === null ? null : value.latestReview.toISOString().substr(0,10)),
        'nextReview': value.nextReview === undefined ? undefined : (value.nextReview === null ? null : value.nextReview.toISOString().substr(0,10)),
        'annualBudgetCostEstimation': AmountToJSON(value.annualBudgetCostEstimation),
        'totalValueOfAgreement': AmountToJSON(value.totalValueOfAgreement),
        'briefDescription': value.briefDescription,
        'agreementType': value.agreementType,
    };
}



/* DetailMainSupplier.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The main supplier could either be an external provider or an internal (an internalEntity).
 * @export
 * @interface DetailMainSupplier
 */
export interface DetailMainSupplier {
    /**
     * 
     * @type {boolean}
     * @memberof DetailMainSupplier
     */
    isExternal?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DetailMainSupplier
     */
    externalSupplier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DetailMainSupplier
     */
    internalSupplier?: string | null;
}

/**
 * Check if a given object implements the DetailMainSupplier interface.
 */
export function instanceOfDetailMainSupplier(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DetailMainSupplierFromJSON(json: any): DetailMainSupplier {
    return DetailMainSupplierFromJSONTyped(json, false);
}

export function DetailMainSupplierFromJSONTyped(json: any, ignoreDiscriminator: boolean): DetailMainSupplier {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isExternal': !exists(json, 'isExternal') ? undefined : json['isExternal'],
        'externalSupplier': !exists(json, 'externalSupplier') ? undefined : json['externalSupplier'],
        'internalSupplier': !exists(json, 'internalSupplier') ? undefined : json['internalSupplier'],
    };
}

export function DetailMainSupplierToJSON(value?: DetailMainSupplier | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isExternal': value.isExternal,
        'externalSupplier': value.externalSupplier,
        'internalSupplier': value.internalSupplier,
    };
}



/* FileAttachment.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface FileAttachment
 */
export interface FileAttachment {
    /**
     * 
     * @type {string}
     * @memberof FileAttachment
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FileAttachment
     */
    filename: string;
    /**
     * 
     * @type {Date}
     * @memberof FileAttachment
     */
    uploaded: Date;
    /**
     * 
     * @type {FileAttachmentSection}
     * @memberof FileAttachment
     */
    section: FileAttachmentSection;
}

/**
 * Check if a given object implements the FileAttachment interface.
 */
export function instanceOfFileAttachment(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "filename" in value;
    isInstance = isInstance && "uploaded" in value;
    isInstance = isInstance && "section" in value;

    return isInstance;
}

export function FileAttachmentFromJSON(json: any): FileAttachment {
    return FileAttachmentFromJSONTyped(json, false);
}

export function FileAttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileAttachment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'filename': json['filename'],
        'uploaded': (new Date(json['uploaded'])),
        'section': FileAttachmentSectionFromJSON(json['section']),
    };
}

export function FileAttachmentToJSON(value?: FileAttachment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'filename': value.filename,
        'uploaded': (value.uploaded.toISOString()),
        'section': FileAttachmentSectionToJSON(value.section),
    };
}



/* FileAttachmentSection.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const FileAttachmentSection = {
    Details: 'details',
    MainProvider: 'mainProvider',
    Npap: 'npap',
    PersonalData: 'personalData',
    ChangeManagementProcess: 'changeManagementProcess',
    CriticalOrImportantAssessment: 'criticalOrImportantAssessment',
    CriticalOrImportantAuthorityNotified: 'criticalOrImportantAuthorityNotified',
    CriticalOrImportantRiskAssesment: 'criticalOrImportantRiskAssesment',
    CriticalOrImportantAuditReport: 'criticalOrImportantAuditReport',
    CriticalOrImportantExitStrategy: 'criticalOrImportantExitStrategy',
    DueDiligence: 'dueDiligence',
    RiskAssessment: 'riskAssessment',
    CorporateSocialResponsibilitySurvey: 'corporateSocialResponsibilitySurvey',
    FollowCodeOfConduct: 'followCodeOfConduct',
    InformationSecurity: 'informationSecurity',
    Other: 'other'
} as const;
export type FileAttachmentSection = typeof FileAttachmentSection[keyof typeof FileAttachmentSection];


export function FileAttachmentSectionFromJSON(json: any): FileAttachmentSection {
    return FileAttachmentSectionFromJSONTyped(json, false);
}

export function FileAttachmentSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileAttachmentSection {
    return json as FileAttachmentSection;
}

export function FileAttachmentSectionToJSON(value?: FileAttachmentSection | null): any {
    return value as any;
}



/* FileInfoWithS3Url.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface FileInfoWithS3Url
 */
export interface FileInfoWithS3Url {
    /**
     * 
     * @type {string}
     * @memberof FileInfoWithS3Url
     */
    url: string;
}

/**
 * Check if a given object implements the FileInfoWithS3Url interface.
 */
export function instanceOfFileInfoWithS3Url(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "url" in value;

    return isInstance;
}

export function FileInfoWithS3UrlFromJSON(json: any): FileInfoWithS3Url {
    return FileInfoWithS3UrlFromJSONTyped(json, false);
}

export function FileInfoWithS3UrlFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileInfoWithS3Url {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': json['url'],
    };
}

export function FileInfoWithS3UrlToJSON(value?: FileInfoWithS3Url | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value.url,
    };
}



/* GetAgreementResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */











/**
 * 
 * @export
 * @interface GetAgreementResponse
 */
export interface GetAgreementResponse {
    /**
     * 
     * @type {Metadata}
     * @memberof GetAgreementResponse
     */
    metadata: Metadata;
    /**
     * 
     * @type {Agreement}
     * @memberof GetAgreementResponse
     */
    data?: Agreement;
    /**
     * 
     * @type {Array<AgreementActions>}
     * @memberof GetAgreementResponse
     */
    permissions: Array<AgreementActions>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetAgreementResponse
     */
    tags?: Array<string>;
    /**
     * 
     * @type {GetAgreementResponseRelations}
     * @memberof GetAgreementResponse
     */
    relations?: GetAgreementResponseRelations;
}

/**
 * Check if a given object implements the GetAgreementResponse interface.
 */
export function instanceOfGetAgreementResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "permissions" in value;

    return isInstance;
}

export function GetAgreementResponseFromJSON(json: any): GetAgreementResponse {
    return GetAgreementResponseFromJSONTyped(json, false);
}

export function GetAgreementResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAgreementResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataFromJSON(json['metadata']),
        'data': !exists(json, 'data') ? undefined : AgreementFromJSON(json['data']),
        'permissions': ((json['permissions'] as Array<any>).map(AgreementActionsFromJSON)),
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'relations': !exists(json, 'relations') ? undefined : GetAgreementResponseRelationsFromJSON(json['relations']),
    };
}

export function GetAgreementResponseToJSON(value?: GetAgreementResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataToJSON(value.metadata),
        'data': AgreementToJSON(value.data),
        'permissions': ((value.permissions as Array<any>).map(AgreementActionsToJSON)),
        'tags': value.tags,
        'relations': GetAgreementResponseRelationsToJSON(value.relations),
    };
}



/* GetAgreementResponseRelations.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface GetAgreementResponseRelations
 */
export interface GetAgreementResponseRelations {
    /**
     * 
     * @type {Array<GetAgreementResponseRelationsChildrenInner>}
     * @memberof GetAgreementResponseRelations
     */
    children?: Array<GetAgreementResponseRelationsChildrenInner>;
}

/**
 * Check if a given object implements the GetAgreementResponseRelations interface.
 */
export function instanceOfGetAgreementResponseRelations(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetAgreementResponseRelationsFromJSON(json: any): GetAgreementResponseRelations {
    return GetAgreementResponseRelationsFromJSONTyped(json, false);
}

export function GetAgreementResponseRelationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAgreementResponseRelations {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'children': !exists(json, 'children') ? undefined : ((json['children'] as Array<any>).map(GetAgreementResponseRelationsChildrenInnerFromJSON)),
    };
}

export function GetAgreementResponseRelationsToJSON(value?: GetAgreementResponseRelations | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'children': value.children === undefined ? undefined : ((value.children as Array<any>).map(GetAgreementResponseRelationsChildrenInnerToJSON)),
    };
}



/* GetAgreementResponseRelationsChildrenInner.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */







/**
 * 
 * @export
 * @interface GetAgreementResponseRelationsChildrenInner
 */
export interface GetAgreementResponseRelationsChildrenInner {
    /**
     * 
     * @type {string}
     * @memberof GetAgreementResponseRelationsChildrenInner
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAgreementResponseRelationsChildrenInner
     */
    name?: string;
    /**
     * 
     * @type {Category}
     * @memberof GetAgreementResponseRelationsChildrenInner
     */
    functionCategory?: Category;
    /**
     * 
     * @type {AgreementType}
     * @memberof GetAgreementResponseRelationsChildrenInner
     */
    agreementType?: AgreementType;
}

/**
 * Check if a given object implements the GetAgreementResponseRelationsChildrenInner interface.
 */
export function instanceOfGetAgreementResponseRelationsChildrenInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetAgreementResponseRelationsChildrenInnerFromJSON(json: any): GetAgreementResponseRelationsChildrenInner {
    return GetAgreementResponseRelationsChildrenInnerFromJSONTyped(json, false);
}

export function GetAgreementResponseRelationsChildrenInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAgreementResponseRelationsChildrenInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'functionCategory': !exists(json, 'functionCategory') ? undefined : CategoryFromJSON(json['functionCategory']),
        'agreementType': !exists(json, 'agreementType') ? undefined : AgreementTypeFromJSON(json['agreementType']),
    };
}

export function GetAgreementResponseRelationsChildrenInnerToJSON(value?: GetAgreementResponseRelationsChildrenInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'functionCategory': CategoryToJSON(value.functionCategory),
        'agreementType': AgreementTypeToJSON(value.agreementType),
    };
}



/* GetAgreementTypeResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */









/**
 * 
 * @export
 * @interface GetAgreementTypeResponse
 */
export interface GetAgreementTypeResponse {
    /**
     * 
     * @type {Metadata}
     * @memberof GetAgreementTypeResponse
     */
    metadata: Metadata;
    /**
     * 
     * @type {AgreementType}
     * @memberof GetAgreementTypeResponse
     */
    data?: AgreementType;
    /**
     * 
     * @type {Array<AgreementTypeActions>}
     * @memberof GetAgreementTypeResponse
     */
    permissions: Array<AgreementTypeActions>;
}

/**
 * Check if a given object implements the GetAgreementTypeResponse interface.
 */
export function instanceOfGetAgreementTypeResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "permissions" in value;

    return isInstance;
}

export function GetAgreementTypeResponseFromJSON(json: any): GetAgreementTypeResponse {
    return GetAgreementTypeResponseFromJSONTyped(json, false);
}

export function GetAgreementTypeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAgreementTypeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataFromJSON(json['metadata']),
        'data': !exists(json, 'data') ? undefined : AgreementTypeFromJSON(json['data']),
        'permissions': ((json['permissions'] as Array<any>).map(AgreementTypeActionsFromJSON)),
    };
}

export function GetAgreementTypeResponseToJSON(value?: GetAgreementTypeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataToJSON(value.metadata),
        'data': AgreementTypeToJSON(value.data),
        'permissions': ((value.permissions as Array<any>).map(AgreementTypeActionsToJSON)),
    };
}



/* GetAgreementVersionResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */









/**
 * 
 * @export
 * @interface GetAgreementVersionResponse
 */
export interface GetAgreementVersionResponse {
    /**
     * 
     * @type {Metadata}
     * @memberof GetAgreementVersionResponse
     */
    metadata: Metadata;
    /**
     * 
     * @type {Agreement}
     * @memberof GetAgreementVersionResponse
     */
    data?: Agreement;
    /**
     * 
     * @type {Array<AgreementActions>}
     * @memberof GetAgreementVersionResponse
     */
    permissions: Array<AgreementActions>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetAgreementVersionResponse
     */
    tags?: Array<string>;
}

/**
 * Check if a given object implements the GetAgreementVersionResponse interface.
 */
export function instanceOfGetAgreementVersionResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "permissions" in value;

    return isInstance;
}

export function GetAgreementVersionResponseFromJSON(json: any): GetAgreementVersionResponse {
    return GetAgreementVersionResponseFromJSONTyped(json, false);
}

export function GetAgreementVersionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAgreementVersionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataFromJSON(json['metadata']),
        'data': !exists(json, 'data') ? undefined : AgreementFromJSON(json['data']),
        'permissions': ((json['permissions'] as Array<any>).map(AgreementActionsFromJSON)),
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
    };
}

export function GetAgreementVersionResponseToJSON(value?: GetAgreementVersionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataToJSON(value.metadata),
        'data': AgreementToJSON(value.data),
        'permissions': ((value.permissions as Array<any>).map(AgreementActionsToJSON)),
        'tags': value.tags,
    };
}



/* GetCabinetResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */







/**
 * 
 * @export
 * @interface GetCabinetResponse
 */
export interface GetCabinetResponse {
    /**
     * 
     * @type {Cabinet}
     * @memberof GetCabinetResponse
     */
    data?: Cabinet;
    /**
     * 
     * @type {Array<CabinetActions>}
     * @memberof GetCabinetResponse
     */
    permissions: Array<CabinetActions>;
}

/**
 * Check if a given object implements the GetCabinetResponse interface.
 */
export function instanceOfGetCabinetResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "permissions" in value;

    return isInstance;
}

export function GetCabinetResponseFromJSON(json: any): GetCabinetResponse {
    return GetCabinetResponseFromJSONTyped(json, false);
}

export function GetCabinetResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCabinetResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : CabinetFromJSON(json['data']),
        'permissions': ((json['permissions'] as Array<any>).map(CabinetActionsFromJSON)),
    };
}

export function GetCabinetResponseToJSON(value?: GetCabinetResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': CabinetToJSON(value.data),
        'permissions': ((value.permissions as Array<any>).map(CabinetActionsToJSON)),
    };
}



/* GetCalendarEventsResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface GetCalendarEventsResponse
 */
export interface GetCalendarEventsResponse {
    /**
     * 
     * @type {Array<CalendarEvent>}
     * @memberof GetCalendarEventsResponse
     */
    result?: Array<CalendarEvent>;
}

/**
 * Check if a given object implements the GetCalendarEventsResponse interface.
 */
export function instanceOfGetCalendarEventsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetCalendarEventsResponseFromJSON(json: any): GetCalendarEventsResponse {
    return GetCalendarEventsResponseFromJSONTyped(json, false);
}

export function GetCalendarEventsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCalendarEventsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : ((json['result'] as Array<any>).map(CalendarEventFromJSON)),
    };
}

export function GetCalendarEventsResponseToJSON(value?: GetCalendarEventsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': value.result === undefined ? undefined : ((value.result as Array<any>).map(CalendarEventToJSON)),
    };
}



/* GetCategoryResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */









/**
 * 
 * @export
 * @interface GetCategoryResponse
 */
export interface GetCategoryResponse {
    /**
     * 
     * @type {Metadata}
     * @memberof GetCategoryResponse
     */
    metadata: Metadata;
    /**
     * 
     * @type {Category}
     * @memberof GetCategoryResponse
     */
    data?: Category;
    /**
     * 
     * @type {Array<CategoryActions>}
     * @memberof GetCategoryResponse
     */
    permissions: Array<CategoryActions>;
}

/**
 * Check if a given object implements the GetCategoryResponse interface.
 */
export function instanceOfGetCategoryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "permissions" in value;

    return isInstance;
}

export function GetCategoryResponseFromJSON(json: any): GetCategoryResponse {
    return GetCategoryResponseFromJSONTyped(json, false);
}

export function GetCategoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCategoryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataFromJSON(json['metadata']),
        'data': !exists(json, 'data') ? undefined : CategoryFromJSON(json['data']),
        'permissions': ((json['permissions'] as Array<any>).map(CategoryActionsFromJSON)),
    };
}

export function GetCategoryResponseToJSON(value?: GetCategoryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataToJSON(value.metadata),
        'data': CategoryToJSON(value.data),
        'permissions': ((value.permissions as Array<any>).map(CategoryActionsToJSON)),
    };
}



/* GetInternalEntityResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */









/**
 * 
 * @export
 * @interface GetInternalEntityResponse
 */
export interface GetInternalEntityResponse {
    /**
     * 
     * @type {Metadata}
     * @memberof GetInternalEntityResponse
     */
    metadata: Metadata;
    /**
     * 
     * @type {InternalEntity}
     * @memberof GetInternalEntityResponse
     */
    data?: InternalEntity;
    /**
     * 
     * @type {Array<InternalEntityActions>}
     * @memberof GetInternalEntityResponse
     */
    permissions: Array<InternalEntityActions>;
}

/**
 * Check if a given object implements the GetInternalEntityResponse interface.
 */
export function instanceOfGetInternalEntityResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "permissions" in value;

    return isInstance;
}

export function GetInternalEntityResponseFromJSON(json: any): GetInternalEntityResponse {
    return GetInternalEntityResponseFromJSONTyped(json, false);
}

export function GetInternalEntityResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetInternalEntityResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataFromJSON(json['metadata']),
        'data': !exists(json, 'data') ? undefined : InternalEntityFromJSON(json['data']),
        'permissions': ((json['permissions'] as Array<any>).map(InternalEntityActionsFromJSON)),
    };
}

export function GetInternalEntityResponseToJSON(value?: GetInternalEntityResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataToJSON(value.metadata),
        'data': InternalEntityToJSON(value.data),
        'permissions': ((value.permissions as Array<any>).map(InternalEntityActionsToJSON)),
    };
}



/* GetPersonalSettingsTokenResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface GetPersonalSettingsTokenResponse
 */
export interface GetPersonalSettingsTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof GetPersonalSettingsTokenResponse
     */
    token: string;
    /**
     * 
     * @type {Date}
     * @memberof GetPersonalSettingsTokenResponse
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetPersonalSettingsTokenResponse
     */
    lastUsed?: Date;
}

/**
 * Check if a given object implements the GetPersonalSettingsTokenResponse interface.
 */
export function instanceOfGetPersonalSettingsTokenResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "token" in value;
    isInstance = isInstance && "created" in value;

    return isInstance;
}

export function GetPersonalSettingsTokenResponseFromJSON(json: any): GetPersonalSettingsTokenResponse {
    return GetPersonalSettingsTokenResponseFromJSONTyped(json, false);
}

export function GetPersonalSettingsTokenResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPersonalSettingsTokenResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
        'created': (new Date(json['created'])),
        'lastUsed': !exists(json, 'lastUsed') ? undefined : (new Date(json['lastUsed'])),
    };
}

export function GetPersonalSettingsTokenResponseToJSON(value?: GetPersonalSettingsTokenResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'created': (value.created.toISOString()),
        'lastUsed': value.lastUsed === undefined ? undefined : (value.lastUsed.toISOString()),
    };
}



/* GetReportResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */









/**
 * 
 * @export
 * @interface GetReportResponse
 */
export interface GetReportResponse {
    /**
     * 
     * @type {Metadata}
     * @memberof GetReportResponse
     */
    metadata: Metadata;
    /**
     * 
     * @type {Report}
     * @memberof GetReportResponse
     */
    data?: Report;
    /**
     * 
     * @type {Array<ReportActions>}
     * @memberof GetReportResponse
     */
    permissions: Array<ReportActions>;
}

/**
 * Check if a given object implements the GetReportResponse interface.
 */
export function instanceOfGetReportResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "permissions" in value;

    return isInstance;
}

export function GetReportResponseFromJSON(json: any): GetReportResponse {
    return GetReportResponseFromJSONTyped(json, false);
}

export function GetReportResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetReportResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataFromJSON(json['metadata']),
        'data': !exists(json, 'data') ? undefined : ReportFromJSON(json['data']),
        'permissions': ((json['permissions'] as Array<any>).map(ReportActionsFromJSON)),
    };
}

export function GetReportResponseToJSON(value?: GetReportResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataToJSON(value.metadata),
        'data': ReportToJSON(value.data),
        'permissions': ((value.permissions as Array<any>).map(ReportActionsToJSON)),
    };
}



/* GetSupplierResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */









/**
 * 
 * @export
 * @interface GetSupplierResponse
 */
export interface GetSupplierResponse {
    /**
     * 
     * @type {Metadata}
     * @memberof GetSupplierResponse
     */
    metadata: Metadata;
    /**
     * 
     * @type {Supplier}
     * @memberof GetSupplierResponse
     */
    data?: Supplier;
    /**
     * 
     * @type {Array<SupplierActions>}
     * @memberof GetSupplierResponse
     */
    permissions: Array<SupplierActions>;
}

/**
 * Check if a given object implements the GetSupplierResponse interface.
 */
export function instanceOfGetSupplierResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "permissions" in value;

    return isInstance;
}

export function GetSupplierResponseFromJSON(json: any): GetSupplierResponse {
    return GetSupplierResponseFromJSONTyped(json, false);
}

export function GetSupplierResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSupplierResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataFromJSON(json['metadata']),
        'data': !exists(json, 'data') ? undefined : SupplierFromJSON(json['data']),
        'permissions': ((json['permissions'] as Array<any>).map(SupplierActionsFromJSON)),
    };
}

export function GetSupplierResponseToJSON(value?: GetSupplierResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataToJSON(value.metadata),
        'data': SupplierToJSON(value.data),
        'permissions': ((value.permissions as Array<any>).map(SupplierActionsToJSON)),
    };
}



/* GetSupplierVersionResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */









/**
 * 
 * @export
 * @interface GetSupplierVersionResponse
 */
export interface GetSupplierVersionResponse {
    /**
     * 
     * @type {Metadata}
     * @memberof GetSupplierVersionResponse
     */
    metadata: Metadata;
    /**
     * 
     * @type {Supplier}
     * @memberof GetSupplierVersionResponse
     */
    data?: Supplier;
    /**
     * 
     * @type {Array<SupplierActions>}
     * @memberof GetSupplierVersionResponse
     */
    permissions: Array<SupplierActions>;
}

/**
 * Check if a given object implements the GetSupplierVersionResponse interface.
 */
export function instanceOfGetSupplierVersionResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "permissions" in value;

    return isInstance;
}

export function GetSupplierVersionResponseFromJSON(json: any): GetSupplierVersionResponse {
    return GetSupplierVersionResponseFromJSONTyped(json, false);
}

export function GetSupplierVersionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSupplierVersionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataFromJSON(json['metadata']),
        'data': !exists(json, 'data') ? undefined : SupplierFromJSON(json['data']),
        'permissions': ((json['permissions'] as Array<any>).map(SupplierActionsFromJSON)),
    };
}

export function GetSupplierVersionResponseToJSON(value?: GetSupplierVersionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataToJSON(value.metadata),
        'data': SupplierToJSON(value.data),
        'permissions': ((value.permissions as Array<any>).map(SupplierActionsToJSON)),
    };
}



/* GetTagResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */







/**
 * 
 * @export
 * @interface GetTagResponse
 */
export interface GetTagResponse {
    /**
     * 
     * @type {Tag}
     * @memberof GetTagResponse
     */
    data?: Tag;
    /**
     * 
     * @type {Array<TagActions>}
     * @memberof GetTagResponse
     */
    permissions: Array<TagActions>;
}

/**
 * Check if a given object implements the GetTagResponse interface.
 */
export function instanceOfGetTagResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "permissions" in value;

    return isInstance;
}

export function GetTagResponseFromJSON(json: any): GetTagResponse {
    return GetTagResponseFromJSONTyped(json, false);
}

export function GetTagResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTagResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : TagFromJSON(json['data']),
        'permissions': ((json['permissions'] as Array<any>).map(TagActionsFromJSON)),
    };
}

export function GetTagResponseToJSON(value?: GetTagResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': TagToJSON(value.data),
        'permissions': ((value.permissions as Array<any>).map(TagActionsToJSON)),
    };
}



/* GlobalActions.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const GlobalActions = {
    AgreementAdd: 'oasys:AgreementAdd',
    AgreementList: 'oasys:AgreementList',
    AgreementTypeAdd: 'oasys:AgreementTypeAdd',
    AgreementTypeList: 'oasys:AgreementTypeList',
    CabinetAdd: 'oasys:CabinetAdd',
    CabinetList: 'oasys:CabinetList',
    CategoryAdd: 'oasys:CategoryAdd',
    InternalEntityAdd: 'oasys:InternalEntityAdd',
    InternalEntityList: 'oasys:InternalEntityList',
    SupplierAdd: 'oasys:SupplierAdd',
    SupplierList: 'oasys:SupplierList',
    ReportAdd: 'oasys:ReportAdd',
    ReportList: 'oasys:ReportList',
    TokenEdit: 'oasys:TokenEdit',
    TokenGet: 'oasys:TokenGet',
    ViewMyAgreements: 'oasys:ViewMyAgreements',
    ViewMyAgreementTypes: 'oasys:ViewMyAgreementTypes',
    ViewMyDashboard: 'oasys:ViewMyDashboard',
    ViewMyCabinets: 'oasys:ViewMyCabinets',
    ViewMyCalendar: 'oasys:ViewMyCalendar',
    ViewMyCategories: 'oasys:ViewMyCategories',
    ViewMyInternalEntities: 'oasys:ViewMyInternalEntities',
    ViewMyReports: 'oasys:ViewMyReports',
    ViewMySettings: 'oasys:ViewMySettings',
    ViewMySuppliers: 'oasys:ViewMySuppliers',
    ViewMyTags: 'oasys:ViewMyTags'
} as const;
export type GlobalActions = typeof GlobalActions[keyof typeof GlobalActions];


export function GlobalActionsFromJSON(json: any): GlobalActions {
    return GlobalActionsFromJSONTyped(json, false);
}

export function GlobalActionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GlobalActions {
    return json as GlobalActions;
}

export function GlobalActionsToJSON(value?: GlobalActions | null): any {
    return value as any;
}



/* GlobalSearchResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface GlobalSearchResponse
 */
export interface GlobalSearchResponse {
    /**
     * 
     * @type {Array<GlobalSearchResult>}
     * @memberof GlobalSearchResponse
     */
    result?: Array<GlobalSearchResult>;
}

/**
 * Check if a given object implements the GlobalSearchResponse interface.
 */
export function instanceOfGlobalSearchResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GlobalSearchResponseFromJSON(json: any): GlobalSearchResponse {
    return GlobalSearchResponseFromJSONTyped(json, false);
}

export function GlobalSearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GlobalSearchResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : ((json['result'] as Array<any>).map(GlobalSearchResultFromJSON)),
    };
}

export function GlobalSearchResponseToJSON(value?: GlobalSearchResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': value.result === undefined ? undefined : ((value.result as Array<any>).map(GlobalSearchResultToJSON)),
    };
}



/* GlobalSearchResult.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */






/**
 * @type GlobalSearchResult
 * 
 * @export
 */
export type GlobalSearchResult = AgreementAttachmentSearchResult | AgreementSearchResult | SupplierAttachmentSearchResult | SupplierSearchResult;

export function GlobalSearchResultFromJSON(json: any): GlobalSearchResult {
    return GlobalSearchResultFromJSONTyped(json, false);
}

export function GlobalSearchResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): GlobalSearchResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...AgreementAttachmentSearchResultFromJSONTyped(json, true), ...AgreementSearchResultFromJSONTyped(json, true), ...SupplierAttachmentSearchResultFromJSONTyped(json, true), ...SupplierSearchResultFromJSONTyped(json, true) };
}

export function GlobalSearchResultToJSON(value?: GlobalSearchResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfAgreementAttachmentSearchResult(value)) {
        return AgreementAttachmentSearchResultToJSON(value as AgreementAttachmentSearchResult);
    }
    if (instanceOfAgreementSearchResult(value)) {
        return AgreementSearchResultToJSON(value as AgreementSearchResult);
    }
    if (instanceOfSupplierAttachmentSearchResult(value)) {
        return SupplierAttachmentSearchResultToJSON(value as SupplierAttachmentSearchResult);
    }
    if (instanceOfSupplierSearchResult(value)) {
        return SupplierSearchResultToJSON(value as SupplierSearchResult);
    }

    return {};
}



/* GlobalSearchResultType.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const GlobalSearchResultType = {
    Agreement: 'agreement',
    Supplier: 'supplier',
    SupplierAttachment: 'supplierAttachment',
    AgreementAttachment: 'agreementAttachment'
} as const;
export type GlobalSearchResultType = typeof GlobalSearchResultType[keyof typeof GlobalSearchResultType];


export function GlobalSearchResultTypeFromJSON(json: any): GlobalSearchResultType {
    return GlobalSearchResultTypeFromJSONTyped(json, false);
}

export function GlobalSearchResultTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): GlobalSearchResultType {
    return json as GlobalSearchResultType;
}

export function GlobalSearchResultTypeToJSON(value?: GlobalSearchResultType | null): any {
    return value as any;
}



/* InitData.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */











/**
 * 
 * @export
 * @interface InitData
 */
export interface InitData {
    /**
     * 
     * @type {Array<Country>}
     * @memberof InitData
     */
    countries: Array<Country>;
    /**
     * 
     * @type {Array<Region>}
     * @memberof InitData
     */
    regions: Array<Region>;
    /**
     * 
     * @type {Array<Currency>}
     * @memberof InitData
     */
    currencies: Array<Currency>;
    /**
     * 
     * @type {Array<GlobalActions>}
     * @memberof InitData
     */
    permissions: Array<GlobalActions>;
}

/**
 * Check if a given object implements the InitData interface.
 */
export function instanceOfInitData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "countries" in value;
    isInstance = isInstance && "regions" in value;
    isInstance = isInstance && "currencies" in value;
    isInstance = isInstance && "permissions" in value;

    return isInstance;
}

export function InitDataFromJSON(json: any): InitData {
    return InitDataFromJSONTyped(json, false);
}

export function InitDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): InitData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'countries': ((json['countries'] as Array<any>).map(CountryFromJSON)),
        'regions': ((json['regions'] as Array<any>).map(RegionFromJSON)),
        'currencies': ((json['currencies'] as Array<any>).map(CurrencyFromJSON)),
        'permissions': ((json['permissions'] as Array<any>).map(GlobalActionsFromJSON)),
    };
}

export function InitDataToJSON(value?: InitData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'countries': ((value.countries as Array<any>).map(CountryToJSON)),
        'regions': ((value.regions as Array<any>).map(RegionToJSON)),
        'currencies': ((value.currencies as Array<any>).map(CurrencyToJSON)),
        'permissions': ((value.permissions as Array<any>).map(GlobalActionsToJSON)),
    };
}



/* InternalEntity.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface InternalEntity
 */
export interface InternalEntity {
    /**
     * 
     * @type {string}
     * @memberof InternalEntity
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof InternalEntity
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof InternalEntity
     */
    corporateRegistrationNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof InternalEntity
     */
    legalEntityIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof InternalEntity
     */
    countryOfRegistration?: string;
    /**
     * 
     * @type {string}
     * @memberof InternalEntity
     */
    registeredAddress?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InternalEntity
     */
    contactDetails?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof InternalEntity
     */
    isUltimateParent?: boolean;
    /**
     * Enabled if ultimateParent is false
     * @type {string}
     * @memberof InternalEntity
     */
    parentCompany?: string;
}

/**
 * Check if a given object implements the InternalEntity interface.
 */
export function instanceOfInternalEntity(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function InternalEntityFromJSON(json: any): InternalEntity {
    return InternalEntityFromJSONTyped(json, false);
}

export function InternalEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'corporateRegistrationNumber': !exists(json, 'corporateRegistrationNumber') ? undefined : json['corporateRegistrationNumber'],
        'legalEntityIdentifier': !exists(json, 'legalEntityIdentifier') ? undefined : json['legalEntityIdentifier'],
        'countryOfRegistration': !exists(json, 'countryOfRegistration') ? undefined : json['countryOfRegistration'],
        'registeredAddress': !exists(json, 'registeredAddress') ? undefined : json['registeredAddress'],
        'contactDetails': !exists(json, 'contactDetails') ? undefined : json['contactDetails'],
        'isUltimateParent': !exists(json, 'isUltimateParent') ? undefined : json['isUltimateParent'],
        'parentCompany': !exists(json, 'parentCompany') ? undefined : json['parentCompany'],
    };
}

export function InternalEntityToJSON(value?: InternalEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'corporateRegistrationNumber': value.corporateRegistrationNumber,
        'legalEntityIdentifier': value.legalEntityIdentifier,
        'countryOfRegistration': value.countryOfRegistration,
        'registeredAddress': value.registeredAddress,
        'contactDetails': value.contactDetails,
        'isUltimateParent': value.isUltimateParent,
        'parentCompany': value.parentCompany,
    };
}



/* InternalEntityActions.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const InternalEntityActions = {
    InternalEntityDelete: 'oasys:InternalEntityDelete',
    InternalEntityEdit: 'oasys:InternalEntityEdit',
    InternalEntityGet: 'oasys:InternalEntityGet'
} as const;
export type InternalEntityActions = typeof InternalEntityActions[keyof typeof InternalEntityActions];


export function InternalEntityActionsFromJSON(json: any): InternalEntityActions {
    return InternalEntityActionsFromJSONTyped(json, false);
}

export function InternalEntityActionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalEntityActions {
    return json as InternalEntityActions;
}

export function InternalEntityActionsToJSON(value?: InternalEntityActions | null): any {
    return value as any;
}



/* InternalEntityListItem.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface InternalEntityListItem
 */
export interface InternalEntityListItem {
    /**
     * 
     * @type {Metadata}
     * @memberof InternalEntityListItem
     */
    metadata: Metadata;
    /**
     * 
     * @type {string}
     * @memberof InternalEntityListItem
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof InternalEntityListItem
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof InternalEntityListItem
     */
    corporateRegistrationNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof InternalEntityListItem
     */
    legalEntityIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof InternalEntityListItem
     */
    countryOfRegistration?: string;
    /**
     * 
     * @type {string}
     * @memberof InternalEntityListItem
     */
    registeredAddress?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InternalEntityListItem
     */
    contactDetails?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof InternalEntityListItem
     */
    isUltimateParent?: boolean;
    /**
     * Enabled if ultimateParent is false
     * @type {string}
     * @memberof InternalEntityListItem
     */
    parentCompany?: string;
}

/**
 * Check if a given object implements the InternalEntityListItem interface.
 */
export function instanceOfInternalEntityListItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function InternalEntityListItemFromJSON(json: any): InternalEntityListItem {
    return InternalEntityListItemFromJSONTyped(json, false);
}

export function InternalEntityListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalEntityListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataFromJSON(json['metadata']),
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'corporateRegistrationNumber': !exists(json, 'corporateRegistrationNumber') ? undefined : json['corporateRegistrationNumber'],
        'legalEntityIdentifier': !exists(json, 'legalEntityIdentifier') ? undefined : json['legalEntityIdentifier'],
        'countryOfRegistration': !exists(json, 'countryOfRegistration') ? undefined : json['countryOfRegistration'],
        'registeredAddress': !exists(json, 'registeredAddress') ? undefined : json['registeredAddress'],
        'contactDetails': !exists(json, 'contactDetails') ? undefined : json['contactDetails'],
        'isUltimateParent': !exists(json, 'isUltimateParent') ? undefined : json['isUltimateParent'],
        'parentCompany': !exists(json, 'parentCompany') ? undefined : json['parentCompany'],
    };
}

export function InternalEntityListItemToJSON(value?: InternalEntityListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataToJSON(value.metadata),
        'name': value.name,
        'description': value.description,
        'corporateRegistrationNumber': value.corporateRegistrationNumber,
        'legalEntityIdentifier': value.legalEntityIdentifier,
        'countryOfRegistration': value.countryOfRegistration,
        'registeredAddress': value.registeredAddress,
        'contactDetails': value.contactDetails,
        'isUltimateParent': value.isUltimateParent,
        'parentCompany': value.parentCompany,
    };
}



/* ListAllAgreementAttachmentsResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface ListAllAgreementAttachmentsResponse
 */
export interface ListAllAgreementAttachmentsResponse {
    /**
     * 
     * @type {Array<FileAttachment>}
     * @memberof ListAllAgreementAttachmentsResponse
     */
    results?: Array<FileAttachment>;
}

/**
 * Check if a given object implements the ListAllAgreementAttachmentsResponse interface.
 */
export function instanceOfListAllAgreementAttachmentsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListAllAgreementAttachmentsResponseFromJSON(json: any): ListAllAgreementAttachmentsResponse {
    return ListAllAgreementAttachmentsResponseFromJSONTyped(json, false);
}

export function ListAllAgreementAttachmentsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListAllAgreementAttachmentsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(FileAttachmentFromJSON)),
    };
}

export function ListAllAgreementAttachmentsResponseToJSON(value?: ListAllAgreementAttachmentsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(FileAttachmentToJSON)),
    };
}



/* ListAllAgreementTagsResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface ListAllAgreementTagsResponse
 */
export interface ListAllAgreementTagsResponse {
    /**
     * 
     * @type {Array<Tag>}
     * @memberof ListAllAgreementTagsResponse
     */
    results?: Array<Tag>;
}

/**
 * Check if a given object implements the ListAllAgreementTagsResponse interface.
 */
export function instanceOfListAllAgreementTagsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListAllAgreementTagsResponseFromJSON(json: any): ListAllAgreementTagsResponse {
    return ListAllAgreementTagsResponseFromJSONTyped(json, false);
}

export function ListAllAgreementTagsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListAllAgreementTagsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(TagFromJSON)),
    };
}

export function ListAllAgreementTagsResponseToJSON(value?: ListAllAgreementTagsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(TagToJSON)),
    };
}



/* ListAllAgreementTypesResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface ListAllAgreementTypesResponse
 */
export interface ListAllAgreementTypesResponse {
    /**
     * 
     * @type {Array<AgreementTypeListItem>}
     * @memberof ListAllAgreementTypesResponse
     */
    result?: Array<AgreementTypeListItem>;
}

/**
 * Check if a given object implements the ListAllAgreementTypesResponse interface.
 */
export function instanceOfListAllAgreementTypesResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListAllAgreementTypesResponseFromJSON(json: any): ListAllAgreementTypesResponse {
    return ListAllAgreementTypesResponseFromJSONTyped(json, false);
}

export function ListAllAgreementTypesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListAllAgreementTypesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : ((json['result'] as Array<any>).map(AgreementTypeListItemFromJSON)),
    };
}

export function ListAllAgreementTypesResponseToJSON(value?: ListAllAgreementTypesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': value.result === undefined ? undefined : ((value.result as Array<any>).map(AgreementTypeListItemToJSON)),
    };
}



/* ListAllAgreementVersionsResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface ListAllAgreementVersionsResponse
 */
export interface ListAllAgreementVersionsResponse {
    /**
     * 
     * @type {Array<AgreementVersionListItem>}
     * @memberof ListAllAgreementVersionsResponse
     */
    result?: Array<AgreementVersionListItem>;
}

/**
 * Check if a given object implements the ListAllAgreementVersionsResponse interface.
 */
export function instanceOfListAllAgreementVersionsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListAllAgreementVersionsResponseFromJSON(json: any): ListAllAgreementVersionsResponse {
    return ListAllAgreementVersionsResponseFromJSONTyped(json, false);
}

export function ListAllAgreementVersionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListAllAgreementVersionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : ((json['result'] as Array<any>).map(AgreementVersionListItemFromJSON)),
    };
}

export function ListAllAgreementVersionsResponseToJSON(value?: ListAllAgreementVersionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': value.result === undefined ? undefined : ((value.result as Array<any>).map(AgreementVersionListItemToJSON)),
    };
}



/* ListAllAgreementsResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface ListAllAgreementsResponse
 */
export interface ListAllAgreementsResponse {
    /**
     * 
     * @type {Array<AgreementListItem>}
     * @memberof ListAllAgreementsResponse
     */
    result?: Array<AgreementListItem>;
}

/**
 * Check if a given object implements the ListAllAgreementsResponse interface.
 */
export function instanceOfListAllAgreementsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListAllAgreementsResponseFromJSON(json: any): ListAllAgreementsResponse {
    return ListAllAgreementsResponseFromJSONTyped(json, false);
}

export function ListAllAgreementsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListAllAgreementsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : ((json['result'] as Array<any>).map(AgreementListItemFromJSON)),
    };
}

export function ListAllAgreementsResponseToJSON(value?: ListAllAgreementsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': value.result === undefined ? undefined : ((value.result as Array<any>).map(AgreementListItemToJSON)),
    };
}



/* ListAllCabinetsResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface ListAllCabinetsResponse
 */
export interface ListAllCabinetsResponse {
    /**
     * 
     * @type {Array<Cabinet>}
     * @memberof ListAllCabinetsResponse
     */
    result?: Array<Cabinet>;
}

/**
 * Check if a given object implements the ListAllCabinetsResponse interface.
 */
export function instanceOfListAllCabinetsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListAllCabinetsResponseFromJSON(json: any): ListAllCabinetsResponse {
    return ListAllCabinetsResponseFromJSONTyped(json, false);
}

export function ListAllCabinetsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListAllCabinetsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : ((json['result'] as Array<any>).map(CabinetFromJSON)),
    };
}

export function ListAllCabinetsResponseToJSON(value?: ListAllCabinetsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': value.result === undefined ? undefined : ((value.result as Array<any>).map(CabinetToJSON)),
    };
}



/* ListAllCategoriesResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface ListAllCategoriesResponse
 */
export interface ListAllCategoriesResponse {
    /**
     * 
     * @type {Array<CategoryListItem>}
     * @memberof ListAllCategoriesResponse
     */
    result?: Array<CategoryListItem>;
}

/**
 * Check if a given object implements the ListAllCategoriesResponse interface.
 */
export function instanceOfListAllCategoriesResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListAllCategoriesResponseFromJSON(json: any): ListAllCategoriesResponse {
    return ListAllCategoriesResponseFromJSONTyped(json, false);
}

export function ListAllCategoriesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListAllCategoriesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : ((json['result'] as Array<any>).map(CategoryListItemFromJSON)),
    };
}

export function ListAllCategoriesResponseToJSON(value?: ListAllCategoriesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': value.result === undefined ? undefined : ((value.result as Array<any>).map(CategoryListItemToJSON)),
    };
}



/* ListAllInternalEntitiesResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface ListAllInternalEntitiesResponse
 */
export interface ListAllInternalEntitiesResponse {
    /**
     * 
     * @type {Array<InternalEntityListItem>}
     * @memberof ListAllInternalEntitiesResponse
     */
    result?: Array<InternalEntityListItem>;
}

/**
 * Check if a given object implements the ListAllInternalEntitiesResponse interface.
 */
export function instanceOfListAllInternalEntitiesResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListAllInternalEntitiesResponseFromJSON(json: any): ListAllInternalEntitiesResponse {
    return ListAllInternalEntitiesResponseFromJSONTyped(json, false);
}

export function ListAllInternalEntitiesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListAllInternalEntitiesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : ((json['result'] as Array<any>).map(InternalEntityListItemFromJSON)),
    };
}

export function ListAllInternalEntitiesResponseToJSON(value?: ListAllInternalEntitiesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': value.result === undefined ? undefined : ((value.result as Array<any>).map(InternalEntityListItemToJSON)),
    };
}



/* ListAllReportsResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface ListAllReportsResponse
 */
export interface ListAllReportsResponse {
    /**
     * 
     * @type {Array<ReportListItem>}
     * @memberof ListAllReportsResponse
     */
    result?: Array<ReportListItem>;
}

/**
 * Check if a given object implements the ListAllReportsResponse interface.
 */
export function instanceOfListAllReportsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListAllReportsResponseFromJSON(json: any): ListAllReportsResponse {
    return ListAllReportsResponseFromJSONTyped(json, false);
}

export function ListAllReportsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListAllReportsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : ((json['result'] as Array<any>).map(ReportListItemFromJSON)),
    };
}

export function ListAllReportsResponseToJSON(value?: ListAllReportsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': value.result === undefined ? undefined : ((value.result as Array<any>).map(ReportListItemToJSON)),
    };
}



/* ListAllSupplierAttachmentsResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface ListAllSupplierAttachmentsResponse
 */
export interface ListAllSupplierAttachmentsResponse {
    /**
     * 
     * @type {Array<SupplierFileAttachment>}
     * @memberof ListAllSupplierAttachmentsResponse
     */
    results?: Array<SupplierFileAttachment>;
}

/**
 * Check if a given object implements the ListAllSupplierAttachmentsResponse interface.
 */
export function instanceOfListAllSupplierAttachmentsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListAllSupplierAttachmentsResponseFromJSON(json: any): ListAllSupplierAttachmentsResponse {
    return ListAllSupplierAttachmentsResponseFromJSONTyped(json, false);
}

export function ListAllSupplierAttachmentsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListAllSupplierAttachmentsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(SupplierFileAttachmentFromJSON)),
    };
}

export function ListAllSupplierAttachmentsResponseToJSON(value?: ListAllSupplierAttachmentsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(SupplierFileAttachmentToJSON)),
    };
}



/* ListAllSupplierVersionsResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface ListAllSupplierVersionsResponse
 */
export interface ListAllSupplierVersionsResponse {
    /**
     * 
     * @type {Array<SupplierVersionListItem>}
     * @memberof ListAllSupplierVersionsResponse
     */
    result?: Array<SupplierVersionListItem>;
}

/**
 * Check if a given object implements the ListAllSupplierVersionsResponse interface.
 */
export function instanceOfListAllSupplierVersionsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListAllSupplierVersionsResponseFromJSON(json: any): ListAllSupplierVersionsResponse {
    return ListAllSupplierVersionsResponseFromJSONTyped(json, false);
}

export function ListAllSupplierVersionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListAllSupplierVersionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : ((json['result'] as Array<any>).map(SupplierVersionListItemFromJSON)),
    };
}

export function ListAllSupplierVersionsResponseToJSON(value?: ListAllSupplierVersionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': value.result === undefined ? undefined : ((value.result as Array<any>).map(SupplierVersionListItemToJSON)),
    };
}



/* ListAllSuppliersResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface ListAllSuppliersResponse
 */
export interface ListAllSuppliersResponse {
    /**
     * 
     * @type {Array<SupplierListItem>}
     * @memberof ListAllSuppliersResponse
     */
    result?: Array<SupplierListItem>;
}

/**
 * Check if a given object implements the ListAllSuppliersResponse interface.
 */
export function instanceOfListAllSuppliersResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListAllSuppliersResponseFromJSON(json: any): ListAllSuppliersResponse {
    return ListAllSuppliersResponseFromJSONTyped(json, false);
}

export function ListAllSuppliersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListAllSuppliersResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : ((json['result'] as Array<any>).map(SupplierListItemFromJSON)),
    };
}

export function ListAllSuppliersResponseToJSON(value?: ListAllSuppliersResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': value.result === undefined ? undefined : ((value.result as Array<any>).map(SupplierListItemToJSON)),
    };
}



/* ListAllTagsResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface ListAllTagsResponse
 */
export interface ListAllTagsResponse {
    /**
     * 
     * @type {Array<Tag>}
     * @memberof ListAllTagsResponse
     */
    result?: Array<Tag>;
}

/**
 * Check if a given object implements the ListAllTagsResponse interface.
 */
export function instanceOfListAllTagsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListAllTagsResponseFromJSON(json: any): ListAllTagsResponse {
    return ListAllTagsResponseFromJSONTyped(json, false);
}

export function ListAllTagsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListAllTagsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : ((json['result'] as Array<any>).map(TagFromJSON)),
    };
}

export function ListAllTagsResponseToJSON(value?: ListAllTagsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': value.result === undefined ? undefined : ((value.result as Array<any>).map(TagToJSON)),
    };
}



/* ListFilterResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface ListFilterResponse
 */
export interface ListFilterResponse {
    /**
     * 
     * @type {Array<StoredAgreementFilter>}
     * @memberof ListFilterResponse
     */
    result: Array<StoredAgreementFilter>;
}

/**
 * Check if a given object implements the ListFilterResponse interface.
 */
export function instanceOfListFilterResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "result" in value;

    return isInstance;
}

export function ListFilterResponseFromJSON(json: any): ListFilterResponse {
    return ListFilterResponseFromJSONTyped(json, false);
}

export function ListFilterResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListFilterResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': ((json['result'] as Array<any>).map(StoredAgreementFilterFromJSON)),
    };
}

export function ListFilterResponseToJSON(value?: ListFilterResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': ((value.result as Array<any>).map(StoredAgreementFilterToJSON)),
    };
}



/* ListReportAgreementsResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface ListReportAgreementsResponse
 */
export interface ListReportAgreementsResponse {
    /**
     * 
     * @type {Array<ListReportAgreementsResponseResultsInner>}
     * @memberof ListReportAgreementsResponse
     */
    results?: Array<ListReportAgreementsResponseResultsInner>;
}

/**
 * Check if a given object implements the ListReportAgreementsResponse interface.
 */
export function instanceOfListReportAgreementsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListReportAgreementsResponseFromJSON(json: any): ListReportAgreementsResponse {
    return ListReportAgreementsResponseFromJSONTyped(json, false);
}

export function ListReportAgreementsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListReportAgreementsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(ListReportAgreementsResponseResultsInnerFromJSON)),
    };
}

export function ListReportAgreementsResponseToJSON(value?: ListReportAgreementsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(ListReportAgreementsResponseResultsInnerToJSON)),
    };
}



/* ListReportAgreementsResponseResultsInner.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */







/**
 * 
 * @export
 * @interface ListReportAgreementsResponseResultsInner
 */
export interface ListReportAgreementsResponseResultsInner {
    /**
     * 
     * @type {Metadata}
     * @memberof ListReportAgreementsResponseResultsInner
     */
    metadata?: Metadata;
    /**
     * 
     * @type {Agreement}
     * @memberof ListReportAgreementsResponseResultsInner
     */
    data?: Agreement;
}

/**
 * Check if a given object implements the ListReportAgreementsResponseResultsInner interface.
 */
export function instanceOfListReportAgreementsResponseResultsInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListReportAgreementsResponseResultsInnerFromJSON(json: any): ListReportAgreementsResponseResultsInner {
    return ListReportAgreementsResponseResultsInnerFromJSONTyped(json, false);
}

export function ListReportAgreementsResponseResultsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListReportAgreementsResponseResultsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': !exists(json, 'metadata') ? undefined : MetadataFromJSON(json['metadata']),
        'data': !exists(json, 'data') ? undefined : AgreementFromJSON(json['data']),
    };
}

export function ListReportAgreementsResponseResultsInnerToJSON(value?: ListReportAgreementsResponseResultsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataToJSON(value.metadata),
        'data': AgreementToJSON(value.data),
    };
}



/* ListReportSuppliersResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface ListReportSuppliersResponse
 */
export interface ListReportSuppliersResponse {
    /**
     * 
     * @type {Array<ListReportSuppliersResponseResultsInner>}
     * @memberof ListReportSuppliersResponse
     */
    results?: Array<ListReportSuppliersResponseResultsInner>;
}

/**
 * Check if a given object implements the ListReportSuppliersResponse interface.
 */
export function instanceOfListReportSuppliersResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListReportSuppliersResponseFromJSON(json: any): ListReportSuppliersResponse {
    return ListReportSuppliersResponseFromJSONTyped(json, false);
}

export function ListReportSuppliersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListReportSuppliersResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(ListReportSuppliersResponseResultsInnerFromJSON)),
    };
}

export function ListReportSuppliersResponseToJSON(value?: ListReportSuppliersResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(ListReportSuppliersResponseResultsInnerToJSON)),
    };
}



/* ListReportSuppliersResponseResultsInner.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */







/**
 * 
 * @export
 * @interface ListReportSuppliersResponseResultsInner
 */
export interface ListReportSuppliersResponseResultsInner {
    /**
     * 
     * @type {Metadata}
     * @memberof ListReportSuppliersResponseResultsInner
     */
    metadata?: Metadata;
    /**
     * 
     * @type {Supplier}
     * @memberof ListReportSuppliersResponseResultsInner
     */
    data?: Supplier;
}

/**
 * Check if a given object implements the ListReportSuppliersResponseResultsInner interface.
 */
export function instanceOfListReportSuppliersResponseResultsInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListReportSuppliersResponseResultsInnerFromJSON(json: any): ListReportSuppliersResponseResultsInner {
    return ListReportSuppliersResponseResultsInnerFromJSONTyped(json, false);
}

export function ListReportSuppliersResponseResultsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListReportSuppliersResponseResultsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': !exists(json, 'metadata') ? undefined : MetadataFromJSON(json['metadata']),
        'data': !exists(json, 'data') ? undefined : SupplierFromJSON(json['data']),
    };
}

export function ListReportSuppliersResponseResultsInnerToJSON(value?: ListReportSuppliersResponseResultsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataToJSON(value.metadata),
        'data': SupplierToJSON(value.data),
    };
}



/* ListSupplierFilterResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface ListSupplierFilterResponse
 */
export interface ListSupplierFilterResponse {
    /**
     * 
     * @type {Array<StoredSupplierFilter>}
     * @memberof ListSupplierFilterResponse
     */
    result: Array<StoredSupplierFilter>;
}

/**
 * Check if a given object implements the ListSupplierFilterResponse interface.
 */
export function instanceOfListSupplierFilterResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "result" in value;

    return isInstance;
}

export function ListSupplierFilterResponseFromJSON(json: any): ListSupplierFilterResponse {
    return ListSupplierFilterResponseFromJSONTyped(json, false);
}

export function ListSupplierFilterResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListSupplierFilterResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': ((json['result'] as Array<any>).map(StoredSupplierFilterFromJSON)),
    };
}

export function ListSupplierFilterResponseToJSON(value?: ListSupplierFilterResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': ((value.result as Array<any>).map(StoredSupplierFilterToJSON)),
    };
}



/* Metadata.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface Metadata
 */
export interface Metadata {
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof Metadata
     */
    group?: number;
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    version?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Metadata
     */
    mostRecentVersion?: boolean;
    /**
     * When the agreement was entered into the system
     * @type {Date}
     * @memberof Metadata
     */
    created?: Date;
    /**
     * When the agreement was last modified in the system
     * @type {Date}
     * @memberof Metadata
     */
    modified?: Date;
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    modifiedBy?: string;
    /**
     * Indicates whether agreement is active or archived
     * @type {boolean}
     * @memberof Metadata
     */
    isArchived?: boolean;
}

/**
 * Check if a given object implements the Metadata interface.
 */
export function instanceOfMetadata(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MetadataFromJSON(json: any): Metadata {
    return MetadataFromJSONTyped(json, false);
}

export function MetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): Metadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'group': !exists(json, 'group') ? undefined : json['group'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'mostRecentVersion': !exists(json, 'mostRecentVersion') ? undefined : json['mostRecentVersion'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'isArchived': !exists(json, 'isArchived') ? undefined : json['isArchived'],
    };
}

export function MetadataToJSON(value?: Metadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'group': value.group,
        'version': value.version,
        'mostRecentVersion': value.mostRecentVersion,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'modified': value.modified === undefined ? undefined : (value.modified.toISOString()),
        'createdBy': value.createdBy,
        'modifiedBy': value.modifiedBy,
        'isArchived': value.isArchived,
    };
}



/* ModelError.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    code: number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    message: string;
}

/**
 * Check if a given object implements the ModelError interface.
 */
export function instanceOfModelError(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "message" in value;

    return isInstance;
}

export function ModelErrorFromJSON(json: any): ModelError {
    return ModelErrorFromJSONTyped(json, false);
}

export function ModelErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'message': json['message'],
    };
}

export function ModelErrorToJSON(value?: ModelError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'message': value.message,
    };
}



/* NoticePeriod.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface NoticePeriod
 */
export interface NoticePeriod {
    /**
     * 
     * @type {number}
     * @memberof NoticePeriod
     */
    value: number;
    /**
     * 
     * @type {string}
     * @memberof NoticePeriod
     */
    unit: NoticePeriodUnitEnum;
}


/**
 * @export
 */
export const NoticePeriodUnitEnum = {
    BusinessDays: 'businessDays',
    CalendarDays: 'calendarDays',
    Months: 'months'
} as const;
export type NoticePeriodUnitEnum = typeof NoticePeriodUnitEnum[keyof typeof NoticePeriodUnitEnum];


/**
 * Check if a given object implements the NoticePeriod interface.
 */
export function instanceOfNoticePeriod(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "unit" in value;

    return isInstance;
}

export function NoticePeriodFromJSON(json: any): NoticePeriod {
    return NoticePeriodFromJSONTyped(json, false);
}

export function NoticePeriodFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoticePeriod {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': json['value'],
        'unit': json['unit'],
    };
}

export function NoticePeriodToJSON(value?: NoticePeriod | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'unit': value.unit,
    };
}



/* NotificationSetting.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface NotificationSetting
 */
export interface NotificationSetting {
    [key: string]: any | any;
    /**
     * 
     * @type {Array<number>}
     * @memberof NotificationSetting
     */
    daysAhead?: Array<number>;
}

/**
 * Check if a given object implements the NotificationSetting interface.
 */
export function instanceOfNotificationSetting(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NotificationSettingFromJSON(json: any): NotificationSetting {
    return NotificationSettingFromJSONTyped(json, false);
}

export function NotificationSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationSetting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
            ...json,
        'daysAhead': !exists(json, 'daysAhead') ? undefined : json['daysAhead'],
    };
}

export function NotificationSettingToJSON(value?: NotificationSetting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
            ...value,
        'daysAhead': value.daysAhead,
    };
}



/* NotificationSettings.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface NotificationSettings
 */
export interface NotificationSettings {
    /**
     * 
     * @type {NotificationSetting}
     * @memberof NotificationSettings
     */
    endDate?: NotificationSetting;
    /**
     * 
     * @type {NotificationSetting}
     * @memberof NotificationSettings
     */
    renewalDate?: NotificationSetting;
    /**
     * 
     * @type {NotificationSetting}
     * @memberof NotificationSettings
     */
    nextReview?: NotificationSetting;
    /**
     * 
     * @type {NotificationSetting}
     * @memberof NotificationSettings
     */
    nextAudit?: NotificationSetting;
    /**
     * 
     * @type {NotificationSetting}
     * @memberof NotificationSettings
     */
    nextRiskAssessment?: NotificationSetting;
}

/**
 * Check if a given object implements the NotificationSettings interface.
 */
export function instanceOfNotificationSettings(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NotificationSettingsFromJSON(json: any): NotificationSettings {
    return NotificationSettingsFromJSONTyped(json, false);
}

export function NotificationSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'endDate': !exists(json, 'endDate') ? undefined : NotificationSettingFromJSON(json['endDate']),
        'renewalDate': !exists(json, 'renewalDate') ? undefined : NotificationSettingFromJSON(json['renewalDate']),
        'nextReview': !exists(json, 'nextReview') ? undefined : NotificationSettingFromJSON(json['nextReview']),
        'nextAudit': !exists(json, 'nextAudit') ? undefined : NotificationSettingFromJSON(json['nextAudit']),
        'nextRiskAssessment': !exists(json, 'nextRiskAssessment') ? undefined : NotificationSettingFromJSON(json['nextRiskAssessment']),
    };
}

export function NotificationSettingsToJSON(value?: NotificationSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'endDate': NotificationSettingToJSON(value.endDate),
        'renewalDate': NotificationSettingToJSON(value.renewalDate),
        'nextReview': NotificationSettingToJSON(value.nextReview),
        'nextAudit': NotificationSettingToJSON(value.nextAudit),
        'nextRiskAssessment': NotificationSettingToJSON(value.nextRiskAssessment),
    };
}



/* Npap.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface Npap
 */
export interface Npap {
    /**
     * 
     * @type {boolean}
     * @memberof Npap
     */
    npapRequired?: boolean | null;
    /**
     * 
     * @type {NpapData}
     * @memberof Npap
     */
    data?: NpapData;
}

/**
 * Check if a given object implements the Npap interface.
 */
export function instanceOfNpap(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NpapFromJSON(json: any): Npap {
    return NpapFromJSONTyped(json, false);
}

export function NpapFromJSONTyped(json: any, ignoreDiscriminator: boolean): Npap {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'npapRequired': !exists(json, 'npapRequired') ? undefined : json['npapRequired'],
        'data': !exists(json, 'data') ? undefined : NpapDataFromJSON(json['data']),
    };
}

export function NpapToJSON(value?: Npap | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'npapRequired': value.npapRequired,
        'data': NpapDataToJSON(value.data),
    };
}



/* NpapData.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface NpapData
 */
export interface NpapData {
    /**
     * 
     * @type {boolean}
     * @memberof NpapData
     */
    npapCompleted?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof NpapData
     */
    npapDescription?: string;
}

/**
 * Check if a given object implements the NpapData interface.
 */
export function instanceOfNpapData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NpapDataFromJSON(json: any): NpapData {
    return NpapDataFromJSONTyped(json, false);
}

export function NpapDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): NpapData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'npapCompleted': !exists(json, 'npapCompleted') ? undefined : json['npapCompleted'],
        'npapDescription': !exists(json, 'npapDescription') ? undefined : json['npapDescription'],
    };
}

export function NpapDataToJSON(value?: NpapData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'npapCompleted': value.npapCompleted,
        'npapDescription': value.npapDescription,
    };
}



/* PersonalData.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface PersonalData
 */
export interface PersonalData {
    /**
     * 
     * @type {boolean}
     * @memberof PersonalData
     */
    isPersonalDataTransferred?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PersonalData
     */
    countriesWhereDataIsTransferred?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof PersonalData
     */
    isPersonalDataProcessed?: boolean | null;
    /**
     * 
     * @type {Role}
     * @memberof PersonalData
     */
    role?: Role | null;
    /**
     * 
     * @type {boolean}
     * @memberof PersonalData
     */
    isDataProcessor?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PersonalData
     */
    dataProtectionIncluded?: boolean | null;
}

/**
 * Check if a given object implements the PersonalData interface.
 */
export function instanceOfPersonalData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PersonalDataFromJSON(json: any): PersonalData {
    return PersonalDataFromJSONTyped(json, false);
}

export function PersonalDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonalData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isPersonalDataTransferred': !exists(json, 'isPersonalDataTransferred') ? undefined : json['isPersonalDataTransferred'],
        'countriesWhereDataIsTransferred': !exists(json, 'countriesWhereDataIsTransferred') ? undefined : json['countriesWhereDataIsTransferred'],
        'isPersonalDataProcessed': !exists(json, 'isPersonalDataProcessed') ? undefined : json['isPersonalDataProcessed'],
        'role': !exists(json, 'role') ? undefined : RoleFromJSON(json['role']),
        'isDataProcessor': !exists(json, 'isDataProcessor') ? undefined : json['isDataProcessor'],
        'dataProtectionIncluded': !exists(json, 'dataProtectionIncluded') ? undefined : json['dataProtectionIncluded'],
    };
}

export function PersonalDataToJSON(value?: PersonalData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isPersonalDataTransferred': value.isPersonalDataTransferred,
        'countriesWhereDataIsTransferred': value.countriesWhereDataIsTransferred,
        'isPersonalDataProcessed': value.isPersonalDataProcessed,
        'role': RoleToJSON(value.role),
        'isDataProcessor': value.isDataProcessor,
        'dataProtectionIncluded': value.dataProtectionIncluded,
    };
}



/* Region.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface Region
 */
export interface Region {
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    name: string;
}

/**
 * Check if a given object implements the Region interface.
 */
export function instanceOfRegion(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function RegionFromJSON(json: any): Region {
    return RegionFromJSONTyped(json, false);
}

export function RegionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Region {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
    };
}

export function RegionToJSON(value?: Region | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
    };
}



/* RelatedAgreements.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface RelatedAgreements
 */
export interface RelatedAgreements {
    /**
     * 
     * @type {boolean}
     * @memberof RelatedAgreements
     */
    hasParent?: boolean | null;
    /**
     * 
     * @type {RelatedAgreementsParentAgreement}
     * @memberof RelatedAgreements
     */
    parentAgreement?: RelatedAgreementsParentAgreement | null;
}

/**
 * Check if a given object implements the RelatedAgreements interface.
 */
export function instanceOfRelatedAgreements(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RelatedAgreementsFromJSON(json: any): RelatedAgreements {
    return RelatedAgreementsFromJSONTyped(json, false);
}

export function RelatedAgreementsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RelatedAgreements {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hasParent': !exists(json, 'hasParent') ? undefined : json['hasParent'],
        'parentAgreement': !exists(json, 'parentAgreement') ? undefined : RelatedAgreementsParentAgreementFromJSON(json['parentAgreement']),
    };
}

export function RelatedAgreementsToJSON(value?: RelatedAgreements | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hasParent': value.hasParent,
        'parentAgreement': RelatedAgreementsParentAgreementToJSON(value.parentAgreement),
    };
}



/* RelatedAgreementsParentAgreement.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface RelatedAgreementsParentAgreement
 */
export interface RelatedAgreementsParentAgreement {
    /**
     * 
     * @type {string}
     * @memberof RelatedAgreementsParentAgreement
     */
    id: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelatedAgreementsParentAgreement
     */
    name?: string | null;
}

/**
 * Check if a given object implements the RelatedAgreementsParentAgreement interface.
 */
export function instanceOfRelatedAgreementsParentAgreement(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function RelatedAgreementsParentAgreementFromJSON(json: any): RelatedAgreementsParentAgreement {
    return RelatedAgreementsParentAgreementFromJSONTyped(json, false);
}

export function RelatedAgreementsParentAgreementFromJSONTyped(json: any, ignoreDiscriminator: boolean): RelatedAgreementsParentAgreement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function RelatedAgreementsParentAgreementToJSON(value?: RelatedAgreementsParentAgreement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
    };
}



/* RenameAgreementAttachmentRequest.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface RenameAgreementAttachmentRequest
 */
export interface RenameAgreementAttachmentRequest {
    /**
     * 
     * @type {string}
     * @memberof RenameAgreementAttachmentRequest
     */
    filename: string;
}

/**
 * Check if a given object implements the RenameAgreementAttachmentRequest interface.
 */
export function instanceOfRenameAgreementAttachmentRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "filename" in value;

    return isInstance;
}

export function RenameAgreementAttachmentRequestFromJSON(json: any): RenameAgreementAttachmentRequest {
    return RenameAgreementAttachmentRequestFromJSONTyped(json, false);
}

export function RenameAgreementAttachmentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RenameAgreementAttachmentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filename': json['filename'],
    };
}

export function RenameAgreementAttachmentRequestToJSON(value?: RenameAgreementAttachmentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filename': value.filename,
    };
}



/* RenameSupplierAttachmentRequest.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface RenameSupplierAttachmentRequest
 */
export interface RenameSupplierAttachmentRequest {
    /**
     * 
     * @type {string}
     * @memberof RenameSupplierAttachmentRequest
     */
    filename: string;
}

/**
 * Check if a given object implements the RenameSupplierAttachmentRequest interface.
 */
export function instanceOfRenameSupplierAttachmentRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "filename" in value;

    return isInstance;
}

export function RenameSupplierAttachmentRequestFromJSON(json: any): RenameSupplierAttachmentRequest {
    return RenameSupplierAttachmentRequestFromJSONTyped(json, false);
}

export function RenameSupplierAttachmentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RenameSupplierAttachmentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filename': json['filename'],
    };
}

export function RenameSupplierAttachmentRequestToJSON(value?: RenameSupplierAttachmentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filename': value.filename,
    };
}



/* Report.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface Report
 */
export interface Report {
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    entity?: ReportEntityEnum;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    query?: string;
}


/**
 * @export
 */
export const ReportEntityEnum = {
    Agreements: 'agreements',
    Suppliers: 'suppliers'
} as const;
export type ReportEntityEnum = typeof ReportEntityEnum[keyof typeof ReportEntityEnum];


/**
 * Check if a given object implements the Report interface.
 */
export function instanceOfReport(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ReportFromJSON(json: any): Report {
    return ReportFromJSONTyped(json, false);
}

export function ReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): Report {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'entity': !exists(json, 'entity') ? undefined : json['entity'],
        'query': !exists(json, 'query') ? undefined : json['query'],
    };
}

export function ReportToJSON(value?: Report | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'entity': value.entity,
        'query': value.query,
    };
}



/* ReportActions.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ReportActions = {
    ReportDelete: 'oasys:ReportDelete',
    ReportEdit: 'oasys:ReportEdit',
    ReportGet: 'oasys:ReportGet'
} as const;
export type ReportActions = typeof ReportActions[keyof typeof ReportActions];


export function ReportActionsFromJSON(json: any): ReportActions {
    return ReportActionsFromJSONTyped(json, false);
}

export function ReportActionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportActions {
    return json as ReportActions;
}

export function ReportActionsToJSON(value?: ReportActions | null): any {
    return value as any;
}



/* ReportListItem.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */







/**
 * 
 * @export
 * @interface ReportListItem
 */
export interface ReportListItem {
    /**
     * 
     * @type {Metadata}
     * @memberof ReportListItem
     */
    metadata: Metadata;
    /**
     * 
     * @type {Report}
     * @memberof ReportListItem
     */
    data?: Report;
}

/**
 * Check if a given object implements the ReportListItem interface.
 */
export function instanceOfReportListItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;

    return isInstance;
}

export function ReportListItemFromJSON(json: any): ReportListItem {
    return ReportListItemFromJSONTyped(json, false);
}

export function ReportListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataFromJSON(json['metadata']),
        'data': !exists(json, 'data') ? undefined : ReportFromJSON(json['data']),
    };
}

export function ReportListItemToJSON(value?: ReportListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataToJSON(value.metadata),
        'data': ReportToJSON(value.data),
    };
}



/* Role.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const Role = {
    DataController: 'dataController',
    JointController: 'jointController',
    DataProcessor: 'dataProcessor',
    Null: 'null'
} as const;
export type Role = typeof Role[keyof typeof Role];


export function RoleFromJSON(json: any): Role {
    return RoleFromJSONTyped(json, false);
}

export function RoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Role {
    return json as Role;
}

export function RoleToJSON(value?: Role | null): any {
    return value as any;
}



/* ServiceDetails.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */







/**
 * 
 * @export
 * @interface ServiceDetails
 */
export interface ServiceDetails {
    /**
     * 
     * @type {boolean}
     * @memberof ServiceDetails
     */
    isProvidedAsCloudService?: boolean | null;
    /**
     * 
     * @type {ServiceDetailsData}
     * @memberof ServiceDetails
     */
    data?: ServiceDetailsData;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceDetails
     */
    countriesWhereServiceIsPerformed?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceDetails
     */
    countriesOrRegionsWhereDataIsStored?: Array<string>;
    /**
     * 
     * @type {Array<ServiceDetailsSubcontractorsInner>}
     * @memberof ServiceDetails
     */
    subcontractors?: Array<ServiceDetailsSubcontractorsInner>;
}

/**
 * Check if a given object implements the ServiceDetails interface.
 */
export function instanceOfServiceDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ServiceDetailsFromJSON(json: any): ServiceDetails {
    return ServiceDetailsFromJSONTyped(json, false);
}

export function ServiceDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isProvidedAsCloudService': !exists(json, 'isProvidedAsCloudService') ? undefined : json['isProvidedAsCloudService'],
        'data': !exists(json, 'data') ? undefined : ServiceDetailsDataFromJSON(json['data']),
        'countriesWhereServiceIsPerformed': !exists(json, 'countriesWhereServiceIsPerformed') ? undefined : json['countriesWhereServiceIsPerformed'],
        'countriesOrRegionsWhereDataIsStored': !exists(json, 'countriesOrRegionsWhereDataIsStored') ? undefined : json['countriesOrRegionsWhereDataIsStored'],
        'subcontractors': !exists(json, 'subcontractors') ? undefined : ((json['subcontractors'] as Array<any>).map(ServiceDetailsSubcontractorsInnerFromJSON)),
    };
}

export function ServiceDetailsToJSON(value?: ServiceDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isProvidedAsCloudService': value.isProvidedAsCloudService,
        'data': ServiceDetailsDataToJSON(value.data),
        'countriesWhereServiceIsPerformed': value.countriesWhereServiceIsPerformed,
        'countriesOrRegionsWhereDataIsStored': value.countriesOrRegionsWhereDataIsStored,
        'subcontractors': value.subcontractors === undefined ? undefined : ((value.subcontractors as Array<any>).map(ServiceDetailsSubcontractorsInnerToJSON)),
    };
}



/* ServiceDetailsData.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface ServiceDetailsData
 */
export interface ServiceDetailsData {
    /**
     * 
     * @type {DeploymentModel}
     * @memberof ServiceDetailsData
     */
    deploymentModel?: DeploymentModel | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceDetailsData
     */
    natureOfData?: string;
}

/**
 * Check if a given object implements the ServiceDetailsData interface.
 */
export function instanceOfServiceDetailsData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ServiceDetailsDataFromJSON(json: any): ServiceDetailsData {
    return ServiceDetailsDataFromJSONTyped(json, false);
}

export function ServiceDetailsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceDetailsData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deploymentModel': !exists(json, 'deploymentModel') ? undefined : DeploymentModelFromJSON(json['deploymentModel']),
        'natureOfData': !exists(json, 'natureOfData') ? undefined : json['natureOfData'],
    };
}

export function ServiceDetailsDataToJSON(value?: ServiceDetailsData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deploymentModel': DeploymentModelToJSON(value.deploymentModel),
        'natureOfData': value.natureOfData,
    };
}



/* ServiceDetailsSubcontractorsInner.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface ServiceDetailsSubcontractorsInner
 */
export interface ServiceDetailsSubcontractorsInner {
    /**
     * automated on client side
     * @type {string}
     * @memberof ServiceDetailsSubcontractorsInner
     */
    id: string;
    /**
     * Reference another subcontractor in the array
     * @type {string}
     * @memberof ServiceDetailsSubcontractorsInner
     */
    parentId?: string | null;
    /**
     * reference to a category
     * @type {string}
     * @memberof ServiceDetailsSubcontractorsInner
     */
    categoryOfActivity?: string | null;
    /**
     * A brief description of function an data
     * @type {string}
     * @memberof ServiceDetailsSubcontractorsInner
     */
    briefDescription?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceDetailsSubcontractorsInner
     */
    countriesWhereServiceIsPerformed?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceDetailsSubcontractorsInner
     */
    countriesOrRegionsWhereDataIsStored?: Array<string>;
    /**
     * 
     * @type {ServiceDetailsSubcontractorsInnerSupplier}
     * @memberof ServiceDetailsSubcontractorsInner
     */
    supplier?: ServiceDetailsSubcontractorsInnerSupplier;
}

/**
 * Check if a given object implements the ServiceDetailsSubcontractorsInner interface.
 */
export function instanceOfServiceDetailsSubcontractorsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function ServiceDetailsSubcontractorsInnerFromJSON(json: any): ServiceDetailsSubcontractorsInner {
    return ServiceDetailsSubcontractorsInnerFromJSONTyped(json, false);
}

export function ServiceDetailsSubcontractorsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceDetailsSubcontractorsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'categoryOfActivity': !exists(json, 'categoryOfActivity') ? undefined : json['categoryOfActivity'],
        'briefDescription': !exists(json, 'briefDescription') ? undefined : json['briefDescription'],
        'countriesWhereServiceIsPerformed': !exists(json, 'countriesWhereServiceIsPerformed') ? undefined : json['countriesWhereServiceIsPerformed'],
        'countriesOrRegionsWhereDataIsStored': !exists(json, 'countriesOrRegionsWhereDataIsStored') ? undefined : json['countriesOrRegionsWhereDataIsStored'],
        'supplier': !exists(json, 'supplier') ? undefined : ServiceDetailsSubcontractorsInnerSupplierFromJSON(json['supplier']),
    };
}

export function ServiceDetailsSubcontractorsInnerToJSON(value?: ServiceDetailsSubcontractorsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'parentId': value.parentId,
        'categoryOfActivity': value.categoryOfActivity,
        'briefDescription': value.briefDescription,
        'countriesWhereServiceIsPerformed': value.countriesWhereServiceIsPerformed,
        'countriesOrRegionsWhereDataIsStored': value.countriesOrRegionsWhereDataIsStored,
        'supplier': ServiceDetailsSubcontractorsInnerSupplierToJSON(value.supplier),
    };
}



/* ServiceDetailsSubcontractorsInnerSupplier.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The main supplier could either be an external provider or an internal (an internalEntity).
 * @export
 * @interface ServiceDetailsSubcontractorsInnerSupplier
 */
export interface ServiceDetailsSubcontractorsInnerSupplier {
    /**
     * 
     * @type {boolean}
     * @memberof ServiceDetailsSubcontractorsInnerSupplier
     */
    isExternal?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ServiceDetailsSubcontractorsInnerSupplier
     */
    externalSupplier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceDetailsSubcontractorsInnerSupplier
     */
    internalSupplier?: string | null;
}

/**
 * Check if a given object implements the ServiceDetailsSubcontractorsInnerSupplier interface.
 */
export function instanceOfServiceDetailsSubcontractorsInnerSupplier(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ServiceDetailsSubcontractorsInnerSupplierFromJSON(json: any): ServiceDetailsSubcontractorsInnerSupplier {
    return ServiceDetailsSubcontractorsInnerSupplierFromJSONTyped(json, false);
}

export function ServiceDetailsSubcontractorsInnerSupplierFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceDetailsSubcontractorsInnerSupplier {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isExternal': !exists(json, 'isExternal') ? undefined : json['isExternal'],
        'externalSupplier': !exists(json, 'externalSupplier') ? undefined : json['externalSupplier'],
        'internalSupplier': !exists(json, 'internalSupplier') ? undefined : json['internalSupplier'],
    };
}

export function ServiceDetailsSubcontractorsInnerSupplierToJSON(value?: ServiceDetailsSubcontractorsInnerSupplier | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isExternal': value.isExternal,
        'externalSupplier': value.externalSupplier,
        'internalSupplier': value.internalSupplier,
    };
}



/* Status.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const Status = {
    Active: 'active',
    NonActive: 'nonActive',
    NotSigned: 'notSigned',
    SignedByBothParties: 'signedByBothParties',
    SignedByInternalEntity: 'signedByInternalEntity',
    SignedBySupplier: 'signedBySupplier',
    NoticedByInternalEntity: 'noticedByInternalEntity',
    NoticedBySupplier: 'noticedBySupplier',
    TerminatedByInternalEntity: 'terminatedByInternalEntity',
    TerminatedBySupplier: 'terminatedBySupplier',
    Terminated: 'terminated',
    Null: 'null'
} as const;
export type Status = typeof Status[keyof typeof Status];


export function StatusFromJSON(json: any): Status {
    return StatusFromJSONTyped(json, false);
}

export function StatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): Status {
    return json as Status;
}

export function StatusToJSON(value?: Status | null): any {
    return value as any;
}



/* StoredAgreementFilter.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface StoredAgreementFilter
 */
export interface StoredAgreementFilter {
    /**
     * 
     * @type {string}
     * @memberof StoredAgreementFilter
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof StoredAgreementFilter
     */
    name: string;
    /**
     * 
     * @type {AgreementFilter}
     * @memberof StoredAgreementFilter
     */
    filter: AgreementFilter;
}

/**
 * Check if a given object implements the StoredAgreementFilter interface.
 */
export function instanceOfStoredAgreementFilter(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "filter" in value;

    return isInstance;
}

export function StoredAgreementFilterFromJSON(json: any): StoredAgreementFilter {
    return StoredAgreementFilterFromJSONTyped(json, false);
}

export function StoredAgreementFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoredAgreementFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'filter': AgreementFilterFromJSON(json['filter']),
    };
}

export function StoredAgreementFilterToJSON(value?: StoredAgreementFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'filter': AgreementFilterToJSON(value.filter),
    };
}



/* StoredSupplierFilter.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface StoredSupplierFilter
 */
export interface StoredSupplierFilter {
    /**
     * 
     * @type {string}
     * @memberof StoredSupplierFilter
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof StoredSupplierFilter
     */
    name: string;
    /**
     * 
     * @type {SupplierFilter}
     * @memberof StoredSupplierFilter
     */
    filter: SupplierFilter;
}

/**
 * Check if a given object implements the StoredSupplierFilter interface.
 */
export function instanceOfStoredSupplierFilter(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "filter" in value;

    return isInstance;
}

export function StoredSupplierFilterFromJSON(json: any): StoredSupplierFilter {
    return StoredSupplierFilterFromJSONTyped(json, false);
}

export function StoredSupplierFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoredSupplierFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'filter': SupplierFilterFromJSON(json['filter']),
    };
}

export function StoredSupplierFilterToJSON(value?: StoredSupplierFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'filter': SupplierFilterToJSON(value.filter),
    };
}



/* Supplier.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface Supplier
 */
export interface Supplier {
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    supplierName?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    corporateRegistrationNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    legalEntityIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    countryOfRegistration?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    registeredAddress?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Supplier
     */
    contactDetails?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof Supplier
     */
    isUltimateParent?: boolean;
    /**
     * Enabled if ultimateParent is false
     * @type {string}
     * @memberof Supplier
     */
    parentCompany?: string;
    /**
     * 
     * @type {SupplierAssessment}
     * @memberof Supplier
     */
    supplierAssessment?: SupplierAssessment;
}

/**
 * Check if a given object implements the Supplier interface.
 */
export function instanceOfSupplier(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SupplierFromJSON(json: any): Supplier {
    return SupplierFromJSONTyped(json, false);
}

export function SupplierFromJSONTyped(json: any, ignoreDiscriminator: boolean): Supplier {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'supplierName': !exists(json, 'supplierName') ? undefined : json['supplierName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'corporateRegistrationNumber': !exists(json, 'corporateRegistrationNumber') ? undefined : json['corporateRegistrationNumber'],
        'legalEntityIdentifier': !exists(json, 'legalEntityIdentifier') ? undefined : json['legalEntityIdentifier'],
        'countryOfRegistration': !exists(json, 'countryOfRegistration') ? undefined : json['countryOfRegistration'],
        'registeredAddress': !exists(json, 'registeredAddress') ? undefined : json['registeredAddress'],
        'contactDetails': !exists(json, 'contactDetails') ? undefined : json['contactDetails'],
        'isUltimateParent': !exists(json, 'isUltimateParent') ? undefined : json['isUltimateParent'],
        'parentCompany': !exists(json, 'parentCompany') ? undefined : json['parentCompany'],
        'supplierAssessment': !exists(json, 'supplierAssessment') ? undefined : SupplierAssessmentFromJSON(json['supplierAssessment']),
    };
}

export function SupplierToJSON(value?: Supplier | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'supplierName': value.supplierName,
        'description': value.description,
        'corporateRegistrationNumber': value.corporateRegistrationNumber,
        'legalEntityIdentifier': value.legalEntityIdentifier,
        'countryOfRegistration': value.countryOfRegistration,
        'registeredAddress': value.registeredAddress,
        'contactDetails': value.contactDetails,
        'isUltimateParent': value.isUltimateParent,
        'parentCompany': value.parentCompany,
        'supplierAssessment': SupplierAssessmentToJSON(value.supplierAssessment),
    };
}



/* SupplierActions.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SupplierActions = {
    SupplierAttachmentDelete: 'oasys:SupplierAttachmentDelete',
    SupplierAttachmentGet: 'oasys:SupplierAttachmentGet',
    SupplierAttachmentList: 'oasys:SupplierAttachmentList',
    SupplierAttachmentRename: 'oasys:SupplierAttachmentRename',
    SupplierAttachmentUpload: 'oasys:SupplierAttachmentUpload',
    SupplierDelete: 'oasys:SupplierDelete',
    SupplierEdit: 'oasys:SupplierEdit',
    SupplierGet: 'oasys:SupplierGet',
    SupplierHistory: 'oasys:SupplierHistory'
} as const;
export type SupplierActions = typeof SupplierActions[keyof typeof SupplierActions];


export function SupplierActionsFromJSON(json: any): SupplierActions {
    return SupplierActionsFromJSONTyped(json, false);
}

export function SupplierActionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierActions {
    return json as SupplierActions;
}

export function SupplierActionsToJSON(value?: SupplierActions | null): any {
    return value as any;
}



/* SupplierAssessment.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface SupplierAssessment
 */
export interface SupplierAssessment {
    /**
     * 
     * @type {boolean}
     * @memberof SupplierAssessment
     */
    isCSRSurveyCompleted?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SupplierAssessment
     */
    isCSRSurveyRequired?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SupplierAssessment
     */
    isDueDiligenceConducted?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SupplierAssessment
     */
    isFollowCodeOfConductConfirmed?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SupplierAssessment
     */
    isFollowCodeOfConductRequired?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SupplierAssessment
     */
    isInformationSecurityCompleted?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SupplierAssessment
     */
    isInformationSecurityRequired?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SupplierAssessment
     */
    isRiskAssessmentCompleted?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof SupplierAssessment
     */
    latestCSRSurvey?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof SupplierAssessment
     */
    latestDueDiligence?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof SupplierAssessment
     */
    latestRiskAssessment?: Date | null;
}

/**
 * Check if a given object implements the SupplierAssessment interface.
 */
export function instanceOfSupplierAssessment(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SupplierAssessmentFromJSON(json: any): SupplierAssessment {
    return SupplierAssessmentFromJSONTyped(json, false);
}

export function SupplierAssessmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierAssessment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isCSRSurveyCompleted': !exists(json, 'isCSRSurveyCompleted') ? undefined : json['isCSRSurveyCompleted'],
        'isCSRSurveyRequired': !exists(json, 'isCSRSurveyRequired') ? undefined : json['isCSRSurveyRequired'],
        'isDueDiligenceConducted': !exists(json, 'isDueDiligenceConducted') ? undefined : json['isDueDiligenceConducted'],
        'isFollowCodeOfConductConfirmed': !exists(json, 'isFollowCodeOfConductConfirmed') ? undefined : json['isFollowCodeOfConductConfirmed'],
        'isFollowCodeOfConductRequired': !exists(json, 'isFollowCodeOfConductRequired') ? undefined : json['isFollowCodeOfConductRequired'],
        'isInformationSecurityCompleted': !exists(json, 'isInformationSecurityCompleted') ? undefined : json['isInformationSecurityCompleted'],
        'isInformationSecurityRequired': !exists(json, 'isInformationSecurityRequired') ? undefined : json['isInformationSecurityRequired'],
        'isRiskAssessmentCompleted': !exists(json, 'isRiskAssessmentCompleted') ? undefined : json['isRiskAssessmentCompleted'],
        'latestCSRSurvey': !exists(json, 'latestCSRSurvey') ? undefined : (json['latestCSRSurvey'] === null ? null : new Date(json['latestCSRSurvey'])),
        'latestDueDiligence': !exists(json, 'latestDueDiligence') ? undefined : (json['latestDueDiligence'] === null ? null : new Date(json['latestDueDiligence'])),
        'latestRiskAssessment': !exists(json, 'latestRiskAssessment') ? undefined : (json['latestRiskAssessment'] === null ? null : new Date(json['latestRiskAssessment'])),
    };
}

export function SupplierAssessmentToJSON(value?: SupplierAssessment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isCSRSurveyCompleted': value.isCSRSurveyCompleted,
        'isCSRSurveyRequired': value.isCSRSurveyRequired,
        'isDueDiligenceConducted': value.isDueDiligenceConducted,
        'isFollowCodeOfConductConfirmed': value.isFollowCodeOfConductConfirmed,
        'isFollowCodeOfConductRequired': value.isFollowCodeOfConductRequired,
        'isInformationSecurityCompleted': value.isInformationSecurityCompleted,
        'isInformationSecurityRequired': value.isInformationSecurityRequired,
        'isRiskAssessmentCompleted': value.isRiskAssessmentCompleted,
        'latestCSRSurvey': value.latestCSRSurvey === undefined ? undefined : (value.latestCSRSurvey === null ? null : value.latestCSRSurvey.toISOString().substr(0,10)),
        'latestDueDiligence': value.latestDueDiligence === undefined ? undefined : (value.latestDueDiligence === null ? null : value.latestDueDiligence.toISOString().substr(0,10)),
        'latestRiskAssessment': value.latestRiskAssessment === undefined ? undefined : (value.latestRiskAssessment === null ? null : value.latestRiskAssessment.toISOString().substr(0,10)),
    };
}



/* SupplierAttachmentSearchResult.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface SupplierAttachmentSearchResult
 */
export interface SupplierAttachmentSearchResult {
    /**
     * 
     * @type {string}
     * @memberof SupplierAttachmentSearchResult
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierAttachmentSearchResult
     */
    content?: string;
    /**
     * 
     * @type {number}
     * @memberof SupplierAttachmentSearchResult
     */
    rank?: number;
    /**
     * 
     * @type {GlobalSearchResultType}
     * @memberof SupplierAttachmentSearchResult
     */
    type?: GlobalSearchResultType;
    /**
     * 
     * @type {string}
     * @memberof SupplierAttachmentSearchResult
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierAttachmentSearchResult
     */
    attachedTo?: string;
}

/**
 * Check if a given object implements the SupplierAttachmentSearchResult interface.
 */
export function instanceOfSupplierAttachmentSearchResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SupplierAttachmentSearchResultFromJSON(json: any): SupplierAttachmentSearchResult {
    return SupplierAttachmentSearchResultFromJSONTyped(json, false);
}

export function SupplierAttachmentSearchResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierAttachmentSearchResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'rank': !exists(json, 'rank') ? undefined : json['rank'],
        'type': !exists(json, 'type') ? undefined : GlobalSearchResultTypeFromJSON(json['type']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'attachedTo': !exists(json, 'attachedTo') ? undefined : json['attachedTo'],
    };
}

export function SupplierAttachmentSearchResultToJSON(value?: SupplierAttachmentSearchResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'content': value.content,
        'rank': value.rank,
        'type': GlobalSearchResultTypeToJSON(value.type),
        'name': value.name,
        'attachedTo': value.attachedTo,
    };
}



/* SupplierFileAttachment.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface SupplierFileAttachment
 */
export interface SupplierFileAttachment {
    /**
     * 
     * @type {string}
     * @memberof SupplierFileAttachment
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierFileAttachment
     */
    filename: string;
    /**
     * 
     * @type {Date}
     * @memberof SupplierFileAttachment
     */
    uploaded: Date;
}

/**
 * Check if a given object implements the SupplierFileAttachment interface.
 */
export function instanceOfSupplierFileAttachment(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "filename" in value;
    isInstance = isInstance && "uploaded" in value;

    return isInstance;
}

export function SupplierFileAttachmentFromJSON(json: any): SupplierFileAttachment {
    return SupplierFileAttachmentFromJSONTyped(json, false);
}

export function SupplierFileAttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierFileAttachment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'filename': json['filename'],
        'uploaded': (new Date(json['uploaded'])),
    };
}

export function SupplierFileAttachmentToJSON(value?: SupplierFileAttachment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'filename': value.filename,
        'uploaded': (value.uploaded.toISOString()),
    };
}



/* SupplierFilter.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface SupplierFilter
 */
export interface SupplierFilter {
    /**
     * 
     * @type {boolean}
     * @memberof SupplierFilter
     */
    isPinned?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof SupplierFilter
     */
    supplierName?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SupplierFilter
     */
    corporateRegistrationNumber?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SupplierFilter
     */
    countryOfRegistration?: Array<string>;
}

/**
 * Check if a given object implements the SupplierFilter interface.
 */
export function instanceOfSupplierFilter(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SupplierFilterFromJSON(json: any): SupplierFilter {
    return SupplierFilterFromJSONTyped(json, false);
}

export function SupplierFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isPinned': !exists(json, 'isPinned') ? undefined : json['isPinned'],
        'supplierName': !exists(json, 'supplierName') ? undefined : json['supplierName'],
        'corporateRegistrationNumber': !exists(json, 'corporateRegistrationNumber') ? undefined : json['corporateRegistrationNumber'],
        'countryOfRegistration': !exists(json, 'countryOfRegistration') ? undefined : json['countryOfRegistration'],
    };
}

export function SupplierFilterToJSON(value?: SupplierFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isPinned': value.isPinned,
        'supplierName': value.supplierName,
        'corporateRegistrationNumber': value.corporateRegistrationNumber,
        'countryOfRegistration': value.countryOfRegistration,
    };
}



/* SupplierFilterInput.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface SupplierFilterInput
 */
export interface SupplierFilterInput {
    /**
     * 
     * @type {boolean}
     * @memberof SupplierFilterInput
     */
    isPinned?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SupplierFilterInput
     */
    name: string;
    /**
     * 
     * @type {SupplierFilter}
     * @memberof SupplierFilterInput
     */
    filter?: SupplierFilter;
}

/**
 * Check if a given object implements the SupplierFilterInput interface.
 */
export function instanceOfSupplierFilterInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function SupplierFilterInputFromJSON(json: any): SupplierFilterInput {
    return SupplierFilterInputFromJSONTyped(json, false);
}

export function SupplierFilterInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierFilterInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isPinned': !exists(json, 'isPinned') ? undefined : json['isPinned'],
        'name': json['name'],
        'filter': !exists(json, 'filter') ? undefined : SupplierFilterFromJSON(json['filter']),
    };
}

export function SupplierFilterInputToJSON(value?: SupplierFilterInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isPinned': value.isPinned,
        'name': value.name,
        'filter': SupplierFilterToJSON(value.filter),
    };
}



/* SupplierListItem.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */







/**
 * 
 * @export
 * @interface SupplierListItem
 */
export interface SupplierListItem {
    /**
     * 
     * @type {Metadata}
     * @memberof SupplierListItem
     */
    metadata: Metadata;
    /**
     * 
     * @type {Supplier}
     * @memberof SupplierListItem
     */
    data?: Supplier;
}

/**
 * Check if a given object implements the SupplierListItem interface.
 */
export function instanceOfSupplierListItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;

    return isInstance;
}

export function SupplierListItemFromJSON(json: any): SupplierListItem {
    return SupplierListItemFromJSONTyped(json, false);
}

export function SupplierListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataFromJSON(json['metadata']),
        'data': !exists(json, 'data') ? undefined : SupplierFromJSON(json['data']),
    };
}

export function SupplierListItemToJSON(value?: SupplierListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataToJSON(value.metadata),
        'data': SupplierToJSON(value.data),
    };
}



/* SupplierSearchResult.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface SupplierSearchResult
 */
export interface SupplierSearchResult {
    /**
     * 
     * @type {string}
     * @memberof SupplierSearchResult
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierSearchResult
     */
    content?: string;
    /**
     * 
     * @type {number}
     * @memberof SupplierSearchResult
     */
    rank?: number;
    /**
     * 
     * @type {GlobalSearchResultType}
     * @memberof SupplierSearchResult
     */
    type?: GlobalSearchResultType;
    /**
     * 
     * @type {string}
     * @memberof SupplierSearchResult
     */
    name?: string;
}

/**
 * Check if a given object implements the SupplierSearchResult interface.
 */
export function instanceOfSupplierSearchResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SupplierSearchResultFromJSON(json: any): SupplierSearchResult {
    return SupplierSearchResultFromJSONTyped(json, false);
}

export function SupplierSearchResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierSearchResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'rank': !exists(json, 'rank') ? undefined : json['rank'],
        'type': !exists(json, 'type') ? undefined : GlobalSearchResultTypeFromJSON(json['type']),
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function SupplierSearchResultToJSON(value?: SupplierSearchResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'content': value.content,
        'rank': value.rank,
        'type': GlobalSearchResultTypeToJSON(value.type),
        'name': value.name,
    };
}



/* SupplierVersionListItem.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface SupplierVersionListItem
 */
export interface SupplierVersionListItem {
    /**
     * 
     * @type {Metadata}
     * @memberof SupplierVersionListItem
     */
    metadata: Metadata;
}

/**
 * Check if a given object implements the SupplierVersionListItem interface.
 */
export function instanceOfSupplierVersionListItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;

    return isInstance;
}

export function SupplierVersionListItemFromJSON(json: any): SupplierVersionListItem {
    return SupplierVersionListItemFromJSONTyped(json, false);
}

export function SupplierVersionListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierVersionListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataFromJSON(json['metadata']),
    };
}

export function SupplierVersionListItemToJSON(value?: SupplierVersionListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataToJSON(value.metadata),
    };
}



/* Tag.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    name: string;
}

/**
 * Check if a given object implements the Tag interface.
 */
export function instanceOfTag(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function TagFromJSON(json: any): Tag {
    return TagFromJSONTyped(json, false);
}

export function TagFromJSONTyped(json: any, ignoreDiscriminator: boolean): Tag {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
    };
}

export function TagToJSON(value?: Tag | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
    };
}



/* TagActions.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TagActions = {
    TagDelete: 'oasys:TagDelete',
    TagGet: 'oasys:TagGet'
} as const;
export type TagActions = typeof TagActions[keyof typeof TagActions];


export function TagActionsFromJSON(json: any): TagActions {
    return TagActionsFromJSONTyped(json, false);
}

export function TagActionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagActions {
    return json as TagActions;
}

export function TagActionsToJSON(value?: TagActions | null): any {
    return value as any;
}



/* Term.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface Term
 */
export interface Term {
    /**
     * 
     * @type {string}
     * @memberof Term
     */
    name?: string;
    /**
     * 
     * @type {Date}
     * @memberof Term
     */
    startDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Term
     */
    endDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Term
     */
    renewalDate?: Date | null;
    /**
     * 
     * @type {NoticePeriod}
     * @memberof Term
     */
    noticePeriodSupplier?: NoticePeriod;
    /**
     * 
     * @type {NoticePeriod}
     * @memberof Term
     */
    noticePeriodInternalEntity?: NoticePeriod;
}

/**
 * Check if a given object implements the Term interface.
 */
export function instanceOfTerm(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TermFromJSON(json: any): Term {
    return TermFromJSONTyped(json, false);
}

export function TermFromJSONTyped(json: any, ignoreDiscriminator: boolean): Term {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'startDate': !exists(json, 'startDate') ? undefined : (json['startDate'] === null ? null : new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (json['endDate'] === null ? null : new Date(json['endDate'])),
        'renewalDate': !exists(json, 'renewalDate') ? undefined : (json['renewalDate'] === null ? null : new Date(json['renewalDate'])),
        'noticePeriodSupplier': !exists(json, 'noticePeriodSupplier') ? undefined : NoticePeriodFromJSON(json['noticePeriodSupplier']),
        'noticePeriodInternalEntity': !exists(json, 'noticePeriodInternalEntity') ? undefined : NoticePeriodFromJSON(json['noticePeriodInternalEntity']),
    };
}

export function TermToJSON(value?: Term | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'startDate': value.startDate === undefined ? undefined : (value.startDate === null ? null : value.startDate.toISOString().substr(0,10)),
        'endDate': value.endDate === undefined ? undefined : (value.endDate === null ? null : value.endDate.toISOString().substr(0,10)),
        'renewalDate': value.renewalDate === undefined ? undefined : (value.renewalDate === null ? null : value.renewalDate.toISOString().substr(0,10)),
        'noticePeriodSupplier': NoticePeriodToJSON(value.noticePeriodSupplier),
        'noticePeriodInternalEntity': NoticePeriodToJSON(value.noticePeriodInternalEntity),
    };
}

