/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FastField as FormikFastField,
  Field as FormikField,
  GenericFieldHTMLAttributes,
} from 'formik';
import React, { ElementType } from 'react';
import { useEditContext } from '../EditContext';

type ElementProps<T extends ElementType<any>> = {
  [K in keyof React.ComponentPropsWithoutRef<
    T
  >]?: React.ComponentPropsWithoutRef<T>[K];
};

type FieldProps<T extends ElementType<any> = never> = T extends never
  ? never
  : ElementProps<T> & {
      notFast?: boolean;
      readOnly?: boolean;
      component?: T;
    };

export const Field = <T extends ElementType<any> = never>({
  notFast = false,
  readOnly = false,
  ...props
}: FieldProps<T> & GenericFieldHTMLAttributes) => {
  const isEditEnabled = useEditContext();
  const Element = notFast ? FormikField : FormikFastField;

  if (readOnly) {
    return (
      <Element
        key={isEditEnabled.toString()}
        isEditEnabled={false}
        {...props}
      />
    );
  }

  return (
    <Element
      key={isEditEnabled.toString()}
      isEditEnabled={isEditEnabled}
      {...props}
    />
  );
};
