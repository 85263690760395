import React from 'react';
import { Formik } from 'formik';
import {
  EditProvider,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalTitle,
  ModalBody,
  PrimaryButton,
  SecondaryButton,
} from '@fcg-tech/regtech-components';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import { Subcontractor } from '../../types';
import {
  SubcontractorsFieldEditForm,
  SubcontractorsFieldEditFormProps,
} from './SubcontractorsFieldEditForm';
import { SubcontractorsFieldEditModalButtonRow } from './SubcontractorsFieldEditModal.styles';

interface SubcontractorsFieldEditModalProps
  extends Omit<SubcontractorsFieldEditFormProps, 'setFieldValue'> {
  title?: string;
  onSubmit: (subcontractor: Subcontractor) => void;
  onCancel: () => void;
}
export const SubcontractorsFieldEditModal = ({
  title = '',
  subcontractor,
  onSubmit,
  onCancel,
  ...props
}: SubcontractorsFieldEditModalProps) => {
  const { t } = useTranslation();

  return (
    <Formik initialValues={subcontractor} onSubmit={onSubmit}>
      {({ handleSubmit, values, setFieldValue }) => (
        <EditProvider value>
          <Modal onHide={onCancel}>
            <ModalHeader>
              <ModalTitle>{title}</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <SubcontractorsFieldEditForm
                subcontractor={values}
                setFieldValue={setFieldValue}
                {...props}
              />
            </ModalBody>
            <ModalFooter>
              <SubcontractorsFieldEditModalButtonRow>
                <PrimaryButton onClick={() => handleSubmit()}>
                  {t(MessageKeys.LabelSubmit)}
                </PrimaryButton>
                <SecondaryButton onClick={onCancel}>
                  {' '}
                  {t(MessageKeys.LabelCancel)}
                </SecondaryButton>
              </SubcontractorsFieldEditModalButtonRow>
            </ModalFooter>
          </Modal>
        </EditProvider>
      )}
    </Formik>
  );
};
