/* tslint:disable */
/* eslint-disable */
/**
 * Notification API
 * This API handles Notification API
 *
 * The version of the OpenAPI document: 0.0.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    NotificationsSettingsResult,
    NotificationsSettingsResultFromJSON,
    NotificationsSettingsResultToJSON,
} from '../models';

export interface CheckIfEmailNotificationsIsEnabledRequest {
    application: string;
}

export interface DisableEmailNotificationRequest {
    application: string;
}

export interface EnableEmailNotificationRequest {
    application: string;
}

/**
 * NotificationSettingsApi - interface
 * 
 * @export
 * @interface NotificationSettingsApiInterface
 */
export interface NotificationSettingsApiInterface {
    /**
     * 
     * @summary Check if email notifications are enabled
     * @param {string} application filter out notification from only this application.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationSettingsApiInterface
     */
    checkIfEmailNotificationsIsEnabledRaw(requestParameters: CheckIfEmailNotificationsIsEnabledRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NotificationsSettingsResult>>;

    /**
     * Check if email notifications are enabled
     */
    checkIfEmailNotificationsIsEnabled(requestParameters: CheckIfEmailNotificationsIsEnabledRequest, initOverrides?: RequestInit): Promise<NotificationsSettingsResult>;

    /**
     * 
     * @summary Disable email notification
     * @param {string} application filter out notification from only this application.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationSettingsApiInterface
     */
    disableEmailNotificationRaw(requestParameters: DisableEmailNotificationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * Disable email notification
     */
    disableEmailNotification(requestParameters: DisableEmailNotificationRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary Enable email notification
     * @param {string} application filter out notification from only this application.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationSettingsApiInterface
     */
    enableEmailNotificationRaw(requestParameters: EnableEmailNotificationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * Enable email notification
     */
    enableEmailNotification(requestParameters: EnableEmailNotificationRequest, initOverrides?: RequestInit): Promise<void>;

}

/**
 * 
 */
export class NotificationSettingsApi extends runtime.BaseAPI implements NotificationSettingsApiInterface {

    /**
     * Check if email notifications are enabled
     */
    async checkIfEmailNotificationsIsEnabledRaw(requestParameters: CheckIfEmailNotificationsIsEnabledRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NotificationsSettingsResult>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling checkIfEmailNotificationsIsEnabled.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/notifications/{application}/subscription/email`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationsSettingsResultFromJSON(jsonValue));
    }

    /**
     * Check if email notifications are enabled
     */
    async checkIfEmailNotificationsIsEnabled(requestParameters: CheckIfEmailNotificationsIsEnabledRequest, initOverrides?: RequestInit): Promise<NotificationsSettingsResult> {
        const response = await this.checkIfEmailNotificationsIsEnabledRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Disable email notification
     */
    async disableEmailNotificationRaw(requestParameters: DisableEmailNotificationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling disableEmailNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/notifications/{application}/subscription/email`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Disable email notification
     */
    async disableEmailNotification(requestParameters: DisableEmailNotificationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.disableEmailNotificationRaw(requestParameters, initOverrides);
    }

    /**
     * Enable email notification
     */
    async enableEmailNotificationRaw(requestParameters: EnableEmailNotificationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling enableEmailNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/notifications/{application}/subscription/email`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Enable email notification
     */
    async enableEmailNotification(requestParameters: EnableEmailNotificationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.enableEmailNotificationRaw(requestParameters, initOverrides);
    }

}
