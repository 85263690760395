import { ModalBody } from '@fcg-tech/regtech-components';
import styled from 'styled-components';

export const SessionTimeoutModalBody = styled(ModalBody)`
  min-width: 35rem;
`;

export const SessionTimeoutModalTextContent = styled.div`
  font-size: 1.4rem;
  line-height: 2.5;
`;
