import { classNames } from '@fcg-tech/regtech-utils';
import React from 'react';

interface TooltipArrowProps {
  size?: number;
  className?: string;
  color?: string;
}

export const TooltipArrow = React.forwardRef<SVGSVGElement, TooltipArrowProps>(
  ({ size = 15, color = 'white', className }, ref) => {
    const aspect = 15 / 10;
    return (
      <svg
        ref={ref}
        className={classNames('tooltip_tooltip-arrow', className)}
        width={size}
        height={size / aspect}
        viewBox="0 0 15 10"
        fill="none"
      >
        <g filter="url(#tooltip-arrow-filter)">
          <path
            d="M7.23544 6.84192L2.40155 1.93244L12.0693 1.93244L7.23544 6.84192Z"
            fill={color}
          />
        </g>
        <defs>
          <filter
            id="tooltip-arrow-filter"
            x="0.401611"
            y="0.932495"
            width="13.6677"
            height="8.90942"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_304:19"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_304:19"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );
  },
);
