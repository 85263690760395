import { classNames } from '@fcg-tech/regtech-utils';
import React, { FunctionComponent } from 'react';
import { NavLinkProps } from 'react-router-dom';
import { SideNavigationItemLinkWrapper } from './SideNavigationItem.styles';

export const SideNavigationItemLink: FunctionComponent<
  NavLinkProps &
    React.RefAttributes<HTMLAnchorElement> & {
      className?: string;
    }
> = (props) => {
  return (
    <SideNavigationItemLinkWrapper
      {...props}
      className={classNames(props.className)}
    />
  );
};
