import styled, { css, DefaultTheme } from 'styled-components';
import { ModalFormWrapper } from '../../components/modals';

export const CabinetMoveModalFormWrapper = styled(ModalFormWrapper)`
  min-width: 56rem;
`;

export const compactTheme: Partial<DefaultTheme> = {
  elements: {
    button: {
      secondary: css`
        height: 3rem;
        font-size: 1.2rem;
        height: 3rem;
      `,
    },
  },
};

export const CautionZone = styled.div`
  ${({ theme }) => css`
    background-color: ${theme?.colors?.warning?.main ?? '#FFC109'};
    padding: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  `}
`;
