import {
  TranslationLocale,
  AuthMessageKeys as MessageKeys,
} from './authMessageKeys';

const en: TranslationLocale = {
  language: 'en',
  messages: {
    [MessageKeys.AuthSessionExpired]: 'Session expired',
    [MessageKeys.AuthSessionExpiredMessage]:
      'Your session has expired. Click "Reauthenticate" below to renew your session.',
    [MessageKeys.AuthSessionExpiredUsername]: 'You will be reauthenticated as',
    [MessageKeys.AuthSessionExpiredNotYou]: 'Not you?',
    [MessageKeys.AuthSessionExpiredReAuthenticate]: 'Reauthenticate',
  },
};

export default en;
