import { classNames, useEscapeKeyOld } from '@fcg-tech/regtech-utils';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
} from 'react';
import { Portal } from '../Portal';
import {
  Backdrop,
  CloseButton,
  CloseButtonContainer,
  CloseIcon,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalWrapper,
} from './Modal.styles';

export interface ModalProps {
  blurBackground?: boolean;
  className?: string;
  container?: string;
  floatingCloseButton?: boolean;
  kind?: string;
  onHide?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface ModalFn
  extends FunctionComponent<PropsWithChildren<ModalProps>> {
  Body: typeof ModalBody;
  Footer: typeof ModalFooter;
  Header: typeof ModalHeader;
  Title: typeof ModalTitle;
}

export const Modal: ModalFn = ({
  blurBackground,
  container = '#modal-root',
  className,
  floatingCloseButton,
  kind = 'modal',
  children,
  onHide,
}) => {
  const handleHide = React.useCallback(
    (event?: React.MouseEvent<HTMLButtonElement>) => {
      if (onHide && typeof onHide === 'function') {
        onHide(event);
      }
    },
    [onHide],
  );

  const handleEscape = useCallback(() => {
    handleHide();
    return true;
  }, [handleHide]);

  useEscapeKeyOld(handleEscape, 1000);

  return (
    <Portal container={container}>
      <ModalWrapper role="dialog" aria-modal="true" data-kind={kind}>
        <Backdrop
          data-testid="modal-backdrop"
          className={classNames(blurBackground && 'blur')}
        />
        <ModalContent className={className}>
          {onHide && (
            <CloseButtonContainer
              className={classNames(floatingCloseButton && 'floating')}
            >
              <CloseButton data-testid="modal-hide-button" onClick={handleHide}>
                <CloseIcon />
              </CloseButton>
            </CloseButtonContainer>
          )}
          {children}
        </ModalContent>
      </ModalWrapper>
    </Portal>
  );
};

Modal.Body = ModalBody;
Modal.Header = ModalHeader;
Modal.Footer = ModalFooter;
Modal.Title = ModalTitle;
