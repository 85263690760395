import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import {
  AccessControl,
  Card,
  CardBody,
  CardTitle,
  loadDataTableSortOrder,
  PageHeader,
  PageHeaderActionButton,
  PageLayout,
  PageLayoutPaddedContent,
  PrimaryButton,
  SecondaryButton,
  useEditContext,
  useDataTableSortable,
} from '@fcg-tech/regtech-components';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { useTranslation } from 'react-i18next';
import { Trash2 } from 'lucide-react';
import { MessageKeys } from '../../../translations/messageKeys';
import { internalEntityPropType, agreementPropType } from '../../../propTypes';
import { routes } from '../../../routes';
import { generalAgreementsListEntitiesCompareFn } from '../../../utils/compareFns';
import { getGeneralAgreementsListSortOrder } from '../../../utils/listDataTableHelpers';
import { useTenant } from '../../../states/tenantState';
import { DirtyFormCheck } from '../../../components/DirtyFormCheck';
import { InternalEntityDetailsSubForm } from '../../../components/SubForms';
import { InternalEntityActions } from '../../../types';
import { ListDataTable } from '../../../components/ListDataTable';

export const EditInternalEntityPage = ({
  internalEntity,
  agreements,
  isSaving,
  onSave,
  onEdit,
  onCancel,
  onDelete,
}) => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const tenantId = tenant.id;
  const isEditEnabled = useEditContext();
  const navigate = useNavigate();

  const pageTitle = React.useMemo(
    () => internalEntity.data.name || t(MessageKeys.LabelUnnamed),
    [internalEntity.data.name, t],
  );

  const columns = React.useMemo(
    () => [
      {
        columnKey: 'name',
        label: t(MessageKeys.LabelName),
        resizable: false,
        sortable: true,
        initialSortAscending: true,
      },
      {
        columnKey: 'agreementType',
        label: t(MessageKeys.LabelAgreementType),
        resizable: false,
        sortable: true,
        initialSortAscending: false,
      },
      {
        columnKey: 'functionCategory',
        label: t(MessageKeys.LabelCategory),
        resizable: false,
        sortable: true,
        initialSortAscending: false,
      },
      {
        columnKey: 'contractOwner',
        label: t(MessageKeys.LabelContractOwner),
        resizable: false,
        sortable: true,
        initialSortAscending: false,
      },
    ],
    [t],
  );

  const rows = React.useMemo(
    () =>
      agreements?.map((agreement) => ({
        id: agreement.metadata.id,
        data: agreement,
      })) ?? [],
    [agreements],
  );

  const { sortedRows, sortOrder, handleSortOrderChange } = useDataTableSortable(
    'agreements-agreementType-table-sort-order',
    rows,
    {
      sortOrder:
        loadDataTableSortOrder('agreements-agreementType-table-sort-order') ??
        getGeneralAgreementsListSortOrder('nameAsc'),
      compareFn: generalAgreementsListEntitiesCompareFn,
    },
  );

  const handleClickRow = React.useCallback(
    (id) =>
      navigate(constructUrl(routes.agreement, { tenantId, agreementId: id })),
    [navigate, tenantId],
  );

  return (
    <Formik initialValues={internalEntity} onSubmit={onSave}>
      {({ handleSubmit, dirty, resetForm }) => (
        <PageLayout>
          <DirtyFormCheck dirty={dirty && !isSaving && isEditEnabled} />
          <PageHeader title={pageTitle} breadcrumbs={[]}>
            {isEditEnabled ? (
              <>
                <SecondaryButton
                  onClick={() => {
                    resetForm();
                    onCancel();
                  }}
                >
                  {t(MessageKeys.LabelCancel)}
                </SecondaryButton>
                <PrimaryButton
                  onClick={handleSubmit}
                  loading={isSaving}
                  disabled={!dirty || isSaving}
                >
                  {t(MessageKeys.LabelSubmit)}
                </PrimaryButton>
              </>
            ) : (
              <>
                <AccessControl
                  permissions={internalEntity.permissions}
                  requiredPermissions={
                    InternalEntityActions.InternalEntityDelete
                  }
                >
                  <PageHeaderActionButton onClick={onDelete}>
                    <Trash2 size="24" />
                  </PageHeaderActionButton>
                </AccessControl>
                <AccessControl
                  permissions={internalEntity.permissions}
                  requiredPermissions={InternalEntityActions.InternalEntityEdit}
                >
                  <PrimaryButton onClick={onEdit}>
                    {t(MessageKeys.LabelEdit)}
                  </PrimaryButton>
                </AccessControl>
              </>
            )}
          </PageHeader>
          <PageLayoutPaddedContent>
            <form onSubmit={handleSubmit}>
              <Card>
                <CardBody>
                  <CardTitle>{t(MessageKeys.LabelDetails)}</CardTitle>
                  <InternalEntityDetailsSubForm />
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <ListDataTable
                    title={t(MessageKeys.LabelIntragroupAgreements)}
                    emptyTableText={t(MessageKeys.LabelNoAgreementsFound)}
                    columns={columns}
                    data={sortedRows}
                    sortOrder={sortOrder}
                    handleSortOrderChange={handleSortOrderChange}
                    handleClickRow={handleClickRow}
                  />
                </CardBody>
              </Card>
            </form>
          </PageLayoutPaddedContent>
        </PageLayout>
      )}
    </Formik>
  );
};

EditInternalEntityPage.propTypes = {
  internalEntity: internalEntityPropType.isRequired,
  agreements: PropTypes.arrayOf(agreementPropType),
  isSaving: PropTypes.bool,
  onSave: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
};

EditInternalEntityPage.defaultProps = {
  agreements: [],
  isSaving: false,
  onSave: null,
  onEdit: null,
  onDelete: null,
  onCancel: null,
};
