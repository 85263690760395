import { omit } from '@fcg-tech/regtech-utils';
import update from 'immutability-helper';
import hash from 'object-hash';
import { parse, stringify } from 'query-string';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate, Location } from 'react-router-dom';
import {
  FilterValues,
  HandleClear,
  HandleExclude,
  HandleFilterChange,
  HandleFilterClear,
  HandleFilterValueChange,
  StoredFilter,
} from './types';
import {
  calculateIntervals,
  isFilterEmpty,
  parseUrlFilterValues,
  stringifyUrlFilterValues,
} from './utils';

export const useHistoryFilter = <T extends FilterValues>(
  location: Location,
  filterProps: Array<keyof T>,
  modifyParsedFilter?: (filterValues: T) => T,
) => {
  const filter = useMemo(() => {
    const filter = parseUrlFilterValues<T>(location.search ?? '', filterProps);
    return modifyParsedFilter ? modifyParsedFilter(filter) : filter;
  }, [filterProps, location.search, modifyParsedFilter]);

  const filterId = parse(location.search ?? '')?.filterId as string;

  return {
    filter,
    filterId,
  };
};

export type UseFilterInterface<T extends FilterValues> = {
  filter: T;
  filterHash: string;
  filterId: string | undefined;
  storedFilters: Array<StoredFilter<T>> | undefined;
  usingInitialFilter: boolean;
  getFilterQuery: (
    filterValues: FilterValues,
    filterId?: string,
  ) => Record<
    string,
    string | number | Array<string> | Array<number> | null | undefined
  >;
  handleFilterChange: HandleFilterChange;
  handleFilterClear: HandleFilterClear;
  handleFilterValueChange: HandleFilterValueChange<T>;
  handleFilterValueClear: HandleClear<T>;
  handleFilterValueExclude: HandleExclude<T>;
  handleStoredFilterSelected: (filterId: string | null) => void;
};

export interface UseFilterProps<T extends FilterValues> {
  filterProps: Array<keyof T>;
  storedFilters?: Array<StoredFilter<T>>;
  initialFilter?: {
    filter: T;
    filterId?: string;
    overrideInitialUrlFilter?: boolean;
  };
  excludePropertyKey?: string;
  modifyParsedFilter?: (filterValues: T) => T;
}

export const useFilter = <T extends FilterValues>({
  filterProps,
  storedFilters,
  initialFilter,
  excludePropertyKey,
  modifyParsedFilter,
}: UseFilterProps<T>): UseFilterInterface<T> => {
  const navigate = useNavigate();
  const location = useLocation();

  const filterPropsParams = useMemo(() => {
    const params: Array<string> = [];
    filterProps.forEach((propName) => {
      params.push(propName.toString());
      params.push(`${String(propName)}.to`);
      params.push(`${String(propName)}.from`);
      params.push(`${String(propName)}.interval`);
    });
    return params;
  }, [filterProps]);

  const { filter, filterId } = useHistoryFilter(
    location,
    filterProps,
    modifyParsedFilter,
  );

  const getFilterQuery = useCallback(
    (
      filterValues: FilterValues,
      filterId?: string,
    ): Record<
      string,
      string | number | Array<string> | Array<number> | null | undefined
    > => {
      const query = stringifyUrlFilterValues(filterValues);
      const search: { page?: number; filterId?: string } =
        parse(location.search) ?? {};

      if (search.page) {
        // Always reset pagination to page 1 when filter changes
        search.page = 1;
      }
      const obj: Record<
        string,
        string | number | Array<string> | Array<number> | null | undefined
      > = {
        page: search.page,
        ...query,
      };

      if (filterId) {
        obj.filterId = filterId;
      }

      return obj;
    },
    [location.search],
  );

  const handleFilterChange = useCallback<HandleFilterChange>(
    (filterValues: FilterValues, filterId?: string): FilterValues => {
      navigate(
        {
          pathname: location.pathname,
          search: stringify({
            ...omit(
              parse(location.search) as unknown as Record<string, unknown>,
              'filterId',
              ...filterPropsParams,
            ),
            ...getFilterQuery(filterValues, filterId),
          }),
        },
        { replace: true },
      );

      return filterValues;
    },
    [
      filterPropsParams,
      getFilterQuery,
      location.pathname,
      location.search,
      navigate,
    ],
  );

  const handleFilterClear = useCallback<HandleFilterClear>(() => {
    return handleFilterChange({}, undefined);
  }, [handleFilterChange]);

  const handleFilterValueChange = useCallback<HandleFilterValueChange<T>>(
    (key, value, filterId) => {
      return handleFilterChange(
        update<FilterValues>(filter, { [key]: { $set: value } }),
        filterId,
      );
    },
    [filter, handleFilterChange],
  );

  const handleFilterValueClear = useCallback<HandleClear<T>>(
    (key) => {
      let updated = update<FilterValues>(filter, {
        $unset: [key],
      });

      const excludeIndex = excludePropertyKey
        ? (filter[excludePropertyKey] as Array<unknown>)?.indexOf(key)
        : -1;

      if (excludePropertyKey && excludeIndex > -1) {
        updated = update<FilterValues>(updated, {
          [excludePropertyKey]: { $splice: [[excludeIndex, 1]] },
        });
      }

      return handleFilterChange(updated, filterId);
    },
    [excludePropertyKey, filter, filterId, handleFilterChange],
  );

  const handleFilterValueExclude = useCallback<HandleExclude<T>>(
    (filterPropKey, isExcluding) => {
      if (excludePropertyKey) {
        return handleFilterChange(
          // TODO: Change to push when we can filter on multiple types
          update(filter, {
            [excludePropertyKey]: {
              $apply: (current: Array<string | number | symbol>) => {
                if (isExcluding) {
                  if (!current) {
                    return [filterPropKey];
                  }
                  return [...(current as Array<string>), filterPropKey];
                } else {
                  const index = current.indexOf(filterPropKey);
                  return update(current, { $splice: [[index, 1]] });
                }
              },
            },
          } as never), // Annoying. immutability-helpers type definitions do not work well with Typescript >4.3
          filterId,
        );
      }

      return filter;
    },
    [excludePropertyKey, filter, filterId, handleFilterChange],
  );

  const handleStoredFilterSelected = useCallback(
    (filterId: string | null) => {
      if (filterId) {
        const storedFilter = storedFilters?.find(({ id }) => id === filterId);
        if (storedFilter) {
          handleFilterChange(
            calculateIntervals(storedFilter.filter), //getFilterValuesFromStoredFilter(storedFilter),
            filterId,
          );
        }
      } else {
        handleFilterChange({} as unknown as T);
      }
    },
    [handleFilterChange, storedFilters],
  );

  const filterHash = useMemo(() => hash(filter), [filter]);
  const usingInitialFilter = useRef(
    Boolean(
      initialFilter?.filter &&
        (initialFilter.overrideInitialUrlFilter || isFilterEmpty(filter)),
    ),
  );

  useEffect(() => {
    if (initialFilter?.filter && usingInitialFilter.current) {
      handleFilterChange(initialFilter.filter, initialFilter.filterId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(
    () => ({
      filter,
      filterHash,
      filterId,
      usingInitialFilter: usingInitialFilter.current,
      getFilterQuery,
      handleFilterClear,
      handleFilterChange,
      handleFilterValueChange,
      handleFilterValueClear,
      handleFilterValueExclude: handleFilterValueExclude,
      handleStoredFilterSelected,
      storedFilters,
    }),
    [
      filter,
      filterHash,
      filterId,
      getFilterQuery,
      handleFilterChange,
      handleFilterClear,
      handleFilterValueChange,
      handleFilterValueClear,
      handleFilterValueExclude,
      handleStoredFilterSelected,
      storedFilters,
    ],
  );
};
