import {
  ConfirmDialog,
  useConfirmDialog,
  useFlash,
  useToggle,
} from '@fcg-tech/regtech-components';
import { Filter, FilterProps, isFilterEmpty } from '@fcg-tech/regtech-filter';
import { FunctionComponent, useCallback, useMemo, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import { AgreementFilterValues, StoredAgreementFilter } from '../../types';
import { isStoredFilterChanged } from '../../utils/filterHelpers';
import { UseAgreementFilterInterface } from '../../utils/filterHooks';
import { FilterSaveDialog } from './FilterSaveDialog';

interface AgreementFilterProps
  extends Omit<
    FilterProps<AgreementFilterValues>,
    | 'storedFilters'
    | 'onFilterSaveClick'
    | 'onFilterUpdateClick'
    | 'onFilterDeleteClick'
    | 'onFilterPinClick'
  > {
  storedFilters?: Array<StoredAgreementFilter>;
  onSaveFilter?: UseAgreementFilterInterface['handleFilterSaved'];
  onDeleteFilter?: UseAgreementFilterInterface['handleFilterDeleted'];
  onPinFilter?: UseAgreementFilterInterface['handleFilterPinned'];
}

export const AgreementFilter: FunctionComponent<PropsWithChildren<PropsWithChildren<AgreementFilterProps>>> = ({
  filter,
  filterId,
  storedFilters,
  onFilterChange,
  onFilterClear,
  onFilterValueChange,
  onFilterValueClear,
  onFilterValueExclude,
  onFilterLoad,
  onSaveFilter,
  onDeleteFilter,
  onPinFilter,
  children,
  ...props
}) => {
  const { t } = useTranslation();
  const addFlash = useFlash();

  const selectedStoredFilter = useMemo(
    () => storedFilters?.find(({ id }) => id === filterId),
    [filterId, storedFilters],
  );

  const selectedStoredFilterChanged = useMemo(
    () =>
      selectedStoredFilter &&
      isStoredFilterChanged(selectedStoredFilter, filter),
    [filter, selectedStoredFilter],
  );

  const filterIsEmpty = useMemo(() => isFilterEmpty(filter), [filter]);

  const [showSaveFilterDialog, , toggleShowSaveFilterDialog] = useToggle();

  const handleSaveFilter = useCallback(
    async (name: string) => {
      try {
        await onSaveFilter(name, filter);
        addFlash({
          level: 'success',
          content: t(MessageKeys.FilterSaveSuccess),
        });
      } catch (err) {
        addFlash({
          level: 'error',
          content: t(MessageKeys.FilterSaveFailure),
        });
      }
    },
    [addFlash, filter, onSaveFilter, t],
  );

  const handleSaveAsNewFilterClick = useCallback(
    () => toggleShowSaveFilterDialog(),
    [toggleShowSaveFilterDialog],
  );

  const handleUpdateFilter = useCallback(async () => {
    try {
      await onSaveFilter?.(
        selectedStoredFilter?.name,
        {
          ...filter,
          isPinned: selectedStoredFilter?.filter?.isPinned,
        },
        selectedStoredFilter?.id,
      );
      addFlash({
        level: 'success',
        content: t(MessageKeys.FilterSaveSuccess),
      });
    } catch (err) {
      addFlash({
        level: 'error',
        content: t(MessageKeys.FilterSaveFailure),
      });
    }
  }, [
    addFlash,
    filter,
    onSaveFilter,
    selectedStoredFilter?.filter?.isPinned,
    selectedStoredFilter?.id,
    selectedStoredFilter?.name,
    t,
  ]);

  const [
    showConfirmDeleteFilterDialog,
    handleConfirmDeleteFilter,
    confirmDeleteFilter,
  ] = useConfirmDialog();

  const handleDeleteFilter = useCallback(async () => {
    const confirmed = await confirmDeleteFilter();
    if (confirmed) {
      try {
        await onDeleteFilter(filterId);
        addFlash({
          level: 'success',
          content: t(MessageKeys.FilterDeleteSuccess),
        });
      } catch (err) {
        addFlash({
          level: 'error',
          content: t(MessageKeys.FilterDeleteSuccess),
        });
      }
    }
  }, [addFlash, confirmDeleteFilter, filterId, onDeleteFilter, t]);

  const isSelectedStoredFilterPinned = useMemo(
    () => (selectedStoredFilter?.filter?.isPinned ? true : false),
    [selectedStoredFilter?.filter?.isPinned],
  );

  const handlePinFilter = useCallback(
    () => onPinFilter?.(filterId, !isSelectedStoredFilterPinned),
    [filterId, isSelectedStoredFilterPinned, onPinFilter],
  );

  return (
    <Filter
      {...props}
      filter={filter}
      filterId={filterId}
      storedFilters={storedFilters}
      isSelectedStoredFilterPinned={isSelectedStoredFilterPinned}
      saveFilterDisabled={filterIsEmpty}
      onFilterChange={onFilterChange}
      onFilterClear={onFilterClear}
      onFilterValueChange={onFilterValueChange}
      onFilterValueClear={onFilterValueClear}
      onFilterValueExclude={onFilterValueExclude}
      onFilterLoad={onFilterLoad}
      onFilterSaveClick={onSaveFilter ? handleSaveAsNewFilterClick : null}
      onFilterUpdateClick={
        selectedStoredFilterChanged ? handleUpdateFilter : null
      }
      onFilterDeleteClick={handleDeleteFilter}
      onFilterPinClick={handlePinFilter}
    >
      {showSaveFilterDialog ? (
        <FilterSaveDialog
          onRequestClose={toggleShowSaveFilterDialog}
          onSubmit={handleSaveFilter}
        />
      ) : null}
      {showConfirmDeleteFilterDialog ? (
        <ConfirmDialog
          title={t(MessageKeys.LabelDelete)}
          body={t(MessageKeys.FilterConfirmDeleteQuestion)}
          confirmText={t(MessageKeys.LabelConfirmLabel)}
          cancelText={t(MessageKeys.LabelCancel)}
          onChoice={handleConfirmDeleteFilter}
        />
      ) : null}
      {children}
    </Filter>
  );
};
