import React, { FunctionComponent, useCallback, useState } from 'react';
import { PrimaryButton, SecondaryButton } from '../Button';
import { InputCheckbox } from '../Checkbox';
import { ModalBody, ModalHeader, ModalTitle } from '../Modal';
import {
  ConfirmDialogButtonRow,
  ConfirmDialogDoNotShowAgainWrapper,
  ConfirmDialogWrapper,
} from './ConfirmDialog.styles';

export type ConfirmDialogProps = {
  title: string;
  body: string;
  confirmText: string;
  cancelText: string;
  onChoice: (confirm: boolean, doNotShowAgain?: boolean) => void;
  allowDoNotShowAgain?: false;
  doNotShowAgainLabel?: never;
};

export type ConfirmDialogWithDoNotShowAgainProps = Omit<
  ConfirmDialogProps,
  'allowDoNotShowAgain' | 'doNotShowAgainLabel'
> & {
  allowDoNotShowAgain: true;
  doNotShowAgainLabel: string;
};

export const ConfirmDialog: FunctionComponent<
  ConfirmDialogProps | ConfirmDialogWithDoNotShowAgainProps
> = ({
  title,
  body,
  confirmText,
  cancelText,
  allowDoNotShowAgain,
  doNotShowAgainLabel,
  onChoice,
}) => {
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);
  const handleCancel = useCallback(() => onChoice(false), [onChoice]);
  const handleConfirm = useCallback(
    () => onChoice(true, doNotShowAgain),
    [doNotShowAgain, onChoice],
  );

  const handleDoNotShowAgainChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDoNotShowAgain(event.target.checked);
    },
    [],
  );

  return (
    <ConfirmDialogWrapper kind="confirm-dialog">
      <ModalHeader>
        <ModalTitle>{title}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        {body}

        <ConfirmDialogButtonRow>
          {allowDoNotShowAgain ? (
            <ConfirmDialogDoNotShowAgainWrapper>
              <InputCheckbox
                checked={doNotShowAgain}
                label={doNotShowAgainLabel}
                labelPosition="right"
                onChange={handleDoNotShowAgainChange}
              />
            </ConfirmDialogDoNotShowAgainWrapper>
          ) : null}
          <SecondaryButton
            onClick={handleCancel}
            data-kind="confirm-dialog-cancel-button"
          >
            {cancelText}
          </SecondaryButton>
          <PrimaryButton
            onClick={handleConfirm}
            data-kind="confirm-dialog-confirm-button"
          >
            {confirmText}
          </PrimaryButton>
        </ConfirmDialogButtonRow>
      </ModalBody>
    </ConfirmDialogWrapper>
  );
};
