/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FunctionComponent } from 'react';
import { Label } from '@fcg-tech/regtech-components';
import { PolicyFieldText } from './PolicyField.styles';
import { FormRow, FormColumn, FormGroup } from '../FormLayout';
import { policyPrinter } from '../../utils/policyHelpers';

type Policy = {
  Statement: Array<{
    Action: Array<string>;
    Effect: string;
    Resource: Array<string>;
    Condition?: any;
  }>;
};

interface PolicyFieldProps {
  title?: string;
  policy: Policy;
}

export const PolicyField: FunctionComponent<PolicyFieldProps> = ({
  title,
  policy,
}) => {
  return (
    <FormRow>
      <FormColumn>
        <FormGroup>
          {title && <Label>{title}</Label>}
          <PolicyFieldText>{policyPrinter(policy)}</PolicyFieldText>
        </FormGroup>
      </FormColumn>
    </FormRow>
  );
};
