import styled, { css } from 'styled-components';
import { UnstyledButton } from '@fcg-tech/regtech-components';

export const DataTableSortPickerRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + & {
    margin-top: 1rem;
  }
`;

export const DataTableSortPickerRowLabel = styled.span`
  font-size: 1.2rem;
  font-weight: 600;
  flex: 1;
  margin-right: 1.6rem;
`;

export const DataTableSortPickerRowButton = styled(UnstyledButton)`
  ${({ theme }) => css`
    position: relative;
    padding: 1rem 1.6rem;
    width: 12ch;
    border: 1px solid ${theme?.palette?.MainColor?.main};
    border-radius: 8px 0 0 8px;
    border-right: 0;
    font-size: 1.1rem;
    font-weight: 600;

    & + & {
      border-radius: 0 8px 8px 0;
      border-right: 1px solid ${theme?.palette?.MainColor?.main};
      border-left: 0;
    }
    &.active {
      background-color: ${theme?.palette?.MainColor?.main ??
      theme?.palette?.MainColorAccent?.light ??
      theme?.palette?.MainColorAccent.main};

      color: ${theme?.palette?.MainColor?.mainContrast ??
      theme?.palette?.MainColorAccent?.lightContrast ??
      theme?.palette?.MainColorAccent.mainContrast};
    }
  `}
`;
