import { classNames } from '@fcg-tech/regtech-utils';
import React, {
  FunctionComponent,
  useCallback,
  PropsWithChildren,
} from 'react';
import { TabState, useTabState } from 'reakit';
import { IconProps } from '../../types';
import { Badge } from '../Badge';
import { TabBarList, TabBarTab, TabBarTabLabel } from './TabBar.styles';

export type TabBarTabConfig = {
  id?: string;
  label: string;
  Icon?: FunctionComponent<IconProps>;
  disabled?: boolean;
  badgeCount?: number;
};

export interface TabBarProps {
  tabs: Array<TabBarTabConfig>;
  initialSelectedId?: string;
  overrideSelectedId?: string;
  className?: string;
  ariaLabel: string;
  vertical?: boolean;
  onChange?: (selectedId: string) => void | boolean;
  children: (tabState: TabState) => JSX.Element;
}

export const TabBar: FunctionComponent<TabBarProps> = ({
  tabs,
  initialSelectedId,
  overrideSelectedId,
  className,
  ariaLabel,
  vertical = false,
  onChange,
  children,
}) => {
  const tabState = useTabState({
    selectedId: initialSelectedId,
    orientation: 'horizontal',
    manual: true,
  });

  const selectedId = tabState.selectedId;

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!onChange) {
        return true;
      }
      const tabId = event.currentTarget.getAttribute('id');
      return onChange?.(tabId);
    },
    [onChange],
  );

  return (
    <>
      <TabBarList
        {...tabState}
        className={classNames(className, vertical ? 'vertical' : 'horizontal')}
        aria-label={ariaLabel}
      >
        {tabs?.map((tab, index) => (
          <TabBarTab
            {...tabState}
            key={tab.id ?? index}
            id={tab.id}
            data-tooltip={index}
            className={classNames('tabbar-tab', tab.disabled && 'disabled')}
            disabled={tab.disabled}
            selected={
              overrideSelectedId
                ? overrideSelectedId === tab.id
                : selectedId === tab.id
            }
            onClick={handleClick}
          >
            {tab.Icon ? <tab.Icon size="20" /> : null}
            <TabBarTabLabel>{tab.label}</TabBarTabLabel>
            {tab.badgeCount ? <Badge>{tab.badgeCount}</Badge> : null}
          </TabBarTab>
        ))}
      </TabBarList>
      {children(
        overrideSelectedId
          ? { ...tabState, selectedId: overrideSelectedId }
          : tabState,
      )}
    </>
  );
};
