import React from 'react';
import { Field, Label } from '@fcg-tech/regtech-components';
import { TextField } from '@fcg-tech/regtech-formik-components';
import { FormColumn, FormGroup, FormRow } from '../FormLayout';

export const UserDetailsSubForm = () => {
  return (
    <>
      <FormRow>
        <FormColumn>
          <FormGroup>
            <Label column htmlFor="user-name">
              Name
            </Label>
            <Field
              component={TextField}
              name="username"
              id="user-name"
              type="text"
            />
          </FormGroup>
        </FormColumn>
      </FormRow>
    </>
  );
};
