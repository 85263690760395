import {
  QuestionIcon,
  TextField,
  useInput,
} from '@fcg-tech/regtech-components';
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import {
  FilterClearButton,
  FilterRow,
  FilterRowLabel,
  FilterRowLabelButton,
  FilterRowLabelLeft,
} from './Filter.styles';
import { FilterCommonProps, FilterValues, FilterWidgetProps } from './types';
import { getStringOrFirstStringValue } from './utils';

interface FilterTextFieldProps<T extends FilterValues>
  extends Omit<FilterCommonProps<T>, 'getNoResultsMessage' | 'loadingMessage'>,
    FilterWidgetProps<T> {
  onHelpClick?: (filterPropType: keyof T) => void;
}

export const FilterTextField = <T extends FilterValues>({
  filter,
  filterId,
  filterPropKey,
  excludePropertyKey,
  className,
  allowExclude,
  label,
  clearLabel,
  excludeLabel,
  noItemsLabel,
  isExcluding,
  children,
  onClear,
  onChange,
  onExclude,
  onHelpClick,
}: React.PropsWithChildren<FilterTextFieldProps<T>>) => {
  const handleHelpClick = useCallback(
    () => onHelpClick?.(filterPropKey),
    [filterPropKey, onHelpClick],
  );

  const handleClear = useCallback(
    () => onClear?.(filterPropKey),
    [filterPropKey, onClear],
  );

  const [value, handleChange, setValue] = useInput(
    getStringOrFirstStringValue(filter?.[filterPropKey]) ?? '',
  );

  const handleBlur = useCallback(() => {
    if (filter?.[filterPropKey] !== value) {
      onChange?.(filterPropKey, value, filterId);
    }
  }, [filter, filterPropKey, onChange, value, filterId]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.currentTarget.blur();
      }
    },
    [],
  );

  useEffect(() => {
    setValue(getStringOrFirstStringValue(filter?.[filterPropKey]) ?? '');
  }, [filter, filterPropKey, setValue]);

  return (
    <>
      <FilterRowLabel as="div">
        <FilterRowLabelLeft>
          {label}
          {onHelpClick ? (
            <FilterRowLabelButton onClick={handleHelpClick}>
              <QuestionIcon size="14" />
            </FilterRowLabelButton>
          ) : null}
        </FilterRowLabelLeft>
        {onClear ? (
          <FilterClearButton onClick={handleClear}>
            {clearLabel}
          </FilterClearButton>
        ) : null}
      </FilterRowLabel>
      <TextField
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
      />
      {children}
    </>
  );
};
