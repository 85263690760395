import React, { FunctionComponent } from 'react';
import { Notification, NotificationType } from '@fcg-tech/regtech-components';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import { NonBlockingError } from '../../types';

import {
  ValidationSummaryWrapper,
  ValidationSummaryTitle,
  ValidationSummarySubTitle,
  ValidationSummaryList,
  ValidationSummaryListItem,
} from './ValidationSummary.styles';

export interface ValidationSummaryProps {
  title?: string;
  nonBlockingErrors?: Array<NonBlockingError>;
}

export const ValidationSummary: FunctionComponent<ValidationSummaryProps> = ({
  title,
  nonBlockingErrors,
}) => {
  const { t } = useTranslation();

  const nonBlockingErrorsEBA = React.useMemo(() => {
    return nonBlockingErrors
      ?.filter((err) => err.validationGroup === 'EBA')
      .map((item) => item.message);
  }, [nonBlockingErrors]);

  const nonBlockingErrorsResolvability = React.useMemo(() => {
    return nonBlockingErrors
      ?.filter((err) => err.validationGroup === 'Resolvability')
      .map((item) => item.message);
  }, [nonBlockingErrors]);

  const nonBlockingErrorsOther = React.useMemo(() => {
    return nonBlockingErrors
      ?.filter((err) => err.validationGroup === 'Other')
      .map((item) => item.message);
  }, [nonBlockingErrors]);

  const nonBlockingErrorsHistory = React.useMemo(() => {
    return nonBlockingErrors
      ?.filter((err) => err.validationGroup === undefined)
      .map((item) => item.message);
  }, [nonBlockingErrors]);

  if (!nonBlockingErrors?.length) {
    return null;
  }

  return (
    <ValidationSummaryWrapper>
      <Notification type={NotificationType.Warning}>
        <ValidationSummaryTitle>{title}</ValidationSummaryTitle>
        {nonBlockingErrorsEBA?.length ? (
          <ValidationSummarySubTitle>
            {t(MessageKeys.ValidationSummaryAuthorityLabel)}
          </ValidationSummarySubTitle>
        ) : null}
        <ValidationSummaryList>
          {nonBlockingErrorsEBA?.map((comment) => (
            <ValidationSummaryListItem key={comment}>
              {comment}
            </ValidationSummaryListItem>
          ))}
        </ValidationSummaryList>
        {nonBlockingErrorsResolvability?.length ? (
          <ValidationSummarySubTitle>
            {t(MessageKeys.ValidationSummaryResolvabilityLabel)}
          </ValidationSummarySubTitle>
        ) : null}
        <ValidationSummaryList>
          {nonBlockingErrorsResolvability?.map((comment) => (
            <ValidationSummaryListItem key={comment}>
              {comment}
            </ValidationSummaryListItem>
          ))}
        </ValidationSummaryList>
        {nonBlockingErrorsOther?.length ? (
          <ValidationSummarySubTitle>
            {t(MessageKeys.ValidationSummaryOtherLabel)}
          </ValidationSummarySubTitle>
        ) : null}
        <ValidationSummaryList>
          {nonBlockingErrorsOther?.map((comment) => (
            <ValidationSummaryListItem key={comment}>
              {comment}
            </ValidationSummaryListItem>
          ))}
        </ValidationSummaryList>
        <ValidationSummaryList>
          {nonBlockingErrorsHistory?.map((comment) => (
            <ValidationSummaryListItem key={comment}>
              {comment}
            </ValidationSummaryListItem>
          ))}
        </ValidationSummaryList>
      </Notification>
    </ValidationSummaryWrapper>
  );
};
