import {
  Modal,
  ModalBody,
  ModalButtonRow,
  ModalHeader,
  ModalTitle,
  PrimaryButton,
  SecondaryButton,
} from '@fcg-tech/regtech-components';
import { useIsMountedRef } from '@fcg-tech/regtech-utils';
import update from 'immutability-helper';
import { FunctionComponent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import { FileUploadZone } from '../FileUploadZone';
import { FileUploadModalContent } from './FileUploadModal.styles';

interface FileUploadModalProps {
  allowMultiple?: boolean;
  onSubmit: (files: Array<File>) => Promise<void>;
  onRequestClose: () => void;
}

export const FileUploadModal: FunctionComponent<FileUploadModalProps> = ({
  allowMultiple,
  onSubmit,
  onRequestClose,
}) => {
  const { t } = useTranslation();
  const isMounted = useIsMountedRef();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<Array<File>>([]);

  const handleDrop = useCallback(async (acceptedFiles: Array<File>) => {
    setFiles((old) => {
      return update(old, { $push: acceptedFiles });
    });
    return true;
  }, []);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    await onSubmit(files);
    if (isMounted.current) {
      setLoading(false);
    }
    onRequestClose();
  }, [files, isMounted, onRequestClose, onSubmit]);

  const handleRemoveFile = useCallback(async (file: File) => {
    setFiles((old) => {
      const index = old.indexOf(file);
      return update(old, { $splice: [[index, 1]] });
    });
    return true;
  }, []);

  return (
    <Modal>
      <ModalHeader>
        <ModalTitle>{t(MessageKeys.LabelUploadFiles)}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <FileUploadModalContent>
          <FileUploadZone
            files={files}
            allowMultiple={allowMultiple}
            onFileDrop={handleDrop}
            onRemoveFile={handleRemoveFile}
          />
          <ModalButtonRow>
            <SecondaryButton onClick={onRequestClose}>
              {t(MessageKeys.LabelCancel)}
            </SecondaryButton>
            <PrimaryButton
              onClick={handleSubmit}
              loading={loading}
              disabled={loading || files.length === 0}
            >
              {t(MessageKeys.LabelUploadFiles)}
            </PrimaryButton>
          </ModalButtonRow>
        </FileUploadModalContent>
      </ModalBody>
    </Modal>
  );
};
