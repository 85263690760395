import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  PrimaryButton,
  SecondaryButton,
} from '@fcg-tech/regtech-components';

const ButtonGroup = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  & > * + * {
    margin-left: 1rem;
  }
`;

export const FormModal = ({
  title,
  children,
  initialValues,
  onSubmit,
  onCancel,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ handleSubmit, ...rest }) => (
        <Form>
          <Modal onHide={onCancel}>
            <ModalHeader>
              <ModalTitle>{title}</ModalTitle>
            </ModalHeader>
            <ModalBody>{children({ handleSubmit, ...rest })}</ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <PrimaryButton onClick={handleSubmit}>Save</PrimaryButton>
                <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
              </ButtonGroup>
            </ModalFooter>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

FormModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.any.isRequired,
  children: PropTypes.func,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

FormModal.defaultProps = {
  children: null,
  title: null,
  onSubmit: null,
  onCancel: null,
};
