import { DataTableCellProps } from '@fcg-tech/regtech-datatable';
import { FunctionComponent } from 'react';
import { useRecoilValue } from 'recoil';
import { country } from '../../states/countryState';
import { SupplierListItem, SupplierTableColumns } from '../../types';
import { SupplierTableTextContent } from './SupplierTable.styles';

type SupplierTableCellProps = DataTableCellProps<SupplierListItem>;

export const SupplierTableCell: FunctionComponent<SupplierTableCellProps> = ({
  cell,
  row,
}) => {
  const supplier = row.original;
  const selectedCountry = useRecoilValue(
    country(supplier?.countryOfRegistration),
  );

  switch (cell.column.id) {
    case SupplierTableColumns.Name:
      return (
        <SupplierTableTextContent title={supplier?.supplierName}>
          {supplier?.supplierName}
        </SupplierTableTextContent>
      );

    case SupplierTableColumns.CountryOfRegistration:
      return (
        <SupplierTableTextContent title={selectedCountry?.name}>
          {selectedCountry?.name}
        </SupplierTableTextContent>
      );

    case SupplierTableColumns.CorporateRegistrationNumber:
      return (
        <SupplierTableTextContent title={supplier?.corporateRegistrationNumber}>
          {supplier?.corporateRegistrationNumber}
        </SupplierTableTextContent>
      );
  }
};
