import React, { useCallback, useMemo } from 'react';
import { KeyLabelItem } from '../../types/misc';
import { itemKeyGetter, itemLabelGetter } from '../../utils';
import { ItemList } from '../ItemList';
import { Select } from '../Select';
import {
  PillCollection,
  PillMultiSelectWrapper,
  ReadOnlyContainer,
  StyledInfoCircle,
  StyledPill,
} from './PillMultiSelect.styles';

export interface PillMultiSelectPropsRender {
  (opts: {
    value: string | number;
    removable: boolean;
    onRemove: (value: string | number) => void;
  }): JSX.Element | Array<JSX.Element>;
}

export interface PillMultiSelectProps {
  items: Array<string | number | KeyLabelItem>;
  info: string;
  isEditEnabled?: boolean;
  typeahead?: boolean;
  value: Array<string | number>;
  onChange: (value: string | number) => void;
  onRemoveValue: (value: string | number) => void;
  children?: PillMultiSelectPropsRender;
}

export const PillMultiSelect = ({
  info,
  typeahead,
  isEditEnabled = true,
  items: options,
  value: selectedOptions = [],
  onChange,
  onRemoveValue,
  children,
}: PillMultiSelectProps) => {
  const getItemLabel = useCallback(
    (key) => {
      const option = options.find((i) => itemKeyGetter(i) === key);
      return option ? itemLabelGetter(option) : null;
    },
    [options],
  );

  const handleSelectChange = useCallback(
    (value: string | number) => {
      if (value && onChange) {
        onChange(value);
      }
    },
    [onChange],
  );

  const handleRemovePill = useCallback(
    (value: string | number) => {
      if (value && onRemoveValue) {
        onRemoveValue(value);
      }
    },
    [onRemoveValue],
  );

  const filteredOptions = useMemo(
    () =>
      options.filter(
        (option) => !selectedOptions.includes(itemKeyGetter(option)),
      ),
    [options, selectedOptions],
  );

  return (
    <PillMultiSelectWrapper>
      {isEditEnabled && selectedOptions.length ? (
        <PillCollection>
          {selectedOptions.map((option: string | number) =>
            children ? (
              children({
                value: option,
                removable: isEditEnabled,
                onRemove: handleRemovePill,
              })
            ) : (
              <StyledPill
                key={option}
                value={option}
                removable={isEditEnabled}
                onRemove={handleRemovePill}
              >
                {getItemLabel(option)}
              </StyledPill>
            ),
          )}
        </PillCollection>
      ) : null}
      {isEditEnabled ? (
        <Select
          items={filteredOptions}
          info={info}
          typeahead={typeahead}
          isEditEnabled={isEditEnabled}
          onChange={handleSelectChange}
        />
      ) : null}
      {!isEditEnabled ? (
        <ReadOnlyContainer>
          <ItemList>
            {selectedOptions.map((option) => (
              <ItemList.Item key={option}>{getItemLabel(option)}</ItemList.Item>
            ))}
          </ItemList>
          {info && <StyledInfoCircle info={info} />}
        </ReadOnlyContainer>
      ) : null}
    </PillMultiSelectWrapper>
  );
};
