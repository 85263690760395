import { EmptyPropsWithChildren } from '@fcg-tech/regtech-types';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { excludeProps } from '@fcg-tech/regtech-components';

export const FormColumnWrapper = styled.div.withConfig<{ empty?: boolean }>(
  excludeProps('empty'),
)`
  ${({ theme, empty }) => css`
    position: relative;
    flex: 1;

    padding: 0 2rem 0 1rem;

    & + & {
      padding-left: 2rem;
      border-left: ${empty
        ? '0'
        : `1px dashed ${theme?.colors?.border ?? '#000'}`};
    }

    &:last-child {
      padding-right: 1rem;
    }

    &:empty {
      border-left: 0 !important;
    }

    @media screen and (max-width: ${theme.breakpoints.mobile}px) {
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-bottom: 2rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  `}
`;

export const FormColumn: FunctionComponent<PropsWithChildren<EmptyPropsWithChildren>> = ({ children, ...props }) => (
  <FormColumnWrapper empty={React.Children.count(children) === 0} {...props}>
    {children}
  </FormColumnWrapper>
);
