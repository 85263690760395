import styled from 'styled-components';
import { FlashNotificationManager, H1 } from '@fcg-tech/regtech-components';

export const MainLayoutWrapper = styled.div`
  overflow: hidden;
  height: 100vh;
  display: flex;
`;

export const MainLayoutContent = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: stretch;
  max-height: 100vh;
  min-width: 0;
  position: relative;
`;

export const MainLayoutPageWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  max-height: calc(100vh - 7rem);
  position: relative;
`;

// temp-fix, can be removed when all containers are converted to TS
export const MainLayoutPageInnerWrapper = styled.div`
  max-height: 100%;
  height: 100%;
`;

export const MainLayoutPaddedContentWrapper = styled.div`
  padding: 1.5rem;

  & > ${H1}:first-child {
    margin: 1.6rem 0 0.6rem 0;
  }

  &.sticky {
    position: sticky;
    top: 0;
    background-color: white;
    border-bottom: 1px solid #eee;
  }
`;

export const MainLayoutFlashNotificationWrapper = styled(
  FlashNotificationManager,
)`
  position: absolute;
`;
