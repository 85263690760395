import { useTranslation } from 'react-i18next';
import {
  FormRow,
  Modal,
  ModalBody,
  ModalButtonRow,
  ModalHeader,
  ModalTitle,
  PrimaryButton,
  SecondaryButton,
  TextField,
  useFocus,
  useInput,
} from '@fcg-tech/regtech-components';
import React, { FunctionComponent, useCallback, useState } from 'react';
import {
  ModalFormLabel,
  ModalFormWrapper,
} from '../modals/modalFormComponents';
import { MessageKeys } from '../../translations/messageKeys';

interface FilterSaveDialogProps {
  onSubmit: (name: string) => Promise<void>;
  onRequestClose: () => void;
}

export const FilterSaveDialog: FunctionComponent<FilterSaveDialogProps> = ({
  onSubmit,
  onRequestClose,
}) => {
  const { t } = useTranslation();
  const [name, handleNameChange] = useInput('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      setLoading(true);
      await onSubmit(name);
      onRequestClose();
    },
    [onSubmit, name, onRequestClose],
  );

  const ref = useFocus<HTMLInputElement>();

  return (
    <Modal onHide={onRequestClose}>
      <ModalHeader>
        <ModalTitle>
          {t(MessageKeys.FilterSaveFilterButtonLabel)}
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <ModalFormWrapper onSubmit={handleSubmit}>
          <FormRow>
            <ModalFormLabel htmlFor="name">
              {t(MessageKeys.FilterSaveFilterNameLabel)}
            </ModalFormLabel>
            <TextField
              name="name"
              ref={ref}
              value={name}
              onChange={handleNameChange}
            />
          </FormRow>
          <ModalButtonRow>
            <SecondaryButton
              type="button"
              onClick={onRequestClose}
              disabled={loading}
            >
              {t(MessageKeys.LabelCancel)}
            </SecondaryButton>
            <PrimaryButton type="submit" disabled={!name} loading={loading}>
              {t(MessageKeys.LabelSubmit)}
            </PrimaryButton>
          </ModalButtonRow>
        </ModalFormWrapper>
      </ModalBody>
    </Modal>
  );
};
