import { EmptyPropsWithChildren } from '@fcg-tech/regtech-types';
import React, {
  createContext,
  FunctionComponent,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import update from 'immutability-helper';

export type SideBarContextProps = {
  isHidden: boolean;
  isFloatingMenuVisible: boolean;
  aniamteFloatingMenu: boolean;
  // Needs to be in the type so SideBar can override internally
  isFloating: boolean;
  setIsHidden?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFloatingMenuVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  setAnimateFloatingMenu?: React.Dispatch<React.SetStateAction<boolean>>;
  expandedHrefs: Array<string>;
  toggleExpandedHref: (href: string, isExpanded: boolean) => void;
  toggleMobileMenu: () => void;
};

export const SideBarContext = createContext<SideBarContextProps>({
  isFloatingMenuVisible: false,
  isHidden: false,
  isFloating: false,
  aniamteFloatingMenu: false,
  expandedHrefs: [],
  toggleExpandedHref: () => null,
  toggleMobileMenu: () => null,
});

export const SideBarContextProvider: FunctionComponent<EmptyPropsWithChildren> = ({ children }) => {
  const [isHidden, setIsHidden] = useState(false);
  const [isFloatingMenuVisible, setIsFloatingMenuVisible] = useState(false);
  const [aniamteFloatingMenu, setAnimateFloatingMenu] = useState(false);
  const [expandedHrefs, setExpandedHrefs] = useState([]);

  const toggleExpandedHref = useCallback(
    (href: string, isExpanded: boolean) => {
      setExpandedHrefs((old) => {
        const index = old.indexOf(href);
        if (isExpanded && index === -1) {
          return update(old, { $push: [href] });
        } else if (!isExpanded && index >= 0) {
          return update(old, { $splice: [[index, 1]] });
        }

        return old;
      });
    },
    [],
  );

  const toggleMobileMenu = useCallback(() => {
    if (isFloatingMenuVisible) {
      // Menu will disappear
      setAnimateFloatingMenu(false);
      setTimeout(() => setIsFloatingMenuVisible(false), 5000);
    } else {
      setIsFloatingMenuVisible(true);
      setTimeout(() => setAnimateFloatingMenu(true), 0);
    }
  }, [isFloatingMenuVisible]);

  const value = useMemo(
    () => ({
      isHidden,
      isFloating: false,
      isFloatingMenuVisible,
      aniamteFloatingMenu,
      setIsHidden,
      setIsFloatingMenuVisible,
      setAnimateFloatingMenu,
      expandedHrefs,
      toggleExpandedHref,
      toggleMobileMenu,
    }),
    [
      aniamteFloatingMenu,
      expandedHrefs,
      isFloatingMenuVisible,
      isHidden,
      toggleExpandedHref,
      toggleMobileMenu,
    ],
  );
  return (
    <SideBarContext.Provider value={value}>{children}</SideBarContext.Provider>
  );
};

export const useSideBar = () => useContext(SideBarContext);
