import { InfoCircle } from '@fcg-tech/regtech-components';
import styled from 'styled-components';

export const FormSelectErrorMessage = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 0.4rem;
  font-size: 1.2rem;
  color: ${({ theme }) => theme?.colors?.error?.main ?? 'red'};
`;

export const FormSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
`;

export const FormSelectReadOnlyWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
`;

export const FormSelectReadOnlyLabel = styled.div`
  display: flex;
  flex: 1;
  font-size: 1.4rem;
  padding: 1rem 1rem 1rem 0;
  border-radius: 3px;
  min-width: 7rem;
`;

export const StyledInfoCircle = styled(InfoCircle)`
  margin-left: 1rem;
`;
