import { FunctionComponent, PropsWithChildren } from 'react';
import { ErrorBoundary } from '../ErrorBoundary';
import {
  DashboardCardBody,
  DashboardCardHeader,
  DashboardCardTitle,
  DashboardCardWrapper,
} from './Dashboard.styles';

interface DashboardCardProps {
  title: string | JSX.Element;
  headerButton?: JSX.Element;
  className?: string;
}

export const DashboardCard: FunctionComponent<PropsWithChildren<DashboardCardProps>> = ({
  title,
  headerButton,
  className,
  children,
}) => {
  return (
    <DashboardCardWrapper className={className}>
      <DashboardCardHeader>
        <DashboardCardTitle>{title}</DashboardCardTitle>
        {headerButton}
      </DashboardCardHeader>
      <DashboardCardBody>
        <ErrorBoundary>{children}</ErrorBoundary>
      </DashboardCardBody>
    </DashboardCardWrapper>
  );
};
