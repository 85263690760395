/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { classNames } from '@fcg-tech/regtech-utils';
import React, { FunctionComponent } from 'react';
import { LabelWrapper } from './Label.styles';

export interface LabelProps
  extends React.HTMLAttributes<HTMLDivElement | HTMLLabelElement> {
  column?: boolean;
  htmlFor?: string;
}

export const Label: FunctionComponent<LabelProps> = ({
  column,
  htmlFor,
  className,
  ...props
}) => {
  return htmlFor || React.Children.count(props.children) > 1 ? (
    <LabelWrapper
      {...props}
      className={classNames(className, column && 'column')}
      htmlFor={htmlFor}
    />
  ) : (
    <LabelWrapper
      as="div"
      {...props}
      className={classNames(className, column && 'column')}
    />
  );
};
