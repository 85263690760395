import { useTranslation } from 'react-i18next';
import { mergeNotifications, NotificationsList } from '@fcg-tech/notifications';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import {
  Checkbox,
  MultiStaticButton,
  SecondaryButton,
  useFlash,
  useStickyObserver,
} from '@fcg-tech/regtech-components';
import { classNames, useMatchMutate } from '@fcg-tech/regtech-utils';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  useNotifications,
  useNotificationsReadActions,
} from '../../api/hooks/notificationsApiHooks';
import { routes } from '../../routes';
import { useTenant } from '../../states/tenantState';
import { MessageKeys } from '../../translations/messageKeys';
import { fixPath } from './notificationPageUtils';
import {
  NotificationsActionBar,
  NotificationsActionBarMarkAsReadButton,
  NotificationsActionBarSide,
  NotificationsListWrapper,
} from './NotificationsContainer.styles';

export interface NotificationsContainerProps {
  filter?: 'all' | 'unread';
}

export const NotificationsContainer: FunctionComponent<
  NotificationsContainerProps
> = ({ filter }) => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const [lastNotificationId, setLastNotificationId] = useState(undefined);
  const [isMarkingAsRead, setIsMarkingAsRead] = useState(false);
  const { data, isValidating, mutate } = useNotifications(
    {
      offset: lastNotificationId,
      unreadOnly: filter === 'unread',
    },
    {
      suspense: !lastNotificationId,
    },
  );

  const matchMutate = useMatchMutate();

  const { markNotificationsAsRead } = useNotificationsReadActions();

  const [notifications, setNotifications] = useState(
    (data?.items ?? []).map(fixPath),
  );
  const [hasMoreNotifications, setHasMoreNotifications] = useState(
    data?.truncated ?? false,
  );

  const addFlash = useFlash();

  const handleLoadMoreNotifications = useCallback(() => {
    setLastNotificationId(data?.lastId);
  }, [data]);

  const [selected, setSelected] = useState<Array<string>>([]);

  const handleSelectedChange = useCallback((selected: Array<string>) => {
    setSelected(selected);
  }, []);

  useEffect(() => {
    setHasMoreNotifications(data?.truncated);
    if (data?.items) {
      setNotifications((items) => {
        return mergeNotifications(items, data.items);
      });
    }
  }, [data?.items, data?.truncated]);

  const handleMarkAsReadClick = useCallback(async () => {
    setIsMarkingAsRead(true);
    try {
      await markNotificationsAsRead(selected);
      if (filter === 'unread') {
        setNotifications([]);
      }
      setSelected([]);
      matchMutate(/notifications\/oasys/, null, false);
      mutate();
    } catch (e) {
      addFlash({
        level: 'error',
        content: t(MessageKeys.LabelCouldNotSave),
      });
    }
    setIsMarkingAsRead(false);
  }, [
    addFlash,
    filter,
    markNotificationsAsRead,
    matchMutate,
    mutate,
    selected,
    t,
  ]);

  const actionRef = useRef<HTMLDivElement>();
  const isActionBarSticky = useStickyObserver(actionRef);

  const [allFilled, someFilled] = useMemo(
    () => [
      notifications.every((n) => selected.includes(n.id)),
      notifications.some((n) => selected.includes(n.id)),
    ],
    [notifications, selected],
  );

  const handleMasterSelectChange = useCallback(() => {
    if (allFilled) {
      setSelected([]);
    } else {
      setSelected(notifications.map((n) => n.id));
    }
  }, [allFilled, notifications]);

  return (
    <NotificationsListWrapper>
      <NotificationsActionBar
        ref={actionRef}
        className={classNames(isActionBarSticky && 'sticky-floating')}
      >
        <NotificationsActionBarSide>
          <Checkbox
            checked={allFilled && notifications?.length > 0}
            partiallyChecked={!allFilled && someFilled}
            onChange={handleMasterSelectChange}
          />
          <NotificationsActionBarMarkAsReadButton
            disabled={selected.length === 0}
            loading={isMarkingAsRead}
            onClick={handleMarkAsReadClick}
          >
            {t(MessageKeys.LabelMarkAsDone)}
          </NotificationsActionBarMarkAsReadButton>
        </NotificationsActionBarSide>
        <NotificationsActionBarSide>
          <MultiStaticButton selected={`${filter}-notifications`}>
            <SecondaryButton
              id="all-notifications"
              to={constructUrl(
                routes.notifications,
                { tenantId: tenant.id },
                { filter: 'all' },
              )}
            >
              All
            </SecondaryButton>
            <SecondaryButton
              id="unread-notifications"
              to={constructUrl(
                routes.notifications,
                { tenantId: tenant.id },
                { filter: 'unread' },
              )}
            >
              Unread
            </SecondaryButton>
          </MultiStaticButton>
        </NotificationsActionBarSide>
      </NotificationsActionBar>
      <NotificationsList
        notifications={notifications}
        emptyMessage="You have no notifications"
        loadMoreNotifications={handleLoadMoreNotifications}
        isLoading={isValidating}
        isMoreNotifications={hasMoreNotifications}
        selected={selected}
        onSelectionChange={handleSelectedChange}
      />
    </NotificationsListWrapper>
  );
};
