import { FunctionComponent } from 'react';
import { AgreementTableContextProvider } from '../../components/AgreementTable/AgreementTableContext';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { PageScrollingWrapper } from '../../components/Page';
import { SuspenseLoader } from '../../components/SuspenseLoader';
import { AgreementTableType } from '../../types';
import { AgreementsOverviewTable } from './AgreementsOverviewTable';

export const AgreementsOverviewPage: FunctionComponent = () => {
  return (
    <PageScrollingWrapper>
      <ErrorBoundary>
        <SuspenseLoader>
          <AgreementTableContextProvider
            tableType={AgreementTableType.Overview}
          >
            <AgreementsOverviewTable />
          </AgreementTableContextProvider>
        </SuspenseLoader>
      </ErrorBoundary>
    </PageScrollingWrapper>
  );
};
