import { ColorWithContrast } from '@fcg-tech/regtech-types';

export type FoundationColorKey =
  | 'emerald100'
  | 'emerald150'
  | 'emerald200'
  | 'emerald300'
  | 'emerald400'
  | 'emerald500'
  | 'emerald600'
  | 'gold100'
  | 'gold200'
  | 'gold200'
  | 'gold300'
  | 'gold400'
  | 'gold500'
  | 'gold600'
  | 'gold700'
  | 'gold800'
  | 'gold900'
  | 'onyx100'
  | 'onyx200'
  | 'onyx250'
  | 'onyx300'
  | 'onyx350'
  | 'onyx400'
  | 'onyx450'
  | 'onyx450'
  | 'onyx500'
  | 'topaz100'
  | 'topaz125'
  | 'topaz150'
  | 'topaz175'
  | 'topaz200'
  | 'topaz250'
  | 'topaz300'
  | 'topaz400'
  | 'topaz500'
  | 'ruby100'
  | 'ruby200'
  | 'ruby300'
  | 'ruby350'
  | 'ruby400'
  | 'ruby500'
  | 'ruby600'
  | 'ruby700'
  | 'ruby800'
  | 'opal100'
  | 'opal200'
  | 'opal300'
  | 'opal350'
  | 'opal400'
  | 'opal500';

export type Foundation = {
  palette: Record<FoundationColorKey, ColorWithContrast>;
  typography: Record<
    string,
    {
      fontSizeRem: number;
      fontSizePx: number;
      fontWeight?: number;
      marginTopRem?: number;
      marginBottomRem?: number;
    }
  >;
};

export type Adaptation<T extends string> = {
  palette: Record<T, ColorWithContrast>;
};

export const foundation: Foundation = {
  palette: {
    emerald100: { hex: '#e9f9f6', contrastHex: '#131313' },
    emerald150: { hex: '#c9e8e0', contrastHex: '#131313' },
    emerald200: { hex: '#28D9AD', contrastHex: '#131313' },
    emerald300: { hex: '#1EC59B', contrastHex: '#131313' },
    emerald400: { hex: '#006659', contrastHex: '#EDF5F7' },
    emerald500: { hex: '#004C42', contrastHex: '#EDF5F7' },
    emerald600: { hex: '#093933', contrastHex: '#EDF5F7' },
    gold100: { hex: '#FCFBF7', contrastHex: '#131313' },
    gold200: { hex: '#F8F3EA', contrastHex: '#131313' },
    gold300: { hex: '#F3EADA', contrastHex: '#131313' },
    gold400: { hex: '#ECDEC5', contrastHex: '#131313' },
    gold500: { hex: '#E5D3B2', contrastHex: '#131313' },
    gold600: { hex: '#CEA964', contrastHex: '#131313' },
    gold700: { hex: '#A8761A', contrastHex: '#E9F9F6' },
    gold800: { hex: '#664B19', contrastHex: '#E9F9F6' },
    gold900: { hex: '#47320B', contrastHex: '#E9F9F6' },
    onyx100: { hex: '#F5F5F5', contrastHex: '#131313' },
    onyx200: { hex: '#DDDDDD', contrastHex: '#131313' },
    onyx250: { hex: '#BCC1C2', contrastHex: '#131313' },
    onyx300: { hex: '#AAAAAA', contrastHex: '#EDF5F7' },
    onyx350: { hex: '#6D7578', contrastHex: '#EDF5F7' },
    onyx400: { hex: '#666666', contrastHex: '#EDF5F7' },
    onyx450: { hex: '#3D4143', contrastHex: '#EDF5F7' },
    onyx500: { hex: '#131313', contrastHex: '#EDF5F7' },
    topaz100: { hex: '#ECF3FC', contrastHex: '#131313' },
    topaz125: { hex: '#E0EBF6', contrastHex: '#131313' },
    topaz150: { hex: '#C5D8EC', contrastHex: '#131313' },
    topaz175: { hex: '#A7C9DE', contrastHex: '#131313' },
    topaz200: { hex: '#2175D4', contrastHex: '#EDF5F7' },
    topaz250: { hex: '#4981B8', contrastHex: '#EDF5F7' },
    topaz300: { hex: '#1A5CA7', contrastHex: '#EDF5F7' },
    topaz400: { hex: '#193764', contrastHex: '#EDF5F7' },
    topaz500: { hex: '#0B2747', contrastHex: '#EDF5F7' },
    ruby100: { hex: '#FCF6F6', contrastHex: '#131313' },
    ruby200: { hex: '#FDEAEA', contrastHex: '#131313' },
    ruby300: { hex: '#FCD7D7', contrastHex: '#131313' },
    ruby350: { hex: '#ECC5CC', contrastHex: '#131313' },
    ruby400: { hex: '#CE6276', contrastHex: '#EDF5F7' },
    ruby500: { hex: '#C64860', contrastHex: '#EDF5F7' },
    ruby600: { hex: '#9E243B', contrastHex: '#EDF5F7' },
    ruby700: { hex: '#661928', contrastHex: '#EDF5F7' },
    ruby800: { hex: '#470B16', contrastHex: '#EDF5F7' },
    opal100: { hex: '#EDF5F8', contrastHex: '#131313' },
    opal200: { hex: '#9CC9D9', contrastHex: '#131313' },
    opal300: { hex: '#3C788F', contrastHex: '#E9F9F6' },
    opal350: { hex: '#2D5A6C', contrastHex: '#131313' },
    opal400: { hex: '#004966', contrastHex: '#E9F9F6' },
    opal500: { hex: '#092C39', contrastHex: '#E9F9F6' },
  },

  typography: {
    h1: {
      fontSizeRem: 3.2,
      fontSizePx: 32,
      fontWeight: 500,
      marginTopRem: 1.6,
      marginBottomRem: 1.6,
    },
    h2: {
      fontSizeRem: 2.4,
      fontSizePx: 24,
      fontWeight: 600,
      marginTopRem: 1.2,
      marginBottomRem: 1.2,
    },
    h3: {
      fontSizeRem: 2.4,
      fontSizePx: 24,
      fontWeight: 400,
      marginTopRem: 1.2,
      marginBottomRem: 1.2,
    },
    h4: {
      fontSizeRem: 1.8,
      fontSizePx: 18,
      fontWeight: 500,
      marginTopRem: 0.9,
      marginBottomRem: 0.9,
    },
    p: {
      fontSizeRem: 1.6,
      fontSizePx: 16,
      fontWeight: 400,
      marginTopRem: 0.9,
      marginBottomRem: 0.9,
    },
  },
};
