import { useCallback, useRef, useState } from 'react';
import type { ConfirmDialogProps } from './ConfirmDialog';

type ConfirmResult = [confirmed: boolean, doNotShowAgain: boolean] | false;

export const useConfirmDialog = (
  confirmCallback?: ConfirmDialogProps['onChoice'],
): [
  showDialog: boolean,
  handleConfirmChoice: ConfirmDialogProps['onChoice'],
  confirm: () => Promise<ConfirmResult>,
] => {
  const [showDialog, setShowDialog] = useState(false);
  const resolveFn = useRef<(ConfirmResult) => void>();

  const handleConfirm = useCallback<ConfirmDialogProps['onChoice']>(
    (confirm, doNotShowAgain) => {
      setShowDialog(false);
      confirmCallback?.(confirm, doNotShowAgain);
      if (resolveFn.current) {
        if (confirm) {
          resolveFn.current([confirm, doNotShowAgain]);
        } else {
          resolveFn.current(false);
        }
        resolveFn.current = undefined;
      }
    },
    [confirmCallback],
  );

  const confirm = useCallback(async () => {
    setShowDialog(true);
    return new Promise<ConfirmResult>((resolve) => {
      resolveFn.current = resolve;
    });
  }, []);

  return [showDialog, handleConfirm, confirm];
};
