import React, { FunctionComponent } from 'react';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { PageScrollingWrapper } from '../../components/Page';
import { SuspenseLoader } from '../../components/SuspenseLoader';
import { DashboardHoistGettingStartedCard } from './cards/DashboardHoistGettingStartedCard';
import { DashboardNotificationsCard } from './cards/DashboardNotificationsCard';
import {
  DashboardPageCardsGrid,
  DashboardPageWrapper,
  StyledMainLayoutPaddedContentWrapper,
} from './Dashboard.styles';

const DashboardComplianceCard = React.lazy(
  () => import('./cards/DashboardComplianceCard'),
);
const DashboardSummaryCard = React.lazy(
  () => import('./cards/DashboardSummaryCard'),
);
const DashboardUpcomingYearCard = React.lazy(
  () => import('./cards/DashboardUpcomingYearCard'),
);

export const DashboardPage: FunctionComponent = () => {
  return (
    <PageScrollingWrapper>
      <ErrorBoundary>
        <SuspenseLoader>
          <StyledMainLayoutPaddedContentWrapper>
            <DashboardPageWrapper>
              <DashboardPageCardsGrid>
                <DashboardHoistGettingStartedCard />
                <DashboardNotificationsCard />
                <DashboardSummaryCard />
                <DashboardComplianceCard />
                <DashboardUpcomingYearCard />
              </DashboardPageCardsGrid>
            </DashboardPageWrapper>
          </StyledMainLayoutPaddedContentWrapper>
        </SuspenseLoader>
      </ErrorBoundary>
    </PageScrollingWrapper>
  );
};
