import {
  SingleTenantAuthCallback as AuthCallback,
  SingleTenantAuthProvider as AuthProvider,
} from '@fcg-tech/regtech-auth';
import { GlobalStyle } from '@fcg-tech/regtech-components';
import { getCurrentPathAndQuery } from '@fcg-tech/regtech-utils';
import React, { FunctionComponent } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { SWRConfig } from 'swr';
import { RecoilRoot } from 'recoil';
import { IamContextProvider } from '../../components/IamContext';
import { SuspenseLoader } from '../../components/SuspenseLoader';
import { environment } from '../../environments/environment';
import Features from '../../Features';
import { LoginContainer } from '../auth/LoginContainer';
import { AuthenticatedApp } from './AuthenticatedApp';
import { AuthErrorBoundary } from './AuthErrorBoundary';

export const App: FunctionComponent = () => {
  return (
    <ThemeProvider theme={Features.Theme}>
      <RecoilRoot>
        <GlobalStyle />
        <AuthProvider
          hostedLoginBaseUrl={environment.oauthBaseUrl}
          clientId={environment.oauthClientId}
          scope={environment.oauthScopes}
          returnUrl={getCurrentPathAndQuery()}
        >
          <SuspenseLoader className="maximized">
            <AuthErrorBoundary>
              <SWRConfig value={{ focusThrottleInterval: 30000 }}>
                <Router>
                  <Routes>
                    <Route path="/login" element={<LoginContainer />} />
                    <Route
                      key="authcallback"
                      path="*"
                      element={
                        <AuthCallback>
                          <IamContextProvider>
                            <AuthenticatedApp />
                          </IamContextProvider>
                        </AuthCallback>
                      }
                    />
                  </Routes>
                </Router>
              </SWRConfig>
            </AuthErrorBoundary>
          </SuspenseLoader>
        </AuthProvider>
      </RecoilRoot>
    </ThemeProvider>
  );
};
