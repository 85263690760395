import { single } from '@fcg-tech/regtech-utils';
import { parse } from 'query-string';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
  PropsWithChildren,
} from 'react';
import { useAuthApi } from '../../api';
import { authContext } from '../../authContext';
import {
  endLoginFlow,
  getAccessToken,
  getAuthState,
  logout,
  setAuthState as setLocalStorageAuthState,
  startLoginFlow,
} from '../../authFlow';
import { AuthFlowState, AuthState } from '../../types';
import { parseJwt } from '../../utils';
import { SessionExpiredModal } from '../SessionExpiredModal';
import { UserEmailPrompt } from '../UserEmailPrompt';
import {
  MultiTenantAuthProviderErrorWrapper,
  MultiTenantAuthProviderWrapper,
} from './MultiTenantAuthProvider.styles';

const signoutPath = '/auth/signout';
const baseCallbackUrl = `${window.location.protocol}//${window.location.host}`;
const authCallbackUrl = `${baseCallbackUrl}/auth/callback`;
const authLogoutCallbackUrl = `${baseCallbackUrl}${signoutPath}`;

interface MultiTenantAuthProviderProps {
  hostedLoginBaseUrl: string;
  selectCognitoApiBaseUrl: string;
  responseType?: string;
  returnUrl?: string | (() => string);
  scope?: string;
  getEmailPromptContent?: () => React.ReactNode;
}

export const MultiTenantAuthProvider: FunctionComponent<
  PropsWithChildren<MultiTenantAuthProviderProps>
> = ({
  hostedLoginBaseUrl,
  selectCognitoApiBaseUrl,
  responseType = 'token',
  returnUrl = baseCallbackUrl,
  scope = 'openid+email',
  getEmailPromptContent,
  children,
}) => {
  const { selectCognito } = useAuthApi(selectCognitoApiBaseUrl);
  const isAuthenticating = useRef(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(
    undefined,
  );
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSessionExpired, setShowSessionExpired] = useState(false);

  const [authState, setLocalAuthState] = useState<AuthState>(getAuthState());
  const returnUrlProxy = useRef(returnUrl);
  returnUrlProxy.current = returnUrl;

  useEffect(() => {
    const hash = window.location.hash;
    const parameters = parse(hash.replace(/^#/, ''));
    const stateKey = single(parameters?.state);
    const idToken = single(parameters?.id_token);

    if (window.location.pathname === signoutPath) {
      window.location.assign('/');
    }
    if (idToken) {
      endLoginFlow(idToken);
    }

    const accessToken = getAccessToken(false);
    if (accessToken) {
      const parsed = parseJwt(accessToken);
      if (parsed?.exp < Date.now() / 1000) {
        // Token expired
        setIsAuthenticated(false);
        window.sessionStorage.clear();
      } else {
        setIsAuthenticated(true);
        setLocalAuthState({ ...getAuthState() });
        if (stateKey) {
          const state = JSON.parse(
            window.localStorage.getItem(stateKey) ?? '{}',
          ) as AuthFlowState;

          window.localStorage.removeItem(stateKey);

          if (state?.returnUrl) {
            window.location.assign(state.returnUrl);
          }
        }
      }
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  const startFlow = useCallback(
    async (email: string) => {
      if (!isAuthenticating.current) {
        isAuthenticating.current = true;
        setIsLoading(true);
        setHasError(false);
        try {
          const clientSettings = await selectCognito(email);
          const returnUrl = returnUrlProxy.current;

          setLocalStorageAuthState({
            ...getAuthState(),
            clientId: clientSettings.client_id,
          });

          startLoginFlow({
            clientId: clientSettings.client_id,
            federated: clientSettings.federated,
            idpIdentifier: clientSettings.idp_identifier,
            hostedLoginBaseUrl,
            responseType,
            scope,
            authCallbackUrl,
            returnUrl:
              typeof returnUrl === 'string'
                ? returnUrl
                : returnUrl?.() ?? baseCallbackUrl,
          });
        } catch (error) {
          setIsLoading(false);
          setIsAuthenticated(false);
          isAuthenticating.current = false;
          setHasError(true);
        }
      }
    },
    [hostedLoginBaseUrl, isAuthenticating, responseType, scope, selectCognito],
  );

  const handleEmailSubmitted = useCallback(
    (email: string, rememberMe: boolean) => {
      setLocalStorageAuthState({
        ...getAuthState(),
        email: rememberMe ? email : undefined,
        rememberMe,
      });
      setLocalAuthState({
        ...getAuthState(),
        email,
        rememberMe,
      });

      startFlow(email);
    },
    [startFlow],
  );

  const reAuthenticate = useCallback(() => {
    // Called by other components - defer state updates
    setTimeout(() => {
      const token = getAccessToken(false);
      if (!token) {
        // Weird place
        window.sessionStorage.clear();

        setIsAuthenticated(false);
        setIsLoading(false);
        isAuthenticating.current = false;
      } else {
        window.sessionStorage.clear();

        setIsAuthenticated(false);
        setIsLoading(false);
        isAuthenticating.current = false;
        setShowSessionExpired(true);
      }
    }, 1);
  }, []);

  const handleLogout = useCallback(
    (returnPath?: string) => {
      logout({
        hostedLoginBaseUrl,
        callbackUrl: returnPath
          ? `${baseCallbackUrl}/${returnPath.replace(/^\//, '')}`
          : authLogoutCallbackUrl,
        clientId: authState.clientId,
      });
    },
    [authState.clientId, hostedLoginBaseUrl],
  );

  const handleSessionExpiredSubmit = useCallback(() => {
    setShowSessionExpired(false);
    const authState = getAuthState();
    setLocalAuthState(authState);
    if (authState.email) {
      startFlow(authState.email);
    }
  }, [startFlow]);

  if (isAuthenticated || showSessionExpired) {
    return (
      <authContext.Provider
        value={{
          isAuthenticated: Boolean(isAuthenticated),
          authToken: getAccessToken(),
          logout: handleLogout,
          reAuthenticate,
        }}
      >
        {children}
        {showSessionExpired ? (
          <SessionExpiredModal onSubmit={handleSessionExpiredSubmit} />
        ) : null}
      </authContext.Provider>
    );
  }

  return (
    <MultiTenantAuthProviderWrapper>
      <UserEmailPrompt
        initialEmail={authState.email ?? ''}
        initialRememberMe={authState.rememberMe}
        loading={isLoading}
        onSubmitEmail={handleEmailSubmitted}
      >
        {hasError ? (
          <MultiTenantAuthProviderErrorWrapper>
            An error occurred, please try again. If this problem persists,
            please contact your administrator
          </MultiTenantAuthProviderErrorWrapper>
        ) : null}
        {getEmailPromptContent?.()}
      </UserEmailPrompt>
    </MultiTenantAuthProviderWrapper>
  );
};
