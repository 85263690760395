import React, { FunctionComponent } from 'react';
import { IconProps } from '../../types/icons';

export const ChevronRightIcon: FunctionComponent<IconProps> = ({
  size = '24',
  color = 'currentColor',
  ...props
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    width={size}
    height={size}
    stroke={color}
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="chevron-right-icon_svg__feather chevron-right-icon_svg__feather-chevron-right"
    {...props}
  >
    <path d="M9 18l6-6-6-6" />
  </svg>
);
