import React from 'react';
import { IconProps } from '../../types/icons';

export const CheckIcon = ({
  size = '24',
  color = 'currentColor',
  ...props
}: IconProps) => (
  <svg viewBox="0 0 76.887 55.552" width={size} height={size} {...props}>
    <path
      fill={color}
      d="M34.373 49.541L76.887 5.889 70.739 0 28.426 43.458 6.078 20.632 0 26.585l22.488 22.972 6.029 5.995-.091-.085.091.085 5.856-6.011z"
    />
  </svg>
);
