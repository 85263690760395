import { SortingRule } from '@fcg-tech/regtech-datatable';
import { UseFilterInterface, useFilter } from '@fcg-tech/regtech-filter';
import update from 'immutability-helper';
import { parse, Stringifiable, stringify } from 'query-string';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useStoredAgreementFilters,
  useStoredFilterActions,
  useStoredSupplierFilters,
} from '../api/hooks/filterApiHooks';
import { useTenant } from '../states/tenantState';
import {
  AgreementFilterValues,
  AgreementListItem,
  StoredAgreementFilter,
  StoredSupplierFilter,
  SupplierFilterValues,
  SupplierListItem,
} from '../types';
import {
  getAgreementOrderBy,
  getAgreementSortBy,
  getSupplierOrderBy,
  getSupplierSortBy,
} from './filterHelpers';

export interface UseAgreementFilterInterface
  extends UseFilterInterface<AgreementFilterValues> {
  pinnedFilters: Array<StoredAgreementFilter>;
  sortBy?: SortingRule<AgreementListItem>;
  handleFilterPinned: (filterId: string, pinned: boolean) => Promise<void>;
  handleSortByChange: (sortBy?: SortingRule<AgreementListItem>) => void;
  handleFilterSaved: (
    name: string,
    filterValues: AgreementFilterValues,
    filterId?: string,
  ) => Promise<void>;
  handleFilterDeleted: (filterId: string) => Promise<unknown>;
}

export const useAgreementFilter = (): UseAgreementFilterInterface => {
  const tenant = useTenant();
  const navigate = useNavigate();

  const { data: storedAgreementFilters } = useStoredAgreementFilters(tenant.id);

  const { saveAgreementFilter, deleteAgreementFilter } = useStoredFilterActions(
    tenant.id,
  );

  const modifyParsedFilter = useCallback(
    (filterValues: AgreementFilterValues): AgreementFilterValues => {
      let modified = filterValues;
      if (modified.name) {
        if (Array.isArray(modified.name)) {
          modified = update(modified, {
            name: { $set: modified.name.join(',') },
          });
        } else {
          modified = update(modified, {
            name: { $set: modified.name.toString() },
          });
        }
      }
      if (modified.archived) {
        if (Array.isArray(modified.archived)) {
          modified = update(modified, {
            archived: { $set: modified.archived[0] },
          });
        } else {
          modified = update(modified, {
            archived: { $set: modified.archived },
          });
        }
      }

      return modified;
    },
    [],
  );

  const filterPropsKeys = useMemo<Array<keyof AgreementFilterValues>>(
    () => [
      'name',
      'agreementType',
      'functionCategory',
      'contractOwner',
      'supplierName',
      'partyToAgreement',
      'cabinet',
      'status',
      'startDate',
      'endDate',
      'latestReview',
      'nextReview',
      'competentAuthorityNotified',
      'isOutsourcingArrangement',
      'isCritical',
      'nrOfComments',
      'isPersonalDataTransferred',
      'isPersonalDataProcessed',
      'isProvidedAsCloudService',
      'tags',
      'archived',
      'isPinned',
      'orderBy',
    ],
    [],
  );

  const filterProps = useFilter<AgreementFilterValues>({
    filterProps: filterPropsKeys,
    storedFilters: storedAgreementFilters,
    modifyParsedFilter,
  });

  const sortBy = useMemo<SortingRule<AgreementListItem>>(() => {
    if (filterProps.filter.orderBy) {
      return getAgreementSortBy(filterProps.filter.orderBy);
    }
    return undefined;
  }, [filterProps.filter.orderBy]);

  const pinnedFilters = useMemo(
    () =>
      storedAgreementFilters?.filter((item) => item?.filter?.isPinned === true),
    [storedAgreementFilters],
  );

  const handleFilterPinned = useCallback(
    async (filterId: string, pinned: boolean) => {
      const storedFilter = storedAgreementFilters.find(
        ({ id }) => id === filterId,
      );
      if (storedFilter) {
        await saveAgreementFilter(
          storedFilter.name,
          { ...storedFilter, isPinned: pinned },
          storedFilter.id,
        );
      }
    },
    [saveAgreementFilter, storedAgreementFilters],
  );

  const handleSortByChange = useCallback(
    (sortBy: SortingRule<AgreementListItem>) => {
      const search: Record<
        string,
        string | number | boolean | Array<Stringifiable> | null | undefined
      > = parse(location.search) ?? {};
      search.orderBy = getAgreementOrderBy(sortBy);
      navigate(
        {
          pathname: location.pathname,
          search: stringify(search),
        },
        { replace: true },
      );
    },
    [navigate],
  );

  const handleFilterSaved = useCallback<
    UseAgreementFilterInterface['handleFilterSaved']
  >(
    async (
      name: string,
      filterValues: AgreementFilterValues,
      filterId?: string,
    ) => {
      const filter = await saveAgreementFilter(name, filterValues, filterId);
      if (filter) {
        filterProps.handleFilterChange(filterValues, filter.id);
      }
    },
    [saveAgreementFilter, filterProps],
  );

  const handleFilterDeleted = useCallback(
    async (filterId: string) => {
      const storedFilter = storedAgreementFilters.find(
        ({ id }) => id === filterId,
      );
      if (storedFilter) {
        await deleteAgreementFilter(storedFilter.id);
        filterProps.handleFilterChange({}, null);
      }
    },
    [deleteAgreementFilter, filterProps, storedAgreementFilters],
  );

  return {
    ...filterProps,
    pinnedFilters,
    sortBy,
    handleFilterPinned,
    handleSortByChange,
    handleFilterSaved,
    handleFilterDeleted,
  };
};

export interface UseSupplierFilterInterface
  extends UseFilterInterface<SupplierFilterValues> {
  pinnedFilters: Array<StoredSupplierFilter>;
  sortBy?: SortingRule<SupplierListItem>;
  handleFilterPinned: (filterId: string, pinned: boolean) => Promise<void>;
  handleSortByChange: (sortBy?: SortingRule<SupplierListItem>) => void;
  handleFilterSaved: (
    name: string,
    filterValues: SupplierFilterValues,
    filterId?: string,
  ) => Promise<void>;
  handleFilterDeleted: (filterId: string) => Promise<unknown>;
}

export const useSupplierFilter = (): UseSupplierFilterInterface => {
  const tenant = useTenant();
  const navigate = useNavigate();

  const { data: storedSupplierFilters } = useStoredSupplierFilters(tenant.id);
  const { saveSupplierFilter, deleteSupplierFilter } = useStoredFilterActions(
    tenant.id,
  );

  const filterPropKeys = useMemo<Array<keyof SupplierFilterValues>>(() => {
    return [
      'supplierName',
      'countryOfRegistration',
      'corporateRegistrationNumber',
      'isPinned',
      'orderBy',
    ];
  }, []);

  const modifyParsedFilter = useCallback(
    (filterValues: SupplierFilterValues): SupplierFilterValues => {
      let modified = filterValues;
      if (modified.supplierName) {
        if (Array.isArray(modified.supplierName)) {
          modified = update(filterValues, {
            supplierName: { $set: modified.supplierName.join(',') },
          });
        } else {
          modified = update(modified, {
            supplierName: { $set: modified.supplierName.toString() },
          });
        }
      }
      if (modified.corporateRegistrationNumber) {
        if (Array.isArray(modified.corporateRegistrationNumber)) {
          modified = update(filterValues, {
            corporateRegistrationNumber: {
              $set: modified.corporateRegistrationNumber.join(','),
            },
          });
        } else {
          modified = update(filterValues, {
            corporateRegistrationNumber: {
              $set: modified.corporateRegistrationNumber.toString(),
            },
          });
        }
      }
      return modified;
    },
    [],
  );

  const filterProps = useFilter<SupplierFilterValues>({
    filterProps: filterPropKeys,
    storedFilters: storedSupplierFilters,
    modifyParsedFilter,
  });

  const sortBy = useMemo<SortingRule<SupplierListItem>>(() => {
    if (filterProps.filter.orderBy) {
      return getSupplierSortBy(filterProps.filter.orderBy);
    }
    return undefined;
  }, [filterProps.filter.orderBy]);

  const pinnedFilters = useMemo(
    () =>
      storedSupplierFilters?.filter((item) => item?.filter?.isPinned === true),
    [storedSupplierFilters],
  );

  const handleFilterPinned = useCallback(
    async (filterId: string, pinned: boolean) => {
      const storedFilter = storedSupplierFilters.find(
        ({ id }) => id === filterId,
      );
      if (storedFilter) {
        await saveSupplierFilter(
          storedFilter.name,
          { ...storedFilter, isPinned: pinned },
          storedFilter.id,
        );
      }
    },
    [saveSupplierFilter, storedSupplierFilters],
  );

  const handleSortByChange = useCallback(
    (sortBy: SortingRule<AgreementListItem>) => {
      const search: Record<
        string,
        string | number | boolean | Array<Stringifiable> | null | undefined
      > = parse(location.search) ?? {};
      search.orderBy = getSupplierOrderBy(sortBy);
      navigate(
        {
          pathname: location.pathname,
          search: stringify(search),
        },
        { replace: true },
      );
    },
    [navigate],
  );

  const handleFilterSaved = useCallback<
    UseSupplierFilterInterface['handleFilterSaved']
  >(
    async (
      name: string,
      filterValues: SupplierFilterValues,
      filterId?: string,
    ) => {
      const filter = await saveSupplierFilter(name, filterValues, filterId);
      if (filter) {
        filterProps.handleFilterChange(filterValues, filter.id);
      }
    },
    [saveSupplierFilter, filterProps],
  );

  const handleFilterDeleted = useCallback(
    async (filterId: string) => {
      const storedFilter = storedSupplierFilters.find(
        ({ id }) => id === filterId,
      );
      if (storedFilter) {
        await deleteSupplierFilter(storedFilter.id);
        filterProps.handleFilterChange({}, null);
      }
    },
    [deleteSupplierFilter, filterProps, storedSupplierFilters],
  );

  return {
    ...filterProps,
    pinnedFilters,
    sortBy,
    handleFilterPinned,
    handleSortByChange,
    handleFilterSaved,
    handleFilterDeleted,
  };
};
