import { classNames } from '@fcg-tech/regtech-utils';
import React, { FunctionComponent, useCallback } from 'react';
import { SquareCheckIcon, SquareIcon } from '../Icons';
import {
  CheckboxPartiallyCheckedDot,
  CheckboxWrapper,
} from './Checkbox.styles';

export interface CheckboxProps {
  value?: unknown;
  checked?: boolean;
  partiallyChecked?: boolean;
  disabled?: boolean;
  className?: string;
  onChange?: (checked: boolean, value: unknown) => void;
}

const size = '18';

export const Checkbox: FunctionComponent<CheckboxProps> = ({
  value,
  partiallyChecked,
  checked,
  disabled,
  className,
  onChange,
}) => {
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      onChange?.(!checked, value);
    },
    [checked, onChange, value],
  );

  return (
    <CheckboxWrapper
      role="checkbox"
      className={classNames(className, disabled || !onChange ? 'disabled' : '')}
      aria-disabled={disabled || !onChange}
      aria-checked={checked}
      data-checked={checked}
      data-partiallychecked={partiallyChecked}
      onClick={!disabled ? handleClick : null}
    >
      {partiallyChecked ? (
        <>
          <SquareIcon size={size} />
          <CheckboxPartiallyCheckedDot />
        </>
      ) : null}
      {!partiallyChecked && checked ? <SquareCheckIcon size={size} /> : null}
      {!partiallyChecked && !checked ? <SquareIcon size={size} /> : null}
    </CheckboxWrapper>
  );
};
