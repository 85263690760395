import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field, Label } from '@fcg-tech/regtech-components';
import { TextField } from '@fcg-tech/regtech-formik-components';

import { FormRow, FormColumn, FormGroup } from '../../../components/FormLayout';
import { FormModal } from '../../../components/FormModal';
import {
  RolePolicySubForm,
  RoleParametersSubForm,
} from '../../../components/SubForms';

const Wrapper = styled.div`
  align-items: center;
  width: 100%;
  min-width: 56rem;
  min-height: 32rem;
`;

export const EditGroupRoleModal = ({ role, onSave, onCancel }) => {
  const initialValues = React.useMemo(
    () => ({
      ...role,
      policy: JSON.stringify(role?.policy, null, 2),
    }),
    [role],
  );

  return (
    <FormModal
      title="Edit role"
      initialValues={initialValues}
      onSubmit={onSave}
      onCancel={onCancel}
    >
      {() => (
        <Wrapper>
          <FormRow>
            <FormColumn>
              <FormGroup>
                <Label column htmlFor="role-name">
                  Name
                </Label>
                <Field
                  name="name"
                  id="role-name"
                  component={TextField}
                  type="text"
                  readOnly
                />
              </FormGroup>
            </FormColumn>
          </FormRow>
          <RoleParametersSubForm />
          <RolePolicySubForm readOnly />
        </Wrapper>
      )}
    </FormModal>
  );
};

EditGroupRoleModal.propTypes = {
  role: PropTypes.shape({}),
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

EditGroupRoleModal.defaultProps = {
  role: [],
  onSave: null,
  onCancel: null,
};
