import React from 'react';
import { useNavigate, useMatch, matchPath } from 'react-router-dom';
import { useAsync } from 'react-async';
import { EditProvider } from '@fcg-tech/regtech-components';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { useTenant } from '../../states/tenantState';
import { routes } from '../../routes';
import { createRole } from '../../api';
import { NewRolePage } from './components';

export const NewRoleContainer = () => {
  const navigate = useNavigate();
  const tenant = useTenant();
  const tenantId = tenant.id;

  const [isSaving, setIsSaving] = React.useState(false);

  const createRoleProxy = async (args) => {
    setIsSaving(true);
    try {
      const { Location } = await createRole(...args);
      if (Location) {
        const match = matchPath({ path: '/iam/roles/:roleId' }, `/${Location}`);
        const { roleId } = match.params;
        navigate(constructUrl(routes.role, { tenantId, roleId }));
      } else {
        setIsSaving(false);
      }
    } catch (err) {
      setIsSaving(false);
      throw err;
    }
  };

  const createRoleReq = useAsync({
    deferFn: createRoleProxy,
  });

  const handleSave = React.useCallback(
    (role) => {
      createRoleReq.run({
        tenantId,
        // TODO: This is not safe. Should check for valid json before parse.
        role: { name: role.name, policy: JSON.parse(role.policy) },
      });
    },
    [createRoleReq, tenantId],
  );

  const handleCancel = React.useCallback(() => {
    navigate(constructUrl(routes.roles, { tenantId }));
  }, [navigate, tenantId]);

  if (createRoleReq.error) {
    console.log('error creating role');
  }

  return (
    <EditProvider value>
      <NewRolePage
        isSaving={isSaving}
        onSave={handleSave}
        onCancel={handleCancel}
      />
    </EditProvider>
  );
};
