import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Label, LabelProps } from '@fcg-tech/regtech-components';
import {
  SubcontractorsFieldLabelWrapper,
  SubcontractorsFieldLabelInfoCircle,
} from './SubcontractorsFieldLabel.styles';

interface SubcontractorsFieldLabelProps extends LabelProps {
  info?: string;
}

export const SubcontractorsFieldLabel: FunctionComponent<PropsWithChildren<PropsWithChildren<SubcontractorsFieldLabelProps>>> =
  ({ children, info, ...props }) => (
    <SubcontractorsFieldLabelWrapper>
      <Label {...props}>{children}</Label>
      <SubcontractorsFieldLabelInfoCircle info={info} />
    </SubcontractorsFieldLabelWrapper>
  );
