import PropTypes from 'prop-types';

export const childrenPropType = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.arrayOf(PropTypes.node),
]);

export const metadataPropType = PropTypes.shape({
  id: PropTypes.string,
});

export const attachmentPropType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  section: PropTypes.string,
  name: PropTypes.string,
  uploaded: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
});

export const categoryPropType = PropTypes.shape({
  metadata: metadataPropType,
  data: PropTypes.shape({
    name: PropTypes.string,
  }),
  permissions: PropTypes.arrayOf(PropTypes.string),
});

export const internalEntityPropType = PropTypes.shape({
  metadata: metadataPropType,
  data: PropTypes.shape({
    name: PropTypes.string,
    parentCompany: PropTypes.string,
    contactDetails: PropTypes.arrayOf(PropTypes.string),
    isUltimateParent: PropTypes.bool,
    registeredAddress: PropTypes.string,
    description: PropTypes.string,
    countryOfRegistration: PropTypes.string,
    legalEntityIdentifier: PropTypes.string,
    corporateRegistrationNumber: PropTypes.string,
  }),
  permissions: PropTypes.arrayOf(PropTypes.string),
});

export const supplierPropType = PropTypes.shape({
  metadata: metadataPropType,
  data: PropTypes.shape({
    parentCompany: PropTypes.string,
    contactDetails: PropTypes.arrayOf(PropTypes.string),
    isUltimateParent: PropTypes.bool,
    registeredAddress: PropTypes.string,
    supplierName: PropTypes.string,
    description: PropTypes.string,
    countryOfRegistration: PropTypes.string,
    legalEntityIdentifier: PropTypes.string,
    corporateRegistrationNumber: PropTypes.string,
    countriesOrRegionsWhereDataIsStored: PropTypes.arrayOf(PropTypes.string),
    supplierAssessment: PropTypes.shape({
      isDueDiligenceConducted: PropTypes.bool,
      latestDueDiligence: PropTypes.string,
      isRiskAssessmentCompleted: PropTypes.bool,
      latestRiskAssessment: PropTypes.string,
      isCSRSurveyRequired: PropTypes.bool,
      isCSRSurveyCompleted: PropTypes.bool,
      latestCSRSurvey: PropTypes.string,
      isFollowCodeOfConductRequired: PropTypes.bool,
      isFollowCodeOfConductConfirmed: PropTypes.bool,
      isInformationSecurityRequired: PropTypes.bool,
      isInformationSecurityCompleted: PropTypes.bool,
    }),
  }),
  permissions: PropTypes.arrayOf(PropTypes.string),
});

export const subcontractorPropType = PropTypes.shape({
  id: PropTypes.string,
  supplier: PropTypes.shape({
    isExternal: PropTypes.bool,
    externalSupplier: PropTypes.string,
    internalSupplier: PropTypes.string,
  }),
  briefDescription: PropTypes.string,
  categoryOfActivity: PropTypes.string,
  countriesWhereServiceIsPerformed: PropTypes.arrayOf(PropTypes.string),
  countriesOrRegionsWhereDataIsStored: PropTypes.arrayOf(PropTypes.string),
});

export const agreementPropType = PropTypes.shape({
  metadata: metadataPropType,
  data: PropTypes.shape({
    details: PropTypes.shape({
      name: PropTypes.string,
      term: PropTypes.shape({
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        renewalDate: PropTypes.string,
        noticePeriodInternalEntity: PropTypes.shape({
          value: PropTypes.number,
          unit: PropTypes.string,
        }),
        noticePeriodSupplier: PropTypes.shape({
          value: PropTypes.number,
          unit: PropTypes.string,
        }),
      }),
      briefDescription: PropTypes.string,
      agreementType: PropTypes.string,
      partyToAgreement: PropTypes.string,
      contractOwner: PropTypes.string,
      internalReference: PropTypes.arrayOf(PropTypes.string),
      mainSupplier: PropTypes.shape({
        isExternal: PropTypes.bool,
        externalSupplier: PropTypes.string,
        internalSupplier: PropTypes.string,
      }),
      receiversOfProvidedService: PropTypes.arrayOf(PropTypes.string),
      annualBudgetCostEstimation: PropTypes.shape({
        amount: PropTypes.string,
        currency: PropTypes.string,
      }),
    }),
    personalData: PropTypes.shape({
      role: PropTypes.string,
      dataProtectionIncluded: PropTypes.bool,
      isPersonalDataProcessed: PropTypes.bool,
      isPersonalDataTransferred: PropTypes.bool,
      countriesWhereDataIsTransferred: PropTypes.arrayOf(PropTypes.string),
    }),
    serviceDetails: PropTypes.shape({
      data: PropTypes.shape({
        deploymentModel: PropTypes.string,
        natureOfData: PropTypes.string,
      }),
      subcontractors: PropTypes.arrayOf(subcontractorPropType),
      isProvidedAsCloudService: PropTypes.bool,
      countriesWhereServiceIsPerformed: PropTypes.arrayOf(PropTypes.string),
      countriesOrRegionsWhereDataIsStored: PropTypes.arrayOf(PropTypes.string),
    }),
    npap: PropTypes.shape({
      data: PropTypes.shape({
        npapCompleted: PropTypes.bool,
        npapDescription: PropTypes.string,
      }),
      npapRequired: PropTypes.bool,
    }),
    changeManagementProcess: PropTypes.shape({
      data: PropTypes.shape({
        completed: PropTypes.bool,
        summary: PropTypes.string,
      }),
      required: PropTypes.bool,
    }),
    relatedAgreements: PropTypes.shape({
      hasParent: PropTypes.bool,
      parentAgreement: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
    validations: PropTypes.arrayOf(
      PropTypes.shape({
        field: PropTypes.string,
        level: PropTypes.string,
        message: PropTypes.string,
      }),
    ),
    agreementAssessment: PropTypes.shape({
      isOutsourcingArrangement: PropTypes.bool,
      data: PropTypes.shape({
        functionCategory: PropTypes.string,
        assessmentCompleted: PropTypes.bool,
        latestAssessment: PropTypes.string,
        summaryOfAssessment: PropTypes.string,
        competentAuthorityNotified: PropTypes.bool,
        competentAuthorityNotifiedDate: PropTypes.string,
        competentAuthorityNotifiedDescription: PropTypes.string,
        isCriticalOrImportant: PropTypes.bool,
        criticalOrImportant: PropTypes.shape({
          nextAudit: PropTypes.string,
          latestAudit: PropTypes.string,
          exitStrategy: PropTypes.string,
          finalApproval: PropTypes.string,
          latestRiskAssessment: PropTypes.string,
          timeCriticalOperation: PropTypes.bool,
          riskAssessmentCompleted: PropTypes.bool,
          attachmentsSentToAuthority: PropTypes.arrayOf(attachmentPropType),
          alternativeServiceProviders: PropTypes.string,
          latestRiskAssessmentSummary: PropTypes.string,
        }),
      }),
    }),
  }),
  permissions: PropTypes.arrayOf(PropTypes.string),
});

export const calendarEventPropType = PropTypes.shape({
  metadata: metadataPropType,
  title: PropTypes.string,
  start: PropTypes.instanceOf(Date),
  end: PropTypes.instanceOf(Date),
  allDay: PropTypes.bool,
});

export const calendarViewPropType = PropTypes.oneOf([
  'month',
  'week',
  'work_week',
  'day',
  'agenda',
]);

const formikFieldValueType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.bool,
  PropTypes.number,
  PropTypes.array,
  PropTypes.object,
  PropTypes.instanceOf(Date),
]);

export const formikFieldShape = PropTypes.shape({
  value: PropTypes.oneOfType([
    formikFieldValueType,
    PropTypes.arrayOf(formikFieldValueType),
  ]),
  name: PropTypes.string,
  onChange: PropTypes.func,
});

export const formikFormShape = PropTypes.shape({
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
});

export const metadataShape = PropTypes.shape({
  id: PropTypes.string,
});

export const groupShape = PropTypes.shape({
  metadata: metadataShape,
  data: PropTypes.shape({
    name: PropTypes.string,
    federatedUserGroup: PropTypes.shape({
      hasFederatedUserGroup: PropTypes.bool,
      federatedUserGroupName: PropTypes.string,
    }),
  }),
});

export const roleShape = PropTypes.shape({
  name: PropTypes.string,
  type: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.string,
      resource: PropTypes.string,
    }),
  ),
});
