import React, { PropsWithChildren } from 'react';
import { TextField } from '../TextField';
import { DynamicListItemWrapper } from './DynamicList.styles';

interface DynamicListItemProps {
  value?: string;
  placeholder?: string;
  readOnly?: boolean; // TODO: Doesn't seem to be used in <TextField> ?
  index?: number;
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  onChanged?: (value: string, index: number) => void;
}

export const DynamicListItem = React.forwardRef<
  HTMLInputElement,
  PropsWithChildren<DynamicListItemProps>
>(
  (
    {
      children,
      value: initialValue,
      placeholder,
      readOnly,
      index,
      onChange,
      onBlur,
      onKeyDown,
      onChanged,
      ...props
    },
    ref,
  ) => {
    const [value, setValue] = React.useState(initialValue || '');

    const handleChange = React.useCallback(
      (event: React.FormEvent<HTMLInputElement>) => {
        setValue(event.currentTarget.value);
        if (onChange && typeof onChange === 'function') {
          onChange(event);
        }
      },
      [onChange, setValue],
    );

    const handleBlur = React.useCallback(
      (event: React.FocusEvent) => {
        if (value !== initialValue) {
          if (onChanged && typeof onChanged === 'function') {
            onChanged(value, index);
          }
          setValue('');
        }

        if (onBlur && typeof onBlur === 'function') {
          onBlur(event);
        }
      },
      [value, initialValue, onBlur, onChanged, index],
    );

    const handleKeyDown = React.useCallback(
      (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
          // ENTER
          event.preventDefault();
          event.stopPropagation();
          handleBlur(event as unknown as React.FocusEvent); // TODO: Not nice. Need to figure out if onBlur actually needs the event
        }
        if (onKeyDown && typeof onKeyDown === 'function') {
          onKeyDown(event);
        }
      },
      [onKeyDown, handleBlur],
    );

    return (
      <DynamicListItemWrapper>
        <TextField
          ref={ref}
          {...props}
          // readOnly={readOnly}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        />
        {children}
      </DynamicListItemWrapper>
    );
  },
);
