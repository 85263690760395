import { useMemo } from 'react';
import useSWR, { mutate, SWRConfiguration } from 'swr';
import { useApi } from '../apiUtils';
import { GetTagResponse, Tag, TagsApi } from '../schema';
import { allTagsKey, tagKey } from './cacheKeys';

export const getAllTags = async (api: TagsApi): Promise<Array<Tag>> => {
  const result = await api.listAllTags();
  return result.result ?? [];
};

export const getTag = async (
  tagName: string,
  api: TagsApi,
): Promise<GetTagResponse> => {
  const result = await api.getTag({ tagName });
  return result;
};

export const useTags = (tenantId: string, config?: SWRConfiguration) => {
  const api = useApi<TagsApi>('TagsApi', tenantId);
  return useSWR<Array<Tag>>(allTagsKey(tenantId), () => getAllTags(api), {
    suspense: true,
    ...config,
  });
};

export const useTag = (tagName: string, config?: SWRConfiguration) => {
  const tenant = 'xjk';
  const api = useApi<TagsApi>('TagsApi', tenant);
  return useSWR<GetTagResponse>(
    tagKey(tenant, tagName),
    () => getTag(tagName, api),
    { suspense: true, ...config },
  );
};

export const useTagActions = (tenantId: string) => {
  const api = useApi<TagsApi>('TagsApi', tenantId);
  return useMemo(
    () => ({
      upsertTag: async (tagName: string, tag?: Tag) => {
        await api.createOrUpdateTag({ tagName, tag });
        mutate(allTagsKey(tenantId));
      },
      deleteTag: async (tagName: string) => {
        await api.deleteTag({ tagName });
        mutate(allTagsKey(tenantId));
      },
    }),
    [api, tenantId],
  );
};
