import { ApiSupplierListItem, SupplierListItem } from '../types';

export const convertSupplierListItem = (
  supplier: ApiSupplierListItem,
): SupplierListItem => ({
  metadata: supplier.metadata,
  supplierName: supplier.data?.supplierName,
  description: supplier.data?.description,
  corporateRegistrationNumber: supplier.data?.corporateRegistrationNumber,
  legalEntityIdentifier: supplier.data?.legalEntityIdentifier,
  countryOfRegistration: supplier.data?.countryOfRegistration,
  registeredAddress: supplier.data?.registeredAddress,
  contactDetails: supplier.data?.contactDetails,
  isUltimateParent: supplier.data?.isUltimateParent,
  parentCompany: supplier.data?.parentCompany,
  supplierAssessment: supplier.data?.supplierAssessment,
});
