import styled, { css } from 'styled-components';
import {
  Radio as ReakitRadio,
  RadioGroup as ReakitRadioGroup,
} from 'reakit/Radio';
import { excludeProps } from '../../utils';
import { InfoCircle } from '../InfoCircle';
import { Label as FormLabel } from '../Label';

export const BooleanDecisionWrapper = styled.div.withConfig(
  excludeProps('contractInfo'),
)<{
  contractInfo?: boolean;
}>`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: ${({ contractInfo }) =>
    contractInfo ? 'flex-start' : 'space-between'};
`;

export const StyledInfoCircle = styled(InfoCircle)`
  margin-left: 4rem;
`;

export const ControlContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.label`
  & + & {
    margin-left: 0.6rem;
  }
  font-size: 1.4rem;
`;

export const Radio = styled(ReakitRadio)`
  margin-right: 0.5rem;
  &:checked {
    color: red;
  }
`;

export const RadioGroup = styled(ReakitRadioGroup)`
  border: 0;
  margin: 0;
  padding: 0;
`;

export const ReadOnlyLabel = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  padding: 0.5rem;
  border: 1px dashed rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  min-width: 7rem;
  text-align: center;
`;

export const ClearButton = styled.span.attrs({ role: 'button' })`
  ${({ theme }) => css`
    border-bottom: 1px dashed ${theme.palette?.MainColor?.dark ?? '#000'};
    color: ${theme.palette?.MainColor?.dark ?? '#000'};
    font-size: 1.2rem;
    padding-bottom: 0.1rem;
    cursor: pointer;
    margin-left: 1.2rem;

    &:hover {
      border-bottom-style: solid;
    }
  `}
`;

export const BooleanDecisionFieldWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const BooleanDecisionFieldLabel = styled(FormLabel)`
  margin-right: 1.5rem;
`;
