import styled, { css, DefaultTheme } from 'styled-components';
import { ModalFormWrapper } from '../../components/modals';

export const AgreementOverviewCreateModalFormWrapper = styled(ModalFormWrapper)`
  min-width: 56rem;
`;

export const AgreementOverviewCreateModalFileUploadZoneWrapper = styled.div`
  font-size: 1.2rem;
  width: 100%;
`;

export const compactTheme: Partial<DefaultTheme> = {
  elements: {
    button: {
      secondary: css`
        height: 3rem;
        font-size: 1.2rem;
        height: 3rem;
      `,
    },
  },
};
