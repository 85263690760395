/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  checkResponse,
  fetchAuthenticated,
  PUT,
  POST,
  DELETE,
} from '@fcg-tech/regtech-api-utils';
import { Endpoint } from '@fcg-tech/regtech-types/oasys';
import {
  SingleEntity,
  ResultArray,
  WithMetadata,
  AgreementType,
  AgreementTypeAgreement,
} from '../../types';
import { getEndpointUrl } from '../apiUtils';

export const loadAgreementTypes = async (
  tenantId: string,
): Promise<ResultArray<WithMetadata<AgreementType>>> => {
  const response = await fetchAuthenticated(
    getEndpointUrl(Endpoint.ListAllAgreementTypes, { tenantId }),
  );
  if (!checkResponse(response)) {
    throw new Error('Load agreement types request failed');
  }
  return response.json();
};

export const loadAgreementType = async (
  tenantId: string,
  agreementTypeId: string,
): Promise<SingleEntity<AgreementType>> => {
  const response = await fetchAuthenticated(
    getEndpointUrl(Endpoint.GetAgreementType, {
      tenantId,
      id: agreementTypeId,
    }),
  );
  if (!checkResponse(response)) {
    throw new Error('Load agreement type request failed');
  }
  return response.json();
};

export const loadAgreementTypeAgreements = async (
  tenantId: string,
  agreementType: string,
  isArchived = false,
): Promise<ResultArray<AgreementTypeAgreement>> => {
  const response = await fetchAuthenticated(
    getEndpointUrl(
      Endpoint.ListAllAgreements,
      { tenantId },
      { agreementType, isArchived },
    ),
  );
  if (!checkResponse(response)) {
    throw new Error('Load agreement type agreements request failed');
  }
  return response.json();
};

export const createAgreementType = async (
  tenantId: string,
  agreementType: AgreementType,
) => {
  const response = await fetchAuthenticated(
    getEndpointUrl(Endpoint.CreateAgreementType, { tenantId }),
    {
      ...POST,
      body: JSON.stringify({ ...agreementType }),
    },
  );
  if (!checkResponse(response)) {
    throw new Error('Create agreement type request failed');
  }
  return response.json();
};

export const updateAgreementType = async (
  tenantId: string,
  agreementTypeId: string,
  agreementType: AgreementType,
) => {
  const response = await fetchAuthenticated(
    getEndpointUrl(Endpoint.UpdateAgreementType, {
      tenantId,
      id: agreementTypeId,
    }),
    {
      ...PUT,
      body: JSON.stringify({ ...agreementType }),
    },
  );
  if (!checkResponse(response)) {
    throw new Error('Update agreement type request failed');
  }
  return response;
};

export const deleteAgreementType = async (
  tenantId: string,
  agreementTypeId: string,
) => {
  const response = await fetchAuthenticated(
    getEndpointUrl(Endpoint.DeleteAgreementType, {
      tenantId,
      id: agreementTypeId,
    }),
    {
      ...DELETE,
    },
  );
  if (!checkResponse(response)) {
    throw new Error('Delete agreement type request failed');
  }
  return response;
};

export const loadAgreementTypesPromiseFn = async (
  props: any,
): Promise<ResultArray<WithMetadata<AgreementType>>> =>
  await loadAgreementTypes(props.tenantId);

export const loadAgreementTypePromiseFn = async (
  props: any,
): Promise<SingleEntity<AgreementType>> =>
  await loadAgreementType(props.tenantId, props.agreementTypeId);

export const loadAgreementTypeAgreementsPromiseFn = async (
  props: any,
): Promise<ResultArray<AgreementTypeAgreement>> =>
  await loadAgreementTypeAgreements(props.tenantId, props.agreementType);
