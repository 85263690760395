import styled, { css, DefaultTheme, StyledComponent } from 'styled-components';
import { H2 } from '../Headings';

export const CardBody = styled.div`
  padding: 2.4rem;
  position: relative;
  width: 100%;
  font-size: 1.4rem;
`;

export const CardTitle = styled(H2)`
  display: block;
  font-size: 2rem;
  font-weight: normal;
  margin-bottom: 2.4rem;
  margin-top: 0;
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 2.4rem;

  & + ${CardBody} {
    padding-top: 0;
  }

  & > ${CardTitle} {
    margin-bottom: 0;
  }
`;

type CardProps = StyledComponent<
  'div',
  DefaultTheme,
  Record<string, unknown>,
  never
> & {
  /**
   * @deprecated Use exported version instead
   */
  Body?: typeof CardBody;
  /**
   * @deprecated Use exported version instead
   */
  Header?: typeof CardHeader;
  /**
   * @deprecated Use exported version instead
   */
  Title?: typeof CardTitle;
};

export const Card: CardProps = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    position: relative;
    background-color: ${theme?.colors?.backdrop ??
    theme?.palette?.MainWhite ??
    '#fff'};
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    border-color: #bbb;
    border-radius: 0.4rem;
    margin-bottom: 2.5rem;
    height: inherit;

    @media print {
      page-break-after: always;
      border-color: transparent;
      box-shadow: none;
      margin-top: 0;
      padding-bottom: 0;
      padding-top: 0;
    }

    ${theme?.elements?.card?.wrapper}
  `}
`;

Card.Body = CardBody;
Card.Header = CardHeader;
Card.Title = CardTitle;
