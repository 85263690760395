import { MutableRefObject } from 'react';
import { KeyLabelItem, ReactSelectOption } from './types/misc';
import type { MultiValue, SingleValue } from 'react-select';

export const isKeyLabelItem = <T>(item: unknown): item is KeyLabelItem<T> =>
  Boolean(item && typeof item === 'object' && 'key' in item);

export const isMutableRef = (ref: unknown): ref is MutableRefObject<unknown> =>
  Boolean(ref && typeof ref === 'object' && 'current' in ref);
export const isOptionsType = <T extends unknown>(
  options: unknown,
): options is ReactSelectOption<T> =>
  Boolean(
    options &&
      typeof options === 'object' &&
      'value' in options &&
      'label' in options,
  );

export const isSingleValueType = <T extends unknown>(
  options: unknown,
): options is SingleValue<ReactSelectOption<T>> =>
  Boolean(
    options &&
      typeof options === 'object' &&
      'value' in options &&
      'label' in options,
  );

export const isMultiValueType = <T extends unknown>(
  options: unknown,
): options is MultiValue<ReactSelectOption<T>> =>
  Array.isArray(options) && options.every(isSingleValueType);
