import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { Field, Label } from '@fcg-tech/regtech-components';
import {
  BooleanDecision,
  DynamicList,
  Select,
  TextArea,
  TextField,
} from '@fcg-tech/regtech-formik-components';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { MessageKeys } from '../../translations/messageKeys';
import { getTooltip } from '../../utils/tooltipHelpers';
import { countryState } from '../../states/countryState';
import { FieldError } from '../../components/FieldError';
import { FormRow, FormColumn, FormGroup } from '../FormLayout';
import { countrySelectOptions } from '../../utils/optionConverters';

export const SupplierDetailsSubForm = ({
  supplierItems,
  onSupplierChange,
  onSupplierCreate,
}) => {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  const { readOnly } = values || {};
  const countries = useRecoilValue(countryState);

  const countryOptions = useMemo(
    () => countries?.map(countrySelectOptions),
    [countries],
  );

  return (
    <FormRow>
      <FormColumn>
        <FormGroup>
          <Label column htmlFor="supplier-name">
            {t(MessageKeys.LabelName)}
          </Label>
          {supplierItems?.length ? (
            <>
              <Field
                component={Select}
                name="data.supplierName"
                id="supplier-name"
                typeahead
                createOptionText={t(
                  MessageKeys.SupplierDetailsSubFormCreateOptionLabel,
                )}
                typeaheadPlaceholder={t(MessageKeys.SupplierNamePlaceholder)}
                items={supplierItems}
                onChange={onSupplierChange}
                onCreate={onSupplierCreate}
                info={getTooltip('supplier.supplierName')}
              />
              <FieldError name="data.supplierName" />
            </>
          ) : (
            <Field
              component={TextField}
              name="data.supplierName"
              type="text"
              id="supplier-name"
              info={getTooltip('supplier.supplierName')}
            />
          )}
        </FormGroup>
        <FormGroup>
          <Label column htmlFor="supplier-country-of-registration">
            {t(
              MessageKeys.InternalEntityDetailsSubFormCountryRegistrationLabel,
            )}
          </Label>
          <Field
            component={Select}
            name="data.countryOfRegistration"
            items={countryOptions}
            id="supplier-country-of-registration"
            info={getTooltip('supplier.countryOfRegistration')}
            typeahead
            readOnly={readOnly}
            notFast
          />
        </FormGroup>
        <FormGroup>
          <Label column htmlFor="supplier-registered-address">
            {t(MessageKeys.InternalEntityDetailsSubFormRegistratedAddressLabel)}
          </Label>
          <Field
            component={TextArea}
            name="data.registeredAddress"
            id="supplier-registered-address"
            info={getTooltip('supplier.registeredAddress')}
            readOnly={readOnly}
            notFast
          />
        </FormGroup>
        <FormGroup>
          <BooleanDecision.FieldContainer>
            <BooleanDecision.FieldLabel>
              {t(MessageKeys.InternalEntityDetailsSubFormUltimateParentLabel)}
            </BooleanDecision.FieldLabel>
            <Field
              component={BooleanDecision}
              name="data.isUltimateParent"
              info={getTooltip('supplier.isUltimateParent')}
              readOnly={readOnly}
              notFast
            />
          </BooleanDecision.FieldContainer>
        </FormGroup>
        {!values.data.isUltimateParent && (
          <FormGroup>
            <Label column htmlFor="supplier-parent-company">
              {t(MessageKeys.InternalEntityDetailsSubFormParentCompanyLabel)}
            </Label>
            <Field
              component={TextField}
              type="text"
              name="data.parentCompany"
              id="supplier-parent-company"
              info={getTooltip('supplier.parentCompany')}
              readOnly={readOnly}
              notFast
            />
          </FormGroup>
        )}
      </FormColumn>
      <FormColumn>
        <FormGroup>
          <Label column htmlFor="supplier-corporate-registration-number">
            {t(
              MessageKeys.InternalEntityDetailsSubFormCorporateRegistrationNumberLabel,
            )}
          </Label>
          <Field
            component={TextField}
            name="data.corporateRegistrationNumber"
            type="text"
            id="supplier-corporate-registration-number"
            info={getTooltip('supplier.corporateRegistrationNumber')}
            readOnly={readOnly}
            notFast
          />
        </FormGroup>
        <FormGroup>
          <Label column htmlFor="supplier-legal-entity-identifier">
            {t(
              MessageKeys.InternalEntityDetailsSubFormLegalEntityIdentifierLabel,
            )}
          </Label>
          <Field
            component={TextField}
            name="data.legalEntityIdentifier"
            type="text"
            id="supplier-legal-entity-identifier"
            info={getTooltip('supplier.legalEntityIdentifier')}
            readOnly={readOnly}
            notFast
          />
        </FormGroup>
        <FormGroup>
          <Label column htmlFor="supplier-legal-entity-identifier">
            {t(MessageKeys.InternalEntityDetailsSubFormContactDetailsLabel)}
          </Label>
          <Field
            component={DynamicList}
            name="data.contactDetails"
            id="supplier-contact-details"
            info={getTooltip('supplier.contactDetails')}
            readOnly={readOnly}
            notFast
          />
        </FormGroup>
        <FormGroup>
          <Label column htmlFor="supplier-description">
            {t(MessageKeys.LabelDescription)}
          </Label>
          <Field
            id="supplier-description"
            name="data.description"
            component={TextArea}
            info={getTooltip('supplier.description')}
            readOnly={readOnly}
            notFast
          />
        </FormGroup>
      </FormColumn>
    </FormRow>
  );
};

SupplierDetailsSubForm.propTypes = {
  supplierItems: PropTypes.arrayOf(PropTypes.string),
  onSupplierChange: PropTypes.func,
  onSupplierCreate: PropTypes.func,
};

SupplierDetailsSubForm.defaultProps = {
  supplierItems: [],
  onSupplierChange: null,
  onSupplierCreate: null,
};
