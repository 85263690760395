import React from 'react';
import { useFormikContext } from 'formik';
import { Field, Label } from '@fcg-tech/regtech-components';
import {
  BooleanDecision,
  TextField,
} from '@fcg-tech/regtech-formik-components';

import { FormColumn, FormGroup, FormRow } from '../FormLayout';

export const GroupDetailsSubForm = () => {
  const { values } = useFormikContext();

  return (
    <>
      <FormRow>
        <FormColumn>
          <FormGroup>
            <Label column htmlFor="group-name">
              Name
            </Label>
            <Field
              component={TextField}
              name="name"
              id="group-name"
              type="text"
            />
          </FormGroup>
        </FormColumn>
      </FormRow>
      <FormRow>
        <FormColumn>
          <FormGroup>
            <BooleanDecision.FieldContainer>
              <BooleanDecision.FieldLabel>
                Federated user group
              </BooleanDecision.FieldLabel>
              <Field
                component={BooleanDecision}
                name="federatedUserGroup.hasFederatedUserGroup"
                contractInfo
              />
            </BooleanDecision.FieldContainer>
          </FormGroup>
          {values.federatedUserGroup.hasFederatedUserGroup && (
            <FormGroup>
              <Field
                component={TextField}
                name="federatedUserGroup.federatedUserGroupName"
                id="federated-user-group-name"
                type="text"
              />
            </FormGroup>
          )}
        </FormColumn>
      </FormRow>
    </>
  );
};
