import {exists, mapValues} from '../runtime';



/* ListNotificationResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Notification API
 * This API handles Notification API
 *
 * The version of the OpenAPI document: 0.0.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface ListNotificationResponse
 */
export interface ListNotificationResponse {
    /**
     * 
     * @type {Array<Notification>}
     * @memberof ListNotificationResponse
     */
    items: Array<Notification>;
    /**
     * 
     * @type {boolean}
     * @memberof ListNotificationResponse
     */
    truncated: boolean;
    /**
     * ulid
     * @type {string}
     * @memberof ListNotificationResponse
     */
    lastId?: string;
}

export function ListNotificationResponseFromJSON(json: any): ListNotificationResponse {
    return ListNotificationResponseFromJSONTyped(json, false);
}

export function ListNotificationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListNotificationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(NotificationFromJSON)),
        'truncated': json['truncated'],
        'lastId': !exists(json, 'lastId') ? undefined : json['lastId'],
    };
}

export function ListNotificationResponseToJSON(value?: ListNotificationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(NotificationToJSON)),
        'truncated': value.truncated,
        'lastId': value.lastId,
    };
}




/* MarkAsReadData.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Notification API
 * This API handles Notification API
 *
 * The version of the OpenAPI document: 0.0.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface MarkAsReadData
 */
export interface MarkAsReadData {
    /**
     * 
     * @type {Array<string>}
     * @memberof MarkAsReadData
     */
    ids: Array<string>;
}

export function MarkAsReadDataFromJSON(json: any): MarkAsReadData {
    return MarkAsReadDataFromJSONTyped(json, false);
}

export function MarkAsReadDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarkAsReadData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ids': json['ids'],
    };
}

export function MarkAsReadDataToJSON(value?: MarkAsReadData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ids': value.ids,
    };
}




/* Notification.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Notification API
 * This API handles Notification API
 *
 * The version of the OpenAPI document: 0.0.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    message?: string;
    /**
     * 
     * @type {Date}
     * @memberof Notification
     */
    timestamp: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Notification
     */
    read: boolean;
    /**
     * 
     * @type {NotificationLink}
     * @memberof Notification
     */
    link?: NotificationLink;
}

export function NotificationFromJSON(json: any): Notification {
    return NotificationFromJSONTyped(json, false);
}

export function NotificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Notification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'timestamp': (new Date(json['timestamp'])),
        'read': json['read'],
        'link': !exists(json, 'link') ? undefined : NotificationLinkFromJSON(json['link']),
    };
}

export function NotificationToJSON(value?: Notification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'message': value.message,
        'timestamp': (value.timestamp.toISOString()),
        'read': value.read,
        'link': NotificationLinkToJSON(value.link),
    };
}




/* NotificationLink.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Notification API
 * This API handles Notification API
 *
 * The version of the OpenAPI document: 0.0.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface NotificationLink
 */
export interface NotificationLink {
    /**
     * 
     * @type {string}
     * @memberof NotificationLink
     */
    baseUrl: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationLink
     */
    path: string;
}

export function NotificationLinkFromJSON(json: any): NotificationLink {
    return NotificationLinkFromJSONTyped(json, false);
}

export function NotificationLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationLink {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'baseUrl': json['baseUrl'],
        'path': json['path'],
    };
}

export function NotificationLinkToJSON(value?: NotificationLink | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'baseUrl': value.baseUrl,
        'path': value.path,
    };
}




/* NotificationsSettingsResult.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * Notification API
 * This API handles Notification API
 *
 * The version of the OpenAPI document: 0.0.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface NotificationsSettingsResult
 */
export interface NotificationsSettingsResult {
    /**
     * 
     * @type {boolean}
     * @memberof NotificationsSettingsResult
     */
    enabled: boolean;
}

export function NotificationsSettingsResultFromJSON(json: any): NotificationsSettingsResult {
    return NotificationsSettingsResultFromJSONTyped(json, false);
}

export function NotificationsSettingsResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationsSettingsResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabled': json['enabled'],
    };
}

export function NotificationsSettingsResultToJSON(value?: NotificationsSettingsResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabled': value.enabled,
    };
}
