import React from 'react';
import { useAsync } from 'react-async';
import { Loader } from '@fcg-tech/regtech-components';

import { loadRoles } from '../../api';
import { useTenant } from '../../states/tenantState';
import { ErrorMessage } from '../../components/ErrorBoundary';
import { RolesPage } from './components/RolesPage';

export const RolesContainer = () => {
  const tenant = useTenant();
  const tenantId = tenant.id;

  const rolesReq = useAsync({
    promiseFn: loadRoles,
    tenantId,
  });

  if (rolesReq.isLoading) {
    return <Loader message="Loading roles" />;
  }

  if (rolesReq.error) {
    return <ErrorMessage error={rolesReq.error} />;
  }

  return <RolesPage roles={rolesReq?.data?.roles || []} />;
};
