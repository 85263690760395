import styled from 'styled-components';
import {
  SideBar,
  SideNavigationCenteredWhenCollapsed,
} from '@fcg-tech/regtech-components';
import { OasysText } from '../icons/OasysText';

export const SIDE_NAVIGATION_WIDTH_REM = 24;

export const StyledSideBar = styled(SideBar)`
  width: ${SIDE_NAVIGATION_WIDTH_REM}rem;
`;

export const MainSideNavigationContent = styled(
  SideNavigationCenteredWhenCollapsed,
)`
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

export const MainSideNavigationMenuWrapper = styled.div`
  flex: 1;
`;

export const MainSideNavigationLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;

  .collapsed & {
    justify-content: flex-start;
    margin-left: 1.2rem;
  }
`;

export const MainSideNavigationLogo = styled.img`
  width: 10rem;
  transform: width 0.2s linear;
  .collapsed & {
    width: 4rem;
  }
`;

export const StyledOasysText = styled(OasysText)`
  width: 20rem;
  position: relative;
  top: 1rem;
`;
