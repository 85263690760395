import Color from 'color';
import { Link } from 'react-router-dom';
import { Button } from 'reakit';
import styled, { css, DefaultTheme } from 'styled-components';
import { excludeProps } from '../../utils';
import { ChevronRightIcon } from '../Icons';

export const ButtonIcon = styled.div`
  margin-left: 1.2rem;
  &:first-child {
    margin-left: 0;
    margin-right: 1.2rem;
  }
`;

export const baseButtonStyle = css`
  ${({ theme }) => css`
    margin: 0;
    padding: 0;
    background: none;
    border: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 1.4rem;
    line-height: 1rem;
    white-space: nowrap;
    color: inherit;
    background-color: inherit;
    font-family: Roboto;

    &:focus {
      outline: 2px dashed rgba(0, 0, 0, 0.1);
    }

    &:disabled {
      cursor: not-allowed;
    }

    &:active {
      outline: 0;
    }

    &.narrow {
      font-size: 1.2rem;
    }

    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      &:focus {
        background: none;
        outline: 0;
      }
      font-size: 1.2rem;
    }

    ${theme?.elements?.button?.base}
  `}
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const UnstyledButton = styled(Button)`
  ${baseButtonStyle};
  &:active,
  &:hover {
    background: none;
    border: 0;
  }

  &:focus {
    background: none;
    border: 0;
  }
`;

const primaryButtonStyle = css`
  ${({ theme }) => css`
    border: 1px solid ${theme?.palette?.MainColor?.main ?? '#ddd'};
    background-color: ${theme?.palette?.MainColor?.main ?? '#ddd'};
    color: ${theme?.palette?.MainColor?.mainContrast ?? '#222'};
    padding: 0 1.8rem;
    font-weight: 600;
    height: 3.8rem;

    &:hover,
    &:focus {
      box-shadow: 0 2px 4px 0
        ${Color(theme?.palette?.MainColor?.main ?? '#ddd')
          .alpha(0.3)
          .string()};
    }

    &:active,
    &.active {
      border: 1px solid ${theme?.palette?.MainColor?.light ?? '#aaa'};
      background-color: ${theme?.palette?.MainColor?.light ?? '#aaa'};
      color: ${theme?.palette?.MainColor?.lightContrast ?? '#222'};
    }

    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      &:focus {
        box-shadow: none;
      }
      padding: 0 1.2rem;
    }

    &:disabled {
      opacity: 0.4;
    }

    &.wide {
      padding: 0 4rem;
    }

    ${theme?.elements?.button?.primary}
  `}
`;

export const PrimaryButtonWrapper = styled(Button).withConfig(
  excludeProps('loading'),
)<{ loading?: boolean }>`
  ${baseButtonStyle}
  ${primaryButtonStyle}

  ${({ loading, theme }) =>
    loading
      ? css`
          color: transparent !important;

          & > ${LoaderWrapper} {
            color: ${theme?.palette?.MainColor?.mainContrast ?? '#222'};
          }
        `
      : null};
`;

export const PrimaryButtonLinkWrapper = styled(Link)`
  ${baseButtonStyle}
  ${primaryButtonStyle}

  text-decoration: none;
  &:visited {
    color: ${({ theme }) => theme?.palette?.MainColor?.mainContrast ?? '#222'};
  }
`;

const secondaryButtonStyle = css`
  ${({ theme }) => css`
    border: 1px solid ${theme?.palette?.MainColorAccent?.dark ?? '#ccc'};
    background-color: ${theme?.palette?.MainWhite ?? '#fff'};
    color: ${theme?.palette?.MainBlack ?? '#222'};
    padding: 0 1.8rem;
    font-weight: 600;
    height: 3.8rem;

    &:hover {
      border-color: ${theme?.palette?.MainColorAccent?.main ?? '#ddd'};
    }

    &:active,
    &.active {
      background-color: ${theme?.palette?.MainColorAccent?.light ?? '#eee'};
    }

    &:disabled {
      opacity: 0.7;
    }

    ${theme?.elements?.button?.secondary}
  `}
`;

export const SecondaryButtonWrapper = styled(Button).withConfig(
  excludeProps('loading'),
)<{ loading?: boolean }>`
  ${baseButtonStyle}
  ${secondaryButtonStyle}

  ${({ loading, theme }) =>
    loading
      ? css`
          color: transparent !important;

          & > ${LoaderWrapper} {
            color: ${theme?.palette?.MainColorAccent?.mainContrast ?? '#222'};
          }
        `
      : null};
`;

export const SecondaryButtonLinkWrapper = styled(Link)`
  ${baseButtonStyle}
  ${secondaryButtonStyle}

  text-decoration: none;
  &:visited {
    color: ${({ theme }) => theme?.palette?.MainBlack ?? '#222'};
  }
`;

export const MultiButtonTitleWrapper = styled.div`
  padding-right: 1.4rem;
`;

export const MultiButtonArrow = styled(ChevronRightIcon).withConfig(
  excludeProps('down'),
)<{ down?: boolean }>`
  position: absolute;
  right: 0.4rem;
  top: 50%;
  transition: rotate 0.1s linear;
  transform: translateY(-50%) rotate(${({ down }) => (down ? '90deg' : '0')});
`;

export const IconButtonDefaultIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const iconButtonStyle = ({
  theme,
  $loading,
}: {
  theme: DefaultTheme;
  $loading?: boolean;
}) => css`
  width: 4.5rem;
  height: 4.5rem;

  & * {
    pointer-events: none;
  }

  &.narrow {
    width: 3rem;
    height: 3rem;
  }

  border-radius: 50%;
  color: ${theme?.palette?.MainColorAccent?.mainContrast ?? '#222'};
  border: 2px solid transparent;

  &.floating {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: white;
  }

  &:hover,
  &.icon-button--toggled {
    background-color: ${theme?.palette?.MainColorAccent?.main ?? '#ccc'};
  }

  &:focus {
    outline: 0;
    border: 2px dashed rgba(0, 0, 0, 0.1);
  }

  &:active {
    background-color: ${theme?.palette?.MainColorAccent?.main ?? '#ccc'};
    border-color: ${theme?.palette?.MainColorAccent?.dark ?? '#444'};
  }

  &:disabled {
    opacity: 0.4;
  }

  ${$loading
    ? css`
        & > ${IconButtonDefaultIconWrapper} {
          visibility: hidden;
        }
      `
    : ''}

  @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
    width: 3rem;
    height: 3rem;
    &:focus {
      border: 2px solid transparent;
    }

    &.floating {
      width: 4rem;
      height: 4rem;
    }
  }

  ${theme?.elements?.button?.icon}
`;

export const IconButtonWrapper = styled(Button)<{ $loading?: boolean }>`
  ${baseButtonStyle}
  ${iconButtonStyle};
`;

export const IconButtonLinkWrapper = styled(Link)<{ $loading?: boolean }>`
  ${baseButtonStyle}
  ${iconButtonStyle};
  color: black;
  text-decoration: none;

  & > ${IconButtonDefaultIconWrapper} {
    color: black;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
`;

export const OptionsButtonWrapper = styled.div`
  display: flex;
  position: relative;

  & > .button {
    border-radius: 0.3rem 0 0 0.3rem;
  }

  & > .arrow-button {
    border-radius: 0 0.3rem 0.3rem 0;
    width: 4rem;
    display: flex;
    padding: 0;
    margin: 0;
    align-items: center;
    justify-content: center;
    position: relative;

    &:after {
      content: ' ';
      position: absolute;
      z-index: 1;
      width: 1px;
      background-color: rgba(0, 0, 0, 0.3);
      left: 0;
      top: 10%;
      bottom: 10%;
    }
  }
`;

export const OptionsButtonArrow = styled(ChevronRightIcon)`
  transform: rotate(90deg);
`;

export const MultiStaticButtonWrapper = styled.div`
  display: flex;
  align-items: stretch;
  border: 1px solid #ccc;
  border-radius: 0.4rem;
  overflow: hidden;

  & > .button {
    border: 0 !important;
    border-radius: 0 !important;
  }

  & > .button + .button {
    border-left: 1px solid #ccc !important;
  }
`;

export const DropdownButtonWrapper = styled.div`
  display: flex;
  align-items: stretch;
  width: initial !important;
  position: relative;
`;

export const DropdownButtonButton = styled(PrimaryButtonWrapper)`
  border: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: initial !important;
`;

export const DropdownButtonArrowWrapper = styled.button`
  ${primaryButtonStyle};
  width: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0;
`;

export const DropdownButtonArrow = styled(ChevronRightIcon).attrs({
  size: '18',
  color: 'white',
})`
  transform: rotate(90deg);
`;

export const DropdownButtonContent = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 10;
  margin-top: 0.2rem;
`;

export const LineButton = styled(UnstyledButton)`
  padding: 0.4rem 0;
  font-weight: 500;

  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`;
