import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Label, useEditContext } from '@fcg-tech/regtech-components';
import { Select } from '@fcg-tech/regtech-formik-components';

import { FormRow, FormColumn, FormGroup } from '../../../components/FormLayout';
import { FormModal } from '../../../components/FormModal';
import { RoleParametersSubForm } from '../../../components/SubForms';
import { PolicyField } from '../../../components/PolicyField';

const Wrapper = styled.div`
  align-items: center;
  width: 100%;
  min-width: 56rem;
  min-height: 32rem;
`;

export const AddGroupRoleModal = ({
  role,
  roles,
  onSave,
  onCancel,
  onRoleChange,
}) => {
  const isEditEnabled = useEditContext();
  const [roleId, setRoleId] = React.useState('none');

  const roleItems = React.useMemo(() => {
    return [
      {
        key: 'none',
        label: '<None>',
      },
    ].concat(
      roles.map((role) => ({
        key: role.id,
        label: role.name,
      })),
    );
  }, [roles]);

  const handleRoleChange = React.useCallback(
    (key) => {
      setRoleId(key);
      if (onRoleChange) {
        onRoleChange(key);
      }
    },
    [onRoleChange],
  );

  return (
    <FormModal
      title="Add role"
      initialValues={{
        ...(role ?? {}),
        id: roleId,
        policy: JSON.stringify(role?.policy, null, 2),
      }}
      onSubmit={onSave}
      onCancel={onCancel}
    >
      {() => (
        <Wrapper>
          <FormRow>
            <FormColumn>
              <FormGroup>
                <Label column htmlFor="role-name">
                  Name
                </Label>
                <Select
                  id="role-name"
                  onChange={handleRoleChange}
                  value={roleId}
                  items={roleItems}
                  isEditEnabled={isEditEnabled}
                  typeahead
                />
              </FormGroup>
            </FormColumn>
          </FormRow>
          <RoleParametersSubForm />
          {role && roleId !== 'none' ? (
            <FormRow>
              <FormGroup>
                <PolicyField title="Policy" policy={role?.policy} />
              </FormGroup>
            </FormRow>
          ) : null}
        </Wrapper>
      )}
    </FormModal>
  );
};

AddGroupRoleModal.propTypes = {
  role: PropTypes.shape({}),
  roles: PropTypes.arrayOf(PropTypes.shape({})),
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onRoleChange: PropTypes.func,
};

AddGroupRoleModal.defaultProps = {
  role: null,
  roles: [],
  onSave: null,
  onCancel: null,
  onRoleChange: null,
};
