import { classNames } from '@fcg-tech/regtech-utils';
import React, { FunctionComponent, useCallback, useMemo, PropsWithChildren } from 'react';
import { ChevronRightIcon } from '../Icons';
import {
  ChevronLeftIcon,
  Dots,
  PaginationPage,
  PaginationWrapper,
} from './Pagination.styles';
import { getPages } from './paginationUtils';

export interface PaginationProps {
  currentPage: number;
  totalPages: number;
  disabled?: boolean;
  delta?: number;
  narrow?: boolean;
  onPageClick: (pageNumber: number) => void;
}

export const Pagination: FunctionComponent<PaginationProps> = ({
  currentPage,
  totalPages,
  disabled,
  narrow,
  delta,
  onPageClick,
}) => {
  const pages = useMemo(
    () => getPages(currentPage, totalPages, delta),
    [currentPage, delta, totalPages],
  );
  return narrow ? (
    <PaginationWrapper>
      <PaginationPageButton
        pageNumber={currentPage - 1}
        disabled={currentPage - 1 < 1}
        onClick={onPageClick}
      >
        <ChevronLeftIcon size="18" />
      </PaginationPageButton>
      <PaginationPageButton
        pageNumber={currentPage}
        current
        onClick={onPageClick}
      />
      <PaginationPageButton
        pageNumber={currentPage + 1}
        disabled={currentPage + 1 > totalPages}
        onClick={onPageClick}
      >
        <ChevronRightIcon size="18" />
      </PaginationPageButton>
    </PaginationWrapper>
  ) : (
    <PaginationWrapper>
      {pages.map((page, i) =>
        page ? (
          <PaginationPageButton
            key={page}
            pageNumber={page}
            current={currentPage === page}
            disabled={disabled}
            onClick={onPageClick}
          />
        ) : (
          <Dots key={`dots-${i}`}>...</Dots>
        ),
      )}
    </PaginationWrapper>
  );
};

interface PaginationPageButtonProps {
  pageNumber: number;
  current?: boolean;
  disabled?: boolean;
  onClick?: (pageNumber: number) => void;
}

const PaginationPageButton: FunctionComponent<PropsWithChildren<PaginationPageButtonProps>> = ({
  pageNumber,
  current,
  disabled,
  children,
  onClick,
}) => {
  const handleClick = useCallback(
    () => onClick(pageNumber),
    [pageNumber, onClick],
  );
  return (
    <PaginationPage
      role="button"
      className={classNames(current && 'current')}
      disabled={disabled}
      onClick={!disabled && !current ? handleClick : null}
    >
      {children ?? pageNumber}
    </PaginationPage>
  );
};
