import {
  DataTableCell,
  DataTableRow,
  DataTableRowOptions,
} from '@fcg-tech/regtech-components';
import { FunctionComponent, useCallback } from 'react';
import { Metadata } from '../../types';
import { formatZonedDateTime } from '../../utils/formatters';

export type RowData = Pick<Metadata, 'version' | 'modified' | 'modifiedBy'>;

export type ColumnKeys = 'version' | 'modified' | 'modifiedBy';

interface VersionHistoryTableRowProps {
  row: DataTableRowOptions<RowData>;
  onClick?: (id: string, version: string) => void;
}

export const VersionHistoryTableRow: FunctionComponent<
  VersionHistoryTableRowProps
> = ({ row, onClick }) => {
  const data = row.data;

  const handleClick = useCallback(() => {
    onClick?.(row.id, data.version);
  }, [data.version, onClick, row.id]);

  return (
    <DataTableRow row={row} onClick={handleClick}>
      <DataTableCell>{data.version}</DataTableCell>
      <DataTableCell>
        {data.modified ? formatZonedDateTime(data.modified) : 'N/A'}
      </DataTableCell>
      <DataTableCell>{data.modifiedBy}</DataTableCell>
    </DataTableRow>
  );
};
