import { useMemo } from 'react';
import useSWR, { mutate, SWRConfiguration } from 'swr';
import { useApi } from '../apiUtils';
import { Cabinet, CabinetsApi, GetCabinetResponse } from '../schema';
import { allCabinetsKey, cabinetKey } from './cacheKeys';

export const getAllCabinets = async (
  api: CabinetsApi,
): Promise<Array<Cabinet>> => {
  const result = await api.listAllCabinets();
  return result.result ?? [];
};

export const getCabinet = async (
  cabinetName: string,
  api: CabinetsApi,
): Promise<GetCabinetResponse> => {
  const result = await api.getCabinet({ cabinetName });
  return result;
};

export const useCabinets = (tenantId: string, config?: SWRConfiguration) => {
  const api = useApi<CabinetsApi>('CabinetsApi', tenantId);
  return useSWR<Array<Cabinet>>(
    allCabinetsKey(tenantId),
    () => getAllCabinets(api),
    {
      suspense: true,
      ...config,
    },
  );
};

export const useCabinet = (cabinetName: string, config?: SWRConfiguration) => {
  const tenant = 'xjk';
  const api = useApi<CabinetsApi>('CabinetsApi', tenant);
  return useSWR<GetCabinetResponse>(
    cabinetKey(tenant, cabinetName),
    () => getCabinet(cabinetName, api),
    { suspense: true, ...config },
  );
};

export const useCabinetActions = (tenantId: string) => {
  const api = useApi<CabinetsApi>('CabinetsApi', tenantId);
  return useMemo(
    () => ({
      upsertCabinet: async (cabinetName: string, cabinet?: Cabinet) => {
        await api.createOrUpdateCabinet({ cabinetName, cabinet });
        mutate(allCabinetsKey(tenantId));
      },
      deleteCabinet: async (cabinetName: string) => {
        await api.deleteCabinet({ cabinetName });
        // TODO: mutate
      },
    }),
    [api, tenantId],
  );
};
