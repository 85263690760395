import { FunctionComponent } from 'react';
import { PageScrollingWrapper } from '../../components/Page';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { SuspenseLoader } from '../../components/SuspenseLoader';
import { ReportsTable } from './ReportsTable';

export const ReportsPage: FunctionComponent = () => {
  return (
    <PageScrollingWrapper>
      <ErrorBoundary>
        <SuspenseLoader>
          <ReportsTable />
        </SuspenseLoader>
      </ErrorBoundary>
    </PageScrollingWrapper>
  );
};
