import React from 'react';
import { useAsync } from 'react-async';
import { useNavigate, useMatch } from 'react-router-dom';
import { ConfirmDialog, Loader } from '@fcg-tech/regtech-components';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { loadUser, deleteUser } from '../../api';
import { ErrorMessage } from '../../components/ErrorBoundary';
import { routes } from '../../routes';
import { useTenant } from '../../states/tenantState';
import { UserPage } from './components';

export const UserContainer = () => {
  const navigate = useNavigate();
  const match = useMatch(routes.user);
  const { username } = match.params;
  const tenant = useTenant();
  const tenantId = tenant.id;

  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);

  const decodedUsername = React.useMemo(
    () => decodeURIComponent(username),
    [username],
  );

  // promise fns
  const userReq = useAsync({
    promiseFn: loadUser,
    tenantId,
    username: decodedUsername,
  });

  //proxy fns
  const deleteUserProxy = async (args) => {
    await deleteUser(...args);
    navigate(constructUrl(routes.users, { tenantId }));
  };

  // defer fns
  const deleteUserReq = useAsync({ deferFn: deleteUserProxy });

  const handleDelete = React.useCallback(() => setShowDeleteConfirm(true), []);

  const handleDeleteChoice = React.useCallback(
    (confirm) => {
      if (confirm) {
        deleteUserReq.run({ tenantId, username: decodedUsername });
      }
      setShowDeleteConfirm(false);
    },
    [deleteUserReq, tenantId, decodedUsername],
  );

  if (userReq.isLoading) {
    return <Loader message="Loading user" />;
  }

  if (userReq.error) {
    return <ErrorMessage error={userReq.error} />;
  }

  return (
    <>
      {showDeleteConfirm ? (
        <ConfirmDialog
          title="Confirm"
          body="Are you sure you want to delete this user?"
          onChoice={handleDeleteChoice}
          confirmText="Yes, delete it"
          cancelText="No"
        />
      ) : null}
      <UserPage
        user={userReq.data}
        groups={userReq?.data?.groups}
        onDelete={handleDelete}
      />
    </>
  );
};
