import styled, { css } from 'styled-components';
import { excludeProps } from '../../utils';
import { Checkbox } from '../Checkbox';
import { ChevronRightIcon } from '../Icons';

export const BooleanTreeWrapper = styled.div`
  ${({ theme }) => css`
    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      padding-right: 1rem;
    }
  `};
`;

export const BooleanTreeRowWrapper = styled.div`
  & + & {
    margin-top: 1rem;
  }
`;

export const BooleanTreeRowInner = styled.div`
  display: flex;
  align-items: center;
`;

export const BooleanTreeRowCheckbox = styled(Checkbox)`
  margin-right: 0.3rem;
`;

export const BooleanTreeRowExpandButton = styled.button.attrs({
  'data-testid': 'boolean-tree-item-expand-button',
})`
  padding: 0;
  margin: 0 0.3rem 0 0;
  border: 0;
  background: none;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:focus,
  &:active {
    outline: rgba(0, 0, 0, 0.3) auto 1px;
  }

  &:hover {
    &:before {
      content: ' ';
      position: absolute;
      z-index: 1;
      top: -10%;
      left: -10%;
      width: 120%;
      height: 120%;
      background-color: #92cde3;
      border-radius: 0.3rem;
    }
  }
`;

export const BooleanTreeRowChevronIcon = styled(ChevronRightIcon).withConfig(
  excludeProps('expanded'),
)<{ expanded?: boolean }>`
  position: relative;
  z-index: 2;
  ${({ expanded }) =>
    expanded
      ? css`
          transform: rotate(90deg);
        `
      : null}
`;

export const BooleanTreeRowLabel = styled.div.withConfig(
  excludeProps('disabled'),
)<{ disabled?: boolean }>`
  ${({ theme, disabled }) => css`
    font-size: 1.4rem;

    ${disabled
      ? css`
          opacity: 0.5;
        `
      : null}

    @media(max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      font-size: 1.2rem;
    }
  `}
`;

export const BooleanTreeRowItems = styled.div.attrs({
  'data-testid': 'boolean-tree-row-items',
})`
  padding-top: 1rem;
  padding-left: 2.3rem;
`;
