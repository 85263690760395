import React from 'react';
import styled, { css } from 'styled-components';
import { Cell, Content, StyledChevronRightIcon } from './TableCell.styles';

interface TableCellProps extends React.HTMLAttributes<HTMLTableCellElement> {
  className?: string;
  inHead?: boolean;
  sortable?: boolean;
  sortKey?: string;
  isSorted?: boolean;
  isAscendingSortOrder?: boolean;
  visible?: boolean;
  colSpan?: HTMLTableCellElement['colSpan'];
}

export const TableCell = styled(
  ({
    children,
    className,
    inHead,
    sortable,
    sortKey,
    isSorted,
    isAscendingSortOrder,
    visible = true,
    ...props
  }: TableCellProps) =>
    inHead ? (
      <Cell data-key={sortKey} as="th" visible={visible} {...props}>
        <Content className={className} inHead={inHead}>
          {children}
          {sortable ? (
            <StyledChevronRightIcon
              ascending={isAscendingSortOrder}
              isActive={isSorted}
            />
          ) : null}
        </Content>
      </Cell>
    ) : (
      <Cell visible={visible} {...props}>
        <Content className={className} inHead={inHead}>
          {children}
        </Content>
      </Cell>
    ),
)`
  ${({ theme }) => css`
    box-sizing: border-box;
    color: ${theme?.palette?.MainBlack ?? '#000'};
    font-family: ${theme.typography?.fontFamily ?? 'inherit'};
    font-size: inherit;
    font-weight: inherit;
    text-align: left;
    min-height: 3.8rem;
    &:first-child {
      padding-left: 0;
    }
  `}
`;
