/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetSupplierResponse,
  GetSupplierVersionResponse,
  ListAllSupplierVersionsResponse,
  ListAllSuppliersResponse,
  Supplier,
} from '../models';
import {
    GetSupplierResponseFromJSON,
    GetSupplierResponseToJSON,
    GetSupplierVersionResponseFromJSON,
    GetSupplierVersionResponseToJSON,
    ListAllSupplierVersionsResponseFromJSON,
    ListAllSupplierVersionsResponseToJSON,
    ListAllSuppliersResponseFromJSON,
    ListAllSuppliersResponseToJSON,
    SupplierFromJSON,
    SupplierToJSON,
} from '../models';

export interface CreateSupplierRequest {
    supplier?: Supplier;
}

export interface DeleteSupplierRequest {
    supplierId: string;
}

export interface GetSupplierRequest {
    supplierId: string;
}

export interface GetSupplierVersionRequest {
    supplierId: string;
    version: string;
}

export interface ListAllSupplierVersionsRequest {
    supplierId: string;
}

export interface UpdateSupplierRequest {
    supplierId: string;
    supplier?: Supplier;
}

/**
 * SuppliersApi - interface
 * 
 * @export
 * @interface SuppliersApiInterface
 */
export interface SuppliersApiInterface {
    /**
     * 
     * @summary Create a Supplier
     * @param {Supplier} [supplier] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuppliersApiInterface
     */
    createSupplierRaw(requestParameters: CreateSupplierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Create a Supplier
     */
    createSupplier(requestParameters: CreateSupplierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Delete Supplier
     * @param {string} supplierId The id of the supplier to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuppliersApiInterface
     */
    deleteSupplierRaw(requestParameters: DeleteSupplierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete Supplier
     */
    deleteSupplier(requestParameters: DeleteSupplierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Get supplier
     * @param {string} supplierId The id of the supplier to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuppliersApiInterface
     */
    getSupplierRaw(requestParameters: GetSupplierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSupplierResponse>>;

    /**
     * Get supplier
     */
    getSupplier(requestParameters: GetSupplierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSupplierResponse>;

    /**
     * 
     * @summary Get an old version of an Supplier
     * @param {string} supplierId The id of the supplier to retrieve history about
     * @param {string} version The number of the version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuppliersApiInterface
     */
    getSupplierVersionRaw(requestParameters: GetSupplierVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSupplierVersionResponse>>;

    /**
     * Get an old version of an Supplier
     */
    getSupplierVersion(requestParameters: GetSupplierVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSupplierVersionResponse>;

    /**
     * 
     * @summary List all old version of an supplier
     * @param {string} supplierId The id of the supplier to retrieve history for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuppliersApiInterface
     */
    listAllSupplierVersionsRaw(requestParameters: ListAllSupplierVersionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListAllSupplierVersionsResponse>>;

    /**
     * List all old version of an supplier
     */
    listAllSupplierVersions(requestParameters: ListAllSupplierVersionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListAllSupplierVersionsResponse>;

    /**
     * 
     * @summary List all suppliers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuppliersApiInterface
     */
    listAllSuppliersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListAllSuppliersResponse>>;

    /**
     * List all suppliers
     */
    listAllSuppliers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListAllSuppliersResponse>;

    /**
     * 
     * @summary Update Supplier
     * @param {string} supplierId The id of the supplier to retrieve
     * @param {Supplier} [supplier] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuppliersApiInterface
     */
    updateSupplierRaw(requestParameters: UpdateSupplierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Update Supplier
     */
    updateSupplier(requestParameters: UpdateSupplierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class SuppliersApi extends runtime.BaseAPI implements SuppliersApiInterface {

    /**
     * Create a Supplier
     */
    async createSupplierRaw(requestParameters: CreateSupplierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/oasys/suppliers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SupplierToJSON(requestParameters.supplier),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a Supplier
     */
    async createSupplier(requestParameters: CreateSupplierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createSupplierRaw(requestParameters, initOverrides);
    }

    /**
     * Delete Supplier
     */
    async deleteSupplierRaw(requestParameters: DeleteSupplierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.supplierId === null || requestParameters.supplierId === undefined) {
            throw new runtime.RequiredError('supplierId','Required parameter requestParameters.supplierId was null or undefined when calling deleteSupplier.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/suppliers/{supplierId}`.replace(`{${"supplierId"}}`, encodeURIComponent(String(requestParameters.supplierId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Supplier
     */
    async deleteSupplier(requestParameters: DeleteSupplierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteSupplierRaw(requestParameters, initOverrides);
    }

    /**
     * Get supplier
     */
    async getSupplierRaw(requestParameters: GetSupplierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSupplierResponse>> {
        if (requestParameters.supplierId === null || requestParameters.supplierId === undefined) {
            throw new runtime.RequiredError('supplierId','Required parameter requestParameters.supplierId was null or undefined when calling getSupplier.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/suppliers/{supplierId}`.replace(`{${"supplierId"}}`, encodeURIComponent(String(requestParameters.supplierId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSupplierResponseFromJSON(jsonValue));
    }

    /**
     * Get supplier
     */
    async getSupplier(requestParameters: GetSupplierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSupplierResponse> {
        const response = await this.getSupplierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get an old version of an Supplier
     */
    async getSupplierVersionRaw(requestParameters: GetSupplierVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSupplierVersionResponse>> {
        if (requestParameters.supplierId === null || requestParameters.supplierId === undefined) {
            throw new runtime.RequiredError('supplierId','Required parameter requestParameters.supplierId was null or undefined when calling getSupplierVersion.');
        }

        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version','Required parameter requestParameters.version was null or undefined when calling getSupplierVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/suppliers/{supplierId}/versions/{version}`.replace(`{${"supplierId"}}`, encodeURIComponent(String(requestParameters.supplierId))).replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSupplierVersionResponseFromJSON(jsonValue));
    }

    /**
     * Get an old version of an Supplier
     */
    async getSupplierVersion(requestParameters: GetSupplierVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSupplierVersionResponse> {
        const response = await this.getSupplierVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all old version of an supplier
     */
    async listAllSupplierVersionsRaw(requestParameters: ListAllSupplierVersionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListAllSupplierVersionsResponse>> {
        if (requestParameters.supplierId === null || requestParameters.supplierId === undefined) {
            throw new runtime.RequiredError('supplierId','Required parameter requestParameters.supplierId was null or undefined when calling listAllSupplierVersions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/suppliers/{supplierId}/versions`.replace(`{${"supplierId"}}`, encodeURIComponent(String(requestParameters.supplierId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListAllSupplierVersionsResponseFromJSON(jsonValue));
    }

    /**
     * List all old version of an supplier
     */
    async listAllSupplierVersions(requestParameters: ListAllSupplierVersionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListAllSupplierVersionsResponse> {
        const response = await this.listAllSupplierVersionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all suppliers
     */
    async listAllSuppliersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListAllSuppliersResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/suppliers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListAllSuppliersResponseFromJSON(jsonValue));
    }

    /**
     * List all suppliers
     */
    async listAllSuppliers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListAllSuppliersResponse> {
        const response = await this.listAllSuppliersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update Supplier
     */
    async updateSupplierRaw(requestParameters: UpdateSupplierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.supplierId === null || requestParameters.supplierId === undefined) {
            throw new runtime.RequiredError('supplierId','Required parameter requestParameters.supplierId was null or undefined when calling updateSupplier.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/oasys/suppliers/{supplierId}`.replace(`{${"supplierId"}}`, encodeURIComponent(String(requestParameters.supplierId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SupplierToJSON(requestParameters.supplier),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update Supplier
     */
    async updateSupplier(requestParameters: UpdateSupplierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateSupplierRaw(requestParameters, initOverrides);
    }

}
