import { classNames } from '@fcg-tech/regtech-utils';
import { IconProps } from '../../types/icons';

export const SquareIcon = ({
  size = '24',
  color = 'currentColor',
  className,
  ...props
}: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    width={size}
    height={size}
    stroke={color}
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    fill="none"
    className={classNames(className, 'icon')}
    {...props}
  >
    <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
  </svg>
);
