/* eslint-disable @typescript-eslint/no-explicit-any */
import update from 'immutability-helper';
import { KeyLabelItem } from './types/misc';
import { isKeyLabelItem } from './guards';
import { NotificationType } from './types';
import { DefaultTheme } from 'styled-components';
import { useEffect, useRef } from 'react';

export const itemKeyGetter = <T = string>(
  item: T | KeyLabelItem<T>,
): T | null => (isKeyLabelItem<T>(item) ? item.key : item);

export const itemLabelGetter = <T = string>(
  item: T | KeyLabelItem<T>,
): string => {
  if (isKeyLabelItem<T>(item)) {
    return item.label;
  }

  if (
    item &&
    typeof item === 'object' &&
    Reflect.has(item as any, 'toString')
  ) {
    return (item as any).toString();
  }

  return '';
};

export const editDistance = (s1: string, s2: string) => {
  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();

  const costs: Array<number> = [];
  for (let i = 0; i <= s1.length; i++) {
    let lastValue = i;
    for (let j = 0; j <= s2.length; j++) {
      if (i === 0) costs[j] = j;
      else {
        if (j > 0) {
          let newValue = costs[j - 1];
          if (s1.charAt(i - 1) !== s2.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
    }
    if (i > 0) costs[s2.length] = lastValue;
  }
  return costs[s2.length];
};

export const similarity = (s1: string, s2: string): number => {
  let longer = s1;
  let shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  const longerLength = longer.length;
  if (longerLength === 0) {
    return 1.0;
  }
  return (
    (longerLength - editDistance(longer, shorter)) /
    parseFloat(longerLength.toString())
  );
};

export const defaultFilter = (
  item: string | number | KeyLabelItem,
  term: string,
  termRe: RegExp,
): number | false => {
  const str = (isKeyLabelItem(item) ? item?.label : item).toString();
  if (str.length && Boolean(str.toString().match(termRe))) {
    return similarity(str, term);
  }

  return false;
};

export const defaultSort = (
  a: string | number | KeyLabelItem,
  b: string | number | KeyLabelItem,
  term: string,
) => {
  const aa = isKeyLabelItem(a) ? a.label : a;
  const bb = isKeyLabelItem(b) ? b.label : b;
  if (typeof aa === 'number' && typeof bb === 'number') {
    return aa - bb;
  }
  if (typeof aa === 'string' && typeof bb === 'string') {
    return aa.indexOf(term) - bb.indexOf(term);
  }

  return aa.toLocaleString().localeCompare(bb.toLocaleString());
};

// generate random id
export const randId = () => Math.random().toString(32).substring(2);

// access token
const ACCESS_TOKEN = 'ACCESS_TOKEN';

/** @deprecated - use @fcg-tech/regtech-auth version instead */
export const setAccessToken = (value: string) => {
  window.sessionStorage.setItem(ACCESS_TOKEN, value);
};

/** @deprecated - use @fcg-tech/regtech-auth version instead */
export const getAccessToken = (): string | null => {
  return window.sessionStorage.getItem(ACCESS_TOKEN);
};

export const excludeProps = (...args: string[]) => ({
  shouldForwardProp: (prop: string) => !args.includes(prop),
});

export const truncateSentence = (
  str: string,
  maxLength = 80,
  suffix = '...',
) => {
  if (!str || str.length <= maxLength) {
    return str ?? '';
  }

  const trunc = str?.substring(0, maxLength - suffix.length) ?? '';
  return `${trunc.replace(/\s\w*$/, '')}${suffix}`;
};

const REGEXP_SCROLL_PARENT = /^(visible|hidden)/;

export const getScrollParent = (el: unknown): Element | null =>
  !(el instanceof HTMLElement) || typeof window.getComputedStyle !== 'function'
    ? null
    : el.scrollHeight >= el.clientHeight &&
      !REGEXP_SCROLL_PARENT.test(
        window.getComputedStyle(el).overflowY || 'visible',
      )
    ? el
    : getScrollParent(el.parentElement) ??
      document.scrollingElement ??
      document.body;

export const getThemedNotificationColor = (
  level: NotificationType,
  theme: DefaultTheme,
): string => {
  switch (level) {
    case NotificationType.Error:
      return theme?.colors?.error?.main ?? 'red';
    case NotificationType.Info:
      return theme?.colors?.info?.main ?? 'lightblue';
    case NotificationType.Success:
      return theme?.colors?.success?.main ?? 'lightgreen';
    case NotificationType.Warning:
      return theme?.colors?.warning?.main ?? 'orange';
  }
};

export const getThemedNotificationContrastColor = (
  level: NotificationType,
  theme: DefaultTheme,
): string => {
  switch (level) {
    case NotificationType.Error:
      return theme?.colors?.error?.contrast ?? 'white';
    case NotificationType.Info:
      return theme?.colors?.info?.contrast ?? 'black';
    case NotificationType.Success:
      return theme?.colors?.success?.contrast ?? 'black';
    case NotificationType.Warning:
      return theme?.colors?.warning?.contrast ?? 'black';
  }
};

/**
 * @deprecated - Use version in regtech-utils instead
 */
export const debounce = <Fn extends (...params: unknown[]) => void>(
  fn: Fn,
  delay: number,
) => {
  let id = 0;
  return function (...args: unknown[]) {
    clearTimeout(id);
    id = window.setTimeout(() => fn?.(...args), delay);
  } as Fn;
};

/**
 * @deprecated - Use version in regtech-utils instead
 */
export const omit = <T extends unknown, K extends keyof T>(
  obj: T,
  ...keys: Array<K>
): Omit<T, K> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return update(obj as any, {
    $unset: keys,
  }) as unknown as Omit<T, K>;
};

export const htmlDecode = (input: string) => {
  if (!input) {
    return '';
  }
  try {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent ?? input;
  } catch (err) {
    return input;
  }
};

/** @deprecated - use regtect-utils instead */
export const single = <T>(value: T | Array<T>): T =>
  Array.isArray(value) ? value[0] : value;

type Ref = { current?: unknown } | ((el: unknown) => void);

export const useCombinedRefs = (...refs: Array<Ref>) => {
  const targetRef = useRef();

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
};

export const isHtmlElement = (el: unknown): el is HTMLElement => {
  return el instanceof HTMLElement;
};

export const isBlockHtmlElement = (
  el: unknown,
): el is HTMLDivElement | HTMLParagraphElement => {
  return isHtmlElement(el) && ['div', 'p'].includes(el.tagName.toLowerCase());
};
