import React from 'react';
import { useAsync } from 'react-async';
import { useNavigate, useMatch } from 'react-router-dom';
import {
  ConfirmDialog,
  EditProvider,
  Loader,
  useFlash,
} from '@fcg-tech/regtech-components';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import {
  loadSupplier,
  updateSupplier,
  deleteSupplier,
  loadSupplierAttachments,
  uploadSupplierAttachment,
  deleteSupplierAttachment,
  downloadSupplierAttachment,
  loadSupplierAgreements,
  renameSupplierAttachment,
} from '../../api';
import { routes } from '../../routes';
import { useTenant } from '../../states/tenantState';
import { useErrorDialog } from '../../components/ErrorDialog';
import { AttachmentControlProvider } from '../../components/Attachments/AttachmentControlContext';
import { EditSupplierPage } from './components';
import { ErrorMessage } from '../../components/ErrorBoundary';

const cleanSupplierData = (supplier = {}) => {
  const copy = { ...supplier };
  if (copy.isUltimateParent) {
    copy.parentCompany = '';
  }
  if (!copy.supplierAssessment.isDueDiligenceConducted) {
    copy.supplierAssessment.latestDueDiligence = '';
  }
  if (!copy.supplierAssessment.isRiskAssessmentCompleted) {
    copy.supplierAssessment.latestRiskAssessment = '';
  }
  if (!copy.supplierAssessment.isCSRSurveyRequired) {
    copy.supplierAssessment.isCSRSurveyCompleted = null;
    copy.supplierAssessment.latestCSRSurvey = '';
  }
  if (!copy.supplierAssessment.isFollowCodeOfConductRequired) {
    copy.supplierAssessment.isFollowCodeOfConductConfirmed = null;
  }
  if (!copy.supplierAssessment.isInformationSecurityRequired) {
    copy.supplierAssessment.isInformationSecurityCompleted = null;
  }
  return copy;
};

export const EditSupplierContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tenant = useTenant();
  const tenantId = tenant.id;
  const match = useMatch(routes.supplier);
  const { supplierId } = match?.params ?? {};

  const [isSaving, setIsSaving] = React.useState(false);
  const [isEditEnabled, setEditEnabled] = React.useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);

  const showErrorDialog = useErrorDialog();
  const addFlash = useFlash();

  const loadSupplierReq = useAsync({
    promiseFn: loadSupplier,
    tenantId,
    supplierId,
  });

  const loadAgreementsReq = useAsync({
    promiseFn: loadSupplierAgreements,
    tenantId,
    supplierId,
    isArchived: false,
  });

  const loadAttachmentsReq = useAsync({
    promiseFn: loadSupplierAttachments,
    tenantId,
    supplierId,
  });

  const updateSupplierProxy = async (args) => {
    setIsSaving(true);
    try {
      await updateSupplier(...args);
      setEditEnabled(false);
      addFlash({
        level: 'success',
        content: t(MessageKeys.EditSupplierSuccessfullyUpdatedLabel),
      });
      loadSupplierReq.reload();
    } catch (err) {
      showErrorDialog({
        title: t(MessageKeys.LabelSomethingWentWrong),
        message: t(MessageKeys.LabelErrorOccured),
      });
    } finally {
      setIsSaving(false);
    }
  };

  const deleteSupplierProxy = async (args) => {
    try {
      await deleteSupplier(...args);
      addFlash({
        level: 'success',
        content: t(MessageKeys.EditSupplierSuccessfullyDeletedLabel),
      });
      navigate(constructUrl(routes.suppliers, { tenantId }));
    } catch (err) {
      showErrorDialog({
        title: t(MessageKeys.LabelSomethingWentWrong),
        message: t(MessageKeys.EditRSupplierFailedDeleteLabel),
      });
    }
  };

  const updateSupplierReq = useAsync({ deferFn: updateSupplierProxy });
  const deleteSupplierReq = useAsync({ deferFn: deleteSupplierProxy });

  const handleEdit = React.useCallback(
    () => setEditEnabled(true),
    [setEditEnabled],
  );

  const handleCancel = React.useCallback(
    () => setEditEnabled(false),
    [setEditEnabled],
  );

  const handleSave = React.useCallback(
    (supplier) => {
      updateSupplierReq.run({
        tenantId,
        supplierId,
        supplier: cleanSupplierData(supplier.data),
        version: supplier.metadata.version + 1,
      });
    },
    [tenantId, supplierId, updateSupplierReq],
  );

  const handleDelete = React.useCallback(() => setShowDeleteConfirm(true), []);

  const handleDeleteChoice = React.useCallback(
    (choice) => {
      if (choice) {
        deleteSupplierReq.run({ tenantId, supplierId });
      }
      setShowDeleteConfirm(false);
    },
    [tenantId, supplierId, deleteSupplierReq],
  );

  if (loadSupplierReq.isLoading) {
    return <Loader message={t(MessageKeys.LabelLoadingDetails)} />;
  }
  if (loadSupplierReq.error) {
    return <ErrorMessage error={loadSupplierReq.error} />;
  }

  const attachmentControl = {
    onUploadAttachment: async (files, section) => {
      await uploadSupplierAttachment({ tenantId, supplierId, files, section });
      loadAttachmentsReq.reload();
    },
    onDownloadAttachment: async (attachmentId) => {
      const { url } = await downloadSupplierAttachment({
        tenantId,
        supplierId,
        attachmentId,
      });
      return url;
    },
    onDeleteAttachment: async (attachmentId) => {
      try {
        await deleteSupplierAttachment({ tenantId, supplierId, attachmentId });
        const result = loadAttachmentsReq.data?.result?.filter(
          ({ id }) => id !== attachmentId,
        );
        loadAttachmentsReq.setData({ result }, () => {
          loadAttachmentsReq.reload();
        });
        return true;
      } catch (err) {
        return false;
      }
    },
    onRenameAttachment: async (attachmentId, newFilename) => {
      try {
        await renameSupplierAttachment({
          tenantId,
          supplierId,
          attachmentId,
          filename: newFilename,
        });
        const result = loadAttachmentsReq.data?.result?.map((attachment) =>
          attachment.id === attachmentId
            ? { ...attachment, filename: newFilename }
            : attachment,
        );
        loadAttachmentsReq.setData({ result }, () => {
          loadAttachmentsReq.reload();
        });
        return true;
      } catch (err) {
        return false;
      }
    },
  };

  return (
    <>
      {showDeleteConfirm && (
        <ConfirmDialog
          title={t(MessageKeys.LabelConfirm)}
          body={t(MessageKeys.EditSupplierConfirmDeleteLabel)}
          confirmText={t(MessageKeys.LabelConfirmDelete)}
          cancelText={t(MessageKeys.LabelNo)}
          onChoice={handleDeleteChoice}
        />
      )}
      <EditProvider value={isEditEnabled}>
        <AttachmentControlProvider value={attachmentControl}>
          <EditSupplierPage
            supplier={loadSupplierReq.data}
            agreements={loadAgreementsReq?.data?.result || []}
            attachments={loadAttachmentsReq.data?.result || []}
            isSaving={isSaving}
            onEdit={handleEdit}
            onCancel={handleCancel}
            onDelete={handleDelete}
            onSave={handleSave}
          />
        </AttachmentControlProvider>
      </EditProvider>
    </>
  );
};
