import React, { FunctionComponent, useCallback, useState } from 'react';
import { DropEvent, FileRejection } from 'react-dropzone';
import {
  UploadErrorIcon,
  UploadInProgessSpinner,
  UploadInProgressMessage,
  UploadZoneButton,
  UploadZoneButtonWrapper,
  UploadZoneError,
  UploadZoneHint,
  UploadZoneInner,
  UploadZoneMessage,
  UploadZoneWrapper,
} from './UploadZone.styles';
import { useUploadZone } from './useUploadZone';

interface UploadZoneProps {
  activeMessage?: string;
  hoverMessage?: string;
  selectFilesButtonLabel?: string;
  dragFilesHintLabel?: string | boolean;
  uploadErrorMessage?: string;
  uploadInProgressMessage?: string;
  className?: string;
  onDrop?: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => Promise<boolean>;
  allowMultiple?: boolean;
}

export const UploadZone: FunctionComponent<UploadZoneProps> = ({
  activeMessage = 'Drag files here',
  hoverMessage = 'Drop the files to upload',
  selectFilesButtonLabel = 'Select files',
  dragFilesHintLabel = 'Hint: You can also drag files here to upload them',
  uploadErrorMessage = 'Failed to upload',
  uploadInProgressMessage = 'Uploading file(s)...',
  onDrop,
  className,
  allowMultiple = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);

  const handleDrop = useCallback(
    async (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      event: DropEvent,
    ) => {
      try {
        setLoading(true);
        setLoadingError(false);
        const success = await onDrop(acceptedFiles, fileRejections, event);
        setLoadingError(!success);
      } catch (e) {
        setLoadingError(true);
      } finally {
        setLoading(false);
      }
    },
    [onDrop],
  );

  const {
    open,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragOverViewport,
  } = useUploadZone({
    noKeyboard: true,
    noClick: true,
    onDrop: handleDrop,
    multiple: allowMultiple,
  });

  return (
    <UploadZoneWrapper className={className}>
      <UploadZoneInner
        {...getRootProps()}
        isDragActive={isDragActive}
        isDragOverViewport={isDragOverViewport}
      >
        <input {...getInputProps()} />
        {isDragOverViewport ? (
          <UploadZoneMessage>
            {isDragActive
              ? hoverMessage
              : isDragOverViewport
              ? activeMessage
              : ''}
          </UploadZoneMessage>
        ) : loading ? (
          <UploadInProgressMessage>
            {uploadInProgressMessage}
            <UploadInProgessSpinner />
          </UploadInProgressMessage>
        ) : (
          <UploadZoneButtonWrapper>
            <UploadZoneButton onClick={open}>
              {selectFilesButtonLabel}
            </UploadZoneButton>
            {dragFilesHintLabel !== false && !loadingError ? (
              <UploadZoneHint>{dragFilesHintLabel as string}</UploadZoneHint>
            ) : null}
            {loadingError ? (
              <UploadZoneError>
                <UploadErrorIcon />
                {uploadErrorMessage}
              </UploadZoneError>
            ) : null}
          </UploadZoneButtonWrapper>
        )}
      </UploadZoneInner>
    </UploadZoneWrapper>
  );
};
