import { useTranslation } from 'react-i18next';
import { isMultiValueType } from '@fcg-tech/regtech-components';
import { classNames } from '@fcg-tech/regtech-utils';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import Select, {
  MultiValue,
  SingleValue,
  StylesConfig,
  SelectComponentsConfig,
  GroupBase,
} from 'react-select';
import AsyncSelect from 'react-select/async';
import {
  FilterClearButton,
  FilterExcludeCheckbox,
  FilterExcludeLabel,
  FilterExcludeWrapper,
  FilterNoItemsMessage,
  FilterRowLabel,
} from './Filter.styles';
import { FilterSelectMenu, FilterSelectMenuContext } from './FilterSelectMenu';
import {
  FilterCommonProps,
  FilterSelectOption,
  FilterValues,
  FilterWidgetProps,
} from './types';
import { multiSelectStyles, singleSelectStyles } from './utils';

const getOptionValue = (option: FilterSelectOption) =>
  option.value.id.toString();

type OnSearchReturn = [
  results: Array<FilterSelectOption>,
  truncated: boolean,
  lastId?: string,
];
export interface FilterSelectProps<
  T extends FilterValues,
  M extends boolean,
  K = keyof T,
> extends FilterCommonProps<T>,
    FilterWidgetProps<T> {
  options?: Array<FilterSelectOption>;
  allowMultipleSelect?: M;
  selectStyles?: Partial<StylesConfig<FilterSelectOption, boolean>>;
  noItemsAvailable?: boolean;
  searchOnOpen?: boolean;
  components?: SelectComponentsConfig<
    FilterSelectOption,
    M,
    GroupBase<FilterSelectOption>
  >;
  onSearch?: (
    searchString: string,
    filterPropKey: K,
    options?: {
      offset?: string;
    },
  ) => Promise<OnSearchReturn>;
}

export const FilterSelect = <T extends FilterValues, M extends boolean>({
  disabled,
  filter,
  filterId,
  filterPropKey,
  excludePropertyKey,
  options = [],
  className,
  allowExclude,
  allowMultipleSelect,
  label,
  clearLabel,
  excludeLabel,
  loadingMessage,
  noItemsAvailable,
  noItemsLabel,
  isExcluding,
  selectStyles,
  searchOnOpen,
  components,
  getNoResultsMessage,
  onClear,
  onChange,
  onSearch,
  onExclude,
}: FilterSelectProps<T, M>) => {
  const { t } = useTranslation();
  const [truncated, setTruncated] = useState(false);
  const [lastId, setLastId] = useState<string | undefined>(undefined);
  const value = filter?.[filterPropKey];
  const [tempValue, setTempValue] = useState<
    MultiValue<FilterSelectOption> | SingleValue<FilterSelectOption> | null
  >();
  const [searchString, setSearchString] = useState('');
  const isAsync = onSearch && !noItemsAvailable;

  const [overrideOptions, setOverrideOptions] = useState<
    Array<FilterSelectOption> | undefined
  >();

  const selectedOptions = useMemo<Array<FilterSelectOption>>(() => {
    if (Array.isArray(value)) {
      return (
        options.filter((opt) => value.includes(opt.value.id as never)) ?? []
      );
    }
    return [options.find((opt) => opt.value.id === value)].filter(
      Boolean,
    ) as Array<FilterSelectOption>;
  }, [options, value]);

  const handleChange = useCallback(
    (
      option:
        | MultiValue<FilterSelectOption>
        | SingleValue<FilterSelectOption>
        | null,
    ) => {
      setTempValue(option);
    },
    [],
  );

  const handleBlur = useCallback(() => {
    if (tempValue) {
      if (isMultiValueType(tempValue)) {
        onChange?.(
          filterPropKey,
          tempValue.map((o) => o.value.id as string),
          filterId,
        );
      } else {
        onChange?.(filterPropKey, tempValue.value.id, filterId);
      }
    }
    setTempValue(undefined);
    setSearchString('');
  }, [filterPropKey, onChange, tempValue, filterId]);

  const handleClear = useCallback(() => {
    onClear?.(filterPropKey);
    setSearchString('');
  }, [filterPropKey, onClear]);

  const searchPromise = useRef<
    Promise<Array<FilterSelectOption>> | undefined
  >();
  const searchTimer = useRef<ReturnType<typeof setTimeout> | undefined>();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const doSearch = useCallback(
    async (searchString: string, lastId?: string) => {
      setSearchString(searchString);
      setTruncated(false);
      setLastId(undefined);

      if (searchString.length || searchOnOpen) {
        if (searchTimer.current) {
          clearTimeout(searchTimer.current);
          searchTimer.current = undefined;
        }

        const promise = new Promise<Array<FilterSelectOption>>(
          (resolve, reject) => {
            searchTimer.current = setTimeout(async () => {
              try {
                const result = await onSearch?.(searchString, filterPropKey, {
                  offset: lastId,
                });
                if (result) {
                  const [items, truncated, lastId] = result;
                  if (truncated) {
                    setLastId(lastId);
                  }
                  setTruncated(truncated);
                  const value = isMultiValueType(tempValue)
                    ? tempValue
                    : tempValue
                    ? [tempValue]
                    : selectedOptions;

                  const opts = (items ?? []).filter(
                    (item) => !value.find((o) => o.value.id === item.value.id),
                  );

                  if (searchPromise.current === promise) {
                    resolve(opts);
                  } else {
                    resolve([]);
                  }
                }
              } catch (err) {
                reject(err);
              }
            }, 250);
          },
        );

        searchPromise.current = promise;
      } else {
        return Promise.resolve([]);
      }

      return searchPromise.current;
    },
    [filterPropKey, onSearch, searchOnOpen, selectedOptions, tempValue],
  );

  const handleSearch = useCallback(
    async (searchString: string) => {
      try {
        setIsLoading(true);
        const result = await doSearch(searchString);
        return result;
      } finally {
        setIsLoading(false);
      }
    },
    [doSearch],
  );

  const handleInputChange = useCallback(
    (searchString: string) => {
      // Special case for empty result, since handleSearch is not called when the string is erased
      if (searchString.length === 0) {
        setSearchString('');
        setTruncated(false);
      } else if (!onSearch) {
        setSearchString(searchString);
      }
    },
    [onSearch],
  );

  const handleExcludeChange = useCallback(
    (checked: boolean) => {
      onExclude?.(filterPropKey, checked);
    },
    [filterPropKey, onExclude],
  );

  const finalIsExcluding =
    isExcluding ??
    (
      excludePropertyKey && (filter?.[excludePropertyKey] as Array<unknown>)
    )?.includes?.(filterPropKey as never);

  const noResultsMessage = useMemo(() => {
    if (!getNoResultsMessage) {
      return 'N/A';
    }
    return isAsync
      ? t(
          getNoResultsMessage?.(searchString, truncated, !truncated && !lastId),
        )
      : t(
          getNoResultsMessage?.(
            searchString,
            truncated,
            (Array.isArray(tempValue)
              ? tempValue
              : [tempValue] ?? selectedOptions
            ).length === options.length,
          ) ?? 'N/A',
        );
  }, [
    getNoResultsMessage,
    isAsync,
    t,
    searchString,
    truncated,
    lastId,
    tempValue,
    selectedOptions,
    options.length,
  ]);

  const getResultMessage = useCallback(
    () => noResultsMessage,
    [noResultsMessage],
  );

  const handleMenuOpen = useCallback(async () => {
    if (!searchString) {
      try {
        setIsLoading(true);
        const result = await doSearch('');
        setOverrideOptions(result);
      } finally {
        setIsLoading(false);
      }
    }
  }, [doSearch, searchString]);

  const handleMenuScrollToBottom = useCallback(async () => {
    if (lastId && !searchString) {
      try {
        setIsLoadingMore(true);
        const result = await doSearch(searchString, lastId);
        setOverrideOptions((old) => {
          if (old) {
            return [...old, ...result];
          }
          return result;
        });
      } finally {
        setIsLoadingMore(false);
      }
    }
  }, [doSearch, lastId, searchString]);

  const asyncOptions = useMemo(() => {
    if (isLoading && !isLoadingMore) {
      return [];
    }

    return overrideOptions ?? options;
  }, [isLoading, isLoadingMore, options, overrideOptions]);

  const finalSelectStyles = useMemo<
    Partial<StylesConfig<FilterSelectOption, boolean>>
  >(() => {
    return {
      ...(allowMultipleSelect ? multiSelectStyles : singleSelectStyles),
      ...(selectStyles ?? {}),
    } as never;
  }, [allowMultipleSelect, selectStyles]);

  return (
    <FilterSelectMenuContext.Provider value={{ isLoadingMore }}>
      <FilterRowLabel>
        {label}
        <FilterClearButton
          className={classNames(disabled && 'disabled')}
          onClick={disabled ? undefined : handleClear}
        >
          {clearLabel}
        </FilterClearButton>
      </FilterRowLabel>
      {options?.length && !onSearch && !noItemsAvailable ? (
        <Select
          blurInputOnSelect={!allowMultipleSelect}
          closeMenuOnSelect={!allowMultipleSelect}
          components={components}
          getOptionValue={getOptionValue}
          hideSelectedOptions
          isClearable={false}
          isDisabled={disabled}
          isMulti={allowMultipleSelect}
          options={options}
          styles={finalSelectStyles}
          value={tempValue ?? selectedOptions}
          noOptionsMessage={getResultMessage}
          onBlur={handleBlur}
          onChange={handleChange}
          onInputChange={handleInputChange}
        />
      ) : null}
      {onSearch && !noItemsAvailable ? (
        <AsyncSelect
          value={tempValue ?? selectedOptions}
          options={asyncOptions}
          defaultOptions={asyncOptions}
          loadOptions={handleSearch}
          isMulti={allowMultipleSelect}
          closeMenuOnSelect={!allowMultipleSelect}
          blurInputOnSelect={!allowMultipleSelect}
          isClearable={false}
          isDisabled={disabled}
          hideSelectedOptions
          noOptionsMessage={getResultMessage}
          /*loadingMessage={loadingMessage}*/
          styles={finalSelectStyles}
          getOptionValue={getOptionValue}
          isLoading={isLoading}
          components={{
            ...components,
            Menu: FilterSelectMenu,
          }}
          onMenuOpen={handleMenuOpen}
          onMenuScrollToBottom={handleMenuScrollToBottom}
          onInputChange={handleInputChange}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      ) : null}
      {(!options?.length && !onSearch) || noItemsAvailable ? (
        <FilterNoItemsMessage>{noItemsLabel ?? ''}</FilterNoItemsMessage>
      ) : null}
      {onExclude && allowExclude ? (
        <FilterExcludeWrapper>
          <FilterExcludeLabel
            className={classNames(finalIsExcluding && 'checked')}
          >
            {excludeLabel}
            <FilterExcludeCheckbox
              disabled={!value}
              checked={finalIsExcluding}
              onChange={handleExcludeChange}
            />
          </FilterExcludeLabel>
        </FilterExcludeWrapper>
      ) : null}
    </FilterSelectMenuContext.Provider>
  );
};
