import { TabBar } from '@fcg-tech/regtech-components';
import { TabPanel } from 'reakit';
import styled from 'styled-components';

export const StickyTabBar = styled(TabBar)`
  position: sticky;
  box-shadow: none;
  top: 0px;
  z-index: 2;
  padding-left: 0;

  & .tabbar-tab:first-child {
    padding-left: 0;
  }

  & .tabbar-tab:first-child:after {
    left: 0;
  }
`;

export const TabPanelsWrapper = styled.div`
  background-color: white;
  height: inherit;
`;

export const TabPanelWrapper = styled(TabPanel)`
  display: flex;
  flex-direction: column;
  padding-top: 2.6rem;

  &.with-bottom-padding {
    padding-bottom: 2.6rem;
  }

  &:focus {
    outline: 0;
  }
`;
