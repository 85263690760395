/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo } from 'react';
import { parse } from 'json2csv';
import { downloadBlob } from './utils/blobHelpers';
import { Json2CsvOptions } from './types';

export const useJson2Csv = <T = any>(
  filename: string,
  data: Readonly<T> | ReadonlyArray<T>,
  opts?: Json2CsvOptions<T>,
) => {
  const handleDownload = useCallback(() => {
    const csv = parse(data, opts);
    downloadBlob(new Blob([csv]), filename);
  }, [data, opts, filename]);

  return { onDownloadCsv: handleDownload };
};

export const useUserAgent = (userAgent = window.navigator.userAgent) => {
  const isIE = useMemo(
    () =>
      userAgent.indexOf('MSIE') !== -1 || userAgent.indexOf('Trident/') !== -1,
    [userAgent],
  );

  const isEdge = useMemo(() => userAgent.indexOf('Edge/') !== -1, [userAgent]);

  return { isIE, isEdge };
};
