import styled, { css } from 'styled-components';
import { mobile } from '../../theme';
import { LabelWrapper } from '../Label/Label.styles';

export const FormRow = styled.div`
  ${({ theme }) => css`
    margin: 0;
    display: flex;
    width: 100%;
    align-items: flex-start;
    position: relative;
    & + & {
      margin-top: 2.8rem;
    }

    & > ${LabelWrapper} {
      width: 14rem;
      min-width: 14rem;
    }

    &.slim {
      & + & {
        margin-top: 1.2rem;
      }
    }

    &.single-line {
      align-items: center;
    }

    &:not(.single-line) > ${LabelWrapper} {
      margin-top: 1rem;
    }

    & > .input-checkbox-wrapper {
      position: relative;
      top: 0.8rem;
    }

    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      &:not(.force-row-layout) {
        flex-direction: column;
        align-items: flex-start;

        &.single-line,
        &:not(.single-line) {
          align-items: flex-start;
        }

        & > ${LabelWrapper} {
          margin-bottom: 0.4rem;
        }

        & > .textfield-wrapper {
          width: 100%;
        }
      }

      & > ${LabelWrapper} {
        width: auto;
        margin-right: 1.6rem;
        font-size: 1.1rem;
      }
    }
  `}
`;

export const FormRowColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  &.align-left {
    align-items: flex-start;
  }

  &.align-right {
    align-items: flex-end;
  }
`;

export const FormRowRow = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > ${FormRow} {
    flex: 1;
    min-width: 50ch;
    margin-top: 2.8rem;
  }

  & > ${FormRow} + ${FormRow} {
    margin-left: 1rem;
  }

  ${mobile(
    () => css`
      flex-wrap: nowrap;
      flex-direction: column;

      & > ${FormRow} + ${FormRow} {
        margin-left: 0;
      }
    `,
  )}
`;

export const FormButtonRow = styled(FormRow)`
  justify-content: flex-end;
  padding: 0.3rem;
  flex-direction: row !important;

  ${FormRow} + & {
    margin-top: 2.8rem;
  }

  & > .button + .button {
    margin-left: 1rem;
  }

  &.sticky--bottom {
    position: sticky;
    bottom: 0;
    padding: 1rem;
    border-top: 1px solid #ccc;
    z-index: 1;
    background-color: white;
  }

  ${FormRowRow} + & {
    margin-top: 2.6rem;
  }
`;

export const FormRowReadOnlyValue = styled.div`
  padding-top: 1rem;

  & > p {
    margin-top: 0;
  }
`;

export const FormRowInputWithButton = styled.div`
  display: flex;

  > .textfield-wrapper {
    flex: 0;
  }

  > .button {
    margin-left: 1rem;
  }
`;
