/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { classNames } from '@fcg-tech/regtech-utils';
import React, { useCallback, useEffect, useRef } from 'react';
import { Checkbox } from '../Checkbox';
import {
  DataTableSortIcon,
  TableHeadCellLabel,
  TableHeadCellWrapper,
} from './DataTable.styles';
import {
  DataTableColumnResizer,
  DataTableColumnResizerProps,
} from './DataTableColumnResizer';
import {
  DataTableColumnKeys,
  DataTableColumnOptions,
  DataTableSortOrder,
  getDataTableHeaderTitle,
} from './dataTableUtils';

interface DataTableHeadCellProps<P = any, ColumnKey = any> {
  id: string;
  column: DataTableColumnOptions<P, ColumnKey>;
  style: React.CSSProperties;
  sortable: boolean;
  sortOrder?: DataTableSortOrder<ColumnKey>;
  readOnly?: boolean;
  reorderable?: boolean;
  allSelected?: boolean;
  partiallySelected?: boolean;
  className?: string;
  onSortOrderChange?: (sortOrder: DataTableSortOrder<ColumnKey>) => void;
  onSelectAll?: (allSelected: boolean) => void;
  onColumnResize?: DataTableColumnResizerProps['onResize'];
}

export const DataTableHeadCell = function <P = any, ColumnKey = any>({
  id,
  column,
  style,
  sortable,
  sortOrder,
  readOnly,
  reorderable,
  allSelected,
  partiallySelected,
  className,
  onSortOrderChange,
  onSelectAll,
  onColumnResize,
}: DataTableHeadCellProps<P, ColumnKey>) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  if (transform) {
    transform.y = 0;
  }

  const dndStyle = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  dndStyle.transition = `box-shadow 0.2s ease-in ${
    dndStyle.transition ? `, ${dndStyle.transition}` : ''
  }`;

  const ascending =
    sortOrder?.columnKey && sortOrder.columnKey === column.columnKey
      ? sortOrder?.ascending
      : undefined;

  const active =
    sortOrder?.columnKey && sortOrder.columnKey === column.columnKey;

  const isDraggingRef = useRef(isDragging);
  useEffect(() => {
    if (isDragging && !isDraggingRef.current) {
      isDraggingRef.current = true;
    } else if (!isDragging && isDraggingRef.current) {
      setTimeout(() => (isDraggingRef.current = false), 10);
    }
  }, [isDragging]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLTableHeaderCellElement>) => {
      if (!isDraggingRef.current) {
        event.preventDefault();
        let newValue = undefined;
        if (ascending === undefined) {
          newValue = Boolean(column.initialSortAscending !== false);
        } else if (
          ascending &&
          Boolean(column.initialSortAscending !== false) === true
        ) {
          newValue = false;
        } else if (
          !ascending &&
          Boolean(column.initialSortAscending) === false
        ) {
          newValue = true;
        }
        onSortOrderChange?.({
          columnKey: column.columnKey,
          ascending: newValue,
        });
      }
    },
    [
      ascending,
      column.columnKey,
      column.initialSortAscending,
      onSortOrderChange,
    ],
  );

  const handleColumnResize = useCallback(
    (columnKey: ColumnKey, diffPixels: number) => {
      isDraggingRef.current = true;
      onColumnResize?.(columnKey, diffPixels);
    },
    [onColumnResize],
  );

  const handlePointerUp = useCallback(
    () => setTimeout(() => (isDraggingRef.current = false), 10),
    [],
  );

  const title = getDataTableHeaderTitle(column);

  return (
    <TableHeadCellWrapper
      as="th"
      ref={setNodeRef}
      style={{ ...style, ...dndStyle }}
      className={classNames(
        className,
        isDragging && 'isdragging',
        sortable && 'sortable',
        column.fixed && 'fixed',
      )}
      onClick={sortable ? handleClick : undefined}
      role={sortable ? 'button' : undefined}
      onPointerUp={handlePointerUp}
      {...(reorderable ? attributes : {})}
      {...(reorderable ? listeners : {})}
    >
      {(column.columnKey as any) === DataTableColumnKeys.CheckboxSelection ? (
        onSelectAll ? (
          <Checkbox
            checked={allSelected}
            partiallyChecked={!allSelected && partiallySelected}
            onChange={onSelectAll}
            disabled={readOnly}
          />
        ) : null
      ) : sortable && onSortOrderChange ? (
        <TableHeadCellLabel
          title={title}
          className={classNames(active && 'active')}
        >
          {typeof column.label === 'function' ? column.label() : column.label}
          <DataTableSortIcon
            className={classNames(
              (ascending ?? column.initialSortAscending) && 'ascending',
              active && 'active',
            )}
            size="18"
          />
        </TableHeadCellLabel>
      ) : (
        <TableHeadCellLabel title={title}>
          {typeof column.label === 'function' ? column.label() : column.label}
        </TableHeadCellLabel>
      )}
      {column.resizable && onColumnResize ? (
        <DataTableColumnResizer
          columnKey={column.columnKey}
          onResize={handleColumnResize}
        />
      ) : null}
    </TableHeadCellWrapper>
  );
};
