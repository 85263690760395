import { useTranslation } from 'react-i18next';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import {
  DownloadLocalIcon,
  PrimaryButton,
  TooltipNext as Tooltip,
  useFlash,
  usePagination,
  useToggle,
} from '@fcg-tech/regtech-components';
import {
  FilterBarButton,
  FilterBarIconButton,
  FilterSelect,
  FilterSelectOption,
  FilterTextField,
} from '@fcg-tech/regtech-filter';
import { downloadDataUrl, unique } from '@fcg-tech/regtech-utils';
import { FunctionComponent, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useSupplierActions } from '../../api/hooks/suppliersApiHooks';
import { GlobalActions } from '../../api/schema';
import { AccessControl } from '../../components/AccessControl';
import { ErrorMessage } from '../../components/ErrorBoundary';
import {
  FilterBorderWrapper,
  StyledFilterBar,
  SupplierFilter,
} from '../../components/Filter';
import { MainLayoutPaddedContentWrapper } from '../../components/MainLayout';
import {
  CreateSupplierModal,
  CsvExportModal,
  SearchHelpModal,
} from '../../components/modals';
import { PaginatedTableHeader } from '../../components/PaginatedTableHeader';
import {
  SupplierTable,
  SupplierTableAndFilterWrapper,
} from '../../components/SupplierTable';
import { getSupplierTableColumns } from '../../components/SupplierTable/supplierTableHelpers';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import { routes } from '../../routes';
import { useTenant } from '../../states/tenantState';
import { countryState } from '../../states/countryState';
import { MessageKeys } from '../../translations/messageKeys';
import {
  AvailableSupplierFilters,
  StoredSupplierFilter,
  Supplier,
  SupplierListItem,
} from '../../types';
import { useSupplierFilter } from '../../utils/filterHooks';
import { usePaginatedSuppliers } from '../../utils/paginationHooks';
import { DataTableColumn } from '@fcg-tech/regtech-datatable';
import { createSupplierTableExport } from '../../utils/csvUtils';

export const SuppliersOverviewTable: FunctionComponent = () => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const navigate = useNavigate();
  const [page, setPage] = usePagination(1);
  const countries = useRecoilValue(countryState);
  const addFlash = useFlash();
  const { createSupplier } = useSupplierActions(tenant.id);
  const [showFilter, , toggleShowFilter] = useToggle(false);
  const [showExportModal, , toggleExportModal] = useToggle();
  const [showCreateSupplierModal, , toggleCreateSupplierModal] = useToggle();
  const [showSearchHelpSupplierNameModal, , toggleSearchHelpSupplierNameModal] =
    useToggle();
  const [
    showSearchHelpCorporateRegistrationNumberModal,
    ,
    toggleSearchHelpCorporateRegistrationNumberModal,
  ] = useToggle();

  const columns = useMemo<Array<DataTableColumn<SupplierListItem>>>(
    () => getSupplierTableColumns(t),
    [t],
  );

  const {
    filter,
    filterId,
    storedFilters,
    pinnedFilters,
    sortBy,
    handleFilterChange,
    handleFilterClear,
    handleFilterDeleted,
    handleFilterPinned,
    handleFilterSaved,
    handleFilterValueChange,
    handleFilterValueClear,
    handleFilterValueExclude,
    handleStoredFilterSelected,
    handleSortByChange,
  } = useSupplierFilter();

  const { data, paginatedData, isValidating, error } = usePaginatedSuppliers(
    filter,
    page,
    DEFAULT_PAGE_SIZE,
  );
  const isLoading = !data && isValidating;
  const pagination = paginatedData?.pagination;

  const supplierFilter = useMemo<AvailableSupplierFilters>(() => {
    return {
      countriesOfRegistration: unique(
        data?.map((item) => item?.countryOfRegistration),
      ),
    };
  }, [data]);

  const [countryOfRegistrationOptions] = useMemo<
    Array<Array<FilterSelectOption>>
  >(() => {
    return [
      supplierFilter?.countriesOfRegistration
        ?.map<FilterSelectOption>((a) => {
          const country = countries.find((c) => c.isoAlpha2 === a);
          return {
            value: { id: a },
            label: country?.name ?? '',
          };
        })
        ?.sort((a, b) => a.label.localeCompare(b.label)),
    ];
  }, [countries, supplierFilter?.countriesOfRegistration]);

  const handlePageChange = useCallback(
    (pageNumber: number) => {
      if (pagination) {
        const totalPages = Math.ceil(
          pagination.totalResults / pagination.limit,
        );
        if (pageNumber <= totalPages && pageNumber >= 1) {
          setPage(pageNumber);
          return true;
        }
      }
      return false;
    },
    [pagination, setPage],
  );

  const handleRowClick = useCallback(
    (supplierId: string) => {
      navigate(
        constructUrl(routes.supplier, {
          tenantId: tenant.id,
          supplierId,
        }),
      );
    },
    [navigate, tenant.id],
  );

  const handleExportCsv = useCallback(
    (filename: string) => {
      const suppliers = data.map((supplier) => ({
        ...supplier,
        countryOfRegistration:
          countries.find((c) => c.isoAlpha2 === supplier.countryOfRegistration)
            ?.name ?? '',
      }));
      const result = createSupplierTableExport(columns, suppliers);
      downloadDataUrl(filename, result.data, result.contentType);
    },
    [columns, countries, data],
  );

  const handleCreateSupplier = useCallback(
    async (supplier: Supplier) => {
      try {
        const supplierId = await createSupplier(supplier);
        addFlash({
          level: 'success',
          content: t(MessageKeys.SupplierCreateSupplierSuccess),
        });
        toggleCreateSupplierModal();
        if (supplierId) {
          navigate(
            constructUrl(routes.supplier, { tenantId: tenant.id, supplierId }),
          );
        }
      } catch (err) {
        addFlash({
          level: 'error',
          content: t(MessageKeys.SupplierCreateSupplierFailure),
        });
      }
    },
    [
      addFlash,
      createSupplier,
      navigate,
      t,
      tenant.id,
      toggleCreateSupplierModal,
    ],
  );

  const handleSupplierEditClick = useCallback(
    (supplierId: string) =>
      navigate(
        constructUrl(routes.supplier, { tenantId: tenant.id, supplierId }),
      ),
    [navigate, tenant.id],
  );

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <>
      {showExportModal ? (
        <CsvExportModal
          filename="suppliers"
          onExport={handleExportCsv}
          onRequestClose={toggleExportModal}
        />
      ) : null}
      {showCreateSupplierModal ? (
        <CreateSupplierModal
          suppliers={data}
          loadingSuppliers={isLoading}
          onSupplierEditClick={handleSupplierEditClick}
          onSubmit={handleCreateSupplier}
          onRequestClose={toggleCreateSupplierModal}
        />
      ) : null}
      {showSearchHelpSupplierNameModal ? (
        <SearchHelpModal
          exampleLabel={t(MessageKeys.SearchHelpModalExample2Label)}
          exampleDescription={t(MessageKeys.SearchHelpModalExample2Description)}
          onHide={toggleSearchHelpSupplierNameModal}
        />
      ) : null}
      {showSearchHelpCorporateRegistrationNumberModal ? (
        <SearchHelpModal
          exampleLabel={t(MessageKeys.SearchHelpModalExample3Label)}
          exampleDescription={t(MessageKeys.SearchHelpModalExample3Description)}
          onHide={toggleSearchHelpCorporateRegistrationNumberModal}
        />
      ) : null}
      <MainLayoutPaddedContentWrapper>
        <PaginatedTableHeader
          currentPage={page}
          totalPages={Math.ceil(
            pagination?.totalResults / pagination?.limit ?? 1,
          )}
          totalResults={pagination?.totalResults}
          disabled={isLoading}
          onPageClick={handlePageChange}
          heading={t(MessageKeys.SuppliersHeading)}
        >
          <AccessControl requiredPermissions={GlobalActions.SupplierAdd}>
            <PrimaryButton onClick={toggleCreateSupplierModal}>
              {t(MessageKeys.SupplierCreateNewSupplierLabel)}
            </PrimaryButton>
          </AccessControl>
        </PaginatedTableHeader>
      </MainLayoutPaddedContentWrapper>
      <StyledFilterBar
        filters={pinnedFilters}
        currentFilterId={filterId}
        onPinnedFilterClick={handleStoredFilterSelected}
      >
        <Tooltip
          content={t(MessageKeys.LabelDownloadCSV)}
          showDelay={1000}
          placement="bottom-end"
        >
          <FilterBarButton id="export-button" onClick={toggleExportModal}>
            <DownloadLocalIcon size="18" />
          </FilterBarButton>
        </Tooltip>
        <FilterBarIconButton
          disabled={isLoading}
          toggled={showFilter}
          onClick={toggleShowFilter}
        />
      </StyledFilterBar>
      <SupplierTableAndFilterWrapper>
        <SupplierTable
          suppliers={paginatedData?.items}
          columns={columns}
          loading={isLoading}
          sortBy={sortBy}
          onRowClick={handleRowClick}
          onSortByChange={handleSortByChange}
        />
        {showFilter ? (
          <FilterBorderWrapper>
            <SupplierFilter
              filter={filter}
              filterId={filterId}
              storedFilters={storedFilters as Array<StoredSupplierFilter>}
              clearLabel={t(MessageKeys.LabelClear)}
              excludeLabel={t(MessageKeys.LabelExclude)}
              noItemsLabel={t(MessageKeys.LabelNoItems)}
              onDeleteFilter={handleFilterDeleted}
              onSaveFilter={handleFilterSaved}
              onPinFilter={handleFilterPinned}
              onFilterChange={handleFilterChange}
              onFilterClear={handleFilterClear}
              onFilterValueChange={handleFilterValueChange}
              onFilterValueClear={handleFilterValueClear}
              onFilterValueExclude={handleFilterValueExclude}
              onFilterLoad={handleStoredFilterSelected}
            >
              <FilterTextField
                filterPropKey="supplierName"
                label={t(MessageKeys.SupplierName)}
                onHelpClick={toggleSearchHelpSupplierNameModal}
              />
              <FilterSelect
                filterPropKey="countryOfRegistration"
                label={t(MessageKeys.SupplierCountryOfRegistration)}
                options={countryOfRegistrationOptions}
                allowMultipleSelect
              />
              <FilterTextField
                filterPropKey="corporateRegistrationNumber"
                label={t(MessageKeys.SupplierCorporateRegistrationNumber)}
                onHelpClick={toggleSearchHelpCorporateRegistrationNumberModal}
              />
            </SupplierFilter>
          </FilterBorderWrapper>
        ) : null}
      </SupplierTableAndFilterWrapper>
    </>
  );
};
