/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  horizontalListSortingStrategy,
  SortableContext,
} from '@dnd-kit/sortable';
import React, { useCallback, useMemo } from 'react';

import {
  TableHead,
  TableHeadCellLabel,
  TableHeadCellWrapper,
  TableRow,
} from './DataTable.styles';
import { DataTableColumnResizerProps } from './DataTableColumnResizer';
import { DataTableHeadCell } from './DataTableHeadCell';
import {
  DataTableColumnOptions,
  DataTableSortOrder,
  getDataTableHeaderTitle,
  renderDataTableHeaderLabel,
} from './dataTableUtils';

export interface DataTableHeaderProps<P = any, ColumnKey = any> {
  hidden?: boolean;
  columns: DataTableColumnOptions<P, ColumnKey>[];
  sortOrder?: DataTableSortOrder<ColumnKey>;
  readOnly?: boolean;
  allSelected?: boolean;
  partiallySelected?: boolean;
  onSelectAll?: (allSelected: boolean) => void;
  onSortOrderChange?: (sortOrder: DataTableSortOrder<ColumnKey>) => void;
  onColumnResize?: DataTableColumnResizerProps['onResize'];
  onColumnOrderChange?: (oldIndex: number, newIndex: number) => void;
}

export const DataTableHeader = React.forwardRef<
  HTMLTableSectionElement,
  DataTableHeaderProps
>(function <P = any, ColumnKey = any>(
  {
    hidden,
    columns,
    sortOrder,
    readOnly,
    allSelected,
    partiallySelected,
    onSelectAll,
    onSortOrderChange,
    onColumnResize,
    onColumnOrderChange,
  }: DataTableHeaderProps<P, ColumnKey>,
  ref: React.MutableRefObject<HTMLTableSectionElement>,
) {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { delay: 350, tolerance: 5 },
    }),
  );

  const items = useMemo(
    () => columns.map((column) => column.columnKey.toString()),
    [columns],
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (active && over && active?.id !== over?.id) {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);

        onColumnOrderChange?.(oldIndex, newIndex);
      }
    },
    [items, onColumnOrderChange],
  );

  const row = (
    <TableRow>
      {columns.map((column, i) => {
        const style: Record<string, unknown> = {};

        if (column.width && column.resizable) {
          style['width'] = `${column['width']}%`;
        } else if (column.width) {
          style['width'] = `${column['width']}px`;
        }
        const label = renderDataTableHeaderLabel(column);
        const wrappedLabel =
          typeof label === 'string' ? (
            <TableHeadCellLabel title={getDataTableHeaderTitle(column)}>
              {label}
            </TableHeadCellLabel>
          ) : (
            <div title={getDataTableHeaderTitle(column)}>{label}</div>
          );
        if (column.fixed) {
          return (
            <TableHeadCellWrapper
              key={column.columnKey.toString()}
              style={style}
              className="fixed"
            >
              {wrappedLabel}
            </TableHeadCellWrapper>
          );
        }
        const reorderable = Boolean(onColumnOrderChange);
        const resizable =
          column.resizable && columns[i + 1] && columns[i + 1].fixed !== true;

        return (
          <DataTableHeadCell
            style={style}
            id={column.columnKey.toString()}
            key={column.columnKey.toString()}
            reorderable={reorderable}
            sortable={Boolean(column.sortable && onSortOrderChange)}
            column={column}
            sortOrder={sortOrder}
            allSelected={allSelected}
            partiallySelected={partiallySelected}
            readOnly={readOnly}
            onColumnResize={resizable ? onColumnResize : null}
            onSelectAll={onSelectAll}
            onSortOrderChange={onSortOrderChange}
          />
        );
      })}
    </TableRow>
  );

  return (
    <TableHead className={hidden ? 'data-table-hidden' : ''} ref={ref}>
      {onColumnOrderChange ? (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={items}
            strategy={horizontalListSortingStrategy}
          >
            {row}
          </SortableContext>
        </DndContext>
      ) : (
        row
      )}
    </TableHead>
  );
});
