import React, { useContext, useMemo } from 'react';
import {
  StoredAgreementTableColumnOptions,
  AgreementTableType,
} from '../../types';
import {
  loadAgreementTableColumnOptions,
  saveAgreementTableColumnOptions,
} from './agreementTableHelpers';

type UpdateColumnOptions = React.Dispatch<
  React.SetStateAction<
    Record<string, Partial<StoredAgreementTableColumnOptions>>
  >
>;

export const AgreementTableContext = React.createContext<{
  columnOptions: Record<string, Partial<StoredAgreementTableColumnOptions>>;
  updateColumnOptions: UpdateColumnOptions;
}>(undefined);

export interface AgreementTableContextProviderProps {
  tableType: AgreementTableType;
}

export const AgreementTableContextProvider = ({
  children,
  tableType,
}: React.PropsWithChildren<AgreementTableContextProviderProps>) => {
  const [columnOptions, setColumnOptions] = React.useState(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useMemo(() => loadAgreementTableColumnOptions(tableType), []),
  );

  const updateColumnOptions = React.useCallback<UpdateColumnOptions>(
    (columnsOrUpdater) => {
      setColumnOptions((prevColumnOptions) => {
        let newData: Record<string, Partial<StoredAgreementTableColumnOptions>>;
        if (typeof columnsOrUpdater === 'function') {
          newData = columnsOrUpdater(prevColumnOptions);
        } else {
          newData = columnsOrUpdater;
        }

        saveAgreementTableColumnOptions(tableType, newData);

        return newData;
      });
    },
    [tableType],
  );

  return (
    <AgreementTableContext.Provider
      value={{ columnOptions, updateColumnOptions }}
    >
      {children}
    </AgreementTableContext.Provider>
  );
};

export const useAgreementTableContext = () => useContext(AgreementTableContext);
