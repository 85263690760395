import styled from 'styled-components';

export const ListDataTableTableWrapper = styled.div`
  margin: 2.4rem 0;
  padding: 0 1rem;
`;

export const ListDataTableEmptyTableState = styled.div`
  flex: 1;
  text-align: center;
`;

export const ListDataTableLeadParagraph = styled.p`
  margin-left: 1rem;
  font-size: 1.4rem;
  font-weight: 400;
`;
