import { FunctionComponent } from 'react';
import { QuestionIcon } from '../Icons';
import { TooltipNext as Tooltip, TooltipProps } from '../Tooltip';
import { InfoCircleIconWrapper } from './InfoCircle.styles';

export interface InfoCircleProps {
  info?: string;
  size?: string;
  className?: string;
  portal?: TooltipProps['portal'];
  placement?: TooltipProps['placement'];
}

export const InfoCircle: FunctionComponent<InfoCircleProps> = ({
  className,
  size = '16',
  info,
  placement,
  portal,
}) => {
  return (
    <Tooltip content={info} placement={placement} portal={portal}>
      <InfoCircleIconWrapper className={className}>
        <QuestionIcon size={size} />
      </InfoCircleIconWrapper>
    </Tooltip>
  );
};
