import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Cell } from './TableCell.styles';

export const TableHead = styled(
  ({
    children,
    ...props
  }: PropsWithChildren<React.HTMLAttributes<HTMLTableSectionElement>>) => (
    <thead {...props}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { inHead: true })
        }
        return null;
      }
      )}
    </thead>
  )
)`
  border-bottom: 2px solid ${({ theme }) => theme.colors?.border ?? 'rgba(0,0,0,0.3)'};
  width: 100%;
  white-space: nowrap;

  ${Cell} {
    font-size: 1.4rem;
    font-weight: 500;
  }
`;
