import styled, { css } from 'styled-components';
import { excludeProps } from '../../utils';
import { IconButton, SecondaryButton } from '../Button';
import { ChevronRightIcon } from '../Icons';

const BORDER_COLOR = '#ccc';
const AGENDA_PADDING = '1rem';

export const CalendarWrapper = styled.div`
  width: 100%;
`;

export const CalendarInnerWrapper = styled.div`
  border: 1px solid ${BORDER_COLOR};
`;

export const CalendarWeek = styled.div`
  display: flex;
  border-bottom: 1px solid ${BORDER_COLOR};

  &:last-child {
    border: 0;
  }
`;

export const CalendarDayWrapper = styled.div`
  flex: 1;
  flex-basis: calc(100% / 7);
  flex-shrink: 0;
  border-right: 1px solid ${BORDER_COLOR};
  padding: 1rem;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: calc(100% / 7);
  background-color: white;

  &:last-child {
    border: 0;
  }
`;

export const CalendarDayInnerWrapper = styled.div.withConfig(
  excludeProps('disabled'),
)<{
  disabled?: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.4;
        `
      : null}
`;

export const CalendarDayDate = styled.div.withConfig(excludeProps('today'))<{
  today?: boolean;
}>`
  ${({ theme, today }) => css`
    width: 3rem;
    height: 3rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    text-align: center;

    ${today
      ? css`
          border-radius: 50%;
          background-color: ${theme?.palette?.MainColor?.main ??
          'rgba(55, 82, 255, 1)'};
          color: ${theme?.palette?.MainColor?.mainContrast ?? 'white'};
          font-weight: 600;
        `
      : null}
  `}
`;

export const CalendarDayWeekday = styled(CalendarDayDate)`
  ${({ theme }) => css`
    font-weight: 600;
    text-transform: uppercase;
    color: #777;
    margin: 0;

    &.short-weekday {
      display: none;
    }

    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      display: none;
      &.short-weekday {
        display: block;
        height: 1.6rem;
      }
    }
  `};
`;

export const CalendarDayContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 13rem;
    width: 100%;

    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      min-height: 3rem;
    }
  `};
`;

export const CalendarNavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
`;

export const CalendarNavigationMonth = styled.h3`
  ${({ theme }) => css`
    margin: 0;
    padding: 0;
    font-size: 1.8rem;
    text-align: center;
    width: 19rem;
    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      width: auto;
      font-size: 1.4rem;
    }
  `};
`;

export const CalendarNavigationPrevButton = styled(IconButton)`
  margin-right: 1rem;
`;

export const CalendarNavigationPrevIcon = styled(ChevronRightIcon)`
  transform: rotate(180deg);
`;

export const CalendarNavigationNextButton = styled(IconButton)`
  margin-left: 1rem;
`;

export const CalendarNavigationContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 1rem;
`;

export const CalendarNavigationControls = styled.div`
  display: flex;
  align-items: center;
`;

export const CalendarTodayButton = styled(SecondaryButton)`
  margin-right: 2rem;
`;

export const CalendarEventWrapper = styled.div.withConfig(
  excludeProps('backgroundColor', 'color'),
)<{ backgroundColor?: string; color?: string }>`
  ${({ backgroundColor, color }) => css`
    padding: 0.5rem 1rem;
    font-weight: 600;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: ${backgroundColor ?? 'black'};
    color: ${color ?? 'white'};
    border-radius: 5px;
    cursor: pointer;

    max-width: 100%;

    & + & {
      margin-top: 0.5rem;
    }
  `}
`;

export const CalendarEventLabel = styled.span`
  flex: 1;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CalendarAgendaWrapper = styled.div`
  border-top: 1px solid ${BORDER_COLOR};
  border-bottom: 1px solid ${BORDER_COLOR};
  font-size: 1.4rem;
`;

export const CalendarAgendaDayDate = styled.div`
  ${({ theme }) => css`
    padding: ${AGENDA_PADDING};
    width: 20rem;
    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      width: 30vw;
      font-size: 1.2rem;
    }
  `}
`;

export const CalendarAgendaEventsWrapper = styled.div`
  flex: 1;
`;

export const CalendarAgendaEventWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    padding: ${AGENDA_PADDING} ${AGENDA_PADDING} ${AGENDA_PADDING} 0;
    cursor: pointer;

    & + & {
      border-top: 1px solid ${BORDER_COLOR};
    }

    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      align-items: flex-start;
    }
  `}
`;

export const CalendarAgendaEventRowWrapper = styled.div`
  & + & {
    border-top: 1px solid ${BORDER_COLOR};
  }
  display: flex;
`;

export const CalendarAgendEventPip = styled.span.withConfig(
  excludeProps('backgroundColor', 'color'),
)<{ backgroundColor?: string; color?: string }>`
  ${({ theme, backgroundColor, color }) => css`
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background-color: ${backgroundColor ?? 'black'};
    color: ${color ?? 'white'};
    margin-right: 1rem;
    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      position: relative;
      top: 0.2rem;
    }
  `}
`;

export const CalendarAgendaEventContent = styled.div`
  ${({ theme }) => css`
    flex: 1 0 0;
    font-weight: 600;
    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      font-size: 1.2rem;
    }
  `};
`;
