import styled from 'styled-components';

export const NotificationsListWrapper = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-bottom: 1rem;
`;

export const NotificationsListItem = styled.li`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  border-bottom: 1px solid
    ${({ theme }) => theme?.colors?.border ?? 'rgba(0,0,0,0.3)'};
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    cursor: pointer;
  }
`;

export const NotificationsListRowInputWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  align-items: center;
  padding-left: 0.2rem;
  min-height: 3.8rem;
  width: 100%;

  &.read {
    opacity: 0.3;
  }
`;

export const NotificationsListRowClickable = styled.div`
  display: flex;
  align-items: flex-start;
  align-items: center;
  min-height: 3.8rem;
  width: 100%;
`;

export const NotificationsListTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-left: 1.4rem;
  width: 100%;
  align-items: flex-start;
  flex: 1;
`;

export const NotificationsListTitle = styled.span`
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme?.typography?.fontFamily ?? 'inherit'};
  font-weight: bold;
`;

export const NotificationsListMessage = styled.span`
  font-family: ${({ theme }) => theme?.typography?.fontFamily ?? 'inherit'};
  font-size: 1rem;
  padding: 1rem 0;
`;

export const NotificationsListMetaData = styled.div`
  margin-top: 1rem;
  display: flex;
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 700;
  margin-right: 1.4rem;
`;

export const NotificationsListNoItemsMessage = styled.div`
  padding: 3rem 0;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 600;
`;
