import { format } from 'date-fns';
import React, { FunctionComponent } from 'react';
import { CalendarEventData, CalendarEventType } from '../../types/calendar';
import {
  CalendarAgendaDayDate,
  CalendarAgendaEventRowWrapper,
  CalendarAgendaEventsWrapper,
} from './Calendar.styles';
import { CalendarAgendaEvent } from './CalendarAgendaEvent';

interface CalendarAgendaEventRowProps {
  events: Array<CalendarEventData>;
  eventTypes?: Array<CalendarEventType>;
  className?: string;
  onEventClick?: (eventId: string | number, eventKey: string | number) => void;
}

export const CalendarAgendaEventRow: FunctionComponent<
  CalendarAgendaEventRowProps
> = ({ events, eventTypes, className, onEventClick }) => {
  const date = events[0]?.dateTime;

  return (
    <CalendarAgendaEventRowWrapper className={className}>
      <CalendarAgendaDayDate>{format(date, 'PPP')}</CalendarAgendaDayDate>
      <CalendarAgendaEventsWrapper>
        {events.map((event) => {
          const eventType = eventTypes.find(({ id }) => id === event.eventType);
          if (eventType) {
            if (eventType.component) {
              return (
                <eventType.component
                  key={event.key ?? event.id}
                  event={event}
                  eventType={eventType}
                  context="agenda"
                  onClick={onEventClick}
                />
              );
            }
            return (
              <CalendarAgendaEvent
                key={event.key ?? event.id}
                event={event}
                eventType={eventType}
                onClick={onEventClick}
              />
            );
          }
          return null;
        })}
      </CalendarAgendaEventsWrapper>
    </CalendarAgendaEventRowWrapper>
  );
};
