import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import {
  Card,
  CardBody,
  CardTitle,
  loadDataTableSortOrder,
  PageHeader,
  PageLayout,
  PageLayoutPaddedContent,
  useEditContext,
  useDataTableSortable,
  SecondaryButton,
  PrimaryButton,
  PageHeaderActionButton,
  AccessControl,
} from '@fcg-tech/regtech-components';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { Trash2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../../translations/messageKeys';
import { routes } from '../../../routes';
import { generalAgreementsListEntitiesCompareFn } from '../../../utils/compareFns';
import { getGeneralAgreementsListSortOrder } from '../../../utils/listDataTableHelpers';
import { useTenant } from '../../../states/tenantState';
import { categoryPropType } from '../../../propTypes';
import { DirtyFormCheck } from '../../../components/DirtyFormCheck';
import { CategoryDetailsSubForm } from '../../../components/SubForms';
import { ListDataTable } from '../../../components/ListDataTable';

export const EditCategoryPage = ({
  category,
  agreements,
  isSaving,
  onEdit,
  onCancel,
  onDelete,
  onSave,
}) => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const tenantId = tenant.id;
  const isEditEnabled = useEditContext();
  const navigate = useNavigate();

  const pageTitle = React.useMemo(
    () => category.data.name || t(MessageKeys.LabelUnnamed),
    [category.data.name, t],
  );

  const columns = React.useMemo(
    () => [
      {
        columnKey: 'name',
        label: t(MessageKeys.LabelName),
        resizable: false,
        sortable: true,
        initialSortAscending: true,
      },
      {
        columnKey: 'agreementType',
        label: t(MessageKeys.LabelAgreementType),
        resizable: false,
        sortable: true,
        initialSortAscending: false,
      },
      {
        columnKey: 'functionCategory',
        label: t(MessageKeys.LabelCategory),
        resizable: false,
        sortable: true,
        initialSortAscending: false,
      },
      {
        columnKey: 'contractOwner',
        label: t(MessageKeys.LabelContractOwner),
        resizable: false,
        sortable: true,
        initialSortAscending: false,
      },
    ],
    [t],
  );

  const rows = React.useMemo(
    () =>
      agreements?.map((agreement) => ({
        id: agreement.metadata.id,
        data: agreement,
      })) ?? [],
    [agreements],
  );

  const { sortedRows, sortOrder, handleSortOrderChange } = useDataTableSortable(
    'agreements-agreementType-table-sort-order',
    rows,
    {
      sortOrder:
        loadDataTableSortOrder('agreements-agreementType-table-sort-order') ??
        getGeneralAgreementsListSortOrder('nameAsc'),
      compareFn: generalAgreementsListEntitiesCompareFn,
    },
  );

  const handleClickRow = React.useCallback(
    (id) =>
      navigate(constructUrl(routes.agreement, { tenantId, agreementId: id })),
    [navigate, tenantId],
  );

  return (
    <Formik initialValues={category} onSubmit={onSave}>
      {({ handleSubmit, dirty, resetForm }) => (
        <PageLayout>
          <DirtyFormCheck dirty={dirty && isEditEnabled} />
          <PageHeader title={pageTitle}>
            {isEditEnabled ? (
              <>
                <SecondaryButton
                  onClick={() => {
                    resetForm();
                    onCancel();
                  }}
                >
                  {t(MessageKeys.LabelCancel)}
                </SecondaryButton>
                <PrimaryButton
                  onClick={() => handleSubmit()}
                  loading={isSaving}
                  disabled={!dirty || isSaving}
                >
                  {t(MessageKeys.LabelSubmit)}
                </PrimaryButton>
              </>
            ) : (
              <>
                <AccessControl
                  permissions={category.permissions}
                  requiredPermissions={['oasys:CategoryDelete']}
                >
                  <PageHeaderActionButton onClick={onDelete}>
                    <Trash2 size="24" />
                  </PageHeaderActionButton>
                </AccessControl>
                <AccessControl
                  permissions={category.permissions}
                  requiredPermissions={['oasys:CategoryEdit']}
                >
                  <PrimaryButton onClick={onEdit}>
                    {t(MessageKeys.LabelEdit)}
                  </PrimaryButton>
                </AccessControl>
              </>
            )}
          </PageHeader>
          <PageLayoutPaddedContent>
            <form onSubmit={handleSubmit}>
              <Card>
                <CardBody>
                  <CardTitle>{t(MessageKeys.LabelDetails)}</CardTitle>
                  <CategoryDetailsSubForm />
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <ListDataTable
                    title={t(MessageKeys.LabelAgreements)}
                    emptyTableText={t(
                      MessageKeys.LabelNoAgreementsFound,
                    )}
                    columns={columns}
                    data={sortedRows}
                    sortOrder={sortOrder}
                    handleSortOrderChange={handleSortOrderChange}
                    handleClickRow={handleClickRow}
                  />
                </CardBody>
              </Card>
            </form>
          </PageLayoutPaddedContent>
        </PageLayout>
      )}
    </Formik>
  );
};

EditCategoryPage.propTypes = {
  category: categoryPropType.isRequired,
  isSaving: PropTypes.bool,
  onSave: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
};

EditCategoryPage.defaultProps = {
  isSaving: false,
  onSave: null,
  onEdit: null,
  onDelete: null,
  onCancel: null,
};
