/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/ban-types */
// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../@types/react-table/index.d.ts" />
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { classNames } from '@fcg-tech/regtech-utils';
import { ArrowDown, ArrowUp } from 'lucide-react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { HeaderGroup } from 'react-table';

interface DataTableHeaderCellProps<D extends object = {}> {
  column: HeaderGroup<D>;
  reorderable?: boolean;
}

export const DataTableHeaderCell = <D extends object = {}>({
  column,
  reorderable,
}: DataTableHeaderCellProps<D>) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: column.id });

  if (transform) {
    transform.y = 0;
  }

  const dndStyle = useMemo(() => {
    const style = {
      transform: CSS.Translate.toString(transform),
      transition,
    };

    style.transition = `box-shadow 0.2s ease-in ${
      style.transition ? `, ${style.transition}` : ''
    }`;
    return style;
  }, [transform, transition]);

  const isDraggingRef = useRef(isDragging);

  useEffect(() => {
    if (isDragging && !isDraggingRef.current) {
      isDraggingRef.current = true;
    } else if (!isDragging && isDraggingRef.current) {
      setTimeout(() => (isDraggingRef.current = false), 10);
    }
  }, [isDragging]);

  const sortProps = column.getSortByToggleProps();
  const headerProps = column.getHeaderProps();
  const sortPropsOnClick = sortProps.onClick;

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (!isDraggingRef.current) {
        sortPropsOnClick?.(event);
      }
    },
    [sortPropsOnClick],
  );

  const handlePointerUp = useCallback(
    () => setTimeout(() => (isDraggingRef.current = false), 10),
    [],
  );

  headerProps.style = {
    ...(headerProps.style ?? {}),
    ...dndStyle,
  };

  return (
    <div
      {...headerProps}
      {...(reorderable ? attributes : {})}
      {...(reorderable ? listeners : {})}
      onPointerUp={handlePointerUp}
      className={classNames(
        'th',
        isDragging && 'dragging',
        column.isSorted && 'sorted',
      )}
      ref={setNodeRef}
      data-columnid={column.id}
    >
      <div {...sortProps} onClick={handleClick} className="th-content">
        <>{column.render('Header')}</>
        {column.isSorted ? (
          column.isSortedDesc ? (
            <ArrowDown size="16" className="lucide sorting-arrow" />
          ) : (
            <ArrowUp size="16" className="lucide sorting-arrow" />
          )
        ) : (
          ''
        )}
      </div>
      {column.canResize &&
      (column.maxWidth ?? Number.MAX_VALUE) > (column.minWidth ?? 0) ? (
        <div
          {...column.getResizerProps()}
          className={`resizer ${column.isResizing ? 'resizing' : ''}`}
        />
      ) : null}
    </div>
  );
};
