import { useTranslation } from 'react-i18next';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import {
  DataTable,
  DataTableColumnOptions,
  DataTableRowOptions,
  DataTableSortOrder,
  PrimaryButton,
  QuestionIcon,
  SpinningLoadingIcon,
  TooltipNext as Tooltip,
  useToggle,
} from '@fcg-tech/regtech-components';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTags } from '../../api/hooks/tagsApiHooks';
import { ErrorMessage } from '../../components/ErrorBoundary';
import { MainLayoutPaddedContentWrapper } from '../../components/MainLayout';
import { PaginatedTableHeader } from '../../components/PaginatedTableHeader';
import {
  TableLoadingWrapper,
  TableNoContent,
  TableWrapper,
} from '../../components/tables';
import Features from '../../Features';
import { routes } from '../../routes';
import { useTenant } from '../../states/tenantState';
import { MessageKeys } from '../../translations/messageKeys';
import { stringCompareFn } from '../../utils/compareFns';
import { TagsCreateModal } from './TagsCreateModal';
import { TagsTableRow, ColumnKeys, RowData } from './TagsTableRow';
import {
  TagsTableHeaderQuestionButton,
  TagsTableHeadingWrapper,
} from './TagsPage.styles';

export const TagsTable: FunctionComponent = () => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const navigate = useNavigate();
  const [showCreateModal, setShowCreateModal, toggleCreateModal] = useToggle();

  const { data, isValidating, error } = useTags(tenant.id, { suspense: false });
  const isLoading = !data && isValidating;

  const [sortOrder, setSortOrder] = useState<DataTableSortOrder<ColumnKeys>>({
    ascending: true,
    columnKey: 'name',
  });

  const columns = useMemo<DataTableColumnOptions<RowData, ColumnKeys>[]>(
    () => [
      {
        columnKey: 'name',
        label: t(MessageKeys.LabelName),
        resizable: false,
        sortable: true,
        initialSortAscending: true,
      },
    ],
    [t],
  );

  const dataRows = useMemo<DataTableRowOptions<RowData>[]>(() => {
    return data
      ?.map((tag) => ({
        id: tag.name,
        data: {
          name: tag.name,
        },
      }))
      .sort((a, b) => {
        if (sortOrder?.ascending === undefined) {
          return 0;
        }
        switch (sortOrder?.columnKey) {
          case 'name':
            return stringCompareFn(
              a.data.name,
              b.data.name,
              sortOrder.ascending,
            );
        }
      });
  }, [sortOrder, data]);

  const handleRowClick = useCallback(
    (tagId: string) => {
      navigate(
        constructUrl(routes.tag, {
          tenantId: tenant.id,
          tagId,
        }),
      );
    },
    [navigate, tenant.id],
  );

  const handleCreateTagModalRequestClose = useCallback(
    async (tagName?: string) => {
      if (tagName) {
        navigate(
          constructUrl(routes.tag, { tenantId: tenant.id, tagId: tagName }),
        );
      }
      setShowCreateModal(false);
    },
    [navigate, setShowCreateModal, tenant.id],
  );

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <>
      {showCreateModal ? (
        <TagsCreateModal onRequestClose={handleCreateTagModalRequestClose} />
      ) : null}
      <MainLayoutPaddedContentWrapper>
        <PaginatedTableHeader
          totalResults={dataRows?.length ?? 0}
          heading={
            <TagsTableHeadingWrapper>
              {t(MessageKeys.TagsHeading)}
              <Tooltip placement="right" content={Features.TagsListDescription}>
                <TagsTableHeaderQuestionButton>
                  <QuestionIcon size="18" />
                </TagsTableHeaderQuestionButton>
              </Tooltip>
            </TagsTableHeadingWrapper>
          }
        >
          <PrimaryButton onClick={toggleCreateModal}>
            {t(MessageKeys.TagsCreateNewTagLabel)}
          </PrimaryButton>
        </PaginatedTableHeader>
      </MainLayoutPaddedContentWrapper>
      <TableWrapper>
        <DataTable
          rows={dataRows}
          columns={columns}
          sortOrder={sortOrder.ascending !== undefined ? sortOrder : undefined}
          onSortOrderChange={setSortOrder}
          stickyHeader
        >
          {(row) => (
            <TagsTableRow key={row.id} row={row} onClick={handleRowClick} />
          )}
        </DataTable>
        {dataRows?.length === 0 ? (
          <TableNoContent>{t(MessageKeys.TagsNoItems)}</TableNoContent>
        ) : null}
        {isLoading ? (
          <TableLoadingWrapper>
            <SpinningLoadingIcon size="80" />
          </TableLoadingWrapper>
        ) : null}
      </TableWrapper>
    </>
  );
};
