import { array, single } from '@fcg-tech/regtech-utils';
import {
  AgreementFilter,
  AgreementFilterValues,
  ApiAgreementFilter,
  ApiGetAllAgreementsRequest,
  ApiStoredAgreementFilter,
  ApiStoredSupplierFilter,
  ApiSupplierFilter,
  AgreementsQueryFilter,
  StoredAgreementFilter,
  StoredSupplierFilter,
  SupplierFilter,
  SupplierFilterValues,
} from '../types';

import {
  convertArchivedOptionToBoolean,
  convertToDateFilter,
  dateConverter,
} from './converterUtils';

export const convertStoredAgreementFilter = (
  apiStoredFilter: ApiStoredAgreementFilter,
): StoredAgreementFilter => {
  if (!apiStoredFilter) {
    return null;
  }
  return {
    ...apiStoredFilter,
    filter: convertAgreementFilter(apiStoredFilter.filter),
  };
};

export const convertAgreementFilter = (
  apiFilter: ApiAgreementFilter,
): AgreementFilter => {
  if (!apiFilter) {
    return null;
  }
  const { name, startDate, endDate, latestReview, nextReview, ...rest } =
    apiFilter;

  const filter: AgreementFilterValues = {
    ...rest,
  };

  if (name) {
    filter.name = single(name);
  }
  if (startDate) {
    filter.startDate = dateConverter(startDate, 'from', 'to');
  }
  if (endDate) {
    filter.endDate = dateConverter(endDate, 'from', 'to');
  }
  if (latestReview) {
    filter.latestReview = dateConverter(latestReview, 'from', 'to');
  }
  if (nextReview) {
    filter.nextReview = dateConverter(nextReview, 'from', 'to');
  }

  return filter;
};

export const convertAgreementFilterForApi = (
  filter: AgreementFilter,
): ApiAgreementFilter => {
  if (!filter) {
    return null;
  }
  const { name, startDate, endDate, latestReview, nextReview, ...rest } =
    filter;

  const apiFilter: ApiAgreementFilter = {
    ...rest,
    name: array(name),
    startDate: convertToDateFilter(startDate),
    endDate: convertToDateFilter(endDate),
    latestReview: convertToDateFilter(latestReview),
    nextReview: convertToDateFilter(nextReview),
  };

  return apiFilter;
};

export const convertStoredSupplierFilter = (
  apiStoredFilter: ApiStoredSupplierFilter,
): StoredSupplierFilter => {
  if (!apiStoredFilter) {
    return null;
  }
  return {
    ...apiStoredFilter,
    filter: convertSupplierFilter(apiStoredFilter.filter),
  };
};

export const convertSupplierFilter = (
  apiFilter: ApiSupplierFilter,
): SupplierFilter => {
  if (!apiFilter) {
    return null;
  }

  const { supplierName, corporateRegistrationNumber, ...rest } = apiFilter;

  const filter: SupplierFilterValues = {
    ...rest,
  };

  if (supplierName) {
    filter.supplierName = single(supplierName);
  }
  if (corporateRegistrationNumber) {
    filter.corporateRegistrationNumber = single(corporateRegistrationNumber);
  }
  return filter;
};

export const convertSupplierFilterForApi = (
  filter: SupplierFilter,
): ApiSupplierFilter => {
  if (!filter) {
    return null;
  }
  const { supplierName, corporateRegistrationNumber, ...rest } = filter;

  const apiFilter: ApiSupplierFilter = {
    ...rest,
    supplierName: array(supplierName),
    corporateRegistrationNumber: array(corporateRegistrationNumber),
  };

  return apiFilter;
};

export const convertAgreementsQueryFilterForAPi = (
  filter: AgreementsQueryFilter,
): ApiGetAllAgreementsRequest => {
  const { archived, ...rest } = filter ?? {};
  const requestParams: ApiGetAllAgreementsRequest = {
    ...rest,
    isArchived: convertArchivedOptionToBoolean(archived),
  };
  return requestParams;
};
