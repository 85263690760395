import React from 'react';
import {
  ExpansionPanelSummaryWrapper,
  ExpansionPanelSummaryChevronIcon,
  ExpansionPanelSummaryTitle,
  ExpansionPanelSummarySubtitle,
} from './ExpansionPanelSummary.styles';

export interface ExpansionPanelSummaryProps {
  title: string;
  subtitle?: string;
  expanded?: boolean;
  disabled?: boolean;
  onToggle?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const ExpansionPanelSummary = ({
  title,
  subtitle,
  expanded,
  disabled,
  onToggle,
}: ExpansionPanelSummaryProps) => (
  <ExpansionPanelSummaryWrapper disabled={disabled} onClick={onToggle}>
    <div>
      <ExpansionPanelSummaryTitle>{title}</ExpansionPanelSummaryTitle>
      {subtitle ? (
        <ExpansionPanelSummarySubtitle>
          {subtitle}
        </ExpansionPanelSummarySubtitle>
      ) : null}
    </div>
    <ExpansionPanelSummaryChevronIcon expanded={expanded} />
  </ExpansionPanelSummaryWrapper>
);
