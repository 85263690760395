import React, { useCallback, useState } from 'react';
import { useNavigate, useMatch } from 'react-router-dom';
import { useAsync } from 'react-async';
import { Loader, ConfirmDialog, useFlash } from '@fcg-tech/regtech-components';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { useTranslation } from 'react-i18next';
import {
  loadAgreementTypePromiseFn,
  updateAgreementType,
  deleteAgreementType,
  loadAgreementTypeAgreementsPromiseFn,
} from '../../api/apis/agreementTypeAPI';
import { MessageKeys } from '../../translations/messageKeys';
import { useTenant } from '../../states/tenantState';
import { routes } from '../../routes';
import { AgreementType, SingleEntity } from '../../types';
import { useErrorDialog } from '../../components/ErrorDialog';
import { AgreementTypeEditPage } from './AgreementTypeEditPage';
import { ErrorMessage } from '../../components/ErrorBoundary';

export const AgreementTypeEditContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tenant = useTenant();
  const tenantId = tenant.id;
  const match = useMatch(routes.agreementType);
  const { agreementTypeId } = match?.params ?? {};

  const [isSaving, setIsSaving] = useState(false);
  const [isEditEnabled, setEditEnabled] = React.useState(false);

  const showErrorDialog = useErrorDialog();
  const addFlash = useFlash();

  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] =
    React.useState(false);

  const { data, error, isPending, reload } = useAsync({
    promiseFn: loadAgreementTypePromiseFn,
    tenantId,
    agreementTypeId,
  });

  const { data: loadAgreementTypeAgreementsReq } = useAsync({
    promiseFn: loadAgreementTypeAgreementsPromiseFn,
    tenantId,
    agreementType: agreementTypeId,
  });

  const handleSave = useCallback(
    async (values: AgreementType) => {
      setIsSaving(true);
      try {
        await updateAgreementType(tenantId, agreementTypeId, values);
        addFlash({
          level: 'success',
          content: t(MessageKeys.AgreementTypeSuccessfullyUpdatedLabel),
        });
        setEditEnabled(false);
        reload();
      } catch (err) {
        showErrorDialog({
          title: t(MessageKeys.LabelSomethingWentWrong),
          message: t(MessageKeys.LabelErrorOccured),
        });
      } finally {
        setIsSaving(false);
      }
    },
    [tenantId, agreementTypeId, addFlash, t, reload, showErrorDialog],
  );

  const handleDelete = useCallback(() => setShowDeleteConfirmDialog(true), []);

  const handleDeleteChoice = useCallback(
    async (choice: boolean) => {
      if (choice) {
        try {
          await deleteAgreementType(tenantId, agreementTypeId);
          addFlash({
            level: 'success',
            content: t(MessageKeys.AgreementTypeSuccessfullyDeletedLabel),
          });
          navigate(constructUrl(routes.agreementTypes, { tenantId }));
        } catch (err) {
          showErrorDialog({
            title: t(MessageKeys.LabelSomethingWentWrong),
            message: t(MessageKeys.AgreementTypeFailedDeleteLabel),
          });
        }
      }
      setShowDeleteConfirmDialog(false);
    },
    [tenantId, agreementTypeId, addFlash, t, navigate, showErrorDialog],
  );

  const handleEdit = useCallback(() => setEditEnabled(true), []);

  const handleCancel = useCallback(() => setEditEnabled(false), []);

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (isPending) {
    return <Loader message={t(MessageKeys.LabelLoadingDetails)} />;
  }
  return (
    <>
      {showDeleteConfirmDialog ? (
        <ConfirmDialog
          title={t(MessageKeys.LabelConfirm)}
          body={t(MessageKeys.AgreementTypeConfirmDeleteLabel)}
          confirmText={t(MessageKeys.LabelConfirmDelete)}
          cancelText={t(MessageKeys.LabelNo)}
          onChoice={handleDeleteChoice}
        />
      ) : null}
      <AgreementTypeEditPage
        agreementType={data as SingleEntity<AgreementType>}
        agreements={loadAgreementTypeAgreementsReq?.result ?? []}
        isEditEnabled={Boolean(isEditEnabled)}
        isSaving={isSaving}
        onEdit={handleEdit}
        onSave={handleSave}
        onDelete={handleDelete}
        onCancel={handleCancel}
      />
    </>
  );
};
