import React, { FunctionComponent, useCallback } from 'react';
import update from 'immutability-helper';
import { useNavigate } from 'react-router-dom';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import {
  PrimaryButton,
  FormButtonRow,
  InputCheckbox,
} from '@fcg-tech/regtech-components';
import { Notification } from '../../api/schema';
import { formatDateTime } from '../../utils';
import {
  NotificationsListItem,
  NotificationsListRowInputWrapper,
  NotificationsListTitle,
  NotificationsListMetaData,
  NotificationsListMessage,
  NotificationsListNoItemsMessage,
  NotificationsListTextWrapper,
  NotificationsListWrapper,
  NotificationsListRowClickable,
} from './NotificationsList.styles';
import { classNames } from '@fcg-tech/regtech-utils';

export interface NotificationsListProps {
  notifications?: Array<Notification>;
  emptyMessage?: string;
  loadButtonText?: string;
  isLoading?: boolean;
  isMoreNotifications?: boolean;
  loadMoreNotifications: () => void;
  selected?: Array<string>;
  onSelectionChange?: (selected: Array<string>) => void;
}

export const NotificationsList: FunctionComponent<NotificationsListProps> = ({
  notifications = [],
  emptyMessage,
  loadButtonText,
  isLoading,
  isMoreNotifications,
  loadMoreNotifications,
  selected,
  onSelectionChange,
}) => {
  const navigate = useNavigate();

  const handleRowClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const { id: notificationId } = e.currentTarget.dataset;
      const notification = notifications.find(
        ({ id }) => id === notificationId,
      );
      return notification?.link
        ? navigate(constructUrl(notification?.link?.path))
        : null;
    },
    [navigate, notifications],
  );

  const handleSelectChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const updated = event.target.checked
        ? update(selected ?? [], { $push: [event.target.value] })
        : update(selected ?? [], {
            $splice: [[(selected ?? []).indexOf(event.target.value), 1]],
          });

      onSelectionChange?.(updated);
    },
    [onSelectionChange, selected],
  );

  return (
    <>
      <NotificationsListWrapper className="notification-list">
        {notifications?.map((item) => (
          <NotificationsListItem
            key={item?.id}
            className="notification-list__item"
          >
            <NotificationsListRowInputWrapper
              className={classNames(item?.read && 'read')}
            >
              <>
                {onSelectionChange ? (
                  <InputCheckbox
                    value={item?.id}
                    onChange={handleSelectChange}
                    checked={selected?.includes(item?.id)}
                  />
                ) : null}
                <NotificationsListRowClickable
                  data-id={item?.id}
                  role="button"
                  onClick={handleRowClick}
                >
                  <NotificationsListTextWrapper>
                    <NotificationsListTitle>
                      {item?.title ?? 'N/A'}
                    </NotificationsListTitle>
                    <NotificationsListMessage>
                      {item?.message ?? ''}
                    </NotificationsListMessage>
                  </NotificationsListTextWrapper>
                  <NotificationsListMetaData>
                    {formatDateTime(item.timestamp)}
                  </NotificationsListMetaData>
                </NotificationsListRowClickable>
              </>
            </NotificationsListRowInputWrapper>
          </NotificationsListItem>
        ))}
        {!notifications?.length ? (
          <NotificationsListNoItemsMessage>
            {emptyMessage}
          </NotificationsListNoItemsMessage>
        ) : null}
      </NotificationsListWrapper>
      {isMoreNotifications ? (
        <FormButtonRow>
          <PrimaryButton onClick={loadMoreNotifications} loading={isLoading}>
            {loadButtonText ?? 'Load more notifications'}
          </PrimaryButton>
        </FormButtonRow>
      ) : null}
    </>
  );
};
