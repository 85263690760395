import memoizee from 'memoizee';
import { GlobalActions } from '../api/schema';
import { IamContextProps } from '../components/IamContext';
import { IamActions } from '../types';

export const canViewPageAgreements = memoizee((iamContext: IamContextProps) => {
  return iamContext?.globalPermissions?.includes(
    GlobalActions.ViewMyAgreements,
  );
});

export const canViewPageAgreementTypes = memoizee(
  (iamContext: IamContextProps) => {
    return iamContext?.globalPermissions?.includes(
      GlobalActions.ViewMyAgreementTypes,
    );
  },
);

export const canViewPageCabinets = memoizee((iamContext: IamContextProps) => {
  return iamContext?.globalPermissions?.includes(GlobalActions.ViewMyCabinets);
});

export const canViewPageCalendar = memoizee((iamContext: IamContextProps) => {
  return iamContext?.globalPermissions?.includes(GlobalActions.ViewMyCalendar);
});

export const canViewPageCategories = memoizee((iamContext: IamContextProps) => {
  return iamContext?.globalPermissions?.includes(
    GlobalActions.ViewMyCategories,
  );
});

export const canViewPageDashboard = memoizee((iamContext: IamContextProps) => {
  return iamContext?.globalPermissions?.includes(GlobalActions.ViewMyDashboard);
});

export const canViewPageInternalEntities = memoizee(
  (iamContext: IamContextProps) => {
    return iamContext?.globalPermissions?.includes(
      GlobalActions.ViewMyInternalEntities,
    );
  },
);

export const canViewPageReports = memoizee((iamContext: IamContextProps) => {
  return iamContext?.globalPermissions?.includes(GlobalActions.ViewMyReports);
});

export const canViewPagePersonalSettings = memoizee(
  (iamContext: IamContextProps) => {
    return iamContext?.globalPermissions?.includes(
      GlobalActions.ViewMySettings,
    );
  },
);

export const canViewPageSuppliers = memoizee((IamContext: IamContextProps) => {
  return IamContext?.globalPermissions?.includes(GlobalActions.ViewMySuppliers);
});

export const canViewPageTags = memoizee((iamContext: IamContextProps) => {
  return iamContext?.globalPermissions?.includes(GlobalActions.ViewMyTags);
});

export const canAgreementCreate = memoizee((iamContext: IamContextProps) => {
  return iamContext?.globalPermissions?.includes(GlobalActions.AgreementAdd);
});

export const canAgreementTypeCreate = memoizee(
  (iamContext: IamContextProps) => {
    return iamContext?.globalPermissions?.includes(
      GlobalActions.AgreementTypeAdd,
    );
  },
);

export const canCabinetCreate = memoizee((iamContext: IamContextProps) => {
  return iamContext?.globalPermissions?.includes(GlobalActions.CabinetAdd);
});

export const canCategoryCreate = memoizee((iamContext: IamContextProps) => {
  return iamContext?.globalPermissions?.includes(GlobalActions.CategoryAdd);
});

export const canInternalEntityCreate = memoizee(
  (iamContext: IamContextProps) => {
    return iamContext?.globalPermissions?.includes(
      GlobalActions.InternalEntityAdd,
    );
  },
);

export const canReportCreate = memoizee((iamContext: IamContextProps) => {
  return iamContext?.globalPermissions?.includes(GlobalActions.ReportAdd);
});

export const canSupplierCreate = memoizee((iamContext: IamContextProps) => {
  return iamContext?.globalPermissions?.includes(GlobalActions.SupplierAdd);
});

export const canNavigateToPermissionManagementAdministration = memoizee(
  (iamContext: IamContextProps) => {
    return (
      iamContext?.iamPermissions?.includes(IamActions.ViewMyGroups) ||
      iamContext?.iamPermissions?.includes(IamActions.ViewMyRoles) ||
      iamContext?.iamPermissions?.includes(IamActions.ViewMyUsers)
    );
  },
);

export const canViewPageGroups = memoizee((iamContext: IamContextProps) => {
  return iamContext?.iamPermissions?.includes(IamActions.ViewMyGroups);
});

export const canViewPageRoles = memoizee((iamContext: IamContextProps) => {
  return iamContext?.iamPermissions?.includes(IamActions.ViewMyRoles);
});

export const canViewPageUsers = memoizee((iamContext: IamContextProps) => {
  return iamContext?.iamPermissions?.includes(IamActions.ViewMyUsers);
});

export const canGroupCreate = memoizee((iamContext: IamContextProps) => {
  return iamContext?.iamPermissions?.includes(IamActions.GroupAdd);
});

export const canRoleCreate = memoizee((iamContext: IamContextProps) => {
  return iamContext?.iamPermissions?.includes(IamActions.RoleAdd);
});

export const canUserCreate = memoizee((iamContext: IamContextProps) => {
  return iamContext?.iamPermissions?.includes(IamActions.UserAdd);
});
