/* eslint-disable @typescript-eslint/no-explicit-any */
import { Translator } from '@fcg-tech/regtech-types';
import update from 'immutability-helper';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import type { DataTableRowProps } from './DataTableRow';

export enum DataTableColumnKeys {
  CheckboxSelection = 'checkboxSelection',
}

export type DataTableSortOrder<ColumnKey = any> = {
  columnKey: ColumnKey;
  ascending: boolean;
};

export type DataTableColumnOptions<P = any, ColumnKey = any> = {
  columnKey: ColumnKey;
  resizable: boolean;
  sortable: boolean;
  initialSortAscending?: boolean;
  /**
   * If resizable, must be a percentage e.g. 75. If not resizable, can be pixels or undefined.
   * Setting all resizable columns to 1 will divide the available space equally among them
   * Will default to 1%/20px if omitted
   */
  width?: number;
  /**
   * Min width in pixels
   */
  minWidth?: number;
  /**
   * Ideal width in pixels. Not currently used by DataTable.
   */
  /**
   * Fixed column will remain in place and cannot be moved. Fixed columns should always appear as the first columns.
   * Having normal columns before fixed columns will result in an undefined behaviour
   */
  fixed?: boolean;
  label?: React.ReactNode | (() => React.ReactNode);
  labelTitle?: string;
  getContent?: (
    t: Translator,
    row: DataTableRowOptions<P>,
    columnKey: ColumnKey,
    context?: unknown,
  ) => string | React.ReactNode;
  getTitle?: (
    row: DataTableRowOptions<P>,
    columnKey: ColumnKey,
    context?: unknown,
  ) => string;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type DataTableRowOptions<P = any> = {
  id: string;
  selected?: boolean;
  data: P;
};

export type DataTableSortableOptions<P = any, ColumnKey = any> = {
  sortOrder: DataTableSortOrder<ColumnKey>;
  compareFn: (a: P, b: P, sortOrder: DataTableSortOrder<ColumnKey>) => number;
};

export const addMissingWidths = <P = any>(
  columns: Array<DataTableColumnOptions<P>>,
) =>
  columns.filter(Boolean).map((column) =>
    update(column, {
      width: {
        $set:
          column.width ??
          (column.resizable
            ? 100 / columns.length
            : column.fixed
            ? 20
            : undefined),
      },
    }),
  );

export const filterDataTableRowSelection = <P = any>(
  rows: DataTableRowOptions<P>[],
): P[] => [...rows?.filter((row) => row.selected)?.map((row) => row.data)];

const normalizePercenteges = (columns: Array<DataTableColumnOptions>) => {
  const totalPercentages = columns.reduce(
    (w, c) => w + (c.resizable ? c.width : 0) ?? 0,
    0,
  );

  return columns.map((col) => ({
    ...col,
    width:
      col.width && col.resizable
        ? (col.width / totalPercentages) * 100
        : col.width,
  }));
};

export const clampPercentageWidths = (
  columns: DataTableColumnOptions[],
  distributableWidthPx?: number,
): Array<DataTableColumnOptions> => {
  let updated = normalizePercenteges(columns);

  if (distributableWidthPx) {
    const hardWidths = columns.reduce(
      (w, c) => w + (c.resizable ? 0 : c.width),
      0,
    );

    const distributablePercentage = 1 - hardWidths / distributableWidthPx;

    const pixelWidths = updated.map(
      ({ resizable, width, minWidth }) =>
        (resizable
          ? Math.max(
              ((width ?? 0) / 100) *
                distributablePercentage *
                Math.round(distributableWidthPx),
              minWidth,
            )
          : width) ?? 0,
    );

    const totalPixelWidth = pixelWidths.reduce((s, c) => s + c, 0);

    const normalizedPixelWidths = pixelWidths.map(
      (pw) => (pw / totalPixelWidth) * distributableWidthPx,
    );

    updated.forEach((col, i) => {
      col.width = col.resizable
        ? (normalizedPixelWidths[i] / distributableWidthPx) * 100
        : col.width;
    });

    updated = normalizePercenteges(updated);
  }

  return updated;
};

export const guessMinWidths = (
  columns: DataTableColumnOptions[],
): DataTableColumnOptions[] => {
  return columns.map((col) => ({
    ...col,
    minWidth:
      col.minWidth ??
      (col.label && typeof col.label === 'string'
        ? col.label.length * 7 + 20
        : Math.max(3, col.width ?? 3)),
  }));
};

export const saveDataTableColumnWidths = (
  key: string,
  columns: DataTableColumnOptions[],
) =>
  localStorage.setItem(key, JSON.stringify(columns.map(({ width }) => width)));

export const loadDataTableColumnWidths = (
  key: string,
  mergeInto: DataTableColumnOptions[],
): DataTableColumnOptions[] => {
  const widths = JSON.parse(localStorage.getItem(key)) ?? [];
  return mergeInto.map((m, i) => ({
    ...m,
    width: widths[i] ?? m.width,
  }));
};

export const useDataTableColumnWidths = (key: string) => {
  const handleColumnOptionsChange = useCallback(
    (columns: DataTableColumnOptions[]) => {
      saveDataTableColumnWidths(key, columns);
    },
    [key],
  );
  return handleColumnOptionsChange;
};

const sortRows = <P = any, ColumnKey = any>(
  rows: DataTableRowOptions<P>[],
  sortOrder: DataTableSortOrder<ColumnKey>,
  compareFn: DataTableSortableOptions<P, ColumnKey>['compareFn'],
) => rows.sort((a, b) => compareFn(a.data, b.data, sortOrder));

export const saveDataTableSortOrder = <P = any>(
  key: string,
  sortOrder: DataTableSortOrder<P>,
) => localStorage.setItem(key, JSON.stringify(sortOrder));

export const loadDataTableSortOrder = (key: string): DataTableSortOrder =>
  JSON.parse(localStorage.getItem(key));

export const useDataTableSortable = <P = any, ColumnKey = any>(
  key: string,
  rows: DataTableRowOptions<P>[],
  {
    sortOrder: initialSortOrder,
    compareFn,
  }: DataTableSortableOptions<P, ColumnKey>,
) => {
  const [sortOrder, setSortOrder] = useState(initialSortOrder);
  const [sortedRows, setSortedRows] = useState(() =>
    sortRows(rows, sortOrder, compareFn),
  );

  useEffect(() => {
    const next = sortRows(rows, sortOrder, compareFn);
    setSortedRows(next);
  }, [rows, sortOrder, compareFn]);

  const handleSortOrderChange = useCallback(
    (sortOrder: DataTableSortOrder<ColumnKey>) => {
      setSortOrder(sortOrder);
      saveDataTableSortOrder(key, sortOrder);
      setSortedRows((old) => sortRows(old, sortOrder, compareFn));
    },
    [compareFn, key],
  );

  return { sortedRows, sortOrder, handleSortOrderChange };
};

export const areRowPropsEqual = (
  a: React.PropsWithChildren<DataTableRowProps>,
  b: React.PropsWithChildren<DataTableRowProps>,
) => {
  for (const key in a) {
    // Special case for columns. We're only interested in if the columns props has changed in length, or if the columns have changed order.
    // If their respective widths have changed, we don't care. The column width is set in the header only
    if (key === 'columns') {
      if (
        a.columns.length !== b.columns.length ||
        !a.columns.every((col, i) => {
          if (b.columns[i] && b.columns[i].columnKey === col.columnKey) {
            return true;
          }
          return false;
        })
      ) {
        return false;
      }
    } else if (a[key] !== b[key]) {
      return false;
    }
  }

  return true;
};

export const reorderColumns = (
  columns: Array<DataTableColumnOptions>,
  oldIndex: number,
  newIndex: number,
) => {
  const item = columns[oldIndex];
  const copy = [...columns];

  copy.splice(oldIndex, 1);
  copy.splice(newIndex, 0, item);

  return copy;
};

export const getDataTableHeaderTitle = (
  column: DataTableColumnOptions,
): string | undefined => {
  return (
    column.labelTitle ??
    (typeof column.label === 'string' ? column.label : undefined)
  );
};

export const renderDataTableHeaderLabel = (column: DataTableColumnOptions) => {
  const { label } = column;
  if (typeof label === 'string') {
    return label;
  }

  if (typeof label === 'function') {
    return label();
  }

  return label;
};

export const DataTableContext = React.createContext<unknown>(null);

export const useDataTableContext = () => useContext(DataTableContext);
