import styled, { css } from 'styled-components';
import { IconButton, mobile } from '@fcg-tech/regtech-components';

export const AttachmentTableWrapper = styled.div`
  position: relative;
  margin: 2.4rem 0;
  padding: 0 1rem;
`;

export const AttachmentTableHeaderWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    align-items: flex-end;
    overflow: hidden;

    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      flex-wrap: wrap;
    }
  `}
`;

export const AttachmentTableHeaderCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
`;

export const AttachmentTableHeaderRightCell = styled(AttachmentTableHeaderCell)`
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  min-width: initial;
  margin-left: 2rem;

  ${mobile(
    () => css`
      margin-top: 0.6rem;
      margin-left: 0;
      justify-content: space-between;
      width: 100%;
    `,
  )}
`;

export const AttachmentTableLoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const AttachmentTableNoAttachmentsMessage = styled.div`
  flex: 1;
  text-align: center;
`;

export const AttachmentTableActions = styled.div`
  & > * + * {
    margin-left: 0.5rem;
  }
`;

export const AttachmentTableActionButton = styled(IconButton)`
  width: 2.8rem;
  height: 2.8rem;
`;
