import update from 'immutability-helper';
import { BooleanTreeItem } from './booleanTreeTypes';

type Predicate = (
  item: BooleanTreeItem,
  items: Array<BooleanTreeItem>,
) => boolean;

export const expandBooleanTreeParentsWithSubSelections = (
  items: Array<BooleanTreeItem>,
  predicate?: Predicate,
) => {
  return items.map((item) => {
    if (!item.expanded) {
      return {
        ...item,
        expanded:
          predicate?.(item, items) ??
          Boolean(
            items.find(
              ({ parentId, selected }) => selected && parentId === item.id,
            ),
          ),
      };
    }
    return item;
  });
};

/**
 * 0 = no recursion
 * -1 = infinite recursion
 * n = recursion n times
 */
type RecursiveDepth = number;

const getBooleanTreeItemSelectedInstructions = (
  items: Array<BooleanTreeItem>,
  parentId: string | number,
  selected: boolean,
  recursiveDepth: RecursiveDepth = 0,
) => {
  const instructions = items.reduce<
    Record<number, { selected: { $set: boolean } }>
  >((inst, item, index) => {
    if (item.parentId === parentId) {
      inst[index] = { selected: { $set: selected } };
      if (recursiveDepth > 0 || recursiveDepth === -1) {
        const subInstructions = getBooleanTreeItemSelectedInstructions(
          items,
          item.id,
          selected,
          recursiveDepth === -1 ? -1 : recursiveDepth - 1,
        );
        return {
          ...inst,
          ...subInstructions,
        };
      }
    }
    return inst;
  }, {});

  return instructions;
};

export const setBooleanTreeItemSelected = <T>(
  items: Array<BooleanTreeItem<T>>,
  itemId: string | number,
  selected: boolean,
  recursiveDepth: RecursiveDepth = 0,
) => {
  const index = items.findIndex(({ id }) => id === itemId);
  let instructions = {
    [index]: { selected: { $set: selected } },
  };
  if (recursiveDepth > 0 || recursiveDepth === -1) {
    const subInstructions = getBooleanTreeItemSelectedInstructions(
      items,
      itemId,
      selected,
      recursiveDepth,
    );
    instructions = {
      ...instructions,
      ...subInstructions,
    };
  }

  return update(items, instructions);
};

export const isItemPartiallySelected = (item: BooleanTreeItem): boolean => {
  if (item?.subItems?.length > 0) {
    const selectedItems = item.subItems.filter(({selected}) => selected);
    if (selectedItems.length > 0 && selectedItems.length < item.subItems.length) {
      return true;
    }
    return Boolean(item.subItems.find(isItemPartiallySelected));
  }
  return false;
}
