import { useTranslation } from 'react-i18next';
import {
  FormRow,
  Modal,
  ModalBody,
  ModalButtonRow,
  ModalHeader,
  ModalTitle,
  PrimaryButton,
  SecondaryButton,
  TextField,
  useFlash,
  useInput,
} from '@fcg-tech/regtech-components';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useAgreementTypeActions } from '../../api/hooks/agreementTypesApiHooks';
import { ModalFormLabel, ModalFormWrapper } from '../../components/modals';
import { useTenant } from '../../states/tenantState';
import { MessageKeys } from '../../translations/messageKeys';

interface AgreementTypesCreateModalProps {
  onRequestClose: (agreementTypeId?: string) => void;
}

export const AgreementTypesCreateModal: FunctionComponent<
  AgreementTypesCreateModalProps
> = ({ onRequestClose }) => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const addFlash = useFlash();
  const [loading, setLoading] = useState(false);
  const [name, handleNameChange] = useInput<HTMLInputElement>('');
  const { createAgreementType } = useAgreementTypeActions(tenant.id);

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      setLoading(true);
      try {
        const agreementTypeId = await createAgreementType(name);
        addFlash({
          level: 'success',
          content: t(MessageKeys.AgreementTypesCreateAgreementTypeSuccess),
        });
        onRequestClose(agreementTypeId);
      } catch (err) {
        setLoading(false);
        addFlash({
          level: 'error',
          content: t(MessageKeys.AgreementTypesCreateAgreementTypeFailure),
        });
      }
    },
    [addFlash, createAgreementType, name, onRequestClose, t],
  );

  const handleHide = useCallback(
    () => onRequestClose(undefined),
    [onRequestClose],
  );

  return (
    <Modal onHide={handleHide}>
      <ModalHeader>
        <ModalTitle>
          {t(MessageKeys.AgreementTypesCreateNewAgreementTypeLabel)}
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <ModalFormWrapper onSubmit={handleSubmit}>
          <FormRow>
            <ModalFormLabel htmlFor="agreement-type-name">
              {t(MessageKeys.LabelName)}
            </ModalFormLabel>
            <TextField
              id="agreement-type-name"
              value={name}
              onChange={handleNameChange}
            />
          </FormRow>
          <ModalButtonRow>
            <SecondaryButton type="button" onClick={handleHide}>
              {t(MessageKeys.LabelCancel)}
            </SecondaryButton>
            <PrimaryButton
              type="submit"
              loading={loading}
              disabled={!name || loading}
            >
              {t(MessageKeys.LabelSubmit)}
            </PrimaryButton>
          </ModalButtonRow>
        </ModalFormWrapper>
      </ModalBody>
    </Modal>
  );
};
