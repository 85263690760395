import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Card,
  CardBody,
  CardTitle,
  Field,
  InfoCircle,
  Label,
} from '@fcg-tech/regtech-components';
import {
  BooleanDecision,
  PillMultiSelect,
  Select,
} from '@fcg-tech/regtech-formik-components';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { MessageKeys } from '../../../translations/messageKeys';
import {
  agreementPropType,
  metadataPropType,
  internalEntityPropType,
  attachmentPropType,
  formikFieldShape,
} from '../../../propTypes';
import { NONE_SELECT_ITEM } from '../../../constants';
import { countrySelectOptions } from '../../../utils/optionConverters';
import { getTooltip } from '../../../utils/tooltipHelpers';
import { countryState } from '../../../states/countryState';
import { FormRow, FormColumn, FormGroup } from '../../FormLayout';
import { Attachments } from '../../Attachments';
import { FieldErrorNonBlocking } from '../../FieldError/FieldErrorNonBlocking';
import { FieldCompare } from '../../FieldCompare/FieldCompare';
import Features from '../../../Features';

const ValidationToggles =
  Features.FieldErrors.NonBlocking.Agreement.PersonalData;

const Institution = styled.span`
  font-size: 1.4rem;
`;

const PartyToAgreementContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
`;

const PartyToAgreement = ({ field, institutions, info }) => {
  const institution = institutions.find(
    ({ metadata: { id } }) => id === field.value,
  );
  return (
    <PartyToAgreementContainer>
      <Institution>{institution ? institution.name : 'N/A'}</Institution>
      {info && <InfoCircle info={info} />}
    </PartyToAgreementContainer>
  );
};

PartyToAgreement.propTypes = {
  field: formikFieldShape.isRequired,
  info: PropTypes.string,
  institutions: PropTypes.arrayOf(internalEntityPropType).isRequired,
};

PartyToAgreement.defaultProps = {
  info: null,
};

const roles = [
  NONE_SELECT_ITEM,
  {
    key: 'dataController',
    label: 'Data controller',
  },
  {
    key: 'jointController',
    label: 'Joint controller',
  },
  {
    key: 'dataProcessor',
    label: 'Data processor',
  },
];

export const PersonalDataSection = ({
  agreement,
  metadata,
  attachments,
  internalEntities,
}) => {
  const { t } = useTranslation();
  const countries = useRecoilValue(countryState);

  const countryOptions = useMemo(
    () => countries?.map(countrySelectOptions),
    [countries],
  );

  return (
    <Card>
      <CardBody>
        <CardTitle>{t(MessageKeys.PersonalDataSectionLabel)}</CardTitle>
        <FormRow>
          <FormColumn>
            <FormGroup>
              <BooleanDecision.FieldContainer>
                <BooleanDecision.FieldLabel>
                  {t(MessageKeys.PersonalDataSectionDataTransferredLabel)}
                </BooleanDecision.FieldLabel>
                <Field
                  component={BooleanDecision}
                  name="personalData.isPersonalDataTransferred"
                  info={getTooltip('personalData.isPersonalDataTransferred')}
                />
              </BooleanDecision.FieldContainer>
              {ValidationToggles.IsPersonalDataTransferred && (
                <FieldErrorNonBlocking name="personalData.isPersonalDataTransferred" />
              )}
              <FieldCompare name="personalData.isPersonalDataTransferred" />
            </FormGroup>
          </FormColumn>
          <FormColumn />
        </FormRow>
        {agreement.personalData.isPersonalDataTransferred && (
          <FormRow>
            <FormColumn>
              <FormGroup>
                <Label column htmlFor="agreement-countries-data-transferred">
                  {t(
                    MessageKeys.PersonalDataSectionDataTransferredCountryLabel,
                  )}
                </Label>
                <Field
                  component={PillMultiSelect}
                  items={[...countryOptions]}
                  name="personalData.countriesWhereDataIsTransferred"
                  id="agreement-countries-data-transferred"
                  info={getTooltip(
                    'personalData.isPersonalDataTransferredCountry',
                  )}
                  typeahead
                />
                <FieldErrorNonBlocking name="personalData.countriesWhereDataIsTransferred" />
                <FieldCompare name="personalData.countriesWhereDataIsTransferred" />
              </FormGroup>
            </FormColumn>
            <FormColumn />
          </FormRow>
        )}
        <FormRow>
          <FormColumn>
            <FormGroup>
              <BooleanDecision.FieldContainer>
                <BooleanDecision.FieldLabel>
                  {t(MessageKeys.PersonalDataSectionDataProcessedLabel)}
                </BooleanDecision.FieldLabel>
                <Field
                  component={BooleanDecision}
                  name="personalData.isPersonalDataProcessed"
                  info={getTooltip('personalData.isPersonalDataProcessed')}
                />
              </BooleanDecision.FieldContainer>
              {ValidationToggles.IsPersonalDataProcessed && (
                <FieldErrorNonBlocking name="personalData.isPersonalDataProcessed" />
              )}
              <FieldCompare name="personalData.isPersonalDataProcessed" />
            </FormGroup>
          </FormColumn>
          <FormColumn />
        </FormRow>
        {agreement.personalData.isPersonalDataProcessed ? (
          <>
            <FormRow>
              <FormColumn>
                <FormGroup>
                  <BooleanDecision.FieldContainer>
                    <BooleanDecision.FieldLabel>
                      {t(MessageKeys.PersonalDataSectionPartyAgreementLabel)}
                    </BooleanDecision.FieldLabel>
                    <Field
                      component={PartyToAgreement}
                      name="details.partyToAgreement"
                      info={getTooltip('details.partyToAgreement')}
                      institutions={internalEntities}
                    />
                  </BooleanDecision.FieldContainer>
                  <FieldCompare name="details.partyToAgreement" />
                </FormGroup>
              </FormColumn>
              <FormColumn />
            </FormRow>
            <FormRow>
              <FormColumn>
                <FormGroup>
                  <Label column htmlFor="personalData.role">
                    {t(MessageKeys.PersonalDataSectionRoleLabel)}
                  </Label>
                  <Field
                    component={Select}
                    name="personalData.role"
                    info={getTooltip('personalData.role')}
                    items={roles}
                    typeahead
                  />
                  {ValidationToggles.Role && (
                    <FieldErrorNonBlocking name="personalData.role" />
                  )}
                  <FieldCompare name="personalData.role" nameOptions={roles} />
                </FormGroup>
              </FormColumn>
              <FormColumn />
            </FormRow>
            <FormRow>
              <FormColumn>
                <FormGroup>
                  <BooleanDecision.FieldContainer>
                    <BooleanDecision.FieldLabel>
                      {t(
                        MessageKeys.PersonalDataSectionDataProtectionIncludedLabel,
                      )}
                    </BooleanDecision.FieldLabel>
                    <Field
                      component={BooleanDecision}
                      name="personalData.dataProtectionIncluded"
                      info={getTooltip('personalData.dataProtectionIncluded')}
                    />
                  </BooleanDecision.FieldContainer>
                  {ValidationToggles.DataProtectionIncluded && (
                    <FieldErrorNonBlocking name="personalData.dataProtectionIncluded" />
                  )}
                  <FieldCompare name="personalData.dataProtectionIncluded" />
                </FormGroup>
              </FormColumn>
              <FormColumn />
            </FormRow>
            {agreement.personalData.dataProtectionIncluded === false && (
              <>
                {metadata && (
                  <Attachments
                    attachments={attachments}
                    section="personalData"
                  />
                )}
              </>
            )}
            {/*  Must have at least one file uploaded*/}
          </>
        ) : null}
      </CardBody>
    </Card>
  );
};

PersonalDataSection.propTypes = {
  agreement: agreementPropType.isRequired,
  attachments: PropTypes.arrayOf(attachmentPropType),
  internalEntities: PropTypes.arrayOf(internalEntityPropType),
  metadata: metadataPropType,
  nonBlockingErrors: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      level: PropTypes.string,
      message: PropTypes.string,
    }),
  ),
};

PersonalDataSection.defaultProps = {
  metadata: null,
  attachments: [],
  internalEntities: [],
  nonBlockingErrors: [],
};
