import { AGREEMENT_SKELETON } from '../constants';

export const clearAgreementData = (original) => {
  const copy = { ...original };
  copy.details.term.endDateRequired = undefined;
  // cleanup details
  if (original.details.mainSupplier) {
    if (original.details.mainSupplier.isExternal) {
      copy.details.mainSupplier.internalSupplier = null;
    } else {
      copy.details.mainSupplier.externalSupplier = null;
    }
  }
  // cleanup notice periods
  if (original.details.term?.noticePeriodSupplier) {
    if (
      original.details.term.noticePeriodSupplier?.value === undefined ||
      original.details.term.noticePeriodSupplier?.unit === undefined
    ) {
      copy.details.term.noticePeriodSupplier = undefined;
    }
  }
  if (original.details.term?.noticePeriodInternalEntity) {
    if (
      original.details.term.noticePeriodInternalEntity?.value === undefined ||
      original.details.term.noticePeriodInternalEntity?.unit === undefined
    ) {
      copy.details.term.noticePeriodInternalEntity = undefined;
    }
  }
  // cleanup related arrangements
  if (!original.relatedAgreements.hasParent) {
    const { parentAgreement } = AGREEMENT_SKELETON.data.relatedAgreements;
    copy.relatedAgreements.parentAgreement = parentAgreement;
  }
  // cleanup personal data
  if (!original.personalData.isPersonalDataProcessed) {
    copy.personalData.role = null;
    copy.personalData.dataProtectionIncluded = null;
  }
  // cleanup service details
  if (!original.serviceDetails.isProvidedAsCloudService) {
    copy.serviceDetails.data = AGREEMENT_SKELETON.data.serviceDetails.data;
  }
  // cleanup npap
  if (!original.npap.npapRequired) {
    copy.npap.data.npapCompleted = null;
    copy.npap.data.npapDescription = '';
  }
  // cleanup agreement assessment
  if (!original.agreementAssessment.isOutsourcingArrangement) {
    copy.agreementAssessment.data.functionCategory = AGREEMENT_SKELETON.data.agreementAssessment.data.functionCategory;
    copy.agreementAssessment.data.assessmentCompleted = AGREEMENT_SKELETON.data.agreementAssessment.data.assessmentCompleted;
    copy.agreementAssessment.data.latestAssessment = AGREEMENT_SKELETON.data.agreementAssessment.data.latestAssessment;
    copy.agreementAssessment.data.summaryOfAssessment = AGREEMENT_SKELETON.data.agreementAssessment.data.summaryOfAssessment;
    copy.agreementAssessment.data.competentAuthorityNotified = AGREEMENT_SKELETON.data.agreementAssessment.data.competentAuthorityNotified;
    copy.agreementAssessment.data.competentAuthorityNotifiedDate = AGREEMENT_SKELETON.data.agreementAssessment.data.competentAuthorityNotifiedDate;
    copy.agreementAssessment.data.competentAuthorityNotifiedDescription = AGREEMENT_SKELETON.data.agreementAssessment.data.competentAuthorityNotifiedDescription;
    copy.agreementAssessment.data.isCriticalOrImportant = AGREEMENT_SKELETON.data.agreementAssessment.data.isCriticalOrImportant;

    copy.agreementAssessment.data.criticalOrImportant.nextAudit = AGREEMENT_SKELETON.data.agreementAssessment.data.nextAudit;
    copy.agreementAssessment.data.criticalOrImportant.latestAudit = AGREEMENT_SKELETON.data.agreementAssessment.data.latestAudit;
    copy.agreementAssessment.data.criticalOrImportant.exitStrategy = AGREEMENT_SKELETON.data.agreementAssessment.data.exitStrategy;
    copy.agreementAssessment.data.criticalOrImportant.finalApproval = AGREEMENT_SKELETON.data.agreementAssessment.data.finalApproval;
    copy.agreementAssessment.data.criticalOrImportant.timeCriticalOperation = AGREEMENT_SKELETON.data.agreementAssessment.data.timeCriticalOperation;
    copy.agreementAssessment.data.criticalOrImportant.alternativeServiceProviders = AGREEMENT_SKELETON.data.agreementAssessment.data.alternativeServiceProviders;
  }
  if (!original.agreementAssessment.data.isCriticalOrImportant) {
    copy.agreementAssessment.data.criticalOrImportant.nextAudit = AGREEMENT_SKELETON.data.agreementAssessment.data.nextAudit;
    copy.agreementAssessment.data.criticalOrImportant.latestAudit = AGREEMENT_SKELETON.data.agreementAssessment.data.latestAudit;
    copy.agreementAssessment.data.criticalOrImportant.exitStrategy = AGREEMENT_SKELETON.data.agreementAssessment.data.exitStrategy;
    copy.agreementAssessment.data.criticalOrImportant.finalApproval = AGREEMENT_SKELETON.data.agreementAssessment.data.finalApproval;
    copy.agreementAssessment.data.criticalOrImportant.timeCriticalOperation = AGREEMENT_SKELETON.data.agreementAssessment.data.timeCriticalOperation;
    copy.agreementAssessment.data.criticalOrImportant.alternativeServiceProviders = AGREEMENT_SKELETON.data.agreementAssessment.data.alternativeServiceProviders;
  }
  if (!original.agreementAssessment.data.competentAuthorityNotified) {
    copy.agreementAssessment.data.competentAuthorityNotifiedDescription = '';
  }
  if (copy.agreementAssessment.data.criticalOrImportant.riskAssessment === null) {
    delete copy.agreementAssessment.data.criticalOrImportant.riskAssessment;
  }
  return copy;
};
