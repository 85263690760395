/* eslint-disable react/prop-types */
import React from 'react';
import { useFormikContext } from 'formik';
import { Field, Label } from '@fcg-tech/regtech-components';
import {
  BooleanDecision,
  DateField,
} from '@fcg-tech/regtech-formik-components';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import { getTooltip } from '../../utils/tooltipHelpers';
import { Attachments } from '../Attachments';
import { FormRow, FormColumn, FormGroup } from '../FormLayout';

const AttachmentSections = {
  DueDiligence: 'dueDiligence',
  RiskAssessment: 'riskAssessment',
  CorporateSocialResponsibilitySurvey: 'corporateSocialResponsibilitySurvey',
  FollowCodeOfConduct: 'followCodeOfConduct',
  InformationSecurity: 'informationSecurity',
};

export const SupplierAssessmentSubForm = ({ attachments }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext();

  return <>
    <FormRow>
      <FormColumn>
        <FormGroup>
          <BooleanDecision.FieldContainer>
            <BooleanDecision.FieldLabel>
              {t(
                MessageKeys.SupplierAssessmentDueDilligenceConducted,
              )}
            </BooleanDecision.FieldLabel>
            <Field
              component={BooleanDecision}
              name="data.supplierAssessment.isDueDiligenceConducted"
              info={getTooltip('supplierAssessment.isDueDiligenceConducted')}
            />
          </BooleanDecision.FieldContainer>
        </FormGroup>
      </FormColumn>
      <FormColumn />
    </FormRow>
    {values.data.supplierAssessment.isDueDiligenceConducted && (
      <>
        <FormRow>
          <FormColumn>
            <FormGroup>
              <Label column htmlFor="latest-due-diligence">
                {t(
                  MessageKeys.SupplierAssessmentLatestDueDilligence,
                )}
              </Label>
              <Field
                component={DateField}
                name="data.supplierAssessment.latestDueDiligence"
                id="latest-due-diligence"
                info={getTooltip('supplierAssessment.latestDueDiligence')}
              />
            </FormGroup>
          </FormColumn>
          <FormColumn />
        </FormRow>
        <Attachments
          attachments={attachments}
          section={AttachmentSections.DueDiligence}
        />
      </>
    )}
    <FormRow>
      <FormColumn>
        <FormGroup>
          <BooleanDecision.FieldContainer>
            <BooleanDecision.FieldLabel>
              {t(
                MessageKeys.SupplierAssessmentRiskAssessmentCompleted,
              )}
            </BooleanDecision.FieldLabel>
            <Field
              component={BooleanDecision}
              name="data.supplierAssessment.isRiskAssessmentCompleted"
              info={getTooltip(
                'supplierAssessment.isRiskAssessmentCompleted',
              )}
            />
          </BooleanDecision.FieldContainer>
        </FormGroup>
      </FormColumn>
      <FormColumn />
    </FormRow>
    {values.data.supplierAssessment.isRiskAssessmentCompleted && (
      <>
        <FormRow>
          <FormColumn>
            <FormGroup>
              <Label column htmlFor="latest-risk-assessment">
                {t(
                  MessageKeys.SupplierAssessmentLatestRiskAssessment,
                )}
              </Label>
              <Field
                component={DateField}
                name="data.supplierAssessment.latestRiskAssessment"
                id="latest-risk-assessment"
                info={getTooltip('supplierAssessment.latestRiskAssessment')}
              />
            </FormGroup>
          </FormColumn>
          <FormColumn />
        </FormRow>
        <Attachments
          attachments={attachments}
          section={AttachmentSections.RiskAssessment}
        />
      </>
    )}
    <FormRow>
      <FormColumn>
        <FormGroup>
          <Label column>
            {t(MessageKeys.SupplierAssessmentCSRSurveyRequired)}
          </Label>
          <BooleanDecision.FieldContainer>
            <BooleanDecision.FieldLabel>
              {t(MessageKeys.LabelRequired)}
            </BooleanDecision.FieldLabel>
            <Field
              component={BooleanDecision}
              name="data.supplierAssessment.isCSRSurveyRequired"
              info={getTooltip('supplierAssessment.isCSRSurveyRequired')}
            />
          </BooleanDecision.FieldContainer>
        </FormGroup>
      </FormColumn>
      <FormColumn />
    </FormRow>
    {values.data.supplierAssessment.isCSRSurveyRequired && (
      <>
        <FormRow>
          <FormColumn>
            <FormGroup>
              <BooleanDecision.FieldContainer>
                <BooleanDecision.FieldLabel>
                  {t(MessageKeys.LabelCompleted)}
                </BooleanDecision.FieldLabel>
                <Field
                  component={BooleanDecision}
                  name="data.supplierAssessment.isCSRSurveyCompleted"
                  info={getTooltip('supplierAssessment.isCSRSurveyCompleted')}
                />
              </BooleanDecision.FieldContainer>
            </FormGroup>
          </FormColumn>
          <FormColumn />
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormGroup>
              <Label column htmlFor="latest-csr-survey">
                {t(MessageKeys.SupplierAssessmentLatestCSRSurvey)}
              </Label>
              <Field
                component={DateField}
                name="data.supplierAssessment.latestCSRSurvey"
                id="latest-csr-survey"
                info={getTooltip('supplierAssessment.latestCSRSurvey')}
              />
            </FormGroup>
          </FormColumn>
          <FormColumn />
        </FormRow>
        <Attachments
          attachments={attachments}
          section={AttachmentSections.CorporateSocialResponsibilitySurvey}
        />
      </>
    )}
    <FormRow>
      <FormColumn>
        <FormGroup>
          <Label column>
            {t(
              MessageKeys.SupplierAssessmentFollowCodeOfConductRequired,
            )}
          </Label>
          <BooleanDecision.FieldContainer>
            <BooleanDecision.FieldLabel>
              {t(MessageKeys.LabelRequired)}
            </BooleanDecision.FieldLabel>
            <Field
              component={BooleanDecision}
              name="data.supplierAssessment.isFollowCodeOfConductRequired"
              info={getTooltip(
                'supplierAssessment.isFollowCodeOfConductRequired',
              )}
            />
          </BooleanDecision.FieldContainer>
        </FormGroup>
      </FormColumn>
      <FormColumn />
    </FormRow>
    {values.data.supplierAssessment.isFollowCodeOfConductRequired && (
      <>
        <FormRow>
          <FormColumn>
            <FormGroup>
              <BooleanDecision.FieldContainer>
                <BooleanDecision.FieldLabel>
                  {t(MessageKeys.LabelConfirmed)}
                </BooleanDecision.FieldLabel>
                <Field
                  component={BooleanDecision}
                  name="data.supplierAssessment.isFollowCodeOfConductConfirmed"
                  info={getTooltip(
                    'supplierAssessment.isFollowCodeOfConductConfirmed',
                  )}
                />
              </BooleanDecision.FieldContainer>
            </FormGroup>
          </FormColumn>
          <FormColumn />
        </FormRow>
        <Attachments
          attachments={attachments}
          section={AttachmentSections.FollowCodeOfConduct}
        />
      </>
    )}
    <FormRow>
      <FormColumn>
        <FormGroup>
          <Label column>
            {t(
              MessageKeys.SupplierAssessmentInformationSecurityRequired,
            )}
          </Label>
          <BooleanDecision.FieldContainer>
            <BooleanDecision.FieldLabel>
              {t(MessageKeys.LabelRequired)}
            </BooleanDecision.FieldLabel>
            <Field
              component={BooleanDecision}
              name="data.supplierAssessment.isInformationSecurityRequired"
              info={getTooltip(
                'supplierAssessment.isInformationSecurityRequired',
              )}
            />
          </BooleanDecision.FieldContainer>
        </FormGroup>
      </FormColumn>
      <FormColumn />
    </FormRow>
    {values.data.supplierAssessment.isInformationSecurityRequired && (
      <>
        <FormRow>
          <FormColumn>
            <FormGroup>
              <BooleanDecision.FieldContainer>
                <BooleanDecision.FieldLabel>
                  {t(MessageKeys.LabelCompleted)}
                </BooleanDecision.FieldLabel>
                <Field
                  component={BooleanDecision}
                  name="data.supplierAssessment.isInformationSecurityCompleted"
                  info={getTooltip(
                    'supplierAssessment.isInformationSecurityCompleted',
                  )}
                />
              </BooleanDecision.FieldContainer>
            </FormGroup>
          </FormColumn>
          <FormColumn />
        </FormRow>
        <Attachments
          attachments={attachments}
          section={AttachmentSections.InformationSecurity}
        />
      </>
    )}
  </>;
};

SupplierAssessmentSubForm.defaultProps = {
  attachments: [],
};
