import { DatepickerTheme } from '@fcg-tech/regtech-types';
import { css } from 'styled-components';

export const theme: Required<DatepickerTheme> = {
  datepicker: {
    container: css`
      display: flex;
      flex-direction: column;
    `,
  },
  day: {
    container: css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      cursor: pointer;
      border: 0;
      font-size: 1.2rem;

      &:hover {
        background-color: #eee;
      }
    `,
    disabled: css`
      opacity: 0.4;
      cursor: not-allowed;
    `,
    current: css`
      background-color: transparent;
      box-shadow: inset 0 0 1px 2px
        ${({ theme }) => theme.palette?.MainColor?.dark ?? '#000'};
      border-radius: 50%;
    `,
    outOfContext: css`
      opacity: 0.4;
      background-color: #ccc;
    `,
    isSelected: css`
      background-color: ${({ theme }) =>
        theme.palette?.MainColor?.main ?? '#000'}!important;
      border-radius: 50%;
      color: ${({ theme }) => theme.palette?.MainColor?.mainContrast ?? '#000'};
    `,
  },

  week: {
    container: css``,
    weekNr: css`
      width: 30px;
      background-color: #3b3b3b;
      color: white;
    `,
  },
  month: { container: css`` },
  header: {
    topContainer: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #eee;
    `,
    changeMonthButton: css`
      border: 0;
      padding: 3px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      background: transparent;
    `,
    monthName: css`
      font-size: 16px;
      font-weight: 500;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    `,
    dayContainer: css`
      display: flex;
      padding-top: 0.6rem;
      background-color: #eee;
      border-bottom: 1px solid #ccc;
    `,

    day: css`
      font-size: 12px;
      font-weight: 600;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 2px;
    `,
  },
};
