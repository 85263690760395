import { LegacyPalette, LegacyTheme } from '@fcg-tech/regtech-types';
import { css } from 'styled-components';
import { Adaptation } from './foundation';

export type HoistAdaptationColorKey =
  | 'hoistEmerald300'
  | 'hoistEmerald500'
  | 'hoistGold100'
  | 'hoistSilver100'
  | 'hoistSilver200'
  | 'hoistSilver300'
  | 'hoistSilver500'
  | 'hoistTopaz200'
  | 'hoistTopaz300'
  | 'hoistTopaz500'
  | 'hoistRuby300'
  | 'hoistRuby500';

export const adaptation: Adaptation<HoistAdaptationColorKey> = {
  palette: {
    hoistEmerald300: { hex: '#A1CB9A', contrastHex: '#FFFFFF' },
    hoistEmerald500: { hex: '#538E49', contrastHex: '#131313' },
    hoistGold100: { hex: '#F4F4F2', contrastHex: '#131313' },
    hoistSilver100: { hex: '#FFFFFF', contrastHex: '#131313' },
    hoistSilver200: { hex: '#BFBFBF', contrastHex: '#131313' },
    hoistSilver300: { hex: '#7D7D7D', contrastHex: '#FFFFFF' },
    hoistSilver500: { hex: '#2B333C', contrastHex: '#FFFFFF' },
    hoistTopaz200: { hex: '#95B8E4', contrastHex: '#131313' },
    hoistTopaz300: { hex: '#6897F4', contrastHex: '#FFFFFF' },
    hoistTopaz500: { hex: '#3272C7', contrastHex: '#FFFFFF' },
    hoistRuby300: { hex: '#C77AA9', contrastHex: '#131313' },
    hoistRuby500: { hex: '#A43579', contrastHex: '#FFFFFF' },
  },
};

const buttonStyle = css`
  border-radius: 0.3rem;
`;

export const palette: LegacyPalette = {
  MainColor: {
    main: '#b2017b',
    mainContrast: '#fff',
    dark: '#813769',
    darkContrast: '#fff',
    light: '#fc83d7',
    lightContrast: '#000',
  },
  MainColorAccent: {
    light: '#a6a6a6',
    lightContrast: '#000',
    main: '#505050',
    mainContrast: '#fff',
    dark: '#3b3b3b',
    darkContrast: '#fff',
  },
  MainBlack: '#000',
  MainWhite: '#FFF',
};

export const theme: LegacyTheme = {
  version: '1',
  breakpoints: {
    mobile: 730,
    narrowDesktop: 1024,
    mediumDesktop: 1240,
  },

  colors: {
    backdrop: '#F9F9F9',
    info: {
      main: palette.MainColor.main,
      contrast: palette.MainColor.mainContrast,
    },
    error: {
      main: '#DC3545',
      contrast: palette.MainWhite,
    },
    warning: {
      main: '#FFC109',
      contrast: palette.MainBlack,
    },
    success: {
      main: '#28A745',
      contrast: palette.MainWhite,
    },
    primary: {
      main: palette.MainColor.main,
      contrast: palette.MainBlack,
    },
    secondary: {
      main: palette.MainColor.main,
      contrast: palette.MainBlack,
    },
  },

  typography: {
    fontFamily: 'Roboto',
  },

  palette,

  elements: {
    header: {
      wrapper: css`
        height: 7rem;
        border-bottom-width: 1px;
      `,
    },
    sideBar: {
      wrapper: css`
        background: #e8e8e8;
        border-right: 1px solid rgba(0, 0, 0, 0.3);
      `,
    },
    sideNavigation: {
      item: {
        link: {
          nominal: css`
            color: ${palette.MainColorAccent.dark};
          `,
          active: css`
            color: ${palette.MainBlack};
          `,
          focus: css`
            color: ${palette.MainBlack};
          `,
          hover: css`
            color: ${palette.MainBlack};
          `,
          visited: css`
            color: ${palette.MainBlack};
          `,
        },
        button: {
          nominal: css`
            color: ${palette.MainColorAccent.dark};
          `,
          active: css`
            color: ${palette.MainColorAccent.dark};
          `,
          focus: css`
            color: ${palette.MainColorAccent.dark};
          `,
          hover: css`
            color: ${palette.MainColorAccent.dark};
          `,
        },
      },
      sectionHeading: css`
        color: ${palette.MainBlack};
      `,
      expandButton: {
        icon: css`
          color: ${palette.MainBlack};
        `,
      },
    },
    button: {
      primary: buttonStyle,
      secondary: buttonStyle,
      icon: css`
        color: ${palette.MainColor.lightContrast};

        &:hover,
        &:focus {
          color: ${palette.MainColor.lightContrast};
          background-color: ${palette.MainColor.light};
        }

        &:active {
          color: ${palette.MainColor.lightContrast};
          background-color: ${palette.MainColor.light};
          border-color: ${palette.MainColor.dark};
        }
      `,
    },
    card: {
      wrapper: css`
        background-color: ${palette.MainWhite};
      `,
    },
    chart: {
      palette: {
        alpha: [
          adaptation.palette.hoistRuby500,
          adaptation.palette.hoistTopaz500,
          adaptation.palette.hoistSilver300,
          adaptation.palette.hoistTopaz200,
          adaptation.palette.hoistSilver500,
        ],
        beta: [
          adaptation.palette.hoistSilver500,
          adaptation.palette.hoistRuby500,
          adaptation.palette.hoistSilver300,
          adaptation.palette.hoistRuby300,
          adaptation.palette.hoistSilver200,
        ],
      },
    },
  },
};
