import React, { FunctionComponent, useCallback, PropsWithChildren } from 'react';
import { CalendarEventData, CalendarEventType } from '../../types/calendar';
import {
  CalendarAgendaEventContent,
  CalendarAgendaEventWrapper,
  CalendarAgendEventPip,
} from './Calendar.styles';

interface CalendarAgendaEventProps {
  event: CalendarEventData;
  eventType?: CalendarEventType;
  onClick?: (eventId: string | number, eventKey: string | number) => void;
}

export const CalendarAgendaEvent: FunctionComponent<PropsWithChildren<CalendarAgendaEventProps>> =
  ({ event, eventType, children, onClick }) => {
    const eventId = event.id;
    const eventKey = event?.key ?? '';
    const handleClick = useCallback(
      () => onClick?.(eventId, eventKey),
      [eventId, eventKey, onClick],
    );

    return (
      <CalendarAgendaEventWrapper role="button" onClick={handleClick}>
        {children ?? (
          <>
            <CalendarAgendEventPip
              backgroundColor={eventType?.backgroundColor}
              color={eventType?.color}
            />
            <CalendarAgendaEventContent>
              {event.label}
            </CalendarAgendaEventContent>
          </>
        )}
      </CalendarAgendaEventWrapper>
    );
  };
