import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Label, Select } from '@fcg-tech/regtech-components';

import { FormRow, FormColumn, FormGroup } from '../../../components/FormLayout';
import { FormModal } from '../../../components/FormModal';

const Content = styled.div`
  align-items: center;
  width: 100%;
  min-width: 68rem;
`;

export const AddGroupUserModal = ({ users, groupUsers, onSave, onCancel }) => {
  const [username, setUsername] = useState('');

  const userItems = useMemo(
    () =>
      users
        .filter((user) => !groupUsers.includes(user.username))
        .filter((user) => Boolean(user.username))
        .map((user) => ({
          key: user.username,
          label: user.username,
        })),
    [users, groupUsers],
  );

  const handleCreate = useCallback(
    (value) => onSave({ username: value }),
    [onSave],
  );

  const handleChange = useCallback((newUsername) => {
    setUsername(newUsername);
  }, []);

  return (
    <FormModal
      title="Add user"
      initialValues={{ username }}
      onSubmit={onSave}
      onCancel={onCancel}
    >
      {() => (
        <Content>
          <FormRow>
            <FormColumn>
              <FormGroup>
                <Label column htmlFor="group-user-username">
                  Add new or select existing user
                </Label>
                <Select
                  id="group-user-username"
                  value={username}
                  items={userItems}
                  isEditEnabled
                  typeahead
                  currentValuePlaceholder="Press enter to add user:"
                  typeaheadPlaceholder="Type to select existing or add new user:"
                  createOptionText="Press enter to add user:"
                  onCreate={handleCreate}
                  onChange={handleChange}
                />
              </FormGroup>
            </FormColumn>
          </FormRow>
        </Content>
      )}
    </FormModal>
  );
};

AddGroupUserModal.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({})),
  groupUsers: PropTypes.arrayOf(PropTypes.string),
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

AddGroupUserModal.defaultProps = {
  users: [],
  groupUsers: [],
  onSave: null,
  onCancel: null,
};
