import React from 'react';
import PropTypes from 'prop-types';

import { formikFormShape, formikFieldShape } from '../../propTypes';
import { TagPicker } from './TagPicker';

export const TagPickerFormik = ({
  field = {},
  form,
  onSelectItem,
  ...props
}) => {
  const handleSelectItem = React.useCallback(
    (id) => {
      if (field.value?.includes(id)) {
        const index = field.value.findIndex((item) => item === id);
        form.setFieldValue(field.name, [
          ...field.value.slice(0, index),
          ...field.value.slice(index + 1),
        ]);
      } else {
        form.setFieldValue(field.name, [...field.value, id]);
      }
    },
    [field, form],
  );

  const value = React.useMemo(() => field.value || [], [field]);

  return (
    <TagPicker
      {...props}
      value={value}
      onSelectItem={onSelectItem || handleSelectItem}
    />
  );
};

TagPickerFormik.propTypes = {
  field: formikFieldShape.isRequired,
  form: formikFormShape.isRequired,
  onSelectItem: PropTypes.func,
};

TagPickerFormik.defaultProps = {
  onSelectItem: null,
};
