import { constructUrl } from '@fcg-tech/regtech-api-utils';
import {
  ArchiveIcon,
  DownloadLocalIcon,
  NotArchiveIcon,
  PrimaryButton,
  TooltipNext as Tooltip,
  usePagination,
  useToggle,
} from '@fcg-tech/regtech-components';
import {
  FilterBarButton,
  FilterBarIconButton,
  FilterCheckbox,
  FilterDateRangeInterval,
  FilterSelect,
  FilterSelectOption,
  FilterTextField,
} from '@fcg-tech/regtech-filter';
import { downloadDataUrl, unique } from '@fcg-tech/regtech-utils';
import { FunctionComponent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTags } from '../../api/hooks/tagsApiHooks';
import { AccessControl } from '../../components/AccessControl';
import {
  AgreementTable,
  AgreementTableAndFilterWrapper,
} from '../../components/AgreementTable';
import { useAgreementTableColumnOptions } from '../../components/AgreementTable/agreementTableHelpers';
import { ErrorMessage } from '../../components/ErrorBoundary';
import {
  AgreementFilter,
  AgreementFilterArchivedStatusSelect,
  AgreementFilterBarColumnSelector,
  FilterBorderWrapper,
  StyledFilterBar,
} from '../../components/Filter';
import { MainLayoutPaddedContentWrapper } from '../../components/MainLayout';
import { CsvExportModal, SearchHelpModal } from '../../components/modals';
import { PaginatedTableHeader } from '../../components/PaginatedTableHeader';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import { routes } from '../../routes';
import { useTenant } from '../../states/tenantState';
import { MessageKeys } from '../../translations/messageKeys';
import {
  AgreementTableType,
  ArchivedOptions,
  AvailableAgreementFilters,
  BooleanFilterEnum,
  GlobalActions,
  StoredAgreementFilter,
} from '../../types';
import { createAgreementTableExport } from '../../utils/csvUtils';
import { dateIntervals } from '../../utils/filterHelpers';
import { useAgreementFilter } from '../../utils/filterHooks';
import { formatStatus } from '../../utils/formatters';
import { usePaginatedAgreements } from '../../utils/paginationHooks';
import { AgreementsOverviewCreateModal } from './AgreementsOverviewCreateModal';

export const AgreementsOverviewTable: FunctionComponent = () => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const navigate = useNavigate();
  const [page, setPage] = usePagination(1);
  const [showFilter, , toggleShowFilter] = useToggle(false);
  const [showExportModal, , toggleExportModal] = useToggle();
  const [showCreateModal, , toggleCreateModal] = useToggle();
  const [showSearchHelpModal, , toggleSearchHelpModal] = useToggle();

  const { data: tags } = useTags(tenant.id, { suspense: false });

  const [columns, handleColumnsChange] = useAgreementTableColumnOptions(
    AgreementTableType.Overview,
  );

  const {
    filter,
    filterId,
    storedFilters,
    pinnedFilters,
    sortBy,
    handleFilterChange,
    handleFilterClear,
    handleFilterDeleted,
    handleFilterPinned,
    handleFilterSaved,
    handleFilterValueChange,
    handleFilterValueClear,
    handleFilterValueExclude,
    handleStoredFilterSelected,
    handleSortByChange,
  } = useAgreementFilter();

  const { data, paginatedData, isValidating, error } = usePaginatedAgreements(
    {
      filter,
      query: {
        archived: filter.archived,
      },
    },
    page,
    DEFAULT_PAGE_SIZE,
  );
  const isLoading = !data && isValidating;
  const pagination = paginatedData?.pagination;

  const agreementFilter = useMemo<AvailableAgreementFilters>(() => {
    return {
      agreementTypes: unique(data?.map((item) => item.agreementType)),
      functionCategories: unique(data?.map((item) => item.functionCategory)),
      contractOwners: unique(
        data?.map((item) => item.contractOwner?.toLowerCase()),
      ),
      supplierNames: unique(data?.map((item) => item.supplierName)),
      partyToAgreement: unique(data?.map((item) => item.partyToAgreement)),
      cabinets: unique(data?.map((item) => item.cabinet)),
      status: unique(
        data?.map((item) => (formatStatus(item.status) ? item.status : '')),
      ),
    };
  }, [data]);

  const [
    agreementTypeOptions,
    functionCategoryOptions,
    contractOwnerOptions,
    supplierNameOptions,
    partyToAgreementOptions,
    cabinetOptions,
    statusOptions,
    tagOptions,
  ] = useMemo<Array<Array<FilterSelectOption>>>(() => {
    return [
      agreementFilter?.agreementTypes
        ?.map((a) => ({
          value: { id: a },
          label: a,
        }))
        ?.sort((a, b) => a.label.localeCompare(b.label)),
      agreementFilter?.functionCategories
        ?.map((a) => ({
          value: { id: a },
          label: a,
        }))
        ?.sort((a, b) => a.label.localeCompare(b.label)),
      agreementFilter?.contractOwners
        ?.map((a) => ({
          value: { id: a },
          label: a,
        }))
        ?.sort((a, b) => a.label.localeCompare(b.label)),
      agreementFilter?.supplierNames
        ?.map((a) => ({
          value: { id: a },
          label: a,
        }))
        ?.sort((a, b) => a.label.localeCompare(b.label)),
      agreementFilter?.partyToAgreement
        ?.map((a) => ({
          value: { id: a },
          label: a,
        }))
        ?.sort((a, b) => a.label.localeCompare(b.label)),
      agreementFilter?.cabinets
        ?.map((a) => ({
          value: { id: a },
          label: a,
        }))
        ?.sort((a, b) => a.label.localeCompare(b.label)),
      agreementFilter?.status
        ?.map((a) => ({
          value: { id: a },
          label: formatStatus(a),
        }))
        ?.sort((a, b) => a.label.localeCompare(b.label)),
      tags
        ?.map<FilterSelectOption>((a) => ({
          value: { id: a.name },
          label: a.name,
        }))
        ?.sort((a, b) => a.label.localeCompare(b.label)),
    ];
  }, [
    agreementFilter?.agreementTypes,
    agreementFilter?.cabinets,
    agreementFilter?.contractOwners,
    agreementFilter?.functionCategories,
    agreementFilter?.partyToAgreement,
    agreementFilter?.status,
    agreementFilter?.supplierNames,
    tags,
  ]);

  const [
    competentAuthorityNotifiedOptions,
    isOutsourcingArrangementOptions,
    isCriticalOptions,
    isPersonalDataTransferredOptions,
    isPersonalDataProcessedOptions,
    isProvidedAsCloudServiceOptions,
  ] = useMemo<Array<Array<FilterSelectOption>>>(() => {
    const booleanDecisions: Array<FilterSelectOption> = [
      {
        value: { id: BooleanFilterEnum.Yes },
        label: t(MessageKeys.LabelYes),
      },
      {
        value: { id: BooleanFilterEnum.No },
        label: t(MessageKeys.LabelNo),
      },
      {
        value: { id: BooleanFilterEnum.NotSet },
        label: 'Not set',
      },
    ];
    return Array.from(Array(6), () => [...booleanDecisions]);
  }, [t]);

  const includeArchived = useMemo(() => {
    return filter.archived === ArchivedOptions.All;
  }, [filter.archived]);

  const handleToggleIncludeArchived = useCallback(() => {
    handleFilterValueChange(
      'archived',
      filter.archived === ArchivedOptions.All
        ? ArchivedOptions.NotArchived
        : ArchivedOptions.All,
      filterId,
    );
  }, [filter.archived, filterId, handleFilterValueChange]);

  const handleCreateModalRequestClose = useCallback(
    (agreementId?: string) => {
      toggleCreateModal();
      if (agreementId) {
        navigate(
          constructUrl(routes.editAgreement, {
            tenantId: tenant.id,
            agreementId,
          }),
        );
      }
    },
    [navigate, tenant.id, toggleCreateModal],
  );

  const handleExport = useCallback(
    (filename: string) => {
      const result = createAgreementTableExport(columns, data);
      downloadDataUrl(filename, result.data, result.contentType);
    },
    [columns, data],
  );

  const handlePageChange = useCallback(
    (pageNumber: number) => {
      if (pagination) {
        const totalPages = Math.ceil(
          pagination.totalResults / pagination.limit,
        );
        if (pageNumber <= totalPages && pageNumber >= 1) {
          setPage(pageNumber);
          return true;
        }
      }
      return false;
    },
    [pagination, setPage],
  );

  const handleRowClick = useCallback(
    (agreementId: string) => {
      navigate(
        constructUrl(routes.agreement, { tenantId: tenant.id, agreementId }),
      );
    },
    [navigate, tenant.id],
  );

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <>
      {showExportModal ? (
        <CsvExportModal
          filename="agreements_overview"
          onExport={handleExport}
          onRequestClose={toggleExportModal}
        />
      ) : null}
      {showCreateModal ? (
        <AgreementsOverviewCreateModal
          onRequestClose={handleCreateModalRequestClose}
        />
      ) : null}
      {showSearchHelpModal ? (
        <SearchHelpModal
          exampleLabel={t(MessageKeys.SearchHelpModalExample1Label)}
          exampleDescription={t(MessageKeys.SearchHelpModalExample1Description)}
          onHide={toggleSearchHelpModal}
        />
      ) : null}
      <MainLayoutPaddedContentWrapper>
        <PaginatedTableHeader
          currentPage={page}
          totalPages={Math.ceil(
            pagination?.totalResults / pagination?.limit ?? 1,
          )}
          totalResults={pagination?.totalResults}
          disabled={isLoading}
          onPageClick={handlePageChange}
          heading={t(MessageKeys.AgreementsOverviewHeading)}
        >
          <AccessControl requiredPermissions={GlobalActions.AgreementAdd}>
            <PrimaryButton onClick={toggleCreateModal}>
              {t(MessageKeys.AgreementsOverviewCreateNewAgreementLabel)}
            </PrimaryButton>
          </AccessControl>
        </PaginatedTableHeader>
      </MainLayoutPaddedContentWrapper>
      <StyledFilterBar
        filters={pinnedFilters}
        currentFilterId={filterId}
        onPinnedFilterClick={handleStoredFilterSelected}
      >
        <Tooltip
          content={
            includeArchived
              ? t(MessageKeys.AgreementFilterExcludeArchivedTooltip)
              : t(MessageKeys.AgreementFilterIncludeArchivedTooltip)
          }
          showDelay={500}
          placement="bottom-end"
        >
          <FilterBarButton
            toggled={includeArchived}
            onClick={handleToggleIncludeArchived}
          >
            {includeArchived ? (
              <ArchiveIcon size="18" />
            ) : (
              <NotArchiveIcon size="18" />
            )}
          </FilterBarButton>
        </Tooltip>
        <Tooltip
          content={t(MessageKeys.LabelDownloadCSV)}
          showDelay={500}
          placement="bottom-end"
        >
          <FilterBarButton id="export-button" onClick={toggleExportModal}>
            <DownloadLocalIcon size="18" />
          </FilterBarButton>
        </Tooltip>
        <AgreementFilterBarColumnSelector
          tableType={AgreementTableType.Overview}
        />
        <FilterBarIconButton
          disabled={isLoading}
          toggled={showFilter}
          onClick={toggleShowFilter}
        />
      </StyledFilterBar>
      <AgreementTableAndFilterWrapper>
        <AgreementTable
          agreements={paginatedData?.items}
          columns={columns}
          loading={isLoading}
          sortBy={sortBy}
          onRowClick={handleRowClick}
          onColumnsChange={handleColumnsChange}
          onSortByChange={handleSortByChange}
        />
        {showFilter ? (
          <FilterBorderWrapper>
            <AgreementFilter
              filter={filter}
              filterId={filterId}
              storedFilters={storedFilters as Array<StoredAgreementFilter>}
              clearLabel={t(MessageKeys.LabelClear)}
              excludeLabel={t(MessageKeys.LabelExclude)}
              noItemsLabel={t(MessageKeys.LabelNoItems)}
              onDeleteFilter={handleFilterDeleted}
              onSaveFilter={handleFilterSaved}
              onPinFilter={handleFilterPinned}
              onFilterChange={handleFilterChange}
              onFilterClear={handleFilterClear}
              onFilterValueChange={handleFilterValueChange}
              onFilterValueClear={handleFilterValueClear}
              onFilterValueExclude={handleFilterValueExclude}
              onFilterLoad={handleStoredFilterSelected}
            >
              <FilterTextField
                filterPropKey="name"
                label={t(MessageKeys.AgreementTableNameLabel)}
                onHelpClick={toggleSearchHelpModal}
              />
              <FilterSelect
                filterPropKey="agreementType"
                label={t(MessageKeys.AgreementTableAgreementTypeLabel)}
                options={agreementTypeOptions}
                allowMultipleSelect
              />
              <FilterSelect
                filterPropKey="functionCategory"
                label={t(MessageKeys.AgreementTableFunctionCategoryLabel)}
                options={functionCategoryOptions}
                allowMultipleSelect
              />
              <FilterSelect
                filterPropKey="contractOwner"
                label={t(MessageKeys.AgreementTableContractOwnerLabel)}
                options={contractOwnerOptions}
                allowMultipleSelect
              />
              <FilterSelect
                filterPropKey="supplierName"
                label={t(MessageKeys.AgreementTableSupplierNameLabel)}
                options={supplierNameOptions}
                allowMultipleSelect
              />
              <FilterSelect
                filterPropKey="partyToAgreement"
                label={t(MessageKeys.AgreementTablePartyToAgreementLabel)}
                options={partyToAgreementOptions}
                allowMultipleSelect
              />
              <FilterSelect
                filterPropKey="cabinet"
                label={t(MessageKeys.AgreementTableCabinetLabel)}
                options={cabinetOptions}
                allowMultipleSelect
              />
              <FilterSelect
                filterPropKey="status"
                label={t(MessageKeys.AgreementTableStatusLabel)}
                options={statusOptions}
                allowMultipleSelect
              />
              <FilterDateRangeInterval
                filterPropKey="startDate"
                label={t(MessageKeys.AgreementTableStartDateLabel)}
                intervals={dateIntervals}
              />
              <FilterDateRangeInterval
                filterPropKey="endDate"
                label={t(MessageKeys.AgreementTableEndDateLabel)}
                intervals={dateIntervals}
              />
              <FilterDateRangeInterval
                filterPropKey="latestReview"
                label={t(MessageKeys.AgreementTableLatestReviewLabel)}
                intervals={dateIntervals}
              />
              <FilterDateRangeInterval
                filterPropKey="nextReview"
                label={t(MessageKeys.AgreementTableNextReviewLabel)}
                intervals={dateIntervals}
              />
              <FilterSelect
                filterPropKey="competentAuthorityNotified"
                label={t(
                  MessageKeys.AgreementTableCompetentAuthorityNotifiedLabel,
                )}
                options={competentAuthorityNotifiedOptions}
                allowMultipleSelect
              />
              <FilterSelect
                filterPropKey="isOutsourcingArrangement"
                label={t(
                  MessageKeys.AgreementTableIsOutsourcingArrangementLabel,
                )}
                options={isOutsourcingArrangementOptions}
                allowMultipleSelect
              />
              <FilterSelect
                filterPropKey="isCritical"
                label={t(MessageKeys.AgreementTableIsCriticalLabel)}
                options={isCriticalOptions}
                allowMultipleSelect
              />
              <FilterCheckbox
                filterPropKey="nrOfComments"
                label={t(MessageKeys.AgreementTableShowCommentsLabel)}
              />
              <FilterSelect
                filterPropKey="isPersonalDataTransferred"
                label={t(
                  MessageKeys.AgreementTableIsPersonalDataTransferredLabel,
                )}
                options={isPersonalDataTransferredOptions}
                allowMultipleSelect
              />
              <FilterSelect
                filterPropKey="isPersonalDataProcessed"
                label={t(
                  MessageKeys.AgreementTableIsPersonalDataProcessedLabel,
                )}
                options={isPersonalDataProcessedOptions}
                allowMultipleSelect
              />
              <FilterSelect
                filterPropKey="isProvidedAsCloudService"
                label={t(
                  MessageKeys.AgreementTableIsProvidedAsCloudServiceLabel,
                )}
                options={isProvidedAsCloudServiceOptions}
                allowMultipleSelect
              />
              <FilterSelect
                filterPropKey="tags"
                label={t(MessageKeys.AgreementTableTagsLabel)}
                options={tagOptions}
                allowMultipleSelect
              />
              <AgreementFilterArchivedStatusSelect
                label="Archived status"
                defaultArchivedOption={ArchivedOptions.NotArchived}
              />
            </AgreementFilter>
          </FilterBorderWrapper>
        ) : null}
      </AgreementTableAndFilterWrapper>
    </>
  );
};
