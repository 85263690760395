import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  Card,
  CardBody,
  CardTitle,
  Field,
  useEditContext,
} from '@fcg-tech/regtech-components';
import { BooleanDecision } from '@fcg-tech/regtech-formik-components';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../../translations/messageKeys';
import { agreementPropType } from '../../../propTypes';
import { getTooltip } from '../../../utils/tooltipHelpers';
import { FormRow, FormColumn, FormGroup } from '../../FormLayout';
import { RelatedAgreementsTable } from './components/RelatedAgreementsTable';
import { FieldCompare } from '../../FieldCompare/FieldCompare';
import { ParentAgreementField } from '../../ParentAgreementField';

const StyledFormRow = styled(FormRow)`
  margin-bottom: 0;
`;

const TableContainer = styled.div`
  ${({ contracted }) => css`
    position: relative;
    margin: ${!contracted ? '2.4rem 0' : '0'};
    padding: 0 1rem;
  `}
`;

export const RelatedAgreementsSection = ({
  tenantId,
  agreement,
  relations,
  agreements,
}) => {
  const isEditEnabled = useEditContext();
  const { t } = useTranslation();

  return (
    <Card>
      <CardBody>
        <CardTitle>
          {t(MessageKeys.RelatedAgreementsSectionLabel)}
        </CardTitle>
        <StyledFormRow>
          <FormColumn>
            <FormGroup>
              <BooleanDecision.FieldContainer>
                <BooleanDecision.FieldLabel>
                  {t(
                    MessageKeys.RelatedAgreementsSectionParentAgreementLabel,
                  )}
                </BooleanDecision.FieldLabel>
                <Field
                  component={BooleanDecision}
                  name="relatedAgreements.hasParent"
                  info={getTooltip('relatedAgreements.parentAgreement')}
                />
              </BooleanDecision.FieldContainer>
              <FieldCompare name="relatedAgreements.hasParent" />
              {isEditEnabled && agreement.relatedAgreements?.hasParent && (
                <>
                  <br />
                  <Field
                    component={ParentAgreementField}
                    name="relatedAgreements.parentAgreement"
                    id="relatedAgreements-parent-agreement"
                    agreements={agreements}
                  />
                </>
              )}
            </FormGroup>
          </FormColumn>
          <FormColumn />
        </StyledFormRow>
        <TableContainer contracted>
          {!isEditEnabled &&
            agreement.relatedAgreements?.hasParent &&
            agreement.relatedAgreements?.parentAgreement && (
              <RelatedAgreementsTable
                tenantId={tenantId}
                relationshipType={t(
                  MessageKeys.RelatedAgreementsSectionParentLabel,
                )}
                relations={[agreement?.relatedAgreements?.parentAgreement]}
              />
            )}
          <FieldCompare name="relatedAgreements.parentAgreement.name" />
        </TableContainer>
        <TableContainer>
          <RelatedAgreementsTable
            title={t(
              MessageKeys.RelatedAgreementsSectionOtherRelationshipsLabel,
            )}
            relations={relations?.children}
          />
        </TableContainer>
      </CardBody>
    </Card>
  );
};

RelatedAgreementsSection.propTypes = {
  agreement: agreementPropType,
  relations: PropTypes.shape({
    children: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  agreements: PropTypes.arrayOf(agreementPropType),
};

RelatedAgreementsSection.defaultProps = {
  agreement: {},
  relations: {
    children: [],
  },
  agreements: [],
};
