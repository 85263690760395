import { classNames } from '@fcg-tech/regtech-utils';
import React, { FunctionComponent, useCallback, useEffect, useState, PropsWithChildren } from 'react';
import { NavLink, matchPath, useLocation } from 'react-router-dom';
import { useSideBar } from '../SideBar';
import {
  SideNavigationExpandButton,
  SideNavigationExpandButtonChevronIcon,
} from './SideNavigationExpandButton';
import {
  SideNavigationMenuItemList,
  SideNavigationMenuTitle,
  SideNavigationMenuWrapper,
} from './SideNavigationMenu.styles';

export interface SideNavigationProps {
  heading?: string;
  href?: string;
  collapsible?: boolean;
  /**
   * If true, and if collapsible = true, this menu is implicitly collapsible and the behavior is controlled by its parent
   */
  controlled?: boolean;
  outlined?: boolean;
  className?: string;
}

export const SideNavigationMenu: FunctionComponent<PropsWithChildren<SideNavigationProps>> = ({
  collapsible,
  controlled,
  heading,
  href,
  outlined,
  children,
  className,
}) => {
  const { expandedHrefs, toggleExpandedHref } = useSideBar();
  const location = useLocation();

  useEffect(() => {
    if (
      href &&
      matchPath(
        {
          end: false,
          path: href,
        },
        location.pathname,
      )
    ) {
      toggleExpandedHref(href, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const expanded = expandedHrefs.includes(href);

  const handleExpandClick = useCallback(
    (event: React.MouseEvent<unknown>) => {
      event.stopPropagation();
      toggleExpandedHref(href, !expanded);
    },
    [expanded, href, toggleExpandedHref],
  );

  const collapser =
    collapsible && !controlled ? (
      <SideNavigationExpandButton onClick={handleExpandClick}>
        <SideNavigationExpandButtonChevronIcon down={expanded} />
      </SideNavigationExpandButton>
    ) : null;

  return (
    <SideNavigationMenuWrapper className={className}>
      {heading && href ? (
        <SideNavigationMenuTitle as={NavLink} to={href}>
          {collapser}
          {heading}
        </SideNavigationMenuTitle>
      ) : null}

      {heading && !href ? (
        <SideNavigationMenuTitle>
          {collapser}
          {heading}
        </SideNavigationMenuTitle>
      ) : null}

      {expanded || !collapsible || controlled ? (
        <SideNavigationMenuItemList
          className={classNames(
            collapsible ? 'collapsible' : 'not-collapsible',
            outlined ? 'outlined' : '',
          )}
        >
          {children}
        </SideNavigationMenuItemList>
      ) : null}
    </SideNavigationMenuWrapper>
  );
};
