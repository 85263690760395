import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import {
  PrimaryButton,
  SecondaryButton,
  Card,
  CardBody,
  CardTitle,
  PageLayout,
  PageLayoutPaddedContent,
  PageHeader,
  useEditContext,
  AccessControl,
  PageHeaderActionButton,
} from '@fcg-tech/regtech-components';
import { groupShape } from '../../../propTypes';
import { DataTable } from '../../../components/DataTable';
import { FormColumn, FormGroup, FormRow } from '../../../components/FormLayout';
import { GroupDetailsSubForm } from '../../../components/SubForms';
import { Trash2 } from 'lucide-react';

const PageActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & > * + * {
    margin-left: 1rem;
  }
`;

const toUserTableData = (username) => ({
  id: username,
  username,
});

const toRoleTableData = ({ roleId, name, parameters, type }) => ({
  id: roleId,
  name,
  type,
  parameters: parameters
    ?.map(
      ({ id: parameterId, value: parameterValue, name: parameterName }) =>
        `${parameterId}=${parameterName || parameterValue}`,
    )
    ?.join('\r\n'),
});

export const GroupPage = ({
  group,
  users,
  roles,
  isLoadingUsers,
  isLoadingRoles,
  isSaving,
  onEdit,
  onSave,
  onCancel,
  onDelete,
  onAddRole,
  onEditRole,
  onDeleteRole,
  onAddUser,
  onDeleteUser,
}) => {
  const isEditEnabled = useEditContext();

  const pageTitle = React.useMemo(() => {
    return group.data.name || '(Unnamed)';
  }, [group]);

  return (
    <Formik initialValues={group.data} onSubmit={onSave}>
      {({ handleSubmit, dirty }) => (
        <PageLayout>
          <PageHeader title={pageTitle}>
            {isEditEnabled ? (
              <PageActionsWrapper>
                <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
                <PrimaryButton
                  onClick={handleSubmit}
                  loading={isSaving}
                  disabled={!dirty || isSaving}
                >
                  Submit
                </PrimaryButton>
              </PageActionsWrapper>
            ) : (
              <PageActionsWrapper>
                <AccessControl
                  permissions={group.permissions}
                  requiredPermissions={['iam:GroupDelete']}
                >
                  <PageHeaderActionButton onClick={onDelete}>
                    <Trash2 size="24" />
                  </PageHeaderActionButton>
                </AccessControl>
                <AccessControl
                  permissions={group.permissions}
                  requiredPermissions={['iam:GroupEdit']}
                >
                  <PrimaryButton onClick={onEdit}>Edit</PrimaryButton>
                </AccessControl>
              </PageActionsWrapper>
            )}
          </PageHeader>
          <PageLayoutPaddedContent>
            <Card>
              <CardBody>
                <CardTitle>Details</CardTitle>
                <Form>
                  <GroupDetailsSubForm />
                </Form>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle>Users</CardTitle>
                <FormRow>
                  <FormColumn>
                    <FormGroup>
                      <DataTable
                        data={users?.map(toUserTableData)}
                        columns={[{ id: 'username', label: 'Username' }]}
                        isLoading={isLoadingUsers}
                        loadingMessage="Loading users"
                        emptyMessage="No users found."
                        sortable={{ orderBy: 'username' }}
                        editable={{ onRowDelete: onDeleteUser }}
                      />
                      <DataTable.Footer>
                        <PrimaryButton onClick={onAddUser}>
                          Add user
                        </PrimaryButton>
                      </DataTable.Footer>
                    </FormGroup>
                  </FormColumn>
                </FormRow>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle>Roles</CardTitle>
                <FormRow>
                  <FormColumn>
                    <FormGroup>
                      <DataTable
                        data={roles?.map(toRoleTableData)}
                        columns={[
                          { id: 'name', label: 'Name' },
                          { id: 'parameters', label: 'Parameters' },
                          { id: 'type', label: 'Type' },
                        ]}
                        isLoading={isLoadingRoles}
                        loadingMessage="Loading roles"
                        emptyMessage="No roles found."
                        sortable={{ orderBy: 'name' }}
                        editable={{
                          onRowEdit: onEditRole,
                          onRowDelete: onDeleteRole,
                        }}
                      />
                      <DataTable.Footer>
                        <PrimaryButton onClick={onAddRole}>
                          Add role
                        </PrimaryButton>
                      </DataTable.Footer>
                    </FormGroup>
                  </FormColumn>
                </FormRow>
              </CardBody>
            </Card>
          </PageLayoutPaddedContent>
        </PageLayout>
      )}
    </Formik>
  );
};

const userShape = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    username: PropTypes.string,
  }),
]);

const roleShape = PropTypes.shape({
  roleId: PropTypes.string,
  name: PropTypes.string,
  parameters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  type: PropTypes.string,
});

GroupPage.propTypes = {
  group: groupShape,
  users: PropTypes.arrayOf(userShape),
  roles: PropTypes.arrayOf(roleShape),
  isSaving: PropTypes.bool,
  isLoadingUsers: PropTypes.bool,
  isLoadingRoles: PropTypes.bool,
  onEdit: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onAddRole: PropTypes.func,
  onEditRole: PropTypes.func,
  onDeleteRole: PropTypes.func,
  onAddUser: PropTypes.func,
  onDeleteUser: PropTypes.func,
};

GroupPage.defaultProps = {
  group: {},
  users: [],
  roles: [],
  isSaving: false,
  isLoadingUsers: false,
  isLoadingRoles: false,
  onEdit: null,
  onSave: null,
  onCancel: null,
  onDelete: null,
  onAddRole: null,
  onEditRole: null,
  onDeleteRole: null,
  onAddUser: null,
  onDeleteUser: null,
};
