/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import { Field, Label } from '@fcg-tech/regtech-components';
import {
  EnumDecision,
  Select,
  TextArea,
  PillMultiSelect,
} from '@fcg-tech/regtech-formik-components';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { MessageKeys } from '../../translations/messageKeys';
import { NONE_SELECT_ITEM } from '../../constants';
import { getTooltip } from '../../utils/tooltipHelpers';
import {
  categoryToSelectOption,
  supplierToSelectOption,
  internalEntityToSelectOption,
} from '../../utils/optionConverters';
import {
  Category,
  InternalEntity,
  Subcontractor,
  WithMetadata,
} from '../../types';
import { countryState } from '../../states/countryState';
import { regionState } from '../../states/regionState';
import { regionSelectOption } from '../../utils/optionConverters';
import { FormRow, FormColumn, FormGroup } from '../FormLayout';
import { SubcontractorsFieldEditFormWrapper } from './SubcontractorsFieldEditForm.styles';

export interface SubcontractorsFieldEditFormProps {
  subcontractor: Subcontractor;
  suppliers?: Array<WithMetadata<any>>; // TODO: descrbe supplier type
  categories?: Array<WithMetadata<Category>>;
  internalEntities?: Array<WithMetadata<InternalEntity>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
}

export const SubcontractorsFieldEditForm = ({
  subcontractor,
  categories = [],
  suppliers = [],
  internalEntities = [],
  setFieldValue,
}: SubcontractorsFieldEditFormProps) => {
  const { t } = useTranslation();

  const countries = useRecoilValue(countryState);
  const regions = useRecoilValue(regionState);

  const regionSelectOptions = useMemo(
    () => regions?.map(regionSelectOption),
    [regions],
  );

  const countrySelectOptions = useMemo(
    () =>
      countries?.map((country) => ({
        key: country.isoAlpha2,
        label: country.name,
      })),
    [countries],
  );

  React.useEffect(() => {
    if (subcontractor.supplier.isExternal) {
      setFieldValue('supplier.internalSupplier', null);
    } else {
      setFieldValue('supplier.externalSupplier', null);
    }
  }, [subcontractor, setFieldValue]);

  const categoriesWithNone = useMemo(
    () => [NONE_SELECT_ITEM, ...categories.map(categoryToSelectOption)],
    [categories],
  );

  const suppliersWithNone = useMemo(
    () => [NONE_SELECT_ITEM, ...suppliers.map(supplierToSelectOption)],
    [suppliers],
  );

  const internalEntitiesWithNone = useMemo(
    () => [
      NONE_SELECT_ITEM,
      ...internalEntities.map(internalEntityToSelectOption),
    ],
    [internalEntities],
  );

  return (
    <SubcontractorsFieldEditFormWrapper>
      <FormRow>
        <FormColumn>
          <FormGroup>
            <EnumDecision.FieldContainer>
              <EnumDecision.FieldLabel htmlFor="subcontractor-supplier">
                {t(MessageKeys.SubcontractorsFieldEditFormSupplierLabel)}
              </EnumDecision.FieldLabel>
              <Field
                component={EnumDecision}
                name="supplier.isExternal"
                items={[
                  {
                    label: t(
                      MessageKeys.SubcontractorsFieldEditFormSupplierExternalLabel,
                    ),
                    value: true,
                  },
                  {
                    label: t(
                      MessageKeys.SubcontractorsFieldEditFormSupplierInternalLabel,
                    ),
                    value: false,
                  },
                ]}
              />
            </EnumDecision.FieldContainer>
            <br />
            {subcontractor.supplier?.isExternal ? (
              <Field
                id="subcontractor-supplier"
                name="supplier.externalSupplier"
                component={Select}
                items={suppliersWithNone}
                typeahead
                info={getTooltip('supplier.isExternal')}
              />
            ) : (
              <Field
                id="subcontractor-supplier"
                name="supplier.internalSupplier"
                component={Select}
                items={internalEntitiesWithNone}
                typeahead
                info={getTooltip('supplier.isExternal')}
              />
            )}
          </FormGroup>
        </FormColumn>
        <FormColumn>
          <FormGroup>
            <Label column htmlFor="subcontractor-category-of-activity">
              {t(MessageKeys.SubcontractorsFieldEditFormFunctionCategoryLabel)}
            </Label>
            <Field
              id="subcontractor-category-of-activity"
              name="categoryOfActivity"
              component={Select}
              items={categoriesWithNone}
              typeahead
              info={getTooltip('subcontractor.categoryOfActivity')}
            />
          </FormGroup>
        </FormColumn>
      </FormRow>
      <FormRow>
        <FormColumn>
          <FormGroup>
            <Label
              column
              htmlFor="subcontractor-countries-where-data-is-performed"
            >
              {t(
                MessageKeys.SubcontractorsFieldEditFormCountriesServicePerformedLabel,
              )}
            </Label>
            <Field
              typeahead
              component={PillMultiSelect}
              name="countriesWhereServiceIsPerformed"
              items={countrySelectOptions}
              id="sub-contractor-countries-where-data-is-performed"
              info={getTooltip(
                'serviceDetails.countriesWhereServiceIsPerformed',
              )}
            />
          </FormGroup>
        </FormColumn>
        <FormColumn>
          <FormGroup>
            <Label
              column
              htmlFor="subcontractor-countries-or-regions-where-data-is-stored"
            >
              {t(
                MessageKeys.SubcontractorsFieldEditFormCountriesDataStoredLabel,
              )}
            </Label>
            <Field
              id="subcontractor-countries-or-regions-where-data-is-stored"
              name="countriesOrRegionsWhereDataIsStored"
              component={PillMultiSelect}
              items={[...regionSelectOptions, ...countrySelectOptions]}
              typeahead
              info={getTooltip(
                'serviceDetails.countriesOrRegionsWhereDataIsStored',
              )}
            />
          </FormGroup>
        </FormColumn>
      </FormRow>
      <FormRow>
        <FormColumn>
          <FormGroup>
            <Label column htmlFor="subcontractor-brief-description">
              {t(MessageKeys.SubcontractorsFieldEditFormDescriptionLabel)}
            </Label>
            <Field
              id="subcontractor-brief-description"
              name="briefDescription"
              component={TextArea}
              info={getTooltip('subcontractor.briefDescription')}
            />
          </FormGroup>
        </FormColumn>
      </FormRow>
    </SubcontractorsFieldEditFormWrapper>
  );
};
