import { useTranslation } from 'react-i18next';
import { FunctionComponent, useCallback } from 'react';
import { useFlash } from '@fcg-tech/regtech-components';
import { NotificationSettings } from '@fcg-tech/regtech-types/oasys';
import {
  NotificationsSettings,
  useNotificationsSettings,
  useNotificationsSettingsActions,
} from '@fcg-tech/notifications';
import { usePersonalNotificationSettings } from '../../api/hooks/personalSettingsApiHooks';
import { environment } from '../../environments/environment';
import { useTenant } from '../../states/tenantState';
import { MessageKeys } from '../../translations/messageKeys';
import { NOTIFICATIONS_OASYS } from '../../constants';
import { NotificationsSettingsTab } from './NotificationsSettingsTab';
import {
  NotificationsSettingsContainerHeading,
  NotificationsSettingsContainerSection,
  NotificationsSettingsContainerDescription,
} from './NotificationsSettingsContainer.styles';

export const NotificationsSettingsContainer: FunctionComponent = () => {
  const { t } = useTranslation();
  const addFlash = useFlash();
  const tenant = useTenant();
  const tenantId = tenant.id;
  const { data: settings } = useNotificationsSettings(
    environment.notificationApiBaseUrl,
    NOTIFICATIONS_OASYS,
  );

  const { data: dateSettings } = usePersonalNotificationSettings(tenantId);

  const { enableEmailNotification, disableEmailNotification } =
    useNotificationsSettingsActions(environment.notificationApiBaseUrl);

  const handleSubmit = useCallback(
    async (toggleCheck: boolean) => {
      try {
        toggleCheck
          ? await enableEmailNotification(NOTIFICATIONS_OASYS)
          : await disableEmailNotification(NOTIFICATIONS_OASYS);
        addFlash({
          level: 'success',
          content: t(MessageKeys.NotificationsSettingsSavedSuccess),
        });
      } catch (e) {
        addFlash({
          level: 'error',
          content: t(MessageKeys.NotificationsSettingsSavedFailure),
        });
      }
    },
    [addFlash, t, enableEmailNotification, disableEmailNotification],
  );

  return <>
    <NotificationsSettingsContainerSection>
      <NotificationsSettingsContainerHeading>
        {t(MessageKeys.NotificationsSettingsEmailSubHeadingLabel)}
      </NotificationsSettingsContainerHeading>
      <NotificationsSettingsContainerDescription>
        {t(MessageKeys.NotificationsSettingsDescriptionLabel)}
      </NotificationsSettingsContainerDescription>
      <NotificationsSettings
        settings={settings?.enabled}
        settingsText={t(MessageKeys.NotificationsSettingsText)}
        onLabel={t(MessageKeys.NotificationsSettingsToggleTextOn)}
        offLabel={t(MessageKeys.NotificationsSettingsToggleTextOff)}
        submitButtonLabel={t(MessageKeys.LabelSubmit)}
        onSubmit={handleSubmit}
      />
    </NotificationsSettingsContainerSection>
    <NotificationsSettingsContainerSection>
      <NotificationsSettingsContainerHeading>
        {t(MessageKeys.NotificationsSettingsDateSubHeadingLabel)}
      </NotificationsSettingsContainerHeading>
      <NotificationsSettingsTab
        dateSettings={dateSettings as NotificationSettings}
      />
    </NotificationsSettingsContainerSection>
  </>;
};
