import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardBody,
  PageHeader,
  PageLayout,
  PageLayoutPaddedContent,
  PrimaryButton,
} from '@fcg-tech/regtech-components';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import Features from '../../../Features';
import { routes } from '../../../routes';
import { useJson2Csv } from '../../../miscHooks';
import { useAccessControl } from '../../../components/AccessControl';
import { useTenant } from '../../../states/tenantState';
import { DataTable } from '../../../components/DataTable';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 0.75rem;
`;

const tableColumns = [
  { id: 'username', label: 'Username' },
  { id: 'groupName', label: 'Group names' },
];

const toTableData = ({ username, memberOf }) => ({
  id: username,
  username,
  groupName: memberOf.map((item) => item.name).join?.(', '),
});

const userComareFn = (a, b) => {
  const aa = a?.username ?? '';
  const bb = b?.username ?? '';
  return aa.localeCompare(bb, 'sv');
};

export const UsersPage = ({ users }) => {
  const navigate = useNavigate();
  const tenant = useTenant();
  const tenantId = tenant.id;

  const canAdd = useAccessControl('iam:UserAdd');

  const csvData = useMemo(
    () => [...users]?.map(toTableData)?.sort(userComareFn),
    [users],
  );

  const { onDownloadCsv } = useJson2Csv('username.csv', csvData, {
    fields: tableColumns.map(({ id, label }) => ({ value: id, label })),
    withBOM: true,
    delimiter: ';',
  });

  const handleRowClick = React.useCallback(
    (username) => {
      navigate(
        constructUrl(routes.user, {
          tenantId,
          username: encodeURIComponent(username),
        }),
      );
    },
    [navigate, tenantId],
  );

  return (
    <PageLayout>
      <PageHeader title="Users" breadcrumbs={[]}>
        {canAdd ? (
          <PrimaryButton to={constructUrl(routes.newUser, { tenantId })}>
            Add new
          </PrimaryButton>
        ) : null}
      </PageHeader>
      <PageLayoutPaddedContent>
        <Card>
          <CardBody>
            {Features.DownloadUsersCsv && (
              <ButtonWrapper>
                <PrimaryButton onClick={onDownloadCsv}>
                  Download CSV
                </PrimaryButton>
              </ButtonWrapper>
            )}
            <DataTable
              columns={tableColumns}
              data={users?.map(toTableData)}
              onRowClick={handleRowClick}
              emptyMessage="No users found."
              sortable={{ orderBy: 'username' }}
            />
          </CardBody>
        </Card>
      </PageLayoutPaddedContent>
    </PageLayout>
  );
};

const userShape = PropTypes.shape({
  username: PropTypes.string,
  memberOf: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
});

UsersPage.propTypes = {
  users: PropTypes.arrayOf(userShape),
};

UsersPage.defaultProps = {
  users: [],
};
