import { FunctionComponent } from 'react';
import { PageScrollingWrapper } from '../../components/Page';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { SuspenseLoader } from '../../components/SuspenseLoader';
import { CategoriesTable } from './CategoriesTable';

export const CategoriesPage: FunctionComponent = () => {
  return (
    <PageScrollingWrapper>
      <ErrorBoundary>
        <SuspenseLoader>
          <CategoriesTable />
        </SuspenseLoader>
      </ErrorBoundary>
    </PageScrollingWrapper>
  );
};
