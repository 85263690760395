import styled, { StyledComponent, DefaultTheme } from 'styled-components';

type PageLayoutElement = StyledComponent<
  'div',
  DefaultTheme,
  Record<string, unknown>,
  never
> & {
  PaddedContent?: StyledComponent<
    'div',
    DefaultTheme,
    Record<string, unknown>,
    never
  >;
};

export const PageLayout: PageLayoutElement = styled.div`
  padding: 2.4rem 0;
  position: relative;
`;

/**
 * Oasys still needs padding for its layout to look correct.
 * Please use PageLayoutContent instead if padding is not needed in your application.
 * */
export const PageLayoutPaddedContent = styled.div`
  height: inherit;
  padding: 0 2.4rem;
`;

export const PageLayoutContent = styled.div`
  height: inherit;
`;

PageLayout.PaddedContent = styled.div`
  padding: 0 2.4rem;
  height: inherit;
`;
