import React from 'react';
import { Portal } from '../Portal';
import * as Spinner from './components';
import { LoaderContent, LoaderWrapper, Message } from './Loader.styles';

const componentNames = ['Square'];

const componentName = (type) => {
  if (componentNames.includes(type)) {
    return Spinner[type];
  }
  return Spinner.Square;
};

interface LoaderProps {
  container?: string;
  disablePortal?: boolean;
  delay?: number;
  message?: string;
  size?: 'small' | 'large';
  type?: 'Square';
}

export const Loader = ({
  container = '#loader-root',
  disablePortal,
  type = 'Square',
  message,
  delay = 300,
  size = 'large',
  ...props
}: LoaderProps) => {
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => setVisible(true), delay);
    return () => clearTimeout(timeout);
  }, [delay, setVisible]);

  if (visible) {
    return (
      <Portal container={container} disable={disablePortal}>
        <LoaderWrapper disablePortal={disablePortal}>
          <LoaderContent>
            {message ? <Message size={size}>{message}</Message> : null}
            {React.createElement(componentName(type), { size, ...props })}
          </LoaderContent>
        </LoaderWrapper>
      </Portal>
    );
  }

  return null;
};
