import { foundation } from '@fcg-tech/regtech-design-system';
import styled, { css } from 'styled-components';
import { excludeProps } from '../../utils';
import { ChevronRightIcon } from '../Icons';

export const PaginationWrapper = styled.div`
  display: flex;
  border-radius: 2px;
`;

export const PaginationPage = styled.div.withConfig(excludeProps('disabled'))<{
  disabled?: boolean;
}>`
  ${({ disabled, theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 3rem;
    padding: 0.5rem;
    height: 3rem;
    font-size: 1.3rem;
    font-weight: 400;
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    opacity: ${disabled ? 0.5 : 1};
    border: 1px solid ${foundation.palette.onyx350.hex};
    background-color: white;
    color: ${foundation.palette.onyx350.hex};

    & + & {
      margin-left: 0.4rem;
    }

    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      font-size: 1.1rem;
      min-width: 2.4rem;
      height: 2.4rem;
    }

    &:hover:not(.current) {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &.current {
      color: #000;
      border: 1px solid #000;
      font-weight: 600;
      transform: scale(1.1);
    }
  `}
`;

export const Dots = styled.div`
  ${({ theme }) => css`
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    background: transparent;

    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      font-size: 1.1rem;
      min-width: 2.4rem;
      height: 2.4rem;
    }
  `}
`;

export const ChevronLeftIcon = styled(ChevronRightIcon)`
  transform: rotate(180deg);
`;
