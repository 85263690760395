import styled, { css, DefaultTheme } from 'styled-components';
import { excludeProps } from '../../utils';
import { H1, H2 } from '../Headings';
import { Breadcrumbs as DefaultBreadcrumbs } from '../Breadcrumbs';
import { IconButton } from '../Button';

export const defaultHeaderStyle = css`
  position: relative;
  padding: 2.4rem 2.4rem 4.8rem 2.4rem;
  padding-top: 0;
`;

export const stickyHeaderStyle = ({ theme }: { theme: DefaultTheme }) => css`
  position: sticky;
  background-color: white;
  border-bottom: 1px solid ${theme.colors?.border ?? 'rgba(0,0,0,0.3)'};
  padding-bottom: 1.4rem;
  padding-top: 1.4rem;
  z-index: 1;
  top: 0;
`;

export const HeaderContainer = styled.div.withConfig(excludeProps('sticky'))<{
  sticy?: boolean;
}>`
  ${({ sticky }) => css`
    ${sticky ? stickyHeaderStyle : defaultHeaderStyle};
  `}
`;

export const HeaderContent = styled.div.withConfig(excludeProps('sticky'))<{
  sticy?: boolean;
}>`
  ${({ sticky }) => css`
    display: flex;
    align-items: ${sticky ? 'center' : 'flex-start'};
    padding-left: ${sticky ? '1.4rem' : '0'};
    padding-right: ${sticky ? '1.4rem' : '0'};
  `}
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-shrink: 0;
`;

export const Actions = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;

  & > button + button {
    margin-left: 1rem;
  }
`;

export const Title = styled(H1).withConfig(excludeProps('sticky'))<{
  sticky?: boolean;
}>`
  font-size: ${({ sticky }) => sticky && '1.6rem'};
  margin-bottom: 0.4rem;
  margin-right: 1rem;
  &.archived {
    opacity: 0.4;
  }
`;

export const Subtitle = styled(H2)`
  margin: 0;
  font-weight: normal;
  font-size: 1.4rem;
  color: #495057;
`;

export const Breadcrumbs = styled(DefaultBreadcrumbs)`
  font-size: 1.4rem;
  font-weight: normal;
`;

export const PageHeaderActions = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
`;

export const PageHeaderActionButton = styled(IconButton)`
  width: 3.6rem;
  height: 3.6rem;
`;
