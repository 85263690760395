import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import {
  Card,
  CardBody,
  CardTitle,
  PageHeader,
  PageLayout,
  PageLayoutPaddedContent,
  PrimaryButton,
  SecondaryButton,
} from '@fcg-tech/regtech-components';

import {
  RolePolicySubForm,
  RoleDetailsSubForm,
} from '../../../components/SubForms';

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & > * + * {
    margin-left: 1rem;
  }
`;

const initialValues = { name: '', type: '', policy: '' };

export const NewRolePage = ({ isSaving, onSave, onCancel }) => {
  return (
    <Formik initialValues={initialValues} onSubmit={onSave}>
      {({ handleSubmit }) => (
        <PageLayout>
          <PageHeader title="Roles" breadcrumbs={[]}>
            <ButtonGroup>
              <PrimaryButton onClick={handleSubmit} disabled={isSaving}>
                Save
              </PrimaryButton>
              <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
            </ButtonGroup>
          </PageHeader>
          <PageLayoutPaddedContent>
            <Card>
              <CardBody>
                <CardTitle>Details</CardTitle>
                <Form>
                  <RoleDetailsSubForm />
                  <RolePolicySubForm />
                </Form>
              </CardBody>
            </Card>
          </PageLayoutPaddedContent>
        </PageLayout>
      )}
    </Formik>
  );
};

NewRolePage.propTypes = {
  isSaving: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

NewRolePage.defaultProps = {
  isSaving: false,
  onSave: null,
  onCancel: null,
};
