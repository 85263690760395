import React from 'react';
import { useAsync } from 'react-async';
import { useMatch } from 'react-router-dom';
import { EditProvider, Loader } from '@fcg-tech/regtech-components';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import {
  loadSupplier,
  loadSupplierAttachments,
  downloadSupplierAttachment,
} from '../../api';
import { routes } from '../../routes';
import { useTenant } from '../../states/tenantState';
import { SupplierVersionPage } from './components';
import { AttachmentControlProvider } from '../../components/Attachments/AttachmentControlContext';
import { ErrorMessage } from '../../components/ErrorBoundary';

export const SupplierVersionContainer = () => {
  const { t } = useTranslation();
  const match = useMatch(routes.supplierVersion);
  const { supplierId, version } = match?.params ?? {};
  const tenant = useTenant();
  const tenantId = tenant.id;

  const loadSupplierReq = useAsync({
    promiseFn: loadSupplier,
    tenantId,
    supplierId,
    version,
  });

  const loadAttachmentsReq = useAsync({
    promiseFn: loadSupplierAttachments,
    tenantId,
    supplierId,
    supplierVersion: version,
  });

  if (loadSupplierReq.isLoading) {
    return <Loader message={t(MessageKeys.LabelLoadingDetails)} />;
  }

  if (loadSupplierReq.error) {
    return <ErrorMessage error={loadSupplierReq.error} />;
  }

  const attachmentControl = {
    onDownloadAttachment: async (attachmentId) => {
      const { url } = await downloadSupplierAttachment({
        tenantId,
        supplierId,
        attachmentId,
      });
      return url;
    },
  };

  return (
    <EditProvider value={false}>
      <AttachmentControlProvider value={attachmentControl}>
        <SupplierVersionPage
          supplier={loadSupplierReq.data}
          attachments={loadAttachmentsReq?.data?.result || []}
        />
      </AttachmentControlProvider>
    </EditProvider>
  );
};
