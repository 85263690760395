import React, {
  MutableRefObject,
  FunctionComponent,
  useRef,
  useEffect,
  useState,
  PropsWithChildren,
} from 'react';
import { NanoPopPosition, reposition } from 'nanopop';
import { Portal } from '../Portal';
import {
  TooltipWrapper,
  ArrowContainer,
  BorderContainer,
} from './Tooltip.styles';
import { classNames } from '@fcg-tech/regtech-utils';

interface TooltipProps {
  container?: string;
  /** @deprecated */
  anchor?: HTMLAnchorElement;
  anchorRef?: MutableRefObject<Element>;
  className?: string;
  arrowClassName?: string;
  arrowStyle?: React.CSSProperties;
  position?: NanoPopPosition;
  style?: React.CSSProperties;
}

export const Tooltip: FunctionComponent<PropsWithChildren<TooltipProps>> = ({
  children,
  container = '#tooltip-root',
  anchor,
  anchorRef,
  position: nanoPopPosition,
  arrowStyle,
  arrowClassName,
  style,
  className,
}) => {
  const tooltip = useRef<HTMLDivElement>();
  const [position, setPosition] = useState<string>('bs');
  const [positioned, setPositioned] = useState(false);

  useEffect(() => {
    if (anchorRef.current && tooltip.current) {
      const position = reposition(
        anchorRef.current as HTMLElement,
        tooltip.current,
        {
          container: document.body.getBoundingClientRect(),
          position: nanoPopPosition ?? 'top-start',
          margin: 12,
        },
      );
      setPositioned(true);
      setPosition(position);
    }
  }, [anchorRef, children, nanoPopPosition]);

  if (!anchorRef?.current) {
    return null;
  }

  return (
    <Portal container={container}>
      <TooltipWrapper
        className={classNames(positioned && 'visible')}
        role="tooltip"
        ref={tooltip}
        style={style}
      >
        <BorderContainer
          className={classNames(
            className,
            `tooltip-arrow-position-${position}`,
          )}
        >
          {children}
        </BorderContainer>
        <ArrowContainer
          className={classNames(
            `tooltip-arrow-position-${position}`,
            arrowClassName,
          )}
          style={arrowStyle}
        />
      </TooltipWrapper>
    </Portal>
  );
};
