/* eslint-disable @typescript-eslint/ban-types */
// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../@types/react-table/index.d.ts" />

import update from 'immutability-helper';
import { DataTableColumn, SortingRule } from './types';
import hash from 'object-hash';
import { ColumnGroup } from 'react-table';

export const compareSortingRules = <D extends object = {}>(
  a: SortingRule<D> | undefined,
  b: SortingRule<D> | undefined,
) => {
  if (a && b && a.id === b.id && a.desc === b.desc) {
    return true;
  }

  return a === b;
};

export const getColumnSettingsHash = <D extends object = {}>(
  columns: Array<DataTableColumn<D>>,
) => {
  const cols = columns.map(({ id, width }) => `${id}-${width}`);
  return hash(cols);
};

export const reorderColumns = <D extends object = {}>(
  columns: Array<DataTableColumn<D>>,
  id: number | string,
  newIndex: number,
) => {
  const [indexes, targetColumn] = findColumnListIndex(columns, id);
  if (indexes?.length && targetColumn) {
    const baseIndexes = [...indexes];
    const oldIndex = baseIndexes.pop();
    if (oldIndex !== undefined && oldIndex >= 0) {
      const [map, innerMost] = toIndexMap(baseIndexes);

      innerMost.$splice = [
        [oldIndex, 1],
        [newIndex, 0, targetColumn],
      ];

      return update(columns, map);
    }
  }
  return columns;
};

const toIndexMap = (
  indexes: Array<number>,
): [map: Record<number, any>, innerMost: any] => {
  const map: Record<number, any> = {};
  let current = map;
  indexes.forEach((index) => {
    current[index] = {};
    current = current[index];
  });

  return [map, current];
};

export const findColumnListIndex = <D extends object = {}>(
  columns: Array<DataTableColumn<D>>,
  id: number | string,
): [indexArr: Array<number> | null, column: DataTableColumn<D> | null] => {
  for (let i = 0; i < columns.length; i++) {
    const column = columns[i];
    if (column.id === id) {
      return [[i], column];
    }
    if (isColumnGroup(column)) {
      const [deepIndex, nestedColumn] = findColumnListIndex(column.columns, id);
      if (deepIndex?.length && nestedColumn) {
        return [[i, ...deepIndex], nestedColumn];
      }
    }
  }

  return [null, null];
};

export const isColumnGroup = <D extends object = {}>(
  column: DataTableColumn<D>,
): column is ColumnGroup<D> =>
  column && Array.isArray((column as ColumnGroup<D>).columns);
