import { useMemo } from 'react';
import useSWR, { mutate, SWRConfiguration } from 'swr';
import { getResourceIdFromLocationHeader, useApi } from '../apiUtils';
import {
  GetInternalEntityResponse,
  InternalEntitiesApi,
  InternalEntity,
  InternalEntityListItem,
} from '../schema';
import { allInternalEntitiesKey, internalEntityKey } from './cacheKeys';

export const getAllInternalEntities = async (
  api: InternalEntitiesApi,
): Promise<Array<InternalEntityListItem>> => {
  const result = await api.listAllInternalEntities();
  return result.result ?? [];
};

export const getInternalEntity = async (
  internalEntityId: string,
  api: InternalEntitiesApi,
): Promise<GetInternalEntityResponse> => {
  const result = await api.getInternalEntity({ internalEntityId });
  return result;
};

export const useInternalEntities = (
  tenantId: string,
  config?: SWRConfiguration,
) => {
  const api = useApi<InternalEntitiesApi>('InternalEntitiesApi', tenantId);
  return useSWR<Array<InternalEntityListItem>>(
    allInternalEntitiesKey(tenantId),
    () => getAllInternalEntities(api),
    { suspense: true, ...config },
  );
};

export const useInternalEntity = (
  tenantId: string,
  internalEntityId: string,
  config?: SWRConfiguration,
) => {
  const api = useApi<InternalEntitiesApi>('InternalEntitiesApi', tenantId);
  return useSWR<GetInternalEntityResponse>(
    internalEntityKey(tenantId, internalEntityId),
    () => getInternalEntity(internalEntityId, api),
    { suspense: true, ...config },
  );
};

export const useInternalEntityActions = (tenantId: string) => {
  const api = useApi<InternalEntitiesApi>('InternalEntitiesApi', tenantId);
  return useMemo(
    () => ({
      createInternalEntity: async (internalEntity: InternalEntity) => {
        const createInternalEntityResponse = await api.createInternalEntityRaw({
          internalEntity,
        });
        mutate(allInternalEntitiesKey(tenantId));
        return getResourceIdFromLocationHeader(createInternalEntityResponse);
      },
      updateInternalEntity: async (
        internalEntityId: string,
        internalEntity: InternalEntity,
      ) => {
        await api.updateInternalEntity({ internalEntityId, internalEntity });
        // TODO: mutate
      },
      deleteInternalEntity: async (internalEntityId: string) => {
        await api.deleteInternalEntity({ internalEntityId });
        // TODO: mutate
      },
    }),
    [api, tenantId],
  );
};
