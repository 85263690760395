import { UploadZone } from '@fcg-tech/regtech-components';
import { useDevice } from '@fcg-tech/regtech-utils';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import {
  FileUploadZoneFileList,
  FileUploadZoneFileListNoItemsMessage,
  FileUploadZoneWrapper,
} from './FileUploadZone.styles';
import { FileUploadZoneFileListItem } from './FileUploadZoneFileListItem';

interface FileUploadZoneProps {
  files: Array<File>;
  allowMultiple?: boolean;
  className?: string;
  onFileDrop?: React.ComponentPropsWithoutRef<typeof UploadZone>['onDrop'];
  onRemoveFile?: (file: File) => Promise<boolean>;
}

export const FileUploadZone: FunctionComponent<FileUploadZoneProps> = ({
  files,
  allowMultiple = false,
  className,
  onFileDrop,
  onRemoveFile,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useDevice();

  return (
    <FileUploadZoneWrapper className={className}>
      {onFileDrop && !isMobile ? (
        <UploadZone
          allowMultiple={allowMultiple}
          activeMessage={t(MessageKeys.FileUploadZoneActiveMessage)}
          hoverMessage={t(MessageKeys.FileUploadZoneHoverMessage)}
          dragFilesHintLabel={t(MessageKeys.FileUploadZoneDragFilesHintLabel)}
          selectFilesButtonLabel={t(
            MessageKeys.FileUploadZoneSelectFilesButtonLabel,
          )}
          uploadErrorMessage={t(MessageKeys.FileUploadZoneUploadErrorMessage)}
          uploadInProgressMessage={t(
            MessageKeys.FileUploadZoneUploadInProgressMessage,
          )}
          onDrop={onFileDrop}
        />
      ) : null}
      {(!onFileDrop || isMobile) && !files?.length ? (
        <FileUploadZoneFileListNoItemsMessage>
          {t(MessageKeys.FileUploadZoneNoItems)}
        </FileUploadZoneFileListNoItemsMessage>
      ) : null}
      <FileUploadZoneFileList>
        {files.map((file, index) => (
          <FileUploadZoneFileListItem
            key={`${file.name}-${index}`}
            file={file}
            onRemoveClick={onRemoveFile}
          />
        ))}
      </FileUploadZoneFileList>
    </FileUploadZoneWrapper>
  );
};
