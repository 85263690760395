import React from 'react';
import { useAsync } from 'react-async';
import { useNavigate, useMatch, matchPath } from 'react-router-dom';
import { EditProvider } from '@fcg-tech/regtech-components';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { routes } from '../../routes';
import { createGroup } from '../../api';
import { NewGroupPage } from './components';

export const NewGroupContainer = () => {
  const navigate = useNavigate();
  const routeMatch = useMatch(routes.newGroup);
  const { tenantId } = routeMatch.params;

  const [isSaving, setIsSaving] = React.useState(false);

  const createGroupProxy = async (args) => {
    setIsSaving(true);
    try {
      const { Location } = await createGroup(...args);
      if (Location) {
        const match = matchPath(
          { path: '/iam/groups/:groupId' },
          `/${Location}`,
        );
        const { groupId } = match.params;
        navigate(constructUrl(routes.group, { tenantId, groupId }));
      } else {
        setIsSaving(false);
      }
    } catch (err) {
      setIsSaving(false);
      throw err;
    }
  };

  const createGroupReq = useAsync({
    deferFn: createGroupProxy,
  });

  const handleSave = React.useCallback(
    (group) => createGroupReq.run({ tenantId, group }),
    [createGroupReq, tenantId],
  );

  const handleCancel = React.useCallback(() => {
    navigate(constructUrl(routes.groups, { tenantId }));
  }, [navigate, tenantId]);

  if (createGroupReq.error) {
    console.log('error creating group');
  }

  return (
    <EditProvider value>
      <NewGroupPage
        isSaving={isSaving}
        onSave={handleSave}
        onCancel={handleCancel}
      />
    </EditProvider>
  );
};
