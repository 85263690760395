import { stringify } from 'query-string';
import { AuthState } from './types';

const ACCESS_TOKEN = 'ACCESS_TOKEN';
const AUTH_STATE = 'AUTH_STATE';

export const clearAccessToken = () => {
  window.sessionStorage.removeItem(ACCESS_TOKEN);
};

export const setAccessToken = (value: string) => {
  const authState = getAuthState() ?? {};
  setAuthState({
    ...authState,
    accessTokenLastUsage: Date.now(),
    userExplicitlyLoggedOut: undefined,
  });
  window.sessionStorage.setItem(ACCESS_TOKEN, value);
};

export const getAccessToken = (updateAuthState = true): string | null => {
  if (updateAuthState) {
    const authState = getAuthState() ?? {};
    setAuthState({
      ...authState,
      accessTokenLastUsage: Date.now(),
    });
  }

  return window.sessionStorage.getItem(ACCESS_TOKEN);
};

export const clearAuthState = () => {
  window.localStorage.removeItem(AUTH_STATE);
};

export const setAuthState = (value: AuthState) => {
  window.localStorage.setItem(AUTH_STATE, JSON.stringify(value));
};

export const getAuthState = (): AuthState => {
  return JSON.parse(window.localStorage.getItem(AUTH_STATE) ?? '{}');
};

const makeStateKey = () => Math.random().toString(36).substring(7);

type StartLoginFlowProps = {
  returnUrl?: string;
  authCallbackUrl: string;
  responseType?: string;
  clientId: string;
  hostedLoginBaseUrl: string;
  scope: string;
  federated: boolean;
  idpIdentifier?: string;
};

export const startLoginFlow = ({
  returnUrl = '/',
  authCallbackUrl,
  responseType = 'token',
  clientId,
  hostedLoginBaseUrl,
  scope,
  federated,
  idpIdentifier,
}: StartLoginFlowProps) => {
  const stateKey = makeStateKey();
  window.localStorage.setItem(stateKey, JSON.stringify({ returnUrl }));
  const queryParameters: Record<string, string> = {
    response_type: responseType,
    client_id: clientId,
    redirect_uri: authCallbackUrl,
    state: stateKey,
  };
  if (idpIdentifier) {
    queryParameters.idp_identifier = idpIdentifier;
  }

  const path = federated ? '/oauth2/authorize' : '/login';
  window.location.href = `${hostedLoginBaseUrl}${path}?${stringify(
    queryParameters,
  )}&scope=${scope}`;
};

export const endLoginFlow = (accessToken: string) => {
  setAccessToken(accessToken);
};

type LogoutProps = {
  callbackUrl: string;
  clientId?: string;
  hostedLoginBaseUrl: string;
  redirectUri?: string;
  responseType?: string;
};

export const logout = async (props?: LogoutProps) => {
  window.sessionStorage.clear();

  if (props?.clientId) {
    const {
      hostedLoginBaseUrl,
      clientId,
      callbackUrl,
      redirectUri,
      responseType,
    } = props;
    const path = '/logout';
    const queryParameters = {
      client_id: clientId,
      logout_uri: callbackUrl,
      redirect_uri: redirectUri,
      response_type: responseType,
    };
    window.location.assign(
      `${hostedLoginBaseUrl}${path}?${stringify(queryParameters)}`,
    );
  }
};
