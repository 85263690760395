import styled from 'styled-components';
import { TriangleExclamationIcon } from '@fcg-tech/regtech-components';

export const ErrorDialogMessage = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 0.4rem 0;
  min-width: 40rem;
`;

export const ErrorDialogTitleInner = styled.span`
  display: flex;
  align-items: center;
`;

export const ErrorDialogErrorIcon = styled(TriangleExclamationIcon)`
  margin-right: 1.4rem;
`;

export const ErrorDialogVersionMessage = styled.div`
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.6);
`;
