import React from 'react';
import { LogLevel } from '../../types';
import { FieldErrorMessage } from './FieldError.styles';
import { useNonBlockingFieldError } from '../../components/FormikNonBlockingContext';

interface FieldErrorNonBlockingProps {
  name: string;
}

export const FieldErrorNonBlocking = ({ name }: FieldErrorNonBlockingProps) => {
  const error = useNonBlockingFieldError(name);
  return error ? (
    <FieldErrorMessage level={LogLevel.Warning}>
      {error.message}
    </FieldErrorMessage>
  ) : null;
};
