import styled from 'styled-components';

export const VersionHistoryTableLoadingWrapper = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  display: flex;

  align-items: center;
  justify-content: center;
  flex: 1;
  z-index: 1;
`;

export const VersionHistoryTableNoContent = styled.div`
  position: absolute;
  top: 40%;
  left: 0;
  width: 100%;
  text-align: center;
  pointer-events: none;
  font-size: 2.2rem;
  font-weight: 600;
`;
