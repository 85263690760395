import { EmptyPropsWithChildren } from '@fcg-tech/regtech-types';
import React, {
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
  PropsWithChildren,
} from 'react';
import { useInitData } from '../../api/hooks/initApiHooks';
import { useTenant } from '../../states/tenantState';
import { GlobalActions, IamActions } from '../../types';

const globalPermRe = /^oasys:/;
const iamPermRe = /^iam:/;

export interface IamContextProps {
  globalPermissions: Array<GlobalActions>;
  iamPermissions: Array<IamActions>;
}

export const IamContext = createContext<IamContextProps>({
  globalPermissions: [],
  iamPermissions: [],
});

export const useIamContext = () => useContext(IamContext);

const getContextPermissions = (
  permissions: Array<GlobalActions | IamActions>,
) => ({
  globalPermissions: permissions.filter((permission) =>
    permission.match(globalPermRe),
  ) as Array<GlobalActions>,
  iamPermissions: permissions.filter((p) =>
    p.match(iamPermRe),
  ) as Array<IamActions>,
});

export const IamContextProvider: FunctionComponent<PropsWithChildren<EmptyPropsWithChildren>> = ({ children }) => {
  const tenant = useTenant();
  const { data: initData } = useInitData(tenant.id);

  const [value, setValue] = useState<IamContextProps>(
    getContextPermissions(initData.permissions),
  );

  const contextValue = useMemo<IamContextProps>(
    () => ({
      ...value,
    }),
    [value],
  );

  useEffect(() => {
    setValue(getContextPermissions(initData.permissions));
  }, [initData.permissions]);

  return (
    <IamContext.Provider value={contextValue}>{children}</IamContext.Provider>
  );
};
