import { UnstyledButton } from '@fcg-tech/regtech-components';
import styled from 'styled-components';
import { ModalFormWrapper } from './modalFormComponents';

export const CreateSupplierModalFormWrapper = styled(ModalFormWrapper)`
  width: 35vw;
`;

export const CreateSupplierModalActionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0.4rem;
  margin-right: 2.4rem;
`;

export const CreateSupplierModalActionRowButton = styled(UnstyledButton)`
  font-size: 1.2rem;
  font-weight: 600;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
`;

export const CreateSupplierModalActionRowIconWrapper = styled.span`
  margin-left: 0.6rem;
`;
