import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  padding: 1.2rem;
  overflow: scroll;
  background-color: ${({ theme }) => theme.colors.backdrop};
  max-height: 380px;

  & + & {
    margin-left: 2.4rem;
  }
`;

const Pre = styled.pre`
  font-size: 1.4rem;
`;

export const JSONPretty = ({ data }) => (
  <Container>
    <Pre>{JSON.stringify(data, null, 2)}</Pre>
  </Container>
);

JSONPretty.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any,
};

JSONPretty.defaultProps = {
  data: null,
};
