import { Tab, TabList } from 'reakit';
import styled, { css } from 'styled-components';
import { mobile } from '../../theme';
import { excludeProps } from '../../utils';
import { Badge } from '../Badge';

export const TabBarWrapper = styled.div`
  display: flex;
  background-color: white;
`;

export const TabBarTab = styled(Tab).withConfig<{ selected?: boolean }>(
  excludeProps('selected'),
)`
  ${({ theme, selected }) => css`
    display: flex;
    position: relative;
    z-index: 5;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 7rem;
    padding: 1rem 2rem;
    background: white;
    border: 0;
    opacity: 0.65;
    color: black;

    &:hover {
      opacity: 0.9;
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.2;
    }

    & > ${Badge} {
      margin-left: 1rem;
    }

    ${selected
      ? css`
          opacity: 1 !important;
          &:after {
            position: absolute;
            content: ' ';
            bottom: 0.4rem;
            left: 1.9rem;
            right: 1.9rem;
            height: 0.4rem;
            border-radius: 0.2rem;
            background-color: ${theme?.palette?.MainColorAccent?.main ??
            'lightblue'};
          }
        `
      : null}

    ${mobile(
      () => css`
        outline: none;
        &:hover,
        &:active {
          opacity: 0.65;
        }
      `,
    )};
  `}
`;

export const TabBarTabLabel = styled.span`
  ${({ theme }) => css`
    font-size: 1.4rem;
    font-weight: 600;
    @media (max-width: ${theme.breakpoints?.mobile ?? 730}px) {
      display: none;
    }
  `}
`;

export const TabBarList = styled(TabList)`
  background-color: white;
  display: flex;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  flex-direction: row;
  padding: 0 2rem;

  &.horizontal {
    & ${TabBarTab} {
      height: 5rem;
      flex-direction: row;
      padding: 1rem 2rem 0 2rem;

      & ${TabBarTabLabel} {
        margin-left: 1rem;
      }
    }

    & ${TabBarTab}:after {
      bottom: -0.3rem;
      height: 0.5rem;
      border-radius: 0.25rem;
      outline: 5px solid white;
    }

    /* Save for later
    & ${TabBarTab} + ${TabBarTab}:before {
      position: absolute;
      content: ' ';
      bottom: 0.8rem;
      top: 1.8rem;
      left: 0.1rem;
      width: 1px;
      background-color: rgba(0, 0, 0, 0.3);
    }
    */
  }
  &.vertical {
    flex-direction: column;
    align-items: stretch;
    padding: 0;
  }

  ${mobile(
    () => css`
      &.horizontal {
        justify-content: space-between;
        padding: 0;
      }
    `,
  )}
`;
