import { useEscapeKey } from '@fcg-tech/regtech-utils';
import { format, isAfter, isBefore, isSameDay, isValid } from 'date-fns';
import { reposition } from 'nanopop';
import React, {
  FunctionComponent,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { getScrollParent } from '../../utils';
import { useClickOutside } from '../ClickOutsideListener';
import { Datepicker, getMonthConfig } from '../DatePicker';
import { CrossIcon } from '../Icons';
import { TextField } from '../TextField';
import {
  DateFieldRemoveDateButton,
  DateFieldTextFieldInputWrapper,
  DateFieldWrapper,
  DatePickerWrapper,
  ReadOnlyContainer,
  ReadOnlyLabel,
  StyledInfoCircle,
} from './DateField.styles';
import { parseDate } from './dateUtils';

export interface DateFieldProps {
  dateFormat?: string;
  info?: string;
  placeholder?: string;
  isEditEnabled?: boolean;
  value?: string | Date | null;
  containerRef?: React.MutableRefObject<HTMLElement>;
  min?: Date | null;
  max?: Date | null;
  className?: string;
  textFieldClassName?: string;
  textFieldInputClassName?: string;
  onChange?: (date: Date | null) => void;
  onReset?: () => void;
}

export const DateField: FunctionComponent<DateFieldProps> = ({
  dateFormat = 'yyyy-MM-dd',
  info = '',
  placeholder,
  isEditEnabled = true,
  containerRef,
  min,
  max,
  onChange,
  onReset,
  value,
  className,
  textFieldClassName,
  textFieldInputClassName,
}) => {
  const datepickerRef = useRef<HTMLDivElement>();
  const inputWrapperRef = useRef<HTMLDivElement>();
  const inputRef = useRef<HTMLInputElement>();
  const wrapperRef = useRef<HTMLDivElement>();
  const valueString = useMemo<string>(
    () =>
      value
        ? value instanceof Date && isValid(value)
          ? format(value, dateFormat)
          : String(value)
        : '-',
    [value, dateFormat],
  );
  const [showDatepicker, setShowDatePicker] = useState(false);
  const actualDate = useMemo(
    () => (typeof value === 'string' ? new Date(value) : value),
    [value],
  );

  const [currentMonth, setCurrentMonth] = useState<Date>(
    actualDate ?? new Date(),
  );

  const isDateAllowed = useCallback(
    (date: Date) => {
      if (min && isBefore(date, min) && !isSameDay(date, min)) {
        return false;
      }
      if (max && isAfter(date, max) && !isSameDay(date, max)) {
        return false;
      }
      return true;
    },
    [min, max],
  );

  const [inputValue, setInputValue] = useState<string>();
  const [inputValueError, setInputValueError] = useState<boolean>(false);

  const commit = useCallback(
    (forceDate?: Date) => {
      if (inputValue || forceDate) {
        try {
          const date = forceDate ?? parseDate(inputValue);
          if (date || !inputValue?.length) {
            onChange?.(date ?? null);
            setInputValue(undefined);
            setInputValueError(false);
            setCurrentMonth(date ?? actualDate ?? new Date());
            setTimeout(() => setShowDatePicker(false), 200);
            inputRef.current?.blur();
          } else {
            setInputValueError(true);
          }
        } catch (e) {
          setInputValueError(true);
        }
      }
    },
    [actualDate, inputValue, onChange, setShowDatePicker],
  );

  const handleClickOutside = useCallback(() => {
    if (showDatepicker) {
      setShowDatePicker(false);
    }
  }, [setShowDatePicker, showDatepicker]);

  useClickOutside(wrapperRef, handleClickOutside);

  useLayoutEffect(() => {
    if (showDatepicker) {
      reposition(inputWrapperRef.current, datepickerRef.current, {
        container: (
          containerRef?.current ?? document.documentElement
        ).getBoundingClientRect(),
        position: 'bottom-start',
      });

      const scrollingParent = getScrollParent(wrapperRef.current);
      if (scrollingParent) {
        const onHideEvent = () => setShowDatePicker(false);
        scrollingParent.addEventListener('scroll', onHideEvent);
        window.addEventListener('resize', onHideEvent);
        return () => {
          scrollingParent.removeEventListener('scroll', onHideEvent);
          window.removeEventListener('resize', onHideEvent);
        };
      }
    }
    return undefined;
  }, [containerRef, setShowDatePicker, showDatepicker]);

  const handleChange = useCallback(
    (date: Date) => {
      commit(date);
    },
    [commit],
  );

  const handleReset = useCallback(
    (event: React.PointerEvent<HTMLButtonElement>) => {
      onReset?.();
      event.preventDefault();
      event.stopPropagation();
    },
    [onReset],
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValueError(false);
      setInputValue(event.target.value);
      if (event.target.value.length === 0) {
        onChange?.(null);
        setCurrentMonth(actualDate ?? new Date());
      } else {
        const date = parseDate(event.target.value);
        if (date) {
          setCurrentMonth(date);
        }
      }
    },
    [actualDate, onChange],
  );

  const handleInputClick = useCallback(
    (event: React.PointerEvent<HTMLInputElement>) => {
      setTimeout(() => setShowDatePicker(true), 1);
    },
    [setShowDatePicker],
  );

  const cancel = useCallback(() => {
    setInputValue(undefined);
    setInputValueError(false);
    setCurrentMonth(actualDate ?? new Date());
    setShowDatePicker(false);
    inputRef.current?.blur();
  }, [actualDate, setShowDatePicker]);

  const handleInputKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        commit();
        if (!inputValue) {
          setShowDatePicker(false);
          inputRef.current?.blur();
        }
      } else {
        setShowDatePicker(true);
      }
    },
    [commit, inputValue, setShowDatePicker],
  );

  const handleInputBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      commit();
    },
    [commit],
  );

  const handleInputFocus = useCallback(() => setShowDatePicker(true), []);

  useEscapeKey({ callback: cancel });

  if (isEditEnabled) {
    return (
      <DateFieldWrapper ref={wrapperRef} className={className}>
        <DateFieldTextFieldInputWrapper ref={inputWrapperRef}>
          <TextField
            ref={inputRef}
            value={
              inputValue ??
              (isValid(actualDate) ? format(actualDate, 'yyyy-MM-dd') : '')
            }
            placeholder={placeholder}
            autoCorrect="off"
            className={textFieldClassName}
            inputClassName={textFieldInputClassName}
            onFocus={handleInputFocus}
            onPointerDown={handleInputClick}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            onKeyDown={handleInputKeyDown}
            error={inputValueError}
          />
          {onReset && value ? (
            <DateFieldRemoveDateButton onClick={handleReset}>
              <CrossIcon size="12" />
            </DateFieldRemoveDateButton>
          ) : null}
        </DateFieldTextFieldInputWrapper>
        {info && <StyledInfoCircle info={info} />}
        {showDatepicker ? (
          <DatePickerWrapper ref={datepickerRef}>
            <Datepicker
              value={inputValue ? parseDate(inputValue) : actualDate}
              currentMonth={currentMonth}
              month={getMonthConfig(currentMonth, { isDateAllowed })}
              onCurrentMonthChange={setCurrentMonth}
              onChange={handleChange}
            />
          </DatePickerWrapper>
        ) : null}
      </DateFieldWrapper>
    );
  }

  return (
    <ReadOnlyContainer>
      <ReadOnlyLabel>{valueString}</ReadOnlyLabel>
      {info && <StyledInfoCircle info={info} />}
    </ReadOnlyContainer>
  );
};
