import { useMemo } from 'react';
import useSWR, { mutate, SWRConfiguration } from 'swr';
import {
  convertAgreementFilterForApi,
  convertStoredAgreementFilter,
  convertStoredSupplierFilter,
  convertSupplierFilterForApi,
} from '../../converters/filterConverters';
import {
  AgreementFilterValues,
  ApiStoredAgreementFilter,
  ApiStoredSupplierFilter,
  FilterType,
  StoredAgreementFilter,
  StoredSupplierFilter,
  SupplierFilterValues,
} from '../../types';
import { removeWithId, upsertWithId } from '../../utils/immutabilityHelpers';
import { useApi } from '../apiUtils';
import {
  AgreementFilterInput,
  FiltersApi,
  SupplierFilterInput,
} from '../schema';
import { filterKey } from './cacheKeys';

export const getStoredAgreementFilters = async (
  api: FiltersApi,
): Promise<Array<StoredAgreementFilter>> => {
  const result = await api.getAgreementFilters();
  return result.result?.map(convertStoredAgreementFilter);
};

export const useStoredAgreementFilters = (
  tenantId: string,
  config?: SWRConfiguration,
) => {
  const api = useApi<FiltersApi>('FiltersApi', tenantId);
  return useSWR<Array<StoredAgreementFilter>>(
    filterKey(tenantId, FilterType.AgreementFilter),
    () => getStoredAgreementFilters(api),
    {
      suspense: true,
      ...config,
    },
  );
};

export const getStoredSupplierFilters = async (
  api: FiltersApi,
): Promise<Array<StoredSupplierFilter>> => {
  const result = await api.getSupplierFilters();
  return result.result?.map(convertStoredSupplierFilter);
};

export const useStoredSupplierFilters = (
  tenantId: string,
  config?: SWRConfiguration,
) => {
  const api = useApi<FiltersApi>('FiltersApi', tenantId);
  return useSWR<Array<StoredSupplierFilter>>(
    filterKey(tenantId, FilterType.SupplierFilter),
    () => getStoredSupplierFilters(api),
    {
      suspense: true,
      ...config,
    },
  );
};

export const useStoredFilterActions = (tenantId: string) => {
  const api = useApi<FiltersApi>('FiltersApi', tenantId);
  return useMemo(
    () => ({
      saveAgreementFilter: async (
        name: string,
        filterValues: AgreementFilterValues,
        filterId?: string,
      ) => {
        const agreementFilterInput: AgreementFilterInput = {
          name,
          filter: convertAgreementFilterForApi(filterValues),
        };
        let apiStoredFilter: ApiStoredAgreementFilter;
        if (!filterId) {
          apiStoredFilter = await api.createAgreementFilter({
            agreementFilterInput: {
              ...agreementFilterInput,
              filter: {
                ...agreementFilterInput.filter,
                isPinned: true,
              },
            },
          });
        } else {
          apiStoredFilter = await api.updateAgreementFilter({
            filterId,
            agreementFilterInput,
          });
        }
        const storedFilter = convertStoredAgreementFilter(apiStoredFilter);
        mutate(
          filterKey(tenantId, FilterType.AgreementFilter),
          upsertWithId(storedFilter.id, storedFilter),
          true,
        );
        return storedFilter;
      },

      deleteAgreementFilter: async (filterId: string) => {
        await api.deleteAgreementFilter({ filterId });
        mutate(
          filterKey(tenantId, FilterType.AgreementFilter),
          removeWithId(filterId),
          true,
        );
      },

      saveSupplierFilter: async (
        name: string,
        filterValues: SupplierFilterValues,
        filterId?: string,
      ) => {
        const supplierFilterInput: SupplierFilterInput = {
          name,
          filter: convertSupplierFilterForApi(filterValues),
        };
        let apiStoredFilter: ApiStoredSupplierFilter;
        if (!filterId) {
          apiStoredFilter = await api.createSupplierFilter({
            supplierFilterInput: {
              ...supplierFilterInput,
              filter: {
                ...supplierFilterInput.filter,
                isPinned: true,
              },
            },
          });
        } else {
          apiStoredFilter = await api.updateSupplierFilter({
            filterId,
            supplierFilterInput,
          });
        }
        const storedFilter = convertStoredSupplierFilter(apiStoredFilter);
        mutate(
          filterKey(tenantId, FilterType.SupplierFilter),
          upsertWithId(storedFilter.id, storedFilter),
          true,
        );
        return storedFilter;
      },

      deleteSupplierFilter: async (filterId: string) => {
        await api.deleteSupplierFilter({ filterId });
        mutate(
          filterKey(tenantId, FilterType.SupplierFilter),
          removeWithId(filterId),
          true,
        );
      },
    }),
    [api, tenantId],
  );
};
