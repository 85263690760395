/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GlobalSearchResponse,
} from '../models';
import {
    GlobalSearchResponseFromJSON,
    GlobalSearchResponseToJSON,
} from '../models';

export interface GlobalSearchRequest {
    query: string;
}

/**
 * GlobalSearchApi - interface
 * 
 * @export
 * @interface GlobalSearchApiInterface
 */
export interface GlobalSearchApiInterface {
    /**
     * 
     * @summary Global search
     * @param {string} query Support common search functionality:  unquoted text: text not inside quote marks will be converted to terms separated by &amp; operators.  \&quot;quoted text\&quot;: text inside quote marks will be converted to terms separated by &lt;-&gt; operators, as if processed by phraseto_tsquery.  OR: the word “or” will be converted to the | operator.  -: a dash will be converted to the ! operator. 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalSearchApiInterface
     */
    globalSearchRaw(requestParameters: GlobalSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GlobalSearchResponse>>;

    /**
     * Global search
     */
    globalSearch(requestParameters: GlobalSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GlobalSearchResponse>;

}

/**
 * 
 */
export class GlobalSearchApi extends runtime.BaseAPI implements GlobalSearchApiInterface {

    /**
     * Global search
     */
    async globalSearchRaw(requestParameters: GlobalSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GlobalSearchResponse>> {
        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError('query','Required parameter requestParameters.query was null or undefined when calling globalSearch.');
        }

        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GlobalSearchResponseFromJSON(jsonValue));
    }

    /**
     * Global search
     */
    async globalSearch(requestParameters: GlobalSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GlobalSearchResponse> {
        const response = await this.globalSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
