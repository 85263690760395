import styled, { css } from 'styled-components';

import { AlignJustifyIcon } from '../Icons';

export const SideBarWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    background: ${theme?.palette?.MainColor?.main ?? '#F9F9F9'};
    min-height: 100%;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    transition: width 0.1s linear;
    margin-right: 0;
    overflow: hidden;
    flex-basis: auto;
    width: 24rem;

    &.collapsed {
      width: 6.6rem;
      align-items: flex-start;
    }

    &.floating {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 100;
      transform: translateX(-6%);
      opacity: 0;
      transition: transform 0.15s linear, opacity 0.12s linear;
      pointer-events: none;
      width: 24rem;
    }

    &.floating-animate {
      transform: translateX(0);
      opacity: 1;
      pointer-events: all;
    }

    @media (max-width: ${theme.breakpoints?.mobile ?? 730}px) {
      display: none;

      &.floating {
        display: flex;
      }
    }

    ${theme.elements?.sideBar?.wrapper ?? ''}
  `}
`;

export const SideBarHamburgerButtonWrapper = styled.div`
  ${({ theme }) => theme.elements?.sideBar?.hamburgerButton?.wrapper ?? ''};
`;

export const SideBarHamburgerButtonIcon = styled(AlignJustifyIcon)`
  &:hover {
    & > path {
      fill: ${({ theme }) => theme?.palette?.MainColorAccent?.main ?? '#ddd'};
    }
  }
`;

export const SideBarHeader = styled.div`
  position: relative;
  height: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.6rem;
  width: 100%;

  & > ${SideBarHamburgerButtonWrapper} {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 2rem;
  }

  .collapsed & > ${SideBarHamburgerButtonWrapper} {
    position: relative;
    top: initial;
    transform: none;
    left: initial;
  }

  ${({ theme }) => theme.elements?.sideBar?.logoWrapper ?? ''};
`;

export const SideBarLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .collapsed & {
    display: none;
  }
`;

export const SideBarLogoImg = styled.img`
  height: 3rem;
  width: auto;
`;

export const SideBarContent = styled.div`
  ${({ theme }) => css`
    padding: 1.6rem 0;
    flex: 1;
    overflow-y: auto;
    &::-webkit-scrollbar {
      background-color: ${theme?.palette?.MainColor?.main ?? '#F9F9F9'};
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 9999px;
      border: 1px solid rgba(0, 0, 0, 0);
      border-right-width: 2px;
      background-clip: padding-box;
    }

    ${theme.elements?.sideBar?.content ?? ''};
  `}
`;

export const SideBarSeparator = styled.div`
  height: 1px;
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
`;
