import React, { FunctionComponent } from 'react';
import { Prompt } from './Prompt';
// import { Prompt } from 'react-router-dom';

const MESSAGE = 'You have unchanged changes, are you sure you wish to leave?';

export interface DirtyFormCheckProps {
  dirty: boolean;
}

export const DirtyFormCheck: FunctionComponent<DirtyFormCheckProps> = ({
  dirty,
}) => {
  React.useEffect(() => {
    window.onbeforeunload = dirty ? () => MESSAGE : null;

    return () => {
      window.onbeforeunload = null;
    };
  }, [dirty]);

  return <Prompt when={dirty} message={MESSAGE} />;
};
