import styled from 'styled-components';
import { sideNavigationItemStyle } from './SideNavigationItem.styles';

export const SideNavigationCenteredWhenCollapsed = styled.div`
  .collapsed & {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const SideNavigationMenuWrapper = styled.div`
  margin-top: 0.4rem;
  font-weight: 500;
  font-size: 1.4rem;
  color: ${({ theme }) => theme?.palette?.MainBlack};

  .collapsed & {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  ${({ theme }) => theme?.elements?.sideNavigation?.menu?.wrapper}
`;

export const SideNavigationMenuItemList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;

  .collapsed & {
    width: 100%;
    justify-content: center;
  }

  &.outlined {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 2.4rem;
      bottom: 0;
      width: 2px;
      background-color: rgba(255, 255, 255, 0.7);
    }
  }
`;

export const SideNavigationMenuTitle = styled.h5`
  ${sideNavigationItemStyle};
  font-size: 1.5rem;
  margin: 0 0 0.4rem 0;
  opacity: 0.7;
  color: inherit;
  font-weight: inherit;
  font-family: inherit;
  text-decoration: none;
  will-change: opacity, color;

  &:hover,
  &:focus,
  &:active {
    color: inherit;
    opacity: 1;
  }

  &:visited {
    color: inherit;
  }
`;
