import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { H1, ListIcon, SettingsIcon } from '@fcg-tech/regtech-components';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { parse } from 'query-string';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { MainLayoutPaddedContentWrapper } from '../../components/MainLayout';
import { PageScrollingWrapper } from '../../components/Page';
import { SuspenseLoader } from '../../components/SuspenseLoader';
import {
  StickyTabBar,
  TabPanelsWrapper,
  TabPanelWrapper,
} from '../../components/TabBar';
import { routes } from '../../routes';
import { useTenant } from '../../states/tenantState';
import { MessageKeys } from '../../translations/messageKeys';
import { NotificationsSettingsContainer } from './NotificationsSettingsContainer';
import { NotificationsContainer } from './NotificationsContainer';
import { NotificationPageWrapper } from './NotificationsContainer.styles';

export enum NotificationsTabID {
  Notifications = 'notifications',
  Settings = 'settings',
}

type Query = {
  filter?: 'all' | 'unread';
};
export const NotificationsPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const tenant = useTenant();
  const location = useLocation();
  const query = useMemo<Query>(() => parse(location.search), [location.search]);
  const [tabId, setTabId] = useState(
    location.pathname ===
      constructUrl(routes.notificationsSettings, { tenantId: tenant.id })
      ? NotificationsTabID.Settings
      : NotificationsTabID.Notifications,
  );

  const filter = query.filter ?? 'unread';

  const tabs = useMemo(
    () => [
      {
        id: NotificationsTabID.Notifications,
        label: t(MessageKeys.NotificationsLabel),
        Icon: ListIcon,
      },
      {
        id: NotificationsTabID.Settings,
        label: t(MessageKeys.NotificationsSettingsLabel),
        Icon: SettingsIcon,
      },
    ],
    [t],
  );

  const handleTabChange = useCallback(
    (selectedTabId: NotificationsTabID) => {
      setTabId(selectedTabId);
      selectedTabId === NotificationsTabID.Notifications
        ? navigate(
            constructUrl(routes.notifications, { tenantId: tenant.id }),
            {
              replace: true,
            },
          )
        : navigate(
            constructUrl(routes.notificationsSettings, { tenantId: tenant.id }),
            {
              replace: true,
            },
          );
    },
    [navigate, tenant.id],
  );

  return (
    <PageScrollingWrapper>
      <MainLayoutPaddedContentWrapper>
        <H1>{t(MessageKeys.NotificationsPageHeading)}</H1>
      </MainLayoutPaddedContentWrapper>
      <NotificationPageWrapper>
        <StickyTabBar
          tabs={tabs}
          initialSelectedId={tabId}
          ariaLabel={t(MessageKeys.NotificationsSettingsLabel)}
          onChange={handleTabChange}
        >
          {(tabState) => (
            <TabPanelsWrapper>
              <TabPanelWrapper {...tabState} id={tabs[0].id}>
                {tabState.currentId === tabs[0].id ? (
                  <ErrorBoundary>
                    <SuspenseLoader>
                      <NotificationsContainer key={filter} filter={filter} />
                    </SuspenseLoader>
                  </ErrorBoundary>
                ) : null}
              </TabPanelWrapper>
              <TabPanelWrapper {...tabState} id={tabs[1].id}>
                {tabState.currentId === tabs[1].id ? (
                  <ErrorBoundary>
                    <SuspenseLoader>
                      <NotificationsSettingsContainer />
                    </SuspenseLoader>
                  </ErrorBoundary>
                ) : null}
              </TabPanelWrapper>
            </TabPanelsWrapper>
          )}
        </StickyTabBar>
      </NotificationPageWrapper>
    </PageScrollingWrapper>
  );
};
