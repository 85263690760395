import {
  ColorWithContrast,
  LegacyPalette,
  Theme,
} from '@fcg-tech/regtech-types';
import { css } from 'styled-components';
import { foundation } from './foundation';

const primaryColor: ColorWithContrast = { hex: '#244C44', contrastHex: '#fff' };

const sidebarMainColor = primaryColor;

const buttonStyle = css`
  border-radius: 0.3rem;
`;

export type InteractTheme = Theme;

export const HEADER_HEIGHT_REM = 7;

export const palette: LegacyPalette = {
  MainColor: {
    light: '#1EC59B',
    lightContrast: 'rgba(10,10,10,1)',
    main: '#244C44',
    mainContrast: '#fff',
    dark: '#00241f',
    darkContrast: 'rgba(247, 247, 247, 1)',
  },
  MainColorAccent: {
    light: '#ECDEC5',
    lightContrast: 'rgba(10,10,10,1)',
    main: '#E5D3B2',
    mainContrast: 'rgba(10,10,10,1)',
    dark: '#ccba99',
    darkContrast: 'rgba(10,10,10,1)',
  },
  MainBlack: '#000',
  MainWhite: '#FFF',
};

export const theme: InteractTheme = {
  version: '1',
  breakpoints: {
    mobile: 730,
    narrowDesktop: 1024,
    mediumDesktop: 1240,
  },

  colors: {
    backdrop: '#F9F9F9',
    info: {
      main: palette.MainColor.main,
      contrast: palette.MainColor.mainContrast,
    },
    error: {
      main: '#DC3545',
      contrast: palette.MainWhite,
    },
    warning: {
      main: '#FFC109',
      contrast: palette.MainBlack,
    },
    success: {
      main: '#28A745',
      contrast: palette.MainWhite,
    },
    primary: {
      main: palette.MainColor.main,
      contrast: palette.MainColor.mainContrast,
    },
    secondary: {
      main: palette.MainColorAccent.light,
      contrast: palette.MainColorAccent.lightContrast,
    },
  },

  palette: palette,

  elements: {
    header: {
      wrapper: css`
        height: ${HEADER_HEIGHT_REM}rem;
        border-bottom-width: 1px;
      `,
    },
    sideNavigation: {
      menu: {
        wrapper: css`
          color: ${sidebarMainColor.contrastHex};
        `,
      },
      item: {
        link: {
          nominal: css`
            color: ${sidebarMainColor.contrastHex};
          `,
          active: css`
            color: ${sidebarMainColor.contrastHex};
          `,
          focus: css`
            color: ${sidebarMainColor.contrastHex};
          `,
          hover: css`
            color: ${sidebarMainColor.contrastHex};
          `,
          visited: css`
            color: ${sidebarMainColor.contrastHex};
          `,
        },
        button: {
          nominal: css`
            color: ${sidebarMainColor.contrastHex};
          `,
          hover: css`
            color: ${sidebarMainColor.contrastHex};
          `,
          active: css`
            color: ${sidebarMainColor.contrastHex};
          `,
        },
      },
      sectionHeading: css`
        color: ${sidebarMainColor.contrastHex};
      `,
      expandButton: {
        icon: css`
          color: ${sidebarMainColor.contrastHex};
        `,
      },
    },
    sideBar: {
      hamburgerButton: {
        wrapper: css`
          color: ${sidebarMainColor.contrastHex};
        `,
      },
      logoWrapper: css`
        background: ${sidebarMainColor.hex};
        height: 7rem;
      `,
      wrapper: css`
        background: ${sidebarMainColor.hex};
        border-right: 1px solid rgba(0, 0, 0, 0.3);
      `,
    },
    button: {
      primary: css`
        ${buttonStyle}
      `,
      secondary: buttonStyle,
    },
    card: {
      wrapper: css`
        background-color: ${foundation.palette.onyx100.hex};
      `,
    },
  },
};
