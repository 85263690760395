import React from 'react';
import { IconProps } from '../../types/icons';

export const CrossIcon = ({
  size = '11',
  color = 'currentColor',
  ...props
}: IconProps) => (
  <svg viewBox="0 0 11 11" width={size} height={size} {...props}>
    <path
      fill={color}
      d="M2.2 1.19l3.3 3.3L8.8 1.2a.67.67 0 01.5-.2.75.75 0 01.7.7.66.66 0 01-.2.48L6.49 5.5 9.8 8.82c.13.126.202.3.2.48a.75.75 0 01-.7.7.67.67 0 01-.5-.2L5.5 6.51 2.21 9.8a.67.67 0 01-.5.2.75.75 0 01-.71-.71.66.66 0 01.2-.48L4.51 5.5 1.19 2.18A.66.66 0 011 1.7a.75.75 0 01.7-.7.67.67 0 01.5.19z"
    />
  </svg>
);
