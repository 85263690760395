import { TextArea as DefaultTextArea } from '@fcg-tech/regtech-components';
import { FieldInputProps } from 'formik';
import React from 'react';

interface TextAreaProps
  extends React.ComponentPropsWithRef<typeof DefaultTextArea> {
  field: FieldInputProps<string>;
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ field, ...props }: TextAreaProps, ref) => (
    <DefaultTextArea ref={ref} {...props} {...(field ?? {})} />
  ),
);
