import React from 'react';
import { useAsync } from 'react-async';
import { Loader } from '@fcg-tech/regtech-components';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import {
  loadCalendarEvents,
  loadPersonalSettingsToken,
  createPersonalSettingsToken,
  deletePersonalSettingsToken,
} from '../../api';
import { useTenant } from '../../states/tenantState';
import { CalendarExportModal, CalendarPage } from './components';
import { PageScrollingWrapper } from '../../components/Page';
import { ErrorMessage } from '../../components/ErrorBoundary';

export const CalendarContainer = () => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const tenantId = tenant.id;

  const [showCalendarExport, setShowCalendarExport] = React.useState(false);

  // promise fns
  const calendarEventsReq = useAsync({
    promiseFn: loadCalendarEvents,
    tenantId,
  });

  const tokenReq = useAsync({
    promiseFn: loadPersonalSettingsToken,
    tenantId,
  });

  // proxy fns
  const createTokenProxy = async (args) => {
    const { Location } = await createPersonalSettingsToken(...args);
    if (Location) {
      tokenReq.reload();
    }
  };

  const deleteTokenProxy = async (args) => {
    await deletePersonalSettingsToken(...args);
    tokenReq.reload();
  };

  // defer fns
  const createTokenReq = useAsync({ deferFn: createTokenProxy });
  const deleteTokenReq = useAsync({ deferFn: deleteTokenProxy });

  // callbacks
  const handleCreateToken = React.useCallback(() => {
    createTokenReq.run({ tenantId });
  }, [createTokenReq, tenantId]);

  const handleDeleteToken = React.useCallback(() => {
    deleteTokenReq.run({ tenantId });
  }, [deleteTokenReq, tenantId]);

  const handleOpenCalendarExport = React.useCallback(() => {
    setShowCalendarExport(true);
  }, [setShowCalendarExport]);

  const handleCloseCalendarExport = React.useCallback(() => {
    setShowCalendarExport(false);
  }, [setShowCalendarExport]);

  if (calendarEventsReq.isLoading) {
    return <Loader message={t(MessageKeys.LabelLoadingCalendar)} />;
  }

  if (calendarEventsReq.error) {
    return <ErrorMessage error={calendarEventsReq.error} />;
  }

  return (
    <PageScrollingWrapper>
      <CalendarPage
        events={calendarEventsReq.data.result}
        onExportCalendar={handleOpenCalendarExport}
      />
      {showCalendarExport && (
        <CalendarExportModal
          token={
            !(tokenReq.error || tokenReq.isLoading) ? tokenReq.data : undefined
          }
          onHide={handleCloseCalendarExport}
          onCreateToken={handleCreateToken}
          onDeleteToken={handleDeleteToken}
        />
      )}
    </PageScrollingWrapper>
  );
};
