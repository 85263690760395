import React from 'react';
import { useAsync } from 'react-async';
import { useNavigate, useMatch } from 'react-router-dom';
import {
  ConfirmDialog,
  EditProvider,
  Loader,
  useFlash,
} from '@fcg-tech/regtech-components';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import {
  loadReport,
  updateReport,
  deleteReport,
  loadReportAgreements,
  loadReportSuppliers,
  downloadReportCSVFile,
} from '../../api';
import { downloadBlob } from '../../utils/blobHelpers';
import { useTenant } from '../../states/tenantState';
import { routes } from '../../routes';
import { useErrorDialog } from '../../components/ErrorDialog';
import { EditReportPage } from './components';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { ErrorMessage } from '../../components/ErrorBoundary';

export const EditReportContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tenant = useTenant();
  const tenantId = tenant.id;
  const match = useMatch(routes.report);
  const { reportId } = match?.params ?? {};

  const [isSaving, setIsSaving] = React.useState(false);
  const [isEditEnabled, setEditEnabled] = React.useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);

  const showErrorDialog = useErrorDialog();
  const addFlash = useFlash();

  const loadReportReq = useAsync({
    promiseFn: loadReport,
    tenantId,
    reportId,
  });

  const loadReportAgreementsReq = useAsync({
    promiseFn: loadReportAgreements,
    tenantId,
  });

  const loadReportSuppliersReq = useAsync({
    promiseFn: loadReportSuppliers,
    tenantId,
  });

  const listData = React.useMemo(() => {
    const entity = loadReportReq?.data?.data?.entity;
    if (entity === 'suppliers') {
      return !loadReportSuppliersReq.error && !loadReportSuppliersReq.isLoading
        ? loadReportSuppliersReq.data
        : null;
    } else {
      return !loadReportAgreementsReq.error &&
        !loadReportAgreementsReq.isLoading
        ? loadReportAgreementsReq.data
        : null;
    }
  }, [loadReportReq, loadReportAgreementsReq, loadReportSuppliersReq]);

  const deleteReportProxy = async (args) => {
    try {
      await deleteReport(...args);
      addFlash({
        level: 'success',
        content: t(MessageKeys.EditReportSuccessfullyDeletedLabel),
      });
      navigate(constructUrl(routes.reports, { tenantId }));
    } catch (err) {
      showErrorDialog({
        title: t(MessageKeys.LabelSomethingWentWrong),
        message: t(MessageKeys.EditReportFailedDeleteLabel),
      });
    }
  };

  const updateReportProxy = async (args) => {
    setIsSaving(true);
    try {
      await updateReport(...args);
      setEditEnabled(false);
      addFlash({
        level: 'success',
        content: t(MessageKeys.EditReportSuccessfullyUpdatedLabel),
      });
      loadReportReq.reload();
    } catch (err) {
      showErrorDialog({
        title: t(MessageKeys.LabelSomethingWentWrong),
        message: t(MessageKeys.LabelErrorOccured),
      });
    } finally {
      setIsSaving(false);
    }
  };

  const downloadCSVFileProxy = async (args) => {
    const blob = await downloadReportCSVFile(...args);
    downloadBlob(blob, 'report.csv');
  };

  const updateReportReq = useAsync({ deferFn: updateReportProxy });
  const deleteReportReq = useAsync({ deferFn: deleteReportProxy });
  const downloadCSVFileReq = useAsync({ deferFn: downloadCSVFileProxy });

  const handleEdit = React.useCallback(() => {
    setEditEnabled(true);
  }, [setEditEnabled]);

  const handleCancel = React.useCallback(() => {
    setEditEnabled(false);
  }, [setEditEnabled]);

  const handleSave = React.useCallback(
    (updated) => {
      updateReportReq.run({ tenantId, reportId, report: updated.data });
    },
    [updateReportReq, tenantId, reportId],
  );

  const handleDelete = React.useCallback(() => {
    setShowDeleteConfirm(true);
  }, []);

  const handleDeleteChoice = React.useCallback(
    (choice) => {
      if (choice) {
        deleteReportReq.run({ tenantId, reportId });
      }
      setShowDeleteConfirm(false);
    },
    [tenantId, reportId, deleteReportReq],
  );

  const handleDownloadCSVFile = React.useCallback(
    (query, entity) => downloadCSVFileReq.run({ tenantId, query, entity }),
    [downloadCSVFileReq, tenantId],
  );

  if (loadReportReq.isLoading) {
    return <Loader message={t(MessageKeys.LabelLoadingReport)} />;
  }

  if (loadReportReq.error || updateReportReq.error) {
    return (
      <ErrorMessage error={loadReportReq.error || updateReportReq.error} />
    );
  }

  return (
    <>
      {showDeleteConfirm && (
        <ConfirmDialog
          title={t(MessageKeys.LabelConfirm)}
          body={t(MessageKeys.EditReportConfirmDeleteLabel)}
          confirmText={t(MessageKeys.LabelConfirmDelete)}
          cancelText={t(MessageKeys.LabelNo)}
          onChoice={handleDeleteChoice}
        />
      )}
      <EditProvider value={isEditEnabled}>
        <EditReportPage
          report={loadReportReq.data}
          listData={listData}
          isSaving={isSaving}
          onEdit={handleEdit}
          onCancel={handleCancel}
          onDelete={handleDelete}
          onSave={handleSave}
          onDownloadCSVFile={handleDownloadCSVFile}
        />
      </EditProvider>
    </>
  );
};
