import styled, { css } from 'styled-components';

export const Badge = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette?.MainColorAccent?.main ?? 'red'};
    color: ${theme.palette?.MainColorAccent?.mainContrast ?? 'white'};
    font-size: 1.1rem;
    font-weight: 800;
    padding: 0.1rem 1.1ch 0 1.1ch;
    height: 3.2ch;
    min-width: 3.2ch;
    border-radius: 1000px/1000px;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;
