import styled from 'styled-components';
import { H3 } from '@fcg-tech/regtech-components';

export const ValidationSummaryWrapper = styled.div`
  margin: 2.4rem 0;
`;

export const ValidationSummaryTitle = styled(H3)`
  font-weight: normal;
`;

export const ValidationSummarySubTitle = styled.div`
  font-size: 1.6rem;
  margin-top: 1.6rem;
`;

export const ValidationSummaryList = styled.ul`
  margin: 0;
  padding: 0 0 0 2.4rem;
`;

export const ValidationSummaryListItem = styled.li`
  margin-bottom: 1.4rem;
  font-size: 1.4rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`;
