import React from 'react';
import styled, { css } from 'styled-components';
import { excludeProps } from '../../utils';
import { InfoCircle } from '../InfoCircle';
import { ChevronRightIcon } from '../Icons';

export const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const IconContainer = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    right: 0.7rem;
    width: 2rem;
    bottom: 0;
    display: flex;
    align-items: center;
    pointer-events: none;
    color: ${theme?.palette?.MainBlack ?? '#000'};
    transition: all 0.15s linear;
  `}
`;

export const Container = styled.div.withConfig(
  excludeProps('disabled', 'isMenuOpen'),
)<{ disabled?: boolean; isMenuOpen?: boolean }>`
  ${({ theme, disabled, isMenuOpen }) => css`
    flex: 1;
    max-width: 100%;
    position: relative;
    cursor: ${disabled ? 'default' : 'pointer'};
    border: 1px solid
      ${disabled ? 'transparent' : theme.colors?.border ?? 'rgba(0,0,0,0.3)'};
    border-radius: 5px;
    padding: ${disabled ? '1rem 1rem 1rem 0' : '1rem 3rem 1rem 1rem'};
    font-family: ${theme.typography?.fontFamily ?? 'inherit'};
    font-size: 1.4rem;
    font-weight: 400;
    background: white;
    height: 4rem;
    outline: 0;
    display: flex;
    align-items: center;

    &:hover ${IconContainer} {
      color: ${theme.palette?.MainColor?.main ?? 'rgba(0,0,0,0.6)'};
    }

    ${theme?.elements?.select?.wrapper}
  `}
`;

export const SelectCurrentValue = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;

  ${({ theme }) => theme?.elements?.select?.currentValue}
`;

export const SelectCurrentValueInner = styled.span`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const NoOptionContainer = styled.li`
  text-align: center;
  white-space: nowrap;
  cursor: initial;
  padding: 1.5rem;
`;

export const StyledInfoCircle = styled(InfoCircle)`
  margin-left: 1rem;
`;

export const SelectMenuPositioner = styled.div`
  position: fixed;
  z-index: 1100;
`;

export const SelectMenuWrapper = styled.div.withConfig<{ bottom?: boolean }>(
  excludeProps('bottom'),
)`
  ${({ theme, bottom }) => css`
    font-size: 1.4rem;
    background-color: white;
    border: 1px solid ${theme.colors?.border ?? 'rgba(0,0,0,0.3)'};
    box-shadow: ${bottom
      ? '0 2px 4px 0 rgba(0, 0, 0, 0.2)'
      : '0 -2px 4px 0 rgba(0,0,0,0.2)'};
    border-radius: ${bottom ? '0 0 5px 5px' : '5px 5px 0 0'};
    transform: ${bottom ? 'translateY(-3px)' : 'translateY(3px)'};
    overflow: hidden;
    width: 100%;
  `}
`;

export const SelectTypeaheadContainer = styled.div.withConfig<{
  placement: 'bottom' | 'top';
}>(excludeProps('placement'))`
  ${({ theme, placement }) => css`
    padding: 0.5rem;
    ${placement === 'bottom'
      ? 'border-bottom'
      : 'border-top'}: 1px dashed ${theme.colors?.border ?? 'rgba(0,0,0,0.3)'};
  `}
`;

export const SelectScrollArea = styled.div`
  max-height: 30rem;
  overflow-y: auto;
  --webkit-overflow-scrolling: touch;
`;

export const SelectOptionList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: block;
`;

export const SelectStyledChevronRightIcon = styled(
  ({
    isMenuOpen,
    ...props
  }: { isMenuOpen?: boolean } & React.ComponentPropsWithoutRef<
    typeof ChevronRightIcon
  >) => <ChevronRightIcon {...props} />,
)`
  transform: rotate(${({ isMenuOpen }) => (isMenuOpen ? '270deg' : '90deg')});
  transition: transform 0.1s linear;
  width: 2rem;
  height: 2rem;
`;

export const Item = styled.li.withConfig(
  excludeProps('isSelected', 'isFocused'),
)<{ isSelected?: boolean; isFocused?: boolean }>`
  ${({ isSelected, isFocused }) => css`
    position: relative;
    padding: 1.2rem 1.4rem;
    display: flex;
    align-items: center;
    outline: 0;
    cursor: pointer;
    white-space: normal;
    background-color: ${isFocused
      ? '#eee'
      : isSelected
      ? '#ddd'
      : 'transparent'};

    &:hover {
      background-color: #eee;
    }
  `}
`;

export const SelectCreateItem = styled.li`
  position: relative;
  padding: 1.2rem 1.4rem;
  display: block;
  outline: 0;
  background-color: #eee;
  font-style: italic;
  white-space: normal;
`;
