import { useTranslation } from 'react-i18next';
import {
  BooleanDecision,
  BooleanDecisionFieldLabel,
  BooleanDecisionFieldWrapper,
  DynamicList,
  Modal,
  ModalBody,
  ModalButtonRow,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  PrimaryButton,
  ReactSelectOption,
  SecondaryButton,
  TextArea,
  TextField,
  useFlash,
  useFocus,
} from '@fcg-tech/regtech-components';
import { useFormik } from 'formik';
import update from 'immutability-helper';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useRecoilValue } from 'recoil';
import { useInternalEntityActions } from '../../api/hooks/internalEntitiesApiHooks';
import { Country } from '../../api/schema';
import { FormSelect } from '../../components/FormSelect';
import { ModalFormGroup, ModalFormLabel } from '../../components/modals';
import { countryState } from '../../states/countryState';
import { useTenant } from '../../states/tenantState';
import { MessageKeys } from '../../translations/messageKeys';
import { InternalEntity, InternalEntityFormikValues } from '../../types';
import { getTooltip } from '../../utils/tooltipHelpers';
import { InternalEntitiesCreateModalFormWrapper } from './InternalEntitiesCreateModal.styles';

interface InternalEntitiesCreateModalProps {
  onRequestClose: (internalEntityId?: string) => void;
}

export const InternalEntitiesCreateModal: FunctionComponent<
  InternalEntitiesCreateModalProps
> = ({ onRequestClose }) => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const addFlash = useFlash();
  const countries = useRecoilValue(countryState);
  const [loading, setLoading] = useState(false);
  const { createInternalEntity } = useInternalEntityActions(tenant.id);

  const ref = useFocus<HTMLInputElement>();

  const translateTooltip = useCallback(
    (value: string) => getTooltip(`internalEntity.${value}`),
    [],
  );

  const formik = useFormik<InternalEntityFormikValues>({
    initialValues: {
      name: '',
      description: '',
      corporateRegistrationNumber: '',
      legalEntityIdentifier: '',
      countryOfRegistration: '',
      registeredAddress: '',
      contactDetails: [],
      isUltimateParent: false,
      parentCompany: '',
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const internalEntity: InternalEntity = { ...values };
        const internalEntityId = await createInternalEntity(internalEntity);
        addFlash({
          level: 'success',
          content: t(MessageKeys.InternalEntitiesCreateInternalEntitySuccess),
        });
        onRequestClose(internalEntityId);
      } catch (err) {
        setLoading(false);
        addFlash({
          level: 'error',
          content: t(MessageKeys.InternalEntitiesCreateInternalEntityFailure),
        });
      }
    },
  });

  const handleHide = useCallback(
    () => onRequestClose(undefined),
    [onRequestClose],
  );

  const handleIsUltimateParentChange = React.useCallback(
    (event) => {
      if (event.target.checked) {
        let value = event.target.value === 'true';
        if (event.target.value === 'notRequired') {
          value = event.target.value;
        }
        formik.setFieldValue('isUltimateParent', value);
      }
    },
    [formik],
  );

  const handleIsUltimateParentClear = React.useCallback(
    () => formik.setFieldValue('isUltimateParent', null),
    [formik],
  );

  const handleContactDetailsSet = React.useCallback(
    (value: string, index: number) => {
      if (index === undefined) {
        formik.setFieldValue(
          'contactDetails',
          update(formik.values.contactDetails, { $push: [value] }),
        );
      } else {
        formik.setFieldValue(
          'contactDetails',
          update(formik.values.contactDetails, { [index]: { $set: value } }),
        );
      }
    },
    [formik],
  );

  const handleContactDetailsRemove = React.useCallback(
    (index: number) =>
      formik.setFieldValue(
        'contactDetails',
        update(formik.values.contactDetails, { $splice: [[index, 1]] }),
      ),
    [formik],
  );

  const handleCountryChange = useCallback(
    (option: ReactSelectOption<Country>) =>
      formik.setFieldValue('countryOfRegistration', option.value.isoAlpha2),
    [formik],
  );

  const countryOptions = useMemo<Array<ReactSelectOption<Country>>>(
    () =>
      countries?.map((country) => ({
        label: country.name,
        value: country,
      })),
    [countries],
  );

  const selectedCountryOption = useMemo<ReactSelectOption<Country>>(
    () =>
      countryOptions?.find(
        (o) => o.value.isoAlpha2 === formik.values.countryOfRegistration,
      ),
    [countryOptions, formik.values.countryOfRegistration],
  );

  return (
    <Modal onHide={handleHide}>
      <ModalHeader>
        <ModalTitle>
          {t(MessageKeys.InternalEntitiesCreateNewInternalEntityLabel)}
        </ModalTitle>
      </ModalHeader>
      <InternalEntitiesCreateModalFormWrapper onSubmit={formik.handleSubmit}>
        <ModalBody>
          <ModalFormGroup>
            <ModalFormLabel htmlFor="internal-entity-name">
              {t(MessageKeys.InternalEntityName)}
            </ModalFormLabel>
            <TextField
              id="internal-entity-name"
              name="name"
              ref={ref}
              value={formik.values.name}
              info={translateTooltip('name')}
              onChange={formik.handleChange}
            />
          </ModalFormGroup>
          <ModalFormGroup>
            <ModalFormLabel htmlFor="internal-entity-country-of-registration">
              {t(MessageKeys.InternalEntityCountryOfRegistration)}
            </ModalFormLabel>
            <FormSelect
              id="internal-entity-country-of-registration"
              value={selectedCountryOption}
              options={countryOptions}
              info={translateTooltip('countryOfRegistration')}
              onChange={handleCountryChange}
            />
          </ModalFormGroup>
          <ModalFormGroup>
            <ModalFormLabel htmlFor="internal-entity-registered-address">
              {t(MessageKeys.InternalEntityRegisteredAddress)}
            </ModalFormLabel>
            <TextArea
              id="internal-entity-registered-address"
              name="registeredAddress"
              value={formik.values.registeredAddress}
              info={translateTooltip('registeredAddress')}
              onChange={formik.handleChange}
            />
          </ModalFormGroup>
          <ModalFormGroup>
            <BooleanDecisionFieldWrapper>
              <BooleanDecisionFieldLabel htmlFor="internal-entity-is-ultimate-parent">
                {t(MessageKeys.InternalEntityUltimateParent)}
              </BooleanDecisionFieldLabel>
              <BooleanDecision
                value={formik.values.isUltimateParent}
                info={translateTooltip('isUltimateParent')}
                onChange={handleIsUltimateParentChange}
                onClear={handleIsUltimateParentClear}
              />
            </BooleanDecisionFieldWrapper>
          </ModalFormGroup>
          {!formik.values.isUltimateParent ? (
            <ModalFormGroup>
              <ModalFormLabel htmlFor="internal-entity-parent-company">
                {t(MessageKeys.InternalEntityParentCompany)}
              </ModalFormLabel>
              <TextField
                id="internal-entity-parent-company"
                name="parentCompany"
                value={formik.values.parentCompany}
                info={translateTooltip('parentCompany')}
                onChange={formik.handleChange}
              />
            </ModalFormGroup>
          ) : null}
          <ModalFormGroup>
            <ModalFormLabel htmlFor="internal-entity-corporate-registration-number">
              {t(MessageKeys.InternalEntityCorporateRegistrationNumber)}
            </ModalFormLabel>
            <TextField
              id="internal-entity-corporate-registration-number"
              name="corporateRegistrationNumber"
              value={formik.values.corporateRegistrationNumber}
              info={translateTooltip('corporateRegistrationNumber')}
              onChange={formik.handleChange}
            />
          </ModalFormGroup>
          <ModalFormGroup>
            <ModalFormLabel id="internal-entity-legal-entity-identifier">
              {t(MessageKeys.InternalEntityLegalEntityIdentifier)}
            </ModalFormLabel>
            <TextField
              id="internal-entity-legal-entity-identifier"
              name="legalEntityIdentifier"
              value={formik.values.legalEntityIdentifier}
              info={translateTooltip('legalEntityIdentifier')}
              onChange={formik.handleChange}
            />
          </ModalFormGroup>
          <ModalFormGroup>
            <ModalFormLabel htmlFor="internal-entity-contact-details">
              {t(MessageKeys.InternalEntityContactDetails)}
            </ModalFormLabel>
            <DynamicList
              value={formik.values.contactDetails}
              placeholder={t(
                MessageKeys.InternalEntityContactDetailsPlaceholder,
              )}
              info={translateTooltip('contactDetails')}
              onSetValue={handleContactDetailsSet}
              onRemoveValue={handleContactDetailsRemove}
            />
          </ModalFormGroup>
          <ModalFormGroup>
            <ModalFormLabel id="internal-entity-description">
              {t(MessageKeys.InternalEntityDescription)}
            </ModalFormLabel>
            <TextArea
              id="internal-entity-description"
              name="description"
              value={formik.values.description}
              info={translateTooltip('description')}
              onChange={formik.handleChange}
            />
          </ModalFormGroup>
        </ModalBody>
        <ModalFooter>
          <ModalButtonRow>
            <SecondaryButton type="button" onClick={handleHide}>
              {t(MessageKeys.LabelCancel)}
            </SecondaryButton>
            <PrimaryButton
              type="submit"
              loading={loading}
              disabled={!formik.values.name || loading}
            >
              {t(MessageKeys.LabelSubmit)}
            </PrimaryButton>
          </ModalButtonRow>
        </ModalFooter>
      </InternalEntitiesCreateModalFormWrapper>
    </Modal>
  );
};
