import React from 'react';
import { IconProps } from '../../types/icons';

export const ListIcon = ({
  size = '24',
  color = 'currentColor',
  ...props
}: IconProps) => (
  <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
    <path
      d="M21 6.99999H8C7.4 6.99999 7 6.59999 7 5.99999C7 5.39999 7.4 4.99999 8 4.99999H21C21.6 4.99999 22 5.39999 22 5.99999C22 6.59999 21.6 6.99999 21 6.99999Z"
      fill={color}
    />
    <path
      d="M21 13H8C7.4 13 7 12.6 7 12C7 11.4 7.4 11 8 11H21C21.6 11 22 11.4 22 12C22 12.6 21.6 13 21 13Z"
      fill={color}
    />
    <path
      d="M21 19H8C7.4 19 7 18.6 7 18C7 17.4 7.4 17 8 17H21C21.6 17 22 17.4 22 18C22 18.6 21.6 19 21 19Z"
      fill={color}
    />
    <path
      d="M3 6.99999C2.9 6.99999 2.9 6.99999 2.8 6.99999C2.7 6.99999 2.7 6.99999 2.6 6.89999C2.5 6.89999 2.5 6.79999 2.4 6.79999C2.3 6.79999 2.3 6.69999 2.3 6.69999C2.2 6.59999 2.1 6.49999 2.1 6.39999C2 6.29999 2 6.09999 2 5.99999C2 5.89999 2 5.69999 2.1 5.59999C2.2 5.49999 2.2 5.39999 2.3 5.29999C2.6 4.99999 3 4.89999 3.4 5.09999C3.5 5.19999 3.6 5.19999 3.7 5.29999C3.9 5.49999 4 5.69999 4 5.99999C4 6.29999 3.9 6.49999 3.7 6.69999C3.5 6.89999 3.3 6.99999 3 6.99999Z"
      fill={color}
    />
    <path
      d="M3 13C2.7 13 2.5 12.9 2.3 12.7C2.1 12.5 2 12.3 2 12C2 11.9 2 11.9 2 11.8C2 11.7 2 11.7 2.1 11.6C2.1 11.5 2.2 11.5 2.2 11.4C2.2 11.3 2.3 11.3 2.3 11.3C2.4 11.2 2.5 11.1 2.6 11.1C3 10.9 3.4 11 3.7 11.3L3.8 11.4C3.8 11.5 3.9 11.5 3.9 11.6C3.9 11.7 3.9 11.7 4 11.8C4 11.9 4 11.9 4 12C4 12.3 3.9 12.5 3.7 12.7C3.5 12.9 3.3 13 3 13Z"
      fill={color}
    />
    <path
      d="M3 19C2.7 19 2.5 18.9 2.3 18.7C2.1 18.5 2 18.3 2 18C2 17.9 2 17.7 2.1 17.6C2.2 17.5 2.2 17.4 2.3 17.3C2.7 16.9 3.3 16.9 3.7 17.3C3.9 17.5 4 17.7 4 18C4 18.3 3.9 18.5 3.7 18.7C3.5 18.9 3.3 19 3 19Z"
      fill={color}
    />
  </svg>
);
