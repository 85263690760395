import { useTranslation } from 'react-i18next';
import {
  FormRow,
  FormRowColumn,
  Modal,
  ModalBody,
  ModalButtonRow,
  ModalHeader,
  ModalTitle,
  PrimaryButton,
  ReactSelectOption,
  SecondaryButton,
  TextArea,
  TextField,
  useFlash,
  useFocus,
  useInput,
} from '@fcg-tech/regtech-components';
import React, { FunctionComponent, useCallback, useState } from 'react';
import Select from 'react-select';
import { ReportEntityEnum } from '../../api/schema';
import { useReportActions } from '../../api/hooks/reportsApiHooks';
import { ModalFormLabel, ModalFormWrapper } from '../../components/modals';
import { getTooltip } from '../../utils/tooltipHelpers';
import { MessageKeys } from '../../translations/messageKeys';

interface ReportsCreateModalProps {
  onRequestClose: (reportId?: string) => void;
}

export const ReportsCreateModal: FunctionComponent<ReportsCreateModalProps> = ({
  onRequestClose,
}) => {
  const { t } = useTranslation();
  const addFlash = useFlash();
  const { createReport } = useReportActions();
  const [loading, setLoading] = useState(false);
  const [name, handleNameChange] = useInput('');
  const [query, setQuery] = useState('');

  const ENTITY_ITEMS: Array<ReactSelectOption<ReportEntityEnum>> = [
    {
      value: ReportEntityEnum.Agreements,
      label: t(MessageKeys.ReportDetailsCardEntityAgreementsLabel),
    },
    {
      value: ReportEntityEnum.Suppliers,
      label: t(MessageKeys.ReportDetailsCardEntitySuppliersLabel),
    },
  ];

  const [entity, setEntity] = useState<ReactSelectOption<ReportEntityEnum>>(
    ENTITY_ITEMS[0],
  );

  const ref = useFocus<HTMLInputElement>();

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      setLoading(true);
      try {
        const reportId = await createReport({
          name: name,
          entity: entity?.value,
          query: query,
        });
        addFlash({
          level: 'success',
          content: t(MessageKeys.ReportsCreateReportSuccess),
        });
        onRequestClose(reportId);
      } catch (err) {
        setLoading(false);
        addFlash({
          level: 'error',
          content: t(MessageKeys.ReportsCreateReportFailure),
        });
      }
    },
    [addFlash, createReport, name, entity, query, onRequestClose, t],
  );

  const handleEntityChange = useCallback(
    (option: ReactSelectOption<ReportEntityEnum> | null) => {
      setEntity(option);
    },
    [],
  );

  const handleQueryChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) =>
      setQuery(event.target.value),
    [],
  );

  const handleHide = useCallback(
    () => onRequestClose(undefined),
    [onRequestClose],
  );

  return (
    <Modal onHide={handleHide}>
      <ModalHeader>
        <ModalTitle>{t(MessageKeys.ReportsCreateNewReportLabel)}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <ModalFormWrapper onSubmit={handleSubmit}>
          <FormRow>
            <ModalFormLabel htmlFor="report-name">
              {t(MessageKeys.ReportDetailsCardNameLabel)}
            </ModalFormLabel>
            <TextField
              id="report-name"
              ref={ref}
              value={name}
              onChange={handleNameChange}
            />
          </FormRow>
          <FormRow>
            <ModalFormLabel htmlFor="report-entity">
              {t(MessageKeys.ReportDetailsCardEntityLabel)}
            </ModalFormLabel>
            <FormRowColumn>
              <Select
                id="report-entity"
                value={entity}
                options={ENTITY_ITEMS}
                isMulti={false}
                onChange={handleEntityChange}
              />
            </FormRowColumn>
          </FormRow>
          <FormRow>
            <ModalFormLabel htmlFor="report-query">
              {t(MessageKeys.ReportDetailsCardQueryLabel)}
            </ModalFormLabel>
            <TextArea
              id="report-query"
              value={query}
              onChange={handleQueryChange}
              info={getTooltip('report.query')}
            />
          </FormRow>
          <ModalButtonRow>
            <SecondaryButton onClick={handleHide}>
              {t(MessageKeys.LabelCancel)}
            </SecondaryButton>
            <PrimaryButton
              type="submit"
              loading={loading}
              disabled={!name || loading}
            >
              {t(MessageKeys.LabelSubmit)}
            </PrimaryButton>
          </ModalButtonRow>
        </ModalFormWrapper>
      </ModalBody>
    </Modal>
  );
};
