import styled, { css } from 'styled-components';
import { mobile } from '../../theme';

export const GRID_AREA_SIDEBAR = 'sidebar';
export const GRID_AREA_HEADER = 'header';
export const GRID_AREA_TOOLBAR = 'toolbar';
export const GRID_AREA_MAIN = 'main';

export const GridLayout = styled.div`
  height: 100vh;
  width: 100vw;

  margin: 0;
  padding: 0;

  display: grid;

  grid-template-columns: auto 1fr;
  grid-template-rows: 6rem auto 1fr;

  grid-template-areas:
    '${GRID_AREA_SIDEBAR} ${GRID_AREA_HEADER}'
    '${GRID_AREA_SIDEBAR} ${GRID_AREA_TOOLBAR}'
    '${GRID_AREA_SIDEBAR} ${GRID_AREA_MAIN}';

  ${mobile(
    () => css`
      grid-template-columns: 1fr;

      grid-template-areas:
        '${GRID_AREA_HEADER}'
        '${GRID_AREA_TOOLBAR}'
        '${GRID_AREA_MAIN}';
    `,
  )}
`;

export const GridLayoutMainWrapper = styled.div`
  grid-area: ${GRID_AREA_MAIN};
  overflow: auto;
`;

export const GridLayoutToolbarWrapper = styled.div`
  grid-area: ${GRID_AREA_TOOLBAR};
`;

export const GridLayoutMainTabPanelWrapper = styled.div`
  grid-area: ${GRID_AREA_MAIN};
  height: 100%;
  min-width: 0;

  & > .tab-panel {
    height: 100%;
  }
`;
