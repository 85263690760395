import { classNames } from '@fcg-tech/regtech-utils';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';
import { useSideBar } from '../SideBar';
import { SideNavigationItemLink } from './SideNavigationItemLink';
import {
  SideNavigationItemButton,
  SideNavigationItemWrapper,
} from './SideNavigationItem.styles';

type NavLinkProps = React.ComponentPropsWithoutRef<typeof NavLink>;

interface SideNavigationItemProps extends Pick<NavLinkProps, 'end'> {
  href?: string;
  isActive?: boolean;
  title?: string;
  className?: string;
  noMobile?: boolean;
  neverActive?: boolean;
  onClick?: (event: React.MouseEvent) => void;
}

export const SideNavigationItem: FunctionComponent<PropsWithChildren<SideNavigationItemProps>> = ({
  href,
  end = true,
  title,
  isActive,
  noMobile,
  neverActive,
  className,
  children,
  onClick,
  ...rest
}) => {
  const { isHidden } = useSideBar();
  return (
    <SideNavigationItemWrapper
      className={classNames(className, noMobile && 'no-mobile')}
    >
      {href ? (
        <SideNavigationItemLink
          {...rest}
          to={href}
          end={!isHidden && end}
          title={title}
          className={classNames(
            neverActive && 'never-active',
            isActive && 'active',
          )}
          onClick={onClick}
        >
          {children}
        </SideNavigationItemLink>
      ) : onClick ? (
        <SideNavigationItemButton title={title} onClick={onClick}>
          {children}
        </SideNavigationItemButton>
      ) : (
        children
      )}
    </SideNavigationItemWrapper>
  );
};
