import { H3 } from '@fcg-tech/regtech-components';
import styled from 'styled-components';

export const NotificationsSettingsContainerHeading = styled(H3)`
  margin-bottom: 0.4rem;
`;

export const NotificationsSettingsContainerSection = styled.section`
  & + & {
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid #ccc;
  }
`;

export const NotificationsSettingsContainerDescription = styled.div`
  font-size: 1.3rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 2rem;
`;
