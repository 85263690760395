import { useEscapeKeyOld } from '@fcg-tech/regtech-utils';
import { reposition } from 'nanopop';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  PropsWithChildren,
} from 'react';
import { SelectOption } from '../../types/form';
import {
  defaultFilter,
  defaultSort,
  getScrollParent,
  itemKeyGetter,
  itemLabelGetter,
} from '../../utils';
import { TextField } from '../TextField';
import { CheckboxOption } from './CheckboxOption';
import { Option } from './Option';
import {
  Container,
  IconContainer,
  NoOptionContainer,
  OuterContainer,
  SelectCreateItem,
  SelectCurrentValue,
  SelectCurrentValueInner,
  SelectOptionList,
  SelectScrollArea,
  SelectStyledChevronRightIcon,
  SelectTypeaheadContainer,
  StyledInfoCircle,
  SelectMenuPositioner,
} from './Select.styles';
import { SelectMenu } from './SelectMenu';

const toggleAllowed = (lastToggle: number) => {
  return !lastToggle || Date.now() - lastToggle > 300;
};

export interface SelectPropsRender {
  (opts: {
    options: Array<SelectOption>;
    handleSelect: (key: string | number) => void;
    currentIndex: number;
  }): JSX.Element | Array<JSX.Element>;
}

type Value = string | number | Array<string | number>;

export interface SelectProps {
  id?: string;
  info?: string;
  isEditEnabled?: boolean;
  items: Array<SelectOption>;
  allowMultipleSelect?: boolean;
  typeahead?: boolean;
  requireSearchCharacters?: number;
  typeaheadFilter?: typeof defaultFilter;
  typeaheadPlaceholder?: string;
  typeaheadSort?: typeof defaultSort;
  currentValuePlaceholder?: string;
  value?: Value;
  className?: string;
  createOptionText?: string;
  noOptionsText?: string;
  typeAtLeastNrOfLettersText?: string;
  disableRepositionInterval?: boolean;
  formatOption?: (option: SelectOption) => JSX.Element | React.ReactNode;
  onClose?: () => void;
  onChange: (value: Value) => void;
  onTypeaheadChange?: (value: string) => void;
  onCreate?: (value: string) => void;
  children?: SelectPropsRender;
}

export const Select: FunctionComponent<PropsWithChildren<SelectProps>> = ({
  id,
  info,
  isEditEnabled = true,
  items: options,
  allowMultipleSelect,
  typeahead,
  requireSearchCharacters,
  typeaheadFilter,
  typeaheadSort,
  currentValuePlaceholder,
  typeaheadPlaceholder,
  value = '',
  className,
  createOptionText,
  noOptionsText,
  typeAtLeastNrOfLettersText,
  disableRepositionInterval,
  formatOption,
  onCreate,
  onClose,
  onChange,
  onTypeaheadChange,
  children,
}) => {
  let currentValueLabel = '';
  if (value && Array.isArray(value)) {
    currentValueLabel = options
      .filter((opt) => value.includes(itemKeyGetter(opt)))
      .map(itemLabelGetter)
      .join(', ');
  } else {
    const selectedOption = options.find(
      (option) => itemKeyGetter(option) === value && value !== null,
    );
    currentValueLabel = selectedOption ? itemLabelGetter(selectedOption) : '';
  }

  if (requireSearchCharacters && !typeahead) {
    console.warn(
      'Select: requireSearchCharacters ignored as typeahead = false',
    );
  }

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState<Array<SelectOption>>(
    requireSearchCharacters > 0 ? [] : null,
  );

  const lastToggle = useRef(0);
  const noExactFilterMatch = useRef(false);
  const typeaheadRef = useRef<HTMLInputElement>();
  const containerButtonRef = useRef<HTMLButtonElement>();
  const scrollAreaRef = useRef<HTMLDivElement>();
  const menuRef = useRef<HTMLDivElement>();

  const values = useMemo(() => (Array.isArray(value) ? value : []), [value]);

  useEffect(() => setFilteredOptions(undefined), [options]);

  const reset = useCallback(() => {
    setSearchTerm('');
    setCurrentIndex(null);
    setFilteredOptions(requireSearchCharacters > 0 ? [] : null);
    noExactFilterMatch.current = false;
  }, [requireSearchCharacters]);

  const handleFocus = useCallback(
    (event: React.FocusEvent) => {
      if (
        isEditEnabled &&
        event.target === containerButtonRef.current &&
        !isMenuOpen
      ) {
        setIsMenuOpen(true);
        setTimeout(() => typeaheadRef.current?.focus(), 50);
        lastToggle.current = Date.now();
      }
    },
    [isEditEnabled, isMenuOpen],
  );

  const handleCloseMenu = useCallback(() => {
    reset();
    setIsMenuOpen(false);
  }, [reset]);

  const handleBlur = useCallback(
    (event: React.FocusEvent) => {
      if (
        !event.relatedTarget ||
        !(
          containerButtonRef.current?.contains(event.relatedTarget as Node) ||
          menuRef.current?.contains(event.relatedTarget as Node)
        )
      ) {
        onClose?.();
        handleCloseMenu();
      }
    },
    [handleCloseMenu, onClose],
  );

  const handleToggleMenu = useCallback(
    (event: React.MouseEvent) => {
      if (
        isEditEnabled &&
        toggleAllowed(lastToggle.current) &&
        event.target === containerButtonRef.current
      ) {
        setIsMenuOpen((current) => {
          if (current) {
            reset();
          } else {
            setTimeout(() => typeaheadRef.current?.focus(), 50);
          }
          lastToggle.current = Date.now();
          return !current;
        });
      }
    },
    [isEditEnabled, reset],
  );

  const handleSelect = useCallback(
    (key: string | number) => {
      if (allowMultipleSelect) {
        if (values.includes(key)) {
          const newValues = [...values];
          newValues.splice(newValues.indexOf(key), 1);
          onChange?.(newValues);
        } else {
          onChange?.([...values, key]);
        }
      } else {
        onChange?.(key);
      }
      if (!allowMultipleSelect) {
        handleCloseMenu();
      }
    },
    [allowMultipleSelect, handleCloseMenu, onChange, values],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      // eslint-disable-next-line prefer-destructuring
      const length = (filteredOptions || options).length;
      if (event.key === 'ArrowDown') {
        event.stopPropagation();
        event.preventDefault();

        setCurrentIndex(
          (current: number | null) =>
            ((current === null ? -1 : current) + 1) % length,
        );
      } else if (event.key === 'ArrowUp') {
        event.stopPropagation();
        event.preventDefault();

        setCurrentIndex(
          (current: number | null) => ((current ?? 0) - 1 + length) % length,
        );
      } else if (event.key === 'Enter') {
        event.stopPropagation();
        event.preventDefault();

        if (
          searchTerm?.length &&
          currentIndex === null &&
          onCreate &&
          noExactFilterMatch.current
        ) {
          onCreate(searchTerm);
          handleCloseMenu();
        } else {
          if (searchTerm && !filteredOptions?.length) {
            return;
          }

          handleSelect(
            itemKeyGetter(
              (filteredOptions?.length ? filteredOptions : options)[
                currentIndex || 0
              ],
            ),
          );
        }
      }
    },
    [
      filteredOptions,
      options,
      searchTerm,
      currentIndex,
      onCreate,
      handleCloseMenu,
      handleSelect,
    ],
  );

  const handleSearch = useCallback(
    (event) => {
      const filterFn = typeaheadFilter || defaultFilter;
      const sortFn = typeaheadSort || defaultSort;
      const term = event.target.value;
      setSearchTerm(term);
      setCurrentIndex(null);
      if (!requireSearchCharacters || term.length >= requireSearchCharacters) {
        const termRe = new RegExp(term.replace(/\s+/g, '.+?'), 'i');
        noExactFilterMatch.current = term.length > 0;
        setFilteredOptions(
          options
            .filter((option) => {
              const score = filterFn(option, term, termRe);
              if (score === 1) {
                noExactFilterMatch.current = false;
              }
              return score;
            })
            .sort((a, b) => sortFn(a, b, term)),
        );
        onTypeaheadChange?.(term);
      } else {
        noExactFilterMatch.current = false;
        setFilteredOptions(undefined);
      }
    },
    [
      typeaheadFilter,
      typeaheadSort,
      requireSearchCharacters,
      options,
      onTypeaheadChange,
    ],
  );

  useEffect(() => {
    if (isMenuOpen) {
      const listener = (event: Event) => {
        setIsMenuOpen(false);
      };
      const scrollParent = getScrollParent(containerButtonRef.current);
      scrollParent.addEventListener('scroll', listener);
      window.addEventListener('resize', listener);
      return () => {
        scrollParent.removeEventListener('scroll', listener);
        window.removeEventListener('resize', listener);
      };
    }
    return undefined;
  }, [isMenuOpen]);

  const [placement, setPlacement] = useState<'bottom' | 'top'>('bottom');
  const popperRef = useRef<HTMLDivElement>();
  const finalOptions = filteredOptions ?? options;

  useLayoutEffect(() => {
    if (containerButtonRef.current && isMenuOpen && popperRef.current) {
      setPlacement((oldPlacement) => {
        const match = reposition(
          containerButtonRef.current,
          popperRef.current,
          {
            margin: 0,
            container: document.documentElement.getBoundingClientRect(),
            position: oldPlacement === 'bottom' ? 'bottom-start' : 'top-start',
          },
        );

        return match?.match(/^t/) ? 'top' : 'bottom';
      });
    }
  }, [isMenuOpen, finalOptions]);

  const handleEscapeKey = useCallback(() => {
    onClose?.();
    handleCloseMenu();
  }, [onClose, handleCloseMenu]);

  useEscapeKeyOld(handleEscapeKey);

  const Opt = allowMultipleSelect ? CheckboxOption : Option;

  return (
    <OuterContainer id={id} className={className}>
      <Container
        role="button"
        ref={containerButtonRef}
        title={currentValueLabel}
        isMenuOpen={isMenuOpen}
        disabled={!isEditEnabled}
        tabIndex={0}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onClick={handleToggleMenu}
        onKeyDown={handleKeyDown}
      >
        <SelectCurrentValue>
          <SelectCurrentValueInner>
            {currentValueLabel ?? <i>{currentValuePlaceholder}</i> ?? ''}
          </SelectCurrentValueInner>
        </SelectCurrentValue>
        <SelectMenuPositioner ref={popperRef}>
          <SelectMenu
            ref={menuRef}
            visible={isMenuOpen}
            containerRef={containerButtonRef}
            placement={placement}
            disableRepositionInterval={disableRepositionInterval}
          >
            {typeahead && placement === 'bottom' ? (
              <SelectTypeaheadContainer placement={placement}>
                <TextField
                  ref={typeaheadRef}
                  value={searchTerm}
                  placeholder={typeaheadPlaceholder ?? 'Type to search...'}
                  selectAllOnFocus
                  onChange={handleSearch}
                />
              </SelectTypeaheadContainer>
            ) : null}
            <SelectScrollArea ref={scrollAreaRef}>
              <SelectOptionList>
                {currentIndex === null &&
                noExactFilterMatch.current &&
                onCreate &&
                createOptionText ? (
                  <SelectCreateItem>
                    {createOptionText} <b>{searchTerm}</b>
                  </SelectCreateItem>
                ) : null}
                {children
                  ? children({
                      options: filteredOptions ?? options,
                      handleSelect,
                      currentIndex,
                    })
                  : (filteredOptions ?? options).map((option, index) => (
                      <Opt
                        key={itemKeyGetter(option)}
                        value={option}
                        isSelected={
                          allowMultipleSelect
                            ? values.includes(itemKeyGetter(option))
                            : itemKeyGetter(option) === value
                        }
                        isFocused={currentIndex === index}
                        children={
                          formatOption ? formatOption(option) : undefined
                        }
                        onClick={handleSelect}
                      />
                    ))}
                {searchTerm && !filteredOptions?.length && !options?.length ? (
                  <NoOptionContainer>
                    {noOptionsText ?? 'No options'}
                  </NoOptionContainer>
                ) : null}
                {!searchTerm && requireSearchCharacters > 0 ? (
                  <NoOptionContainer>
                    {typeAtLeastNrOfLettersText ??
                      `Type at least ${requireSearchCharacters} letters to search`}
                  </NoOptionContainer>
                ) : null}
              </SelectOptionList>
            </SelectScrollArea>
            {typeahead && placement === 'top' ? (
              <SelectTypeaheadContainer placement={placement}>
                <TextField
                  ref={typeaheadRef}
                  value={searchTerm}
                  placeholder={typeaheadPlaceholder ?? 'Type to search...'}
                  selectAllOnFocus
                  onChange={handleSearch}
                />
              </SelectTypeaheadContainer>
            ) : null}
          </SelectMenu>
        </SelectMenuPositioner>
        {isEditEnabled && (
          <IconContainer>
            <SelectStyledChevronRightIcon isMenuOpen={isMenuOpen} />
          </IconContainer>
        )}
      </Container>
      {info && <StyledInfoCircle info={info} />}
    </OuterContainer>
  );
};
