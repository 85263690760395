import styled from 'styled-components';

import React, { FunctionComponent } from 'react';
import { FilterIcon, IconButton } from '@fcg-tech/regtech-components';

const StyledIconButton = styled(IconButton)`
  width: 3rem;
  height: 3rem;
`;

interface FilterBarIconButtonProps {
  disabled?: boolean;
  toggled?: boolean;
  onClick?: () => void;
}

export const FilterBarIconButton: FunctionComponent<
  FilterBarIconButtonProps
> = ({ disabled, toggled, onClick }) => {
  return (
    <StyledIconButton
      disabled={disabled}
      toggled={toggled}
      id="filter-toggle-button"
      className="filter-toggle-button"
      onClick={onClick}
    >
      <FilterIcon size="18" />
    </StyledIconButton>
  );
};
