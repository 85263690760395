import styled from 'styled-components';
import { InfoCircle } from '@fcg-tech/regtech-components';

export const CurrencyAmountFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

export const CurrencyAmountFieldInner = styled.div`
  margin-right: 1rem;
  flex-grow: 1;
`;

export const CurrencyAmountFieldReadOnlyWrapper = styled.div`
  height: 3.8rem;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const CurrencyAmountFieldReadOnlyValue = styled.span`
  position: relative;
  font-family: ${({ theme }) => theme?.typography?.fontFamily ?? 'inherit'};
  font-size: 1.4rem;
  font-weight: 400;
  padding: 1rem 0;
  & + & {
    margin-left: 1rem;
  }
`;

export const StyledInfoCircle = styled(InfoCircle)`
  margin-left: auto;
`;
