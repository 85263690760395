import { hoist, fcg } from '@fcg-tech/regtech-design-system';
import { Theme } from '@fcg-tech/regtech-types';
import { environment } from './environments/environment';
import defaultTooltips from './tooltips.json';
import hoistTooltips from './tooltips-hoist.json';

/**
 * Client/company specific feature toggles and settings
 *
 * Centralize settings by looking at env _here_ and not all over the place.
 * Typed interface for all decisions on new clients.
 */
export interface Features {
  /**
   * Feature to doenload csv of users
   */
  DownloadUsersCsv: boolean;

  /**
   * Feature of related agreements in agreement form
   */
  RelatedAgreements: boolean;

  /**
   * Feature of enable user guide
   */
  UserGuide: boolean;

  AgreementStatusDefault: string | undefined;
  CabinetsListDescription: string;
  TagsListDescription: string;

  FieldErrors: {
    NonBlocking: {
      Agreement: {
        Details: {
          AgreementType: boolean;
          Supplier: boolean;
          PartyToAgreement: boolean;
          Term: {
            StartDate: boolean;
            EndDate: boolean;
          };
          BriefDescription: boolean;
        };
        PersonalData: {
          IsPersonalDataTransferred: boolean;
          IsPersonalDataProcessed: boolean;
          Role: boolean;
          DataProtectionIncluded: boolean;
        };
        ResolvabilityAssessment: {
          partOfResolutionGroup: boolean;
          resolutionGroupName: boolean;
          resolutionResilientAgreement: boolean;
          criticalBusinessFunction: boolean;
          criticalBusinessFunctionDetails: boolean;
          coreBusinessLine: boolean;
          degreeOfCriticality: boolean;
          jurisdiction: boolean;
          existenceOfAutomaticRenewalClauses: boolean;
          pricingStructurePredictable: boolean;
          conditionsOfPayment: boolean;
          qualitativeAndQuantitativePerformanceTarget: boolean;
          effectsOfBreaches: boolean;
          triggersForEarlyTermination: boolean;
          partiesAllowedToTerminate: boolean;
          estimatedTime: boolean;
          durationOfPostTerminationAssist: boolean;
          resolvabilityAlternativeServiceProviders: boolean;
        };
        AgreementAssessment: {
          Data: {
            CriticalOrImportant:{
              RiskAssessment: boolean;
            }
          }
        };
      };
    };
    Blocking: {
      Agreement: {
        ContractOwnerMandatory: boolean;
      };
    };
  };

  HoistIntstructionVideo: boolean;
  Theme: Theme;
  Tooltips: { [index: string]: string };
  ShowFileUploadAgreementAtStart: boolean;
  SideBarText: boolean;
  ResolutionFields: boolean;
}

const LandshypotekFeatures: Features = {
  FieldErrors: {
    NonBlocking: {
      Agreement: {
        Details: {
          AgreementType: false,
          Supplier: false,
          PartyToAgreement: false,
          Term: {
            StartDate: false,
            EndDate: false,
          },
          BriefDescription: false,
        },
        PersonalData: {
          IsPersonalDataTransferred: false,
          IsPersonalDataProcessed: false,
          Role: false,
          DataProtectionIncluded: false,
        },
        ResolvabilityAssessment: {
          partOfResolutionGroup: true,
          resolutionGroupName: true,
          resolutionResilientAgreement: true,
          criticalBusinessFunction: true,
          criticalBusinessFunctionDetails: true,
          coreBusinessLine: true,
          degreeOfCriticality: true,
          jurisdiction: true,
          existenceOfAutomaticRenewalClauses: true,
          pricingStructurePredictable: true,
          conditionsOfPayment: true,
          qualitativeAndQuantitativePerformanceTarget: true,
          effectsOfBreaches: true,
          triggersForEarlyTermination: true,
          partiesAllowedToTerminate: true,
          estimatedTime: true,
          durationOfPostTerminationAssist: true,
          resolvabilityAlternativeServiceProviders: true,
        },
        AgreementAssessment: {
          Data: {
            CriticalOrImportant:{
              RiskAssessment: false
            }
          }
        }
      },
    },
    Blocking: {
      Agreement: {
        ContractOwnerMandatory: false,
      },
    },
  },
  AgreementStatusDefault: 'notSigned',
  CabinetsListDescription: `Agreements are filed in Cabinets. Each Cabinet can hold one or several agreements.
    Access to Cabinets are managed by permissions. `,
  TagsListDescription: `A Tag is used to mark agreement(s) with specific text(s)
  element(s). Tags can be used to give Groups access to specific
  agreements that are marked with Tags they have been allowed to see
  or edit via their Role(s).`,
  DownloadUsersCsv: false,
  RelatedAgreements: true,
  UserGuide: true,
  HoistIntstructionVideo: false,
  Theme: fcg.theme,
  Tooltips: defaultTooltips,
  ShowFileUploadAgreementAtStart: false,
  SideBarText: true,
  ResolutionFields: true,
};

const DefaultFeatures: Features = {
  FieldErrors: {
    NonBlocking: {
      Agreement: {
        Details: {
          AgreementType: false,
          Supplier: false,
          PartyToAgreement: false,
          Term: {
            StartDate: false,
            EndDate: false,
          },
          BriefDescription: false,
        },
        PersonalData: {
          IsPersonalDataTransferred: false,
          IsPersonalDataProcessed: false,
          Role: false,
          DataProtectionIncluded: false,
        },
        ResolvabilityAssessment: {
          partOfResolutionGroup: false,
          resolutionGroupName: false,
          resolutionResilientAgreement: false,
          criticalBusinessFunction: false,
          criticalBusinessFunctionDetails: false,
          coreBusinessLine: false,
          degreeOfCriticality: false,
          jurisdiction: false,
          existenceOfAutomaticRenewalClauses: false,
          pricingStructurePredictable: false,
          conditionsOfPayment: false,
          qualitativeAndQuantitativePerformanceTarget: false,
          effectsOfBreaches: false,
          triggersForEarlyTermination: false,
          partiesAllowedToTerminate: false,
          estimatedTime: false,
          durationOfPostTerminationAssist: false,
          resolvabilityAlternativeServiceProviders: false,
        },
        AgreementAssessment: {
          Data: {
            CriticalOrImportant:{
              RiskAssessment: false
            }
          }
        }
      },
    },
    Blocking: {
      Agreement: {
        ContractOwnerMandatory: false,
      },
    },
  },
  AgreementStatusDefault: 'notSigned',
  CabinetsListDescription: `Agreements are filed in Cabinets. Each Cabinet can hold one or several agreements.
    Access to Cabinets are managed by permissions. `,
  TagsListDescription: `A Tag is used to mark agreement(s) with specific text(s)
  element(s). Tags can be used to give Groups access to specific
  agreements that are marked with Tags they have been allowed to see
  or edit via their Role(s).`,
  DownloadUsersCsv: false,
  RelatedAgreements: true,
  UserGuide: true,
  HoistIntstructionVideo: false,
  Theme: fcg.theme,
  Tooltips: defaultTooltips,
  ShowFileUploadAgreementAtStart: false,
  SideBarText: true,
  ResolutionFields: false,
};

const HoistFeatures: Features = {
  FieldErrors: {
    NonBlocking: {
      Agreement: {
        Details: {
          AgreementType: true,
          Supplier: true,
          PartyToAgreement: true,
          Term: {
            StartDate: true,
            EndDate: true,
          },
          BriefDescription: true,
        },
        PersonalData: {
          IsPersonalDataTransferred: true,
          IsPersonalDataProcessed: true,
          Role: true,
          DataProtectionIncluded: true,
        },
        ResolvabilityAssessment: {
          partOfResolutionGroup: false,
          resolutionGroupName: false,
          resolutionResilientAgreement: false,
          criticalBusinessFunction: false,
          criticalBusinessFunctionDetails: false,
          coreBusinessLine: false,
          degreeOfCriticality: false,
          jurisdiction: false,
          existenceOfAutomaticRenewalClauses: false,
          pricingStructurePredictable: false,
          conditionsOfPayment: false,
          qualitativeAndQuantitativePerformanceTarget: false,
          effectsOfBreaches: false,
          triggersForEarlyTermination: false,
          partiesAllowedToTerminate: false,
          estimatedTime: false,
          durationOfPostTerminationAssist: false,
          resolvabilityAlternativeServiceProviders: false,
        },
        AgreementAssessment: {
          Data: {
            CriticalOrImportant:{
              RiskAssessment: true
            }
          }
        }
      },
    },
    Blocking: {
      Agreement: {
        ContractOwnerMandatory: true,
      },
    },
  },
  AgreementStatusDefault: 'signedByBothParties',
  CabinetsListDescription: `All agreements should be placed in the General cabinet.
  If the agreement you want to upload is highly sensitive, kindly contact the Legal function.`,
  TagsListDescription: `Tag means business function. Agreements should be marked with the tag/business function that an agreement
     belongs to.`,
  DownloadUsersCsv: true,
  RelatedAgreements: false,
  UserGuide: false,
  HoistIntstructionVideo: true,
  Theme: hoist.theme,
  Tooltips: hoistTooltips,
  ShowFileUploadAgreementAtStart: true,
  SideBarText: true,
  ResolutionFields: false,
};

function getFeatures(): Features {
  if (environment.featureSet === 'hoist_finance') {
    return HoistFeatures;
  } else if (environment.featureSet === 'landshypotek') {
    return LandshypotekFeatures;
  } else {
    return DefaultFeatures;
  }
}

export default getFeatures();
