/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  DataTableColumnOptions,
  DataTableRowOptions,
} from '@fcg-tech/regtech-components';
import { parse } from 'json2csv';

export const downloadBlob = (blob: Blob, filename: string): void => {
  if ((window.navigator as any).msSaveOrOpenBlob) {
    (window.navigator as any).msSaveOrOpenBlob(blob, filename);
  } else {
    const anchor = document.createElement('a');
    anchor.href = window.URL.createObjectURL(blob);
    anchor.download = filename;
    document.body.appendChild(anchor);
    anchor.click();
    setTimeout(() => {
      document.body.removeChild(anchor);
      window.URL.revokeObjectURL(anchor.href);
    }, 0);
  }
};

export const createCSvFile = <P = any, ColumnKey = any>(
  rows: Array<DataTableRowOptions<P>>,
  columns: Array<DataTableColumnOptions<P, ColumnKey>>,
  withBOM = true,
  delimiter = ';',
) => {
  const data = rows?.map((row) =>
    columns.reduce((obj, column) => {
      const columnKey = column.columnKey as unknown as string;
      return {
        ...obj,
        [columnKey]: row.data?.[columnKey],
      };
    }, {}),
  );
  const fields = columns?.map<{ value: string; label: string }>(
    ({ columnKey, label }) => ({
      value: columnKey as unknown as string,
      label: typeof label === 'string' ? label : 'N/A',
    }),
  );
  const parsed = parse(data, { fields, withBOM, delimiter });
  return new Blob([parsed], { type: 'text/csv;charset=utf-8;' });
};
