import { addMonths, format, subMonths } from 'date-fns';
import React, { FunctionComponent, useCallback, PropsWithChildren } from 'react';
import { SecondaryButton } from '../Button';
import { ChevronRightIcon } from '../Icons';
import {
  CalendarNavigationContent,
  CalendarNavigationControls,
  CalendarNavigationMonth,
  CalendarNavigationNextButton,
  CalendarNavigationPrevButton,
  CalendarNavigationPrevIcon,
  CalendarNavigationWrapper,
  CalendarTodayButton,
} from './Calendar.styles';

interface CalendarNavigationProps {
  month: Date;
  todayLabel: string;
  toggleAgendaLabel?: string;
  onChange: (month: Date) => void;
  onToggleAgendaClick?: () => void;
  onReset?: () => void;
}

export const CalendarNavigation: FunctionComponent<PropsWithChildren<CalendarNavigationProps>> = ({
  month,
  todayLabel,
  toggleAgendaLabel,
  children,
  onToggleAgendaClick,
  onChange,
  onReset,
}) => {
  const handlePrevClick = useCallback(() => onChange(subMonths(month, 1)), [
    month,
    onChange,
  ]);
  const handleNextClick = useCallback(() => onChange(addMonths(month, 1)), [
    month,
    onChange,
  ]);

  return (
    <CalendarNavigationWrapper>
      {onReset ? (
        <CalendarTodayButton onClick={onReset}>
          {todayLabel}
        </CalendarTodayButton>
      ) : null}
      <CalendarNavigationPrevButton onClick={handlePrevClick}>
        <CalendarNavigationPrevIcon size="22" />
      </CalendarNavigationPrevButton>
      <CalendarNavigationMonth>
        {format(month, 'MMMM yyyy')}
      </CalendarNavigationMonth>
      <CalendarNavigationNextButton onClick={handleNextClick}>
        <ChevronRightIcon size="22" />
      </CalendarNavigationNextButton>
      <CalendarNavigationContent>{children}</CalendarNavigationContent>
      <CalendarNavigationControls>
        {toggleAgendaLabel && onToggleAgendaClick ? (
          <SecondaryButton onClick={onToggleAgendaClick}>
            {toggleAgendaLabel}
          </SecondaryButton>
        ) : null}
      </CalendarNavigationControls>
    </CalendarNavigationWrapper>
  );
};
