import React from 'react';
import PropTypes from 'prop-types';

import {
  TagPickerWrapper,
  Tag,
  TagLabel,
  TagCheckbox,
  TagCheckmark,
  ReadOnlyTag,
} from './TagPicker.styles';

const sortFn = (a, b, key = '') => {
  const aa = key ? a[key] : a;
  const bb = key ? b[key] : b;
  if (!aa) return -1;
  if (!bb) return 1;
  return aa.localeCompare(bb);
};

export const TagPicker = ({
  items,
  isEditEnabled,
  value: selectedItems,
  onSelectItem,
}) => {
  const menuItems = React.useMemo(
    () =>
      items.map(({ key, label }) => ({
        key,
        label,
        checked: selectedItems.includes(key),
        callback: (event) => {
          event.stopPropagation();
          event.preventDefault();
          onSelectItem(key);
        },
      })),
    [items, selectedItems, onSelectItem],
  );

  return (
    <TagPickerWrapper>
      {isEditEnabled
        ? menuItems
            .sort((a, b) => sortFn(a, b, 'label'))
            .map(({ key, label, checked, callback }) => (
              <Tag key={key} onClick={callback}>
                <TagCheckbox>
                  <TagCheckmark checked={checked} />
                </TagCheckbox>
                <TagLabel>{label}</TagLabel>
              </Tag>
            ))
        : selectedItems
            .sort(sortFn)
            ?.map((item) => <ReadOnlyTag key={item}>{item}</ReadOnlyTag>)}
    </TagPickerWrapper>
  );
};

TagPicker.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
      }),
    ]),
  ),
  isEditEnabled: PropTypes.bool,
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  onSelectItem: PropTypes.func,
};

TagPicker.defaultProps = {
  isEditEnabled: true,
  items: [],
  value: [],
  onSelectItem: null,
};
