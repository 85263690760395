import {
  ConfirmDialog,
  DocumentIcon,
  TrashIcon,
  useConfirmDialog,
} from '@fcg-tech/regtech-components';
import { useIsMountedRef } from '@fcg-tech/regtech-utils';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import {
  FileUploadZoneFileListItemButton,
  FileUploadZoneFileListItemButtons,
  FileUploadZoneFileListItemName,
  FileUploadZoneFileListItemNameWrapper,
  FileUploadZoneFileListItemWrapper,
} from './FileUploadZone.styles';

interface FileUploadZoneFileListItemProps {
  file: File;
  onRemoveClick?: (file: File) => Promise<boolean>;
}

export const FileUploadZoneFileListItem: FunctionComponent<
  FileUploadZoneFileListItemProps
> = ({ file, onRemoveClick }) => {
  const { t } = useTranslation();
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [showRemoveConfirm, handleRemoveConfirm, confirmRemove] =
    useConfirmDialog();

  const isMounted = useIsMountedRef();

  const handleRemoveClick = useCallback(async () => {
    if (await confirmRemove()) {
      setLoadingRemove(true);
      await onRemoveClick?.(file);
      if (isMounted.current) {
        setLoadingRemove(false);
      }
    }
  }, [confirmRemove, file, isMounted, onRemoveClick]);

  return (
    <FileUploadZoneFileListItemWrapper>
      {showRemoveConfirm ? (
        <ConfirmDialog
          title={t(MessageKeys.LabelConfirmAction)}
          body={t(MessageKeys.FileUploadZoneConfirmRemoveFileMessage)}
          confirmText={t(MessageKeys.LabelConfirmLabel)}
          cancelText={t(MessageKeys.LabelCancel)}
          onChoice={handleRemoveConfirm}
        />
      ) : null}
      <FileUploadZoneFileListItemNameWrapper>
        <DocumentIcon size="16" className="icon" />
        <FileUploadZoneFileListItemName>
          {file.name}
        </FileUploadZoneFileListItemName>
      </FileUploadZoneFileListItemNameWrapper>
      <FileUploadZoneFileListItemButtons>
        {onRemoveClick ? (
          <FileUploadZoneFileListItemButton
            loading={loadingRemove}
            onClick={handleRemoveClick}
          >
            <TrashIcon size="16" />
          </FileUploadZoneFileListItemButton>
        ) : null}
      </FileUploadZoneFileListItemButtons>
    </FileUploadZoneFileListItemWrapper>
  );
};
