import React from 'react';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import { environment } from '../../environments/environment';
import { getResponse } from '../../api/apiUtils';
import { MainLayoutPaddedContentWrapper } from '../MainLayout';
import { Error403 } from './Error403';
import { Error404 } from './Error404';
import {
  ErrorContent,
  ErrorHeading,
  ErrorInfo,
  ErrorPageWrapper,
  ErrorRequestId,
  ErrorStatusHeading,
} from './ErrorMessage.styles';

interface ErrorMessageProps {
  error?: Error;
}

export const ErrorMessage = ({ error }: ErrorMessageProps) => {
  const { t } = useTranslation();
  const response = getResponse(error);
  if (response) {
    if (response.status === 403) {
      return (
        <MainLayoutPaddedContentWrapper>
          <Error403 response={response} />
        </MainLayoutPaddedContentWrapper>
      );
    }
    if (response.status === 404) {
      return (
        <MainLayoutPaddedContentWrapper>
          <Error404 response={response} />
        </MainLayoutPaddedContentWrapper>
      );
    }
  }

  return (
    <MainLayoutPaddedContentWrapper>
      <ErrorPageWrapper>
        {response ? (
          <ErrorStatusHeading>Error {response.status}</ErrorStatusHeading>
        ) : null}
        <ErrorHeading>An error occurred</ErrorHeading>
        {environment.showErrorTraces && error?.stack ? (
          <ErrorContent>
            <pre>{error.stack}</pre>
          </ErrorContent>
        ) : null}
        {response && response.headers.get('apigw-requestid') ? (
          <>
            <ErrorInfo>{t(MessageKeys.ErrorReportToSupportMessage)}</ErrorInfo>
            <ErrorInfo className="center">
              <ErrorRequestId>
                {response.headers.get('apigw-requestid')}
              </ErrorRequestId>
            </ErrorInfo>
          </>
        ) : null}
      </ErrorPageWrapper>
    </MainLayoutPaddedContentWrapper>
  );
};
