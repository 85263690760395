import React from 'react';
import { useAsync } from 'react-async';
import { useNavigate, useMatch } from 'react-router-dom';
import {
  ConfirmDialog,
  EditProvider,
  Loader,
} from '@fcg-tech/regtech-components';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { loadRole, updateRole, deleteRole } from '../../api';
import { ErrorMessage } from '../../components/ErrorBoundary';
import { useTenant } from '../../states/tenantState';
import { routes } from '../../routes';
import { RolePage } from './components';

export const RoleContainer = () => {
  const navigate = useNavigate();
  const match = useMatch(routes.role);
  const { roleId } = match?.params ?? {};
  const tenant = useTenant();
  const tenantId = tenant.id;

  const [isSaving, setIsSaving] = React.useState(false);
  const [isEditEnabled, setIsEditEnabled] = React.useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);

  const roleReq = useAsync({
    promiseFn: loadRole,
    tenantId,
    roleId,
  });

  const updateRoleProxy = async (args) => {
    setIsSaving(true);
    try {
      await updateRole(...args);
      setIsSaving(false);
      roleReq.reload();
      setIsEditEnabled(false);
    } catch (err) {
      setIsSaving(false);
      throw err;
    }
  };

  const deleteRoleProxy = async (args) => {
    await deleteRole(...args);
    navigate(constructUrl(routes.roles, { tenantId }));
  };

  const updateRoleReq = useAsync({ deferFn: updateRoleProxy });
  const deleteRoleReq = useAsync({ deferFn: deleteRoleProxy });

  const handleSave = React.useCallback(
    (data) => {
      updateRoleReq.run({
        tenantId,
        roleId,
        role: { name: data.name, policy: JSON.parse(data.policy) },
      });
    },
    [updateRoleReq, tenantId, roleId],
  );

  const handleDelete = React.useCallback(() => setShowDeleteConfirm(true), []);

  const handleDeleteChoice = React.useCallback(
    (confirm) => {
      if (confirm) {
        deleteRoleReq.run({ tenantId, roleId });
      }
      setShowDeleteConfirm(false);
    },
    [deleteRoleReq, tenantId, roleId],
  );

  const handleCancel = React.useCallback(() => {
    setIsEditEnabled(false);
  }, []);

  const handleEdit = React.useCallback(() => {
    setIsEditEnabled(true);
  }, []);

  if (roleReq.isLoading) {
    return <Loader message="Loading role" />;
  }

  if (roleReq.error) {
    return <ErrorMessage error={roleReq.error} />;
  }

  return (
    <EditProvider value={isEditEnabled}>
      {showDeleteConfirm ? (
        <ConfirmDialog
          title="Confirm"
          body="Are you sure you want to delete this role?"
          onChoice={handleDeleteChoice}
          confirmText="Yes, delete it"
          cancelText="No"
        />
      ) : null}
      <RolePage
        role={roleReq.data}
        onEdit={handleEdit}
        onSave={handleSave}
        onCancel={handleCancel}
        onDelete={handleDelete}
        isSaving={isSaving}
      />
    </EditProvider>
  );
};
