import { createContext, useContext } from 'react';

export const ValidationErrorsContext = createContext<
  Array<{ message: string; field: string }>
>([]);

export const ValidationErrorsProvider = ValidationErrorsContext.Provider;

export const useValidationErrors = () => useContext(ValidationErrorsContext);

export const useNonBlockingFieldError = (name: string) => {
  const errors = useValidationErrors();
  return errors?.find((error) => error.field === name);
};
