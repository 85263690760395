import React from 'react';
import { Field, Label } from '@fcg-tech/regtech-components';
import { TextField } from '@fcg-tech/regtech-formik-components';

import { FormColumn, FormGroup, FormRow } from '../FormLayout';

export const RoleDetailsSubForm = () => (
  <>
    <FormRow>
      <FormColumn>
        <FormGroup>
          <Label column htmlFor="role-name">
            Name
          </Label>
          <Field id="role-name" name="name" component={TextField} type="text" />
        </FormGroup>
      </FormColumn>
    </FormRow>
  </>
);
