import { useTranslation } from 'react-i18next';
import { ReactSelectOption } from '@fcg-tech/regtech-components';
import {
  FilterClearButton,
  FilterRowLabel,
  FilterWidget,
} from '@fcg-tech/regtech-filter';
import { classNames, single } from '@fcg-tech/regtech-utils';
import { useCallback, useMemo } from 'react';
import Select from 'react-select';
import { MessageKeys } from '../../translations/messageKeys';
import { AgreementFilterValues, ArchivedOptions } from '../../types';

interface AgreementFilterArchivedStatusSelectProps {
  label: string;
  defaultArchivedOption?: ArchivedOptions;
}

export const AgreementFilterArchivedStatusSelect: FilterWidget<
  AgreementFilterArchivedStatusSelectProps,
  AgreementFilterValues
> = ({
  filter,
  filterId,
  label,
  clearLabel,
  disabled,
  defaultArchivedOption,
  onChange,
  onClear,
}) => {
  const { t } = useTranslation();

  const options = useMemo<Array<ReactSelectOption<ArchivedOptions>>>(
    () => [
      {
        label: t(MessageKeys.LabelAll),
        value: ArchivedOptions.All,
      },
      {
        label: t(MessageKeys.LabelNotArchived),
        value: ArchivedOptions.NotArchived,
      },
      {
        label: t(MessageKeys.LabelArchived),
        value: ArchivedOptions.Archived,
      },
    ],
    [t],
  );

  const selectedOption = useMemo(
    () =>
      options.find(
        (opt) =>
          opt.value === (single(filter.archived) ?? defaultArchivedOption),
      ) ?? null,
    [defaultArchivedOption, filter.archived, options],
  );

  const handleChange = useCallback(
    (option: ReactSelectOption<ArchivedOptions> | null) => {
      onChange?.(
        'archived',
        option?.value ?? ArchivedOptions.NotArchived,
        filterId,
      );
    },
    [filterId, onChange],
  );

  const handleClear = useCallback(() => onClear?.('archived'), [onClear]);

  return (
    <>
      <FilterRowLabel>
        {label}
        <FilterClearButton
          className={classNames(disabled && 'disabled')}
          onClick={disabled ? undefined : handleClear}
        >
          {clearLabel}
        </FilterClearButton>
      </FilterRowLabel>
      <Select
        options={options}
        value={selectedOption}
        onChange={handleChange}
      />
    </>
  );
};

AgreementFilterArchivedStatusSelect.isFilterWidget = true;
