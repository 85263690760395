import React from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { Field, Label } from '@fcg-tech/regtech-components';
import { TextField } from '@fcg-tech/regtech-formik-components';

import { FormColumn, FormGroup, FormRow } from '../FormLayout';

export const RoleParametersSubForm = () => {
  const { values } = useFormikContext();

  return (
    <>
      <FormRow>
        <FormColumn>
          <FormGroup>
            <Label>Parameters:</Label>
          </FormGroup>
        </FormColumn>
      </FormRow>
      <FieldArray
        name="parameters"
        render={() =>
          values?.parameters?.map(({ id: parameterId }, index) => (
            <FormRow key={parameterId}>
              <FormColumn>
                <FormGroup>
                  <Label column htmlFor={`parameters-${index}-value`}>
                    {parameterId}
                  </Label>
                  <Field
                    name={`parameters.${index}.value`}
                    id={`parameters-${index}-value`}
                    component={TextField}
                    type="text"
                  />
                </FormGroup>
              </FormColumn>
            </FormRow>
          )) || null
        }
      />
    </>
  );
};
