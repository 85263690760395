import styled, { css } from 'styled-components';
import { excludeProps } from '../../utils';
import { SecondaryButton } from '../Button';
import { SpinningLoadingIcon, TriangleExclamationIcon } from '../Icons';

export const UploadZoneWrapper = styled.div`
  background-color: white;
  display: flex;
  width: 100%;
  flex: 1;
`;

export const UploadZoneButton = styled(SecondaryButton)`
  min-width: 10rem;
  margin-right: 1rem;
`;

export const UploadZoneInner = styled.div.withConfig(
  excludeProps('isDragActive', 'isDragOverViewport'),
)<{ isDragActive?: boolean; isDragOverViewport?: boolean }>`
  ${({ isDragActive, isDragOverViewport, theme }) => css`
    border: 2px dashed
      ${isDragActive
        ? theme?.palette?.MainColor?.main ?? '#bbb'
        : isDragOverViewport
        ? 'rgba(0,0,0,0.1)'
        : 'transparent'};
    padding: ${isDragOverViewport ? '1.6rem' : '0'};
    flex: 1;
    display: flex;
  `}
`;

export const UploadZoneMessage = styled.p`
  font-size: 1.4rem;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const UploadZoneButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  width: 100%;
  align-items: center;
`;

export const UploadZoneHint = styled.div`
  margin: 1rem 0;
  color: rgba(0, 0, 0, 0.7);
`;

export const UploadZoneError = styled(UploadZoneHint)`
  color: red;
  display: flex;
  align-items: center;
`;

export const UploadErrorIcon = styled(TriangleExclamationIcon).attrs({
  size: '16',
})`
  margin-right: 0.6rem;
`;

export const UploadInProgressMessage = styled.div`
  display: flex;
  align-items: center;
`;

export const UploadInProgessSpinner = styled(SpinningLoadingIcon).attrs({
  size: '20',
})`
  margin-left: 1rem;
`;
