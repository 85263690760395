import React from 'react';
import styled from 'styled-components';
import { QuestionIcon } from '../Icons';

export const InfoCircleIconWrapper = styled.div`
  width: 1.6rem;
  height: 1.6rem;
  @media print {
    display: none;
  }
`;
