import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  PageLayout,
  PageLayoutPaddedContent,
  PageHeader,
  useEditContext,
  SecondaryButton,
  PrimaryButton,
  PageHeaderActionButton,
  AccessControl,
} from '@fcg-tech/regtech-components';
import { useTranslation } from 'react-i18next';
import { agreementPropType, supplierPropType } from '../../../propTypes';
import { MessageKeys } from '../../../translations/messageKeys';
import { DirtyFormCheck } from '../../../components/DirtyFormCheck';
import { ReportDetailsCard } from '../../../components/editorsections/reports/ReportDetailsCard';
import { Trash2 } from 'lucide-react';

export const EditReportPage = ({
  report,
  listData,
  isSaving,
  onEdit,
  onCancel,
  onSave,
  onDelete,
  onDownloadCSVFile,
}) => {
  const { t } = useTranslation();
  const isEditEnabled = useEditContext();

  const pageTitle = React.useMemo(
    () => report.data.name || t(MessageKeys.LabelUnnamed),
    [report.data.name, t],
  );

  return (
    <Formik initialValues={report} onSubmit={onSave}>
      {({ handleSubmit, values, dirty, resetForm }) => (
        <PageLayout>
          <DirtyFormCheck dirty={dirty && isEditEnabled} />
          <PageHeader title={pageTitle}>
            {isEditEnabled ? (
              <>
                <SecondaryButton
                  onClick={() => {
                    resetForm();
                    onCancel();
                  }}
                >
                  {t(MessageKeys.LabelCancel)}
                </SecondaryButton>
                <PrimaryButton
                  onClick={handleSubmit}
                  loading={isSaving}
                  disabled={!dirty || isSaving}
                >
                  {t(MessageKeys.LabelSubmit)}
                </PrimaryButton>
              </>
            ) : (
              <>
                <AccessControl
                  permissions={report.permissions}
                  requiredPermissions={['oasys:ReportDelete']}
                >
                  <PageHeaderActionButton onClick={onDelete}>
                    <Trash2 size="24" />
                  </PageHeaderActionButton>
                </AccessControl>
                <AccessControl
                  permissions={report.permissions}
                  requiredPermissions={['oasys:ReportEdit']}
                >
                  <PrimaryButton onClick={onEdit}>
                    {t(MessageKeys.LabelEdit)}
                  </PrimaryButton>
                </AccessControl>
              </>
            )}
          </PageHeader>
          <PageLayoutPaddedContent>
            <form onSubmit={handleSubmit}>
              <ReportDetailsCard
                report={values}
                entityJson={listData}
                onDownloadCSVFile={onDownloadCSVFile}
              />
            </form>
          </PageLayoutPaddedContent>
        </PageLayout>
      )}
    </Formik>
  );
};

EditReportPage.propTypes = {
  report: PropTypes.shape({
    data: PropTypes.shape({
      name: PropTypes.string,
      query: PropTypes.string,
    }),
    permissions: PropTypes.arrayOf(PropTypes.string),
  }),
  // TODO
  listData:
    PropTypes.arrayOf(agreementPropType) || PropTypes.arrayOf(supplierPropType),
  isSaving: PropTypes.bool,
  onEdit: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  onDownloadCSVFile: PropTypes.func,
};

EditReportPage.defaultProps = {
  report: {},
  listData: [],
  isSaving: false,
  onEdit: null,
  onCancel: null,
  onSave: null,
  onDelete: null,
  onDownloadCSVFile: null,
};
