import { EmptyPropsWithChildren } from '@fcg-tech/regtech-types';
import React, { FunctionComponent, useCallback, useState, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import { ErrorDialog } from './ErrorDialog';

interface ErrorDialogData {
  title: string;
  message: string;
  closeText?: string;
  onClose?: (event: React.MouseEvent<unknown>) => void;
}

type ErrorDialogContextProps = (data: ErrorDialogData) => void;

export const ErrorDialogContext = React.createContext<ErrorDialogContextProps>(
  () => null,
);

export const ErrorDialogManager: FunctionComponent<PropsWithChildren<EmptyPropsWithChildren>> = ({ children }) => {
  const { t } = useTranslation();
  const [error, setError] = useState<ErrorDialogData | null>(null);

  const showErrorDialog = useCallback(
    (data: ErrorDialogData) => setError(data),
    [],
  );

  const handleClose = useCallback(
    (event: React.MouseEvent<unknown>) => {
      setError(null);
      error?.onClose?.(event);
    },
    [error],
  );

  return (
    <ErrorDialogContext.Provider value={showErrorDialog}>
      {children}
      {error ? (
        <ErrorDialog
          title={error.title}
          message={error.message}
          closeText={error?.closeText ?? t(MessageKeys.LabelOk)}
          onClose={handleClose}
        />
      ) : null}
    </ErrorDialogContext.Provider>
  );
};

export const useErrorDialog = () => React.useContext(ErrorDialogContext);
