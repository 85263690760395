import { PrimaryButton } from '@fcg-tech/regtech-components';
import styled from 'styled-components';

export const NotificationsActionBar = styled.div`
  position: sticky;
  top: -1px;
  background-color: white;
  padding: 1.5rem 0 1.5rem 0.2rem;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.sticky-floating {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
`;

export const NotificationsActionBarSide = styled.div`
  display: flex;
  align-items: center;
`;

export const NotificationsActionBarMarkAsReadButton = styled(PrimaryButton)`
  margin-left: 1.4rem;
`;
export const NotificationsListWrapper = styled.div`
  margin-top: 1.6rem;
  padding-bottom: 3rem;
`;

export const NotificationPageWrapper = styled.div`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`;
