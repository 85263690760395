import { H1, mobile, PaginationWrapper } from '@fcg-tech/regtech-components';
import styled, { css } from 'styled-components';

export const PaginatedTableHeaderWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    align-items: center;
    overflow: hidden;

    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      flex-wrap: wrap;
    }
  `}
`;

export const PaginatedTableHeaderCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
`;

export const RightArticleTableHeaderCell = styled(PaginatedTableHeaderCell)`
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  min-width: initial;
  margin-left: 2rem;

  & > ${PaginationWrapper} {
    margin-right: 1.6rem;
  }

  ${mobile(
    () => css`
      margin-top: 0.6rem;
      margin-left: 0;
      justify-content: space-between;
      width: 100%;
    `,
  )}
`;

export const PaginatedTableHeaderHeading = styled(H1)`
  margin: 0;
  max-width: 100%;
  overflow: hidden;
`;

export const PaginatedTableHeaderSummary = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  margin-right: 1rem;
  white-space: nowrap;
  min-height: 32px;
  ${mobile(
    () => css`
      font-size: 1.1rem;
    `,
  )}
`;
