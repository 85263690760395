import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Currency, Status } from '../api/schema';
import { Settings } from '../types';
import { STATUS_SELECT_ITEM } from '../constants';
import { Amount } from '@fcg-tech/regtech-types/oasys';

export const formatZonedDateTime = (date: string | Date): string => {
  if (!date) {
    return '';
  }
  try {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const zonedDate = utcToZonedTime(date, timeZone);
    return format(zonedDate, 'yyyy-MM-dd HH:mm:ss');
  } catch (error) {
    console.error(error);
    return 'N/A';
  }
};

export const formatDate = (date: Date | string, settings?: Settings) => {
  if (!date) {
    return '';
  }
  try {
    return format(
      typeof date === 'string' ? new Date(date) : date,
      settings?.dateFormat ?? 'yyyy-MM-dd',
    );
  } catch (err) {
    console.error(err);
    return 'N/A';
  }
};

export const formatBoolean = (value: boolean | undefined) =>
  value === undefined ? '' : value === true ? 'Yes' : 'No';

export const formatStatus = (status: Status | string | undefined) => {
  if (!status) {
    return '';
  }
  return STATUS_SELECT_ITEM.find(({ key }) => key === status)?.label ?? '';
};

export const formatCurrencyAmount = (
  value: Amount,
  currencies: Array<Currency>,
) => {
  if (!value) {
    return '';
  }

  return `${value.amount} ${
    currencies.find((currency) => currency.code === value.currency)?.name
  }`;
};
