/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import update from 'immutability-helper';
import { classNames, debounce } from '@fcg-tech/regtech-utils';
import { SpinningLoadingIcon } from '../Icons/SpinningLoadingIcon';
import {
  guessMinWidths,
  clampPercentageWidths,
  DataTableColumnOptions,
  DataTableRowOptions,
  DataTableSortOrder,
  DataTableColumnKeys,
  addMissingWidths,
  reorderColumns,
} from './dataTableUtils';
import { DataTableHeader } from './DataTableHeader';
import {
  DataTableWrapper,
  DataTableTableWrapper,
  DataTableLoadingWrapper,
  DataTableInnerScrollWrapper,
  DataTableNoItemsMessage,
  Table,
  DataTableStickyWrapper,
} from './DataTable.styles';
import { DataTableRow, DataTableRowProps } from './DataTableRow';

export interface DataTableProps<P = any, CK = any> {
  rows: Array<DataTableRowOptions<P>>;
  columns: Array<DataTableColumnOptions<P, CK>>;
  loading?: boolean;
  readOnly?: boolean;
  emptyMessage?: string;
  stickyHeader?: boolean;
  checkboxSelection?: boolean;
  hideSelectAllCheckbox?: boolean;
  noScroll?: boolean;
  sortOrder?: DataTableSortOrder<CK>;
  rowComponent?: FunctionComponent<DataTableRowProps<P, CK>>;
  scrollRef?: React.MutableRefObject<HTMLDivElement>;
  tableBodyRef?: React.MutableRefObject<HTMLTableSectionElement>;
  children?: (
    row: DataTableRowOptions<P>,
    props: Omit<DataTableRowProps<P, CK>, 'row'>,
  ) => JSX.Element;
  onRowSelectionChange?: (rows: Array<DataTableRowOptions<P>>) => void;
  onRowClick?: (rowId: string) => void;
  onSortOrderChange?: (sortOrder: DataTableSortOrder<CK>) => void;
  onColumnWidthsChange?: (
    updatedColumns: Array<DataTableColumnOptions<P, CK>>,
  ) => void;
  onColumnOrderChange?: (
    updatedColumns: Array<DataTableColumnOptions<P, CK>>,
    oldIndex: number,
    newIndex: number,
  ) => void;
}

export const DataTable = function <P = any, CK = any>({
  rows,
  columns: initialColumns,
  loading,
  readOnly = false,
  emptyMessage,
  stickyHeader,
  checkboxSelection,
  hideSelectAllCheckbox,
  sortOrder,
  rowComponent,
  scrollRef,
  tableBodyRef,
  noScroll,
  children,
  onSortOrderChange,
  onColumnWidthsChange,
  onRowSelectionChange,
  onRowClick,
  onColumnOrderChange,
}: DataTableProps<P, CK>): JSX.Element {
  const tableWrapperRef = useRef<HTMLDivElement>();

  const [columns, setColumns] = useState(addMissingWidths<P>(initialColumns));
  const initialSetColumns = useRef(false);
  const hardWidths = useMemo(
    () => columns.reduce((w, c) => w + (c.resizable ? 0 : c.width), 0),
    [columns],
  );

  const debouncedOnColumnWidthsChange = useMemo(
    () => debounce(onColumnWidthsChange, 500),
    [onColumnWidthsChange],
  );

  useEffect(() => {
    if (
      initialColumns.length !== columns.length ||
      !initialSetColumns.current
    ) {
      setColumns(
        clampPercentageWidths(
          guessMinWidths(
            addMissingWidths<P>(
              checkboxSelection
                ? [
                    {
                      columnKey: DataTableColumnKeys.CheckboxSelection,
                      resizable: false,
                      sortable: false,
                      fixed: hideSelectAllCheckbox,
                      width: 50,
                    },
                    ...initialColumns,
                  ]
                : initialColumns,
            ),
          ),
          tableWrapperRef.current?.getBoundingClientRect()?.width,
        ),
      );
      initialSetColumns.current = true;
    }
  }, [
    checkboxSelection,
    columns.length,
    initialColumns,
    hideSelectAllCheckbox,
  ]);

  const handleResizeColumn = useCallback(
    (columnKey: string, pxDiff: number) =>
      setColumns((old) => {
        const rect = tableWrapperRef.current.getBoundingClientRect();
        const distWidth = rect.width - hardWidths;
        const ratio = pxDiff / distWidth;
        const percentage = ratio * 100;
        const index = old.findIndex((col) => col.columnKey === columnKey);
        let next = old;
        if (next[index]) {
          if (next[index]) {
            if (
              pxDiff < 0 &&
              ((next[index].width + percentage) / 100) * distWidth <
                next[index].minWidth
            ) {
              return old;
            }
            if (
              pxDiff > 0 &&
              next[index + 1] &&
              ((next[index + 1].width - percentage) / 100) * distWidth <
                next[index + 1].minWidth
            ) {
              return old;
            }

            next = update(next, {
              [index]: { width: { $set: next[index].width + percentage } },
            });
            if (next[index + 1]) {
              next = update(next, {
                [index + 1]: {
                  width: { $set: next[index + 1].width - percentage },
                },
              });
            }
          }
        }
        const nextColumns = clampPercentageWidths(next);
        debouncedOnColumnWidthsChange?.(nextColumns);
        return nextColumns;
      }),
    [debouncedOnColumnWidthsChange, hardWidths],
  );

  const handleSelectAll = useCallback(
    (selectAll: boolean) => {
      let next = rows;
      next.forEach((_, index) => {
        next = update(next, {
          [index]: {
            selected: { $set: selectAll },
          },
        });
      });
      onRowSelectionChange?.(next);
    },
    [onRowSelectionChange, rows],
  );

  const handleSelectRow = useCallback(
    (rowId: string, checked: boolean) => {
      let next = rows;
      const index = next?.findIndex((row) => row.id === rowId);
      next = update(next, {
        [index]: {
          selected: { $set: checked },
        },
      });
      onRowSelectionChange?.(next);
    },
    [onRowSelectionChange, rows],
  );

  const handleColumnOrderChange = useCallback(
    (oldIndex: number, newIndex: number) => {
      const updated = reorderColumns(columns, oldIndex, newIndex);
      onColumnOrderChange?.(updated, oldIndex, newIndex);
      setColumns(updated);
    },
    [columns, onColumnOrderChange],
  );

  const Row = rowComponent ?? DataTableRow;
  const rowProps = useMemo(
    () => ({
      columns,
      readOnly,
      onClick: onRowClick,
      onSelect: handleSelectRow,
    }),
    [columns, handleSelectRow, onRowClick, readOnly],
  );

  return (
    <DataTableWrapper data-kind="datatable">
      {loading ? (
        <DataTableLoadingWrapper>
          <SpinningLoadingIcon size="80px" />
        </DataTableLoadingWrapper>
      ) : (
        <DataTableTableWrapper ref={tableWrapperRef}>
          {stickyHeader ? (
            <DataTableStickyWrapper>
              <Table>
                <DataTableHeader
                  columns={columns}
                  readOnly={readOnly}
                  sortOrder={sortOrder}
                  allSelected={rows?.every((row) => row.selected)}
                  partiallySelected={rows?.some((row) => row.selected)}
                  onSortOrderChange={onSortOrderChange}
                  onSelectAll={
                    hideSelectAllCheckbox ? undefined : handleSelectAll
                  }
                  onColumnResize={handleResizeColumn}
                  onColumnOrderChange={
                    onColumnOrderChange ? handleColumnOrderChange : null
                  }
                />
              </Table>
            </DataTableStickyWrapper>
          ) : null}
          <DataTableInnerScrollWrapper
            ref={scrollRef}
            className={classNames(noScroll && 'no-scroll')}
          >
            <Table>
              <DataTableHeader
                hidden={stickyHeader}
                columns={columns}
                readOnly={readOnly}
                sortOrder={sortOrder}
                allSelected={rows?.every((row) => row.selected)}
                partiallySelected={rows?.some((row) => row.selected)}
                onSortOrderChange={onSortOrderChange}
                onSelectAll={
                  hideSelectAllCheckbox ? undefined : handleSelectAll
                }
                onColumnResize={stickyHeader ? undefined : handleResizeColumn}
                onColumnOrderChange={
                  onColumnOrderChange ? handleColumnOrderChange : null
                }
              />
              <tbody ref={tableBodyRef}>
                {rows?.map((row) =>
                  children ? (
                    children(row, rowProps)
                  ) : (
                    <Row
                      key={row.id}
                      row={row}
                      columns={columns}
                      sortOrder={sortOrder}
                      readOnly={readOnly}
                      onClick={onRowClick}
                      onSelect={handleSelectRow}
                    />
                  ),
                )}
              </tbody>
            </Table>
            {!rows?.length ? (
              <DataTableNoItemsMessage>{emptyMessage}</DataTableNoItemsMessage>
            ) : null}
          </DataTableInnerScrollWrapper>
        </DataTableTableWrapper>
      )}
    </DataTableWrapper>
  );
};
