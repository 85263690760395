import React, { FunctionComponent, PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
  container?: string | HTMLElement;
  disable?: boolean;
}

export const Portal: FunctionComponent<PropsWithChildren<PortalProps>> = ({
  children,
  container,
  disable,
}) => {
  if (disable) {
    if (React.isValidElement(children)) {
      return React.cloneElement(children);
    }
    if (typeof children === 'object') {
      return null;
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  let containerEl =
    typeof container === 'string'
      ? document.querySelector(container)
      : container;
  if (!container && typeof container === 'string') {
    containerEl = document.createElement('div');
    containerEl.setAttribute('id', container.replace('#', ''));
  }

  return ReactDOM.createPortal(children, containerEl ?? document.body);
};
