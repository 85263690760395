/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/ban-types */
// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../@types/react-table/index.d.ts" />

import { classNames } from '@fcg-tech/regtech-utils';
import { PropsWithChildren, useCallback, useMemo } from 'react';
import { CommonRowProps, Row } from './types';

export interface DataTableRowProps<D extends object = {}>
  extends CommonRowProps<D>,
    React.HTMLProps<HTMLDivElement> {
  row: Row<D>;
}

export const DataTableRow = <D extends object = {}>({
  row,
  renderExpanded,
  expanded,
  isAnyColumnResizing,
  onRowClick,
  children,
  ...props
}: PropsWithChildren<DataTableRowProps<D>>): JSX.Element => {
  const isExpanded = useMemo(
    () => expanded?.includes(row.id),
    [expanded, row.id],
  );

  const handleClick = useCallback(
    (event: React.PointerEvent<HTMLDivElement>) => {
      const hoverContainer = (event.target as Element).closest(
        '[data-kind="hover"]',
      );

      if (!hoverContainer) {
        event.stopPropagation();
        onRowClick?.(row, event);
      }
    },
    [onRowClick, row],
  );

  return isExpanded ? (
    <div key={row.getRowProps().key} className="tr-expanded" data-id={row.id}>
      {renderExpanded?.({ row })}
    </div>
  ) : (
    <div
      {...props}
      {...row.getRowProps([
        {
          className: classNames('tr', props.className),
        },
      ])}
      onClick={handleClick}
      data-id={row.id}
    >
      {row.cells.map((cell) => {
        return (
          <div
            {...cell.getCellProps()}
            className={classNames('td', cell.column.isSorted && 'sorted')}
            data-columnid={cell.column.id}
          >
            <>{cell.render('Cell')}</>
          </div>
        );
      })}
      {children}
    </div>
  );
};
