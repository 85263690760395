import { FunctionComponent, PropsWithChildren } from 'react';
import {
  DashboardHeaderNavIconButtonChevron,
  DashboardIconButton,
} from './Dashboard.styles';

interface DashboardHeaderNavIconButtonProps {
  to: string;
}

export const DashboardHeaderNavIconButton: FunctionComponent<PropsWithChildren<DashboardHeaderNavIconButtonProps>> =
  ({ to, children }) => {
    return (
      <DashboardIconButton to={to}>
        {children}
        <DashboardHeaderNavIconButtonChevron size="16" />
      </DashboardIconButton>
    );
  };
