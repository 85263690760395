import styled from 'styled-components';

export const NotificationsSettingsTabWrapper = styled.div`
  padding: 0.2rem 0;
`;

export const NotificationsSettingsTabButtonGroup = styled.div`
  margin-left: auto;
  & > * + * {
    margin-left: 1rem;
  }
`;

export const NotificationsSettingsTabHeader = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin-bottom: 2.4rem;
`;
