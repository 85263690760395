import { DataTableSortOrder } from '@fcg-tech/regtech-components';
import { Amount } from '@fcg-tech/regtech-types/oasys';
import {
  GeneralAgreementsListTableRowData,
  GeneralAgreementsListTableColumnKeys,
} from '../types';

export const stringCompareFn = (
  a: string | undefined,
  b: string | undefined,
  ascending: boolean,
  locales: string | string[] = 'sv',
) => {
  if (!a && !b) return 0;
  if (!a) return 1;
  if (!b) return -1;
  return ascending ? a.localeCompare(b, locales) : b.localeCompare(a, locales);
};

export const dateCompareFn = (
  a: Date | undefined,
  b: Date | undefined,
  ascending: boolean,
) => {
  if (!a && !b) return 0;
  if (!a) return 1;
  if (!b) return -1;
  return ascending ? +a - +b : +b - +a;
};

export const numberCompareFn = (
  a: number | undefined,
  b: number | undefined,
  ascending: boolean,
) => (ascending ? (a || 0) - (b || 0) : (b || 0) - (a || 0));

export const arrayCompareFn = (
  a: Array<string> | undefined,
  b: Array<string> | undefined,
  ascending: boolean,
) => {
  const aa = ascending ? a : b;
  const bb = ascending ? b : a;
  return !aa?.length ? -1 : !bb?.length ? 1 : aa.length - bb.length;
};

export const currencyAmountCompareFn = (
  a: Amount,
  b: Amount,
  ascending: boolean,
) => {
  if (!a && !b) return 0;
  if (!a) return 1;
  if (!b) return -1;
  return ascending
    ? a.currency.localeCompare(b.currency) || a.amount.localeCompare(b.amount)
    : b.currency.localeCompare(a.currency) || b.amount.localeCompare(a.amount);
};

export const generalAgreementsListEntitiesCompareFn = (
  a: GeneralAgreementsListTableRowData,
  b: GeneralAgreementsListTableRowData,
  sortOrder: DataTableSortOrder,
): number => {
  switch (sortOrder.columnKey) {
    case GeneralAgreementsListTableColumnKeys.Name:
      return stringCompareFn(a.name, b.name, sortOrder.ascending);
    case GeneralAgreementsListTableColumnKeys.AgreementType:
      return stringCompareFn(
        a.agreementType,
        b.agreementType,
        sortOrder.ascending,
      );
    case GeneralAgreementsListTableColumnKeys.FunctionCategory:
      return stringCompareFn(
        a.functionCategory,
        b.functionCategory,
        sortOrder.ascending,
      );
    case GeneralAgreementsListTableColumnKeys.ContractOwner:
      return stringCompareFn(
        a.contractOwner,
        b.contractOwner,
        sortOrder.ascending,
      );
  }
  return 0;
};
