import { useTranslation } from 'react-i18next';
import {
  FormButtonRow,
  FormRow,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
  PrimaryButton,
  SecondaryButton,
  TextField,
  useInput,
} from '@fcg-tech/regtech-components';
import { format } from 'date-fns';
import { FunctionComponent, useCallback } from 'react';
import slugify from 'slugify';
import { MessageKeys } from '../../translations/messageKeys';
import {
  CsvExportModalFilenameExtension,
  CsvExportModalFilenameRow,
  CsvExportModalFormLabel,
} from './CsvExportModal.styles';

interface CsvExportModalProps {
  filename?: string;
  onExport: (filename: string) => void;
  onRequestClose: () => void;
}

export const CsvExportModal: FunctionComponent<CsvExportModalProps> = ({
  filename: defaultFilename,
  onExport,
  onRequestClose,
}) => {
  const { t } = useTranslation();
  const [filename, handleFilenameChange] = useInput(
    `${slugify(defaultFilename ?? undefined, {
      replacement: '_',
    })}-${format(new Date(), 'yyMMdd-HHmmss')}`,
  );

  const handleExport = useCallback(() => {
    onExport(filename);
    onRequestClose();
  }, [filename, onExport, onRequestClose]);

  return (
    <Modal>
      <ModalHeader>
        <ModalTitle>
          {t(MessageKeys.LabelExport)}
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <FormRow>
          <CsvExportModalFormLabel>
            {t(MessageKeys.LabelFilename)}
          </CsvExportModalFormLabel>
          <CsvExportModalFilenameRow>
            <TextField value={filename} onChange={handleFilenameChange} />
            <CsvExportModalFilenameExtension>
              .csv
            </CsvExportModalFilenameExtension>
          </CsvExportModalFilenameRow>
        </FormRow>
        <FormButtonRow>
          <SecondaryButton onClick={onRequestClose}>
            {t(MessageKeys.LabelCancel)}
          </SecondaryButton>
          <PrimaryButton onClick={handleExport}>
            {t(MessageKeys.LabelExport)}
          </PrimaryButton>
        </FormButtonRow>
      </ModalBody>
    </Modal>
  );
};
