import { useMemo } from 'react';
import useSWR, { mutate, SWRConfiguration } from 'swr';
import { useApi } from '../apiUtils';
import { NotificationSettings, PersonalSettingsApi } from '../schema';
import { personalNotificationSettingsKey } from './cacheKeys';

export const getPersonalNotificationSettings = async (
  api: PersonalSettingsApi,
): Promise<NotificationSettings> => {
  const result = await api.getPersonalNotificationSettings();
  return result;
};

export const usePersonalNotificationSettings = (
  tenantId: string,
  config?: SWRConfiguration,
) => {
  const api = useApi<PersonalSettingsApi>('PersonalSettingsApi', tenantId);
  return useSWR<NotificationSettings>(
    personalNotificationSettingsKey(tenantId),
    () => getPersonalNotificationSettings(api),
    {
      suspense: true,
      ...config,
    },
  );
};

export const usePersonalNotificationSettingsActions = (tenantId: string) => {
  const api = useApi<PersonalSettingsApi>('PersonalSettingsApi', tenantId);
  return useMemo(
    () => ({
      updatePersonalNotificationSettings: async (
        notificationSettings: NotificationSettings,
      ) => {
        await api.updatePersonalNotificationSettings({ notificationSettings });
        mutate(personalNotificationSettingsKey(tenantId));
      },
    }),
    [api, tenantId],
  );
};
