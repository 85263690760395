import styled, { css } from 'styled-components';

import { CrossIcon } from '../Icons';
import { SearchIcon } from '../Icons/SearchIcon';
import { TextField } from '../TextField';

export const StyledSearchIcon = styled(SearchIcon)`
  ${({ theme }) => css`
    color: ${theme?.colors?.border ?? 'rgba(0,0,0,0.3)'};
    height: 2rem;
    width: 2rem;
  `}
`;

export const StyledClearIcon = styled(CrossIcon)`
  ${({ theme }) => css`
    color: ${theme?.palette?.MainBlack ?? '#000'};
    height: 1.4rem;
    width: 1.4rem;
  `}
`;

export const SearchIconWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledTextField = styled(TextField)`
  padding: 0 0 0 3rem;
  &.has-clear {
    padding: 0 2.5rem 0 3rem;
  }
`;

export const ClearIconWrapper = styled(SearchIconWrapper)`
  left: initial;
  right: 0;
  cursor: pointer;
  opacity: 0.3;

  &:hover {
    opacity: 1;
  }
`;
