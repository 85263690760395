import React, { FunctionComponent, StrictMode, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { Error404 } from '../../components/ErrorBoundary/Error404';
import { useIamContext } from '../../components/IamContext';
import { routes, tenantBase } from '../../routes';
import { useTenant } from '../../states/tenantState';
import {
  canGroupCreate,
  canRoleCreate,
  canUserCreate,
  canViewPageAgreements,
  canViewPageCabinets,
  canViewPageCalendar,
  canViewPageCategories,
  canViewPageGroups,
  canViewPageInternalEntities,
  canViewPagePersonalSettings,
  canViewPageReports,
  canViewPageRoles,
  canViewPageSuppliers,
  canViewPageTags,
  canViewPageUsers,
} from '../../utils/iamHelpers';
import { getLogger } from '../../api/logger';
import { AgreementTypeEditContainer } from '../AgreementType';
import { AgreementTypesPage } from '../AgreementTypes';
import { AgreementVersionContainer } from '../AgreementVersion';
import { CabinetsPage } from '../Cabinets';
import { CalendarContainer } from '../Calendar';
import { CategoriesPage } from '../Categories';
import { DashboardPage } from '../DashboardPage';
import { EditAgreementContainer } from '../EditAgreement';
import { EditCabinetContainer } from '../EditCabinet';
import { EditCategoryContainer } from '../EditCategory';
import { EditInternalEntityContainer } from '../EditInternalEntity';
import { EditReportContainer } from '../EditReport';
import { EditSupplierContainer } from '../EditSupplier';
import { EditTagContainer } from '../EditTag';
import { GlobalSearchPage } from '../GlobalSearchPage';
import { GroupContainer } from '../Group';
import { GroupsContainer } from '../Groups';
import { InternalEntitiesPage } from '../InternalEntities';
import { NewGroupContainer } from '../NewGroup';
import { NewRoleContainer } from '../NewRole';
import { NewUserContainer } from '../NewUser';
import { NotificationsPage } from '../NotificationsPage/NotificationsPage';
import { ReportsPage } from '../Reports';
import { RoleContainer } from '../Role';
import { RolesContainer } from '../Roles';
import { SuppliersOverviewPage } from '../SuppliersOverviewPage';
import { SupplierVersionContainer } from '../SupplierVersion';
import { TagsPage } from '../Tags';
import { UserContainer } from '../User';
import { UsersContainer } from '../Users';
import { AgreementsOverviewPage } from '../AgreementsOverviewPage';
import { AgreementHistoryPage } from '../AgreementHistoryPage';
import { SupplierHistoryPage } from '../SupplierHistoryPage';
import { MainLayoutPageInnerWrapper } from '../../components/MainLayout';

export const AppRoutes: FunctionComponent = () => {
  const location = useLocation();
  const iamContext = useIamContext();
  const tenant = useTenant();

  useEffect(
    () => getLogger().info(`Navigated to ${location.pathname}`),
    [location.pathname],
  );

  return (
    <Routes>
      <Route
        path={routes.newAgreement}
        element={
          <Navigate
            to={constructUrl(routes.agreements, { tenantId: tenant.id })}
          />
        }
      />
      <Route
        path={routes.newSupplier}
        element={
          <Navigate
            to={constructUrl(routes.suppliers, { tenantId: tenant.id })}
          />
        }
      />
      <Route
        path={routes.newAgreementType}
        element={
          <Navigate
            to={constructUrl(routes.agreementTypes, { tenantId: tenant.id })}
          />
        }
      />
      <Route
        path={routes.newInternalEntity}
        element={
          <Navigate
            to={constructUrl(routes.internalEntities, { tenantId: tenant.id })}
          />
        }
      />
      <Route
        path={routes.newCategory}
        element={
          <Navigate
            to={constructUrl(routes.categories, { tenantId: tenant.id })}
          />
        }
      />
      <Route
        path={routes.newTag}
        element={
          <Navigate to={constructUrl(routes.tags, { tenantId: tenant.id })} />
        }
      />
      <Route
        path={routes.newCabinet}
        element={
          <Navigate
            to={constructUrl(routes.cabinets, { tenantId: tenant.id })}
          />
        }
      />

      <Route
        path={routes.dashboard}
        element={
          <StrictMode>
            <MainLayoutPageInnerWrapper>
              <DashboardPage />
            </MainLayoutPageInnerWrapper>
          </StrictMode>
        }
      />
      {canViewPageCalendar(iamContext) ? (
        <Route
          path={routes.calendar}
          element={
            <MainLayoutPageInnerWrapper>
              <CalendarContainer />
            </MainLayoutPageInnerWrapper>
          }
        />
      ) : null}
      {canViewPageAgreements(iamContext) ? (
        <Route
          path={routes.agreements}
          element={
            <StrictMode>
              <MainLayoutPageInnerWrapper>
                <AgreementsOverviewPage />
              </MainLayoutPageInnerWrapper>
            </StrictMode>
          }
        />
      ) : null}
      <Route path={routes.editAgreement} element={<EditAgreementContainer />} />
      <Route path={routes.agreement} element={<EditAgreementContainer />} />
      <Route
        path={routes.agreementVersions}
        element={
          <StrictMode>
            <MainLayoutPageInnerWrapper>
              <AgreementHistoryPage />
            </MainLayoutPageInnerWrapper>
          </StrictMode>
        }
      />
      <Route
        path={routes.agreementVersion}
        element={<AgreementVersionContainer />}
      />
      {canViewPageCabinets(iamContext) ? (
        <Route
          path={routes.cabinets}
          element={
            <StrictMode>
              <MainLayoutPageInnerWrapper>
                <CabinetsPage />
              </MainLayoutPageInnerWrapper>
            </StrictMode>
          }
        />
      ) : null}

      <Route path={routes.cabinet} element={<EditCabinetContainer />} />
      <Route
        path={routes.agreementTypes}
        element={
          <StrictMode>
            <MainLayoutPageInnerWrapper>
              <AgreementTypesPage />
            </MainLayoutPageInnerWrapper>
          </StrictMode>
        }
      />

      <Route
        path={routes.agreementType}
        element={<AgreementTypeEditContainer />}
      />
      {canViewPageCategories(iamContext) ? (
        <Route
          path={routes.categories}
          element={
            <StrictMode>
              <MainLayoutPageInnerWrapper>
                <CategoriesPage />
              </MainLayoutPageInnerWrapper>
            </StrictMode>
          }
        />
      ) : null}

      <Route path={routes.category} element={<EditCategoryContainer />} />
      {canViewPageSuppliers(iamContext) ? (
        <Route
          path={routes.suppliers}
          element={
            <StrictMode>
              <MainLayoutPageInnerWrapper>
                <SuppliersOverviewPage />
              </MainLayoutPageInnerWrapper>
            </StrictMode>
          }
        />
      ) : null}
      <Route path={routes.supplier} element={<EditSupplierContainer />} />
      <Route
        path={routes.supplierVersions}
        element={
          <StrictMode>
            <MainLayoutPageInnerWrapper>
              <SupplierHistoryPage />
            </MainLayoutPageInnerWrapper>
          </StrictMode>
        }
      />
      <Route
        path={routes.supplierVersion}
        element={<SupplierVersionContainer />}
      />
      {canViewPageInternalEntities(iamContext) ? (
        <Route
          path={routes.internalEntities}
          element={
            <StrictMode>
              <MainLayoutPageInnerWrapper>
                <InternalEntitiesPage />
              </MainLayoutPageInnerWrapper>
            </StrictMode>
          }
        />
      ) : null}
      <Route
        path={routes.internalEntity}
        element={<EditInternalEntityContainer />}
      />
      {canViewPageReports(iamContext) ? (
        <Route
          path={routes.reports}
          element={
            <StrictMode>
              <MainLayoutPageInnerWrapper>
                <ReportsPage />
              </MainLayoutPageInnerWrapper>
            </StrictMode>
          }
        />
      ) : null}
      <Route path={routes.report} element={<EditReportContainer />} />
      {canViewPagePersonalSettings(iamContext) ? (
        <Route
          path={routes.notifications}
          element={
            <StrictMode>
              <MainLayoutPageInnerWrapper>
                <NotificationsPage />
              </MainLayoutPageInnerWrapper>
            </StrictMode>
          }
        />
      ) : null}
      {canViewPagePersonalSettings(iamContext) ? (
        <Route
          path={routes.notificationsSettings}
          element={
            <StrictMode>
              <MainLayoutPageInnerWrapper>
                <NotificationsPage />
              </MainLayoutPageInnerWrapper>
            </StrictMode>
          }
        />
      ) : null}
      <Route
        path={routes.search}
        element={
          <StrictMode>
            <MainLayoutPageInnerWrapper>
              <GlobalSearchPage />
            </MainLayoutPageInnerWrapper>
          </StrictMode>
        }
      />
      {canViewPageTags(iamContext) ? (
        <Route
          path={routes.tags}
          element={
            <StrictMode>
              <MainLayoutPageInnerWrapper>
                <TagsPage />
              </MainLayoutPageInnerWrapper>
            </StrictMode>
          }
        />
      ) : null}
      <Route path={routes.tag} element={<EditTagContainer />} />
      {canViewPageGroups(iamContext) ? (
        <Route path={routes.groups} element={<GroupsContainer />} />
      ) : null}
      {canGroupCreate(iamContext) ? (
        <Route path={routes.newGroup} element={<NewGroupContainer />} />
      ) : null}
      <Route path={routes.group} element={<GroupContainer />} />
      {canViewPageRoles(iamContext) ? (
        <Route path={routes.roles} element={<RolesContainer />} />
      ) : null}
      {canRoleCreate(iamContext) ? (
        <Route path={routes.newRole} element={<NewRoleContainer />} />
      ) : null}
      <Route path={routes.role} element={<RoleContainer />} />
      {canViewPageUsers(iamContext) ? (
        <Route path={routes.users} element={<UsersContainer />} />
      ) : null}
      {canUserCreate(iamContext) ? (
        <Route path={routes.newUser} element={<NewUserContainer />} />
      ) : null}
      <Route path={routes.user} element={<UserContainer />} />

      <Route
        path="/"
        element={
          <Navigate
            to={constructUrl(routes.dashboard, { tenantId: tenant.id })}
          />
        }
      />
      <Route
        path={tenantBase}
        element={
          <Navigate
            to={constructUrl(routes.dashboard, { tenantId: tenant.id })}
          />
        }
      />
      <Route
        path="/auth/callback"
        element={
          <Navigate
            to={constructUrl(routes.dashboard, { tenantId: tenant.id })}
          />
        }
      />
      <Route element={<Error404 />} />
    </Routes>
  );
};
