import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalTitle } from '@fcg-tech/regtech-components';
import React, { FunctionComponent } from 'react';
import { MessageKeys } from '../../translations/messageKeys';
import {
  SearchHelpModalContent,
  SearchHelpModalExampleString,
  SearchHelpModalSection,
  SearchHelpModalSectionLabel,
} from './SearchHelpModal.styles';

interface SearchHelpModalProps {
  exampleLabel?: string;
  exampleDescription?: string;
  onHide: () => void;
}

export const SearchHelpModal: FunctionComponent<SearchHelpModalProps> = ({
  exampleLabel,
  exampleDescription,
  onHide,
}) => {
  const { t } = useTranslation();
  return (
    <Modal onHide={onHide}>
      <ModalHeader>
        <ModalTitle>
          {t(MessageKeys.SearchHelpModalTitle)}
        </ModalTitle>
      </ModalHeader>
      <SearchHelpModalContent>
        <SearchHelpModalSection>
          <SearchHelpModalSectionLabel>
            {t(MessageKeys.SearchHelpModalUnquotedTextLabel)}
          </SearchHelpModalSectionLabel>
          {t(MessageKeys.SearchHelpModalUnquotedTextDescription)}
        </SearchHelpModalSection>
        <SearchHelpModalSection>
          <SearchHelpModalSectionLabel>
            {t(MessageKeys.SearchHelpModalQuotedTextLabel)}
          </SearchHelpModalSectionLabel>
          {t(MessageKeys.SearchHelpModalQuotedTextDescription)}
        </SearchHelpModalSection>
        <SearchHelpModalSection>
          <SearchHelpModalSectionLabel>
            {t(MessageKeys.SearchHelpModalOrTextLabel)}
          </SearchHelpModalSectionLabel>
          {t(MessageKeys.SearchHelpModalOrTextDescription)}
        </SearchHelpModalSection>
        <SearchHelpModalSection>
          <SearchHelpModalSectionLabel>
            {t(MessageKeys.SearchHelpModalExampleTextLabel)}
          </SearchHelpModalSectionLabel>
          <SearchHelpModalExampleString>
            {exampleLabel}
          </SearchHelpModalExampleString>
          {exampleDescription}
        </SearchHelpModalSection>
      </SearchHelpModalContent>
    </Modal>
  );
};
