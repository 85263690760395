import { DataTableColumn } from '@fcg-tech/regtech-datatable';
import { Translator } from '@fcg-tech/regtech-types';
import { MessageKeys } from '../../translations/messageKeys';
import { SupplierListItem, SupplierTableColumns } from '../../types';
import { SupplierTableCell } from './SupplierTableCell';

export const getSupplierTableColumns = (
  t: Translator,
): Array<DataTableColumn<SupplierListItem>> => {
  return [
    {
      id: SupplierTableColumns.Name,
      defaultCanSort: true,
      width: 250,
      minWidth: 200,
      Header: t(MessageKeys.SupplierName),
      Cell: SupplierTableCell,
      accessor: (row: SupplierListItem) => row.supplierName,
    },
    {
      id: SupplierTableColumns.CountryOfRegistration,
      defaultCanSort: true,
      width: 250,
      minWidth: 200,
      Header: t(MessageKeys.SupplierCountryOfRegistration),
      Cell: SupplierTableCell,
      accessor: (row: SupplierListItem) => row.countryOfRegistration,
    },
    {
      id: SupplierTableColumns.CorporateRegistrationNumber,
      defaultCanSort: true,
      width: 250,
      minWidth: 200,
      Header: t(MessageKeys.SupplierCorporateRegistrationNumber),
      Cell: SupplierTableCell,
      accessor: (row: SupplierListItem) => row.corporateRegistrationNumber,
    },
  ];
};
