import styled, { css } from 'styled-components';
import { excludeProps } from '../../utils';
import { H1, H2 } from '../Headings';
import { ArrowUpIcon } from '../Icons';

export const DataTableScrollInnerWrapper = styled.div`
  max-height: 100%;
`;

export const DataTableScrollWrapper = styled.div`
  max-height: 100%;
  overflow: auto;
`;

export const DataTableWrapper = styled.div`
  display: flex;
  align-items: stretch;
  max-height: 100%;
  flex: 1;
  overflow: hidden;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
`;

export const DataTableTableWrapper = styled.div`
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  flex: 1;
  position: relative;
`;

export const DataTableInnerScrollWrapper = styled.div`
  position: relative;
  overflow-y: scroll;
  flex: 1;

  &.no-scroll {
    overflow-y: hidden;
  }
`;

// This component helps to offset the sticky header with the width of a scrollbar, when scrollbars are set to always show
export const DataTableStickyWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
`;

export const DataTableLoadingWrapper = styled.div`
  overflow: hidden;
  display: flex;
  max-height: 100%;
  min-height: 50vh;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const DataTableNoItemsMessage = styled.div`
  padding: 3rem 0;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 600;
`;

export const Table = styled.table`
  display: table;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  max-width: 100%;
  font-size: 1.2rem;
`;

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 7rem;
  margin-top: -1rem;

  & > ${H1}, & > ${H2} {
    margin: 0;
  }
`;

export const TableHead = styled.thead`
  font-weight: 600;
  letter-spacing: 0.1rem;
  font-size: 1.4rem;
  border-bottom: 1px solid #ccc;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: white;

  &.data-table-hidden {
    box-shadow: none;
    border: 0;
  }
`;

export const TableRow = styled.tr.withConfig<{
  selected?: boolean;
  readOnly?: boolean;
  clickable?: boolean;
}>(excludeProps('selected', 'readOnly', 'clickable'))`
  ${({ selected }) =>
    selected &&
    css`
      background-color: rgba(0, 0, 0, 0.075);
    `}
  &:hover {
    cursor: ${({ readOnly, clickable }) =>
      readOnly ? 'default' : clickable ? 'pointer' : 'default'};
    background-color: ${({ readOnly, clickable, selected }) =>
      selected
        ? 'rgba(0, 0, 0, 0.075)'
        : clickable && !readOnly
        ? 'rgba(0, 0, 0, 0.05)'
        : 'inherit'};
  }
  ${TableHead} > & {
    overflow: hidden;
  }
  ${TableHead} > &:hover {
    cursor: default;
    background-color: inherit;
  }
`;

export const TableCell = styled.td`
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  padding: 1rem 0.4rem 1rem 1.2rem;

  &.fixed {
    text-overflow: initial;
    text-align: center;
    padding: 0;
  }

  &.fixed:first-child {
    padding-left: 0.3rem;
  }

  &.fog {
    position: relative;
    &:after {
      transition: opacity linear 2.5s;
      content: '';
      opacity: 1;
      position: absolute;
      right: 0;
      top: 0.4rem;
      bottom: 0.4rem;
      width: 10px;
      pointer-events: none;
      z-index: 1;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1)
      );
    }
  }
`;

export const TableColumnResizerWrapper = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  right: -0.5rem;
  width: 1rem;
  z-index: 20;
  cursor: ew-resize;

  &:after {
    position: absolute;
    content: ' ';
    width: 1px;
    border-right: 1px dashed transparent;
    left: 50%;
    top: 0;
    bottom: 0;
  }

  ${TableHead}:hover &:after {
    border-color: rgba(0, 0, 0, 0.3);
  }
`;

export const TableHeadCellWrapper = styled(TableCell).attrs({ as: 'th' })`
  padding: 0 0.4rem 0 1.2rem;
  height: 4rem;
  vertical-align: middle;
  position: relative;
  overflow: visible;

  background-color: white;

  .data-article-table-hidden & {
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    height: 0;
    border-color: transparent !important;
    opacity: 0;
  }

  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0);

  &.isdragging {
    box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.5);
    background-color: #ddd;
    position: relative;
    z-index: 10;
    opacity: 0.7;
    cursor: ew-resize;

    & ${TableColumnResizerWrapper} {
      display: none;
    }

    & button {
      pointer-events: none;
    }
  }

  &.fixed {
    text-overflow: initial;
    text-align: center;
    padding: 0;
  }

  &.fixed:first-child {
    padding-left: 0.3rem;
  }
`;

export const TableHeadCellLabel = styled.span`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 1rem 0 0;
  letter-spacing: initial;
  display: flex;
  align-items: center;
  height: 100%;

  font-weight: 400;
  &.active {
    opacity: 1;
    font-weight: 700;
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const DataTableHeadCellIconWrapper = styled.div`
  display: flex;
  height: inherit;
  align-items: center;
`;

export const DataTableSortIcon = styled(ArrowUpIcon)`
  opacity: 0;
  margin-left: 1ch;
  z-index: 2;

  &.ascending {
    transform-origin: 50% 50%;
    transform: rotate(180deg);
  }

  &.active {
    opacity: 1 !important;
  }

  ${TableHeadCellLabel}:hover & , ${TableHeadCellLabel}:focus & , ${TableHeadCellLabel}:focus-within & {
    opacity: 0.25;
  }

  ${TableHeadCellWrapper}.isdragging & {
    display: none;
  }
`;
