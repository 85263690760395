import React from 'react';
import { Spinner, SpinnerInner, SquareWrapper } from './Square.styles';

interface SquareProps extends React.HTMLAttributes<HTMLDivElement> {
  size: 'small' | 'large';
}

export const Square = ({ size, ...props }: SquareProps) => (
  <SquareWrapper {...props}>
    <Spinner size={size}>
      <SpinnerInner size={size} />
    </Spinner>
  </SquareWrapper>
);
