/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, FunctionComponent, useContext, PropsWithChildren } from 'react';
import { getIn } from 'formik';
import { Agreement } from '../../types';
import { jsonDiff } from '../../utils/diffHelpers';

interface FieldCompareManagerProps {
  current: Agreement;
  previous: Agreement;
}
export const FieldCompareManagerContext = createContext<{
  [index: string]: any;
}>({});

export const FieldCompareManager: FunctionComponent<PropsWithChildren<PropsWithChildren<FieldCompareManagerProps>>> =
  ({ children, current, previous }) => {
    const compareResult = React.useMemo(
      () => jsonDiff(previous, current),
      [previous, current],
    );

    return (
      <FieldCompareManagerContext.Provider value={compareResult}>
        {children}
      </FieldCompareManagerContext.Provider>
    );
  };

export const useFieldCompareContext = () =>
  useContext(FieldCompareManagerContext);

export const useFieldCompare = (name?: string) => {
  const compare = useFieldCompareContext();

  return name ? getIn(compare, name) : null;
};
