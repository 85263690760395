import React from 'react';
import { Field, Label } from '@fcg-tech/regtech-components';
import { TextField } from '@fcg-tech/regtech-formik-components';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import { getTooltip } from '../../utils/tooltipHelpers';
import { FormRow, FormColumn, FormGroup } from '../FormLayout';

export const TagDetailsSubForm = () => {
  const { t } = useTranslation();

  return (
    <FormRow>
      <FormColumn>
        <FormGroup>
          <Label column htmlFor="tag-name">
            {t(MessageKeys.LabelName)}
          </Label>
          <Field
            id="tag-name"
            name="name"
            component={TextField}
            type="text"
            info={getTooltip('tag.name')}
          />
        </FormGroup>
      </FormColumn>
    </FormRow>
  );
};
