import { classNames } from '@fcg-tech/regtech-utils';
import React, { FunctionComponent, useCallback } from 'react';
import { TextField } from '../TextField';
import {
  ClearIconWrapper,
  SearchIconWrapper,
  StyledClearIcon,
  StyledSearchIcon,
  StyledTextField,
} from './SearchTextField.styles';

export interface SearchTextFieldProps
  extends React.ComponentPropsWithRef<typeof TextField> {
  onClear?: () => void;
}

export const SearchTextField: FunctionComponent<SearchTextFieldProps> =
  React.forwardRef(({ onClear, className, ...props }, ref) => {
    const handleClear = useCallback(() => {
      onClear?.();
    }, [onClear]);

    return (
      <StyledTextField
        ref={ref}
        {...props}
        className={classNames(onClear && 'has-clear', className)}
        selectAllOnFocus
      >
        <SearchIconWrapper>
          <StyledSearchIcon />
        </SearchIconWrapper>
        {onClear ? (
          <ClearIconWrapper
            role="button"
            onClick={handleClear}
            aria-label="clear"
          >
            <StyledClearIcon />
          </ClearIconWrapper>
        ) : null}
      </StyledTextField>
    );
  });
