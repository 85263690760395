import styled, { css } from 'styled-components';
import { mobile } from '../../theme';

const base = css`
  margin: 0 0 2rem 0;

  &.center {
    text-align: center;
  }
`;

export const H1 = styled.h1`
  ${({ theme }) => css`
    ${base};
    font-family: ${theme.typography?.fontFamily};
    font-size: 2.6rem;
    margin: 0 0 3rem 0;

    &.no-margin {
      margin: 0;
    }

    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      font-size: 2rem;
    }

    ${theme?.elements?.heading?.h1}
  `}

  &.flex {
    display: flex;
    align-items: center;
  }
`;

export const H2 = styled.h2`
  ${({ theme }) => css`
    ${base};
    font-family: ${theme.typography?.fontFamily};
    font-size: 2.2rem;

    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      font-size: 1.6rem;
    }

    ${theme?.elements?.heading?.h2}
  `}

  &.flex {
    display: flex;
    align-items: center;
  }
`;

export const H3 = styled.h3`
  ${({ theme }) => css`
    ${base};
    font-family: ${theme.typography?.fontFamily};
    font-size: 2rem;

    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      font-size: 1.4rem;
    }

    ${theme?.elements?.heading?.h3}
  `}

  &.flex {
    display: flex;
    align-items: center;
  }
`;

export const Heading1 = styled.h1`
  ${({ theme }) => css`
    font-family: ${theme.typography?.fontFamily};
    font-size: 3.2rem;
    font-weight: 500;
    &:first-child {
      margin-top: 0;
    }

    ${mobile(
      () => css`
        font-size: 2.6rem;
      `,
    )}

    &.flex {
      display: flex;
      align-items: center;
    }
  `};
`;

export const Heading2 = styled.h2`
  ${({ theme }) => css`
    font-family: ${theme.typography?.fontFamily};
    font-size: 2.4rem;
    font-weight: 700;
    &:first-child {
      margin-top: 0;
    }

    ${mobile(
      () => css`
        font-size: 2rem;
      `,
    )}

    &.flex {
      display: flex;
      align-items: center;
    }
  `};
`;

export const Heading3 = styled.h3`
  ${({ theme }) => css`
    font-family: ${theme.typography?.fontFamily};
    font-size: 2.4rem;
    font-weight: 400;
    &:first-child {
      margin-top: 0;
    }

    ${mobile(
      () => css`
        font-size: 2rem;
      `,
    )}

    &.flex {
      display: flex;
      align-items: center;
    }
  `};
`;

export const Heading4 = styled.h4`
  ${({ theme }) => css`
    font-family: ${theme.typography?.fontFamily};
    font-size: 1.8rem;
    font-weight: 700;
    &:first-child {
      margin-top: 0;
    }

    ${mobile(
      () => css`
        font-size: 1.6rem;
      `,
    )}

    &.flex {
      display: flex;
      align-items: center;
    }
  `};
`;
