import { atom, selectorFamily } from 'recoil';
import { Country } from '../api/schema';

export const countryState = atom<Array<Country>>({
  key: 'CountryState',
  default: [],
});

export const country = selectorFamily({
  key: 'countryById',
  get:
    (isoAlpha2: string) =>
    ({ get }) =>
      get(countryState).find((c) => c.isoAlpha2 === isoAlpha2),
});
