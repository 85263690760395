/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  BooleanTree,
  BooleanTreeItem,
  BooleanTreeItemValue,
} from '@fcg-tech/regtech-components';
import { DataTableColumn } from '@fcg-tech/regtech-datatable';
import update from 'immutability-helper';
import React, { FunctionComponent, useCallback } from 'react';
import { AgreementListItem, AgreementTableColumns } from '../../types';

const getColumnLabel = (
  column: DataTableColumn<AgreementListItem>,
): string | null => {
  if (typeof column.Header === 'string') {
    return column.Header;
  }
  return null;
};

interface ColumnSelectorProps {
  allowedColumns: Array<DataTableColumn<AgreementListItem>>;
  selectedColumns: Array<AgreementTableColumns>;
  onChange: (selectedColumns: Array<string>) => void;
}

export const ColumnSelector: FunctionComponent<ColumnSelectorProps> = ({
  allowedColumns,
  selectedColumns,
  onChange,
}) => {
  const handleChange = useCallback(
    (itemValues: BooleanTreeItemValue<any>, selected: boolean) => {
      const newSelectedColumns = selected
        ? update(selectedColumns, { $push: [itemValues.value!] })
        : update(selectedColumns, {
            $splice: [[selectedColumns.indexOf(itemValues.value!), 1]],
          });
      onChange(newSelectedColumns);
    },
    [onChange, selectedColumns],
  );

  const items: Array<BooleanTreeItem> = allowedColumns.map<BooleanTreeItem>(
    (column) => {
      return {
        id: column.id,
        value: column.id,
        label: getColumnLabel(column),
        selected: Boolean(
          selectedColumns?.includes(column.id as AgreementTableColumns),
        ),
      };
    },
    [],
  );

  return <BooleanTree items={items} onChange={handleChange} />;
};
