/** TODO: Move these /hooks instead */

import { Maybe } from '@fcg-tech/regtech-types';
import { parse, stringify } from 'query-string';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export const useToggle = (
  initial = false,
): [boolean, (force?: boolean) => void, () => void] => {
  const [active, setActive] = useState(initial);
  const handleAction = useCallback(
    (force?: boolean) =>
      setActive((old) => (force === undefined ? !old : force)),
    [],
  );
  const handleEventAction = useCallback(() => setActive((old) => !old), []);

  return [active, handleAction, handleEventAction];
};

export const useInput = <T extends { value: string } = HTMLInputElement>(
  initialValue = '',
  validator?: (value: string) => Maybe<string>,
): [
  value: string,
  onChange: (event: React.ChangeEvent<T>) => void,
  setValue: React.Dispatch<React.SetStateAction<string>>,
  error: Maybe<string>,
] => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState<string>();
  const onChange = useCallback(
    (event: React.ChangeEvent<T>) => {
      setValue(event.target.value);
      if (validator) {
        const validationError = validator(event.target.value);
        setError(validationError ?? undefined);
      }
    },
    [validator],
  );
  return [value, onChange, setValue, error];
};

export const useCurry = <T extends Array<unknown>>(
  callback: (...a: T) => unknown,
  ...args: T
) => {
  const onEvent = useCallback(() => callback?.(...args), [callback, args]);
  return onEvent;
};

export const useFocus = <T extends { focus: () => void }>() => {
  const ref = useRef<T>();
  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  return ref;
};

/**
 * @deprecated Use @fcg-tech/regtech-utils instead
 */
export const useEscapeKey = (callback?: () => void) => {
  useEffect(() => {
    if (callback) {
      const listener = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          callback?.();
          event.stopPropagation();
        }
      };
      document.addEventListener('keydown', listener);

      return () => document.removeEventListener('keydown', listener);
    }
    return undefined;
  }, [callback]);
};

/**
 * @deprecated Use @fcg-tech/regtech-utils instead
 */
export const useReturnKey = (callback?: () => void) => {
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        callback?.();
        event.stopPropagation();
      }
    };
    document.addEventListener('keydown', listener);

    return () => document.removeEventListener('keydown', listener);
  }, [callback]);
};

/**
 * @deprecated Use @fcg-tech/regtech-utils instead
 */
const isInputActive = (event: KeyboardEvent) =>
  document.activeElement?.tagName?.toLowerCase() === 'input' ||
  (event.target as HTMLElement)?.tagName?.toLowerCase() === 'input' ||
  (event.target as HTMLElement)?.tagName?.toLowerCase() === 'textarea';

export type HotkeyOptions = {
  modifier?: 'shiftKey';
};

/**
 * @deprecated Use @fcg-tech/regtech-utils instead
 */
export const useHotkeys = (
  hotkeys: Array<string>,
  callback: (key: string) => void,
  options: HotkeyOptions = {},
) => {
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (hotkeys.includes(event.key.toLowerCase())) {
        if (
          (options.modifier && event[options.modifier]) ||
          (!options.modifier &&
            !event.ctrlKey &&
            !event.shiftKey &&
            !event.metaKey &&
            !event.altKey)
        ) {
          if (!isInputActive(event)) {
            callback(event.key.toLowerCase());
          }
        }
      }
    };
    document.addEventListener('keydown', listener);

    return () => document.removeEventListener('keydown', listener);
  }, [callback, hotkeys, options.modifier]);
};

/**
 * @deprecated Use @fcg-tech/regtech-utils instead
 */
export const useIsMountedRef = () => {
  const isMountedRef = useRef<boolean | null>(null);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  return isMountedRef;
};

export const usePagination = (
  defaultPage = 1,
): [page: number, handlePageChange: (page: number) => void] => {
  const navigate = useNavigate();
  const location = useLocation();

  const page = useMemo(
    () => Number(parse(location.search)?.['page']) || defaultPage,
    [location.search, defaultPage],
  );

  const setPage = useCallback(
    (pageNumber: number) => {
      navigate({
        pathname: location.pathname,
        search: stringify({ ...parse(location.search), page: pageNumber }),
      });
    },
    [navigate, location],
  );

  return [page, setPage];
};

export const useFormRef = (
  options?: EventInit,
): [
  formRef: MutableRefObject<HTMLFormElement | undefined>,
  dispatchSubmit: () => boolean,
] => {
  const formRef = useRef<HTMLFormElement>();

  const dispatchSubmit = useCallback(
    () =>
      formRef.current?.dispatchEvent(
        new Event('submit', {
          cancelable: true,
          bubbles: true,
          ...options,
        }),
      ) ?? false,
    [formRef, options],
  );

  return [formRef, dispatchSubmit];
};

export const useStickyObserver = (ref: React.MutableRefObject<Element>) => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const cachedRef = ref.current,
      observer = new IntersectionObserver(
        ([e]) => {
          setIsSticky(e.intersectionRatio < 1);
        },
        { threshold: [1] },
      );

    observer.observe(cachedRef);

    // unmount
    return function () {
      observer.unobserve(cachedRef);
    };
  }, [ref]);

  return isSticky;
};

export const useModuloCycle = (
  max: number,
  start = 0,
): [current: number, next: () => void, prev: () => void, reset: () => void] => {
  const [current, setCurrent] = useState(start);

  useEffect(() => {
    if (current >= max) {
      setCurrent(0);
    }
  }, [current, max]);

  return [
    current,
    useCallback(() => setCurrent((old) => (old + 1) % max), [max]),
    useCallback(() => setCurrent((old) => (old - 1 + max * 2) % max), [max]),
    useCallback(() => setCurrent(0), []),
  ];
};
