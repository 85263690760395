import styled, { css, keyframes } from 'styled-components';
import { excludeProps } from '../../../utils';

export const SquareWrapper = styled.div``;

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const blopp = keyframes`
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0.3);
  }
`;

export const spinnerSize = {
  small: () => css`
    height: 2rem;
    width: 2rem;
  `,
  large: () => css`
    height: 3rem;
    width: 3rem;
  `,
};

export const spinnerInnerSize = {
  small: () => css`
    height: 0.8rem;
    width: 0.8rem;
  `,
  large: () => css`
    height: 1.6rem;
    width: 1.6rem;
  `,
};

export const Spinner = styled.div.withConfig(excludeProps('size'))<{
  size: 'small' | 'large';
}>`
  ${({ size }) => css`
    ${spinnerSize[size]};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #47b2a3;
    border-radius: 8px;
    animation: ${rotate} 1.2s ease-in-out infinite;
  `}
`;

export const SpinnerInner = styled.div.withConfig(excludeProps('size'))<{
  size: 'small' | 'large';
}>`
  ${({ size, theme }) => css`
    ${spinnerInnerSize[size]};
    background-color: ${theme.colors?.backdrop ?? theme?.palette?.MainWhite ?? '#fff'};
    border-radius: 4px;
    animation: ${blopp} 0.8s ease-in-out infinite;
  `}
`;
