import React from 'react';
import { format, isValid, parse } from 'date-fns';
import { DateField as DefaultDateField } from '@fcg-tech/regtech-components';
import { FormikHelpers, FieldInputProps } from 'formik';

const DATE_FMT = 'yyyy-MM-dd';

interface DateFieldProps {
  form: FormikHelpers<string>;
  field: FieldInputProps<string>;
}

export const DateField = ({ form, field, ...props }: DateFieldProps) => {
  const value = React.useMemo(() => {
    const parsedDate = parse(field.value, DATE_FMT, new Date());
    return isValid(parsedDate) ? parsedDate : null;
  }, [field.value]);

  const handleChange = React.useCallback(
    (date) => {
      form.setFieldValue(
        field.name,
        isValid(date) ? format(date, DATE_FMT) : null,
      );
    },
    [form, field.name],
  );

  return (
    <DefaultDateField
      {...props}
      {...field}
      value={value}
      onChange={handleChange}
      dateFormat={DATE_FMT}
    />
  );
};
