import React from 'react';
import { useAsync } from 'react-async';
import { useNavigate, useMatch } from 'react-router-dom';
import {
  ConfirmDialog,
  EditProvider,
  Loader,
  useFlash,
} from '@fcg-tech/regtech-components';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import {
  loadInternalEntity,
  updateInternalEntity,
  deleteInternalEntity,
  loadInternalEntityAgreements,
} from '../../api';
import { routes } from '../../routes';
import { useTenant } from '../../states/tenantState';
import { useErrorDialog } from '../../components/ErrorDialog';
import { EditInternalEntityPage } from './components';
import { ErrorMessage } from '../../components/ErrorBoundary';

export const EditInternalEntityContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tenant = useTenant();
  const tenantId = tenant.id;
  const match = useMatch(routes.internalEntity);
  const { internalEntityId } = match?.params ?? {};

  const showErrorDialog = useErrorDialog();
  const addFlash = useFlash();

  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);
  const [isEditEnabled, setEditEnabled] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);

  const loadInternalEntityReq = useAsync({
    promiseFn: loadInternalEntity,
    tenantId,
    internalEntityId,
  });

  const loadAgreementsReq = useAsync({
    promiseFn: loadInternalEntityAgreements,
    tenantId,
    internalEntityId,
    isArchived: false,
  });

  const updateInternalEntityProxy = async (args) => {
    setIsSaving(true);
    try {
      await updateInternalEntity(...args);
      addFlash({
        level: 'success',
        content: t(MessageKeys.EditInternalEntitySuccessfullyUpdatedLabel),
      });
      setEditEnabled(false);
      loadInternalEntityReq.reload();
    } catch (err) {
      showErrorDialog({
        title: t(MessageKeys.LabelSomethingWentWrong),
        message: t(MessageKeys.LabelErrorOccured),
      });
    } finally {
      setIsSaving(false);
    }
  };

  const deleteInternalEntityProxy = async (args) => {
    try {
      await deleteInternalEntity(...args);
      addFlash({
        level: 'success',
        content: t(MessageKeys.EditInternalEntitySuccessfullyDeletedLabel),
      });
      navigate(constructUrl(routes.internalEntities, { tenantId }));
    } catch (err) {
      showErrorDialog({
        title: t(MessageKeys.LabelSomethingWentWrong),
        message: t(MessageKeys.EditInternalEntityFailedDeleteLabel),
      });
    }
  };

  const updateInternalEntityReq = useAsync({
    deferFn: updateInternalEntityProxy,
  });

  const deleteInternalEntityReq = useAsync({
    deferFn: deleteInternalEntityProxy,
  });

  const handleSave = React.useCallback(
    (internalEntity) => {
      setIsSaving(true);
      updateInternalEntityReq.run({
        tenantId,
        internalEntityId,
        internalEntity: internalEntity.data,
      });
    },
    [updateInternalEntityReq, setIsSaving, tenantId, internalEntityId],
  );

  const handleEdit = React.useCallback(() => {
    setEditEnabled(true);
  }, [setEditEnabled]);

  const handleCancel = React.useCallback(() => {
    setEditEnabled(false);
  }, [setEditEnabled]);

  const handleDelete = React.useCallback(() => {
    setShowDeleteConfirm(true);
  }, []);

  const handleDeleteChoice = React.useCallback(
    (choice) => {
      if (choice) {
        deleteInternalEntityReq.run({ tenantId, internalEntityId });
      }
      setShowDeleteConfirm(false);
    },
    [deleteInternalEntityReq, tenantId, internalEntityId],
  );

  if (loadInternalEntityReq.isLoading) {
    return <Loader message={t(MessageKeys.LabelLoadingInternalEntity)} />;
  }

  if (loadInternalEntityReq.error || updateInternalEntityReq.error) {
    return (
      <ErrorMessage
        error={loadInternalEntityReq.error || updateInternalEntityReq.error}
      />
    );
  }

  return (
    <>
      {showDeleteConfirm && (
        <ConfirmDialog
          title={t(MessageKeys.LabelConfirm)}
          body={t(MessageKeys.EditInternalEntityConfirmDeleteLabel)}
          confirmText={t(MessageKeys.LabelConfirmDelete)}
          cancelText={t(MessageKeys.LabelNo)}
          onChoice={handleDeleteChoice}
        />
      )}
      <EditProvider value={isEditEnabled}>
        <EditInternalEntityPage
          internalEntity={loadInternalEntityReq.data}
          agreements={loadAgreementsReq?.data?.result ?? []}
          isSaving={isSaving}
          onEdit={handleEdit}
          onCancel={handleCancel}
          onSave={handleSave}
          onDelete={handleDelete}
        />
      </EditProvider>
    </>
  );
};
