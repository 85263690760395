import React, { FunctionComponent, useEffect, useMemo, useState, PropsWithChildren } from 'react';
import { getCurrentPathAndQuery } from '@fcg-tech/regtech-utils';
import { matchPath, useNavigate } from 'react-router-dom';
import { parse } from 'querystring';

import { useAuth } from '../AuthContext';
import { getAccessToken } from '@fcg-tech/regtech-api-utils';

interface AuthCallbackProps {
  allowedReturnUrls: Array<string | { path: string; exact: boolean }>;
}

enum AuthState {
  Pending,
  Initialized,
  Authenticated,
  NotAuthenticated,
}

/**
 * @deprecated - Use @fcg-tech/regtech-auth SingleTenantAuthCallback instead
 */
export const AuthCallback: FunctionComponent<PropsWithChildren<AuthCallbackProps>> = ({
  allowedReturnUrls = [],
  children,
}) => {
  const { setIsLoggedIn, startLoginFlow, endLoginFlow } = useAuth();
  const navigate = useNavigate();
  const hash = window.location.hash;

  const parameters = useMemo(() => parse(hash.replace(/^#/, '')), [hash]);

  const stateKey = Array.isArray(parameters['state'])
    ? parameters['state'][0]
    : parameters['state'];
  const idToken = Array.isArray(parameters['id_token'])
    ? parameters['id_token'][0]
    : parameters['id_token'];

  const state = window.localStorage.getItem(stateKey as string);

  const [authState, setAuthState] = useState(
    state ? AuthState.Initialized : AuthState.Pending,
  );

  useEffect(() => {
    if (authState === AuthState.Pending) {
      const accessToken = getAccessToken();
      if (!accessToken) {
        startLoginFlow(getCurrentPathAndQuery());
      } else {
        // Optimistically set user as authenticated if an accessToken is detected. This might cuase the app to invoke and endpoint ending with a failure in which case it should start the login flow again
        setIsLoggedIn?.(true);
        setAuthState(AuthState.Authenticated);
      }
    } else if (authState === AuthState.Initialized) {
      if (stateKey && state) {
        window.localStorage.removeItem(stateKey);
        if (idToken) {
          endLoginFlow(idToken);
          setIsLoggedIn?.(true);
          setAuthState(AuthState.Authenticated);
          const { returnUrl } = JSON.parse(state);
          const redirect = allowedReturnUrls?.some((path) => {
            const match = matchPath(
              typeof path === 'string' ? { path, exact: true } : path,
              returnUrl,
            );
            return match;
          })
            ? returnUrl
            : '/';

          setTimeout(() => navigate(redirect), 1);
        } else {
          setAuthState(AuthState.NotAuthenticated);
          setIsLoggedIn?.(false);
        }
      } else {
        // eslint-disable-next-line no-console
        console.error(
          'Could not find the referenced state, this is an invalid request',
        );
        setAuthState(AuthState.NotAuthenticated);
        setIsLoggedIn?.(false);
        navigate('/', { replace: true });
      }
    }
  }, [
    allowedReturnUrls,
    endLoginFlow,
    idToken,
    stateKey,
    setIsLoggedIn,
    state,
    authState,
    startLoginFlow,
    navigate,
  ]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return authState === AuthState.Authenticated ? <>{children}</> : null;
};
