import { IconButton } from '@fcg-tech/regtech-components';
import styled from 'styled-components';

export const FileUploadZoneWrapper = styled.div`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FileUploadZoneFileList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

export const FileUploadZoneFileListItemWrapper = styled.div`
  padding: 0.5rem 0;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid transparent;

  &:hover {
    border-color: #ddd;
  }
`;

export const FileUploadZoneFileListItemNameWrapper = styled.div`
  display: flex;
  align-items: center;
  & > .icon {
    margin-right: 0.5rem;
  }
`;

export const FileUploadZoneFileListItemName = styled.span`
  flex: 1;
`;

export const FileUploadZoneFileListItemButtons = styled.div`
  display: flex;
  align-items: center;
`;

export const FileUploadZoneFileListItemButton = styled(IconButton)`
  width: 2.2rem;
  height: 2.2rem;
  & + & {
    margin-left: 0.6rem;
  }
`;

export const FileUploadZoneFileListItemLink = styled.a`
  flex: 1;
`;

export const FileUploadZoneFileListNoItemsMessage = styled.div`
  margin-top: 1rem;
  opacity: 0.7;
  font-size: 1.2rem;
  position: relative;
  top: -0.1rem;
`;
