import React, { FunctionComponent } from 'react';
import { IconProps } from '../../types/icons';

export const MoreDotsIcon: FunctionComponent<IconProps> = ({
  size = '24',
  color = 'currentColor',
  ...props
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    width={size}
    height={size}
    stroke={color}
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="more-dots-icon_svg__feather more-dots-icon_svg__feather-more-dots"
    {...props}
  >
    <circle cx="12" cy="12" r="1"></circle>
    <circle cx="19" cy="12" r="1"></circle>
    <circle cx="5" cy="12" r="1"></circle>
  </svg>
);
