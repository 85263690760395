import { useMemo } from 'react';
import useSWR, { SWRConfiguration, useSWRConfig } from 'swr';
import {
  NotificationSettingsApi,
  EnableEmailNotificationRequest,
  DisableEmailNotificationRequest,
} from '../../api/schema';
import { NotificationsSettingsResult } from '../../api/schema';
import { useNotificationsSettingsApi } from '../notificationsApiUtils';
import { notificationsSettingsKey } from './cacheKeys';

export const checkIfEmailNotificationsIsEnabledFetcher = async (
  api: NotificationSettingsApi,
  application: string,
) => {
  try {
    const result = await api.checkIfEmailNotificationsIsEnabled({
      application,
    });
    return result;
  } catch (err) {
    throw new Error(
      `Failed to check email notification settings from ${application}`,
    );
  }
};

export const useNotificationsSettings = (
  baseEndpoint: string,
  application: string,
  config: SWRConfiguration = {},
) => {
  const api = useNotificationsSettingsApi(baseEndpoint);
  return useSWR<NotificationsSettingsResult>(
    notificationsSettingsKey(application),
    () => checkIfEmailNotificationsIsEnabledFetcher(api, application),
    { suspense: true, ...config },
  );
};

export const useNotificationsSettingsActions = (baseEndpoint: string) => {
  const api = useNotificationsSettingsApi(baseEndpoint);
  const { mutate } = useSWRConfig();

  return useMemo(
    () => ({
      enableEmailNotification: async (application: string) => {
        try {
          const requestParams: EnableEmailNotificationRequest = {
            application,
          };
          const result = await api.enableEmailNotification(requestParams);
          mutate(notificationsSettingsKey(application));
          return result;
        } catch (err) {
          throw new Error(`Failed to enable email notifications`);
        }
      },
      disableEmailNotification: async (application: string) => {
        try {
          const requestParams: DisableEmailNotificationRequest = {
            application,
          };
          const result = await api.disableEmailNotification(requestParams);
          mutate(notificationsSettingsKey(application));
          return result;
        } catch (err) {
          throw new Error(`Failed to disable email notifications`);
        }
      },
    }),
    [api, mutate],
  );
};
