import React from 'react';
import { useAsync } from 'react-async';
import { useNavigate, useMatch, matchPath } from 'react-router-dom';
import {
  ConfirmDialog,
  EditProvider,
  Loader,
  useFlash,
} from '@fcg-tech/regtech-components';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import {
  createSupplier,
  deleteAgreement,
  deleteAgreementAttachment,
  loadAgreement,
  loadAgreementAttachments,
  loadAgreementAttachmentViewUrl,
  loadAgreements,
  loadCategories,
  loadInternalEntities,
  loadSuppliers,
  loadTags,
  updateAgreement,
  uploadAgreementAttachment,
  renameAgreementAttachment,
} from '../../api';
import { loadAgreementTypesPromiseFn } from '../../api/apis/agreementTypeAPI';
import { useAgreementArchiveActions } from '../../api/hooks/agreementsApiHooks';
import { convertAgreement } from '../../converters/agreementConverter';
import { clearAgreementData } from '../../utils/agreementHelpers';
import { useTenant } from '../../states/tenantState';
import { routes } from '../../routes';
import { useErrorDialog } from '../../components/ErrorDialog';
import { EditAgreementPage } from './components';
import { AttachmentControlProvider } from '../../components/Attachments/AttachmentControlContext';
import { CreateSupplierModal } from '../../components/modals';
import { convertSupplierListItem } from '../../converters/supplierConverters';
import { ErrorMessage } from '../../components/ErrorBoundary';

const shouldRequestReload = (props, prevProps) =>
  props.tenantId !== prevProps.tenantId ||
  props?.agreementId !== prevProps?.agreementId;

export const EditAgreementContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tenant = useTenant();
  const tenantId = tenant.id;
  const matchA = useMatch(routes.agreement);
  const matchB = useMatch(routes.editAgreement);
  const params = matchA?.params ?? matchB?.params ?? {};
  const { agreementId } = params;
  const isEditEnabled = Boolean(matchB?.params);

  const showErrorDialog = useErrorDialog();
  const addFlash = useFlash();

  const [isSaving, setIsSaving] = React.useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);
  const [selectedTags, setSelectedTags] = React.useState([]);
  const [showAddSupplierDialog, setShowAddSuppplierDialog] =
    React.useState(false);

  const [showForceSaveDialog, setShowForceSaveDialog] = React.useState(false);

  const { archiveAgreement, unArchiveAgreement } = useAgreementArchiveActions(
    tenant.id,
  );

  const loadAgreementReq = useAsync({
    promiseFn: loadAgreement,
    watchFn: shouldRequestReload,
    tenantId,
    agreementId,
    onResolve: (data) => setSelectedTags(data.tags),
  });

  const loadAgreementsReq = useAsync({
    promiseFn: loadAgreements,
    watchFn: shouldRequestReload,
    tenantId,
  });

  const loadAttachmentsReq = useAsync({
    promiseFn: loadAgreementAttachments,
    watchFn: shouldRequestReload,
    tenantId,
    agreementId,
  });

  const loadCategoriesReq = useAsync({
    promiseFn: loadCategories,
    watchFn: shouldRequestReload,
    tenantId,
  });

  const loadSuppliersReq = useAsync({
    promiseFn: loadSuppliers,
    watchFn: shouldRequestReload,
    tenantId,
  });

  const loadInternalEntitiesReq = useAsync({
    promiseFn: loadInternalEntities,
    watchFn: shouldRequestReload,
    tenantId,
  });

  const loadTagsReq = useAsync({
    promiseFn: loadTags,
    tenantId,
  });

  const loadAgreementTypesReq = useAsync({
    promiseFn: loadAgreementTypesPromiseFn,
    tenantId,
  });

  const deleteAgreementProxy = async (args) => {
    try {
      await deleteAgreement(...args);
      addFlash({
        level: 'success',
        content: t(MessageKeys.EditAgreementSuccessfullyDeletedLabel),
      });
      navigate(constructUrl(routes.agreements, { tenantId }));
    } catch (err) {
      showErrorDialog({
        title: t(MessageKeys.LabelSomethingWentWrong),
        message: t(MessageKeys.EditAgreementFailedDeleteLabel),
      });
    }
  };

  const updateAgreementProxy = async (args) => {
    setIsSaving(true);
    try {
      await updateAgreement(...args);
      addFlash({
        level: 'success',
        content: t(MessageKeys.EditAgreementSuccessfullyUpdatedLabel),
      });
      navigate(constructUrl(routes.agreement, { tenantId, agreementId }));
      loadAgreementReq.reload();
    } catch (err) {
      if (err.status === 422) {
        setShowForceSaveDialog(true);
      } else {
        showErrorDialog({
          title: t(MessageKeys.LabelSomethingWentWrong),
          message: t(MessageKeys.LabelErrorOccured),
        });
      }
    } finally {
      setIsSaving(false);
    }
  };

  const updateAgreementReq = useAsync({ deferFn: updateAgreementProxy });
  const deleteAgreementReq = useAsync({ deferFn: deleteAgreementProxy });

  const handleEdit = React.useCallback(() => {
    navigate(constructUrl(routes.editAgreement, { tenantId, agreementId }));
  }, [navigate, tenantId, agreementId]);

  const handleCancel = React.useCallback(() => {
    navigate(constructUrl(routes.agreement, { tenantId, agreementId }));
    loadAgreementReq.reload();
  }, [navigate, tenantId, agreementId, loadAgreementReq]);

  const parentAgreementCheck = (agreement) => {
    if (
      agreement.relatedAgreements.hasParent &&
      !agreement.relatedAgreements.parentAgreement
    ) {
      return null;
    }
    return agreement.relatedAgreements.hasParent;
  };

  const handleSave = React.useCallback(
    (data, currentVersion, nonBlockingErrors, force = false) => {
      const agreement = clearAgreementData(data);
      const isParentAgreementCheck = parentAgreementCheck(data);
      updateAgreementReq.run({
        tenantId,
        agreementId,
        agreement: {
          ...agreement,
          validations: nonBlockingErrors,
          relatedAgreements: {
            ...agreement.relatedAgreements,
            hasParent: isParentAgreementCheck,
          },
        },
        tags: selectedTags,
        version: currentVersion + 1,
        force,
      });
      if (force) {
        setShowForceSaveDialog(false);
      }
    },
    [tenantId, agreementId, selectedTags, updateAgreementReq],
  );

  const handleDelete = React.useCallback(() => setShowDeleteConfirm(true), []);

  const handleDeleteChoice = React.useCallback(
    (choice) => {
      if (choice) {
        deleteAgreementReq.run({ tenantId, agreementId });
      }
      setShowDeleteConfirm(false);
    },
    [deleteAgreementReq, tenantId, agreementId],
  );

  const handleArchive = React.useCallback(
    async (archived) => {
      try {
        if (archived) {
          await archiveAgreement(agreementId);
          addFlash({
            level: 'success',
            content: t(MessageKeys.EditAgreementSuccessfullyArchivedLabel),
          });
        }
        if (!archived) {
          await unArchiveAgreement(agreementId);
          addFlash({
            level: 'success',
            content: t(MessageKeys.EditAgreementSuccessfullyUnarchivedLabel),
          });
        }
        loadAgreementReq.reload();
      } catch (err) {
        if (archived) {
          addFlash({
            level: 'error',
            content: t(MessageKeys.EditAgreementFailedArchivedLabel),
          });
        }
        if (!archived) {
          addFlash({
            level: 'error',
            content: t(MessageKeys.EditAgreementFailedUnarchivedLabel),
          });
        }
      }
    },
    [
      agreementId,
      addFlash,
      t,
      archiveAgreement,
      unArchiveAgreement,
      loadAgreementReq,
    ],
  );

  const handleToggleTag = React.useCallback(
    (tagName) =>
      setSelectedTags((prev) =>
        prev.includes(tagName)
          ? [...prev.filter((o) => o !== tagName)]
          : [...prev, tagName],
      ),
    [setSelectedTags],
  );

  const createSupplierProxy = async (args) => {
    try {
      const { Location } = await createSupplier(...args);
      if (Location) {
        const match = matchPath(
          { path: '/suppliers/:supplierId' },
          `/${Location}`,
        );
        const { supplierId } = match.params;
        const agreement = { ...loadAgreementReq.data };
        agreement.data.details.mainSupplier.externalSupplier = supplierId;
        loadAgreementReq.setData({ ...agreement }, () =>
          loadSuppliersReq.reload(),
        );
      }
    } catch (err) {
      // TODO: log error
    }
  };

  const createSupplierReq = useAsync({ deferFn: createSupplierProxy });

  const handleAddSupplier = React.useCallback(
    () => setShowAddSuppplierDialog(true),
    [],
  );

  const handleAddSupplierSubmit = React.useCallback(
    (supplier) => {
      createSupplierReq.run({
        tenantId,
        supplier: supplier,
      });
      setShowAddSuppplierDialog(false);
    },
    [createSupplierReq, tenantId],
  );

  const handleAddSupplierCancel = React.useCallback(
    () => setShowAddSuppplierDialog(false),
    [],
  );

  const loading =
    loadAgreementReq.isLoading ||
    loadAgreementTypesReq.isLoading ||
    loadInternalEntitiesReq.isLoading ||
    loadCategoriesReq.isLoading ||
    loadAgreementsReq.isLoading ||
    loadTagsReq.isLoading;
  if (loading) {
    return <Loader message={t(MessageKeys.LabelLoadingAgreement)} />;
  }

  const error =
    loadAgreementReq.error ||
    loadInternalEntitiesReq.error ||
    loadAgreementTypesReq.error ||
    loadCategoriesReq.error ||
    loadAttachmentsReq.error ||
    loadSuppliersReq.error ||
    loadAgreementsReq.error ||
    loadTagsReq.error;
  if (error) {
    return <ErrorMessage error={error} />;
  }

  const attachmentControl = {
    onUploadAttachment: async (files, section) => {
      await uploadAgreementAttachment({
        tenantId,
        agreementId,
        files,
        section,
      });
      loadAttachmentsReq.reload();
    },
    onDownloadAttachment: async (attachmentId) => {
      const { url } = await loadAgreementAttachmentViewUrl({
        tenantId,
        agreementId,
        attachmentId,
      });
      return url;
    },
    onDeleteAttachment: async (attachmentId) => {
      try {
        await deleteAgreementAttachment({
          tenantId,
          agreementId,
          attachmentId,
        });
        const result = loadAttachmentsReq?.data?.result?.filter(
          ({ id }) => id !== attachmentId,
        );
        loadAttachmentsReq.setData({ result }, () => {
          loadAttachmentsReq.reload();
        });
        return true;
      } catch (err) {
        return false;
      }
    },
    onRenameAttachment: async (attachmentId, newFilename) => {
      try {
        await renameAgreementAttachment({
          tenantId,
          agreementId,
          attachmentId,
          filename: newFilename,
        });
        const result = loadAttachmentsReq?.data?.result?.map((attachment) =>
          attachment.id === attachmentId
            ? { ...attachment, filename: newFilename }
            : attachment,
        );
        loadAttachmentsReq.setData({ result }, () => {
          loadAttachmentsReq.reload();
        });
        return true;
      } catch (err) {
        return false;
      }
    },
  };

  return (
    <>
      {showDeleteConfirm ? (
        <ConfirmDialog
          title="Confirm"
          body="Are you sure you want to delete this agreement?"
          confirmText="Yes, delete it"
          cancelText="No"
          onChoice={handleDeleteChoice}
        />
      ) : null}
      {showAddSupplierDialog ? (
        <CreateSupplierModal
          suppliers={(loadSuppliersReq?.data?.result || []).map(
            convertSupplierListItem,
          )}
          onSubmit={handleAddSupplierSubmit}
          onRequestClose={handleAddSupplierCancel}
        />
      ) : null}

      <EditProvider value={isEditEnabled}>
        <AttachmentControlProvider value={attachmentControl}>
          <EditAgreementPage
            agreement={convertAgreement(loadAgreementReq.data)}
            isArchived={loadAgreementReq?.data?.metadata?.isArchived}
            agreements={loadAgreementsReq.data.result}
            attachments={loadAttachmentsReq?.data?.result}
            tags={loadTagsReq.data.result}
            selectedTags={selectedTags}
            onToggleTag={handleToggleTag}
            categories={loadCategoriesReq.data.result}
            suppliers={
              loadSuppliersReq.isLoading ? [] : loadSuppliersReq.data.result
            }
            internalEntities={loadInternalEntitiesReq.data.result}
            agreementTypes={loadAgreementTypesReq.data?.result}
            showForceSaveDialog={showForceSaveDialog}
            onCloseForceSaveDialog={() => setShowForceSaveDialog(false)}
            isSaving={isSaving}
            onSave={handleSave}
            onEdit={handleEdit}
            onCancel={handleCancel}
            onDelete={handleDelete}
            onArchive={handleArchive}
            onAddSupplier={handleAddSupplier}
          />
        </AttachmentControlProvider>
      </EditProvider>
    </>
  );
};
