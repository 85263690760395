import { classNames } from '@fcg-tech/regtech-utils';
import React from 'react';
import { IconProps } from '../../types/icons';

export const BookIcon = ({
  size = '24',
  color = 'currentColor',
  className,
  ...props
}: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    width={size}
    height={size}
    stroke={color}
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    fill="none"
    className={classNames(className, 'icon')}
    {...props}
  >
    <path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20"></path>
    <path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z"></path>
  </svg>
);
