import { FunctionComponent, PropsWithChildren } from 'react';
import {
  SideNavigationItemLinkLabelInner,
  SideNavigationItemLinkLabelWrapper,
} from './SideNavigationItem.styles';

interface SideNavigationItemLinkLabelProps {
  className?: string;
}

export const SideNavigationItemLinkLabel: FunctionComponent<PropsWithChildren<SideNavigationItemLinkLabelProps>> =
  ({ className, children }) => {
    return (
      <SideNavigationItemLinkLabelWrapper className={className}>
        <SideNavigationItemLinkLabelInner>
          {children}
        </SideNavigationItemLinkLabelInner>
      </SideNavigationItemLinkLabelWrapper>
    );
  };
