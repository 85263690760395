import { classNames } from '@fcg-tech/regtech-utils';
import React from 'react';
import { IconProps } from '../../types/icons';

export const CheckCircleIcon = ({
  size = '24',
  color = 'currentColor',
  className,
  ...props
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    focusable="false"
    style={{ transform: 'rotate(360deg)' }}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    className={classNames('icon', className)}
    {...props}
  >
    <path
      d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm-1 12.502l-4.5-4.5 1.414-1.414L11 13.674l5.586-5.586L18 9.502l-7 7z"
      fill={color}
    />
  </svg>
);
