import { EmptyPropsWithChildren } from '@fcg-tech/regtech-types';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
} from '@fcg-tech/regtech-components';
import { useUserAgent } from '../../miscHooks';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import {
  IEBlockerTitleWrapper,
  IEBlockerTriangleExclamationIcon,
  IEBlockerMessageWrapper,
} from './IEBlocker.styles';

export const IEBlocker: FunctionComponent<PropsWithChildren<EmptyPropsWithChildren>> = ({ children }) => {
  const { t } = useTranslation();
  const { isIE } = useUserAgent();

  if (isIE) {
    return (
      <Modal>
        <ModalHeader>
          <ModalTitle>
            <IEBlockerTitleWrapper>
              <IEBlockerTriangleExclamationIcon size="30" />
              <span>{t(MessageKeys.IEBlockerHeadingLabel)}</span>
            </IEBlockerTitleWrapper>
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <IEBlockerMessageWrapper>
            <p>{t(MessageKeys.IEBlockerBodyLabel)}</p>
          </IEBlockerMessageWrapper>
        </ModalBody>
      </Modal>
    );
  }

  return children as React.ReactElement;
};
