import styled from 'styled-components';
import { Form } from 'formik';

export const SubcontractorsFieldEditFormWrapper = styled(Form)`
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 50vw;
  min-width: 50rem;
`;
