import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import {
  Card,
  CardBody,
  CardTitle,
  loadDataTableSortOrder,
  PageLayout,
  PageLayoutPaddedContent,
  PageHeader,
  useDataTableSortable,
} from '@fcg-tech/regtech-components';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../../translations/messageKeys';
import { routes } from '../../../routes';
import { generalAgreementsListEntitiesCompareFn } from '../../../utils/compareFns';
import { getGeneralAgreementsListSortOrder } from '../../../utils/listDataTableHelpers';
import { useTenant } from '../../../states/tenantState';
import { agreementPropType } from '../../../propTypes';
import { TagDetailsSubForm } from '../../../components/SubForms';
import { ListDataTable } from '../../../components/ListDataTable';

export const EditTagPage = ({ tag, agreements }) => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const tenantId = tenant.id;
  const navigate = useNavigate();

  const pageTitle = React.useMemo(() => {
    return tag?.data?.name || t(MessageKeys.LabelUnnamed);
  }, [tag, t]);

  const columns = React.useMemo(
    () => [
      {
        columnKey: 'name',
        label: t(MessageKeys.LabelName),
        resizable: false,
        sortable: true,
        initialSortAscending: true,
      },
      {
        columnKey: 'agreementType',
        label: t(MessageKeys.LabelAgreementType),
        resizable: false,
        sortable: true,
        initialSortAscending: false,
      },
      {
        columnKey: 'functionCategory',
        label: t(MessageKeys.LabelCategory),
        resizable: false,
        sortable: true,
        initialSortAscending: false,
      },
      {
        columnKey: 'contractOwner',
        label: t(MessageKeys.LabelContractOwner),
        resizable: false,
        sortable: true,
        initialSortAscending: false,
      },
    ],
    [t],
  );

  const rows = React.useMemo(
    () =>
      agreements?.map((agreement) => ({
        id: agreement.metadata.id,
        data: agreement,
      })) ?? [],
    [agreements],
  );

  const { sortedRows, sortOrder, handleSortOrderChange } = useDataTableSortable(
    'agreements-agreementType-table-sort-order',
    rows,
    {
      sortOrder:
        loadDataTableSortOrder('agreements-agreementType-table-sort-order') ??
        getGeneralAgreementsListSortOrder('nameAsc'),
      compareFn: generalAgreementsListEntitiesCompareFn,
    },
  );

  const handleClickRow = React.useCallback(
    (id) =>
      navigate(constructUrl(routes.agreement, { tenantId, agreementId: id })),
    [navigate, tenantId],
  );

  return (
    <Formik initialValues={tag?.data} onSubmit={() => null}>
      {() => (
        <PageLayout>
          <PageHeader title={pageTitle} />
          <PageLayoutPaddedContent>
            <Card>
              <CardBody>
                <CardTitle>{t(MessageKeys.LabelDetails)}</CardTitle>
                <Form>
                  <TagDetailsSubForm />
                </Form>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <ListDataTable
                  title={t(MessageKeys.LabelAgreements)}
                  emptyTableText={t(
                    MessageKeys.LabelNoAgreementsFound,
                  )}
                  columns={columns}
                  data={sortedRows}
                  sortOrder={sortOrder}
                  handleSortOrderChange={handleSortOrderChange}
                  handleClickRow={handleClickRow}
                />
              </CardBody>
            </Card>
          </PageLayoutPaddedContent>
        </PageLayout>
      )}
    </Formik>
  );
};

EditTagPage.propTypes = {
  tag: PropTypes.shape({
    data: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  agreements: PropTypes.arrayOf(agreementPropType),
};

EditTagPage.defaultProps = {
  tag: null,
  agreements: [],
};
