import React from 'react';
import {
  ToggleSwitchWrapper,
  ToggleSwitchInner,
  ToggleSwitchInput,
  ToggleSwitchLabel,
  ToggleSwitchDot,
} from './ToggleSwitch.styles';

export interface ToggleSwitchProps
  extends React.HTMLAttributes<HTMLInputElement> {
  name?: string;
  /** @deprecated - use checked instead */
  value?: boolean;
  checked?: boolean;
  onLabel?: string;
  offLabel?: string;
}

export const ToggleSwitch = ({
  id,
  name,
  value = false,
  checked,
  onLabel = 'On',
  offLabel = 'Off',
  onChange,
  ...props
}: ToggleSwitchProps) => (
  <ToggleSwitchWrapper className="toggle-switch">
    <ToggleSwitchLabel htmlFor={id}>{offLabel}</ToggleSwitchLabel>
    <ToggleSwitchInner>
      <ToggleSwitchInput
        {...props}
        id={id}
        name={name}
        checked={(checked === undefined ? value : checked) ?? false}
        onChange={onChange}
      />
      <ToggleSwitchDot />
    </ToggleSwitchInner>
    <ToggleSwitchLabel htmlFor={id}>{onLabel}</ToggleSwitchLabel>
  </ToggleSwitchWrapper>
);
