import React, { FunctionComponent, PropsWithChildren } from 'react';
import { SpinningLoadingIcon } from '@fcg-tech/regtech-components';
import { SuspenseLoaderWrapper } from './SuspenseLoader.styles';

interface SuspenseLoaderProps {
  className?: string;
}

export const SuspenseLoader: FunctionComponent<PropsWithChildren<PropsWithChildren<SuspenseLoaderProps>>> = ({
  className,
  children,
}) => {
  return (
    <React.Suspense
      fallback={
        <SuspenseLoaderWrapper className={className}>
          <SpinningLoadingIcon size="80px" />
        </SuspenseLoaderWrapper>
      }
    >
      {children}
    </React.Suspense>
  );
};
