import React, { FunctionComponent, useCallback } from 'react';

import {
  FilterCheckboxCheckbox,
  FilterRowCheckboxLabel,
} from './Filter.styles';
import { FilterCommonProps, FilterValues, FilterWidgetProps } from './types';

interface FilterCheckboxProps<T extends FilterValues>
  extends Omit<FilterCommonProps<T>, 'getNoResultsMessage' | 'loadingMessage'>,
    FilterWidgetProps<T> {
  inverted?: boolean;
  onHelpClick?: (filterPropType: keyof T) => void;
}

export const FilterCheckbox = <T extends FilterValues>({
  filter,
  filterId,
  filterPropKey,
  label,
  inverted,
  onChange,
}: FilterCheckboxProps<T>) => {
  const handleChange = useCallback(
    (checked: boolean) => {
      onChange?.(filterPropKey, inverted ? !checked : checked, filterId);
    },
    [onChange, filterPropKey, inverted, filterId],
  );

  return (
    <FilterRowCheckboxLabel>
      <FilterCheckboxCheckbox
        checked={
          inverted
            ? filter?.[filterPropKey] === false
            : filter?.[filterPropKey] === true
        }
        onChange={handleChange}
      />
      {label}
    </FilterRowCheckboxLabel>
  );
};
