import React, { useCallback, useMemo } from 'react';
import {
  ClipboardIcon,
  ButtonIcon,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
  PrimaryButton,
  useCopyToClipboard,
} from '@fcg-tech/regtech-components';
import { useTranslation } from 'react-i18next';
import { useTenant } from '../../../states/tenantState';
import { MessageKeys } from '../../../translations/messageKeys';
import { environment } from '../../../environments/environment';
import {
  CalendarExportModalContent,
  CalendarExportModalBodyText,
  CalendarExportModalFormGroup,
  CalendarExportModalButtonGroup,
  CopyToClipboardWrapper,
  CopyToClipboardTextField,
  CopyToClipboardButton,
  StyledInfoCircle,
} from './CalendarExportModal.styles';

type PersonalSettingsToken = {
  token: string;
  created: string;
};

interface CalendarExportModalProps {
  token?: PersonalSettingsToken;
  onHide: () => void;
  onCreateToken: () => void;
  onDeleteToken: () => void;
}

export const CalendarExportModal = ({
  token,
  onHide,
  onCreateToken,
  onDeleteToken,
}: CalendarExportModalProps) => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const tenantId = tenant.id;

  const SECRET_ADDRESS_TOOLTIP = t(
    MessageKeys.CalendarExportSecretAddressTooltipLabel,
  );

  const secretAddress = useMemo(
    () =>
      token
        ? `${environment.apiBaseUrl}/${tenantId}/calendar/export?token=${token.token}`
        : '',
    [token, tenantId],
  );

  const [isCopied, onCopy] = useCopyToClipboard(secretAddress, {
    successDuration: 350,
  });

  const handleCopy = useCallback(() => onCopy(), [onCopy]);

  return (
    <Modal onHide={onHide}>
      <CalendarExportModalContent>
        <ModalHeader>
          <ModalTitle>
            {t(MessageKeys.CalendarExportLabel)}
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <CalendarExportModalBodyText>
            {t(MessageKeys.CalendarExportSecretAddressBodyLabel)}
          </CalendarExportModalBodyText>
          {token && (
            <CalendarExportModalFormGroup>
              <Label>
                {t(MessageKeys.CalendarExportSecretAddressLabel)}
                <StyledInfoCircle info={SECRET_ADDRESS_TOOLTIP} />
              </Label>
              <CopyToClipboardWrapper>
                <CopyToClipboardTextField value={secretAddress} readOnly />
                <CopyToClipboardButton
                  onClick={handleCopy}
                  disabled={!secretAddress || isCopied}
                >
                  <ButtonIcon>
                    <ClipboardIcon size="16" />
                  </ButtonIcon>
                  <span>
                    {t(MessageKeys.CalendarExportCopyLabel)}
                  </span>
                </CopyToClipboardButton>
              </CopyToClipboardWrapper>
            </CalendarExportModalFormGroup>
          )}
          <CalendarExportModalButtonGroup>
            {token ? (
              <PrimaryButton onClick={onDeleteToken}>
                {t(
                  MessageKeys.CalendarExportDeleteSecretAddressLabel,
                )}
              </PrimaryButton>
            ) : (
              <PrimaryButton onClick={onCreateToken}>
                {t(MessageKeys.CalendarExportGetSecretAddressLabel)}
              </PrimaryButton>
            )}
          </CalendarExportModalButtonGroup>
        </ModalBody>
      </CalendarExportModalContent>
    </Modal>
  );
};
