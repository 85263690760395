import { AuthError } from '@fcg-tech/regtech-api-utils';
import { authContext } from '@fcg-tech/regtech-auth';
import { EmptyPropsWithChildren } from '@fcg-tech/regtech-types';
import { Component } from 'react';

export class AuthErrorBoundary extends Component<EmptyPropsWithChildren> {
  state = {
    hasError: false,
  };

  static contextType = authContext;

  context!: React.ContextType<typeof authContext>;

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: error instanceof AuthError };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      this.context?.reAuthenticate?.();
      return null;
    }

    return this.props.children;
  }
}
