import React, {
  useState,
  useEffect,
  useRef,
  MutableRefObject,
  FunctionComponent,
  PropsWithChildren,
} from 'react';
import {
  HeaderContainer,
  HeaderContent,
  TitleContainer,
  Title,
  ActionsContainer,
  Actions,
} from './PageHader.styles';
import { Breadcrumb } from '../../types/misc';

const useSticky = (
  ref: MutableRefObject<HTMLDivElement>,
  { threshold }: { threshold?: number } = {},
) => {
  const [state, setState] = useState({ sticky: false });

  const onIntersection = (entries: Array<{ isIntersecting: boolean }>) => {
    setState({ sticky: !entries[0].isIntersecting });
  };

  const current = ref.current;

  useEffect(() => {
    const observer = new window.IntersectionObserver(onIntersection, {
      threshold: threshold ?? 0,
      root: null,
      rootMargin: '0px',
    });

    if (current) {
      observer.observe(current);
    }

    return () => observer.disconnect();
  }, [current, threshold]);

  return state.sticky;
};

interface PageHeaderProps {
  title?: string | JSX.Element;
  isArchived?: boolean;
  subtitle?: string;
  /** @deprecated  */
  breadcrumbs?: Array<string | Breadcrumb>;
}

export const PageHeader: FunctionComponent<PropsWithChildren<PageHeaderProps>> = ({
  title,
  isArchived,
  children,
}) => {
  const sentinalRef = useRef<HTMLDivElement>(null);
  const sticky = useSticky(sentinalRef, { threshold: 0.8 });

  return (
    <>
      {sticky ? (
        <HeaderContainer sticky>
          <HeaderContent sticky={sticky}>
            <TitleContainer>
              {title ? (
                <Title sticky={sticky} className={isArchived && 'archived'}>
                  {title}
                </Title>
              ) : null}
            </TitleContainer>
            <ActionsContainer>
              <Actions>{children}</Actions>
            </ActionsContainer>
          </HeaderContent>
        </HeaderContainer>
      ) : null}
      <HeaderContainer ref={sentinalRef}>
        <HeaderContent>
          <TitleContainer>
            {title ? (
              <Title className={isArchived && 'archived'}>{title}</Title>
            ) : null}
          </TitleContainer>
          <ActionsContainer>
            <Actions>{children}</Actions>
          </ActionsContainer>
        </HeaderContent>
      </HeaderContainer>
    </>
  );
};
