import { useTranslation } from 'react-i18next';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import {
  DataTable,
  DataTableColumnOptions,
  DataTableRowOptions,
  SpinningLoadingIcon,
} from '@fcg-tech/regtech-components';
import { classNames } from '@fcg-tech/regtech-utils';
import { parse } from 'query-string';
import { FunctionComponent, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGlobalSearch } from '../../api/hooks/globalSearchApiHooks';
import {
  AgreementAttachmentSearchResult,
  GlobalSearchResultType,
  SupplierAttachmentSearchResult,
} from '../../api/schema';
import { MainLayoutPaddedContentWrapper } from '../../components/MainLayout';
import { PaginatedTableHeader } from '../../components/PaginatedTableHeader';
import { TableLoadingWrapper, TableNoContent } from '../../components/tables';
import { routes } from '../../routes';
import { useTenant } from '../../states/tenantState';
import { MessageKeys } from '../../translations/messageKeys';
import { numberCompareFn } from '../../utils/compareFns';
import {
  GlobalSearchPageHeadingSearchString,
  GlobalSearchPageHeadingSearchStringPre,
  GlobalSearchPageHeadingWrapper,
} from './GlobalSearchPage.styles';
import {
  ColumnKeys,
  GlobalSearchTableRow,
  RowData,
} from './GlobalSearchTableRow';

export const GlobalSearchTable: FunctionComponent = () => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const navigate = useNavigate();
  const location = useLocation();
  let { searchString } = parse(location.search) ?? {};
  searchString = Array.isArray(searchString)
    ? searchString.join(' ')
    : searchString;

  const { data, isValidating } = useGlobalSearch(tenant.id, searchString, {
    suspense: false,
  });
  const isLoading = !data && isValidating;

  const columns = useMemo<
    Array<DataTableColumnOptions<RowData, ColumnKeys>>
  >(() => {
    return [
      {
        columnKey: 'type',
        label: t(MessageKeys.GlobalSearchTypeLabel),
        resizable: true,
        sortable: true,
        minWidth: 140,
        initialSortAscending: true,
      },
      {
        columnKey: 'name',
        label: t(MessageKeys.GlobalSearchNameLabel),
        resizable: true,
        sortable: true,
        minWidth: 140,
        initialSortAscending: false,
      },
      {
        columnKey: 'content',
        label: t(MessageKeys.GlobalSearchContentLabel),
        resizable: true,
        sortable: true,
        minWidth: 140,
        initialSortAscending: false,
      },
    ];
  }, [t]);

  const dataRows = useMemo<Array<DataTableRowOptions<RowData>>>(() => {
    return data
      ?.map((value) => ({
        id: value.id,
        data: {
          name: value.name,
          content: value.content,
          type: value.type,
          rank: value.rank,
        },
      }))
      ?.sort((a, b) => numberCompareFn(a.data.rank, b.data.rank, true));
  }, [data]);

  const handleRowClick = useCallback(
    (rowId: string) => {
      const item = data?.find((value) => value.id === rowId);
      switch (item?.type) {
        case GlobalSearchResultType.Agreement:
          navigate(
            constructUrl(routes.agreement, {
              tenantId: tenant.id,
              agreementId: item?.id,
            }),
          );
          break;
        case GlobalSearchResultType.Supplier:
          navigate(
            constructUrl(routes.supplier, {
              tenantId: tenant.id,
              supplierId: item?.id,
            }),
          );
          break;
        case GlobalSearchResultType.AgreementAttachment:
          {
            navigate(
              constructUrl(routes.agreement, {
                tenantId: tenant.id,
                agreementId: (item as AgreementAttachmentSearchResult)
                  ?.attachedTo,
              }),
            );
          }
          break;
        case GlobalSearchResultType.SupplierAttachment:
          navigate(
            constructUrl(routes.supplier, {
              tenantId: tenant.id,
              supplierId: (item as SupplierAttachmentSearchResult)?.attachedTo,
            }),
          );
          break;
      }
    },
    [data, navigate, tenant.id],
  );

  return (
    <>
      <MainLayoutPaddedContentWrapper>
        <PaginatedTableHeader
          totalResults={dataRows?.length ?? 0}
          heading={
            <GlobalSearchPageHeadingWrapper>
              {t(MessageKeys.GlobalSearchHeading)}
              {searchString?.length ? (
                <GlobalSearchPageHeadingSearchString title={searchString}>
                  <GlobalSearchPageHeadingSearchStringPre
                    className={classNames(searchString.length > 20 && 'small')}
                  >
                    {searchString}
                  </GlobalSearchPageHeadingSearchStringPre>
                </GlobalSearchPageHeadingSearchString>
              ) : null}
            </GlobalSearchPageHeadingWrapper>
          }
        />
      </MainLayoutPaddedContentWrapper>
      <DataTable rows={dataRows} columns={columns} stickyHeader>
        {(row) => (
          <GlobalSearchTableRow
            key={row.id}
            row={row}
            onClick={handleRowClick}
          />
        )}
      </DataTable>
      {dataRows?.length === 0 ? (
        <TableNoContent>{t(MessageKeys.GlobalSearchNoItems)}</TableNoContent>
      ) : null}
      {isLoading ? (
        <TableLoadingWrapper>
          <SpinningLoadingIcon size="80" />
        </TableLoadingWrapper>
      ) : null}
    </>
  );
};
