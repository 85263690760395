import { Environment } from './types';

export const environment: Environment = {
  production: true,
  featureSet: 'default',
  showErrorTraces: false,
  oauthBaseUrl: 'https://auth.fcgapps.com',
  oauthClientId: '7ejm8r5hv6qgt9gnb5sf2jdf0p',
  oauthScopes: 'profile+openid+email+https://oasys-sodg-api.fcgapps.com/init',
  apiBaseUrl: 'https://73ex4a34df.execute-api.eu-north-1.amazonaws.com/Prod',
  documentationApiUrl:
    'https://atlk65auk3.execute-api.eu-north-1.amazonaws.com/documentation/oasys',
  notificationApiBaseUrl:
    'https://ek7mp5hyo3.execute-api.eu-north-1.amazonaws.com',
};
