import { useMemo } from 'react';
import useSWR, { mutate, SWRConfiguration, useSWRConfig } from 'swr';
import { EMPTY_AGREEMENT } from '../../constants';
import {
  AgreementsQueryFilter,
  ApiGetAllAgreementsRequest,
  Metadata,
} from '../../types';
import {
  checkUnprocessableEntity,
  getAgreementsFetcherQuery,
  getResourceIdFromLocationHeader,
  useApi,
} from '../apiUtils';
import {
  Agreement,
  AgreementCreateInput,
  AgreementListItem,
  AgreementsApi,
  AgreementVersionCreateInput,
  Cabinet,
  GetAgreementResponse,
  GetAgreementVersionResponse,
  Tag,
} from '../schema';
import {
  agreementKey,
  agreementVersionKey,
  allAgreementsKey,
  agreementHistoryKey,
} from './cacheKeys';

export const useUpdateAgreementCabinet = () => {
  const tenant = 'xjk';
  const api = useApi<AgreementsApi>('AgreementsApi', tenant);
  return useMemo(
    () => ({
      updateAgreementCabinet: async (agreementId: string, cabinet: string) => {
        try {
          const result = await api.updateAgreementCabinet({
            agreementId,
            cabinet,
          });
          mutate(allAgreementsKey(tenant, null));
          return result;
        } catch (err) {
          throw new Error(`Failed to update agreement cabinet`);
        }
      },
    }),
    [api],
  );
};

export const getAllAgreements = async (
  query: ApiGetAllAgreementsRequest,
  api: AgreementsApi,
): Promise<Array<AgreementListItem>> => {
  const result = await api.listAllAgreements(query);
  return result.result;
};

export const useAgreements = (
  tenantId: string,
  filter?: AgreementsQueryFilter,
  config?: SWRConfiguration,
) => {
  const api = useApi<AgreementsApi>('AgreementsApi', tenantId);
  const query = getAgreementsFetcherQuery(filter);
  return useSWR<Array<AgreementListItem>>(
    allAgreementsKey(tenantId, query),
    () => getAllAgreements(query, api),
    { suspense: true, ...config },
  );
};

export const getAgreement = async (
  agreementId: string,
  api: AgreementsApi,
): Promise<GetAgreementResponse> => {
  const result = await api.getAgreement({ agreementId });
  return result;
};

export const useAgreement = (
  tenantId: string,
  agreementId: string,
  config?: SWRConfiguration,
) => {
  const api = useApi<AgreementsApi>('AgreementsApi', tenantId);
  return useSWR<GetAgreementResponse>(
    agreementKey(tenantId, agreementId),
    () => getAgreement(agreementId, api),
    { suspense: true, ...config },
  );
};

export const getAgreementHistory = async (
  agreementId: string,
  api: AgreementsApi,
): Promise<Array<Metadata>> => {
  const result = await api.listAllAgreementVersions({ agreementId });
  return result.result?.map(({ metadata }) => metadata) ?? [];
};

export const useAgreementHistory = (
  tenantId: string,
  agreementId: string,
  config?: SWRConfiguration,
) => {
  const api = useApi<AgreementsApi>('AgreementsApi', tenantId);
  return useSWR<Array<Metadata>>(
    agreementHistoryKey(tenantId, agreementId),
    () => getAgreementHistory(agreementId, api),
    { suspense: true, ...config },
  );
};

export const getAgreementVersion = async (
  agreementId: string,
  version: string,
  api: AgreementsApi,
): Promise<GetAgreementVersionResponse> => {
  const result = await api.getAgreementVersion({ agreementId, version });
  return result;
};

export const useAgreementVersion = (
  tenantId: string,
  agreementId: string,
  version: string,
  config?: SWRConfiguration,
) => {
  const api = useApi<AgreementsApi>('AgreementsApi', tenantId);
  return useSWR<GetAgreementVersionResponse>(
    agreementVersionKey(tenantId, agreementId, version),
    () => getAgreementVersion(agreementId, version, api),
    { suspense: true, ...config },
  );
};

export const useAgreementActions = (tenantId: string) => {
  const api = useApi<AgreementsApi>('AgreementsApi', tenantId);
  return useMemo(
    () => ({
      createAgreement: async (
        agreementName: string,
        cabinet: Cabinet,
        tags?: Array<Tag>,
        force = false,
      ): Promise<string> => {
        try {
          const agreementCreateInput: AgreementCreateInput = {
            agreement: {
              ...EMPTY_AGREEMENT,
              details: {
                ...EMPTY_AGREEMENT.details,
                name: agreementName,
              },
            },
            tags: (tags || []).map((tag) => tag.name),
          };
          const createAgreementResponse = await api.createAgreementRaw({
            cabinet: cabinet.name,
            agreementCreateInput,
            force,
          });
          mutate(allAgreementsKey(tenantId, null));
          return getResourceIdFromLocationHeader(createAgreementResponse);
        } catch (err) {
          checkUnprocessableEntity(err);
        }
        throw new Error('Could not create agreement');
      },

      updateAgreement: async (
        agreementId: string,
        currentVersion: string,
        agreement: Agreement,
        tags?: Array<Tag>,
        force = false,
      ) => {
        const agreementVersionCreateInput: AgreementVersionCreateInput = {
          agreement,
          tags: (tags || []).map((tag) => tag.name),
        };
        await api.createAgreementVersion({
          agreementId,
          version: currentVersion + 1,
          agreementVersionCreateInput,
          force,
        });
        // TODO: mutate and fix function arguments
      },

      deleteAgreement: async (agreementId: string) => {
        await api.deleteAgreement({ agreementId });
        // TODO: mutate
      },
    }),
    [api, tenantId],
  );
};

export const useAgreementArchiveActions = (tenantId: string) => {
  const api = useApi<AgreementsApi>('AgreementsApi', tenantId);
  const { mutate } = useSWRConfig();

  return useMemo(
    () => ({
      archiveAgreement: async (agreementId: string) => {
        try {
          const result = await api.archiveAgreement({ agreementId });
          mutate(allAgreementsKey(tenantId, null));
          return result;
        } catch (err) {
          throw new Error(`Failed to archive agreement`);
        }
      },
      unArchiveAgreement: async (agreementId: string) => {
        try {
          const result = await api.unarchiveAgreement({ agreementId });
          mutate(allAgreementsKey(tenantId, null));
          return result;
        } catch (err) {
          throw new Error(`Failed to unarchive agreement`);
        }
      },
    }),
    [api, mutate, tenantId],
  );
};
