import { MessageLevel } from '@fcg-tech/regtech-types';
import styled, { css } from 'styled-components';
import { excludeProps } from '../../utils';

export const FlashNotificationIconWrapper = styled.div`
  position: absolute;
  left: 1rem;
  top: 55%;
  transform: translateY(-50%);
`;

export const FlashNotificationWrapper = styled.div.withConfig(
  excludeProps('level'),
)<{ level?: MessageLevel }>`
  ${({ theme, level }) => css`
    display: inline-block;
    position: relative;
    padding: 1rem 2rem 1rem 4.4rem;
    font-size: 1.4rem;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
    border-radius: 3rem;
    background-color: ${theme?.colors?.[level]?.main ?? '#ddd'};
    color: ${theme?.colors?.[level]?.contrast ?? '#000'};

    max-width: 35vw;

    @media screen and (max-width: ${theme.breakpoints.narrowDesktop}px) {
      max-width: 50vw;
    }

    @media screen and (max-width: ${theme.breakpoints.mobile}px) {
      max-width: 90vw;
    }

    ${level === 'error' || level === 'warning'
      ? css`
          & > ${FlashNotificationIconWrapper} {
            left: 1.4rem;
            top: 52%;
          }
        `
      : ''}
  `}
`;

export const FlashNotificationListWrapper = styled.div`
  position: fixed;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;

  & > ${FlashNotificationWrapper} + ${FlashNotificationWrapper} {
    margin-top: 1rem;
  }
`;
