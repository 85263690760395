import { Stringifiable, stringify, StringifyOptions } from 'query-string';
import {
  checkResponse,
  getAccessToken,
  ConstructUrlParams,
} from '@fcg-tech/regtech-api-utils';
import { Endpoint } from '@fcg-tech/regtech-types/oasys';
import { useMemo } from 'react';
import { convertAgreementsQueryFilterForAPi } from '../converters/filterConverters';
import { environment } from '../environments/environment';
import { ApiGetAllAgreementsRequest, AgreementsQueryFilter } from '../types';
import {
  ApiResponse,
  Configuration,
  JSONApiResponse,
  Middleware,
  ResponseContext,
} from './schema';
import * as api from './schema/apis';
import { UnprocessableEntityError } from './apiErrors';
import { getLogger, getResponsePayload } from './logger';

export const getEndpointUrl = <
  Q = Record<string, Stringifiable | Stringifiable[]>,
>(
  endpoint: Endpoint | string,
  args?: ConstructUrlParams,
  query?: Q,
): string => {
  return constructUrl(
    `${environment.apiBaseUrl}/{tenantId}${endpoint}`,
    args,
    query,
  );
};

/**
 * DO NOT USE.
 * This was added only for getEndpointUrl in order to parse args correctly
 * For routing etc. please use constructUrl from @fcg-tech/regtech-api-utils instead
 *
 * This will be removed again once all containers use the new api.
 */
export const constructUrl = <
  T = Record<string, Stringifiable>,
  Q = Record<string, Stringifiable | Stringifiable[]>,
>(
  url: string,
  args?: T,
  query?: Q,
  queryOptions: StringifyOptions = {},
): string => {
  let finalUrl = Object.entries(args ?? {})
    .filter(([, value]) => Boolean(value))
    .reduce<string>(
      (r, [key, value]) =>
        r
          .replace(`:${key}`, value.toString())
          .replace(`{${key}}`, value.toString()),
      url,
    );

  if (query && Object.keys(query).length) {
    finalUrl = `${finalUrl}?${stringify(
      query as unknown as Record<string, Stringifiable>,
      { arrayFormat: 'comma', ...queryOptions },
    )}`;
  }

  return finalUrl;
};

export const getAgreementsFetcherQuery = (
  filter?: AgreementsQueryFilter,
): ApiGetAllAgreementsRequest => {
  return {
    skip: undefined,
    limit: undefined,
    ...convertAgreementsQueryFilterForAPi(filter),
  };
};

export const checkResponseMiddleware: Middleware = {
  post: async ({ response }: ResponseContext) => {
    if (checkResponse(response)) {
      return Promise.resolve(response);
    }
    if (!checkAuthError(response)) {
      getLogger().error('An error occured', getResponsePayload(response));
      throw response;
    }
  },
};

export const useConfiguration = (tenantId: string): Configuration => {
  const accessToken = getAccessToken();
  const config = useMemo(() => {
    return new Configuration({
      basePath: `${environment.apiBaseUrl}/${tenantId}`,
      accessToken,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      middleware: [checkResponseMiddleware],
    });
  }, [accessToken, tenantId]);
  return config;
};

type Apis = keyof typeof api;

export const useApi = <T>(apiKey: Apis, tenantId: string): T => {
  const configuration = useConfiguration(tenantId);
  const apiInstance = useMemo(() => {
    return new api[apiKey](configuration);
  }, [apiKey, configuration]);
  return apiInstance as unknown as T;
};

export const getResponse = (err: unknown): Response | null => {
  if (err instanceof Response) {
    return err;
  }
  if (err instanceof JSONApiResponse) {
    return err.raw;
  }
  return null;
};

export const checkAuthError = (err: unknown) => {
  const response = getResponse(err);
  if (response?.status === 401) {
    window.document.location.href = '/login'; // TODO: Redirect URI
    return true;
  }
  return false;
};

export const getResourceIdFromLocationHeader = <T>(
  response: ApiResponse<T>,
) => {
  const location = (response.raw.headers.get('location') as string)?.split('/');
  return location?.length ? location[location.length - 1] : null;
};

export const checkUnprocessableEntity = (err: unknown, message?: string) => {
  const response = getResponse(err);
  if (response?.status === 422) {
    throw new UnprocessableEntityError(message);
  }
  return false;
};
