import {
  NotificationsApi,
  NotificationSettingsApi,
  Configuration,
  Middleware,
  ResponseContext,
} from '../api/schema';
import {
  checkResponse,
  getAccessToken,
  checkAuthError,
} from '@fcg-tech/regtech-api-utils';
import { useMemo } from 'react';

export const checkResponseMiddleware: Middleware = {
  post: async ({
    response,
  }: ResponseContext): Promise<Response | undefined> => {
    if (response) {
      if (checkResponse(response)) {
        return Promise.resolve(response);
      }
      if (!checkAuthError(response)) {
        throw response;
      }
    }
    return Promise.reject('No response or error');
  },
};

export const useConfiguration = (baseEndpoint: string) => {
  const accessToken = getAccessToken();
  const config = useMemo(() => {
    return new Configuration({
      basePath: baseEndpoint,
      accessToken,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      middleware: [checkResponseMiddleware],
    });
  }, [accessToken, baseEndpoint]);
  return config;
};

export const useNotificationsApi = (baseEndpoint: string) => {
  const configuration = useConfiguration(baseEndpoint);
  return useMemo(() => new NotificationsApi(configuration), [configuration]);
};

export const useNotificationsSettingsApi = (baseEndpoint: string) => {
  const configuration = useConfiguration(baseEndpoint);
  return useMemo(
    () => new NotificationSettingsApi(configuration),
    [configuration],
  );
};
