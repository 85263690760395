import React from 'react';
import { useAsync } from 'react-async';
import { Loader } from '@fcg-tech/regtech-components';
import { useTenant } from '../../states/tenantState';
import { loadUsers } from '../../api';
import { ErrorMessage } from '../../components/ErrorBoundary';
import { UsersPage } from './components/UsersPage';

export const UsersContainer = () => {
  const tenant = useTenant();
  const tenantId = tenant.id;

  const usersReq = useAsync({
    promiseFn: loadUsers,
    tenantId,
  });

  if (usersReq.isLoading) {
    return <Loader message="Loading users" />;
  }

  if (usersReq.error) {
    return <ErrorMessage error={usersReq.error} />;
  }

  return <UsersPage users={usersReq.data.users} />;
};
