import { DataTable, DataTableComponent } from '@fcg-tech/regtech-datatable';
import styled from 'styled-components';
import { AgreementListItem } from '../../types';

export const AgreementTableWrapper = styled.div`
  max-height: 100%;
  flex: 1;
  overflow: hidden;
  position: relative;
`;

export const AgreementTableNoContent = styled.div`
  position: absolute;
  top: 40%;
  left: 0;
  width: 100%;
  text-align: center;
  pointer-events: none;
  font-size: 2.2rem;
  font-weight: 600;
`;

export const AgreementTableLoadingWrapper = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  display: flex;

  align-items: center;
  justify-content: center;
  flex: 1;
  z-index: 1;
`;

export const AgreementTableIconContent = styled.div`
  display: flex;
  align-items: center;
`;

export const AgreementTableTextContent = styled.div`
  display: -webkit-box;
  padding-top: 0.5rem;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
  line-height: 1.5;
  text-overflow: ellipsis;
  color: #333;
`;

export const AgreementTableDataTable = styled<
  DataTableComponent<AgreementListItem>
>(DataTable)`
  flex: 1;
  border-top: 1px solid #ccc;

  .header .th {
    padding-top: 1rem;
    padding-bottom: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
  }

  .header .tr {
    background-color: white;
  }

  .rowgroup:not(.resizing-columns) > .tr {
    cursor: pointer;
    &:hover .td {
      background-color: #e7dcbf;
    }
  }

  .tr .td {
    font-size: 1.2rem;
    overflow: hidden;
    background-color: white;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .td:first-child,
  .th:first-child {
    padding-left: 1.6rem;
  }

  .tr.archived
    > .td
    > ${AgreementTableTextContent},
    .tr.archived
    > .td
    > ${AgreementTableIconContent} {
    opacity: 0.4;
  }
`;

export const AgreementTableAndFilterWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: stretch;
  min-height: 0;
`;
