import { IconButton } from '../Button';
import { H3 } from '../Headings';
import styled, { css } from 'styled-components';
import { ChevronRightIcon } from '../Icons';

export const DashboardCardWrapper = styled.div`
  ${({ theme }) => css`
    border-radius: 0.4rem;
    border: 1px solid
      ${theme?.palette?.MainColorAccent?.dark ?? 'rgba(0, 0, 0, 0.15)'};
    margin: 0.4rem;
    background-color: ${theme.palette?.MainColorAccent?.light ?? '#fff'};
    display: flex;
    flex-direction: column;

    ${theme?.elements?.dashboard?.card}
  `}
`;

export const DashboardIconButton = styled(IconButton)`
  ${({ theme }) => css`
    height: 3.2rem;
    min-width: 3.2rem;
    width: auto;
    margin-left: auto;
    border-radius: 900px/1000px;
    padding-left: 1rem;
    padding-right: 0.6rem;
    outline: 0;
    color: black;
    position: relative;
    top: 1px;
    &:focus {
      border: 0;
    }
    ${theme?.elements?.dashboard?.headerButton}
  `}
`;

export const DashboardHeaderNavIconButtonChevron = styled(ChevronRightIcon)`
  margin-left: 0.8rem;
`;

export const DashboardCardHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    border-bottom: 1px solid
      ${theme.palette?.MainColorAccent?.dark ?? 'rgba(0, 0, 0, 0.15)'};
    margin: 0rem 1rem;
    ${theme?.elements?.dashboard?.header}
  `}
`;

export const DashboardCardTitle = styled(H3)`
  ${({ theme }) => css`
    flex: 1 0 0;
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 0;
    padding: 1.4rem 0rem 1.2rem 1.2rem;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    ${theme?.elements?.dashboard?.headerTitle}
  `}
`;

export const DashboardCardTitleIconWrapper = styled.div`
  margin-right: 1rem;
`;

export const DashboardCardBody = styled.div`
  ${({ theme }) => css`
    padding: 1.6rem 2rem;
    flex: 1;
    display: flex;
    ${theme?.elements?.dashboard?.body}
  `}
`;
