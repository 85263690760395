import { classNames } from '@fcg-tech/regtech-utils';
import React from 'react';
import { IconProps } from '../../types/icons';

export const ArrowUpIcon = ({
  size = '24',
  color = 'currentColor',
  className,
  ...props
}: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    width={size}
    height={size}
    stroke={color}
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    fill="none"
    className={classNames(className, 'icon')}
    {...props}
  >
    <line x1="12" y1="19" x2="12" y2="5"></line>
    <polyline points="5 12 12 5 19 12"></polyline>
  </svg>
);
