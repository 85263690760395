import React from 'react';
import styled, { css, DefaultTheme, StyledComponent } from 'styled-components';

type ItemListElement = StyledComponent<
  'ul',
  DefaultTheme,
  Record<string, unknown>,
  never
> & {
  /**
   * @deprecated Use exported version instead
   */
  Item?: StyledComponent<'li', DefaultTheme, Record<string, unknown>, never>;
};

export const ItemList: ItemListElement = styled.ul`
  ${({ children }) => css`
    margin: 0;
    padding: 0 0 0 ${React.Children.count(children) > 1 ? '1.4rem' : '0'};
    list-style-type: ${React.Children.count(children) > 1 ? 'bullets' : 'none'};
  `}
`;

export const ItemListItem = styled.li`
  font-size: 1.4rem;
  margin: 0.7rem 0;
`;

ItemList.Item = ItemListItem;
