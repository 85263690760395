import styled from 'styled-components';
import { excludeProps } from '../../utils';
import { UnstyledButton } from '../Button';
import { ChevronRightIcon } from '../Icons';

export const SideNavigationExpandButton = styled(UnstyledButton)`
  position: absolute;
  right: 0.5rem;
  top: 0;
  bottom: 0;
  width: 2.5rem;
  z-index: 2;

  opacity: 0.5;

  will-change: opacity;

  &:hover {
    opacity: 1;
  }
  ${({ theme }) => theme?.elements?.sideNavigation?.expandButton?.wrapper}

  .collapsed & {
    display: none;
  }
`;

export const SideNavigationExpandButtonChevronIcon = styled(ChevronRightIcon)
  .attrs({ size: '18' })
  .withConfig(excludeProps('down'))<{ down?: boolean }>`
  transform: rotate(${({ down }) => (down ? '90deg' : '0')});
  ${({ theme }) => theme?.elements?.sideNavigation?.expandButton?.icon}
`;
