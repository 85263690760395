import { useTranslation } from 'react-i18next';
import React, { useCallback } from 'react';
import { classNames } from '@fcg-tech/regtech-utils';
import { Checkbox } from '../Checkbox';
import { TableRow, TableCell } from './DataTable.styles';
import {
  areRowPropsEqual,
  DataTableColumnKeys,
  DataTableColumnOptions,
  DataTableRowOptions,
  DataTableSortOrder,
} from './dataTableUtils';

export interface DataTableRowProps<P = any, CK = any> {
  row: DataTableRowOptions<P>;
  columns?: Array<DataTableColumnOptions<P, CK>>;
  sortOrder?: DataTableSortOrder<CK>;
  readOnly?: boolean;
  className?: string;
  onClick?: (rowId: string) => void;
  onSelect?: (rowId: string, selected: boolean) => void;
}

export const DataTableRow = React.memo(
  React.forwardRef<
    HTMLTableRowElement,
    React.PropsWithChildren<DataTableRowProps>
  >(function <P = any, CK = any>(
    {
      row,
      columns,
      readOnly,
      sortOrder,
      className,
      onClick,
      onSelect,
      children,
    }: React.PropsWithChildren<DataTableRowProps<P>>,
    ref: React.MutableRefObject<HTMLTableRowElement>,
  ): JSX.Element {
    const { t } = useTranslation();
    const handleClick = useCallback(
      (event: React.MouseEvent) => {
        const tagName = (event.target as HTMLElement)?.tagName?.toLowerCase();
        if (tagName !== 'button' && !event.defaultPrevented) onClick?.(row.id);
      },
      [row, onClick],
    );

    const handleSelect = useCallback(
      (checked: boolean) => onSelect?.(row.id, checked),
      [onSelect, row],
    );

    return (
      <TableRow
        key={row.id}
        data-key={row.id}
        ref={ref}
        clickable={Boolean(!readOnly && onClick)}
        className={className}
        onClick={!readOnly ? handleClick : undefined}
        readOnly={readOnly}
        selected={row.selected}
      >
        {children ??
          columns.map(({ columnKey, fixed, getContent }) => (
            <TableCell
              key={`${row.id}-${columnKey}`}
              className={classNames(
                !fixed && 'fog',
                fixed && 'fixed',
                sortOrder?.columnKey === columnKey && 'sorted',
              )}
            >
              {columnKey === DataTableColumnKeys.CheckboxSelection ? (
                <Checkbox
                  checked={row.selected}
                  onChange={handleSelect}
                  disabled={readOnly}
                />
              ) : getContent ? (
                getContent(t, row, columnKey)
              ) : (
                row.data?.[columnKey]
              )}
            </TableCell>
          ))}
      </TableRow>
    );
  }),
  areRowPropsEqual,
);
