import { useEffect, useState } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';

export const useUploadZone = (opts: DropzoneOptions) => {
  const [isDragOverViewport, setIsDragOverViewport] = useState(false);
  useEffect(() => {
    const onEnter = (event: DragEvent) => {
      const dt = event.dataTransfer;
      if (
        dt.types &&
        (dt.types.indexOf
          ? dt.types.indexOf('Files') !== -1
          : dt.types.includes('Files'))
      ) {
        setIsDragOverViewport(true);
      }
    };

    const onExit = (event: DragEvent) => {
      if (!event.relatedTarget) {
        setIsDragOverViewport(false);
      }
    };

    const onDrop = (event: DragEvent) => {
      setIsDragOverViewport(false);
    }

    document.addEventListener('dragover', onEnter);
    document.addEventListener('dragleave', onExit);
    document.addEventListener('drop', onDrop);
    return () => {
      document.removeEventListener('dragover', onEnter);
      document.removeEventListener('dragleave', onExit);
      document.removeEventListener('drop', onDrop);
    };
  });

  const r = useDropzone(opts);
  return {
    ...r,
    isDragOverViewport,
  };
};
