import styled from 'styled-components';
import { InfoCircle } from '@fcg-tech/regtech-components';

export const SubcontractorsFieldLabelWrapper = styled.div`
  display: flex;
`;

export const SubcontractorsFieldLabelInfoCircle = styled(InfoCircle)`
  margin-left: 1.4rem;
`;
