import React, { useCallback, useMemo, useState } from 'react';
import {
  ButtonIcon,
  TrashIcon,
  IconButton,
  ExpansionPanel,
  PlusCircleIcon,
  PrimaryButton,
  NumberField,
  ExpansionPanelProps,
} from '@fcg-tech/regtech-components';
import update from 'immutability-helper';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import { joinMulti } from '../../utils/arrayHelpers';
import {
  NotificationsSettingsDateDescription,
  NotificationsSettingsDateDescriptionWrapper,
  NotificationsSettingsDateSectionHeading,
  NotificationsSettingsDateDaysAheadWrapper,
  NotificationsSettingsDateDaysAheadInner,
  NotificationsSettingsDateDaysAheadFieldWrapper,
} from './NotificationsSettingsDate.styles';

interface NotificationsSettingsDateProps {
  name: string;
  title: ExpansionPanelProps['title'];
  description: string;
  daysAhead: Array<number>;
  expanded: ExpansionPanelProps['expanded'];
  onToggle: ExpansionPanelProps['onToggle'];
  onChange: (daysAhead: Array<number>) => void;
}

export const NotificationsSettingsDate = ({
  name,
  title,
  description,
  daysAhead,
  expanded,
  onToggle,
  onChange,
}: NotificationsSettingsDateProps) => {
  const { t } = useTranslation();
  const [editState, setEditState] = useState([...daysAhead]);

  const daysAheadField = useMemo(() => `${name}.daysAhead`, [name]);

  const subtitle = useMemo(() => {
    if (editState?.length) {
      const reminders = joinMulti(editState, ', ', ' & ');
      return t(MessageKeys.NotificationsSettingsReminerSentLabel, {
        reminders,
      });
    }
  }, [editState, t]);

  const handleReminderChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: number) => {
      const index = event.currentTarget.name.slice(-1);
      if (event.currentTarget.value === '') {
        const daysAheadUpdated = update(editState, {
          [index]: { $set: null },
        });
        setEditState(daysAheadUpdated);
        onChange(daysAheadUpdated);
      } else {
        const daysAheadUpdated = update(editState, {
          [index]: { $set: value },
        });
        setEditState(daysAheadUpdated);
        onChange(daysAheadUpdated);
      }
    },
    [editState, onChange],
  );

  const handleReminderBlur = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const index = event.currentTarget.name.slice(-1);
      if (event.currentTarget.value === '') {
        const daysAheadUpdated = update(editState, { [index]: { $set: 0 } });
        setEditState(daysAheadUpdated);
        onChange(daysAheadUpdated);
      }
    },
    [setEditState, onChange, editState],
  );

  const handleReminderAdd = useCallback(() => {
    const daysAheadUpdated = [...(editState ?? []), 0];
    setEditState(daysAheadUpdated);
    onChange(daysAheadUpdated);
  }, [setEditState, onChange, editState]);

  const handleReminderRemove = useCallback(
    (index: number) => {
      if (editState) {
        const daysAheadUpdated = [
          ...editState.slice(0, index),
          ...editState.slice(index + 1),
        ];
        setEditState(daysAheadUpdated);
        onChange(daysAheadUpdated);
      }
    },
    [setEditState, onChange, editState],
  );

  const handleKeyDown = useCallback((event) => {
    if (event.key === ' ' || event.key === 'Enter') {
      event.preventDefault();
    }
  }, []);

  return (
    <ExpansionPanel
      id={name}
      title={title}
      subtitle={subtitle}
      onToggle={onToggle}
      expanded={expanded}
    >
      <NotificationsSettingsDateDescriptionWrapper>
        <NotificationsSettingsDateDescription>
          {description}
        </NotificationsSettingsDateDescription>
      </NotificationsSettingsDateDescriptionWrapper>
      <>
        <NotificationsSettingsDateSectionHeading>
          {t(MessageKeys.NotificationsSettingsNumberDaysAheadLabel)}
        </NotificationsSettingsDateSectionHeading>
        <NotificationsSettingsDateDaysAheadWrapper>
          {editState.map((value, index) => (
            <NotificationsSettingsDateDaysAheadInner key={`daysahead-${index}`}>
              <NotificationsSettingsDateDaysAheadFieldWrapper>
                <NumberField
                  id={`${daysAheadField}.${index}`}
                  name={`${daysAheadField}.${index}`}
                  value={value}
                  onChange={handleReminderChange}
                  onBlur={handleReminderBlur}
                  onKeyDown={handleKeyDown}
                  selectAllOnFocus
                />
              </NotificationsSettingsDateDaysAheadFieldWrapper>
              <IconButton onClick={() => handleReminderRemove(index)}>
                <TrashIcon size="16" />
              </IconButton>
            </NotificationsSettingsDateDaysAheadInner>
          ))}
        </NotificationsSettingsDateDaysAheadWrapper>
        <br />
        <PrimaryButton type="button" onClick={handleReminderAdd}>
          <ButtonIcon>
            <PlusCircleIcon size="16" />
          </ButtonIcon>
          <span>{t(MessageKeys.LabelAdd)}</span>
        </PrimaryButton>
      </>
    </ExpansionPanel>
  );
};
