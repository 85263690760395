import { isFilterEmpty } from '@fcg-tech/regtech-filter';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { SWRResponse } from 'swr';
import { useAgreements } from '../api/hooks/agreementsApiHooks';
import { useSuppliers } from '../api/hooks/suppliersApiHooks';
import { DEFAULT_PAGE_SIZE } from '../constants';
import { countryState } from '../states/countryState';
import { useTenant } from '../states/tenantState';
import {
  AgreementFetchContext,
  AgreementFilterValues,
  AgreementListItem,
  PaginatedItems,
  SupplierFilterValues,
  SupplierListItem,
} from '../types';
import {
  agreementSorter,
  filterAgreements,
  filterSuppliers,
  getAgreementSortBy,
  getSupplierSortBy,
  supplierSorter,
} from './filterHelpers';

type UsePaginatedAgreementsInterface = SWRResponse<
  Array<AgreementListItem>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
> & {
  paginatedData: PaginatedItems<AgreementListItem>;
};

export const usePaginatedAgreements = (
  context: AgreementFetchContext, // may need to merge query and filter when adding tabs
  page = 1,
  pageSize = DEFAULT_PAGE_SIZE,
): UsePaginatedAgreementsInterface => {
  const tenant = useTenant();

  const swrResponse = useAgreements(tenant.id, context.query, {
    suspense: false,
  });

  const items = useMemo(() => {
    const { orderBy, ...filter } = context.filter ?? {};
    let agreements = [...(swrResponse.data || [])];

    if (!isFilterEmpty(filter as AgreementFilterValues)) {
      agreements = filterAgreements(agreements, filter);
    }

    if (orderBy) {
      const sortBy = getAgreementSortBy(orderBy);
      agreements = agreements.sort((a, b) => agreementSorter(a, b, sortBy));
    }

    return agreements;
  }, [context.filter, swrResponse.data]);

  const paginatedData = useMemo<PaginatedItems<AgreementListItem>>(() => {
    const skip = (page - 1) * pageSize;
    const limit = pageSize;

    return {
      items: items.slice(skip, skip + limit),
      pagination: {
        totalResults: items.length,
        skip,
        limit,
      },
    };
  }, [items, page, pageSize]);

  return {
    ...swrResponse,
    data: items,
    paginatedData: swrResponse.data ? paginatedData : undefined,
  };
};

type UsePaginatedSuppliersInterface = SWRResponse<
  Array<SupplierListItem>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
> & {
  paginatedData: PaginatedItems<SupplierListItem>;
};

export const usePaginatedSuppliers = (
  filter: SupplierFilterValues,
  page = 1,
  pageSize = DEFAULT_PAGE_SIZE,
): UsePaginatedSuppliersInterface => {
  const tenant = useTenant();
  const countries = useRecoilValue(countryState);

  const swrResponse = useSuppliers(tenant.id, { suspense: false });

  const items = useMemo(() => {
    const { orderBy, ...rest } = filter ?? {};
    let suppliers = [...(swrResponse.data || [])];

    if (!isFilterEmpty(rest as SupplierFilterValues)) {
      suppliers = filterSuppliers(suppliers, rest);
    }

    if (orderBy) {
      const sortBy = getSupplierSortBy(orderBy);
      suppliers = suppliers.sort((a, b) =>
        supplierSorter(a, b, sortBy, countries),
      );
    }

    return suppliers;
  }, [countries, filter, swrResponse.data]);

  const paginatedData = useMemo<PaginatedItems<SupplierListItem>>(() => {
    const skip = (page - 1) * pageSize;
    const limit = pageSize;

    return {
      items: items.slice(skip, skip + limit),
      pagination: {
        totalResults: items.length,
        skip,
        limit,
      },
    };
  }, [items, page, pageSize]);

  return {
    ...swrResponse,
    data: items,
    paginatedData: swrResponse.data ? paginatedData : undefined,
  };
};
