import {
  getAccessToken,
  parseJwt,
  useAuthContext,
} from '@fcg-tech/regtech-auth';
import { differenceInMilliseconds, fromUnixTime } from 'date-fns';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { SESSION_TIMEOUT_WARNING_MS } from '../../constants';
import { SessionTimeoutModal } from './SessionTimeoutModal';

export const SessionTimeout: FunctionComponent = () => {
  const { reAuthenticate } = useAuthContext();

  const [warningCountdownMS, setWarningCountdownMS] = useState(0);
  const [showWarningModal, setShowWarningModal] = useState(false);

  const disabled = useRef(false);
  const intervalRef = useRef<NodeJS.Timeout>(null);

  useEffect(() => {
    const accessToken = getAccessToken(false);
    if (!accessToken) {
      return;
    }
    const parsedJwt = parseJwt(accessToken);

    const poll = () => {
      if (parsedJwt?.exp < Date.now() / 1000) {
        // reAuthenticate();
      } else {
        const timeLeftMS = differenceInMilliseconds(
          fromUnixTime(parsedJwt?.exp),
          fromUnixTime(new Date().getTime() / 1000),
        );
        if (!disabled.current && timeLeftMS <= SESSION_TIMEOUT_WARNING_MS) {
          disabled.current = true;
          setWarningCountdownMS(timeLeftMS);
          setShowWarningModal(true);
        }
      }
    };

    poll();
    intervalRef.current = setInterval(poll, 1000);
    return () => clearInterval(intervalRef.current);
  }, [reAuthenticate]);

  const handleRequestClose = useCallback(() => {
    setWarningCountdownMS(0);
    setShowWarningModal(false);
  }, []);

  if (showWarningModal) {
    return (
      <SessionTimeoutModal
        countdownMS={warningCountdownMS}
        onRequestClose={handleRequestClose}
        onSubmit={reAuthenticate}
      />
    );
  }

  return null;
};
