import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardTitle,
  loadDataTableSortOrder,
  PageHeader,
  PageHeaderActions,
  PageLayout,
  PageLayoutPaddedContent,
  SecondaryButton,
  useAccessControl,
  useEditContext,
  useDataTableSortable,
  PrimaryButton,
  TooltipNext as Tooltip,
  PageHeaderActionButton,
} from '@fcg-tech/regtech-components';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { useTranslation } from 'react-i18next';
import { History, Trash2 } from 'lucide-react';
import { MessageKeys } from '../../../translations/messageKeys';
import {
  attachmentPropType,
  agreementPropType,
  supplierPropType,
} from '../../../propTypes';
import { routes } from '../../../routes';
import { generalAgreementsListEntitiesCompareFn } from '../../../utils/compareFns';
import { getGeneralAgreementsListSortOrder } from '../../../utils/listDataTableHelpers';
import { formatZonedDateTime } from '../../../utils/formatters';
import { useTenant } from '../../../states/tenantState';
import { SUPPLIER_SKELETON } from '../../../constants';
import { Attachments } from '../../../components/Attachments';
import { DirtyFormCheck } from '../../../components/DirtyFormCheck';
import {
  SupplierDetailsSubForm,
  SupplierAssessmentSubForm,
} from '../../../components/SubForms';
import { ListDataTable } from '../../../components/ListDataTable';

export const EditSupplierPage = ({
  supplier,
  attachments,
  agreements,
  isSaving,
  onEdit,
  onCancel,
  onSave,
  onDelete,
}) => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const tenantId = tenant.id;
  const isEditEnabled = useEditContext();
  const navigate = useNavigate();

  const canEdit = useAccessControl(supplier.permissions, [
    'oasys:SupplierEdit',
  ]);

  const canDelete = useAccessControl(supplier.permissions, [
    'oasys:SupplierDelete',
  ]);

  const canViewHistory = useAccessControl(supplier.permissions, [
    'oasys:SupplierHistory',
  ]);

  const pageTitle = React.useMemo(
    () => supplier.data.supplierName || t(MessageKeys.LabelUnnamed),
    [supplier.data.supplierName, t],
  );

  const initialValues = React.useMemo(
    () => ({
      metadata: supplier.metadata,
      data: {
        ...SUPPLIER_SKELETON.data,
        ...supplier.data,
        supplierAssessment: {
          ...SUPPLIER_SKELETON.data.supplierAssessment,
          ...supplier.data?.supplierAssessment,
        },
      },
    }),
    [supplier],
  );

  const columns = React.useMemo(
    () => [
      {
        columnKey: 'name',
        label: t(MessageKeys.LabelName),
        resizable: false,
        sortable: true,
        initialSortAscending: true,
      },
      {
        columnKey: 'agreementType',
        label: t(MessageKeys.LabelAgreementType),
        resizable: false,
        sortable: true,
        initialSortAscending: false,
      },
      {
        columnKey: 'functionCategory',
        label: t(MessageKeys.LabelCategory),
        resizable: false,
        sortable: true,
        initialSortAscending: false,
      },
      {
        columnKey: 'contractOwner',
        label: t(MessageKeys.LabelContractOwner),
        resizable: false,
        sortable: true,
        initialSortAscending: false,
      },
    ],
    [t],
  );

  const rows = React.useMemo(
    () =>
      agreements?.map((agreement) => ({
        id: agreement.metadata.id,
        data: agreement,
      })) ?? [],
    [agreements],
  );

  const { sortedRows, sortOrder, handleSortOrderChange } = useDataTableSortable(
    'agreements-agreementType-table-sort-order',
    rows,
    {
      sortOrder:
        loadDataTableSortOrder('agreements-agreementType-table-sort-order') ??
        getGeneralAgreementsListSortOrder('nameAsc'),
      compareFn: generalAgreementsListEntitiesCompareFn,
    },
  );

  const handleClickRow = React.useCallback(
    (id) =>
      navigate(constructUrl(routes.agreement, { tenantId, agreementId: id })),
    [navigate, tenantId],
  );

  return (
    <Formik initialValues={initialValues} onSubmit={onSave}>
      {({ dirty, handleSubmit, resetForm }) => (
        <PageLayout>
          <DirtyFormCheck dirty={dirty && isEditEnabled} />
          <PageHeader title={pageTitle} breadcrumbs={null}>
            {isEditEnabled ? (
              <>
                <SecondaryButton
                  onClick={() => {
                    resetForm();
                    onCancel();
                  }}
                >
                  {t(MessageKeys.LabelCancel)}
                </SecondaryButton>
                <PrimaryButton
                  onClick={handleSubmit}
                  loading={isSaving}
                  disabled={!dirty || isSaving}
                >
                  {t(MessageKeys.LabelSubmit)}
                </PrimaryButton>
              </>
            ) : (
              <>
                <PageHeaderActions>
                  {canViewHistory ? (
                    <Tooltip
                      html
                      content={t(
                        MessageKeys.EditSupplierCurrentVersionLabel,
                        {
                          version: supplier.metadata.version,
                          modified: formatZonedDateTime(
                            supplier.metadata.modified,
                          ),
                          modifiedBy: supplier.metadata.modifiedBy,
                        },
                      )}
                      placement="bottom-end"
                    >
                      <PageHeaderActionButton
                        to={constructUrl(routes.supplierVersions, {
                          tenantId,
                          supplierId: supplier.metadata.id,
                        })}
                      >
                        <History size="24" />
                      </PageHeaderActionButton>
                    </Tooltip>
                  ) : null}
                  {canDelete ? (
                    <PageHeaderActionButton onClick={onDelete}>
                      <Trash2 size="24" />
                    </PageHeaderActionButton>
                  ) : null}
                </PageHeaderActions>
                {canEdit ? (
                  <PrimaryButton onClick={onEdit}>
                    {t(MessageKeys.LabelEdit)}
                  </PrimaryButton>
                ) : null}
              </>
            )}
          </PageHeader>
          <PageLayoutPaddedContent>
            <form onSubmit={handleSubmit}>
              <Card>
                <CardBody>
                  <CardTitle>{t(MessageKeys.LabelDetails)}</CardTitle>
                  <SupplierDetailsSubForm />
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <ListDataTable
                    title={t(MessageKeys.LabelAgreements)}
                    emptyTableText={t(
                      MessageKeys.LabelNoAgreementsFound,
                    )}
                    columns={columns}
                    data={sortedRows}
                    sortOrder={sortOrder}
                    handleSortOrderChange={handleSortOrderChange}
                    handleClickRow={handleClickRow}
                  />
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle>
                    {t(
                      MessageKeys.EditSupplierSupplierAssessmentLabel,
                    )}
                  </CardTitle>
                  <SupplierAssessmentSubForm attachments={attachments} />
                </CardBody>
              </Card>
              {supplier.metadata && (
                <Card>
                  <CardBody>
                    <CardTitle>
                      {t(MessageKeys.LabelOtherDocumentation)}
                    </CardTitle>
                    <Attachments attachments={attachments} section="other" />
                  </CardBody>
                </Card>
              )}
            </form>
          </PageLayoutPaddedContent>
        </PageLayout>
      )}
    </Formik>
  );
};

EditSupplierPage.propTypes = {
  supplier: supplierPropType.isRequired,
  attachments: PropTypes.arrayOf(attachmentPropType),
  agreements: PropTypes.arrayOf(agreementPropType),
  isSaving: PropTypes.bool,
  onEdit: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
};

EditSupplierPage.defaultProps = {
  attachments: [],
  agreements: [],
  isSaving: false,
  onEdit: null,
  onCancel: null,
  onSave: null,
  onDelete: null,
};
