import { AuthError, getResponse } from '@fcg-tech/regtech-api-utils';
import { Component, PropsWithChildren } from 'react';
import { ErrorBoundaryMessage } from './ErrorBoundary.styles';

export class ErrorBoundary extends Component<
  PropsWithChildren<{ silent?: boolean }>
> {
  override state = {
    hasError: false,
    error: undefined,
  };

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: Boolean(error), error };
  }

  override componentDidCatch(error, errorInfo) {
    // TODO. Really stupid
  }

  override render() {
    if (this.state.hasError) {
      if (this.props.silent) {
        return null;
      }
      if (this.state.error instanceof AuthError) {
        throw this.state.error;
      }
      if (
        this.state.error instanceof Response &&
        this.state.error.status === 401
      ) {
        throw new AuthError('Not authenticated: 401');
      }
      const response = getResponse(this.state.error);
      if (response) {
        if (response.status === 404) {
          return <ErrorBoundaryMessage>Error 404</ErrorBoundaryMessage>;
        }
        if (response.status === 403) {
          return <ErrorBoundaryMessage>Permission Denied</ErrorBoundaryMessage>;
        }
      }
      return (
        <ErrorBoundaryMessage>
          Something went wrong, please try again later.
        </ErrorBoundaryMessage>
      );
    }

    return this.props.children;
  }
}
