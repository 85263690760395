import {
  Modal,
  ModalButtonRow,
  ModalHeader,
  ModalTitle,
  PrimaryButton,
  SecondaryButton,
} from '@fcg-tech/regtech-components';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import {
  SessionTimeoutModalBody,
  SessionTimeoutModalTextContent,
} from './SessionTimeoutModal.styles';

interface SessionTimeoutModalProps {
  countdownMS?: number;
  autoLogout?: boolean;
  onRequestClose?: () => void;
  onSubmit?: () => void;
}

type SessionTimer = {
  minutes?: number;
  seconds?: number;
};

export const SessionTimeoutModal: FunctionComponent<
  SessionTimeoutModalProps
> = ({ countdownMS, autoLogout = true, onRequestClose, onSubmit }) => {
  const { t } = useTranslation();

  const [timer, setTimer] = useState<SessionTimer>(null);

  const intervalRef = useRef<NodeJS.Timeout>(null);
  const countdownRef = useRef<number>(countdownMS);

  useEffect(() => {
    const tick = () => {
      countdownRef.current -= 1000;
      if (countdownRef.current <= 0 && autoLogout) {
        onSubmit?.();
      } else {
        setTimer({
          minutes: Math.floor(countdownRef.current / 1000 / 60),
          seconds: Math.floor((countdownRef.current / 1000) % 60),
        });
      }
    };

    setTimeout(() => {
      tick();
      intervalRef.current = setInterval(tick, 1000);
    }, 1);

    return () => clearInterval(intervalRef.current);
  }, [autoLogout, countdownMS, onSubmit]);

  const handleRequestClose = useCallback(() => {
    clearInterval(intervalRef.current);
    onRequestClose?.();
  }, [onRequestClose]);

  return (
    <Modal blurBackground>
      <ModalHeader>
        <ModalTitle>{t(MessageKeys.SessionTimeoutHeading)}</ModalTitle>
      </ModalHeader>
      <SessionTimeoutModalBody>
        <SessionTimeoutModalTextContent>
          {t(MessageKeys.SessionTimeoutInfoMessage)}
        </SessionTimeoutModalTextContent>
        <SessionTimeoutModalTextContent>
          <Trans
            i18nKey={MessageKeys.SessionTimeoutAutoLogoutMessage as string}
            values={{
              time: timer?.minutes
                ? t(MessageKeys.SessionTimeoutTimerMinutesAndSeconds, {
                    ...timer,
                  })
                : t(MessageKeys.SessionTimeoutTimerOnlySeconds, {
                    seconds: timer?.seconds,
                  }),
            }}
            components={{
              bold: <b />,
            }}
          />
        </SessionTimeoutModalTextContent>
        <ModalButtonRow>
          <SecondaryButton onClick={handleRequestClose}>
            {t(MessageKeys.LabelCancel)}
          </SecondaryButton>
          <PrimaryButton onClick={onSubmit}>
            {t(MessageKeys.LabelLogout)}
          </PrimaryButton>
        </ModalButtonRow>
      </SessionTimeoutModalBody>
    </Modal>
  );
};
