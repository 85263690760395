import React, { FunctionComponent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ToggleSwitch,
  PrimaryButton,
  FormButtonRow,
  FormRow,
} from '@fcg-tech/regtech-components';
import {
  NotificationsSettingsWrapper,
  NotificationsSettingsLabel,
  NotificationsSettingsToggleSwitchWrapper,
} from './NotificationsSettings.styles';

export interface NotificationsSettingsProps {
  settings?: boolean;
  settingsText?: string;
  onLabel?: string;
  offLabel?: string;
  submitButtonLabel?: string;
  onSubmit: (toggleCheck: boolean) => Promise<void>;
}

export const NotificationsSettings: FunctionComponent<NotificationsSettingsProps> =
  ({
    settings,
    settingsText,
    onLabel = 'On',
    offLabel = 'Off',
    submitButtonLabel = 'Submit',
    onSubmit,
  }) => {
    const { t } = useTranslation();
    const [toggle, setToggle] = useState(settings ?? false);
    const [saving, setSaving] = useState(false);

    const handleToggleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setToggle(event.target.checked);
      },
      [],
    );

    const handleSubmit = useCallback(async () => {
      setSaving(true);
      await onSubmit?.(toggle);
      setSaving(false);
    }, [toggle, onSubmit]);

    return (
      <NotificationsSettingsWrapper>
        <FormRow>
          <NotificationsSettingsLabel>
            {settingsText}
          </NotificationsSettingsLabel>
          <NotificationsSettingsToggleSwitchWrapper>
            <ToggleSwitch
              checked={toggle}
              onLabel={onLabel}
              offLabel={offLabel}
              onChange={handleToggleChange}
            />
          </NotificationsSettingsToggleSwitchWrapper>
        </FormRow>
        <FormButtonRow>
          <PrimaryButton
            loading={saving}
            disabled={settings === toggle}
            onClick={handleSubmit}
          >
            {t(submitButtonLabel)}
          </PrimaryButton>
        </FormButtonRow>
      </NotificationsSettingsWrapper>
    );
  };
