import { useAuthContext } from '@fcg-tech/regtech-auth';
import { useSetRecoilState } from 'recoil';
import useSWR, { SWRConfiguration } from 'swr';
import { countryState } from '../../states/countryState';
import { currencyState } from '../../states/currencyState';
import { regionState } from '../../states/regionState';
import { Country, Currency, InitData, Region } from '../../types';
import { useApi } from '../apiUtils';
import { InitApi } from '../schema';
import { initDataKey } from './cacheKeys';

export const getInitData = async (
  api: InitApi,
  setCurrencies: (currencies: Array<Currency>) => void,
  setCountries: (countries: Array<Country>) => void,
  setRegions: (regions: Array<Region>) => void,
): Promise<InitData> => {
  const result = await api.getInitData();
  setCurrencies(result.currencies);
  setCountries(result.countries);
  setRegions(result.regions);
  return result;
};

export const useInitData = (tenantId: string, config?: SWRConfiguration) => {
  const authState = useAuthContext();
  const api = useApi<InitApi>('InitApi', tenantId);
  const setCurrencies = useSetRecoilState(currencyState);
  const setCountries = useSetRecoilState(countryState);
  const setRegions = useSetRecoilState(regionState);
  return useSWR<InitData>(
    initDataKey(tenantId),
    () => getInitData(api, setCurrencies, setCountries, setRegions),
    authState.isAuthenticated
      ? {
          suspense: true,
          errorRetryCount: 0,
          dedupingInterval: 1000,
          revalidateOnFocus: false,
          ...config,
        }
      : {
          suspense: true,
          ...config,
        },
  );
};
