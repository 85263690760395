import { uploadToS3 } from '@fcg-tech/regtech-api-utils';
import { useMemo } from 'react';
import useSWR, { SWRConfiguration } from 'swr';
import { useApi } from '../apiUtils';
import {
  AgreementAttachmentsApi,
  FileInfoWithS3Url,
  FileAttachment,
  FileAttachmentSection,
} from '../schema';
import { allAgreementAttachmentsKey } from './cacheKeys';

export const getAllAgreementAttachments = async (
  agreementId: string,
  agreementVersion: number | undefined,
  api: AgreementAttachmentsApi,
): Promise<Array<FileAttachment>> => {
  const result = await api.listAllAgreementAttachments({
    agreementId,
    agreementVersion: agreementVersion ?? undefined,
  });
  return result?.results ?? [];
};

export const useAgreementAttachments = (
  tenantId: string,
  agreementId: string,
  agreementVersion: number | undefined,
  config?: SWRConfiguration,
) => {
  const api = useApi<AgreementAttachmentsApi>(
    'AgreementAttachmentsApi',
    tenantId,
  );
  return useSWR<Array<FileAttachment>>(
    allAgreementAttachmentsKey(tenantId, agreementId, agreementVersion),
    () => getAllAgreementAttachments(agreementId, agreementVersion, api),
    { suspense: true, ...config },
  );
};

export const getFileUploadUrl = async (
  agreementId: string,
  agreementVersion: number | undefined,
  section: FileAttachmentSection,
  filename: string,
  api: AgreementAttachmentsApi,
): Promise<FileInfoWithS3Url> => {
  const result = await api.createAgreementAttachmentUploadUrl({
    agreementId,
    agreementVersion,
    createAgreementAttachmentUploadUrlRequest: { section, filename },
  });
  return result;
};

export const getFileDownloadUrl = async (
  agreementId: string,
  attachmentId: string,
  api: AgreementAttachmentsApi,
): Promise<FileInfoWithS3Url> => {
  const result = await api.createAgreementAttachmentDownloadUrl({
    agreementId,
    attachmentId,
  });
  return result;
};

export const uploadAttachment = async (
  agreementId: string,
  agreementVersion: number | undefined,
  files: Array<File>,
  section: FileAttachmentSection,
  api: AgreementAttachmentsApi,
): Promise<Array<FileInfoWithS3Url>> => {
  const uploadInfos = await Promise.all(
    files.map(async (file) => {
      const uploadInfo = await getFileUploadUrl(
        agreementId,
        agreementVersion,
        section,
        file.name,
        api,
      );
      await uploadToS3(uploadInfo.url, file);
      return uploadInfo;
    }),
  );
  return uploadInfos;
};

export const deleteAttachment = async (
  agreementId: string,
  attachmentId: string,
  api: AgreementAttachmentsApi,
): Promise<void> => {
  await api.deleteAgreementAttachment({ agreementId, attachmentId });
};

export const useAgreementAttachmentActions = (tenantId: string) => {
  const api = useApi<AgreementAttachmentsApi>(
    'AgreementAttachmentsApi',
    tenantId,
  );
  return useMemo(
    () => ({
      getFileDownloadUrl: async (agreementId: string, attachmentId: string) =>
        getFileDownloadUrl(agreementId, attachmentId, api),
      uploadAttachment: async (
        agreementId: string,
        agreementVersion: number | undefined,
        file: Array<File>,
        section: FileAttachmentSection,
      ) => uploadAttachment(agreementId, agreementVersion, file, section, api),
      deleteAttachment: async (agreementId: string, attachmentId: string) =>
        deleteAttachment(agreementId, attachmentId, api),
    }),
    [api],
  );
};
