import update from 'immutability-helper';

export const removeWithId =
  (targetId: string) =>
  <T extends { id: string }>(data: Array<T>) => {
    const index = data.findIndex(({ id }) => id === targetId);
    if (index >= 0) {
      return update(data, { $splice: [[index, 1]] });
    }
    return data;
  };

export const upsertWithId =
  <T extends { id: string }>(targetId: string | null, item: T) =>
  (items: Array<T>) => {
    if (targetId) {
      const index = items.findIndex(({ id }) => id === targetId);
      if (index >= 0) {
        return update(items, { [index]: { $set: item } });
      }
    } else {
      return update(items, { $push: [item] });
    }
    return items;
  };
