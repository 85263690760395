import styled from 'styled-components';
import { excludeProps } from '../../utils';
import { ChevronRightIcon } from '../Icons';

export const ExpansionPanelSummaryWrapper = styled.div.withConfig(
  excludeProps('disabled'),
)<{
  disabled?: boolean;
}>`
  cursor: ${({ disabled }) => (disabled ? 'cursor' : 'pointer')};
  padding: 0.8rem 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ExpansionPanelSummaryTitle = styled.div`
  font-size: 1.6rem;
  font-weight: 500;
`;

export const ExpansionPanelSummarySubtitle = styled.div`
  font-size: 1.3rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 0.4rem;
`;

export const ExpansionPanelSummaryChevronIcon = styled(
  ChevronRightIcon,
).withConfig(excludeProps('expanded'))<{
  expanded?: boolean;
}>`
  transform: rotate(${({ expanded }) => (expanded ? '270deg' : '90deg')});
  transition: transform 0.1s linear;
  width: 2rem;
  height: 2rem;
  opacity: ${({ expanded }) => (expanded ? 1 : 0.2)};
  float: right;
`;
