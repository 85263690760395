import React, { FunctionComponent } from 'react';

import { PrimaryButton } from '../Button';
import { PageLayout } from '../PageLayout';
import {
  NotFoundPageWrapper,
  Title,
  Message,
  StatusCode,
} from './NotFoundPage.styles';

export const NotFoundPage: FunctionComponent = () => (
  <NotFoundPageWrapper>
    <PageLayout>
      <PageLayout.PaddedContent>
        <Title>Oops!</Title>
        <StatusCode>404 - Page not found</StatusCode>
        <Message>
          The page you are looking for might have been removed, had its name
          changed or is temporarily unavailable.
        </Message>
        <PrimaryButton to="/">
          Go to Homepage
        </PrimaryButton>
      </PageLayout.PaddedContent>
    </PageLayout>
  </NotFoundPageWrapper>
);
