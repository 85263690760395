import styled from 'styled-components';
import { H3 } from '@fcg-tech/regtech-components';

export const ValidationErrorsSummaryWrapper = styled.div`
  margin: 2.4rem 0;
`;

export const ValidationErrorsSummaryTitle = styled(H3)`
  font-weight: normal;
  color: black;
`;

export const ValidationErrorsSummaryList = styled.ul`
  margin: 0;
  padding: 0 0 0 2.4rem;
`;

export const ValidationErrorsSummaryListItem = styled.li`
  margin-bottom: 1.4rem;
  font-size: 1.4rem;
  color: black;

  &:last-of-type {
    margin-bottom: 0;
  }
`;
