import { foundation } from '@fcg-tech/regtech-design-system';
import styled, { css } from 'styled-components';
import { mobile } from '../../theme';

export const TooltipWrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    z-index: 10000;
    visibility: hidden;

    &.visible {
      visibility: visible;
    }

    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      display: none;
    }
  `}
`;

export const ArrowContainer = styled.div`
  ${({ theme }) => css`
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;

    border-bottom: 10px solid ${theme.colors?.border ?? '#000'};
    position: absolute;
    z-index: 3;

    &:after {
      content: ' ';
      position: absolute;
      top: 1px;
      left: -10px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid ${theme?.palette?.MainWhite ?? '#fff'};
      z-index: 4;
    }
  `}

  &.tooltip-arrow-position-bs {
    bottom: 100%;
    left: 0;
  }
  &.tooltip-arrow-position-be {
    bottom: 100%;
    right: 0;
  }
  &.tooltip-arrow-position-bm {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  &.tooltip-arrow-position-ts {
    top: 100%;
    left: 0;
    transform: rotate(180deg);
  }
  &.tooltip-arrow-position-te {
    top: 100%;
    right: 0;
    transform: rotate(180deg);
  }
  &.tooltip-arrow-position-tm {
    top: 100%;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
  }
`;

export const BorderContainer = styled.div`
  ${({ theme }) => css`
    background-color: ${theme?.palette?.MainWhite ?? '#fff'};
    border: 1px solid ${theme.colors?.border ?? '#000'};
    padding: 1rem;
    z-index: 2;
    font-size: 1.4rem;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    position: relative;

    &.tooltip-arrow-position-bs,
    &.tooltip-arrow-position-ts {
      left: -10px;
    }
    &.tooltip-arrow-position-be,
    &.tooltip-arrow-position-te {
      right: -10px;
    }
  `}
`;

export const TooltipTooltip = styled.div`
  background-color: white;
  border: 1px solid white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3), 0 0 6px 1px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  border-radius: 3px;
  font-size: 1.4rem;
`;

export const TooltipNextWrapper = styled.div`
  position: fixed;
  pointer-events: none;
  z-index: 10000;

  opacity: 0;
  transition: opacity 0.25s linear;

  max-width: 30vw;

  ${mobile(
    () => css`
      display: none;
    `,
  )}

  &.positioned {
    opacity: 1;
  }

  & > .tooltip_tooltip-arrow {
    position: absolute;
    z-index: 101;
  }

  &.tooltip__placement-top {
    & > .tooltip_tooltip-arrow {
      transform: translate(-50%, -2px);
      top: 100%;
      left: 50%;
    }
  }

  &.tooltip__placement-top-end {
    & > .tooltip_tooltip-arrow {
      transform: translate(50%, -2px);
      top: 100%;
      right: 50%;
    }
  }

  &.tooltip__placement-top-start {
    & > .tooltip_tooltip-arrow {
      transform: translate(-50%, -2px);
      top: 100%;
      left: 50%;
    }
  }

  &.tooltip__placement-left {
    & > .tooltip_tooltip-arrow {
      transform: translate(-5px, -50%) rotate(270deg);
      top: 50%;
      left: 100%;
    }
  }

  &.tooltip__placement-left-start {
    & > .tooltip_tooltip-arrow {
      transform: translate(-5px, -50%) rotate(270deg);
      top: 50%;
      left: 100%;
    }
  }

  &.tooltip__placement-left-end {
    & > .tooltip_tooltip-arrow {
      transform: translate(-5px, 50%) rotate(270deg);
      bottom: 50%;
      left: 100%;
    }
  }

  &.tooltip__placement-right {
    & > .tooltip_tooltip-arrow {
      transform: translate(5px, -50%) rotate(90deg);
      top: 50%;
      right: 100%;
    }
  }

  &.tooltip__placement-right-end {
    & > .tooltip_tooltip-arrow {
      transform: translate(5px, 50%) rotate(90deg);
      bottom: 50%;
      right: 100%;
    }
  }

  &.tooltip__placement-right-start {
    & > .tooltip_tooltip-arrow {
      transform: translate(5px, -50%) rotate(90deg);
      top: 50%;
      right: 100%;
    }
  }

  &.tooltip__placement-bottom {
    & > .tooltip_tooltip-arrow {
      transform: translate(-50%, 2px) rotate(180deg);
      left: 50%;
      bottom: 100%;
    }
  }

  &.tooltip__placement-bottom-end {
    & > .tooltip_tooltip-arrow {
      transform: translate(50%, 2px) rotate(180deg);
      right: 50%;
      bottom: 100%;
    }
  }

  &.tooltip__placement-bottom-start {
    & > .tooltip_tooltip-arrow {
      transform: translate(-50%, 2px) rotate(180deg);
      left: 50%;
      bottom: 100%;
    }
  }

  &.error > ${TooltipTooltip} {
    border-color: ${foundation.palette.gold600.hex};
    background-color: ${foundation.palette.gold400.hex};
    color: ${foundation.palette.gold800.hex};
  }
`;
