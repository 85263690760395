import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import {
  Label,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@fcg-tech/regtech-components';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../../../translations/messageKeys';
import { routes } from '../../../../routes';

const EmptyTableState = styled.div`
  flex: 1;
  text-align: center;
`;

const FixedTable = styled(Table)`
  table-layout: fixed;
`;

export const RelatedAgreementsTable = ({
  tenantId,
  title,
  relations,
  relationshipType,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClickRow = React.useCallback(
    (id) => {
      navigate(
        constructUrl(routes.agreement, {
          tenantId,
          agreementId: id,
        }),
      );
    },
    [navigate, tenantId],
  );

  return <>
    {title && <Label>{title}</Label>}
    <FixedTable>
      <TableHead>
        <TableRow>
          <TableCell>
            {t(MessageKeys.RelatedAgreementsTableNameLabel)}
          </TableCell>
          <TableCell>
            {t(
              MessageKeys.RelatedAgreementsTableAgreementTypeLabel,
            )}
          </TableCell>
          <TableCell>
            {t(MessageKeys.RelatedAgreementsTableCategoryLabel)}
          </TableCell>
          <TableCell>
            {t(MessageKeys.RelatedAgreementsTableRelationshipLabel)}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {relations?.length ? (
          relations.map(({ id, name, agreementType, functionCategory }) => (
            <TableRow key={id} clickable onClick={() => handleClickRow(id)}>
              <TableCell>{name}</TableCell>
              <TableCell>{agreementType?.name}</TableCell>
              <TableCell>{functionCategory?.name}</TableCell>
              <TableCell>{relationshipType}</TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={4}>
              <EmptyTableState>
                {t(
                  MessageKeys.RelatedAgreementsTableNoAgreementsLabel,
                )}
              </EmptyTableState>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </FixedTable>
  </>;
};

RelatedAgreementsTable.propTypes = {
  title: PropTypes.string,
  relations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      cabinet: PropTypes.string,
      name: PropTypes.string,
      functionCategory: PropTypes.object,
      agreementType: PropTypes.object,
    }),
  ),
  relationshipType: PropTypes.string,
};

RelatedAgreementsTable.defaultProps = {
  title: '',
  relations: [],
  relationshipType: 'Child',
};
