import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import {
  Card,
  CardBody,
  CardTitle,
  PageHeader,
  PageLayout,
  PageLayoutPaddedContent,
  PrimaryButton,
  SecondaryButton,
} from '@fcg-tech/regtech-components';
import { UserDetailsSubForm } from '../../../components/SubForms';

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & > * + * {
    margin-left: 1rem;
  }
`;

export const NewUserPage = ({ isSaving, onSave, onCancel }) => {
  return (
    <Formik initialValues={{ username: '' }} onSubmit={onSave}>
      {({ handleSubmit, values }) => (
        <PageLayout>
          <PageHeader title="Create user" breadcrumbs={[]}>
            <ButtonGroup>
              <PrimaryButton
                onClick={handleSubmit}
                disabled={isSaving || values.username === ''}
              >
                Save
              </PrimaryButton>
              <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
            </ButtonGroup>
          </PageHeader>
          <PageLayoutPaddedContent>
            <Card>
              <CardBody>
                <CardTitle>Details</CardTitle>
                <Form>
                  <UserDetailsSubForm />
                </Form>
              </CardBody>
            </Card>
          </PageLayoutPaddedContent>
        </PageLayout>
      )}
    </Formik>
  );
};

NewUserPage.propTypes = {
  isSaving: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

NewUserPage.defaultProps = { isSaving: false, onSave: null, onCancel: null };
