import React, { FunctionComponent } from 'react';

interface OasysTextProps {
  color?: string;
  className?: string;
}

export const OasysText: FunctionComponent<OasysTextProps> = ({
  color = 'currentColor',
  className,
}) => {
  return (
    <svg
      width="100"
      height="42"
      viewBox="0 0 100 42"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.78416 17.568C8.04016 17.568 6.46416 17.192 5.05616 16.44C3.66416 15.688 2.56816 14.656 1.76816 13.344C0.984163 12.016 0.592163 10.528 0.592163 8.87999C0.592163 7.23199 0.984163 5.75199 1.76816 4.43999C2.56816 3.11199 3.66416 2.07199 5.05616 1.31999C6.46416 0.567986 8.04016 0.191986 9.78416 0.191986C11.5282 0.191986 13.0962 0.567986 14.4882 1.31999C15.8802 2.07199 16.9762 3.11199 17.7762 4.43999C18.5762 5.75199 18.9762 7.23199 18.9762 8.87999C18.9762 10.528 18.5762 12.016 17.7762 13.344C16.9762 14.656 15.8802 15.688 14.4882 16.44C13.0962 17.192 11.5282 17.568 9.78416 17.568ZM9.78416 14.256C10.7762 14.256 11.6722 14.032 12.4722 13.584C13.2722 13.12 13.8962 12.48 14.3442 11.664C14.8082 10.848 15.0402 9.91999 15.0402 8.87999C15.0402 7.83999 14.8082 6.91199 14.3442 6.09599C13.8962 5.27999 13.2722 4.64799 12.4722 4.19999C11.6722 3.73599 10.7762 3.50399 9.78416 3.50399C8.79216 3.50399 7.89616 3.73599 7.09616 4.19999C6.29616 4.64799 5.66416 5.27999 5.20016 6.09599C4.75216 6.91199 4.52816 7.83999 4.52816 8.87999C4.52816 9.91999 4.75216 10.848 5.20016 11.664C5.66416 12.48 6.29616 13.12 7.09616 13.584C7.89616 14.032 8.79216 14.256 9.78416 14.256Z"
        fill={color}
      />
      <path
        d="M26.8422 4.17599C28.8422 4.17599 30.3782 4.65599 31.4502 5.61599C32.5222 6.55999 33.0582 7.99199 33.0582 9.91199V17.28H29.5542V15.672C28.8502 16.872 27.5382 17.472 25.6182 17.472C24.6262 17.472 23.7622 17.304 23.0262 16.968C22.3062 16.632 21.7542 16.168 21.3702 15.576C20.9862 14.984 20.7942 14.312 20.7942 13.56C20.7942 12.36 21.2422 11.416 22.1382 10.728C23.0502 10.04 24.4502 9.69599 26.3382 9.69599H29.3142C29.3142 8.87999 29.0662 8.25599 28.5702 7.82399C28.0742 7.37599 27.3302 7.15199 26.3382 7.15199C25.6502 7.15199 24.9702 7.26399 24.2982 7.48799C23.6422 7.69599 23.0822 7.98399 22.6182 8.35199L21.2742 5.73599C21.9782 5.23999 22.8182 4.85599 23.7942 4.58399C24.7862 4.31199 25.8022 4.17599 26.8422 4.17599ZM26.5542 14.952C27.1942 14.952 27.7622 14.808 28.2582 14.52C28.7542 14.216 29.1062 13.776 29.3142 13.2V11.88H26.7462C25.2102 11.88 24.4422 12.384 24.4422 13.392C24.4422 13.872 24.6262 14.256 24.9942 14.544C25.3782 14.816 25.8982 14.952 26.5542 14.952Z"
        fill={color}
      />
      <path
        d="M40.7907 17.472C39.7187 17.472 38.6707 17.344 37.6467 17.088C36.6227 16.816 35.8067 16.48 35.1987 16.08L36.4467 13.392C37.0227 13.76 37.7187 14.064 38.5347 14.304C39.3507 14.528 40.1507 14.64 40.9347 14.64C42.5187 14.64 43.3107 14.248 43.3107 13.464C43.3107 13.096 43.0947 12.832 42.6627 12.672C42.2307 12.512 41.5667 12.376 40.6707 12.264C39.6147 12.104 38.7427 11.92 38.0547 11.712C37.3667 11.504 36.7667 11.136 36.2547 10.608C35.7587 10.08 35.5107 9.32799 35.5107 8.35199C35.5107 7.53599 35.7427 6.81599 36.2067 6.19199C36.6867 5.55199 37.3747 5.05599 38.2707 4.70399C39.1827 4.35199 40.2547 4.17599 41.4867 4.17599C42.3987 4.17599 43.3027 4.27999 44.1987 4.48799C45.1107 4.67999 45.8627 4.95199 46.4547 5.30399L45.2067 7.96799C44.0707 7.32799 42.8307 7.00799 41.4867 7.00799C40.6867 7.00799 40.0867 7.11999 39.6867 7.34399C39.2867 7.56799 39.0867 7.85599 39.0867 8.20799C39.0867 8.60799 39.3027 8.88799 39.7347 9.04799C40.1667 9.20799 40.8547 9.35999 41.7987 9.50399C42.8547 9.67999 43.7187 9.87199 44.3907 10.08C45.0627 10.272 45.6467 10.632 46.1427 11.16C46.6387 11.688 46.8867 12.424 46.8867 13.368C46.8867 14.168 46.6467 14.88 46.1667 15.504C45.6867 16.128 44.9827 16.616 44.0547 16.968C43.1427 17.304 42.0547 17.472 40.7907 17.472Z"
        fill={color}
      />
      <path
        d="M61.7783 4.36799L55.9463 18.072C55.3543 19.56 54.6183 20.608 53.7383 21.216C52.8743 21.824 51.8263 22.128 50.5943 22.128C49.9223 22.128 49.2583 22.024 48.6023 21.816C47.9463 21.608 47.4103 21.32 46.9943 20.952L48.3623 18.288C48.6503 18.544 48.9783 18.744 49.3463 18.888C49.7303 19.032 50.1063 19.104 50.4743 19.104C50.9863 19.104 51.4023 18.976 51.7223 18.72C52.0423 18.48 52.3303 18.072 52.5863 17.496L52.6343 17.376L47.0423 4.36799H50.9063L54.5303 13.128L58.1783 4.36799H61.7783Z"
        fill={color}
      />
      <path
        d="M67.4625 17.472C66.3905 17.472 65.3425 17.344 64.3185 17.088C63.2945 16.816 62.4785 16.48 61.8705 16.08L63.1185 13.392C63.6945 13.76 64.3905 14.064 65.2065 14.304C66.0225 14.528 66.8225 14.64 67.6065 14.64C69.1905 14.64 69.9825 14.248 69.9825 13.464C69.9825 13.096 69.7665 12.832 69.3345 12.672C68.9025 12.512 68.2385 12.376 67.3425 12.264C66.2865 12.104 65.4145 11.92 64.7265 11.712C64.0385 11.504 63.4385 11.136 62.9265 10.608C62.4305 10.08 62.1825 9.32799 62.1825 8.35199C62.1825 7.53599 62.4145 6.81599 62.8785 6.19199C63.3585 5.55199 64.0465 5.05599 64.9425 4.70399C65.8545 4.35199 66.9265 4.17599 68.1585 4.17599C69.0705 4.17599 69.9745 4.27999 70.8705 4.48799C71.7825 4.67999 72.5345 4.95199 73.1265 5.30399L71.8785 7.96799C70.7425 7.32799 69.5025 7.00799 68.1585 7.00799C67.3585 7.00799 66.7585 7.11999 66.3585 7.34399C65.9585 7.56799 65.7585 7.85599 65.7585 8.20799C65.7585 8.60799 65.9745 8.88799 66.4065 9.04799C66.8385 9.20799 67.5265 9.35999 68.4705 9.50399C69.5265 9.67999 70.3905 9.87199 71.0625 10.08C71.7345 10.272 72.3185 10.632 72.8145 11.16C73.3105 11.688 73.5585 12.424 73.5585 13.368C73.5585 14.168 73.3185 14.88 72.8385 15.504C72.3585 16.128 71.6545 16.616 70.7265 16.968C69.8145 17.304 68.7265 17.472 67.4625 17.472Z"
        fill={color}
      />
    </svg>
  );
};
