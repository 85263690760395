import { FunctionComponent, useCallback, useMemo } from 'react';
import { useToggle } from '../../miscHooks';
import {
  BooleanTreeRowCheckbox,
  BooleanTreeRowChevronIcon,
  BooleanTreeRowExpandButton,
  BooleanTreeRowInner,
  BooleanTreeRowItems,
  BooleanTreeRowLabel,
  BooleanTreeRowWrapper,
} from './BooleanTree.styles';
import { BooleanTreeItem, BooleanTreeItemValue } from './booleanTreeTypes';
import { isItemPartiallySelected } from './booleanTreeUtils';

export interface BooleanTreeRowProps {
  item: BooleanTreeItem;
  onChange?: (itemValues: BooleanTreeItemValue, checked: boolean) => void;
}

export const BooleanTreeRow: FunctionComponent<BooleanTreeRowProps> = ({
  item,
  onChange,
}) => {
  const [expanded, , handleExpandClick] = useToggle(item.expanded ?? false);

  const partiallyChecked = useMemo(() => isItemPartiallySelected(item), [item]);

  const handleChange = useCallback(
    (checked: boolean) => {
      onChange?.(
        { id: item.id, parentId: item.parentId, value: item.value },
        checked,
      );
    },
    [onChange, item],
  );

  const dataProps: Record<string, unknown> = {};
  if (item.dataProperty) {
    dataProps[`data-${item.dataProperty.toString().toLowerCase()}`] = item.id;
  }

  return (
    <BooleanTreeRowWrapper data-testid={`boolean-tree-item-id-${item.id}`}>
      <BooleanTreeRowInner {...dataProps}>
        <BooleanTreeRowCheckbox
          checked={item.selected}
          partiallyChecked={partiallyChecked}
          disabled={item.disabled}
          onChange={onChange && !item.disabled ? handleChange : null}
        />
        {item.subItems?.length ? (
          <BooleanTreeRowExpandButton onClick={handleExpandClick}>
            {expanded ? (
              <BooleanTreeRowChevronIcon size="20" expanded />
            ) : (
              <BooleanTreeRowChevronIcon size="20" />
            )}
          </BooleanTreeRowExpandButton>
        ) : null}
        <BooleanTreeRowLabel disabled={item.disabled}>
          {item.getLabel?.(item.value, {
            disabled: item.disabled,
            selected: item.selected,
            expanded: item.expanded,
          }) ??
            item.label ??
            'N/A'}
        </BooleanTreeRowLabel>
      </BooleanTreeRowInner>
      {item.subItems?.length && expanded ? (
        <BooleanTreeRowItems>
          {item.subItems.map((subItem) => (
            <BooleanTreeRow
              key={`${subItem.id}-${subItem.parentId}`}
              item={subItem}
              onChange={onChange}
            />
          ))}
        </BooleanTreeRowItems>
      ) : null}
    </BooleanTreeRowWrapper>
  );
};
