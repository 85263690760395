/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo, useState } from 'react';
import { FormikHelpers, FieldInputProps } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import {
  ConfirmDialog,
  PrimaryButton,
  useToggle,
} from '@fcg-tech/regtech-components';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import { SUBCONTRACTOR_SKELETON } from '../../constants';
import {
  Category,
  Direction,
  InternalEntity,
  Subcontractor,
  WithChildren,
  WithMetadata,
} from '../../types';
import { SubcontractorsFieldTable } from './SubcontractorsFieldTable';
import { SubcontractorsFieldEditModal } from './SubcontractorsFieldEditModal';
import {
  SubcontractorsFieldWrapper,
  SubcontractorsFieldButtonWrapper,
} from './SubcontractorsField.styled';

const flatten = (
  subcontractors: Array<WithChildren<Subcontractor>>,
): Array<Subcontractor> => {
  let result = [] as Array<Subcontractor>;
  for (let i = 0; i < subcontractors.length; i += 1) {
    const { children, ...subcontractor } = subcontractors[i];
    result = result.concat({ ...subcontractor });
    result = result.concat(flatten(children));
  }
  return result;
};

const unflatten = (
  subcontractors: Array<Subcontractor>,
): Array<WithChildren<Subcontractor>> => {
  const result = [];
  const nodes = subcontractors.map((subcontractor) => ({
    ...subcontractor,
    children: [] as Array<WithChildren<Subcontractor>>,
  }));
  const map = new Map(nodes.map((node) => [node.id, node]));
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    if (node.parentId) {
      const parent = map.get(node.parentId);
      parent?.children.push(node);
    } else {
      result.push(node);
    }
  }
  return result;
};

const findSubcontractorById = (
  subcontractors: Array<WithChildren<Subcontractor>>,
  subcontractorId: string | null,
): WithChildren<Subcontractor> | null => {
  if (!subcontractorId) {
    return null;
  }
  for (let i = 0; i < subcontractors.length; i += 1) {
    if (subcontractors[i].id === subcontractorId) {
      return subcontractors[i];
    }
    const subcontractor = findSubcontractorById(
      subcontractors[i].children,
      subcontractorId,
    );
    if (subcontractor) {
      return subcontractor;
    }
  }
  return null;
};

interface SubcontractorsFieldProps {
  form: FormikHelpers<Array<Subcontractor>>;
  field: FieldInputProps<Array<Subcontractor>>;
  isEditEnabled: boolean;
  suppliers?: Array<any>;
  internalEntities?: Array<WithMetadata<InternalEntity>>;
  categories?: Array<WithMetadata<Category>>;
}

export const SubcontractorsField = ({
  form,
  field,
  isEditEnabled,
  suppliers = [],
  categories = [],
  internalEntities = [],
}: SubcontractorsFieldProps) => {
  const { t } = useTranslation();
  const [
    showNewSubcontractorModal,
    setShowNewSubcontractorModal,
    toggleShowNewSubcontractorModal,
  ] = useToggle();

  const [editSubcontractor, setEditSubcontractor] = useState<Subcontractor>();
  const [deleteSubcontractorId, setDeleteSubcontractorId] =
    useState<string>('');

  const subcontractors = useMemo(() => unflatten(field.value), [field.value]);

  const handleCancel = useCallback(() => {
    setShowNewSubcontractorModal(false);
    setEditSubcontractor(undefined);
  }, [setShowNewSubcontractorModal]);

  const handleAdd = useCallback(
    (subcontractor: Subcontractor) => {
      form.setFieldValue(field.name, [...field.value, { ...subcontractor }]);
      setTimeout(handleCancel, 0);
    },
    [form, field.name, field.value, handleCancel],
  );

  const handleUpdate = useCallback(
    (subcontractor: Subcontractor) => {
      const index = field.value.findIndex(({ id }) => id === subcontractor.id);
      if (index !== -1) {
        form.setFieldValue(field.name, [
          ...field.value.slice(0, index),
          subcontractor,
          ...field.value.slice(index + 1),
        ]);
      }
      setTimeout(handleCancel, 0);
    },
    [handleCancel, form, field.value, field.name],
  );

  const handleEditRow = useCallback(
    (subcontractorId: string) => {
      const subcontractor = field.value.find(
        ({ id }) => id === subcontractorId,
      );
      if (subcontractor) {
        setEditSubcontractor(subcontractor);
      }
    },
    [field.value],
  );

  const handleDeleteRow = useCallback(
    (subcontractorId: string) => setDeleteSubcontractorId(subcontractorId),
    [],
  );

  const handleDeleteChoice = useCallback(
    (choice: boolean) => {
      if (choice) {
        const subcontractor = findSubcontractorById(
          subcontractors,
          deleteSubcontractorId,
        );
        if (subcontractor) {
          const parent = findSubcontractorById(
            subcontractors,
            subcontractor.parentId,
          );
          const children = parent ? parent.children : subcontractors;
          const index = children.findIndex((o) => o.id === subcontractor.id);
          children.splice(index, 1);
        }

        form.setFieldValue(field.name, [...flatten(subcontractors)]);
      }
      setTimeout(() => setDeleteSubcontractorId(''), 0);
    },
    [form, field.name, deleteSubcontractorId, subcontractors],
  );

  const handleMoveRow = useCallback(
    (subcontractorId: string, direction: string) => {
      const subcontractor = findSubcontractorById(
        subcontractors,
        subcontractorId,
      );
      if (!subcontractor) {
        return;
      }
      switch (direction) {
        case Direction.Left:
          {
            const parent = findSubcontractorById(
              subcontractors,
              subcontractor.parentId,
            );
            if (parent) {
              parent.children = parent.children.filter(
                (o) => o.id !== subcontractor.id,
              );
              const newParent = findSubcontractorById(
                subcontractors,
                parent.parentId,
              );
              if (newParent) {
                subcontractor.parentId = newParent.id;
                newParent.children.push(subcontractor);
              } else {
                subcontractor.parentId = null;
                subcontractors.push(subcontractor);
              }
            }
          }
          break;
        case Direction.Down:
          {
            const parent = findSubcontractorById(
              subcontractors,
              subcontractor.parentId,
            );
            const children = parent ? parent.children : subcontractors;
            const index = children.findIndex((o) => o.id === subcontractor.id);
            if (index >= 0 && index < children.length - 1) {
              children.splice(
                index,
                1,
                children.splice(index + 1, 1, subcontractor)[0],
              );
            }
          }
          break;
        case Direction.Up:
          {
            const parent = findSubcontractorById(
              subcontractors,
              subcontractor.parentId,
            );
            const children = parent ? parent.children : subcontractors;
            const index = children.findIndex((o) => o.id === subcontractor.id);
            if (index > 0)
              children.splice(
                index,
                1,
                children.splice(index - 1, 1, subcontractor)[0],
              );
          }
          break;
        case Direction.Right:
          {
            const parent = findSubcontractorById(
              subcontractors,
              subcontractor.parentId,
            );
            const children = parent ? parent.children : subcontractors;
            const index = children.findIndex((o) => o.id === subcontractor.id);

            if (index > 0) {
              children.splice(index, 1);
              const newParent = children[index - 1];
              subcontractor.parentId = newParent.id;
              newParent.children.push(subcontractor);
            }
          }
          break;
        default:
          break;
      }
      form.setFieldValue(field.name, [...flatten(subcontractors)]);
    },
    [form, field.name, subcontractors],
  );

  return (
    <SubcontractorsFieldWrapper>
      {showNewSubcontractorModal ? (
        <SubcontractorsFieldEditModal
          title={t(MessageKeys.SubcontractorsFieldAddLabel)}
          subcontractor={{ ...SUBCONTRACTOR_SKELETON, id: uuidv4() }}
          onSubmit={handleAdd}
          onCancel={handleCancel}
          suppliers={suppliers}
          categories={categories}
          internalEntities={internalEntities}
        />
      ) : null}
      {editSubcontractor ? (
        <SubcontractorsFieldEditModal
          title={t(MessageKeys.SubcontractorsFieldEditLabel)}
          subcontractor={editSubcontractor}
          onSubmit={handleUpdate}
          onCancel={handleCancel}
          suppliers={suppliers}
          categories={categories}
          internalEntities={internalEntities}
        />
      ) : null}
      {deleteSubcontractorId ? (
        <ConfirmDialog
          title={t(MessageKeys.LabelConfirm)}
          body={t(MessageKeys.SubcontractorsFieldDeleteLabel)}
          confirmText={t(
            MessageKeys.SubcontractorsFieldConfirmDeleteLabel,
          )}
          cancelText={t(MessageKeys.LabelNo)}
          onChoice={handleDeleteChoice}
        />
      ) : null}
      <SubcontractorsFieldTable
        subcontractors={subcontractors}
        isEditEnabled={isEditEnabled}
        onEditRow={handleEditRow}
        onDeleteRow={handleDeleteRow}
        onMoveRow={handleMoveRow}
        suppliers={suppliers}
        categories={categories}
        internalEntities={internalEntities}
      />
      {isEditEnabled ? (
        <SubcontractorsFieldButtonWrapper>
          <PrimaryButton
            type="button"
            onClick={toggleShowNewSubcontractorModal}
          >
            {t(MessageKeys.SubcontractorsFieldAddLabel)}
          </PrimaryButton>
        </SubcontractorsFieldButtonWrapper>
      ) : null}
    </SubcontractorsFieldWrapper>
  );
};
