import { useTranslation } from 'react-i18next';
import {
  FormRow,
  Modal,
  ModalBody,
  ModalButtonRow,
  ModalHeader,
  ModalTitle,
  PrimaryButton,
  SecondaryButton,
  TextField,
  TooltipNext as Tooltip,
  useFlash,
  useFocus,
} from '@fcg-tech/regtech-components';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useCabinetActions } from '../../api/hooks/cabinetsApiHooks';
import { ModalFormLabel, ModalFormWrapper } from '../../components/modals';
import { useTenant } from '../../states/tenantState';
import { MessageKeys } from '../../translations/messageKeys';

const whiteSpaceTestRe = /\s/;
const whiteSpaceReplaceRe = /\s+/g;

const formatCabinetName = (cabinetName: string): string => {
  return cabinetName
    .replace(whiteSpaceReplaceRe, ' ')
    .replace(whiteSpaceReplaceRe, '-');
};

interface CabinetsCreateModalProps {
  onRequestClose: (cabinetName?: string) => void;
}

export const CabinetsCreateModal: FunctionComponent<
  CabinetsCreateModalProps
> = ({ onRequestClose }) => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const addFlash = useFlash();
  const [loading, setLoading] = useState(false);
  const [nameValue, setNameValue] = useState('');
  const [showNameTooltip, setShowNameTooltip] = useState(false);
  const { upsertCabinet } = useCabinetActions(tenant.id);

  const ref = useFocus<HTMLInputElement>();

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      setLoading(true);
      try {
        const cabinetName = formatCabinetName(nameValue);
        await upsertCabinet(cabinetName);
        addFlash({
          level: 'success',
          content: t(MessageKeys.CabinetsCreateCabinetSuccess),
        });
        onRequestClose(cabinetName);
      } catch (err) {
        setLoading(false);
        addFlash({
          level: 'error',
          content: t(MessageKeys.CabinetsCreateCabinetFailure),
        });
      }
    },
    [addFlash, nameValue, onRequestClose, t, upsertCabinet],
  );

  const handleNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setNameValue(value);
      setShowNameTooltip(
        whiteSpaceTestRe.test(
          value?.trimStart()?.length === 0 ? value?.trim() : value,
        ),
      );
    },
    [],
  );

  const handleHide = useCallback(
    () => onRequestClose(undefined),
    [onRequestClose],
  );

  return (
    <Modal onHide={handleHide}>
      <ModalHeader>
        <ModalTitle>{t(MessageKeys.CabinetsCreateNewCabinetLabel)}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <ModalFormWrapper onSubmit={handleSubmit}>
          <FormRow>
            <ModalFormLabel htmlFor="cabinet-name">
              {t(MessageKeys.LabelName)}
            </ModalFormLabel>
            <Tooltip
              forceOpen={showNameTooltip}
              placement="top-start"
              content={t(MessageKeys.CabinetsCreateCabinetNameFormatTooltip, {
                cabinetName: formatCabinetName(nameValue),
              })}
              html
            >
              <TextField
                id="cabinet-name"
                ref={ref}
                value={nameValue}
                onChange={handleNameChange}
              />
            </Tooltip>
          </FormRow>
          <ModalButtonRow>
            <SecondaryButton type="button" onClick={handleHide}>
              {t(MessageKeys.LabelCancel)}
            </SecondaryButton>
            <PrimaryButton
              type="submit"
              loading={loading}
              disabled={!nameValue.trimStart() || loading}
            >
              {t(MessageKeys.LabelSubmit)}
            </PrimaryButton>
          </ModalButtonRow>
        </ModalFormWrapper>
      </ModalBody>
    </Modal>
  );
};
