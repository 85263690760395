import { useTranslation } from 'react-i18next';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import {
  DataTable,
  DataTableColumnOptions,
  DataTableRowOptions,
  DataTableSortOrder,
  PrimaryButton,
  SpinningLoadingIcon,
  useToggle,
} from '@fcg-tech/regtech-components';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAgreementTypes } from '../../api/hooks/agreementTypesApiHooks';
import { GlobalActions } from '../../api/schema';
import { AccessControl } from '../../components/AccessControl';
import { ErrorMessage } from '../../components/ErrorBoundary';
import { MainLayoutPaddedContentWrapper } from '../../components/MainLayout';
import { PaginatedTableHeader } from '../../components/PaginatedTableHeader';
import {
  TableLoadingWrapper,
  TableNoContent,
  TableWrapper,
} from '../../components/tables';
import { routes } from '../../routes';
import { useTenant } from '../../states/tenantState';
import { MessageKeys } from '../../translations/messageKeys';
import { stringCompareFn } from '../../utils/compareFns';
import { AgreementTypesCreateModal } from './AgreementTypesCreateModal';
import {
  AgreementTypesTableRow,
  ColumnKeys,
  RowData,
} from './AgreementTypesTableRow';

export const AgreementTypesTable: FunctionComponent = () => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const navigate = useNavigate();
  const [showCreateModal, setShowCreateModal, toggleCreateModal] = useToggle();

  const { data, isValidating, error } = useAgreementTypes(tenant.id, {
    suspense: false,
  });
  const isLoading = !data && isValidating;

  const [sortOrder, setSortOrder] = useState<DataTableSortOrder<ColumnKeys>>({
    ascending: true,
    columnKey: 'name',
  });

  const columns = useMemo<DataTableColumnOptions<RowData, ColumnKeys>[]>(
    () => [
      {
        columnKey: 'name',
        label: t(MessageKeys.LabelName),
        resizable: false,
        sortable: true,
        initialSortAscending: true,
      },
    ],
    [t],
  );

  const dataRows = useMemo<DataTableRowOptions<RowData>[]>(() => {
    return data
      ?.map((agreementType) => ({
        id: agreementType.metadata.id,
        data: {
          name: agreementType.name,
        },
      }))
      .sort((a, b) => {
        if (sortOrder?.ascending === undefined) {
          return 0;
        }
        switch (sortOrder?.columnKey) {
          case 'name':
            return stringCompareFn(
              a.data.name,
              b.data.name,
              sortOrder.ascending,
            );
        }
      });
  }, [sortOrder, data]);

  const handleRowClick = useCallback(
    (agreementTypeId: string) => {
      navigate(
        constructUrl(routes.agreementType, {
          tenantId: tenant.id,
          agreementTypeId,
        }),
      );
    },
    [navigate, tenant.id],
  );

  const handleCreateAgreementTypeModalRequestClose = useCallback(
    async (agreementTypeId?: string) => {
      if (agreementTypeId) {
        navigate(
          constructUrl(routes.agreementType, {
            tenantId: tenant.id,
            agreementTypeId,
          }),
        );
      }
      setShowCreateModal(false);
    },
    [navigate, setShowCreateModal, tenant.id],
  );

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <>
      {showCreateModal ? (
        <AgreementTypesCreateModal
          onRequestClose={handleCreateAgreementTypeModalRequestClose}
        />
      ) : null}
      <MainLayoutPaddedContentWrapper>
        <PaginatedTableHeader
          totalResults={dataRows?.length ?? 0}
          heading={t(MessageKeys.AgreementTypesHeading)}
        >
          <AccessControl requiredPermissions={GlobalActions.AgreementTypeAdd}>
            <PrimaryButton onClick={toggleCreateModal}>
              {t(MessageKeys.AgreementTypesCreateNewAgreementTypeLabel)}
            </PrimaryButton>
          </AccessControl>
        </PaginatedTableHeader>
      </MainLayoutPaddedContentWrapper>
      <TableWrapper>
        <DataTable
          rows={dataRows}
          columns={columns}
          sortOrder={sortOrder.ascending !== undefined ? sortOrder : undefined}
          onSortOrderChange={setSortOrder}
          stickyHeader
        >
          {(row) => (
            <AgreementTypesTableRow
              key={row.id}
              row={row}
              onClick={handleRowClick}
            />
          )}
        </DataTable>
        {dataRows?.length === 0 ? (
          <TableNoContent>
            {t(MessageKeys.AgreementTypesNoItems)}
          </TableNoContent>
        ) : null}
        {isLoading ? (
          <TableLoadingWrapper>
            <SpinningLoadingIcon size="80" />
          </TableLoadingWrapper>
        ) : null}
      </TableWrapper>
    </>
  );
};
