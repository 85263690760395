import { useState, useCallback, useEffect } from 'react';

export const copyToClipboard = (text: string): boolean => {
  const el = document.createElement('textarea');
  el.setAttribute('readonly', '');
  el.value = text;
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  const success = document.execCommand('copy');
  document.body.removeChild(el);
  return success;
};

export interface CopyToClipboardOptions {
  successDuration?: number;
}

export const useCopyToClipboard = (
  text: string,
  { successDuration }: CopyToClipboardOptions,
): [isCopied: boolean, onCopy: () => void] => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (isCopied && successDuration) {
      const timeout = setTimeout(() => setIsCopied(false), successDuration);
      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [isCopied, successDuration]);

  const handleCopy = useCallback(() => {
    if (!isCopied) {
      const success = copyToClipboard(text);
      setIsCopied(success);
    }
  }, [isCopied, text]);

  return [isCopied, handleCopy];
};
