import { useTranslation } from 'react-i18next';
import {
  BooleanDecision,
  BooleanDecisionFieldLabel,
  BooleanDecisionFieldWrapper,
  DynamicList,
  EditIcon,
  Modal,
  ModalBody,
  ModalButtonRow,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  PrimaryButton,
  ReactSelectOption,
  SecondaryButton,
  TextArea,
  TextField,
} from '@fcg-tech/regtech-components';
import { Translator } from '@fcg-tech/regtech-types';
import { useFormik } from 'formik';
import update from 'immutability-helper';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRecoilValue } from 'recoil';
import * as yup from 'yup';
import { Country } from '../../api/schema';
import { countryState } from '../../states/countryState';
import { MessageKeys } from '../../translations/messageKeys';
import { getTooltip } from '../../utils/tooltipHelpers';
import {
  CreateSupplierFormikValues,
  Supplier,
  SupplierListItem,
} from '../../types';
import { FormSelect } from '../FormSelect';
import { ModalFormGroup, ModalFormLabel } from './modalFormComponents';
import {
  CreateSupplierModalActionRow,
  CreateSupplierModalActionRowButton,
  CreateSupplierModalActionRowIconWrapper,
  CreateSupplierModalFormWrapper,
} from './CreateSupplierModal.styles';

interface CreateSupplierModalProps {
  suppliers?: Array<SupplierListItem>;
  loadingSuppliers?: boolean;
  onSupplierEditClick?: (supplierId: string) => void;
  onSubmit: (supplier: Supplier) => Promise<void>;
  onRequestClose: () => void;
}

export const CreateSupplierModal: FunctionComponent<
  CreateSupplierModalProps
> = ({
  suppliers: initialSuppliers,
  loadingSuppliers,
  onSupplierEditClick,
  onSubmit,
  onRequestClose,
}) => {
  const { t } = useTranslation();
  const countries = useRecoilValue(countryState);
  const [saving, setSaving] = useState(false);
  const [editSupplier, setEditSupplier] = useState(undefined);
  const [suppliers, setSuppliers] =
    useState<Array<SupplierListItem>>(initialSuppliers);

  useEffect(() => {
    if (initialSuppliers) {
      setSuppliers(initialSuppliers);
    }
  }, [initialSuppliers]);

  const translateTooltip = useCallback(
    (value: string) => getTooltip(`supplier.${value}`),
    [],
  );

  const countryOptions = useMemo<Array<ReactSelectOption<Country>>>(
    () =>
      countries?.map((country) => ({
        label: country.name,
        value: country,
      })),
    [countries],
  );

  const supplierOptions = useMemo<Array<ReactSelectOption<SupplierListItem>>>(
    () =>
      suppliers?.map((supplier) => ({
        label: supplier.supplierName,
        value: supplier,
      })),
    [suppliers],
  );

  const validationSchema = useMemo(() => getValidationSchema(t), [t]);

  const formik = useFormik<CreateSupplierFormikValues>({
    initialValues: {
      supplierName: '',
      description: '',
      corporateRegistrationNumber: '',
      legalEntityIdentifier: '',
      countryOfRegistration: '',
      registeredAddress: '',
      contactDetails: [],
      isUltimateParent: false,
      parentCompany: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setSaving(true);
      const supplier: Supplier = { ...values };
      await onSubmit(supplier);
    },
  });

  const setFormikValues = useCallback(
    (supplier: SupplierListItem) => {
      if (!supplier) {
        formik.resetForm();
        setEditSupplier(undefined);
        return;
      }
      if (supplier.metadata) {
        formik.setValues({
          supplierName: supplier.supplierName,
          description: supplier.description,
          corporateRegistrationNumber: supplier.corporateRegistrationNumber,
          legalEntityIdentifier: supplier.legalEntityIdentifier,
          countryOfRegistration: supplier.countryOfRegistration,
          registeredAddress: supplier.registeredAddress,
          contactDetails: supplier.contactDetails,
          isUltimateParent: supplier.isUltimateParent,
          parentCompany: supplier.parentCompany,
        });
        setEditSupplier(supplier.metadata.id);
      } else {
        formik.resetForm();
        formik.setFieldValue('supplierName', supplier.supplierName);
        setEditSupplier(undefined);
      }
    },
    [formik],
  );

  const handleSupplierEditClick = useCallback(
    () => onSupplierEditClick(editSupplier),
    [editSupplier, onSupplierEditClick],
  );

  const handleCountryChange = useCallback(
    (option: ReactSelectOption<Country>) =>
      formik.setFieldValue('countryOfRegistration', option.value.isoAlpha2),
    [formik],
  );

  const handleSupplierChange = useCallback(
    (option: ReactSelectOption<SupplierListItem>) =>
      setFormikValues(option?.value),
    [setFormikValues],
  );

  const handleSupplierCreate = useCallback(
    (supplierName: string) => {
      const option: SupplierListItem = {
        metadata: null,
        supplierName: supplierName,
      };
      setSuppliers((old) => update(old, { $push: [option] }));
      setFormikValues(option);
    },
    [setFormikValues],
  );

  const handleIsUltimateParentChange = React.useCallback(
    (event) => {
      if (event.target.checked) {
        let value = event.target.value === 'true';
        if (event.target.value === 'notRequired') {
          value = event.target.value;
        }
        formik.setFieldValue('isUltimateParent', value);
      }
    },
    [formik],
  );

  const handleIsUltimateParentClear = React.useCallback(
    () => formik.setFieldValue('isUltimateParent', null),
    [formik],
  );

  const handleContactDetailsSet = React.useCallback(
    (value, index) => {
      if (index === undefined) {
        formik.setFieldValue(
          'contactDetails',
          update(formik.values.contactDetails, { $push: [value] }),
        );
      } else {
        formik.setFieldValue(
          'contactDetails',
          update(formik.values.contactDetails, { [index]: { $set: value } }),
        );
      }
    },
    [formik],
  );

  const handleContactDetailsRemove = React.useCallback(
    (index) =>
      formik.setFieldValue(
        'contactDetails',
        update(formik.values.contactDetails, { $splice: [[index, 1]] }),
      ),
    [formik],
  );

  const selectedCountryOption = useMemo<ReactSelectOption<Country>>(
    () =>
      countryOptions?.find(
        (o) => o.value.isoAlpha2 === formik.values.countryOfRegistration,
      ),
    [countryOptions, formik.values.countryOfRegistration],
  );

  const selectedSupplierOption = useMemo<ReactSelectOption<SupplierListItem>>(
    () =>
      supplierOptions?.find(
        (o) => o.value.supplierName === formik.values.supplierName,
      ),
    [formik.values.supplierName, supplierOptions],
  );

  return (
    <Modal onHide={onRequestClose}>
      <ModalHeader>
        <ModalTitle>{t(MessageKeys.SupplierCreateNewSupplierLabel)}</ModalTitle>
      </ModalHeader>

      <CreateSupplierModalFormWrapper onSubmit={formik.handleSubmit}>
        <ModalBody>
          <ModalFormGroup>
            <ModalFormLabel htmlFor="supplier-name">
              {t(MessageKeys.SupplierName)}
            </ModalFormLabel>
            <FormSelect
              value={selectedSupplierOption}
              options={supplierOptions}
              placeholder={t(
                MessageKeys.SupplierSelectorTypeToSelectOrCreateSupplierPlaceholder,
              )}
              isLoading={loadingSuppliers}
              isClearable
              info={translateTooltip('supplierName')}
              error={Boolean(formik.errors.supplierName) || editSupplier}
              errorMessage={
                editSupplier
                  ? t(MessageKeys.SupplierNameNameClashError)
                  : formik.errors.supplierName
              }
              formatCreateLabel={(supplierName) =>
                t(MessageKeys.SupplierSelectorCreateOptionText, {
                  supplierName,
                })
              }
              onChange={handleSupplierChange}
              onCreateOption={handleSupplierCreate}
            />
            {editSupplier && onSupplierEditClick ? (
              <CreateSupplierModalActionRow>
                <CreateSupplierModalActionRowButton
                  onClick={handleSupplierEditClick}
                >
                  {t(MessageKeys.SupplierEditThisSupplierLabel)}
                  <CreateSupplierModalActionRowIconWrapper>
                    <EditIcon size="16" />
                  </CreateSupplierModalActionRowIconWrapper>
                </CreateSupplierModalActionRowButton>
              </CreateSupplierModalActionRow>
            ) : null}
          </ModalFormGroup>
          <ModalFormGroup>
            <ModalFormLabel htmlFor="country-of-registration">
              {t(MessageKeys.SupplierCountryOfRegistration)}
            </ModalFormLabel>
            <FormSelect
              id="country-of-registration"
              name="countryOfRegistration"
              value={selectedCountryOption}
              options={countryOptions}
              isEditEnabled={!editSupplier}
              info={translateTooltip('countryOfRegistration')}
              onChange={handleCountryChange}
            />
          </ModalFormGroup>
          <ModalFormGroup>
            <ModalFormLabel htmlFor="registered-address">
              {t(MessageKeys.SupplierRegisteredAddress)}
            </ModalFormLabel>
            <TextArea
              id="registered-address"
              name="registeredAddress"
              value={formik.values.registeredAddress}
              info={translateTooltip('registeredAddress')}
              isEditEnabled={!editSupplier}
              onChange={formik.handleChange}
            />
          </ModalFormGroup>
          <ModalFormGroup>
            <BooleanDecisionFieldWrapper>
              <BooleanDecisionFieldLabel>
                {t(MessageKeys.SupplierUltimateParent)}
              </BooleanDecisionFieldLabel>
              <BooleanDecision
                value={formik.values.isUltimateParent}
                info={translateTooltip('isUltimateParent')}
                onChange={handleIsUltimateParentChange}
                onClear={handleIsUltimateParentClear}
                isEditEnabled={!editSupplier}
              />
            </BooleanDecisionFieldWrapper>
          </ModalFormGroup>
          {!formik.values.isUltimateParent ? (
            <ModalFormGroup>
              <ModalFormLabel htmlFor="parent-company">
                {t(MessageKeys.SupplierParentCompany)}
              </ModalFormLabel>
              <TextField
                id="parent-company"
                name="parentCompany"
                value={formik.values.parentCompany}
                info={translateTooltip('parentCompany')}
                isEditEnabled={!editSupplier}
                onChange={formik.handleChange}
              />
            </ModalFormGroup>
          ) : null}
          <ModalFormGroup>
            <ModalFormLabel htmlFor="corporate-registration-number">
              {t(MessageKeys.SupplierCorporateRegistrationNumber)}
            </ModalFormLabel>
            <TextField
              id="corporate-registration-number"
              name="corporateRegistrationNumber"
              value={formik.values.corporateRegistrationNumber}
              info={translateTooltip('corporateRegistrationNumber')}
              isEditEnabled={!editSupplier}
              onChange={formik.handleChange}
            />
          </ModalFormGroup>
          <ModalFormGroup>
            <ModalFormLabel htmlFor="legal-entity-identifier">
              {t(MessageKeys.SupplierLegalEntityIdentifier)}
            </ModalFormLabel>
            <TextField
              id="legal-entity-identifier"
              name="legalEntityIdentifier"
              value={formik.values.legalEntityIdentifier}
              info={translateTooltip('legalEntityIdentifier')}
              isEditEnabled={!editSupplier}
              onChange={formik.handleChange}
            />
          </ModalFormGroup>
          <ModalFormGroup>
            <ModalFormLabel>
              {t(MessageKeys.SupplierContactDetails)}
            </ModalFormLabel>
            <DynamicList
              placeholder={t(MessageKeys.SupplierContactDetailsPlaceholder)}
              value={formik.values.contactDetails}
              info={translateTooltip('contactDetails')}
              isEditEnabled={!editSupplier}
              onSetValue={handleContactDetailsSet}
              onRemoveValue={handleContactDetailsRemove}
            />
          </ModalFormGroup>
          <ModalFormGroup>
            <ModalFormLabel htmlFor="description">
              {t(MessageKeys.SupplierDescription)}
            </ModalFormLabel>
            <TextArea
              id="description"
              name="description"
              value={formik.values.description}
              info={translateTooltip('description')}
              isEditEnabled={!editSupplier}
              onChange={formik.handleChange}
            />
          </ModalFormGroup>
        </ModalBody>
        <ModalFooter>
          <ModalButtonRow>
            <SecondaryButton type="button" onClick={onRequestClose}>
              {t(MessageKeys.LabelCancel)}
            </SecondaryButton>
            <PrimaryButton
              type="submit"
              loading={saving}
              disabled={editSupplier || !formik.values.supplierName}
            >
              {t(MessageKeys.LabelSubmit)}
            </PrimaryButton>
          </ModalButtonRow>
        </ModalFooter>
      </CreateSupplierModalFormWrapper>
    </Modal>
  );
};

const getValidationSchema = (t: Translator) =>
  yup.object().shape({
    supplierName: yup.string().required(t(MessageKeys.SupplierNameRequired)),
  });
