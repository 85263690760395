import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import {
  Card,
  CardBody,
  CardTitle,
  Label,
  PageLayout,
  PageLayoutPaddedContent,
  PageHeader,
  PageHeaderActionButton,
  AccessControl,
} from '@fcg-tech/regtech-components';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { Trash2 } from 'lucide-react';
import { routes } from '../../../routes';
import { useTenant } from '../../../states/tenantState';
import { DataTable } from '../../../components/DataTable';
import { FormColumn, FormGroup, FormRow } from '../../../components/FormLayout';
import { PolicyField } from '../../../components/PolicyField';

const UserDetailsParagraph = styled.p`
  font-size: 1.4rem;
  margin-bottom: 3rem;
`;

const tableColumns = [
  { id: 'name', label: 'Groups' },
  { id: 'roleName', label: 'Inherited roles' },
];

const toTableData = ({ id, name, roles }) => ({
  id: id,
  name,
  roleName: roles?.map(({ name: roleName }) => `${roleName}`)?.join(', '),
});

export const UserPage = ({ user, groups, onDelete }) => {
  const navigate = useNavigate();
  const tenant = useTenant();
  const tenantId = tenant.id;

  const pageTitle = React.useMemo(() => {
    return user.username || '(Unnamed)';
  }, [user]);

  const handleRowClick = React.useCallback(
    (id) => {
      navigate(
        constructUrl(routes.group, {
          tenantId,
          groupId: id,
        }),
      );
    },
    [navigate, tenantId],
  );

  const initialValues = React.useMemo(
    () => ({
      ...user,
    }),
    [user],
  );

  return (
    <Formik initialValues={initialValues}>
      {() => (
        <PageLayout>
          <PageHeader title={pageTitle}>
            <AccessControl
              permissions={user.permissions}
              requiredPermissions={['iam:UserDelete']}
            >
              <PageHeaderActionButton onClick={onDelete}>
                <Trash2 size="24" />
              </PageHeaderActionButton>
            </AccessControl>
          </PageHeader>
          <PageLayoutPaddedContent>
            <Card>
              <CardBody>
                <CardTitle>Details</CardTitle>
                <FormRow>
                  <FormColumn>
                    <FormGroup>
                      <Label>Username</Label>
                      <UserDetailsParagraph>
                        {user.username}
                      </UserDetailsParagraph>
                    </FormGroup>
                  </FormColumn>
                </FormRow>
                <FormRow>
                  <FormColumn>
                    <FormGroup>
                      <Label>Member in groups</Label>
                      <DataTable
                        columns={tableColumns}
                        data={groups?.map(toTableData)}
                        onRowClick={handleRowClick}
                        emptyMessage="No groups found."
                        sortable={{ orderBy: 'name' }}
                      />
                    </FormGroup>
                  </FormColumn>
                </FormRow>
                <FormRow>
                  <FormColumn>
                    <FormGroup>
                      <PolicyField
                        title="Calculated policy"
                        policy={initialValues.calculated}
                      />
                    </FormGroup>
                  </FormColumn>
                </FormRow>
              </CardBody>
            </Card>
          </PageLayoutPaddedContent>
        </PageLayout>
      )}
    </Formik>
  );
};

const userShape = PropTypes.shape({
  username: PropTypes.string,
  calculated: PropTypes.shape({
    Statement: PropTypes.arrayOf(
      PropTypes.shape({
        action: PropTypes.string,
        resource: PropTypes.string,
      }),
    ),
  }),
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      roles: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        }),
      ),
    }),
  ),
});

const groupShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
});

UserPage.propTypes = {
  user: userShape,
  groups: PropTypes.arrayOf(groupShape),
  onDelete: PropTypes.func,
};

UserPage.defaultProps = {
  user: null,
  groups: [],
  onDelete: null,
};
