/* eslint-disable @typescript-eslint/no-explicit-any */
import { SelectOption } from '@fcg-tech/regtech-components';
import {
  AgreementType,
  Cabinet,
  Category,
  Country,
  Currency,
  InternalEntity,
  Region,
  Tag,
  WithMetadata,
} from '../types';

// Select option converters used for OLD select component in @fcg-tech/regtech-components

export const cabinetToSelectOption = (cabinet: Cabinet): SelectOption => ({
  key: cabinet.name,
  label: cabinet.name,
});

export const tagToSelectOption = (tag: Tag): SelectOption => ({
  key: tag.name,
  label: tag.name,
});

export const internalEntityToSelectOption = (
  internalEntity: WithMetadata<InternalEntity>,
): SelectOption => ({
  key: internalEntity.metadata.id,
  label: internalEntity.name,
});

// TODO: describe supplier type
export const supplierToSelectOption = (supplier: any): SelectOption => ({
  key: supplier.metadata.id,
  label: supplier.data.supplierName,
});

export const agreementTypeToSelectOption = (
  agreementType: WithMetadata<AgreementType>,
): SelectOption => ({
  key: agreementType.metadata.id,
  label: agreementType.name,
});

export const categoryToSelectOption = (
  category: WithMetadata<Category>,
): SelectOption => ({
  key: category.metadata.id,
  label: category.name,
});

export const countrySelectOptions = (country: Country): SelectOption => ({
  key: country.isoAlpha2,
  label: country.name,
});

export const currencySelectOption = (currency: Currency): SelectOption => ({
  key: currency.code,
  label: currency.name,
});

export const regionSelectOption = (region: Region): SelectOption => ({
  key: region.name,
  label: region.name,
});
