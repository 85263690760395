import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@fcg-tech/regtech-components';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';

const Message = styled.p`
  font-size: 1.4rem;
`;

const normalizeColumns = (arr) => {
  const isFlat = arr.every(
    (item) => !(item instanceof Object) && !(item instanceof Array),
  );

  if (isFlat) {
    return null;
  }

  const sortedArr = arr.sort((a, b) => {
    return Object.keys(a).length > Object.keys(b).length ? -1 : 1;
  });

  return Object.keys(sortedArr[0]).map((key) => {
    return { key, label: key };
  });
};

const getFieldValue = (item, key) => {
  const value = item[key] || null;
  if (!value) {
    return null;
  }

  if (value instanceof Array || value instanceof Object) {
    return <pre>{JSON.stringify(value, null, 2)}</pre>;
  }

  if (typeof value === 'boolean') {
    return value.toString();
  }

  return value;
};

const renderHead = (arr, cols) => (
  <TableHead>
    <TableRow>
      {cols.map(({ key, label }) => (
        <TableCell key={key}>{label}</TableCell>
      ))}
    </TableRow>
  </TableHead>
);

const renderBody = (arr, cols) => (
  <TableBody>
    {arr.map((item, rowIndex) => (
      // eslint-disable-next-line react/no-array-index-key
      <TableRow key={`table-body-row-${rowIndex}`}>
        {cols.map((col, colIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <TableCell key={`table-body-col-${colIndex}`}>
            {getFieldValue(item, col.key)}
          </TableCell>
        ))}
      </TableRow>
    ))}
  </TableBody>
);

export const JSONTable = ({ data }) => {
  const { t } = useTranslation();

  if (!data || !data.length) {
    return null;
  }

  const cols = normalizeColumns(data);
  if (!cols || !cols.length) {
    return (
      <Message>
        {t(MessageKeys.JSONTableResultNotRepresentedLabel)}
      </Message>
    );
  }

  return (
    <Table>
      {renderHead(data, cols)}
      {renderBody(data, cols)}
    </Table>
  );
};

JSONTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any,
};

JSONTable.defaultProps = {
  data: null,
};
