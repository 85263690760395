import React from 'react';
import { DynamicList as DefaultDynamicList } from '@fcg-tech/regtech-components';
import { FormikHelpers, FieldInputProps } from 'formik';

interface DynamicListProps
  extends React.ComponentPropsWithRef<typeof DefaultDynamicList> {
  form: FormikHelpers<Array<string>>;
  field: FieldInputProps<Array<string>>;
}

export const DynamicList = ({ form, field, ...props }: DynamicListProps) => {
  const handleSetValue = React.useCallback(
    (value, index) => {
      if (index === undefined) {
        form.setFieldValue(field.name, [...field.value, value]);
      } else {
        form.setFieldValue(field.name, [
          ...field.value.slice(0, index),
          value,
          ...field.value.slice(index + 1),
        ]);
      }
    },
    [form, field.name, field.value],
  );

  const handleRemoveValue = React.useCallback(
    (index) =>
      form.setFieldValue(field.name, [
        ...field.value.slice(0, index),
        ...field.value.slice(index + 1),
      ]),
    [form, field.name, field.value],
  );

  return (
    <DefaultDynamicList
      {...props}
      {...field}
      onSetValue={handleSetValue}
      onRemoveValue={handleRemoveValue}
    />
  );
};
