export enum AuthMessageKeys {
  AuthSessionExpired = 'auth-session-expired',
  AuthSessionExpiredMessage = 'auth-session-expired-message',
  AuthSessionExpiredUsername = 'auth-session-expired-username',
  AuthSessionExpiredNotYou = 'auth-session-expired-not-you',
  AuthSessionExpiredReAuthenticate = 'auth-session-expired-re-authenticate',
}

export type TranslationLocale = {
  language: string;
  messages: {
    [P in AuthMessageKeys]: string;
  };
};
