import React from 'react';
import { useAsync } from 'react-async';
import { useMatch } from 'react-router-dom';
import { EditProvider, Loader } from '@fcg-tech/regtech-components';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import {
  loadAgreement,
  loadAgreements,
  loadInternalEntities,
  loadCategories,
  loadAgreementAttachments,
  loadAgreementAttachmentViewUrl,
  loadSuppliers,
} from '../../api';
import { loadAgreementTypesPromiseFn } from '../../api/apis/agreementTypeAPI';
import { useTenant } from '../../states/tenantState';
import { routes } from '../../routes';
import { convertAgreement } from '../../converters/agreementConverter';
import { AgreementVersionPage } from './components';
import { AttachmentControlProvider } from '../../components/Attachments/AttachmentControlContext';
import { FieldCompareManager } from '../../components/FieldCompare/FieldCompareManager';
import { ErrorMessage } from '../../components/ErrorBoundary';

const loadAndConvertAttachments = async (...args) => {
  const json = await loadAgreementAttachments(...args);
  json.result = json.result.map((arr) => ({
    ...arr,
    uploaded: arr.uploaded ? new Date(arr.uploaded) : arr.uploaded,
  }));
  return json;
};

export const AgreementVersionContainer = () => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const tenantId = tenant.id;
  const match = useMatch(routes.agreementVersion);
  const { version, agreementId } = match.params;
  const previousVersion = parseInt(version) - 1;

  const loadAgreementReq = useAsync({
    promiseFn: loadAgreement,
    tenantId,
    agreementId,
    version,
  });

  const loadPreviousAgreementReq = useAsync({
    promiseFn: loadAgreement,
    tenantId,
    agreementId,
    version: previousVersion > 0 ? previousVersion : 1,
  });

  const loadAgreementsReq = useAsync({
    promiseFn: loadAgreements,
    tenantId,
  });

  const loadAttachmentsReq = useAsync({
    promiseFn: loadAndConvertAttachments,
    tenantId,
    agreementId,
    agreementVersion: version,
  });

  const loadInternalEntitiesReq = useAsync({
    promiseFn: loadInternalEntities,
    tenantId,
  });

  const loadCategoriesReq = useAsync({
    promiseFn: loadCategories,
    tenantId,
  });

  const loadSuppliersReq = useAsync({
    promiseFn: loadSuppliers,
    tenantId,
  });

  const loadAgreementTypesReq = useAsync({
    promiseFn: loadAgreementTypesPromiseFn,
    tenantId,
  });

  const loading =
    loadAgreementReq.isLoading ||
    loadPreviousAgreementReq.isLoading ||
    loadAgreementsReq.isLoading ||
    loadInternalEntitiesReq.isLoading ||
    loadCategoriesReq.isLoading ||
    loadSuppliersReq.isLoading ||
    loadAgreementTypesReq.isLoading;
  if (loading) {
    return <Loader message={t(MessageKeys.LabelLoadingAgreement)} />;
  }

  const error =
    loadAgreementReq.error ||
    loadPreviousAgreementReq.error ||
    loadInternalEntitiesReq.error ||
    loadCategoriesReq.error ||
    loadSuppliersReq.error ||
    loadAgreementsReq.error ||
    loadAgreementTypesReq.error;
  if (error) {
    return <ErrorMessage error={error} />;
  }

  const attachmentControl = {
    onDownloadAttachment: async (attachmentId) => {
      const { url } = await loadAgreementAttachmentViewUrl({
        tenantId,
        agreementId,
        attachmentId,
      });
      return url;
    },
  };

  return (
    <EditProvider value={false}>
      <AttachmentControlProvider value={attachmentControl}>
        <FieldCompareManager
          current={loadAgreementReq.data.data}
          previous={loadPreviousAgreementReq.data.data}
        >
          <AgreementVersionPage
            agreement={convertAgreement(loadAgreementReq.data)}
            agreements={loadAgreementsReq.data.result}
            attachments={loadAttachmentsReq?.data?.result || []}
            categories={loadCategoriesReq.data.result}
            internalEntities={loadInternalEntitiesReq.data.result}
            suppliers={loadSuppliersReq.data.result}
            agreementTypes={loadAgreementTypesReq.data.result}
          />
        </FieldCompareManager>
      </AttachmentControlProvider>
    </EditProvider>
  );
};
