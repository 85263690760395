import React from 'react';
import { useAsync } from 'react-async';
import { useMatch, useNavigate } from 'react-router-dom';
import {
  ConfirmDialog,
  EditProvider,
  Loader,
  useFlash,
} from '@fcg-tech/regtech-components';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import { useTenant } from '../../states/tenantState';
import { deleteCabinet, loadCabinet, loadCabinetAgreements } from '../../api';
import { routes } from '../../routes';
import { useErrorDialog } from '../../components/ErrorDialog';
import { EditCabinetPage } from './components';
import { ErrorMessage } from '../../components/ErrorBoundary';

export const EditCabinetContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tenant = useTenant();
  const tenantId = tenant.id;
  const match = useMatch(routes.cabinet);
  const { cabinetId } = match?.params ?? {};

  const showErrorDialog = useErrorDialog();
  const addFlash = useFlash();

  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);

  const loadCabinetReq = useAsync({
    promiseFn: loadCabinet,
    tenantId,
    cabinet: cabinetId,
  });

  const loadCabinetAgreementsReq = useAsync({
    promiseFn: loadCabinetAgreements,
    tenantId,
    cabinet: cabinetId,
    isArchived: false,
  });

  const deleteCabinetProxy = async (args) => {
    try {
      await deleteCabinet(...args);
      addFlash({
        level: 'success',
        content: t(MessageKeys.EditCabinetSuccessfullyDeletedLabel),
      });
      navigate(constructUrl(routes.cabinets, { tenantId }));
    } catch (err) {
      showErrorDialog({
        title: t(MessageKeys.LabelSomethingWentWrong),
        message: t(MessageKeys.EditCabinetFailedDeleteLabel),
      });
    }
  };

  const deleteCabinetReq = useAsync({ deferFn: deleteCabinetProxy });

  const handleDelete = React.useCallback(() => setShowDeleteConfirm(true), []);

  const handleDeleteChoice = React.useCallback(
    (choice) => {
      if (choice) {
        deleteCabinetReq.run({ tenantId, cabinet: cabinetId });
      }
      setShowDeleteConfirm(false);
    },
    [deleteCabinetReq, tenantId, cabinetId],
  );

  if (loadCabinetReq.isLoading) {
    return <Loader message={t(MessageKeys.LabelLoadingCabinet)} />;
  }

  if (loadCabinetReq.error) {
    return <ErrorMessage error={loadCabinetReq.error} />;
  }

  return (
    <>
      {showDeleteConfirm ? (
        <ConfirmDialog
          title={t(MessageKeys.LabelConfirm)}
          body={t(MessageKeys.EditCabinetConfirmDeleteLabel)}
          confirmText={t(MessageKeys.LabelConfirmDelete)}
          cancelText={t(MessageKeys.LabelNo)}
          onChoice={handleDeleteChoice}
        />
      ) : null}
      <EditProvider value={false}>
        <EditCabinetPage
          cabinet={loadCabinetReq.data}
          agreements={
            loadCabinetAgreementsReq.isFulfilled
              ? loadCabinetAgreementsReq.data.result
              : []
          }
          onDelete={handleDelete}
        />
      </EditProvider>
    </>
  );
};
