import { FunctionComponent } from 'react';
import { PageScrollingWrapper } from '../../components/Page';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { SuspenseLoader } from '../../components/SuspenseLoader';
import { TagsTable } from './TagsTable';

export const TagsPage: FunctionComponent = () => {
  return (
    <PageScrollingWrapper>
      <ErrorBoundary>
        <SuspenseLoader>
          <TagsTable />
        </SuspenseLoader>
      </ErrorBoundary>
    </PageScrollingWrapper>
  );
};
