import { foundation } from '@fcg-tech/regtech-design-system';
import styled from 'styled-components';

export const ToolbarWrapper = styled.div`
  background-color: ${foundation.palette.onyx100.hex};
  padding-right: 1rem;

  border-top: 2px solid white;
  border-bottom: 1px solid rgba(31, 75, 66, 0.25);
`;

export const ToolbarRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ToolbarItemWrapper = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;

  & > .lucide {
    margin-right: 1rem;
    color: ${foundation.palette.onyx350.hex};
  }
`;
