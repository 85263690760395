import { format } from 'date-fns';

export const formatDateTime = (date: Date | string) => {
  if (!date) {
    return '';
  }
  try {
    return format(
      typeof date === 'string' ? new Date(date) : date,
      'yyyy-MM-dd HH:mm',
    );
  } catch (err) {
    console.error(err);
    return 'N/A';
  }
};
