import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { PageScrollingWrapper } from '../../components/Page';
import { SuspenseLoader } from '../../components/SuspenseLoader';
import { SupplierHistoryTable } from './SupplierHistoryTable';

export const SupplierHistoryPage: FunctionComponent = () => {
  const params = useParams<{ supplierId?: string }>();

  return (
    <PageScrollingWrapper>
      <ErrorBoundary>
        <SuspenseLoader>
          <SupplierHistoryTable params={params} />
        </SuspenseLoader>
      </ErrorBoundary>
    </PageScrollingWrapper>
  );
};
