import { UnstyledButton } from '../Button';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const sideNavigationItemStyle = css`
  position: relative;
  ${({ theme }) => css`
    padding: 0.2rem 2rem 0.2rem 2.4rem;
    min-height: 2.4rem;
    margin: 0.4rem 0;
    display: flex;
    transition: padding 0.2s ease-out;

    &.no-mobile {
      @media (max-width: ${theme.breakpoints?.mobile ?? 730}px) {
        display: none;
      }
    }
  `}
`;

export const SideNavigationItemWrapper = styled.li`
  ${sideNavigationItemStyle}
  ${({ theme }) => theme?.elements?.sideNavigation?.item?.wrapper}
`;

export const SideNavigationItemIcon = styled.div.attrs({ size: 18 })`
  z-index: 5;
`;

export const SideNavigationItemLinkWrapper = styled(NavLink)`
  color: ${({ theme }) => theme.palette?.MainColor?.mainContrast ?? '#000'};
  font-weight: inherit;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 1;
  opacity: 0.7;
  will-change: opacity, color;
  overflow: hidden;
  width: 100%;

  .outlined & {
    padding-left: 1.6rem;
  }

  &:focus,
  &:active {
    color: ${({ theme }) => theme.palette?.MainColor?.mainContrast ?? '#000'};
  }

  &:hover {
    opacity: 1;
    color: ${({ theme }) => theme.palette?.MainColor.mainContrast ?? '#000'};
    ${({ theme }) => theme?.elements?.sideNavigation?.item?.link?.hover}
  }
  &:focus:not(.never-active) {
    opacity: 1;
    color: ${({ theme }) => theme.palette?.MainColor.mainContrast ?? '#000'};
    ${({ theme }) => theme?.elements?.sideNavigation?.item?.link?.focus}
  }

  &.active:not(.never-active) {
    opacity: 1;
    color: ${({ theme }) => theme.palette?.MainColor.mainContrast ?? '#000'};
    ${({ theme }) => theme?.elements?.sideNavigation?.item?.link?.active}
  }

  &:visited {
    opacity: 1;
    color: ${({ theme }) => theme.palette?.MainColor.mainContrast ?? '#000'};
    ${({ theme }) => theme?.elements?.sideNavigation?.item?.link?.visited}
  }

  &.active:not(.never-active):before,
  &:hover:not(.never-active):before {
    content: '';
    position: absolute;
    top: -0.2rem;
    left: 0;
    bottom: 0;
    width: 0.6rem;
    z-index: 0;
    background: ${({ theme }) =>
      theme?.palette?.MainColorAccent?.main ?? 'rgba(255,255,255,0.3)'};

    ${({ theme }) => theme?.elements?.sideNavigation?.item?.activeMarker}
  }

  &:not(.active):hover:before {
    opacity: 0.5;
  }

  ${({ theme }) => theme?.elements?.sideNavigation?.item?.link?.nominal}
`;

export const SideNavigationItemButton = styled(UnstyledButton)`
  color: ${({ theme }) => theme.palette?.MainColor.mainContrast ?? '#000'};
  font-weight: inherit;
  text-decoration: none;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  will-change: opacity, color;

  &:hover,
  &:focus {
    opacity: 1;
    color: ${({ theme }) => theme.palette?.MainColor.mainContrast ?? '#000'};
    ${({ theme }) => theme?.elements?.sideNavigation?.item?.button?.focus}
    ${({ theme }) => theme?.elements?.sideNavigation?.item?.button?.hover}
  }

  ${({ theme }) => theme?.elements?.sideNavigation?.item?.button?.nominal}
`;

export const SideNavigationItemLinkLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;
  overflow: hidden;
  z-index: 1;
  margin-left: 1rem;
  transition: margin-left 0.2s ease-out;
  letter-spacing: 0.005em;
  pointer-events: none;
  text-align: left;
  color: inherit;

  .collapsed & {
    margin-left: 2.6rem;
  }

  &.with-badge {
    margin-right: 4rem;
  }
`;

export const SideNavigationItemLinkLabelInner = styled.span`
  display: inline-block;
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 2ch;
  overflow: hidden;
  color: inherit;
`;
