import { classNames, useFocusEvents } from '@fcg-tech/regtech-utils';
import React, { useLayoutEffect, useRef } from 'react';
import { useCombinedRefs } from '../../utils';
import {
  ReadOnly,
  StyledInfoCircle,
  StyledTextArea,
  TextAreaErrorMessage,
  TextAreaWrapper,
} from './TextArea.styles';

export interface TextAreaProps
  extends React.HTMLAttributes<HTMLTextAreaElement> {
  error?: boolean;
  errorMessage?: string;
  info?: string;
  inputRef?: React.MutableRefObject<HTMLTextAreaElement>;
  autoFocus?: boolean;
  value?: string;
  isEditEnabled?: boolean;
  className?: string;
  disabled?: boolean;
  name?: string;
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      error,
      errorMessage,
      info,
      autoFocus,
      isEditEnabled = true,
      value = '',
      className,
      disabled,
      inputRef,
      name,
      onFocus: defaultOnFocus,
      onBlur: defaultOnBlur,
      ...props
    },
    ref,
  ) => {
    const innerRef = useRef<HTMLTextAreaElement>();
    const combinedRef = useCombinedRefs(ref, innerRef);
    useLayoutEffect(() => {
      if (autoFocus) {
        innerRef.current?.focus();
      }
    }, [autoFocus, inputRef]);

    useLayoutEffect(() => {
      if (inputRef) {
        inputRef.current = innerRef.current;
      }
    });

    const [hasFocus, onFocus, onBlur] = useFocusEvents(
      innerRef,
      defaultOnFocus,
      defaultOnBlur,
    );

    return (
      <TextAreaWrapper className={classNames(className, 'text-area-wrapper')}>
        {isEditEnabled ? (
          <>
            <StyledTextArea
              {...props}
              ref={combinedRef}
              className={classNames(error && !hasFocus && 'error')}
              name={name}
              disabled={!isEditEnabled || disabled}
              value={value}
              onBlur={onBlur}
              onFocus={onFocus}
            />
            {errorMessage && !hasFocus ? (
              <TextAreaErrorMessage>{errorMessage}</TextAreaErrorMessage>
            ) : null}
          </>
        ) : (
          <ReadOnly>{value}</ReadOnly>
        )}
        {info && <StyledInfoCircle info={info} />}
      </TextAreaWrapper>
    );
  },
);
