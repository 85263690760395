import React from 'react';
import { IconProps } from '../../types/icons';

export const AlignJustifyIcon = ({
  size = '24',
  color = 'currentColor',
  ...props
}: IconProps) => (
  <svg
    aria-hidden="true"
    data-prefix="fas"
    data-icon="align-justify"
    className="align-justify-icon_svg__svg-inline--fa align-justify-icon_svg__fa-align-justify align-justify-icon_svg__fa-w-14"
    viewBox="0 0 448 512"
    width={size}
    height={size}
    {...props}
  >
    <path
      fill={color}
      d="M432 416H16a16 16 0 00-16 16v32a16 16 0 0016 16h416a16 16 0 0016-16v-32a16 16 0 00-16-16zm0-128H16a16 16 0 00-16 16v32a16 16 0 0016 16h416a16 16 0 0016-16v-32a16 16 0 00-16-16zm0-128H16a16 16 0 00-16 16v32a16 16 0 0016 16h416a16 16 0 0016-16v-32a16 16 0 00-16-16zm0-128H16A16 16 0 000 48v32a16 16 0 0016 16h416a16 16 0 0016-16V48a16 16 0 00-16-16z"
    />
  </svg>
);
