import { MessageLevel } from '@fcg-tech/regtech-types';
import React, { FunctionComponent } from 'react';
import { CheckCircleIcon, CircleExclamationIcon, TriangleExclamationIcon } from '../Icons';
import { FlashNotificationIconWrapper, FlashNotificationWrapper } from './FlashNotification.styles';

export interface FlashNotificationProps {
  content: string;
  level?: MessageLevel;
  onClose?: boolean;
}

export const FlashNotification: FunctionComponent<FlashNotificationProps> = ({content, level, onClose}) => {

  return (
    <FlashNotificationWrapper level={level}>
      {content}
      <FlashNotificationIconWrapper>
        {level === 'info' ? <CircleExclamationIcon size="20" /> : null}
        {level === 'success' ? <CheckCircleIcon size="20" /> : null}
        {level === 'warning' ? <CircleExclamationIcon size="20" /> : null}
        {level === 'error' ? <TriangleExclamationIcon size="20" /> : null}
      </FlashNotificationIconWrapper>
    </FlashNotificationWrapper>
  );
};
