import { RemoteLog } from 'loglevel-plugin-remote';
import { Logger } from './logger';
import { ILogger, LoggerConfiguration, LogOptions, Log } from './types';

export const formatLog = (log: RemoteLog): Log => {
  const { message, options } = parseLogMessage(log.message);
  return {
    ...(options ?? {}),
    message: message ?? '',
    level: log.level.label,
    timestamp: log.timestamp,
  } as Log;
};

export const parseLogMessage = (
  message: string,
): { message: string; options?: LogOptions } => {
  const [msg, opts] = message?.split(' Object');
  let options: LogOptions | undefined;
  try {
    options = (JSON.parse(opts) ?? {}) as LogOptions;
  } catch (err) {
    // noop
  }
  return { message: msg, options };
};

let singleton: ILogger;
export const setupLogger = (config: LoggerConfiguration): ILogger => {
  if (singleton) {
    return singleton;
  }
  singleton = new Logger(config);
  return singleton;
};
