import { useTranslation } from 'react-i18next';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { SecondaryButton } from '@fcg-tech/regtech-components';
import { FunctionComponent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useSupplier,
  useSupplierHistory,
} from '../../api/hooks/suppliersApiHooks';
import { ErrorMessage } from '../../components/ErrorBoundary';
import { MainLayoutPaddedContentWrapper } from '../../components/MainLayout';
import { PaginatedTableHeader } from '../../components/PaginatedTableHeader';
import { VersionHistoryTable } from '../../components/VersionHistoryTable';
import { routes } from '../../routes';
import { useTenant } from '../../states/tenantState';
import { MessageKeys } from '../../translations/messageKeys';

interface SupplierHistoryTableProps {
  params?: Record<string, string>;
}

export const SupplierHistoryTable: FunctionComponent<
  SupplierHistoryTableProps
> = ({ params }) => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const navigate = useNavigate();

  const { data: supplier } = useSupplier(tenant.id, params?.supplierId);

  const headingProps: Record<string, unknown> = {};
  if (params?.supplierId) {
    headingProps.supplier = supplier?.data?.supplierName ?? '...';
  }
  const heading = t(MessageKeys.SupplierHistoryHeading, {
    ...headingProps,
  });

  const { data, isValidating, error } = useSupplierHistory(
    tenant.id,
    params?.supplierId,
    {
      suspense: false,
    },
  );
  const isLoading = !data && isValidating;

  const handleRowClick = useCallback(
    (supplierId: string, version: string) => {
      navigate(
        constructUrl(routes.supplierVersion, {
          tenantId: tenant.id,
          supplierId: supplierId,
          version,
        }),
      );
    },
    [navigate, tenant.id],
  );

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <>
      <MainLayoutPaddedContentWrapper>
        <PaginatedTableHeader
          totalResults={data?.length ?? 0}
          heading={heading}
        >
          <SecondaryButton
            to={constructUrl(routes.supplier, {
              tenantId: tenant.id,
              supplierId: params?.supplierId,
            })}
          >
            {t(MessageKeys.LabelCancel)}
          </SecondaryButton>
        </PaginatedTableHeader>
      </MainLayoutPaddedContentWrapper>
      <VersionHistoryTable
        versions={data}
        loading={isLoading}
        onRowClick={handleRowClick}
      />
    </>
  );
};
