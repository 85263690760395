import styled from 'styled-components';
import {
  IconButton,
  ChevronRightIcon,
  excludeProps,
} from '@fcg-tech/regtech-components';

export const TableCellIndent = styled.span.withConfig<{ depth: number }>(
  excludeProps('depth'),
)`
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: ${({ depth }) => `${depth * 1}rem`};
`;

export const TableCellEllipsis = styled.span`
  overflow: hidden;
  word-wrap: nowrap;
  text-overflow: ellipsis;
`;

export const StyledIconButton = styled(IconButton)`
  width: 2.8rem;
  height: 2.8rem;
`;

export const ChevronIcon = styled(ChevronRightIcon)
  .attrs({ size: '16' })
  .withConfig(excludeProps('left', 'down', 'up'))<{
  left?: boolean;
  down?: boolean;
  up?: boolean;
}>`
  transform: rotate(${({ left, down, up }) =>
    left ? '-180deg' : down ? '90deg' : up ? '-90deg' : '0deg'});
`;
