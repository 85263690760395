import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Loader,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TrashIcon,
  EditIcon,
  useSortableTable,
} from '@fcg-tech/regtech-components';

const EmptyMessage = styled.div`
  flex: 1;
  text-align: center;
`;

const LoaderContainer = styled.div`
  margin-top: 1rem;
`;

const StyledIconButton = styled(IconButton)`
  width: 2.8rem;
  height: 2.8rem;
`;

const makeTableHeaderCellProps = (key, { sortedColumnKey, ...props }) => ({
  sortable: true,
  sortKey: key,
  isSorted: sortedColumnKey === key,
  ...props,
});

const defaultSorter = (a, b, key, ascending) => {
  const aa = ascending ? a : b;
  const bb = ascending ? b : a;

  if (!aa[key]) return -1;
  if (!bb[key]) return 1;
  return aa[key].localeCompare(bb[key]);
};

export const DataTable = ({
  data,
  columns,
  isLoading,
  loadingMessage,
  emptyMessage,
  sortable,
  editable,
  onRowClick,
}) => {
  const { orderBy, onRequestSort } = sortable || {};
  const { onRowEdit, onRowDelete } = editable || {};

  const canEdit = React.useMemo(() => {
    return onRowEdit || onRowDelete;
  }, [onRowEdit, onRowDelete]);

  const { sortedItems, ...sortProps } = useSortableTable({
    column: orderBy,
    sorter: onRequestSort || defaultSorter,
    items: data,
  });

  const handleRowClick = React.useCallback(
    (event) => {
      if (onRowClick) {
        const { id } = event.currentTarget.dataset;
        onRowClick(id);
      }
    },
    [onRowClick],
  );

  return (
    <>
      <Table>
        <TableHead>
          <TableRow clickable>
            {columns?.map(({ id, label }) => (
              <TableCell key={id} {...makeTableHeaderCellProps(id, sortProps)}>
                {label}
              </TableCell>
            ))}
            {canEdit && <TableCell>Actions</TableCell>}
          </TableRow>
        </TableHead>
        {!isLoading && (
          <TableBody>
            {sortedItems?.length ? (
              sortedItems.map((item) => (
                <TableRow
                  key={item.id}
                  data-id={item.id}
                  clickable={!!onRowClick}
                  onClick={handleRowClick}
                >
                  {columns?.map(({ id: columnId }) => (
                    <TableCell key={columnId}>{item?.[columnId]}</TableCell>
                  ))}
                  {canEdit && (
                    <TableCell>
                      {onRowEdit && (
                        <StyledIconButton onClick={() => onRowEdit(item.id)}>
                          <EditIcon size="16" />
                        </StyledIconButton>
                      )}
                      {onRowDelete && (
                        <StyledIconButton onClick={() => onRowDelete(item.id)}>
                          <TrashIcon size="16" />
                        </StyledIconButton>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns?.length + (canEdit ? 1 : 0)}>
                  <EmptyMessage>{emptyMessage}</EmptyMessage>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
      {isLoading && (
        <LoaderContainer>
          <Loader message={loadingMessage} size="small" disablePortal />
        </LoaderContainer>
      )}
    </>
  );
};

DataTable.Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1.4rem;
`;

const dataShape = PropTypes.shape({
  id: PropTypes.string,
});

const columnShape = PropTypes.shape({
  id: PropTypes.string,
  label: PropTypes.string,
});

const sortableShape = PropTypes.shape({
  orderBy: PropTypes.string,
  onRequestSort: PropTypes.func,
});

const editableShape = PropTypes.shape({
  onRowEdit: PropTypes.func,
  onRowDelete: PropTypes.func,
});

DataTable.propTypes = {
  data: PropTypes.arrayOf(dataShape),
  columns: PropTypes.arrayOf(columnShape),
  isLoading: PropTypes.bool,
  loadingMessage: PropTypes.string,
  emptyMessage: PropTypes.string,
  onRowClick: PropTypes.func,
  sortable: sortableShape,
  editable: editableShape,
};

DataTable.defaultProps = {
  data: [],
  columns: [],
  isLoading: false,
  loadingMessage: 'Loading',
  emptyMessage: '',
  onRowClick: null,
  sortable: null,
  editable: null,
};
