/* eslint-disable react/jsx-no-useless-fragment */
import { array } from '@fcg-tech/regtech-utils';
import React from 'react';

/**
 *
 * @param permissions
 * @param requiredPermissions
 * @returns true if all permissions in requiredPermissions are found in permissions
 */
export const useAccessControl = <T,>(
  permissions: T | Array<T>,
  requiredPermissions: T | Array<T>,
) => {
  const arrRequiredPermissions = array(requiredPermissions);
  if (arrRequiredPermissions?.length === 0) {
    return true;
  }

  return arrRequiredPermissions?.every((permission) =>
    array(permissions)?.includes(permission),
  );
};

export interface AccessControlProps<T> {
  permissions?: T | Array<T>;
  requiredPermissions?: T | Array<T>;
  renderNoAccess?: () => React.ReactNode | JSX.Element | null;
  inverted?: boolean;
}

export const AccessControl = <T,>({
  children,
  permissions = [],
  requiredPermissions = [],
  inverted,
  renderNoAccess = () => null,
}: React.PropsWithChildren<AccessControlProps<T>>) => {
  const permitted = useAccessControl(permissions, requiredPermissions);

  if ((permitted && !inverted) || (!permitted && inverted)) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <React.Fragment>{children}</React.Fragment>;
  }

  return <>{renderNoAccess?.() ?? null}</>;
};
