/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetInternalEntityResponse,
  InternalEntity,
  ListAllInternalEntitiesResponse,
} from '../models';
import {
    GetInternalEntityResponseFromJSON,
    GetInternalEntityResponseToJSON,
    InternalEntityFromJSON,
    InternalEntityToJSON,
    ListAllInternalEntitiesResponseFromJSON,
    ListAllInternalEntitiesResponseToJSON,
} from '../models';

export interface CreateInternalEntityRequest {
    internalEntity?: InternalEntity;
}

export interface DeleteInternalEntityRequest {
    internalEntityId: string;
}

export interface GetInternalEntityRequest {
    internalEntityId: string;
}

export interface UpdateInternalEntityRequest {
    internalEntityId: string;
    internalEntity?: InternalEntity;
}

/**
 * InternalEntitiesApi - interface
 * 
 * @export
 * @interface InternalEntitiesApiInterface
 */
export interface InternalEntitiesApiInterface {
    /**
     * 
     * @summary Create an InternalEntity
     * @param {InternalEntity} [internalEntity] Inventory item to add
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEntitiesApiInterface
     */
    createInternalEntityRaw(requestParameters: CreateInternalEntityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Create an InternalEntity
     */
    createInternalEntity(requestParameters: CreateInternalEntityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Delete a single internal entity
     * @param {string} internalEntityId The id of the internalEntity to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEntitiesApiInterface
     */
    deleteInternalEntityRaw(requestParameters: DeleteInternalEntityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete a single internal entity
     */
    deleteInternalEntity(requestParameters: DeleteInternalEntityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Get an InternalEntity
     * @param {string} internalEntityId The id of the internalEntity to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEntitiesApiInterface
     */
    getInternalEntityRaw(requestParameters: GetInternalEntityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInternalEntityResponse>>;

    /**
     * Get an InternalEntity
     */
    getInternalEntity(requestParameters: GetInternalEntityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInternalEntityResponse>;

    /**
     * 
     * @summary List all internal entities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEntitiesApiInterface
     */
    listAllInternalEntitiesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListAllInternalEntitiesResponse>>;

    /**
     * List all internal entities
     */
    listAllInternalEntities(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListAllInternalEntitiesResponse>;

    /**
     * 
     * @summary Update an InternalEntity
     * @param {string} internalEntityId The id of the internalEntity to retrieve
     * @param {InternalEntity} [internalEntity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEntitiesApiInterface
     */
    updateInternalEntityRaw(requestParameters: UpdateInternalEntityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Update an InternalEntity
     */
    updateInternalEntity(requestParameters: UpdateInternalEntityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class InternalEntitiesApi extends runtime.BaseAPI implements InternalEntitiesApiInterface {

    /**
     * Create an InternalEntity
     */
    async createInternalEntityRaw(requestParameters: CreateInternalEntityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/oasys/internalEntities`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InternalEntityToJSON(requestParameters.internalEntity),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create an InternalEntity
     */
    async createInternalEntity(requestParameters: CreateInternalEntityRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createInternalEntityRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a single internal entity
     */
    async deleteInternalEntityRaw(requestParameters: DeleteInternalEntityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.internalEntityId === null || requestParameters.internalEntityId === undefined) {
            throw new runtime.RequiredError('internalEntityId','Required parameter requestParameters.internalEntityId was null or undefined when calling deleteInternalEntity.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/internalEntities/{internalEntityId}`.replace(`{${"internalEntityId"}}`, encodeURIComponent(String(requestParameters.internalEntityId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a single internal entity
     */
    async deleteInternalEntity(requestParameters: DeleteInternalEntityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteInternalEntityRaw(requestParameters, initOverrides);
    }

    /**
     * Get an InternalEntity
     */
    async getInternalEntityRaw(requestParameters: GetInternalEntityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInternalEntityResponse>> {
        if (requestParameters.internalEntityId === null || requestParameters.internalEntityId === undefined) {
            throw new runtime.RequiredError('internalEntityId','Required parameter requestParameters.internalEntityId was null or undefined when calling getInternalEntity.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/internalEntities/{internalEntityId}`.replace(`{${"internalEntityId"}}`, encodeURIComponent(String(requestParameters.internalEntityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInternalEntityResponseFromJSON(jsonValue));
    }

    /**
     * Get an InternalEntity
     */
    async getInternalEntity(requestParameters: GetInternalEntityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInternalEntityResponse> {
        const response = await this.getInternalEntityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all internal entities
     */
    async listAllInternalEntitiesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListAllInternalEntitiesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/internalEntities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListAllInternalEntitiesResponseFromJSON(jsonValue));
    }

    /**
     * List all internal entities
     */
    async listAllInternalEntities(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListAllInternalEntitiesResponse> {
        const response = await this.listAllInternalEntitiesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update an InternalEntity
     */
    async updateInternalEntityRaw(requestParameters: UpdateInternalEntityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.internalEntityId === null || requestParameters.internalEntityId === undefined) {
            throw new runtime.RequiredError('internalEntityId','Required parameter requestParameters.internalEntityId was null or undefined when calling updateInternalEntity.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/oasys/internalEntities/{internalEntityId}`.replace(`{${"internalEntityId"}}`, encodeURIComponent(String(requestParameters.internalEntityId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InternalEntityToJSON(requestParameters.internalEntity),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update an InternalEntity
     */
    async updateInternalEntity(requestParameters: UpdateInternalEntityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateInternalEntityRaw(requestParameters, initOverrides);
    }

}
