import { TFunction } from 'react-i18next';
import { SWRResponse } from 'swr';

export type Maybe<T> = T | undefined | null;

export type SWRMutateFn<Data> = SWRResponse<Data, unknown>['mutate'];

export type PartialWithId<T extends { id: unknown }> = Partial<Omit<T, 'id'>> &
  Pick<T, 'id'>;
export type WithoutId<T extends { id: unknown }> = Omit<T, 'id'>;
export type MaybeId<T extends { id: unknown }> = WithoutId<T> &
  Partial<Pick<T, 'id'>>;

export enum MessageLevel {
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
  Success = 'success',
}

export type PathParameters<T extends { path: unknown }> = T['path'];
export type QueryParameters<T extends { query: unknown }> = T['query'];

export type Unpacked<T> = T extends (infer U)[] ? U : T;

type OperationRequest = {
  requestBody: {
    'application/json': unknown;
  };
};

export type RequestBody<O extends OperationRequest> =
  O['requestBody']['application/json'];
type Operation200 = {
  responses: {
    '200': {
      'application/json': unknown;
    };
  };
};

type Operation201 = {
  responses: {
    '201': {
      'application/json': unknown;
    };
  };
};

export type R20X<T> = T extends Operation200
  ? T['responses']['200']['application/json']
  : T extends Operation201
  ? T['responses']['201']['application/json']
  : never;

export type ArrowDirection =
  | 'ArrowUp'
  | 'ArrowDown'
  | 'ArrowLeft'
  | 'ArrowRight';

export type Translator = TFunction<string>;

export type EmptyPropsWithChildren = {
  children?: React.ReactNode | undefined;
};
// See libs/regtech-api-utils/src/lib/apiUtils.ts#formatApiDate
export type OpenApiDate = {
  toString: () => string;
  toISOString: () => string;
  isApiDate: true;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type DefinedPrimitive = string | number | boolean | symbol | object;
