import { useMemo } from 'react';
import useSWR, { mutate, SWRConfiguration } from 'swr';
import { EMPTY_SUPPLIER } from '../../constants';
import { convertSupplierListItem } from '../../converters/supplierConverters';
import {
  GetSupplierResponse,
  Metadata,
  Supplier,
  SupplierListItem,
} from '../../types';
import { getResourceIdFromLocationHeader, useApi } from '../apiUtils';
import { GetSupplierVersionResponse, SuppliersApi } from '../schema';
import {
  allSuppliersKey,
  supplierHistoryKey,
  supplierKey,
  supplierVersionKey,
} from './cacheKeys';

export const getSuppliers = async (
  api: SuppliersApi,
): Promise<Array<SupplierListItem>> => {
  const result = await api.listAllSuppliers();
  return result.result?.map(convertSupplierListItem);
};

export const useSuppliers = (tenantId: string, config?: SWRConfiguration) => {
  const api = useApi<SuppliersApi>('SuppliersApi', tenantId);

  return useSWR<Array<SupplierListItem>>(
    allSuppliersKey(tenantId),
    () => getSuppliers(api),
    {
      suspense: true,
      ...config,
    },
  );
};

export const getSupplier = async (
  supplierId: string,
  api: SuppliersApi,
): Promise<GetSupplierResponse> => {
  const result = await api.getSupplier({ supplierId });
  return result;
};

export const useSupplier = (
  tenantId: string,
  supplierId: string,
  config?: SWRConfiguration,
) => {
  const api = useApi<SuppliersApi>('SuppliersApi', tenantId);
  return useSWR<GetSupplierResponse>(
    supplierKey(tenantId, supplierId),
    () => getSupplier(supplierId, api),
    { suspense: true, ...config },
  );
};

export const getSupplierHistory = async (
  supplierId: string,
  api: SuppliersApi,
): Promise<Array<Metadata>> => {
  const result = await api.listAllSupplierVersions({ supplierId });
  return result.result?.map(({ metadata }) => metadata) ?? [];
};

export const useSupplierHistory = (
  tenantId: string,
  supplierId: string,
  config?: SWRConfiguration,
) => {
  const api = useApi<SuppliersApi>('SuppliersApi', tenantId);
  return useSWR<Array<Metadata>>(
    supplierHistoryKey(tenantId, supplierId),
    () => getSupplierHistory(supplierId, api),
    { suspense: true, ...config },
  );
};

export const getSupplierVersion = async (
  supplierId: string,
  version: string,
  api: SuppliersApi,
): Promise<GetSupplierVersionResponse> => {
  const result = await api.getSupplierVersion({ supplierId, version });
  return result;
};

export const useSupplierVersion = (
  supplierId: string,
  version: string,
  config?: SWRConfiguration,
) => {
  const tenant = 'xjk';
  const api = useApi<SuppliersApi>('SuppliersApi', tenant);
  return useSWR<GetSupplierVersionResponse>(
    supplierVersionKey(tenant, supplierId, version),
    () => getSupplierVersion(supplierId, version, api),
    { suspense: true, ...config },
  );
};

export const useSupplierActions = (tenantId: string) => {
  const api = useApi<SuppliersApi>('SuppliersApi', tenantId);
  return useMemo(
    () => ({
      createSupplier: async (supplier: Supplier) => {
        const createSupplierResponse = await api.createSupplierRaw({
          supplier: {
            ...supplier,
            supplierAssessment: {
              ...EMPTY_SUPPLIER.supplierAssessment,
            },
          },
        });
        mutate(allSuppliersKey(tenantId));
        return getResourceIdFromLocationHeader(createSupplierResponse);
      },
      updateSupplier: async (supplierId: string, supplier: Supplier) => {
        await api.updateSupplier({ supplierId, supplier });
        // TODO: mutate
      },
      deleteSupplier: async (supplierId: string) => {
        await api.deleteSupplier({ supplierId });
        // TODO: mutate
      },
    }),
    [api, tenantId],
  );
};
