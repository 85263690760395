import { debounce } from '@fcg-tech/regtech-utils';
import { useCallback, useEffect, useState } from 'react';

type Placement = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

export const useShowcaseWrapperPosition = (htmlElement: HTMLElement) => {
  const calculate = useCallback(() => {
    const box = htmlElement.getBoundingClientRect();
    const middleX = box.left + box.width / 2;
    const middleY = box.top + box.height / 2;
    const viewportMiddleX = window.innerWidth / 2;
    const viewportMiddleY = window.innerHeight / 2;

    let placement: Placement;

    if (middleX < viewportMiddleX && middleY < viewportMiddleY) {
      placement = 'top-left';
    } else if (middleX > viewportMiddleX && middleY < viewportMiddleY) {
      placement = 'top-right';
    } else if (middleX < viewportMiddleX && middleY > viewportMiddleY) {
      placement = 'bottom-left';
    } else {
      placement = 'bottom-right';
    }

    return { placement, cx: middleX, cy: middleY };
  }, [htmlElement]);

  const [position, setPosition] = useState<{
    placement: Placement;
    cx: number;
    cy: number;
  }>(calculate());

  useEffect(() => {
    const handleResize = debounce(() => {
      const result = calculate();
      setPosition(result);
    }, 500);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [calculate]);

  return position;
};

export const getSeguePosition = (
  placement: Placement,
  cx: number,
  cy: number,
  radius: number,
): { x: number; y: number; width: number; height: number } => {
  switch (placement) {
    case 'top-left':
      return {
        x: cx + radius,
        y: cy,
        width: radius * 2,
        height: 4,
      };
    case 'top-right':
      return {
        x: cx - radius * 2,
        y: cy,
        width: radius * 2,
        height: 4,
      };
    case 'bottom-left':
      return {
        x: cx + radius,
        y: cy - radius * 2,
        width: radius * 2,
        height: 4,
      };
    case 'bottom-right':
      return {
        x: cx - radius * 2,
        y: cy - radius * 2,
        width: radius * 2,
        height: 4,
      };
  }
};

export const getContentPosition = (
  placement: Placement,
  cx: number,
  cy: number,
  radius: number,
) => {
  switch (placement) {
    case 'top-left':
      return {
        left: cx + radius * 2,
        top: cy - radius,
      };
    case 'top-right':
      return {
        right: window.innerWidth - cx + radius * 2,
        top: cy - radius,
      };
    case 'bottom-left':
      return {
        left: cx + radius * 2,
        bottom: window.innerHeight - cy + radius,
      };
    case 'bottom-right':
      return {
        right: window.innerWidth - cx + radius * 2,
        bottom: window.innerHeight - cy + radius,
      };
  }
};
