import styled from 'styled-components';
import {
  SideNavigationItemLinkWrapper,
  sideNavigationItemStyle,
} from './SideNavigationItem.styles';

export const SideNavigationSectionHeading = styled.h4`
  color: ${({ theme }) => theme?.colors?.primary?.contrast ?? '#000'};

  font-weight: 600;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  margin: 2rem 0 0.6rem 0;
  padding-bottom: 0.4rem;
  z-index: 1;

  ${sideNavigationItemStyle};

  margin: 1.2rem 0 0.6rem 0;

  & > ${SideNavigationItemLinkWrapper} {
    padding: 0.4rem 0;
  }

  ${({ theme }) => theme?.elements?.sideNavigation?.sectionHeading}
`;
