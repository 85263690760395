import styled from 'styled-components';

export const NotificationsSettingsWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  margin-top: 1rem;
  width: 100%;
`;

export const NotificationsSettingsLabel = styled.div`
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme?.typography?.fontFamily ?? 'inherit'};
  font-weight: bold;
  margin-top: 0.4rem;
`;

export const NotificationsSettingsToggleSwitchWrapper = styled.div`
  margin-left: 10rem;
  & > label {
    margin: 0;
    padding: 0;
    margin-right: 1rem;
    font-size: 1.4rem;
    font-weight: 500;
  }
`;
