import { ReactSelectOption } from '@fcg-tech/regtech-components';
import { useMemo } from 'react';
import { StylesConfig } from 'react-select';
import { useTheme } from 'styled-components';

export const multiSelectStyles: StylesConfig<unknown, true> = {
  container: (p) => ({
    ...p,
    fontSize: '1.4rem',
    minWidth: '28rem',
  }),
  menu: (p) => ({
    ...p,
    zIndex: 10,
  }),
};

export const singleSelectStyles: StylesConfig<unknown, false> = {
  container: (p) => ({
    ...p,
    fontSize: '1.4rem',
    minWidth: '28rem',
  }),
  menu: (p) => ({
    ...p,
    zIndex: 10,
  }),
};

export const useFormSelectStyles = (
  error?: boolean,
): StylesConfig<ReactSelectOption<unknown>, boolean> => {
  const theme = useTheme();
  return useMemo(() => {
    return {
      container: (p) => ({
        ...p,
        fontSize: '1.4rem',
        minWidth: '28rem',
        width: '100%',
      }),
      control: (p, state) => {
        if (!state.isFocused && error) {
          return {
            ...p,
            border: `1px solid ${theme?.colors?.error?.main ?? 'red'}`,
            boxShadow: `0 0 0 1px ${theme?.colors?.error?.main ?? 'red'}`,
          };
        }
        return p;
      },
      menu: (p) => ({
        ...p,
        zIndex: 10,
      }),
    };
  }, [error, theme?.colors?.error?.main]);
};
