import { useTranslation } from 'react-i18next';
import {
  FormRow,
  Modal,
  ModalBody,
  ModalButtonRow,
  ModalHeader,
  ModalTitle,
  PrimaryButton,
  SecondaryButton,
  TextField,
  useFlash,
  useFocus,
  useInput,
} from '@fcg-tech/regtech-components';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useCategoryActions } from '../../api/hooks/categoriesApiHooks';
import { ModalFormLabel, ModalFormWrapper } from '../../components/modals';
import { useTenant } from '../../states/tenantState';
import { MessageKeys } from '../../translations/messageKeys';

interface CategoriesCreateModalProps {
  onRequestClose: (categoryId?: string) => void;
}

export const CategoriesCreateModal: FunctionComponent<
  CategoriesCreateModalProps
> = ({ onRequestClose }) => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const addFlash = useFlash();
  const [loading, setLoading] = useState(false);
  const [name, handleNameChange] = useInput<HTMLInputElement>('');
  const { createCategory } = useCategoryActions(tenant.id);

  const ref = useFocus<HTMLInputElement>();

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      setLoading(true);
      try {
        const categoryId = await createCategory(name);
        addFlash({
          level: 'success',
          content: t(MessageKeys.CategoriesCreateCategorySuccess),
        });
        onRequestClose(categoryId);
      } catch (err) {
        setLoading(false);
        addFlash({
          level: 'error',
          content: t(MessageKeys.CategoriesCreateCategoryFailure),
        });
      }
    },
    [addFlash, createCategory, name, onRequestClose, t],
  );

  const handleHide = useCallback(
    () => onRequestClose(undefined),
    [onRequestClose],
  );

  return (
    <Modal onHide={handleHide}>
      <ModalHeader>
        <ModalTitle>
          {t(MessageKeys.CategoriesCreateNewCategoryLabel)}
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <ModalFormWrapper onSubmit={handleSubmit}>
          <FormRow>
            <ModalFormLabel htmlFor="category-name">
              {t(MessageKeys.LabelName)}
            </ModalFormLabel>
            <TextField
              id="category-name"
              ref={ref}
              value={name}
              onChange={handleNameChange}
            />
          </FormRow>
          <ModalButtonRow>
            <SecondaryButton onClick={handleHide}>
              {t(MessageKeys.LabelCancel)}
            </SecondaryButton>
            <PrimaryButton
              type="submit"
              loading={loading}
              disabled={!name || loading}
            >
              {t(MessageKeys.LabelSubmit)}
            </PrimaryButton>
          </ModalButtonRow>
        </ModalFormWrapper>
      </ModalBody>
    </Modal>
  );
};
