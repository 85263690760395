import { foundation } from '@fcg-tech/regtech-design-system';
import styled from 'styled-components';

export const FormGridToggleWrapper = styled.div`
  & > input {
    height: 0;
    overflow: hidden;
    border: 0;
    width: 0;
    opacity: 0;
    position: fixed;
  }

  & > .inner-wrapper {
    position: relative;
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    border: 2px solid ${foundation.palette.onyx300.hex};
  }

  & > .inner-wrapper > .lucide {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    stroke-width: 4px;
  }

  & > input:checked + .inner-wrapper {
    background-color: ${foundation.palette.topaz200.hex};
    border-color: ${foundation.palette.topaz200.hex};
  }
`;
