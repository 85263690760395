import React, { FunctionComponent } from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  Field,
  Label,
} from '@fcg-tech/regtech-components';
import { TextField } from '@fcg-tech/regtech-formik-components';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import { FormRow, FormColumn, FormGroup } from '../../components/FormLayout';

interface AgreementTypeDetailsFormSectionProps {
  id?: string;
  name?: string;
}

export const AgreementTypeDetailsFormSection: FunctionComponent<AgreementTypeDetailsFormSectionProps> =
  () => {
    const { t } = useTranslation();

    return (
      <Card>
        <CardBody>
          <CardTitle>{t(MessageKeys.LabelDetails)}</CardTitle>
          <FormRow>
            <FormColumn>
              <FormGroup>
                <Label column htmlFor="agreement-type-name">
                  {t(MessageKeys.LabelName)}
                </Label>
                <Field
                  id="agreement-type-name"
                  name="name"
                  component={TextField}
                  type="text"
                />
              </FormGroup>
            </FormColumn>
          </FormRow>
        </CardBody>
      </Card>
    );
  };
