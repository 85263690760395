import {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useRef,
  useState,
} from 'react';
import { authContext } from '../../authContext';
import { getAccessToken, logout, startLoginFlow } from '../../authFlow';

const signoutPath = '/auth/signout';
const baseCallbackUrl = `${window.location.protocol}//${window.location.host}`;
const authCallbackUrl = `${baseCallbackUrl}/auth/callback`;
const authLogoutCallbackUrl = `${baseCallbackUrl}${signoutPath}`;

interface SingleTenantAuthProviderProps {
  hostedLoginBaseUrl?: string;
  responseType?: string;
  clientId?: string;
  returnUrl?: string | (() => string);
  scope?: string;
}

export const SingleTenantAuthProvider: FunctionComponent<
  PropsWithChildren<SingleTenantAuthProviderProps>
> = ({
  hostedLoginBaseUrl,
  clientId,
  responseType = 'token',
  returnUrl = baseCallbackUrl,
  scope = 'openid+email',
  children,
} = {}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(
    undefined,
  );
  const returnUrlProxy = useRef(returnUrl);
  returnUrlProxy.current = returnUrl;

  const startFlow = useCallback(() => {
    startLoginFlow({
      clientId,
      federated: false,
      hostedLoginBaseUrl,
      responseType,
      scope,
      authCallbackUrl,
      returnUrl:
        typeof returnUrl === 'string'
          ? returnUrl
          : returnUrl?.() ?? baseCallbackUrl,
    });
  }, [clientId, hostedLoginBaseUrl, responseType, scope, returnUrl]);

  const reAuthenticate = useCallback(() => {
    setTimeout(() => {
      startFlow();
    }, 1);
  }, [startFlow]);

  const handleLogout = useCallback(
    (returnPath?: string) =>
      logout({
        hostedLoginBaseUrl,
        callbackUrl: returnPath
          ? `${baseCallbackUrl}/${returnPath.replace(/^\//, '')}`
          : authLogoutCallbackUrl,
        clientId: clientId,
        redirectUri: authLogoutCallbackUrl,
        responseType,
      }),
    [hostedLoginBaseUrl, clientId, responseType],
  );

  const handleSetIsAuthenticated: React.Dispatch<
    React.SetStateAction<boolean>
  > = useCallback((...args) => {
    setTimeout(() => setIsAuthenticated(...args), 0);
  }, []);

  return (
    <authContext.Provider
      value={{
        authToken: getAccessToken(),
        isAuthenticated: Boolean(isAuthenticated),
        setIsAuthenticated: handleSetIsAuthenticated,
        logout: handleLogout,
        reAuthenticate,
      }}
    >
      {children}
    </authContext.Provider>
  );
};
