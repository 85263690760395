import { useIsMountedRef } from '@fcg-tech/regtech-utils';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getScrollParent, randId } from '../../utils';
import { TooltipContext, useTooltipContext } from './tooltipContext';

export interface UseTooltipProps {
  entryTimeoutMS?: number;
  exitTimeoutMS?: number;
  disabled?: boolean;
  priority?: number;
}

export interface UseTooltipResult<ElementType = HTMLElement> {
  ref: React.MutableRefObject<ElementType>;
  dynamicRef: React.MutableRefObject<ElementType>;
  isTooltipVisible: boolean;
  value: string;
  mouseEvents: {
    onMouseEnter: (event: React.MouseEvent<ElementType>) => void;
    onMouseLeave: (event: React.MouseEvent<ElementType>) => void;
  };
}

export const useTooltip = <ElementType = HTMLElement>({
  entryTimeoutMS = 0,
  exitTimeoutMS = 250,
  disabled = false,
  priority = 0,
}: UseTooltipProps = {}): UseTooltipResult<ElementType> => {
  const ref = useRef<ElementType>();
  const dynamicRef = useRef<ElementType>();
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const current = ref.current;
  const scrollParent = useMemo(() => getScrollParent(current), [current]);
  const id = useMemo(() => randId(), []);

  const dataTooltip = useRef<string>();
  const entryTimerRef = useRef(null);
  const exitTimerRef = useRef(null);
  const context = useRef<TooltipContext>();
  context.current = useTooltipContext();
  const isMounted = useIsMountedRef();

  useEffect(() => {
    if (scrollParent) {
      const onScroll = () => setTooltipVisible(false);
      scrollParent.addEventListener('scroll', onScroll);

      return () => scrollParent.removeEventListener('scroll', onScroll);
    }
    return undefined;
  }, [scrollParent]);

  const onMouseEnter = useCallback(
    (event: React.MouseEvent<ElementType>) => {
      if (window.ontouchstart !== undefined) {
        // Touch device
        return;
      }

      dynamicRef.current = event.currentTarget as unknown as ElementType;

      if (!entryTimerRef.current) {
        dataTooltip.current = (
          event.currentTarget as unknown as HTMLElement
        ).getAttribute('data-tooltip');

        entryTimerRef.current = setTimeout(() => {
          entryTimerRef.current = null;
          if (
            context.current?.visibleTooltip?.priority > priority ||
            !isMounted.current
          ) {
            return;
          }
          if (exitTimerRef.current) {
            clearTimeout(exitTimerRef.current);
            exitTimerRef.current = null;
          }
          setTooltipVisible(true);
          context.current?.visibleTooltip?.setVisible?.(false);
          context.current?.setVisibleTooltip?.(id);
        }, entryTimeoutMS);
      }
    },
    [entryTimeoutMS, id, isMounted, priority],
  );

  const onMouseLeave = useCallback(() => {
    if (!isMounted.current) {
      return;
    }
    if (entryTimerRef.current) {
      clearTimeout(entryTimerRef.current);
      entryTimerRef.current = null;
    }
    dataTooltip.current = null;
    exitTimerRef.current = setTimeout(() => {
      setTooltipVisible(false);
      if (context.current?.visibleTooltip?.setVisible === setTooltipVisible) {
        context.current?.setVisibleTooltip?.(null);
      }
    }, exitTimeoutMS);
  }, [exitTimeoutMS, isMounted]);

  useEffect(() => {
    if (disabled) {
      setTooltipVisible(false);
      if (context.current?.visibleTooltip?.setVisible === setTooltipVisible) {
        context.current?.setVisibleTooltip?.(null);
      }
      if (entryTimerRef.current) {
        clearTimeout(entryTimerRef.current);
        entryTimerRef.current = null;
      }
    }
  }, [context, disabled]);

  return {
    ref,
    dynamicRef,
    isTooltipVisible,
    value: dataTooltip.current,
    mouseEvents: {
      onMouseEnter,
      onMouseLeave,
    },
  };
};
