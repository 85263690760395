import React from 'react';
import styled, { css } from 'styled-components';
import { TableCell } from './TableCell';

interface TableRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  inHead?: boolean;
  noBorder?: boolean;
  disabled?: boolean;
  clickable?: boolean;
}

export const TableRow = styled(
  ({
    children,
    inHead,
    noBorder,
    disabled,
    clickable,
    ...props
  }: TableRowProps) => (
    <tr {...props}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { inHead });
        }
        return null;
      })}
    </tr>
  ),
)`
  box-sizing: border-box;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  width: 100%;
  border-bottom: ${({ theme, noBorder }) =>
    noBorder ? 0 : `1px solid ${theme.colors?.border ?? 'rgba(0,0,0,0.3)'}`};
  &:last-child {
    border-bottom: 0;
  }

  & ${TableCell} {
    ${({ disabled }) =>
      disabled
        ? css`
            position: relative;
            :after {
              content: ' ';
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              background-color: inherit;
              z-index: 2;
            }
          `
        : ''}
  }
`;
