import React, { FunctionComponent } from 'react';
import { useRadioState } from 'reakit/Radio';
import {
  EnumDecisionWrapper,
  ControlContainer,
  Label,
  LabelContainer,
  EnumDecisionFieldContainer,
  EnumDecisionFieldLabel,
  Radio,
  RadioGroup,
  ReadOnlyLabel,
  StyledInfoCircle,
} from './EnumDecision.styles';

export interface EnumDecisionProps {
  items?: Array<{
    label: string;
    value: string | number | boolean;
  }>;
  contractInfo?: boolean;
  info?: string;
  isEditEnabled?: boolean;
  value: string | number | boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface EnumDecisionFn extends FunctionComponent<EnumDecisionProps> {
  /**
   * @deprecated Use the exported version instead
   */
  FieldContainer: typeof EnumDecisionFieldContainer;
  /**
   * @deprecated Use the exported version instead
   */
  FieldLabel: typeof EnumDecisionFieldLabel;
}

export const EnumDecision: EnumDecisionFn = ({
  info,
  contractInfo,
  isEditEnabled = true,
  items = [],
  value,
  onChange,
}) => {
  const radio = useRadioState({
    state: value?.toString() || '',
  });

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event);
    },
    [onChange],
  );

  const labels = React.useMemo(() => {
    const l: Record<string, string> = {};
    items.forEach(({ value, label }) => {
      l[value.toString()] = label;
    });
    return l;
  }, [items]);

  return (
    <EnumDecisionWrapper contractInfo={contractInfo}>
      {isEditEnabled ? (
        <ControlContainer>
          <RadioGroup {...radio} aria-label="enum decision">
            <LabelContainer>
              {items.map((item) => (
                <Label key={`enum-decision-item-${item.label}`}>
                  <Radio
                    {...radio}
                    value={item?.value?.toString() ?? ''}
                    checked={value === item?.value}
                    onChange={handleChange}
                  />
                  {item.label}
                </Label>
              ))}
            </LabelContainer>
          </RadioGroup>
        </ControlContainer>
      ) : (
        <ReadOnlyLabel>{labels?.[value?.toString()] ?? 'Not set'}</ReadOnlyLabel>
      )}
      {info && <StyledInfoCircle info={info} />}
    </EnumDecisionWrapper>
  );
};

EnumDecision.FieldContainer = EnumDecisionFieldContainer;
EnumDecision.FieldLabel = EnumDecisionFieldLabel;
