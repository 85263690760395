import { useTranslation } from 'react-i18next';
import {
  ConfirmDialog,
  FormRow,
  Modal,
  ModalBody,
  ModalButtonRow,
  ModalHeader,
  ModalTitle,
  PrimaryButton,
  ReactSelectOption,
  SecondaryButton,
  TextField,
  useConfirmDialog,
  useFlash,
} from '@fcg-tech/regtech-components';
import { Translator } from '@fcg-tech/regtech-types';
import deepmerge from 'deepmerge';
import { useFormik } from 'formik';
import update from 'immutability-helper';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { ThemeProvider, useTheme } from 'styled-components';
import * as yup from 'yup';
import { UnprocessableEntityError } from '../../api/apiErrors';
import { useAgreementActions } from '../../api/hooks/agreementsApiHooks';
import { useAgreementAttachmentActions } from '../../api/hooks/agreementAttachmentsApiHooks';
import { useCabinets } from '../../api/hooks/cabinetsApiHooks';
import { useTags } from '../../api/hooks/tagsApiHooks';
import { Cabinet, FileAttachmentSection, Tag } from '../../api/schema';
import { FileUploadZone } from '../../components/FileUploadZone';
import { FormSelect } from '../../components/FormSelect';
import { ModalFormLabel } from '../../components/modals';
import Features from '../../Features';
import { useTenant } from '../../states/tenantState';
import { MessageKeys } from '../../translations/messageKeys';
import { getTooltip } from '../../utils/tooltipHelpers';
import { CreateAgreementFormikValues } from '../../types';
import {
  AgreementOverviewCreateModalFileUploadZoneWrapper,
  AgreementOverviewCreateModalFormWrapper,
  compactTheme,
} from './AgreementOverviewCreateModal.styles';

interface AgreementsOverviewCreateModalProps {
  onRequestClose: (agreementId?: string) => void;
}

export const AgreementsOverviewCreateModal: FunctionComponent<
  AgreementsOverviewCreateModalProps
> = ({ onRequestClose }) => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const addFlash = useFlash();
  const [saving, setSaving] = useState(false);
  const [files, setFiles] = useState<Array<File>>([]);
  const { createAgreement } = useAgreementActions(tenant.id);
  const { uploadAttachment } = useAgreementAttachmentActions(tenant.id);

  const defaultTheme = useTheme();
  const theme = useMemo(
    () => deepmerge(defaultTheme, compactTheme),
    [defaultTheme],
  );

  const translateTooltip = useCallback(
    (value: string) => getTooltip(`createAgreement.${value}`),
    [],
  );

  const [showForceSubmitConfirm, handleForceSubmitConfirm, confirmForceSubmit] =
    useConfirmDialog();

  const { data: cabinets = [], isValidating: isValidatingCabinets } =
    useCabinets(tenant.id, { suspense: false });

  const { data: tags = [], isValidating: isValidatingTags } = useTags(
    tenant.id,
    { suspense: false },
  );

  const cabinetOptions = useMemo<Array<ReactSelectOption<Cabinet>>>(
    () =>
      cabinets?.map<ReactSelectOption<Cabinet>>((cabinet) => ({
        label: cabinet.name,
        value: cabinet,
      })),
    [cabinets],
  );

  const tagOptions = useMemo<Array<ReactSelectOption<Tag>>>(
    () =>
      tags
        ?.map<ReactSelectOption<Tag>>((tag) => ({
          label: tag.name,
          value: tag,
        }))
        ?.sort((a, b) => a.label.localeCompare(b.label)),
    [tags],
  );

  const validationSchema = useMemo(() => getValidationSchema(t), [t]);

  const formik = useFormik<CreateAgreementFormikValues>({
    initialValues: {
      name: '',
      cabinet: '',
      tags: [],
    },
    validationSchema,
    onSubmit: async (values) => {
      const selectedCabinet = cabinets.find((o) => o.name === values.cabinet);
      const selectedTags = tags.filter((o) => values.tags?.includes(o.name));

      const saveAgreement = async (force?: boolean) => {
        try {
          setSaving(true);
          const agreementId = await createAgreement(
            values.name,
            selectedCabinet,
            selectedTags,
            force,
          );
          if (files?.length > 0) {
            await uploadAttachment(
              agreementId,
              1,
              files,
              FileAttachmentSection.Details,
            );
          }
          addFlash({
            level: 'success',
            content: t(MessageKeys.AgreementsOverviewCreateAgreementSuccess),
          });
          onRequestClose(agreementId);
        } catch (err) {
          setSaving(false);
          // 422 response, will provider the option to force save the agreement
          if (err instanceof UnprocessableEntityError) {
            if (await confirmForceSubmit()) {
              await saveAgreement(true);
            }
          } else {
            addFlash({
              level: 'error',
              content: t(MessageKeys.AgreementsOverviewCreateAgreementFailure),
            });
          }
        }
      };

      await saveAgreement(false);
    },
  });

  const handleFileDrop = useCallback(async (acceptedFiles: Array<File>) => {
    setFiles((old) => {
      return update(old, { $push: acceptedFiles });
    });
    return true;
  }, []);

  const handleRemoveFile = useCallback(async (file: File) => {
    setFiles((old) => {
      const index = old.indexOf(file);
      return update(old, { $splice: [[index, 1]] });
    });
    return true;
  }, []);

  const handleCabinetChange = useCallback(
    (option: ReactSelectOption<Cabinet>) =>
      formik.setFieldValue('cabinet', option.value.name),
    [formik],
  );

  const handleTagsChange = useCallback(
    (option: Array<ReactSelectOption<Tag>>) => {
      const newValue = option.map((o) => o.value.name);
      formik.setFieldValue('tags', newValue);
    },
    [formik],
  );

  const handleHide = useCallback(() => onRequestClose(), [onRequestClose]);

  const selectedCabinetOption = useMemo<ReactSelectOption<Cabinet>>(
    () => cabinetOptions.find((o) => o.value.name === formik.values.cabinet),
    [cabinetOptions, formik.values.cabinet],
  );

  const selectedTagOptions = useMemo<Array<ReactSelectOption<Tag>>>(
    () => tagOptions.filter((o) => formik.values.tags?.includes(o.value.name)),
    [formik.values.tags, tagOptions],
  );

  return (
    <>
      <Modal onHide={handleHide}>
        <ModalHeader>
          <ModalTitle>
            {t(MessageKeys.AgreementsOverviewCreateNewAgreementLabel)}
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <AgreementOverviewCreateModalFormWrapper
            onSubmit={formik.handleSubmit}
          >
            <ThemeProvider theme={theme}>
              <FormRow>
                <ModalFormLabel htmlFor="name">
                  {t(MessageKeys.AgreementsOverviewCreateAgreementName)}
                </ModalFormLabel>
                <TextField
                  name="name"
                  type="text"
                  value={formik.values.name}
                  error={Boolean(formik.errors.name)}
                  errorMessage={formik.errors.name}
                  onChange={formik.handleChange}
                  info={translateTooltip('name')}
                />
              </FormRow>
              <FormRow>
                <ModalFormLabel htmlFor="cabinet">
                  {t(MessageKeys.AgreementsOverviewCreateAgreementCabinet)}
                </ModalFormLabel>
                <FormSelect
                  id="cabinet"
                  name="cabinet"
                  options={cabinetOptions}
                  value={selectedCabinetOption}
                  isLoading={!cabinets && isValidatingCabinets}
                  info={translateTooltip('cabinet')}
                  error={Boolean(formik.errors.cabinet)}
                  errorMessage={formik.errors.cabinet}
                  onChange={handleCabinetChange}
                />
              </FormRow>
              <FormRow>
                <ModalFormLabel htmlFor="tags">
                  {t(MessageKeys.AgreementsOverviewCreateAgreementTags)}
                </ModalFormLabel>
                <FormSelect
                  id="tags"
                  name="tags"
                  options={tagOptions}
                  value={selectedTagOptions}
                  isLoading={!tags && isValidatingTags}
                  closeMenuOnSelect={false}
                  info={translateTooltip('tags')}
                  isMulti
                  onChange={handleTagsChange}
                />
              </FormRow>
              {Features.ShowFileUploadAgreementAtStart ? (
                <FormRow>
                  <ModalFormLabel>Agreement file(s)</ModalFormLabel>
                  <AgreementOverviewCreateModalFileUploadZoneWrapper>
                    <FileUploadZone
                      files={files}
                      allowMultiple
                      onFileDrop={handleFileDrop}
                      onRemoveFile={handleRemoveFile}
                    />
                  </AgreementOverviewCreateModalFileUploadZoneWrapper>
                </FormRow>
              ) : null}
            </ThemeProvider>
            <ModalButtonRow>
              <SecondaryButton type="button" onClick={handleHide}>
                {t(MessageKeys.LabelCancel)}
              </SecondaryButton>
              <PrimaryButton
                type="submit"
                loading={saving}
                disabled={!formik.values.name || !formik.values.cabinet}
              >
                {t(MessageKeys.LabelSubmit)}
              </PrimaryButton>
            </ModalButtonRow>
          </AgreementOverviewCreateModalFormWrapper>
        </ModalBody>
      </Modal>
      {showForceSubmitConfirm ? (
        <ConfirmDialog
          title={t(MessageKeys.LabelWarning)}
          body={t(
            MessageKeys.AgreementsOverviewCreateAgreementConfirmForceQuestion,
          )}
          confirmText={t(MessageKeys.LabelProceed)}
          cancelText={t(MessageKeys.LabelCancel)}
          onChoice={handleForceSubmitConfirm}
        />
      ) : null}
    </>
  );
};

const getValidationSchema = (t: Translator) =>
  yup.object().shape({
    name: yup
      .string()
      .required(t(MessageKeys.AgreementsOverviewCreateAgreementNameRequired)),
    cabinet: yup
      .string()
      .required(
        t(MessageKeys.AgreementsOverviewCreateAgreementCabinetRequired),
      ),
  });
