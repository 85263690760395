import { SpinningLoadingIcon } from '@fcg-tech/regtech-components';
import {
  DataTableColumn,
  DataTableProps,
  Row,
  RowRenderer,
  SortingRule,
} from '@fcg-tech/regtech-datatable';
import { FunctionComponent, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import { AgreementListItem } from '../../types';
import { AgreementTableRow } from './AgreementTableRow';
import {
  AgreementTableDataTable,
  AgreementTableLoadingWrapper,
  AgreementTableNoContent,
  AgreementTableWrapper,
} from './AgreementTable.styles';

interface AgreementTableProps extends Pick<DataTableProps, 'onColumnsChange'> {
  agreements?: Array<AgreementListItem>;
  columns?: Array<DataTableColumn<AgreementListItem>>;
  loading?: boolean;
  sortBy?: SortingRule<AgreementListItem>;
  onRowClick?: (agreementId: string) => void;
  onSortByChange?: (sortBy: SortingRule<AgreementListItem>) => void;
}

const getRowId = (originalRow: AgreementListItem) => originalRow.metadata.id;

export const AgreementTable: FunctionComponent<AgreementTableProps> = ({
  agreements,
  columns,
  loading,
  sortBy,
  onRowClick,
  onSortByChange,
  onColumnsChange,
}) => {
  const { t } = useTranslation();
  const scrollRef = useRef<HTMLDivElement>();
  const wrapperRef = useRef<HTMLDivElement>();

  const handleRowClick = useCallback(
    (row: Row<AgreementListItem>) => {
      onRowClick?.(row.id);
    },
    [onRowClick],
  );

  const WrappedRow = useMemo<RowRenderer<AgreementListItem>>(() => {
    return (props) => <AgreementTableRow {...props} />;
  }, []);

  return (
    <AgreementTableWrapper ref={wrapperRef}>
      <AgreementTableDataTable
        columns={columns}
        data={agreements}
        Row={WrappedRow}
        fullHeight
        getRowId={getRowId}
        scrollRef={scrollRef}
        sortBy={sortBy}
        onRowClick={handleRowClick}
        onSortByChange={onSortByChange}
        onColumnsChange={onColumnsChange}
      />
      {agreements?.length == 0 ? (
        <AgreementTableNoContent>
          {t(MessageKeys.AgreementTableNoItems)}
        </AgreementTableNoContent>
      ) : null}
      {loading ? (
        <AgreementTableLoadingWrapper>
          <SpinningLoadingIcon size="80px" />
        </AgreementTableLoadingWrapper>
      ) : null}
    </AgreementTableWrapper>
  );
};
