/* eslint-disable @typescript-eslint/triple-slash-reference */
/* eslint-disable @typescript-eslint/ban-types */
/// <reference path="../@types/react-table/index.d.ts" />
/// <reference path="../@types/react/index.d.ts" />

import {
  closestCenter,
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  horizontalListSortingStrategy,
  SortableContext,
} from '@dnd-kit/sortable';
import { classNames } from '@fcg-tech/regtech-utils';
import { useCallback, useMemo, useState } from 'react';
import { HeaderGroup } from 'react-table';
import { DataTableHeaderCell } from './DataTableHeaderCell';

interface DataTableHeaderProps<D extends object = {}> {
  headerGroups: Array<HeaderGroup<D>>;
  headerRowRef: React.RefObject<HTMLDivElement>;
  isAnyColumnResizing: boolean;
  onColumnOrderChange?: (id: number | string, newIndex: number) => void;
}

export const DataTableHeader = <D extends object = {}>({
  headerGroups,
  headerRowRef,
  isAnyColumnResizing,
  onColumnOrderChange,
}: DataTableHeaderProps<D>) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { delay: 750, tolerance: 5 },
    }),
  );

  const items = useMemo<Array<Array<string>>>(() => {
    return headerGroups
      .map((headerGroup) => {
        return headerGroup.headers?.map((column) => column.id.toString());
      })
      .filter(Boolean) as Array<Array<string>>;
  }, [headerGroups]);

  const [isDragging, setIsDragging] = useState(false);

  const handleDragStart = useCallback(() => setIsDragging(true), []);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      setIsDragging(false);

      if (active && over && active?.id !== over?.id) {
        let index = -1;
        for (let i = 0; i < items.length; i++) {
          if (items[i].includes(over.id)) {
            index = items[i].indexOf(over.id);
          }
        }
        if (index >= 0 && onColumnOrderChange) {
          onColumnOrderChange(active.id, index);
        }
      }
    },
    [items, onColumnOrderChange],
  );

  return (
    <div
      className={classNames('header', isDragging && 'dragging')}
      ref={headerRowRef}
    >
      {headerGroups.map((headerGroup, i) => {
        const headerGroupProps = headerGroup.getHeaderGroupProps();
        const row = (
          <div
            {...headerGroupProps}
            className={classNames('tr', isAnyColumnResizing && 'resizing')}
          >
            {headerGroup.headers.map((column, i) => (
              <DataTableHeaderCell
                column={column}
                reorderable={Boolean(onColumnOrderChange)}
                key={column.getHeaderProps().key}
              />
            ))}
          </div>
        );
        return onColumnOrderChange ? (
          <DndContext
            key={headerGroupProps.key}
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={items[i] ?? []}
              strategy={horizontalListSortingStrategy}
            >
              {row}
            </SortableContext>
          </DndContext>
        ) : (
          row
        );
      })}
    </div>
  );
};
