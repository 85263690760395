import styled from 'styled-components';
import { Table } from '@fcg-tech/regtech-components';

export const SubcontractorsFieldTableWrapper = styled(Table)`
  table-layout: fixed;
`;

export const SubcontractorsFieldTableEmptyMessage = styled.div`
  flex: 1;
  text-align: center;
`;
