import styled from 'styled-components';
import { Badge, IconButton } from '@fcg-tech/regtech-components';
import { Link } from 'react-router-dom';

export const BellIconButtonWrapper = styled.div`
  position: relative;
`;

export const BellIconButton = styled(IconButton)`
  position: relative;
  width: 3.6rem;
  height: 3.6rem;
  margin-left: 1.2rem;
`;

export const BellIconButtonNavLink = styled(Link)`
  position: relative;
  width: 3.6rem;
  height: 3.6rem;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BellIconButtonBadge = styled(Badge)`
  position: absolute;
  top: -0.3rem;
  left: 1.8rem;
`;
