import { useTranslation } from 'react-i18next';
import {
  DataTableCell,
  DataTableRow,
  DataTableRowOptions,
} from '@fcg-tech/regtech-components';
import { FunctionComponent, useCallback } from 'react';
import { GlobalSearchResultType } from '../../api/schema';
import { GLOBAL_SEARCH_RESULT_TYPE_MESSAGE_KEYS } from '../../constants';
import { GlobalSearchResultTypeTag } from './GlobalSearchPage.styles';

export type RowData = {
  type: GlobalSearchResultType;
  name: string;
  content: string;
  rank: number;
};

export type ColumnKeys = 'type' | 'name' | 'content';

interface GlobalSearchTableRowProps {
  row: DataTableRowOptions<RowData>;
  onClick?: (rowId: string) => void;
}

export const GlobalSearchTableRow: FunctionComponent<
  GlobalSearchTableRowProps
> = ({ row, onClick }) => {
  const { t } = useTranslation();
  const data = row.data;

  const handleClick = useCallback(() => {
    onClick?.(row.id);
  }, [onClick, row]);

  return (
    <DataTableRow row={row} onClick={handleClick}>
      <DataTableCell>
        <GlobalSearchResultTypeTag>
          {t(GLOBAL_SEARCH_RESULT_TYPE_MESSAGE_KEYS[data.type])}
        </GlobalSearchResultTypeTag>
      </DataTableCell>
      <DataTableCell dangerouslySetInnerHTML={{ __html: data.name }} />
      <DataTableCell dangerouslySetInnerHTML={{ __html: data.content }} />
    </DataTableRow>
  );
};
