import styled from 'styled-components';

export const TagsTableHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TagsTableHeaderQuestionButton = styled.div`
  background: white;
  display: flex;
  align-items: center;
  margin-left: 1rem;
`;
