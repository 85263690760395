import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';

import './theme';
import './i18n';
import { App } from './containers/App';

// react-async not yet supported in StrictMode with react-18
// all routes using SWR will be wrapped will be wrapped in StrictMode for now inside AppRoutes
// see: AppRoutes.tsx
// Should re-enable for entire app as soon as all pages are migrated to use SWR

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  // <StrictMode>
  <App />,
  // </StrictMode>,
);
