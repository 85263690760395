/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetCalendarEventsResponse,
} from '../models';
import {
    GetCalendarEventsResponseFromJSON,
    GetCalendarEventsResponseToJSON,
} from '../models';

/**
 * CalendarApi - interface
 * 
 * @export
 * @interface CalendarApiInterface
 */
export interface CalendarApiInterface {
    /**
     * 
     * @summary Returns events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApiInterface
     */
    getCalendarEventsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCalendarEventsResponse>>;

    /**
     * Returns events
     */
    getCalendarEvents(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCalendarEventsResponse>;

}

/**
 * 
 */
export class CalendarApi extends runtime.BaseAPI implements CalendarApiInterface {

    /**
     * Returns events
     */
    async getCalendarEventsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCalendarEventsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/calendar/events`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCalendarEventsResponseFromJSON(jsonValue));
    }

    /**
     * Returns events
     */
    async getCalendarEvents(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCalendarEventsResponse> {
        const response = await this.getCalendarEventsRaw(initOverrides);
        return await response.value();
    }

}
