import styled, { css } from 'styled-components';

export const HeaderWrapper = styled.header`
  background-color: ${({ theme }) => theme.colors?.backdrop};
  display: flex;
  z-index: 1;
  display: flex;
  align-items: center;
  z-index: 5;
  border-bottom: 1px solid #ccc;
  background-color: white;

  @media print {
    display: none;
  }

  ${({ theme }) => theme.elements?.header?.wrapper ?? ''}
`;

export const HeaderLeftContent = styled.nav`
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
`;

export const HeaderRightContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: 1.5rem;

    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      display: none;
    }
  `}
`;

export const HeaderActionButtonWrapper = styled.div`
  margin-right: 2.2rem;
`;

export const SearchTextFieldWrapper = styled.div`
  min-width: 40rem;
`;
