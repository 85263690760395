import { useTranslation } from 'react-i18next';
import { ReactSelectOption } from '@fcg-tech/regtech-components';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import Select, { SingleValue, StylesConfig } from 'react-select';
import {
  FilterMessageKeys,
  RelativeDateIntervalPrefix,
} from './lang/filterMessageKeys';
import { FilterSelectOption, RelativeDateInterval } from './types';

const filterMessages = Object.entries(FilterMessageKeys);

const INTERVAL_CUSTOM = 'INTERVAL_CUSTOM';

type OptionType = ReactSelectOption<
  RelativeDateInterval | typeof INTERVAL_CUSTOM
>;

export interface FilterRelativeDateIntervalSelectProps {
  disabled?: boolean;
  interval?: RelativeDateInterval;
  intervals: Array<RelativeDateInterval>;
  selectStyles?: Partial<StylesConfig<OptionType, false>>;
  onChange: (interval: RelativeDateInterval | null) => void;
}

export const FilterRelativeDateIntervalSelect: FunctionComponent<FilterRelativeDateIntervalSelectProps> =
  ({ disabled, interval = null, intervals, selectStyles, onChange }) => {
    const { t } = useTranslation();
    const options = useMemo<Array<OptionType>>(() => {
      const opts = intervals.map<OptionType>((i) => ({
        label: t(
          filterMessages.find(
            ([key, val]) =>
              key === `${RelativeDateIntervalPrefix}${i.toString()}`,
          )?.[1] ?? '',
        ),
        value: i,
      }));

      opts.push({
        label: t(FilterMessageKeys.RelativeDateIntervalCustom),
        value: INTERVAL_CUSTOM,
      });

      return opts;
    }, [intervals, t]);

    const selectedOption = useMemo(() => {
      return options.find((o) => o.value === interval);
    }, [interval, options]);

    const handleChange = useCallback(
      (option: SingleValue<OptionType> | null) => {
        onChange?.(
          option?.value === INTERVAL_CUSTOM ? null : option?.value ?? null,
        );
      },
      [onChange],
    );

    return (
      <Select
        isDisabled={disabled}
        value={selectedOption ?? null}
        options={options}
        isMulti={false}
        styles={selectStyles}
        onChange={handleChange}
      />
    );
  };
