import styled from 'styled-components';

export const ErrorBoundaryMessage = styled.div`
  font-size: 1.6rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1;
  min-width: 0;
`;
