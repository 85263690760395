import { useTranslation } from 'react-i18next';
import React, { FunctionComponent } from 'react';
import { MessageKeys } from '../../translations/messageKeys';
import {
  ErrorStatusHeading,
  ErrorInfo,
  ErrorPageWrapper,
} from './ErrorMessage.styles';

interface Error404Props {
  response?: Response;
}

export const Error404: FunctionComponent<Error404Props> = () => {
  const { t } = useTranslation();
  return (
    <ErrorPageWrapper>
      <ErrorStatusHeading>
        {t(MessageKeys.ErrorNotFoundHeading)}
      </ErrorStatusHeading>
      <ErrorInfo>{t(MessageKeys.ErrorNotFoundInfo)}</ErrorInfo>
    </ErrorPageWrapper>
  );
};
