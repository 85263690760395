import styled from 'styled-components';

export const TableWrapper = styled.div`
  display: block;
  height: 100%;
  max-height: 100%;

  .table {
    display: flex;
    flex-direction: column;
    border-spacing: 0;
    border: 0;
    height: 100%;
    max-height: 100%;
    background-color: white;

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }

      &.expandable {
        cursor: pointer;
      }
    }

    .tr-hover-container {
      width: 0;
      height: 0;
    }

    .tr-expanded {
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    .rowgroup {
      overflow: auto;
      flex: 1;
      min-height: 0;

      .tr {
        will-change: transform;
      }
    }

    .header {
      &.dragging {
        cursor: ew-resize !important;

        & * {
          cursor: ew-resize !important;
        }
      }

      &:hover {
        .resizer {
          :after {
            opacity: 1;
          }
        }

        .resizing > .th {
          user-select: none;
        }
      }
    }

    .th,
    .td {
      margin: 0;
      padding: 0.5rem;

      position: relative;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);

      .resizer {
        display: inline-block;
        background: transparent;
        width: 10px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        touch-action: none;
        cursor: col-resize;

        :after {
          content: '';
          opacity: 0;
          will-change: opacity;
          transition: opacity 0.2s ease-in-out;
          position: absolute;
          left: 50%;
          top: 0;
          bottom: 0;
          border-left: 1px dashed rgba(0, 0, 0, 0.5);
        }
      }
    }

    .th {
      &.dragging {
        z-index: 10;
        background-color: white;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

        & > .resizer {
          display: none;
        }
      }

      &.sorted {
        font-weight: 600;
      }

      .th-content {
        width: 100%;
        display: flex;
        align-items: center;
      }

      .sorting-arrow {
        margin-left: 1rem;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
`;
