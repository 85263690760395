/* eslint-disable @typescript-eslint/no-explicit-any */
export const policyPrinter = (policy: any) => {
  const arrayReplace = JSON.stringify(policy, null, 2)
    .replace(/],/g, '')
    .replace(/]|[[]/g, '');
  const specialCharacterReplace = arrayReplace.replace(/["$]/g, '');
  const newlineTmp = specialCharacterReplace.replace(/},/g, '@&');
  const curlyBracesReplace = newlineTmp.replace(/[{}]/g, '');
  const result = curlyBracesReplace.replace(/(^[ \t]*\n)|@&/gm, '');
  return result;
};
