import React, { useMemo, useCallback } from 'react';
import { FormikHelpers, FieldInputProps } from 'formik';
import {
  NumberField,
  Select,
  itemKeyGetter,
  itemLabelGetter,
} from '@fcg-tech/regtech-components';
import { NoticePeriod } from '@fcg-tech/regtech-types/oasys';
import {
  NoticePeriodFieldWrapper,
  NoticePeriodFieldInner,
  NoticePeriodFieldReadOnlyWrapper,
  NoticePeriodFieldReadOnlyValue,
  NoticePeriodFieldReadOnlyInfoCircle,
} from './NoticePeriodField.styles';

const UNIT_OPTIONS = [
  { key: 'businessDays', label: 'Business days' },
  { key: 'calendarDays', label: 'Calendar days' },
  { key: 'months', label: 'Months' },
];

interface NoticePeriodFieldProps {
  form: FormikHelpers<NoticePeriod>;
  field: FieldInputProps<NoticePeriod>;
  isEditEnabled?: boolean;
  info: string;
  id: string;
}

export const NoticePeriodField = ({
  form,
  field,
  isEditEnabled = true,
  info,
  id,
}: NoticePeriodFieldProps) => {
  const selectedUnitLabel = useMemo(() => {
    const selected = UNIT_OPTIONS.find(
      (unit) => itemKeyGetter(unit) === field.value?.unit,
    );
    return selected ? itemLabelGetter(selected) : '';
  }, [field.value]);

  const handleValueChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: number) => {
      if (event.currentTarget.value === '') {
        form.setFieldValue(`${field.name}.value`, undefined);
      } else {
        form.setFieldValue(`${field.name}.value`, value);
      }
    },
    [form, field.name],
  );

  const handleUnitChange = useCallback(
    (value) => form.setFieldValue(`${field.name}.unit`, value),
    [form, field.name],
  );

  return (
    <NoticePeriodFieldWrapper>
      {isEditEnabled ? (
        <>
          <NoticePeriodFieldInner>
            <NumberField
              id={id}
              value={field.value?.value}
              onChange={handleValueChange}
            />
          </NoticePeriodFieldInner>
          <Select
            items={UNIT_OPTIONS}
            value={field.value?.unit}
            onChange={handleUnitChange}
            info={info}
          />
        </>
      ) : (
        <NoticePeriodFieldReadOnlyWrapper>
          <NoticePeriodFieldReadOnlyValue>
            {field.value?.value}
          </NoticePeriodFieldReadOnlyValue>
          <NoticePeriodFieldReadOnlyValue>
            {selectedUnitLabel}
          </NoticePeriodFieldReadOnlyValue>
          <NoticePeriodFieldReadOnlyInfoCircle info={info} />
        </NoticePeriodFieldReadOnlyWrapper>
      )}
    </NoticePeriodFieldWrapper>
  );
};
