/* eslint-disable @typescript-eslint/triple-slash-reference */
/* eslint-disable @typescript-eslint/ban-types */
/// <reference path="../@types/react-table/index.d.ts" />
/// <reference path="../@types/react/index.d.ts" />

import { debounce } from '@fcg-tech/regtech-utils';
import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import {
  TableOptions,
  useFlexLayout,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table';
import { TableWrapper } from './DataTable.styles';
import { DataTableBody } from './DataTableBody';
import { DataTableHeader } from './DataTableHeader';
import {
  compareSortingRules,
  getColumnSettingsHash,
  reorderColumns,
} from './dataTableUtils';
import {
  CommonRowProps,
  DataTableColumn,
  RowRenderer,
  SortingRule,
} from './types';

const IGNORE_SCROLL_EVENT_THRESHOLD = 50;

export interface DataTableProps<D extends object = {}>
  extends CommonRowProps<D>,
    Pick<TableOptions<D>, 'getRowId'> {
  columns: Array<DataTableColumn<D>>;
  data?: Array<D>;
  className?: string;
  fullHeight?: boolean;
  height?: string;
  sortBy?: SortingRule<D>;
  scrollRef?: React.MutableRefObject<HTMLDivElement>;
  reorderableColumns?: boolean;
  bodyScroll?: {
    top?: number;
    left?: number;
  };
  Row?: RowRenderer<D>;
  onSortByChange?: (sortOrder: SortingRule<D>) => void;
  onColumnsChange?: (columns: Array<DataTableColumn<D>>) => void;
  onBodyScroll?: (scrollTop: number, scrollLeft: number) => void;
}

const DataTableInner = <D extends object = {}>(
  {
    columns,
    data = [],
    fullHeight,
    sortBy,
    renderExpanded,
    scrollRef,
    expanded,
    reorderableColumns,
    Row,
    height,
    bodyScroll,
    onRowClick,
    onSortByChange,
    onColumnsChange,
    onBodyScroll,
    className,
    ...tableOptions
  }: DataTableProps<D>,
  ref: React.ForwardedRef<HTMLDivElement>,
): JSX.Element => {
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      width: 150,
      maxWidth: 400,
    }),
    [],
  );

  const tableRef = useRef<HTMLDivElement>(null);
  const headerRowRef = useRef<HTMLDivElement>(null);
  const columnHashRef = useRef<string>(getColumnSettingsHash(columns));
  const horizonalScroll = useRef(0);
  const ignoreNextScrollEvent = useRef<number>(0);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setSortBy,
    columns: updatedColumns,
  } = useTable<D>(
    {
      columns,
      data,
      defaultColumn,
      manualSortBy: true,
      initialState: {
        sortBy: sortBy ? [sortBy] : [],
      },
      ...tableOptions,
    },
    useFlexLayout,
    useResizeColumns,
    useSortBy,
  );

  const isAnyColumnResizing = headerGroups.some(
    (headerGroup) =>
      headerGroup.isResizing || headerGroup.headers.some((h) => h.isResizing),
  );

  const sortByRef = useRef<SortingRule<D> | undefined>(sortBy);
  const stateSortByRef = useRef<SortingRule<D> | undefined>(sortBy);

  const updatedColumnsHash = getColumnSettingsHash(updatedColumns);

  useEffect(() => {
    if (
      onColumnsChange &&
      !isAnyColumnResizing &&
      updatedColumnsHash !== columnHashRef.current
    ) {
      onColumnsChange(updatedColumns);
      columnHashRef.current = updatedColumnsHash;
    }
  }, [
    onColumnsChange,
    updatedColumns,
    isAnyColumnResizing,
    updatedColumnsHash,
  ]);

  useEffect(() => {
    if (scrollRef?.current) {
      scrollRef.current.scrollLeft = horizonalScroll.current;
      ignoreNextScrollEvent.current = new Date().getTime();
    }
    // TODO: useEvent possibility
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  useEffect(() => {
    if (
      onSortByChange &&
      !compareSortingRules(stateSortByRef.current, state.sortBy[0])
    ) {
      stateSortByRef.current = state.sortBy[0];
      onSortByChange(state.sortBy[0]);
    } else if (!compareSortingRules(sortByRef.current, sortBy)) {
      sortByRef.current = sortBy;
      if (!compareSortingRules(state.sortBy[0], sortBy)) {
        setSortBy(sortBy ? [sortBy] : []);
      }
    }
  }, [onSortByChange, setSortBy, sortBy, state.sortBy]);

  const handleBodyScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement>) => {
      if (
        ignoreNextScrollEvent.current &&
        new Date().getTime() - ignoreNextScrollEvent.current <
          IGNORE_SCROLL_EVENT_THRESHOLD
      ) {
        ignoreNextScrollEvent.current = 0;
        return;
      }
      const scrollLeft = event.currentTarget.scrollLeft;
      if (headerRowRef.current) {
        headerRowRef.current.style.transform = `translateX(${-scrollLeft}px)`;
      }
      onBodyScroll?.(event.currentTarget.scrollTop, scrollLeft);
      horizonalScroll.current = scrollLeft;
    },
    [onBodyScroll],
  );

  const resize = useCallback(() => {
    if (tableRef.current) {
      tableRef.current.style.height =
        height ??
        (fullHeight && tableRef.current.parentElement?.parentElement
          ? `${tableRef.current.parentElement.parentElement.clientHeight}px`
          : 'auto');
    }
  }, [fullHeight, height]);

  useLayoutEffect(() => {
    resize();
  }, [fullHeight, resize]);

  useLayoutEffect(() => {
    if (bodyScroll?.top && scrollRef?.current) {
      scrollRef.current.scrollTop = bodyScroll.top;
    }
    if (bodyScroll?.left && scrollRef?.current) {
      scrollRef.current.scrollLeft = bodyScroll.left;
    }
    // TODO: useEvent possibility
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onResize = debounce(() => {
      resize();
    }, 500);
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [resize]);

  const handleColumnOrderChange = useCallback(
    (id: string | number, newIndex: number) => {
      const newColumns = reorderColumns(columns, id, newIndex);
      onColumnsChange?.(newColumns);
    },
    [columns, onColumnsChange],
  );

  const { style, ...tableProps } = getTableProps();

  return (
    <TableWrapper className={className} ref={ref}>
      <div
        {...tableProps}
        style={{ ...style, minWidth: '100%' }}
        className="table"
        ref={tableRef}
      >
        <DataTableHeader
          headerGroups={headerGroups}
          isAnyColumnResizing={isAnyColumnResizing}
          headerRowRef={headerRowRef}
          onColumnOrderChange={handleColumnOrderChange}
        />
        <DataTableBody
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
          rows={rows}
          renderExpanded={renderExpanded}
          Row={Row}
          scrollRef={scrollRef}
          isAnyColumnResizing={isAnyColumnResizing}
          expanded={expanded}
          onRowClick={onRowClick}
          onScroll={handleBodyScroll}
        />
      </div>
    </TableWrapper>
  );
};

export const DataTable = React.forwardRef(DataTableInner);
