import { AuthError } from '@fcg-tech/regtech-api-utils';
import { EmptyPropsWithChildren } from '@fcg-tech/regtech-types';
import React, { Component } from 'react';
import { getLogger } from '../../api/logger';
import { ErrorMessage } from './ErrorMessage';

export class ErrorBoundary extends Component<EmptyPropsWithChildren> {
  override state = {
    hasError: false,
    error: undefined,
  };

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: Boolean(error), error };
  }

  override componentDidCatch(error: Error) {
    getLogger().error('A UI error occured', {
      path: window.location.toString(),
      userAgent: window.navigator.userAgent,
      name: error.name,
      message: error.message,
      stack: error.stack,
    });
  }

  override render() {
    if (this.state.hasError) {
      if (this.state.error instanceof AuthError) {
        throw this.state.error;
      }
      if (
        this.state.error instanceof Response &&
        this.state.error.status === 401
      ) {
        throw new AuthError('Not authenticated: 401');
      }

      return <ErrorMessage error={this.state.error} />;
    }

    return this.props.children;
  }
}
