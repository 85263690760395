/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useCallback } from 'react';
import { FormikHelpers, FieldInputProps } from 'formik';
import { Select, Label } from '@fcg-tech/regtech-components';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import { NONE_SELECT_ITEM } from '../../constants';
import { FormGroup } from '../FormLayout';
import {
  ParentAgreementFieldWrapper,
  ParentAgreementFieldInner,
  ParentAgreementFieldLabel,
} from './ParentAgreementField.styles';

interface ParentAgreementFieldProps {
  form: FormikHelpers<ParentAgreement>;
  field: FieldInputProps<ParentAgreement>;
  agreements: Array<any>;
}

type ParentAgreement = {
  supplierName: string;
  id: string;
};

export const ParentAgreementField = ({
  form,
  field,
  agreements,
}: ParentAgreementFieldProps) => {
  const { t } = useTranslation();
  const agreementsItems = agreements.map(
    ({ metadata, supplierName, name }) => ({
      key: metadata.id,
      supplierName: supplierName,
      name: name,
    }),
  );

  const supplierNameItems = Array.from(
    new Set(agreementsItems.map((s) => s.supplierName)),
  )
    .map((supplierName) => {
      return {
        key: supplierName,
        label: supplierName,
      };
    })
    .filter((item) => {
      return item.label !== undefined;
    });

  const selectedSupplierName = useMemo(() => {
    const selected = agreementsItems
      .filter((item) => field.value?.supplierName?.includes(item.supplierName))
      .map(({ key, name }) => ({
        key: key,
        label: name,
      }));
    const selectedNone = agreementsItems.map(({ key, name }) => ({
      key: key,
      label: name,
    }));
    return selected.length > 0 ? selected : selectedNone;
  }, [field.value, agreementsItems]);

  const handleSupplierChange = useCallback(
    (value) => form.setFieldValue(`${field.name}.supplierName`, value),
    [form, field.name],
  );

  const handleNameChange = useCallback(
    (value) => form.setFieldValue(`${field.name}.id`, value),
    [form, field.name],
  );

  return (
    <ParentAgreementFieldWrapper>
      <>
        <ParentAgreementFieldInner>
          <FormGroup>
            <Label>
              {t(MessageKeys.ParentAgreementFieldSupplierLabel)}
            </Label>
            <ParentAgreementFieldLabel>
              <Select
                items={[NONE_SELECT_ITEM, ...supplierNameItems]}
                value={field.value?.supplierName}
                onChange={handleSupplierChange}
                typeahead
              />
            </ParentAgreementFieldLabel>
          </FormGroup>
        </ParentAgreementFieldInner>
        <FormGroup>
          <Label>
            {t(MessageKeys.ParentAgreementFieldAgreementNameLabel)}
          </Label>
          <ParentAgreementFieldLabel>
            <Select
              items={selectedSupplierName}
              value={field.value?.id}
              onChange={handleNameChange}
              typeahead
            />
          </ParentAgreementFieldLabel>
        </FormGroup>
      </>
    </ParentAgreementFieldWrapper>
  );
};
