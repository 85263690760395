import {
  DataTableCell,
  DataTableRow,
  DataTableRowOptions,
} from '@fcg-tech/regtech-components';
import { FunctionComponent, useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { countryState } from '../../states/countryState';

export type RowData = {
  name: string;
  countryOfRegistration?: string;
  corporateRegistrationNumber?: string;
};

export type ColumnKeys =
  | 'name'
  | 'countryOfRegistration'
  | 'corporateRegistrationNumber';

interface InternalEntitiesTableRowProps {
  row: DataTableRowOptions<RowData>;
  onClick?: (rowId: string) => void;
}

export const InternalEntitiesTableRow: FunctionComponent<
  InternalEntitiesTableRowProps
> = ({ row, onClick }) => {
  const data = row.data;
  const countries = useRecoilValue(countryState);

  const countryOfRegistration = useMemo(() => {
    const country = countries.find(
      (c) => c.isoAlpha2 === data.countryOfRegistration,
    );
    return country?.name ?? '';
  }, [countries, data.countryOfRegistration]);

  const handleClick = useCallback(() => {
    onClick?.(row.id);
  }, [onClick, row]);

  return (
    <DataTableRow row={row} onClick={handleClick}>
      <DataTableCell>{data.name}</DataTableCell>
      <DataTableCell>{countryOfRegistration}</DataTableCell>
      <DataTableCell>{data.corporateRegistrationNumber}</DataTableCell>
    </DataTableRow>
  );
};
