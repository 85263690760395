/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef } from 'react';
import { TableColumnResizerWrapper } from './DataTable.styles';

export interface DataTableColumnResizerProps<ColumnKey = any> {
  columnKey: ColumnKey;
  onResize: (columnKey: ColumnKey, diffPixels: number) => void;
}

export const DataTableColumnResizer = function <ColumnKey = any>({
  columnKey,
  onResize,
}: DataTableColumnResizerProps<ColumnKey>) {
  const pointerUpCallback = useRef<(event: PointerEvent) => void>();
  const pointerMoveCallback = useRef<(event: PointerEvent) => void>();
  const lastXPos = useRef<number>();

  const handlePointerDown = useCallback(
    (event: React.PointerEvent) => {
      event.preventDefault();
      lastXPos.current = event.pageX;
      pointerUpCallback.current = () => {
        document.body.setAttribute('class', '');
        window.removeEventListener('pointerup', pointerUpCallback.current);
        window.removeEventListener('pointermove', pointerMoveCallback.current);
      };
      document.body.setAttribute('class', 'no-select');

      pointerMoveCallback.current = (event: PointerEvent) => {
        if (event.buttons === 0) {
          window.removeEventListener('pointerup', pointerUpCallback.current);
          window.removeEventListener(
            'pointermove',
            pointerMoveCallback.current,
          );
        } else {
          const diff = event.pageX - lastXPos.current;
          if (diff !== 0) {
            onResize(columnKey, diff);
          }
          lastXPos.current = event.pageX;
        }
      };

      window.addEventListener('pointerup', pointerUpCallback.current);
      window.addEventListener('pointermove', pointerMoveCallback.current);
    },
    [columnKey, onResize],
  );

  useEffect(
    () => () => {
      if (pointerUpCallback.current)
        window.removeEventListener('pointerup', pointerUpCallback.current);
      if (pointerMoveCallback.current)
        window.removeEventListener('pointermove', pointerMoveCallback.current);
    },
    [],
  );

  return (
    <TableColumnResizerWrapper
      onPointerDown={handlePointerDown}
      tabIndex={-1}
      className="column-resizer"
    />
  );
};
