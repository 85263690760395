import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  Card,
  CardBody,
  CardTitle,
  Field,
  useEditContext,
} from '@fcg-tech/regtech-components';
import { BooleanDecision } from '@fcg-tech/regtech-formik-components';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../../translations/messageKeys';
import { agreementPropType } from '../../../propTypes';
import { getTooltip } from '../../../utils/tooltipHelpers';
import { FormRow, FormColumn, FormGroup } from '../../FormLayout';
import { RelatedAgreementsTable } from './components/RelatedAgreementsTable';
import { FieldCompare } from '../../FieldCompare/FieldCompare';
import { ParentAgreementField } from '../../ParentAgreementField';

const StyledFormRow = styled(FormRow)`
  margin-bottom: 0;
`;

const TableContainer = styled.div`
  ${({ contracted }) => css`
    position: relative;
    margin: ${!contracted ? '2.4rem 0' : '0'};
    padding: 0 1rem;
  `}
`;

export const AMLSection = ({ tenantId, agreement, relations, agreements }) => {
  const isEditEnabled = useEditContext();
  const { t } = useTranslation();

  return (
    <Card>
      <CardBody>
        <CardTitle>{t(MessageKeys.AMLSectionLabel)}</CardTitle>
        <FormColumn>
          <FormGroup>
            <BooleanDecision.FieldContainer>
              <BooleanDecision.FieldLabel>
                {t(MessageKeys.AMLSectionAmlRelatedActivityLabel)}
              </BooleanDecision.FieldLabel>
              <Field
                component={BooleanDecision}
                name="aml.AmlRelatedActivity"
                info={getTooltip('aml.amlRelatedActivity')}
              />
            </BooleanDecision.FieldContainer>
            <FieldCompare name="aml.AmlRelatedActivity" />
          </FormGroup>
        </FormColumn>
        <FormColumn />
      </CardBody>
    </Card>
  );
};

AMLSection.propTypes = {
  agreement: agreementPropType,
  relations: PropTypes.shape({
    children: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  agreements: PropTypes.arrayOf(agreementPropType),
};

AMLSection.defaultProps = {
  agreement: {},
  relations: {
    children: [],
  },
  agreements: [],
};
