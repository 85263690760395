import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useTranslation } from 'react-i18next';
import {
  TooltipNext as Tooltip,
  UsersIcon,
} from '@fcg-tech/regtech-components';
import { classNames } from '@fcg-tech/regtech-utils';
import React, { useCallback, useEffect, useRef } from 'react';
import {
  FilterBarIconWrapper,
  FilterBarPinnedItemWrapper,
} from './FilterBar.styles';
import { FilterMessageKeys } from './lang/filterMessageKeys';
import { FilterValues, StoredFilter, StoredFilterType } from './types';

export interface FilterBarItemProps<T extends FilterValues> {
  filter: StoredFilter<T>;
  active: boolean;
  reorderable?: boolean;
  singleUserFilterToolTipMessageKey?: string;
  multiUserFilterToolTipMessageKey?: string;
  className?: string;
  onFilterClick: (filterId: string) => void;
}

export const FilterBarItem = <T extends FilterValues>({
  filter,
  active,
  reorderable,
  multiUserFilterToolTipMessageKey,
  singleUserFilterToolTipMessageKey,
  className,
  onFilterClick,
}: FilterBarItemProps<T>) => {
  const { t } = useTranslation();
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: filter.id });

  if (transform) {
    transform.y = 0;
  }

  const dndStyle: React.CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition: transition ?? undefined,
  };

  dndStyle.transition = `box-shadow 0.2s ease-in ${
    dndStyle.transition ? `, ${dndStyle.transition}` : ''
  }`;

  if (isDragging) {
    dndStyle.transform = `${dndStyle.transform} scale(1.1)`;
  }

  const isDraggingRef = useRef(isDragging);
  useEffect(() => {
    // We need to persist the isDragging value for a bit in order to stop the mouse/touch up event to turn into a click after the user has stopped dragging
    if (isDragging && !isDraggingRef.current) {
      isDraggingRef.current = true;
    } else if (!isDragging && isDraggingRef.current) {
      setTimeout(() => (isDraggingRef.current = false), 10);
    }
  }, [isDragging]);

  const handlePointerUp = useCallback(
    () => setTimeout(() => (isDraggingRef.current = false), 10),
    [],
  );

  const handleFilterClick = useCallback(
    (event: React.MouseEvent) => {
      if (!isDraggingRef.current) {
        event.preventDefault();
        onFilterClick(filter.id);
      }
    },
    [filter.id, onFilterClick],
  );

  return (
    <Tooltip
      showDelay={750}
      content={
        !isDragging
          ? t(
              filter.type === StoredFilterType.SingleUserFilter
                ? singleUserFilterToolTipMessageKey ??
                    FilterMessageKeys.FilterSingleUserFilterTooltipLabel
                : multiUserFilterToolTipMessageKey ??
                    FilterMessageKeys.FilterMultiUserFilterTooltipLabel,
              { name: filter.name },
            )
          : null
      }
    >
      <FilterBarPinnedItemWrapper
        key={filter.id}
        ref={setNodeRef}
        style={dndStyle}
        className={classNames(
          active && 'active',
          isDragging && 'isdragging',
          className,
        )}
        onClick={handleFilterClick}
        onPointerUp={handlePointerUp}
        {...(reorderable ? attributes : {})}
        {...(reorderable ? listeners : {})}
      >
        {filter.type === StoredFilterType.MultiUserFilter ? (
          <FilterBarIconWrapper>
            <UsersIcon size="14" />
          </FilterBarIconWrapper>
        ) : null}

        {filter.name}
      </FilterBarPinnedItemWrapper>
    </Tooltip>
  );
};
