import styled from 'styled-components';
import { Option } from './Option';

export const CheckboxOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.6rem;

  &:hover {
    background-color: #eee;
  }
`;

export const CheckboxOptionInnerOption = styled(Option)`
  flex: 1;
  &:hover {
    background-color: transparent;
  }
`;

export const CheckboxOptionCheckbox = styled.span`
  cursor: pointer;
`;
