import styled from 'styled-components';
import {
  InfoCircle,
  SecondaryButton,
  TextField,
} from '@fcg-tech/regtech-components';

export const CalendarExportModalContent = styled.div`
  max-width: 54rem;
  width: 100%;
`;

export const CalendarExportModalBodyText = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  margin-top: 0;
`;

export const CalendarExportModalFormGroup = styled.div`
  margin-bottom: 1.4rem;
  margin-top: 1.4rem;
`;

export const CalendarExportModalButtonGroup = styled.div`
  display: flex;
`;

export const StyledInfoCircle = styled(InfoCircle)`
  margin-left: 1.4rem;
`;

export const CopyToClipboardWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`;

export const CopyToClipboardTextField = styled(TextField)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

export const CopyToClipboardButton = styled(SecondaryButton)`
  display: flex;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
  height: 4rem;
`;
