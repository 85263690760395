import React, { FunctionComponent, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import {
  Card,
  CardBody,
  EditProvider,
  loadDataTableSortOrder,
  PageLayout,
  PageLayoutPaddedContent,
  PageHeader,
  useDataTableSortable,
  PageHeaderActionButton,
  SecondaryButton,
  AccessControl,
  PrimaryButton,
} from '@fcg-tech/regtech-components';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/messageKeys';
import { routes } from '../../routes';
import {
  AgreementTypeAgreement,
  AgreementType,
  SingleEntity,
} from '../../types';
import { useTenant } from '../../states/tenantState';
import { generalAgreementsListEntitiesCompareFn } from '../../utils/compareFns';
import { getGeneralAgreementsListSortOrder } from '../../utils/listDataTableHelpers';
import { DirtyFormCheck } from '../../components/DirtyFormCheck';
import { ListDataTable } from '../../components/ListDataTable';
import { AgreementTypeDetailsFormSection } from './AgreementTypeDetailsFormSection';
import { Trash2 } from 'lucide-react';
import { AgreementTypeActions } from '../../api/schema';

interface AgreementTypeEditPageProps {
  agreementType: SingleEntity<AgreementType>;
  agreements: Array<AgreementTypeAgreement>;
  isEditEnabled: boolean;
  isSaving: boolean;
  onEdit: () => void;
  onDelete: () => void;
  onSave: (values: AgreementType) => Promise<void>;
  onCancel: () => void;
}

export const AgreementTypeEditPage: FunctionComponent<
  AgreementTypeEditPageProps
> = ({
  agreementType,
  agreements,
  isEditEnabled,
  isSaving,
  onSave,
  onEdit,
  onCancel,
  onDelete,
}) => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const tenantId = tenant.id;
  const navigate = useNavigate();

  const permissions = useMemo(
    () => agreementType?.permissions ?? [],
    [agreementType],
  );

  const pageTitle = useMemo(
    () => agreementType.data.name || t(MessageKeys.LabelUnknown),
    [agreementType.data.name, t],
  );

  const columns = React.useMemo(
    () => [
      {
        columnKey: 'name',
        label: t(MessageKeys.LabelName),
        resizable: false,
        sortable: true,
        initialSortAscending: true,
      },
      {
        columnKey: 'agreementType',
        label: t(MessageKeys.LabelAgreementType),
        resizable: false,
        sortable: true,
        initialSortAscending: false,
      },
      {
        columnKey: 'functionCategory',
        label: t(MessageKeys.LabelCategory),
        resizable: false,
        sortable: true,
        initialSortAscending: false,
      },
      {
        columnKey: 'contractOwner',
        label: t(MessageKeys.LabelContractOwner),
        resizable: false,
        sortable: true,
        initialSortAscending: false,
      },
    ],
    [t],
  );

  const rows = React.useMemo(
    () =>
      agreements?.map((agreement) => ({
        id: agreement.metadata.id,
        data: agreement,
      })) ?? [],
    [agreements],
  );

  const { sortedRows, sortOrder, handleSortOrderChange } = useDataTableSortable(
    'agreements-agreementType-table-sort-order',
    rows,
    {
      sortOrder:
        loadDataTableSortOrder('agreements-agreementType-table-sort-order') ??
        getGeneralAgreementsListSortOrder('nameAsc'),
      compareFn: generalAgreementsListEntitiesCompareFn,
    },
  );

  const handleClickRow = React.useCallback(
    (id) =>
      navigate(constructUrl(routes.agreement, { tenantId, agreementId: id })),
    [navigate, tenantId],
  );

  return (
    <EditProvider value={isEditEnabled}>
      <Formik initialValues={agreementType.data} onSubmit={onSave}>
        {({ handleSubmit, resetForm, dirty }) => (
          <Form>
            <DirtyFormCheck dirty={dirty && !isSaving && isEditEnabled} />
            <PageLayout>
              <PageHeader title={pageTitle}>
                {isEditEnabled ? (
                  <>
                    <SecondaryButton
                      onClick={() => {
                        resetForm();
                        onCancel();
                      }}
                    >
                      {t(MessageKeys.LabelCancel)}
                    </SecondaryButton>
                    <PrimaryButton
                      onClick={() => handleSubmit()}
                      loading={isSaving}
                      disabled={!dirty || isSaving}
                    >
                      {t(MessageKeys.LabelSubmit)}
                    </PrimaryButton>
                  </>
                ) : (
                  <>
                    <AccessControl
                      permissions={permissions}
                      requiredPermissions={
                        AgreementTypeActions.AgreementTypeDelete
                      }
                    >
                      <PageHeaderActionButton onClick={onDelete}>
                        <Trash2 size="24" />
                      </PageHeaderActionButton>
                    </AccessControl>
                    <AccessControl
                      permissions={permissions}
                      requiredPermissions={
                        AgreementTypeActions.AgreementTypeEdit
                      }
                    >
                      <PrimaryButton onClick={onEdit}>
                        {t(MessageKeys.LabelEdit)}
                      </PrimaryButton>
                    </AccessControl>
                  </>
                )}
              </PageHeader>
              <PageLayoutPaddedContent>
                <AgreementTypeDetailsFormSection />
                <Card>
                  <CardBody>
                    <ListDataTable
                      title={t(MessageKeys.LabelAgreements)}
                      emptyTableText={t(MessageKeys.LabelNoAgreementsFound)}
                      columns={columns}
                      data={sortedRows}
                      sortOrder={sortOrder}
                      handleSortOrderChange={handleSortOrderChange}
                      handleClickRow={handleClickRow}
                    />
                  </CardBody>
                </Card>
              </PageLayoutPaddedContent>
            </PageLayout>
          </Form>
        )}
      </Formik>
    </EditProvider>
  );
};
