/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik } from 'formik';
import {
  Card,
  CardBody,
  CardTitle,
  PageHeader,
  PageHeaderActionButton,
  PageHeaderActions,
  PageLayout,
  PageLayoutPaddedContent,
  SecondaryButton,
  TooltipNext as Tooltip,
  useAccessControl,
} from '@fcg-tech/regtech-components';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { useTranslation } from 'react-i18next';
import { History } from 'lucide-react';
import { MessageKeys } from '../../../translations/messageKeys';
import {
  agreementPropType,
  categoryPropType,
  internalEntityPropType,
  attachmentPropType,
} from '../../../propTypes';
import { routes } from '../../../routes';
import { useTenant } from '../../../states/tenantState';
import { formatZonedDateTime } from '../../../utils/formatters';
import { Attachments } from '../../../components/Attachments';
import { TagPicker } from '../../../components/TagPicker';
import { ValidationSummary } from '../../../components/ValidationSummary';
import { DetailsSection } from '../../../components/editorsections/agreements/DetailsSection';
import { NPAPSection } from '../../../components/editorsections/agreements/NPAPSection';
import { PersonalDataSection } from '../../../components/editorsections/agreements/PersonalDataSection';
import { ServiceDetailsSection } from '../../../components/editorsections/agreements/ServiceDetailsSection';
import { ResolvabilityAssessmentSection } from '../../../components/editorsections/agreements/ResolvabilityAssessmentSection';
import { RelatedAgreementsSection } from '../../../components/editorsections/agreements/RelatedAgreementsSection';
import { AgreementAssessmentSection } from '../../../components/editorsections/agreements/AgreementAssessmentSection';
import { ChangeManagementProcessSection } from '../../../components/editorsections/agreements/ChangeManagementProcessSection';
import { RiskAssessmentSection } from '../../../components/editorsections/agreements/RiskAssessmentSection';
import { AMLSection } from '../../../components/editorsections/agreements/AMLSection';
import { ValidationErrorsProvider } from '../../../components/FormikNonBlockingContext';
import Features from '../../../Features';

const TagPickerWrapper = styled.div`
  margin: 2.4rem 0;
`;

export const AgreementVersionPage = ({
  agreement,
  agreements,
  attachments,
  agreementTypes,
  categories,
  internalEntities,
  suppliers,
}) => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const tenantId = tenant.id;

  const canViewHistory = useAccessControl(agreement.permissions, [
    'oasys:AgreementHistory',
  ]);

  const pageTitle = React.useMemo(() => {
    if (agreement?.metadata?.isArchived) {
      return (
        agreement.data.details.name +
          ` (${t(MessageKeys.EditAgreementArchivedHeadingLabel)})` ||
        t(MessageKeys.LabelUnnamed)` (${t(
          MessageKeys.EditAgreementArchivedHeadingLabel,
        )})`
      );
    } else {
      return agreement.data.details.name || t(MessageKeys.LabelUnnamed);
    }
  }, [agreement.data.details.name, t, agreement.metadata.isArchived]);

  return (
    <Formik
      key={agreement.metadata.version}
      initialValues={agreement.data}
      onSubmit={null}
      validateOnBlur
      validateOnChange={false}
    >
      {({ handleSubmit, values }) => (
        <PageLayout>
          <PageHeader
            title={pageTitle}
            isArchived={agreement?.metadata?.isArchived}
          >
            <PageHeaderActions>
              {canViewHistory ? (
                <Tooltip
                  html
                  content={t(MessageKeys.AgreementVersionInfoLabel, {
                    version: agreement.metadata.version,
                    date: formatZonedDateTime(agreement.metadata.modified),
                    modifiedBy: agreement.metadata.modifiedBy,
                  })}
                  placement="bottom-end"
                >
                  <PageHeaderActionButton
                    to={constructUrl(routes.agreementVersions, {
                      tenantId,
                      agreementId: agreement.metadata.id,
                    })}
                  >
                    <History size="24" />
                  </PageHeaderActionButton>
                </Tooltip>
              ) : null}
            </PageHeaderActions>
            <SecondaryButton
              to={constructUrl(routes.agreement, {
                tenantId,
                agreementId: agreement.metadata.id,
              })}
            >
              {t(MessageKeys.LabelCurrent)}
            </SecondaryButton>
          </PageHeader>
          <PageLayoutPaddedContent>
            <ValidationSummary
              title={t(MessageKeys.AgreementVersionAdditionalInformationLabel)}
              nonBlockingErrors={agreement.data.validations}
            />
            <TagPickerWrapper>
              <TagPicker isEditEnabled={false} value={agreement?.tags || []} />
            </TagPickerWrapper>

            <form onSubmit={handleSubmit}>
              <ValidationErrorsProvider value={agreement.data.validations}>
                <DetailsSection
                  agreement={values}
                  metadata={agreement.metadata}
                  attachments={attachments}
                  internalEntities={internalEntities}
                  agreementTypes={agreementTypes}
                  suppliers={suppliers}
                />
                {Features.RelatedAgreements ? (
                  <RelatedAgreementsSection
                    tenantId={tenantId}
                    agreement={values}
                    relations={agreement.relations}
                    agreements={agreements}
                  />
                ) : null}
                <PersonalDataSection
                  agreement={values}
                  metadata={agreement.metadata}
                  attachments={attachments}
                  internalEntities={internalEntities}
                />
                <ServiceDetailsSection
                  agreement={values}
                  categories={categories}
                  internalEntities={internalEntities}
                  suppliers={suppliers}
                />
                <NPAPSection
                  agreement={values}
                  metadata={agreement.metadata}
                  attachments={attachments}
                />
                <ChangeManagementProcessSection
                  agreement={values}
                  metadata={agreement.metadata}
                  attachments={attachments}
                />
                <RiskAssessmentSection
                  agreement={values}
                  metadata={agreement.metadata}
                  attachments={attachments}
                />
                <AMLSection
                  agreement={values}
                  metadata={agreement.metadata}
                  attachments={attachments}
                />
                <AgreementAssessmentSection
                  agreement={values}
                  metadata={agreement.metadata}
                  attachments={attachments}
                  internalEntities={internalEntities}
                  suppliers={suppliers}
                  categories={categories}
                />
                {Features.ResolutionFields ? (
                  <ResolvabilityAssessmentSection agreement={values} />
                ) : null}
              </ValidationErrorsProvider>
            </form>
            <Card>
              <CardBody>
                <CardTitle>
                  {t(MessageKeys.AgreementVersionOtherAttachmentsLabel)}
                </CardTitle>
                {agreement.metadata && (
                  <Attachments section="other" attachments={attachments} />
                )}
              </CardBody>
            </Card>
          </PageLayoutPaddedContent>
        </PageLayout>
      )}
    </Formik>
  );
};

AgreementVersionPage.propTypes = {
  agreement: agreementPropType.isRequired,
  agreements: PropTypes.arrayOf(agreementPropType),
  attachments: PropTypes.arrayOf(attachmentPropType),
  categories: PropTypes.arrayOf(categoryPropType),
  internalEntities: PropTypes.arrayOf(internalEntityPropType),
  // TODO: service provider prop type
  suppliers: PropTypes.arrayOf(PropTypes.shape({})),
};

AgreementVersionPage.defaultProps = {
  agreements: [],
  attachments: [],
  categories: [],
  internalEntities: [],
  suppliers: [],
};
