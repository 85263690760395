import { AnyCSS, Theme } from '@fcg-tech/regtech-types';
import 'styled-components';
import { css } from 'styled-components';

declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends Theme {}
}

/**
 * @deprecated - use mobile/narrow/medium instead
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const media = (theme?: Theme) => (styles: any) => {
  return css`
    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      ${styles}
    }
  `;
};

export const wide =
  (styles: (theme?: Theme) => AnyCSS) =>
  ({ theme }: { theme?: Theme }) => {
    return css`
      @media (min-width: 2000px) {
        ${styles(theme)}
      }
    `;
  };

export const xWide =
  (styles: (theme?: Theme) => AnyCSS) =>
  ({ theme }: { theme?: Theme }) => {
    return css`
      @media (min-width: 2600px) {
        ${styles(theme)}
      }
    `;
  };

export const mobile =
  (styles: (theme?: Theme) => AnyCSS) =>
  ({ theme }: { theme?: Theme }) => {
    return css`
      @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
        ${styles(theme)}
      }
    `;
  };

export const narrow =
  (styles: (theme?: Theme) => AnyCSS) =>
  ({ theme }: { theme?: Theme }) => {
    return css`
      @media (max-width: ${theme?.breakpoints?.narrowDesktop ?? 1024}px) {
        ${styles(theme)}
      }
    `;
  };

export const medium =
  (styles: (theme?: Theme) => AnyCSS) =>
  ({ theme }: { theme?: Theme }) => {
    return css`
      @media (max-width: ${theme?.breakpoints?.mediumDesktop ?? 1240}px) {
        ${styles(theme)}
      }
    `;
  };
