import styled from 'styled-components';
import { InfoCircle } from '../InfoCircle';
import { excludeProps } from '../../utils';
import { IconButton } from '../Button';

export const DynamicListWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const DynamicListInner = styled.div`
  flex: 1;
`;

export const StyledInfoCircle = styled(InfoCircle).withConfig(
  excludeProps('isEditEnabled'),
)<{ isEditEnabled?: boolean }>`
  margin-left: 1rem;
  margin-top: ${({ isEditEnabled }) => (isEditEnabled ? '1.2rem' : 0)};
`;

export const DynamicListItemWrapper = styled.div`
  display: flex;
  align-items: center;
  & + & {
    margin-top: 1rem;
  }
`;

export const DynamicListItemRemoveIconButton = styled(IconButton)`
  width: 3rem;
  height: 3rem;
  margin-left: 0.4rem;
`;
