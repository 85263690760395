import styled, { css } from 'styled-components';
import { excludeProps } from '../../utils';

export const fixedContainerStyle = css`
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
`;

export const containerStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoaderWrapper = styled.div.withConfig(excludeProps('disablePortal'))<{
  disablePortal?: boolean;
}>`
  ${({ disablePortal }) => css`
    ${disablePortal ? containerStyle : fixedContainerStyle}
  `}
`;

export const LoaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const messageFontSize = {
  small: () => css`
    font-size: 1.2rem;
  `,
  large: () => css`
    font-size: 1.6rem;
  `,
};

export const Message = styled.div.withConfig(excludeProps('size'))<{
  size?: 'small' | 'large';
}>`
  ${({ theme, size }) => css`
    ${messageFontSize[size]};
    font-family: ${theme.typography?.fontFamily};
    font-weight: inherit;
    margin-bottom: 1rem;
  `}
`;
