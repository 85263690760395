import { BellIcon, Tooltip, useTooltip } from '@fcg-tech/regtech-components';
import React, { FunctionComponent } from 'react';
import {
  BellIconButtonBadge,
  BellIconButtonNavLink,
  BellIconButtonWrapper,
} from './BellIconButton.styles';

interface BellIconButtonProps {
  id?: string;
  count?: number | string;
  href?: string;
  tooltip?: string;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const BellIconButton: FunctionComponent<BellIconButtonProps> = ({
  id,
  count,
  href,
  tooltip: tooltipText,
  className,
  onClick,
}) => {
  const tooltip = useTooltip<HTMLDivElement>({
    entryTimeoutMS: 500,
  });

  const badge = count ? (
    <BellIconButtonBadge>{count}</BellIconButtonBadge>
  ) : null;

  return (
    <BellIconButtonWrapper
      ref={tooltip.ref}
      id={id}
      {...(tooltipText ? tooltip.mouseEvents : {})}
    >
      {tooltipText && tooltip.isTooltipVisible ? (
        <Tooltip anchorRef={tooltip.ref}>{tooltipText}</Tooltip>
      ) : null}
      {href ? (
        <BellIconButtonNavLink to={href} className={className}>
          <BellIcon size="22" color="black" />
        </BellIconButtonNavLink>
      ) : (
        <BellIcon size="22" color="black" />
      )}
      {badge}
    </BellIconButtonWrapper>
  );
};
