import {
  isMultiValueType,
  isSingleValueType,
  ReactSelectOption,
} from '@fcg-tech/regtech-components';
import { useCallback, useState } from 'react';
import Select, { MultiValue, SingleValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { portalRoot } from '../Portal';
import { useFormSelectStyles } from '../selectStyles';
import {
  FormSelectErrorMessage,
  FormSelectReadOnlyLabel,
  FormSelectReadOnlyWrapper,
  FormSelectWrapper,
  StyledInfoCircle,
} from './FormSelect.styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type OptionValue = { [key: string]: any };

interface FormSelectProps<Option extends OptionValue> {
  id?: string;
  name?: string;
  value?:
    | SingleValue<ReactSelectOption<Option>>
    | MultiValue<ReactSelectOption<Option>>
    | null;
  options?: Array<ReactSelectOption<Option>>;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  isEditEnabled?: boolean;
  isClearable?: boolean;
  isLoading?: boolean;
  autoFocus?: boolean;
  closeMenuOnSelect?: boolean;
  isMulti?: boolean;
  info?: string;
  onChange?: (
    option?:
      | SingleValue<ReactSelectOption<Option>>
      | MultiValue<ReactSelectOption<Option>>
      | null,
  ) => void;
  onCreateOption?: (inputValue?: string) => void;
  formatCreateLabel?: (inputValue?: string) => React.ReactNode;
}

export const FormSelect = <Option extends OptionValue>({
  id,
  name,
  value,
  options,
  placeholder,
  error,
  errorMessage,
  isEditEnabled = true,
  isClearable = false,
  isLoading = false,
  isMulti,
  autoFocus,
  closeMenuOnSelect,
  info,
  onChange,
  onCreateOption,
  formatCreateLabel,
}: FormSelectProps<Option>) => {
  const [isFocused, setFocused] = useState(autoFocus);
  const handleFocus = useCallback(() => setFocused(true), []);
  const handleBlur = useCallback(() => setFocused(false), []);

  const styles = useFormSelectStyles(error);

  return (
    <FormSelectWrapper>
      {isEditEnabled ? (
        <>
          {onCreateOption ? (
            <CreatableSelect
              id={id}
              name={name}
              value={value}
              options={options}
              placeholder={placeholder}
              styles={styles}
              isMulti={isMulti}
              isClearable={isClearable}
              isLoading={isLoading}
              blurInputOnSelect
              closeMenuOnSelect={closeMenuOnSelect}
              menuPortalTarget={portalRoot}
              createOptionPosition="first"
              onChange={onChange}
              allowCreateWhileLoading={false}
              onCreateOption={onCreateOption}
              formatCreateLabel={formatCreateLabel}
              onBlur={handleBlur}
              onFocus={handleFocus}
            />
          ) : (
            <Select
              id={id}
              name={name}
              value={value}
              options={options}
              placeholder={placeholder}
              styles={styles}
              isMulti={isMulti}
              isClearable={isClearable}
              isLoading={isLoading}
              closeMenuOnSelect={closeMenuOnSelect}
              menuPortalTarget={portalRoot}
              onChange={onChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          )}
        </>
      ) : (
        <FormSelectReadOnlyWrapper>
          <FormSelectReadOnlyLabel>
            {isMultiValueType(value)
              ? value?.map((v) => v.label).join(',') // TODO: fix readonly for multi value type
              : value?.label}
          </FormSelectReadOnlyLabel>
        </FormSelectReadOnlyWrapper>
      )}
      {info ? <StyledInfoCircle info={info} /> : null}
      {!isFocused && errorMessage ? (
        <FormSelectErrorMessage>{errorMessage}</FormSelectErrorMessage>
      ) : null}
    </FormSelectWrapper>
  );
};
