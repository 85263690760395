import { flattenJSON } from '@fcg-tech/regtech-utils';
import { stringify } from 'query-string';
import { FilterType, ApiGetAllAgreementsRequest } from '../../types';

export const initDataKey = (tenantId: string) => `/${tenantId}/init`;

export const globalSearchKey = (tenantId: string, searchString: string) =>
  `/${tenantId}/search?${stringify({ searchString })}`;

export const calendarEventsKey = (tenantId: string) =>
  `/${tenantId}/calendar-events`;

export const personalNotificationSettingsKey = (tenantId: string) =>
  `${tenantId}/personal-settings/notifications`;

export const allAgreementsKey = (
  tenantId: string,
  query: Partial<ApiGetAllAgreementsRequest>,
) => `/${tenantId}/agreements?${stringify(flattenJSON(query) as never)}`;

export const agreementKey = (tenantId: string, agreementId: string) =>
  `/${tenantId}/agreements/${agreementId}`;

export const allAgreementAttachmentsKey = (
  tenantId: string,
  agreementId: string,
  agreementVersion: number | null,
) =>
  `/${tenantId}/agreements/${agreementId}/version/${agreementVersion}/attachments`;

export const allAgreementTypesKey = (tenantId: string) =>
  `/${tenantId}/agreement-types`;

export const agreementTypeKey = (tenantId: string, agreementTypeId: string) =>
  `/${tenantId}/agreement-types/${agreementTypeId}`;

export const agreementHistoryKey = (tenantId: string, agreementId: string) =>
  `/${tenantId}/agreements/${agreementId}/versions`;

export const agreementVersionKey = (
  tenantId: string,
  agreementId: string,
  version: string,
) => `${tenantId}/agreements/${agreementId}/versions/${version}`;

export const allAgreementTagsKey = (tenantId: string, agreementId: string) =>
  `/${tenantId}/agreements/${agreementId}/tags`;

export const agreementTagKey = (
  tenantId: string,
  agreementId: string,
  tagName: string,
) => `/${tenantId}/agreements/${agreementId}/tags/${tagName}`;

export const allCabinetsKey = (tenantId: string) =>
  `/${tenantId}/agreements/cabinets`;

export const cabinetKey = (tenantId: string, cabinetName: string) =>
  `/${tenantId}/agreements/cabinets/${cabinetName}`;

export const allTagsKey = (tenantId: string) => `/${tenantId}/agreements/tags`;

export const tagKey = (tenantId: string, tagName: string) =>
  `/${tenantId}/agreements/tags/${tagName}`;

export const allInternalEntitiesKey = (tenantId: string) =>
  `/${tenantId}/internal-entities`;

export const internalEntityKey = (tenantId: string, internalEntityId: string) =>
  `/${tenantId}/internal-entities/${internalEntityId}`;

export const allCategoriesKey = (tenantId: string) => `/${tenantId}/categories`;

export const categoryKey = (tenantId: string, categoryId: string) =>
  `/${tenantId}/categories/${categoryId}`;

export const allSuppliersKey = (tenantId: string) => `/${tenantId}/suppliers`;

export const supplierKey = (tenantId: string, supplierId: string) =>
  `/${tenantId}/suppliers/${supplierId}`;

export const allSupplierAttachmentsKey = (
  tenantId: string,
  supplierId: string,
  supplierVersion: number | null,
) =>
  `/${tenantId}/suppliers/${supplierId}/version/${supplierVersion}/attachments`;

export const supplierHistoryKey = (tenantId: string, supplierId: string) =>
  `/${tenantId}/suppliers/${supplierId}/versions`;

export const supplierVersionKey = (
  tenantId: string,
  supplierId: string,
  version: string,
) => `/${tenantId}/suppliers/${supplierId}/versions/${version}`;

export const allReportsKey = (tenantId: string) => `/${tenantId}/reports`;

export const allReportAgreementsKey = (tenantId: string) =>
  `/${tenantId}/reports/agreements`;

export const reportKey = (tenantId: string, reportId: string) =>
  `/${tenantId}/reports/${reportId}`;

export const filterKey = (tenantId: string, filterType: FilterType) =>
  `/${tenantId}/filters/${filterType}`;
