import styled from 'styled-components';

export const FileUploadModalContent = styled.div`
  padding: 0.3rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 30vw;
  min-width: 50rem;
  flex: 1;
  overflow-y: auto;
`;
