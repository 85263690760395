import { classNames } from '@fcg-tech/regtech-utils';
import { format, getDate } from 'date-fns';
import React, { FunctionComponent, useCallback, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { theme as defaultTheme } from './theme';

export type OnDateChange = (
  date: Date,
  event: React.MouseEvent<HTMLElement>,
) => void;

export type DayConfig = {
  date: Date;
  disabled?: boolean;
  isSelected?: boolean;
  current?: boolean;
  outOfContext?: boolean;
  getClassName?: (d: Date) => string;
  getGlobalDayClassName?: (d: Date) => string;
};

export interface DayProps extends DayConfig {
  className?: string;
  onClick?: OnDateChange;
}

export const Day: FunctionComponent<PropsWithChildren<DayProps>> = ({
  date,
  disabled,
  isSelected,
  current,
  outOfContext,
  getClassName,
  getGlobalDayClassName,
  className,
  onClick,
  children,
}) => {
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => onClick?.(date, event),
    [date, onClick],
  );
  const finalClassName = classNames(
    className,
    getClassName?.(date),
    getGlobalDayClassName?.(date),
  );

  return (
    <DayContainer
      className={finalClassName}
      role="button"
      data-date={format(date, 'yyyy-MM-dd')}
      onClick={!disabled ? handleClick : undefined}
      $disabled={disabled}
      $outOfContext={outOfContext}
      $current={current}
      $isSelected={isSelected}
    >
      {children ?? getDate(date)}
    </DayContainer>
  );
};

const DayContainer = styled.div<{
  $disabled?: boolean;
  $current?: boolean;
  $outOfContext?: boolean;
  $isSelected?: boolean;
}>`
  ${({ theme }) =>
    theme?.elements?.datepicker?.day?.container ?? defaultTheme.day.container};
  ${({ $disabled, theme }) =>
    $disabled
      ? theme?.elements?.datepicker?.day?.disabled ?? defaultTheme.day.disabled
      : null}
  ${({ $current, theme }) =>
    $current
      ? theme?.elements?.datepicker?.day?.current ?? defaultTheme.day.current
      : null}
  ${({ $outOfContext, theme }) =>
    $outOfContext
      ? theme?.elements?.datepicker?.day?.outOfContext ??
        defaultTheme.day.outOfContext
      : null}
  ${({ $isSelected, theme }) =>
    $isSelected
      ? theme?.elements?.datepicker?.day?.isSelected ??
        defaultTheme.day.isSelected
      : null}
`;
