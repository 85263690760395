import { PillMultiSelect as DefaultPillMultiSelect } from '@fcg-tech/regtech-components';
import { FieldInputProps, FormikHelpers } from 'formik';
import React from 'react';


interface PillMultiSelectProps
  extends React.ComponentPropsWithRef<typeof DefaultPillMultiSelect> {
  form: FormikHelpers<Array<string | number>>;
  field: FieldInputProps<Array<string | number>>;
}

export const PillMultiSelect = ({ form, field, onChange, ...props }: PillMultiSelectProps) => {
  const handleChange = React.useCallback(
    (value) => {
      if (value) {
        form.setFieldValue(field.name, [...(field.value || []), value]);
      }
    },
    [form, field.name, field.value],
  );

  const handleRemoveValue = React.useCallback(
    (value) => {
      const index = field.value?.indexOf(value);
      if (index >= 0) {
        form.setFieldValue(field.name, [
          ...field.value.slice(0, index),
          ...field.value.slice(index + 1),
        ]);
      }
    },
    [form, field.name],
  );

  const value = React.useMemo(() => field.value || [], [field.value]);

  return (
    <DefaultPillMultiSelect
      {...props}
      {...field}
      value={value}
      onChange={onChange || handleChange}
      onRemoveValue={handleRemoveValue}
    />
  );
};
