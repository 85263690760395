import React from 'react';
import Color from 'color';
import { IconProps } from '../../types/icons';

export const NotArchiveIcon = ({
  size = '24',
  color = 'currentColor',
  ...props
}: IconProps) => (
  <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
    <path
      d="M1.73994 0.327327C1.36843 -0.0813304 0.735985 -0.111447 0.327327 0.26006C-0.0813304 0.631567 -0.111447 1.26402 0.26006 1.67267L1.73994 0.327327ZM20.2601 23.6727C20.6316 24.0813 21.264 24.1114 21.6727 23.7399C22.0813 23.3684 22.1114 22.736 21.7399 22.3273L20.2601 23.6727ZM0.26006 1.67267L20.2601 23.6727L21.7399 22.3273L1.73994 0.327327L0.26006 1.67267Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 12C9 11.4477 9.44772 11 10 11H10.6023C11.1546 11 11.6747 12 11.6747 12C11.6747 12.5523 11.4718 13 11.4718 13H10C9.44772 13 9 12.5523 9 12Z"
      fill={color}
    />
    <path
      d="M21 7C21.5523 7 22 7.44772 22 8V9H23C23.5523 9 24 8.55228 24 8V3C24 2.44772 23.5523 2 23 2H8.10968C8.10968 2 7.09524 2 7.09524 3C7.09524 4 8.10968 4 8.10968 4H22V7H21Z"
      fill={color}
    />
    <path
      d="M2 9V8C2 7.44772 2.44772 7 3 7H2V4H4.66059L3 2H1C0.447715 2 0 2.44772 0 3V8C0 8.55228 0.447715 9 1 9H2Z"
      fill={color}
    />
    <path d="M3 7C3.55228 7 4 7.44772 4 8V9H8.6024L7.32711 7H3Z" fill={color} />
    <path
      d="M20 8C20 7.44772 20.4477 7 21 7H13.0338C13.0338 7 12.0515 7 12.0515 8C12.0515 9 13.0338 9 13.0338 9H20V8Z"
      fill={color}
    />
    <path
      d="M19.1815 20H4V9H2V21C2 21.5523 2.44772 22 3 22H21L19.1815 20Z"
      fill={color}
    />
    <path
      d="M4 8C4 7.44772 3.55228 7 3 7C2.44772 7 2 7.44772 2 8V9H4V8Z"
      fill={color}
    />
    <path
      d="M21 7C20.4477 7 20 7.44772 20 8V9H22V8C22 7.44772 21.5523 7 21 7Z"
      fill={color}
    />
    <path
      d="M22 9H20V17.0535C20 17.0535 20 18.1259 21 18.1259C22 18.1259 22 17.0535 22 17.0535V9Z"
      fill={color}
    />
  </svg>
);
