import styled, { css } from 'styled-components';

export const GlobalSearchPageHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  white-space: nowrap;
`;

export const GlobalSearchPageHeadingSearchString = styled.div`
  border: 1px solid #ddd;
  background-color: #eee;
  padding: 0.2rem 0.5rem;
  border-radius: 0.4rem;
  margin: 0 0 0 1rem;
  min-width: 0;
`;

export const GlobalSearchPageHeadingSearchStringPre = styled.pre`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;

  &.small {
    font-size: 1.6rem;
  }
`;

export const GlobalSearchResultTypeTag = styled.span`
  ${({ theme }) => css`
    border: 1px solid transparent;
    border-radius: 0.4rem;
    font-size: 1.2rem;
    font-weight: 500;
    padding: 0.4rem 0.6rem;
    border-color: ${theme?.palette?.MainColor?.mainContrast ?? 'initial'};
    color: ${theme?.palette?.MainColor?.mainContrast ?? 'initial'};
    background-color: ${theme?.palette?.MainColor?.main ?? 'initial'};
  `}
`;
