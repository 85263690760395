import { StylesConfig } from 'react-select';
import { ReactSelectOption } from '../../types';

export type SelectAllOptionData = { _selectAll_?: boolean };

export const isSelectAllOptionData = (
  data: unknown,
): data is SelectAllOptionData => {
  return data && (data as SelectAllOptionData)._selectAll_ === true;
};

export const inLabelStyles: StylesConfig<unknown> = {
  container: (p) => ({
    ...p,
    pointerEvents: 'all',
    width: 'calc(100% - 1px)',
    marginRight: 1,
    backgroundColor: 'transparent',
    top: '-1px',
  }),
  control: (p, props) => ({
    ...p,
    border: 0,
    boxShadow: 'none',
    cursor: props.isDisabled ? 'not-allowed' : 'default',
    top: '1px',
    background: 'transparent',
  }),
  valueContainer: (p) => ({
    ...p,
    paddingLeft: 0,
  }),
  menu: (p) => ({
    ...p,
    left: '-0.8rem',
    marginTop: 3,
    width: 'calc(100% + 0.8rem)',
  }),
};

export const multiSelectStyles: StylesConfig<unknown, true> = {
  container: (p) => ({
    ...p,
    fontSize: '1.4rem',
    minWidth: '28rem',
    width: '100%',
  }),
  menu: (p) => ({
    ...p,
    zIndex: 10,
  }),
  option: (p, { data }) => {
    if (
      isSelectAllOptionData(
        (data as ReactSelectOption<SelectAllOptionData>).value,
      )
    ) {
      return {
        ...p,
        fontWeight: 600,
      };
    }
    return p;
  },
};

export const singleSelectStyles: StylesConfig<unknown, false> = {
  container: (p) => ({
    ...p,
    fontSize: '1.4rem',
    minWidth: '28rem',
    width: '100%',
  }),
  menu: (p) => ({
    ...p,
    zIndex: 10,
  }),
};

export const multiSelectInLabelStyles: typeof multiSelectStyles = {
  ...multiSelectStyles,
  ...inLabelStyles,
  container: (p, props) => ({
    ...multiSelectStyles.container(p, props),
    ...inLabelStyles.container(p, props),
  }),
};

export const singleSelectInLabelStyles: typeof singleSelectStyles = {
  ...singleSelectStyles,
  ...inLabelStyles,
  container: (p, props) => ({
    ...singleSelectStyles.container(p, props),
    ...inLabelStyles.container(p, props),
  }),
};
